import { createMuiTheme } from "@material-ui/core/styles";
import { paletteOptions } from "./palette";
import { shapeOptions } from "./shape";
import { typographyOptions } from "./typography";
import { overrides } from "./overrides";
import { shadowOptions } from "./shadows";

const SPACING = 8;

export const deepCrawlTheme = createMuiTheme({
  palette: paletteOptions,
  shape: shapeOptions,
  typography: typographyOptions,
  overrides,
  spacing: SPACING,
  shadows: shadowOptions
});
