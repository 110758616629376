import React from "react";
import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { SchedulerListItem } from "./SchedulerListItem";
import { getBuildSchedules_getAccount_buildSchedules_nodes } from "../../../generated-graphql-interfaces";

const useStyles = makeStyles(theme => ({
  listPaper: {
    minHeight: "688px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down("md")]: {
      minHeight: "0px",
      overflow: "hidden",
      paddingBottom: theme.spacing(2)
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    minHeight: "76px",
    padding: theme.spacing(0, 5, 0, 5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  testSuitesHeader: {
    textAlign: "right"
  },
  tableHeadings: {
    display: "flex",
    alignItems: "center",
    minHeight: "57px",
    padding: theme.spacing(0, 3.5, 0, 3.5),
    color: theme.palette.navy[300],
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  tableHeadingsContainer: { alignItems: "center" },
  arrowGap: {
    flex: "1 0 40px",
    paddingRight: "0px"
  }
}));

export interface SchedulerManageListProps {
  schedulers: getBuildSchedules_getAccount_buildSchedules_nodes[];
  selectedSchedulerId: string | undefined;
  accountId: string;
  onSelectItem: (itemId: string) => void;
}

export function SchedulerManageList(props: SchedulerManageListProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.listPaper} data-testid="scheduler-manage-list" data-pendo="auto-scheduler-list">
      <Box className={classes.tableHeadings}>
        <Grid container className={classes.tableHeadingsContainer}>
          <Grid item xs={8} data-testid="scheduler-manage-list-header-counter">
            {props.schedulers.length}
            {props.schedulers.length === 1 ? " schedule" : " schedules"}
          </Grid>
          <Grid item xs={4} className={classes.testSuitesHeader} data-testid="scheduler-manage-list-header-description">
            Test suites
          </Grid>
        </Grid>
      </Box>
      {props.schedulers.map(scheduler => (
        <SchedulerListItem
          key={scheduler.id}
          scheduler={scheduler}
          onSelect={props.onSelectItem}
          selected={props.selectedSchedulerId === scheduler.id}
        />
      ))}
    </Paper>
  );
}
