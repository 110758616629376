import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { AddTestSuiteButton } from "../../../../_common/components/AddTestSuiteButton/AddTestSuiteButton";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.deepcrawlGreen.main,
    marginRight: theme.spacing(1)
  },
  home: {
    textAlign: "center"
  },
  integrate: {
    borderBottom: "2px var(--border-type) var(--white)",
    padding: "40px"
  },
  howItWorks: {
    padding: "40px"
  },
  link: {
    textDecoration: "none"
  },
  title: {
    fontWeight: 600
  }
}));

export function HomeIntro(): JSX.Element {
  const classes = useStyles();
  const accountId = useAccountRouteMatch();

  return (
    <>
      <section className={classes.home} data-testid="home-intro">
        <div className={classes.integrate}>
          <h1 className={classes.title}>Integrate DeepCrawl into your CI/CD Pipeline</h1>
          <div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <CheckCircleIcon className={classes.icon} />
              <h3 className={classes.title}>Protect your site from SEO regressions</h3>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
              <CheckCircleIcon className={classes.icon} />
              <h3 className={classes.title}>Automate your SEO Testing</h3>
            </Box>
            <AddTestSuiteButton
              accountId={accountId}
              dataCy="btn-add-test-suite"
              dataTestId="home-intro-add-test-suite"
              data-pendo="auto-testsuitelist-add-test-suite-button"
            />
          </div>
        </div>
      </section>
    </>
  );
}
