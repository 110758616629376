import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getJiraAuthentications, getJiraAuthenticationsVariables } from "../../../../../generated-graphql-interfaces";

export const getJiraAuthenticationsGql = gql`
  query getJiraAuthentications($accountId: ObjectID!) {
    getAccount(id: $accountId) {
      id
      jiraAuthentications {
        nodes {
          id
          name
          jiraUrl
        }
      }
    }
  }
`;

export function useGetJiraAuthenticationsQuery(variables: getJiraAuthenticationsVariables) {
  return useQuery<getJiraAuthentications>(getJiraAuthenticationsGql, { variables, fetchPolicy: "cache-and-network" });
}
