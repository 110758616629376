import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getUserWithTerms } from "../../../../../generated-graphql-interfaces";

export const getUserWithTermsGql = gql`
  query getUserWithTerms {
    me {
      id
      termsAgreed
    }
  }
`;

export function useGetUserWithTermsQuery() {
  return useQuery<getUserWithTerms>(getUserWithTermsGql);
}
