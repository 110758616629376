import { useQuery } from "@apollo/react-hooks";
import { useState } from "react";
import {
  getBuildScheduleWithTestSuite,
  getBuildScheduleWithTestSuiteVariables,
  getBuildScheduleWithTestSuite_node_BuildSchedule
} from "../../../../generated-graphql-interfaces";
import { getBuildScheduleWithTestSuiteGql } from "./getBuildScheduleWithTestSuiteGql";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../../_common/utils/mergePageInfo/mergePageInfo";

export function useGetBuildScheduleWithTestSuitesQuery(variables: getBuildScheduleWithTestSuiteVariables) {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery<
    getBuildScheduleWithTestSuite,
    getBuildScheduleWithTestSuiteVariables
  >(getBuildScheduleWithTestSuiteGql, { variables, fetchPolicy: "network-only" });

  function loadMore() {
    setIsFetchingMore(true);

    const newVariables = {
      ...variables,
      cursor: (data?.node as getBuildScheduleWithTestSuite_node_BuildSchedule).buildScheduleTestSuites.pageInfo
        .endCursor
    };

    fetchMore({
      variables: newVariables,
      updateQuery: (previousResult: getBuildScheduleWithTestSuite, { fetchMoreResult }) => {
        if (fetchMoreResult?.node?.__typename === "BuildSchedule" && fetchMoreResult.node.buildScheduleTestSuites) {
          return merge(previousResult, fetchMoreResult, defaultMergeOptions);
        }
        return previousResult;
      }
    }).finally(() => {
      setIsFetchingMore(false);
    });
  }

  return {
    data,
    error,
    loading,
    isFetchingMore,
    loadMore,
    refetch
  };
}
