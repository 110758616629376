import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { ResponsiveTabsConfig } from "./ResponsiveTabs";

const useStyles = makeStyles(theme => ({
  box: {
    marginBottom: "14px",
    display: "flex",
    padding: theme.spacing(0.9),
    maxWidth: "220px",
    background: "white",
    borderRadius: "30px",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.navy[50]
    },
    color: theme.palette.navy[500],
    transition: "all 0.2s ease",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "inherit",
      padding: theme.spacing(2, 3.5, 2, 3.5),
      justifyContent: "space-between"
    }
  },
  icon: {
    paddingTop: "2px",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),

    "& svg": {
      color: theme.palette.navy[500],
      fontSize: "16px"
    },

    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  selectedBox: {
    background: theme.palette.primary.main,
    color: "#FFF",
    "& svg": {
      color: "#FFF",
      fontSize: "16px"
    },
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  intoIcon: {
    color: theme.palette.navy[200],
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  wrapper: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  }
}));

interface ResponsiveTabsMenuProps {
  config: ResponsiveTabsConfig[];
  setSelectedTabId: (selectedTabId: string) => void;
  selectedTabId: string | null;
}

export function ResponsiveTabsMenu(props: ResponsiveTabsMenuProps) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {props.config.map(tabItem => {
        return (
          <Box
            className={clsx(classes.box, tabItem.id === props.selectedTabId && classes.selectedBox)}
            key={tabItem.label}
            data-testid={`${tabItem.id}-tab`}
            data-pendo={`auto-${tabItem.id}-tab`}
            data-cy={`${tabItem.id}-tab`}
            onClick={() => props.setSelectedTabId(tabItem.id)}
          >
            <Box className={classes.icon}>{tabItem.icon}</Box>
            <Box>{tabItem.label}</Box>

            <ChevronRightRoundedIcon className={classes.intoIcon} />
          </Box>
        );
      })}
    </Box>
  );
}
