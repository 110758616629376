import { getIdFromEncodedString } from "../getIdFromEncodedString/getIdFromEncodedString";
import { WindowWithPendo } from "./pendo.types";

export function setAccountIdInPendo(encodedAccountId: string): void {
  const accountId = getIdFromEncodedString(encodedAccountId, "Account");
  const pendo = (window as WindowWithPendo).pendo;

  if (pendo && pendo.set_account_id && accountId) {
    pendo.set_account_id(accountId);
  }
}
