import React from "react";
import { Button, Box, makeStyles, Typography } from "@material-ui/core";
import {
  generateEmailTextAndCopyToClipboard,
  automatorSdkUrl,
  generateEmailTextForCiCdPipelineAsHTML
} from "./CiCdFormUtils";
import { CopyBlock } from "../CopyBlock/CopyBlock";
import { DeepcrawlInfoTooltip } from "../DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { useSnackbar } from "notistack";
import { CopyIcon } from "../../svg/CopyIcon";

export interface CiCdProps {
  testSuiteId: string;
  testSuiteName: string;
  testSuiteSitePrimary: string;
}

const useStyles = makeStyles(theme => ({
  ciCdWrapper: {
    borderColor: theme.palette.grey[400]
  },
  httpsWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  iconBox: {
    borderRadius: 4,
    marginLeft: theme.spacing(1.5),
    height: "42px",
    width: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F4F4F4",
    "&:hover": {
      "& svg": {
        color: theme.palette.secondary.main
      }
    }
  },
  icon: {
    background: "none"
  }
}));

export function CiCdForm(props: CiCdProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const informationText = generateEmailTextForCiCdPipelineAsHTML(
    props.testSuiteName,
    props.testSuiteSitePrimary,
    props.testSuiteId
  );

  return (
    <Box border={1} borderRadius={4} p={3} data-testid="ci-cd-instructions" className={classes.ciCdWrapper}>
      <Box mb={3}>
        <Typography color="textPrimary">Instructions for CI/CD admin:</Typography>
        <Box component="span" display="block">
          <a
            href={automatorSdkUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-pendo="auto-ci-cd-instructions-admin"
          >
            <span className={classes.httpsWrapper}>https://</span>
            github.com/deepcrawl/automator-sdk
          </a>
        </Box>
      </Box>
      <Box>
        <CopyBlock label="Test Suite ID" text={props.testSuiteId} copyMessage="Test Suite ID copied to clipboard" />
      </Box>

      <Box display="flex" mt={6} mb={4}>
        <Button
          onClick={() => {
            generateEmailTextAndCopyToClipboard(props.testSuiteName, props.testSuiteSitePrimary, props.testSuiteId);
            enqueueSnackbar("Instruction information copied to clipboard.", { variant: "success" });
          }}
          variant="outlined"
          color="primary"
          data-testid="cicd-form-copy-button"
          data-pendo="auto-ci-cd-form-instructions-copy"
          startIcon={<CopyIcon />}
        >
          Copy instructions
        </Button>
        <DeepcrawlInfoTooltip
          testId="show-intergration-details"
          title="Instructions for CI CD"
          data-pendo="auto-tooltip-ci-cd-form"
          body={<span dangerouslySetInnerHTML={{ __html: informationText }}></span>}
          maxWidth={450}
          iconComponent={
            <Box className={classes.iconBox} data-pendo="auto-ci-cd-form-instructions-view">
              <VisibilityRoundedIcon className={classes.icon} />
            </Box>
          }
        />
      </Box>
    </Box>
  );
}
