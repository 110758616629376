import React from "react";
import { makeStyles, Box, Typography, TooltipClassKey } from "@material-ui/core";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { DeepcrawlTooltip } from "../DeepcrawlTooltip/DeepcrawlTooltip";
import clsx from "clsx";
import { PendoTagProps } from "../../../typings/pendo.prototype";

const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main
  },
  tooltipTitle: {
    color: "#202020",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  tooltipText: {
    color: "#202020",
    fontSize: "0.875rem",
    "& .ul": {
      paddingTop: theme.spacing(1),
      display: "block",
      "& .li": {
        display: "block"
      }
    }
  },
  missingTitle: {
    marginTop: "2px"
  }
}));

export interface DeepcrawlInfoTooltipProps extends PendoTagProps {
  title?: string;
  body: string | JSX.Element;
  testId?: string;
  cyId?: string;
  size?: number;
  maxWidth?: number;
  toolTipClasses?: Partial<Record<TooltipClassKey, string>>;
  iconComponent?: JSX.Element;
  variant?: "white";
}

export function DeepcrawlInfoTooltip(props: DeepcrawlInfoTooltipProps) {
  const classes = useStyles();
  const { title, body, ...rest } = props;

  return (
    <DeepcrawlTooltip
      iconComponent={props.iconComponent}
      content={
        <Box display="flex" p={1}>
          <Box>
            <InfoRoundedIcon className={classes.tooltipIcon} />
          </Box>
          <Box>
            {title && (
              <Typography variant="h5" className={classes.tooltipTitle} data-testid="tooltip-info-title">
                {title}
              </Typography>
            )}
            <Typography
              variant="body1"
              className={clsx(classes.tooltipText, !title && classes.missingTitle)}
              data-testid="tooltip-info-body"
            >
              {body}
            </Typography>
          </Box>
        </Box>
      }
      {...rest}
    />
  );
}
