import React from "react";
import { Alert } from "@material-ui/lab";

export function TestSuiteListItemWarning() {
  return (
    <Alert data-testid="clone-test-warning" severity="warning">
      We didn&apos;t copy over any URL list files. Currently set to web crawl.
    </Alert>
  );
}
