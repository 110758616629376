import gql from "graphql-tag";

export const getBuildScheduleWithTestSuiteGql = gql`
  query getBuildScheduleWithTestSuite($buildScheduleId: ObjectID!, $itemsPerRequest: Int!, $cursor: String!) {
    node(id: $buildScheduleId) {
      ... on BuildSchedule {
        id
        name
        repetitionRate
        startAt
        nextRunAt
        buildScheduleTestSuites(first: $itemsPerRequest, after: $cursor, orderBy: { field: id, direction: DESC }) {
          totalCount
          pageInfo {
            endCursor
          }
          nodes {
            lastRunBuild {
              id
              status
              finishedAt
              ciBuildId
              updatedAt
              passed
              failedTestCount
              passedTestCount
              warnedTestCount
            }
            id
            testSuite {
              name
              id
              sitePrimary
              updatedAt
              clonedAt
              tests(last: 100) {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;
