import React, { useState } from "react";
import { PendoTagProps } from "../../../typings/pendo.prototype";
import { defaultPopOverId } from "../../constants/popover";
import { DeleteIcon } from "../../svg/DeleteIcon";
import { ActionIconButton } from "../ActionIconButton/ActionIconButton";
import PopoverDialog, { PopoverDialogButton, PopoverDialogDefaultIdentifiers } from "../PopoverDialog/PopoverDialog";

export interface DeleteButtonWithConfirmationProps extends PendoTagProps {
  handleConfirmation: () => void;
  tooltipTitle: string;
  confirmationTitle: string;
  confirmationText: string;
  dataTestId: string;
  dataCy?: string;
  disableForViewer?: boolean;
  disabled?: boolean;
  deleteButtonLabel?: string;
}

export function DeleteButtonWithConfirmation(props: DeleteButtonWithConfirmationProps) {
  const { dataTestId, dataCy, confirmationTitle, confirmationText, ...rest } = props;
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(popoverAnchorElement);
  const popoverId = isPopoverOpen ? defaultPopOverId : undefined;

  const popoverButtons: PopoverDialogButton[] = [
    {
      label: "Cancel",
      identifier: PopoverDialogDefaultIdentifiers.CANCEL,
      color: "primary"
    },
    {
      label: props.deleteButtonLabel || "Delete",
      identifier: PopoverDialogDefaultIdentifiers.OK,
      color: "alert"
    }
  ];

  function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    setPopoverAnchorElement(event.currentTarget);
  }

  function handleAction(id: string) {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      props.handleConfirmation();
    }
  }

  return (
    <>
      <ActionIconButton
        data-testid={dataTestId}
        onClick={handleDelete}
        icon={<DeleteIcon />}
        includeBorder={true}
        variant="error"
        isActive={isPopoverOpen}
        data-cy={dataCy}
        {...rest}
      />
      <PopoverDialog
        anchorElement={popoverAnchorElement}
        handleAction={handleAction}
        open={isPopoverOpen}
        title={confirmationTitle}
        text={confirmationText}
        buttons={popoverButtons}
        id={popoverId}
      />
    </>
  );
}
