import Rollbar from "rollbar";

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT
  }
};
