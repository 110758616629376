import React from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { Field } from "formik";
import { CustomCheckbox } from "./CustomCheckbox";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

export function RobotsOverwrite() {
  const classes = useCrawlSettingsStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Box mb={4} mt={7} display="flex" alignItems="center">
          <b className={classes.tooltipTitle}>Robots.txt Overwrite</b>
          <DeepcrawlInfoTooltip
            maxWidth={400}
            body={crawlSettingsTooltips.robotsOverwrite}
            data-pendo="auto-tooltip-robots-override"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2} data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-robots-enable">
          <Field
            name="useRobotsOverwrite"
            id="use-robots-overwrite"
            data-testid="use-robots-overwrite"
            component={CustomCheckbox}
          />
          <InputLabel htmlFor="use-robots-overwrite" className={classes.label}>
            Use Robots.txt Overwrite
          </InputLabel>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          component={TextField}
          multiline={true}
          rows="4"
          name="robotsOverwrite"
          id="robots-overwrite"
          data-testid="robots-overwrite-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-robots-override"
          label="Enter Robots Overwrite"
          variant="filled"
          type="text"
          placeholder="User-Agent: *&#10;Allow: /&#10;Disallow: /blog"
        />
      </Grid>
    </Grid>
  );
}
