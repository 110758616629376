import { onError } from "apollo-link-error";
import { errorsSubscription, ServerError } from "../errors-subscription";
import { getFullListOfMessagesFromError } from "./errorLinkUtils";
import { ServerError as ApolloServerError, ServerParseError } from "apollo-link-http-common";
import { GraphQLError } from "graphql";

export function errorHandler({
  graphQLErrors,
  networkError
}: {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  networkError?: Error | ApolloServerError | ServerParseError;
}) {
  const errorWithStatusCode =
    networkError && "statusCode" in networkError
      ? { message: networkError.message, statusCode: networkError.statusCode }
      : undefined;

  const graphErrors = graphQLErrors ? getFullListOfMessagesFromError(graphQLErrors).map(e => ({ message: e })) : [];

  const errorsToSend: ServerError[] = errorWithStatusCode ? [...graphErrors, errorWithStatusCode] : graphErrors;

  if (errorsToSend.length) {
    errorsSubscription.sendErrors(errorsToSend);
  }
}

export const errorLink = onError(errorHandler);
