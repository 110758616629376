import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Field, FieldAttributes } from "formik";

const useStyles = makeStyles(theme => ({
  input: {
    "& .MuiInputLabel-outlined": {
      fontSize: "1.25rem"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      fontSize: "1rem"
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: theme.palette.secondary.main
      }
    },
    "& .MuiOutlinedInput-root.Mui-focused.Mui-error": {
      "& fieldset": {
        borderColor: theme.palette.error.main
      }
    },
    "& fieldset": {
      borderRadius: 4
    },
    "& input": {
      lineHeight: 1,
      fontSize: "1.25rem",
      "&::placeholder": {
        color: theme.palette.navy[400]
      }
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.secondary.main
      }
    }
  }
}));

export function MainInputField(props: FieldAttributes<any>) {
  const classes = useStyles(props);
  return <Field {...props} component={TextField} type="text" variant="outlined" className={classes.input} />;
}
