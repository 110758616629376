import React from "react";
import {
  getTestSuite,
  getTestSuiteVariables,
  getTestSuite_node_TestSuite
} from "../../../../generated-graphql-interfaces";
import { ResponsiveTabs, ResponsiveTabsConfig } from "../../../../_common/components/ResposiveTabs/ResponsiveTabs";
import { ConnectViaCiCd } from "./ConnectViaCiCd";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../../_common/routes/routes";
import { FloatingStepSave } from "../FloatingStepSave/FoatingStepSave";
import AllInclusiveRoundedIcon from "@material-ui/icons/AllInclusiveRounded";
import { RunTestSuiteNow } from "./components/RunTestSuiteNow";
import { ApolloQueryResult } from "apollo-client";
import { PlayFilledIcon } from "../../../../_common/svg/PlayFilledIcon";

export interface ConnectCiCdToolProps {
  testSuite: getTestSuite_node_TestSuite;
  refetchTestSuite: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
}

export function ConnectCiCdTool({ testSuite, refetchTestSuite }: ConnectCiCdToolProps) {
  const history = useHistory();
  const accountId = useAccountRouteMatch();

  function handleSubmit() {
    history.push(Routes.TestSuites.getUrl({ accountId }));
  }

  const tabs: ResponsiveTabsConfig[] = [
    {
      icon: <AllInclusiveRoundedIcon />,
      label: "Connect via CI/CD",
      id: "connect-via-ci-cd",
      content: (
        <ConnectViaCiCd
          testSuiteId={testSuite.id}
          testSuiteName={testSuite.name}
          testSuiteSitePrimary={testSuite.sitePrimary}
        />
      )
    },
    {
      icon: <PlayFilledIcon />,
      label: "Run now",
      id: "run-now",
      content: <RunTestSuiteNow refetchTestSuite={refetchTestSuite} testSuiteId={testSuite.id} />
    }
  ];

  return (
    <Box data-cy="connect-cicd-content">
      <Box minHeight={600}>
        <ResponsiveTabs config={tabs} label="Run test suite" />
      </Box>
      <FloatingStepSave
        dataCy="connect-cicd-save-button"
        dataTestId="connect-cicd-save-button"
        data-pendo="auto-testsuite-edit-add-connect-cicd-save-step"
        label="Go to test suites"
        onClick={handleSubmit}
      />
    </Box>
  );
}
