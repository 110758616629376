import { FilledInputClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";
import { colorPurple } from "../palette";

export const muiFilledInput: Partial<
  Record<FilledInputClassKey, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
> = {
  root: {
    backgroundColor: "#EFF0F1",
    "&:hover": {
      backgroundColor: "#E9E9E9"
    }
  },
  underline: {
    "&:hover": {
      "&:before": {
        borderBottom: `1px solid ${colorPurple[500]}`
      }
    },
    "&:before": {
      borderBottom: "1px solid #E2E2E2"
    },
    "&:after": {
      borderBottom: `2px solid ${colorPurple[500]}`
    },
    "&$error": {
      "&:after": {
        borderBottomColor: "#CB7E7C"
      }
    },
    "&$disabled": {
      "&:hover": {
        "&:before": {
          borderBottom: "1px solid #E2E2E2"
        }
      }
    }
  },
  input: {
    padding: "26px 16px 8px"
  },
  multiline: {
    padding: "26px 16px 8px"
  }
};
