import React, { ChangeEvent } from "react";
import { Grid, Select, Fade, MenuItem, TextField, FormControl, InputLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { startOfDay, endOfDay } from "date-fns";
import { DurationSelection, durationOptions } from "./FailPassWarnChartUtils";
import { useStyles } from "./FailPassWarnChartStyles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isAfter, isBefore } from "date-fns";
import { Autocomplete } from "@material-ui/lab";

import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { getMenuProps } from "../../../_common/visualOverrides/selectVisualOverrides";
import {
  FormHelperTextStyled,
  FormHelperTextStyledType
} from "../../../_common/components/FormHelperTextStyled/FormHelperTextStyled";

export interface FailPassWarnChartFilterSettings {
  testSuiteId: string | undefined;
  durationSelection: DurationSelection;
  customDuration: {
    start: Date | null;
    end: Date | null;
  };
  show: {
    passes: boolean;
    fails: boolean;
    warnings: boolean;
  };
}

export interface FailPassWarnChartFilterProps {
  availableTestSuites: { id: string; name: string }[];
  settings: FailPassWarnChartFilterSettings;
  onSettingsChange: (newSettings: FailPassWarnChartFilterSettings) => void;
  errorMessageForcedOnDatePickers: string | boolean;
}

// eslint-disable-next-line max-lines-per-function, max-statements
export function FailPassWarnChartFilter(props: FailPassWarnChartFilterProps): JSX.Element {
  const classes = useStyles();

  function handleCustomDateStartChange(date: MaterialUiPickersDate) {
    if (date && props.settings.customDuration.end && isAfter(startOfDay(date), props.settings.customDuration.end)) {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: startOfDay(date),
          end: endOfDay(date)
        }
      });
    } else if (date) {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: startOfDay(date),
          end: props.settings.customDuration.end
        }
      });
    } else {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: date,
          end: props.settings.customDuration.end
        }
      });
    }
  }

  function handleAutocompleteChange(event: ChangeEvent<{}>, newOption: { id: string; name: string } | null) {
    if (newOption) {
      props.onSettingsChange({
        ...props.settings,
        testSuiteId: newOption.id
      });
    }
  }

  function handleCustomDateEndChange(date: MaterialUiPickersDate) {
    if (date && props.settings.customDuration.start && isBefore(date, props.settings.customDuration.start)) {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: startOfDay(date),
          end: endOfDay(date)
        }
      });
    } else if (date) {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: props.settings.customDuration.start,
          end: endOfDay(date)
        }
      });
    } else {
      props.onSettingsChange({
        ...props.settings,
        customDuration: {
          start: props.settings.customDuration.start,
          end: date
        }
      });
    }
  }
  // eslint-disable-next-line complexity
  function onDurationChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    if (
      event.target.value === DurationSelection.CUSTOM_SELECTION ||
      event.target.value === DurationSelection.LAST_30_DAYS ||
      event.target.value === DurationSelection.LAST_7_DAYS ||
      event.target.value === DurationSelection.LATEST_RUNS
    ) {
      props.onSettingsChange({
        ...props.settings,
        durationSelection: event.target.value
      });
    }
  }

  return (
    <Grid container spacing={2} className={classes.selectsGrid} data-testid="fail-pass-warn-chart-filter">
      <Grid item>
        <Autocomplete
          style={{ minWidth: 330 }}
          openOnFocus
          popupIcon={<ExpandMoreRoundedIcon />}
          value={props.availableTestSuites.filter(opt => opt.id === props.settings.testSuiteId)[0]}
          id="combo-box-demo"
          options={props.availableTestSuites}
          getOptionLabel={suite => suite.name}
          onChange={handleAutocompleteChange}
          getOptionSelected={(option, value) => {
            return option && value && option.id === value.id;
          }}
          data-testid="filter-select-test-suite"
          data-pendo="auto-dashboard-widget-fails-warnings-select-test-suite"
          renderInput={params => <TextField {...params} label="Test suite" variant="outlined" />}
        />
      </Grid>
      <Grid item>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Date range</InputLabel>
          <Select
            name="minutes"
            IconComponent={ExpandMoreRoundedIcon}
            labelId="filter-select-duration"
            id="filter-select-duration"
            data-testid="filter-select-duration"
            data-pendo="auto-dashboard-widget-fails-warnings-select-data-range"
            aria-describedby="filter-select-duration"
            value={props.settings.durationSelection}
            onChange={onDurationChange}
            label="Date range"
            className={classes.selectMinutes}
            classes={{ select: classes.select }}
            MenuProps={getMenuProps({ paper: classes.menuPaper })}
          >
            {durationOptions.map(option => (
              <MenuItem key={option.id} value={option.id} data-cy={`duration-select-option-${option.id}`}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Fade
        in={props.settings.durationSelection === DurationSelection.CUSTOM_SELECTION}
        data-testid="filter-select-custom-duration-fade"
      >
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                format="dd MM yyyy"
                margin="normal"
                id="date-picker-inline-start"
                label="From"
                inputVariant="outlined"
                error={!!props.errorMessageForcedOnDatePickers}
                helperText={null}
                maxDate={props.settings.customDuration.end}
                maxDateMessage={"Selected date is after end date"}
                value={props.settings.customDuration.start}
                onChange={handleCustomDateStartChange}
                KeyboardButtonProps={{
                  "aria-label": "change starting date"
                }}
                data-testid="filter-select-custom-duration-start"
                data-pendo="auto-dashboard-widget-fails-warnings-select-data-range-custom-start"
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                className={classes.datePicker}
                variant="inline"
                format="dd MM yyyy"
                margin="normal"
                id="date-picker-inline-end"
                label="To"
                error={!!props.errorMessageForcedOnDatePickers}
                helperText={null}
                minDate={props.settings.customDuration.start}
                minDateMessage={"Selected date is before start date"}
                value={props.settings.customDuration.end}
                onChange={handleCustomDateEndChange}
                KeyboardButtonProps={{
                  "aria-label": "change ending date"
                }}
                data-testid="filter-select-custom-duration-end"
                data-pendo="auto-dashboard-widget-fails-warnings-select-data-range-custom-end"
              />
            </Grid>
          </Grid>
          <Grid item>
            <FormHelperTextStyled
              testId="select-schedule-date-error"
              id="select-schedule-date"
              text={props.errorMessageForcedOnDatePickers}
              show={!!props.errorMessageForcedOnDatePickers}
              type={FormHelperTextStyledType.ERROR}
            />
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );
}
