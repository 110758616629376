import { makeStyles } from "@material-ui/core";

export const useStylesItemGrid = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 3, 3, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 1, 3, 0)
    }
  },
  mainGrid: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  textWrapper: {
    alignItems: "center",
    flexGrow: 0,
    maxWidth: `calc(100% - ${76 + 40 + theme.spacing(2)}px)`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: `calc(100% - ${46 + 40 + theme.spacing(2)}px)`
    }
  },
  iconWrapper: {
    width: "76px",
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "46px"
    }
  },
  actionsMenuWrapper: {
    width: `${40 + theme.spacing(2)}px`
  },

  itemInfoWrapper: {
    paddingRight: theme.spacing(1)
  },
  chipsWrapper: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2)
    }
  },
  dateTimeWrapper: {
    textAlign: "left"
  },
  rootBorder: {
    borderLeft: `6px solid transparent`
  }
}));
