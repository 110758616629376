import React from "react";
import { Grid, Box } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

export function StartUrls() {
  const classes = useCrawlSettingsStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Box mt={7} mb={4}>
          <Box mb={4} display="flex" alignItems="center">
            <b className={classes.tooltipTitle}>Start URLs</b>
            <DeepcrawlInfoTooltip
              data-pendo="auto-tooltip-start-override"
              testId="starts-url-tooltip"
              maxWidth={400}
              body="A crawl will start from your primary domain by default. If you need the crawl to start from a different point, specify it below."
            />
          </Box>
          <Field
            fullWidth
            component={TextField}
            name="startUrls"
            id="start-urls"
            data-testid="start-urls-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-start-urls"
            type="text"
            label="Start URLs"
            variant="filled"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
