import React, { useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { ConnectSiteForm } from "./ConnectSiteForm";
import { useCreateTestSuiteMutation } from "./graphql/useCreateTestSuiteMutation";
import { TestSuiteVariables } from "./TestSuiteVariables";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../../_common/routes/routes";

export const CreateTestSuite = withRouter(function CreateTestSuite() {
  const [testSuiteId, setTestSuiteId] = useState<string | null>(null);
  const [createTestSuite] = useCreateTestSuiteMutation();
  const accountId = useAccountRouteMatch();

  return testSuiteId ? (
    <Redirect to={Routes.EditTestSuite.getUrl({ accountId, testSuiteId })} />
  ) : (
    <ConnectSiteForm
      onSubmit={async (testSuiteVariables: TestSuiteVariables) => {
        const data = await createTestSuite({
          variables: {
            ...testSuiteVariables,
            accountId,
            automaticThreshold: false,
            automaticThresholdEnabledForAll: true
          }
        });

        if (data && data.data) {
          setTestSuiteId(data.data.createTestSuite.testSuite.id);
        }
      }}
    />
  );
});
