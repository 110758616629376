import { format, differenceInYears, differenceInMilliseconds } from "date-fns";
import * as d3Scale from "d3-scale";
import { LineChartLineData } from "../components/LineChart";
import { getUniqueStringsFromArray } from "../../../_common/utils/getUniqueStringsFromArray/getUniqueStringsFromArray";

export function getProcessedChartData(data: LineChartLineData[]): LineChartLineData[] {
  const processedData: LineChartLineData[] = data.map(lineDataObj => {
    const dataWithTimes = lineDataObj.data.map(datum => ({
      ...datum,
      x: format(new Date(datum.x), "yyyy-MM-dd HH:mm").toString()
    }));
    return { ...lineDataObj, data: dataWithTimes };
  });
  return processedData;
}

export function getLabelFromId(pointId: string | number): string {
  if (typeof pointId === "number") return "items";
  return pointId.toString().split(".")[0].toLowerCase();
}

export function getMinMax(data: LineChartLineData[]): number[] {
  const yValues = data.flatMap(datum => {
    return datum.data.flatMap(d => {
      return d.y;
    });
  });
  if (!yValues.length) {
    return [];
  }
  const filteredYValues = yValues.filter(value => value !== null) as number[];
  return [0, Math.max(...filteredYValues)];
}

export function getD3Ticks(minMax: number[], ticksCount: number) {
  if (minMax[0] !== undefined && minMax[1] !== undefined) {
    const scale = d3Scale.scaleLinear().domain(minMax).nice();

    const tickFormat = scale.tickFormat(ticksCount, "d");
    const ticks = scale.ticks(ticksCount);
    const mappedTicks = ticks.map(tickFormat);
    return mappedTicks.filter(getUniqueStringsFromArray).filter(str => str); // ensure strings are non-empty
  }
  return [];
}

function getMinDateFormat(dataToDisplay: LineChartLineData[]) {
  return Math.min(
    ...dataToDisplay.flatMap(data => {
      const dates: number[] = data.data.map(d => new Date(d.x).getTime());
      const min = Math.min(...dates);
      return min;
    })
  );
}

function getMaxDateFormat(dataToDisplay: LineChartLineData[]) {
  return Math.max(
    ...dataToDisplay.flatMap(data => {
      const dates: number[] = data.data.map(d => new Date(d.x).getTime());
      const max = Math.max(...dates);
      return max;
    })
  );
}

export function getAxisXDateFormat(dataToDisplay: LineChartLineData[]) {
  const minDateN = getMinDateFormat(dataToDisplay);
  const maxDateN = getMaxDateFormat(dataToDisplay);
  return Math.abs(differenceInYears(new Date(minDateN), new Date(maxDateN))) >= 1 ? "%d %b %Y" : "%d %b";
}

export function getD3TimeTicks(data: LineChartLineData[], ticksCount?: number) {
  const ticktoUse = ticksCount && ticksCount >= 2 ? ticksCount : 10;
  const minDateN = getMinDateFormat(data);
  const maxDateN = getMaxDateFormat(data);
  const diff = differenceInMilliseconds(maxDateN, minDateN);
  if (diff === 0) {
    return [new Date(minDateN)];
  }
  const tickJumpInMs = diff / (ticktoUse - 1);
  return Array.from(Array(ticktoUse).keys()).map(value => {
    return new Date(minDateN + value * tickJumpInMs);
  });
}
