import React from "react";
import { Select } from "formik-material-ui";
import { Field } from "formik";
import { InputLabel, MenuItem, Box, FormControl } from "@material-ui/core";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

export function UrlLimit() {
  const classes = useCrawlSettingsStyles();

  return (
    <Box mb={7}>
      <Box display="flex" alignItems="center" mb={4}>
        <b className={classes.tooltipTitle}>URL Limit</b>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-url-limit"
          maxWidth={400}
          body="Crawling more URLs will provide you with a greater level of accuracy, but it will take longer for the test to complete."
        />
      </Box>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor="url-limit">URL Limit</InputLabel>
        <Field name="limitPagesMax" component={Select} id="url-limit" data-cy="url-limit-dropdown">
          <MenuItem value={500}>Up to 500 URLs</MenuItem>
          <MenuItem data-cy="one-thousand-urls-select" value={1000}>
            Up to 1,000 URLs
          </MenuItem>
          <MenuItem value={3000}>Up to 3,000 URLs</MenuItem>
          <MenuItem value={5000}>Up to 5,000 URLs</MenuItem>
          <MenuItem value={10000}>Up to 10,000 URLs</MenuItem>
        </Field>
      </FormControl>
    </Box>
  );
}
