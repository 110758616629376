import React from "react";
import { SvgIconProps, SvgIcon } from "@material-ui/core";

export function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4766 24.248C19.104 24.248 24.4766 18.8755 24.4766 12.248C24.4766 5.62063 19.104 0.248047 12.4766 0.248047C5.84915 0.248047 0.476562 5.62063 0.476562 12.248C0.476562 18.8755 5.84915 24.248 12.4766 24.248ZM12.4766 22.248C17.9994 22.248 22.4766 17.7709 22.4766 12.248C22.4766 6.7252 17.9994 2.24805 12.4766 2.24805C6.95371 2.24805 2.47656 6.7252 2.47656 12.248C2.47656 17.7709 6.95371 22.248 12.4766 22.248Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4761 11.4669L8.5248 7.51562L7.67627 8.36415L11.6275 12.3154L7.80937 16.1336L8.6579 16.9821L12.4761 13.164L16.1616 16.8494L17.0101 16.0009L13.3246 12.3154L17.1432 8.49684L16.2946 7.64831L12.4761 11.4669Z"
        fill="#09DCFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2.39771"
          y1="19.6453"
          x2="20.726"
          y2="21.1731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="1" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
