import React from "react";
import { FormGroup, FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { useStyles } from "./FailPassWarnChartStyles";
import { FailPassWarnChartFilterSettings } from "./FailPassWarnChartFilter";
import { deepCrawlTheme } from "../../../theme/deepCrawlTheme";
import { shouldUpdateVisibility } from "./FailPassWarnChartFilterSwitchesUtils";

export interface FailPassWarnChartFilterSwithchesProps {
  settings: FailPassWarnChartFilterSettings;
  onSettingsChange: (newSettings: FailPassWarnChartFilterSettings) => void;
}

export enum TestSuiteShowType {
  PASS = "pass",
  FAIL = "fail",
  WARN = "warn"
}

const FailsSwitch = withStyles(theme => ({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white"
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.error.main,
      opacity: 1
    }
  },
  checked: {},
  track: { backgroundColor: "rgba(0, 0, 0, 0.54)", opacity: 1 }
}))(Switch);

const WarningSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white"
    },
    "&$checked + $track": {
      backgroundColor: deepCrawlTheme.palette.deepcrawlOrange.main,
      opacity: 1
    }
  },
  checked: {},
  track: { backgroundColor: "rgba(0, 0, 0, 0.54)", opacity: 1 }
})(Switch);

const PassSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white"
    },
    "&$checked + $track": {
      backgroundColor: "#5F7E2B",
      opacity: 1
    }
  },
  checked: {},
  track: { backgroundColor: "rgba(0, 0, 0, 0.54)", opacity: 1 }
})(Switch);

// eslint-disable-next-line max-lines-per-function, max-statements
export function FailPassWarnChartFilterSwithches(props: FailPassWarnChartFilterSwithchesProps): JSX.Element {
  const classes = useStyles();

  function handleVisibilityChange(selectedId: TestSuiteShowType, checked: boolean) {
    if (shouldUpdateVisibility(selectedId, checked, props.settings)) {
      props.onSettingsChange({
        ...props.settings,
        show: {
          fails: selectedId === TestSuiteShowType.FAIL ? checked : props.settings.show.fails,
          passes: selectedId === TestSuiteShowType.PASS ? checked : props.settings.show.passes,
          warnings: selectedId === TestSuiteShowType.WARN ? checked : props.settings.show.warnings
        }
      });
    }
  }

  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-fails"
        control={
          <FailsSwitch
            checked={props.settings.show.fails}
            onChange={(event, value) => handleVisibilityChange(TestSuiteShowType.FAIL, value)}
            name="fails"
            data-testid="fails-switch"
          />
        }
        label="Fails"
      />
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-warnings"
        control={
          <WarningSwitch
            checked={props.settings.show.warnings}
            onChange={(event, value) => handleVisibilityChange(TestSuiteShowType.WARN, value)}
            name="warnings"
            data-testid="warnings-switch"
          />
        }
        label="Warnings"
      />
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-passes"
        control={
          <PassSwitch
            checked={props.settings.show.passes}
            onChange={(event, value) => handleVisibilityChange(TestSuiteShowType.PASS, value)}
            name="passes"
            data-testid="passes-switch"
          />
        }
        label="Passes"
      />
    </FormGroup>
  );
}
