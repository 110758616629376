import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { Severity } from "../../../generated-graphql-interfaces";
import clsx from "clsx";

interface TestSeverityButtonProps {
  handleClick: () => void;
  isActive: boolean;
  severityType: Severity;
}

const useStyles = makeStyles(theme => ({
  button: (props: TestSeverityButtonProps) => {
    const isFail = props.severityType === Severity.Fail;

    return {
      backgroundColor: "white",
      border: `2px solid ${theme.palette.grey[800]}`,
      padding: theme.spacing(3, 1.5),
      width: "100%",
      color: theme.palette.grey[800],
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2.5),
      marginRight: isFail ? 4 : 0,
      marginLeft: isFail ? 0 : 4,
      borderRadius: 0,
      height: "auto"
    };
  },
  activeButton: (props: TestSeverityButtonProps) => ({
    color: theme.palette.deepBlack.main,
    border: `2px solid ${props.severityType === Severity.Fail ? theme.palette.error.light : theme.palette.warning.main}`
  }),
  container: {
    textAlign: "left",
    lineHeight: 1.2
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    paddingBottom: theme.spacing(1)
  },
  text: {
    fontWeight: "normal"
  },
  icon: {
    fontSize: "35px"
  },
  activeIcon: (props: TestSeverityButtonProps) => ({
    color: props.severityType === Severity.Fail ? theme.palette.error.light : theme.palette.warning.main
  })
}));

// eslint-disable-next-line complexity
export function TestSeverityButton(props: TestSeverityButtonProps) {
  const isFail = props.severityType === Severity.Fail;
  const classes = useStyles(props);

  return (
    <Button
      onClick={props.handleClick}
      className={`${classes.button} ${props.isActive ? classes.activeButton + " cy-active" : ""}`}
      data-testid={`${props.severityType}-severity-btn`}
      data-cy={`${props.severityType}-severity-btn`}
      data-pendo={`auto-test-suite-edit-choose-tests-test-${props.severityType}`}
    >
      <Box display="flex" alignItems="center" className={classes.container}>
        <Box mr={1}>
          {isFail ? (
            <HighlightOffIcon className={clsx(classes.icon, props.isActive && classes.activeIcon)} />
          ) : (
            <WarningRoundedIcon className={clsx(classes.icon, props.isActive && classes.activeIcon)} />
          )}
        </Box>
        <Box>
          <Box display="block" className={classes.title} data-testid="severity-btn-title">
            {isFail ? "Fail" : "Warning"}
          </Box>
          <Box className={classes.text} data-testid="severity-btn-info">
            {isFail ? "Build is stopped" : "Build continues with warning"}
          </Box>
        </Box>
      </Box>
    </Button>
  );
}
