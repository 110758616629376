import { BuildInfo } from "../Dashboard";
import { getFinishedStatusFromBuild } from "./getFinishedStatusFromBuild";
import { BuildStatusFilter } from "../components/LatestTestRunsWidget";
import { BuildStatus } from "../../../generated-graphql-interfaces";

export function filterByBuildInfoStatus(build: BuildInfo, buildsToShow: string): boolean {
  const isBuildAbortedOrCancelled = build.status === BuildStatus.Aborted || build.status === BuildStatus.Cancelled;

  if (buildsToShow === BuildStatusFilter.All) {
    return !isBuildAbortedOrCancelled;
  }
  return getFinishedStatusFromBuild(build) === buildsToShow;
}
