import React from "react";
import { Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: "white",
      height: "100%",
      display: "block"
    },
    crawlItem: {
      color: "white",
      fontSize: "16px",
      lineHeight: "24px",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom: theme.spacing(3)
      }
    },
    iconWrapper: {
      width: "40px"
    },
    textWrapper: {
      width: "calc(100% - 60px)",
      maxWidth: 260,
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%"
      }
    }
  })
);

interface CheckedIconInformationProps {
  description: string;
}

export function CheckedIconInformation(props: CheckedIconInformationProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      className={classes.crawlItem}
      alignContent="flex-start"
      alignItems="flex-start"
      direction="row"
    >
      <Grid item className={classes.iconWrapper}>
        <CheckCircleIcon className={classes.icon} data-testid={"check-circle-icon"} />
      </Grid>

      <Grid item className={classes.textWrapper} data-testid={"check-text"}>
        {props.description}
      </Grid>
    </Grid>
  );
}
