import gql from "graphql-tag";

export const createJiraIntegrationGgl = gql`
  mutation createJiraIntegration(
    $name: String!
    $jiraAuthenticationId: ObjectID!
    $jiraProjectId: String!
    $jiraIssueTypeId: String!
  ) {
    createJiraIntegration(
      input: {
        name: $name
        jiraAuthenticationId: $jiraAuthenticationId
        jiraProjectId: $jiraProjectId
        jiraIssueTypeId: $jiraIssueTypeId
      }
    ) {
      jiraIntegration {
        id
      }
    }
  }
`;
