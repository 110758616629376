import gql from "graphql-tag";

export const deleteSlackWebhookGql = gql`
  mutation deleteSlackWebhook($slackWebhookId: ObjectID!) {
    deleteAutomatorSlackWebhook(input: { slackWebhookId: $slackWebhookId }) {
      slackWebhook {
        id
      }
    }
  }
`;
