import { useMutation } from "@apollo/react-hooks";
import { deleteTestSuite, deleteTestSuiteVariables } from "../../../generated-graphql-interfaces";
import { deleteTestsuiteMutation } from "./deleteTestsuiteMutation";

export function useDeleteTestSuiteMutation() {
  return useMutation<deleteTestSuite, deleteTestSuiteVariables>(deleteTestsuiteMutation, {
    refetchQueries: [
      "getNextTestSuites",
      "getPreviousTestSuites",
      "getJiraIntegrations",
      "getJiraIntegrationsWithTestSuites"
    ]
  });
}
