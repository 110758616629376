import React from "react";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import { useStyles } from "./StatusIconStyles";
import { ActionButtonTooltip } from "../ActionButtonTooltip/ActionButtonTooltip";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { Typography } from "@material-ui/core";

export enum StatusIconType {
  ALERT = "alert",
  WARNING = "warning",
  ERROR = "error",
  CHECK = "check",
  CANCELLED = "cancelled",
  LOADING = "loading",
  NA = "n/a",
  NOT_YET_RUN = "not_yet_run",
  SCHEDULED = "scheduled",
  QUEUED = "queued",
  ROUND_ERROR = "round_error"
}

export enum StatusIconSize {
  XL = "xl",
  LG = "lg",
  MD = "md",
  SM = "sm"
}

export interface StatusIconProps {
  type: StatusIconType;
  size?: StatusIconSize;
  text?: string;
  inline?: boolean;
  tooltipVariant?: boolean;
}

export function StatusIcon(props: StatusIconProps) {
  const classes = useStyles(props);

  function getIcon(): JSX.Element {
    switch (props.type) {
      case StatusIconType.CHECK:
        return <CheckCircleRoundedIcon className={classes.icon} />;
      case StatusIconType.ERROR:
      case StatusIconType.CANCELLED:
        return <CancelRoundedIcon className={classes.icon} />;
      case StatusIconType.WARNING:
      case StatusIconType.ALERT:
        return <WarningRoundedIcon className={classes.icon} />;
      case StatusIconType.NOT_YET_RUN:
        return <RemoveCircleRoundedIcon className={classes.icon} />;
      case StatusIconType.SCHEDULED:
      case StatusIconType.QUEUED:
        return <WatchLaterRoundedIcon className={classes.icon} />;
      case StatusIconType.ROUND_ERROR:
        return <ErrorRoundedIcon className={classes.icon} />;
      case StatusIconType.LOADING:
      default:
        return <CachedRoundedIcon className={classes.icon} />;
    }
  }

  const icon = getIcon();

  const iconContent =
    props.tooltipVariant && props.text ? (
      <ActionButtonTooltip
        title={props.text[0].toUpperCase() + props.text.slice(1).toLowerCase()}
        data-testid="status-icon-tooltip"
      >
        <span>{icon}</span>
      </ActionButtonTooltip>
    ) : (
      icon
    );

  return (
    <div
      className={`${classes.status}`}
      data-testid="status-icon"
      data-cy="status-icon"
      id={`status-icon-${props.type}-${props.size}-${props.inline}`}
    >
      {iconContent}
      {!props.tooltipVariant && props.text && (
        <Typography
          className={classes.statusText}
          data-testid="status-icon-description"
          data-cy="status-icon-description"
        >
          {props.text}
        </Typography>
      )}
    </div>
  );
}
