import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  updateAutomaticThreshold,
  updateAutomaticThresholdVariables
} from "../../../../../generated-graphql-interfaces";

export function useUpdateAutomaticThresholdMutation() {
  return useMutation<updateAutomaticThreshold, updateAutomaticThresholdVariables>(
    gql`
      mutation updateAutomaticThreshold(
        $testSuiteId: ObjectID!
        $automaticThreshold: Boolean!
        $automaticThresholdEnabledForAll: Boolean!
      ) {
        updateTestSuite(
          input: {
            automaticThreshold: $automaticThreshold
            testSuiteId: $testSuiteId
            automaticThresholdEnabledForAll: $automaticThresholdEnabledForAll
          }
        ) {
          testSuite {
            id
          }
        }
      }
    `
  );
}
