import { Severity } from "../../../generated-graphql-interfaces";
import { Theme } from "@material-ui/core";
import { getBorderMatchingColors } from "../StatusIcon/StatusIconUtils";
import { StatusIconType } from "../StatusIcon/StatusIcon";

export enum TestResultStatus {
  WARNING = "warning",
  FAIL = "fail",
  PASS = "pass",
  NA = "N/A"
}

export function getTestStatus(isPassed: boolean, severity: Severity): TestResultStatus {
  if (isPassed) {
    return TestResultStatus.PASS;
  } else if (severity === Severity.Fail) {
    return TestResultStatus.FAIL;
  } else if (severity === Severity.Warning) {
    return TestResultStatus.WARNING;
  } else {
    return TestResultStatus.NA;
  }
}

export function getMatchingBorderColorForStatus(status: TestResultStatus, theme: Theme) {
  const colors = getBorderMatchingColors(theme);
  const iconType = getStatusIconType(status);
  const map: [StatusIconType, string][] = [
    [StatusIconType.CHECK, colors.checkColor],
    [StatusIconType.WARNING, colors.warningColor],
    [StatusIconType.ERROR, colors.errorColor]
  ];

  const names = map.filter(pair => pair[0] === iconType).map(pair => pair[1]);
  return names.length ? names[0] : colors.naColor;
}

export function getStatusIconType(status: TestResultStatus) {
  const map: [TestResultStatus, StatusIconType][] = [
    [TestResultStatus.PASS, StatusIconType.CHECK],
    [TestResultStatus.WARNING, StatusIconType.WARNING],
    [TestResultStatus.FAIL, StatusIconType.ERROR]
  ];

  const names = map.filter(pair => pair[0] === status).map(pair => pair[1]);
  return names.length ? names[0] : StatusIconType.NA;
}

export function getText(status: TestResultStatus) {
  const map: [TestResultStatus, string][] = [
    [TestResultStatus.PASS, "Pass"],
    [TestResultStatus.WARNING, "Warning"],
    [TestResultStatus.FAIL, "Fail"]
  ];
  const names = map.filter(pair => pair[0] === status).map(pair => pair[1]);
  return names.length ? names[0] : "N/A";
}
