import { useMutation } from "@apollo/react-hooks";
import {
  unlinkChildTestSuiteFromParentTestSuite,
  unlinkChildTestSuiteFromParentTestSuiteVariables
} from "../../../../../generated-graphql-interfaces";
import { unlinkChildTestSuiteFromParentTestSuiteGql } from "./unlinkChildTestSuiteFromParentTestSuiteGql";

export function useUnlinkChildToParentMutation() {
  return useMutation<unlinkChildTestSuiteFromParentTestSuite, unlinkChildTestSuiteFromParentTestSuiteVariables>(
    unlinkChildTestSuiteFromParentTestSuiteGql,
    { refetchQueries: ["getTestSuite", "getTestSuiteChildren"] }
  );
}
