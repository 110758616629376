import gql from "graphql-tag";

export const getTestSuiteTestsAndCustomExtractionsGql = gql`
  query getTestSuiteTestsAndCustomExtractions(
    $id: ObjectID!
    $hasJiraAccess: Boolean!
    $hasSmartThresholdsAccess: Boolean!
  ) {
    node(id: $id) {
      ... on TestSuite {
        name
        id
        customExtractions {
          cleanHtmlTags
          regex
          label
          matchNumberFrom
          matchNumberTo
          reportTemplateCode
        }
        tests(first: 100) {
          nodes {
            createJiraTicketOnFailure @include(if: $hasJiraAccess)
            jiraTicketCustomNote @include(if: $hasJiraAccess)
            automaticThresholdEnabled @include(if: $hasSmartThresholdsAccess)
            createdAt
            updatedAt
            relativeThreshold
            absoluteThreshold
            thresholdType
            thresholdPredicate
            reportTemplateCode
            severity
            reportTemplate {
              name
              code
              automatorSummary
            }
            id
          }
        }
      }
    }
  }
`;
