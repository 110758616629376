import React from "react";
import { BuildTestStatus, ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { TestSuiteListItemNotYetRun } from "./TestSuiteListItemNotYetRun";
import { TestSuiteListItemStarted } from "./TestSuiteListItemStarted";

export interface TestSuiteListItemProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  editable: boolean;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
  refetchData?: () => void;
  testSuiteJiraIntegrationId?: string | null;
}

export function TestSuiteListItem(props: TestSuiteListItemProps): JSX.Element {
  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;

  return (
    <div data-testid="test-suite-list-item" data-pendo={`auto-testsuitelist-item-${props.status.toLowerCase()}`}>
      {isNotYetRun ? (
        <TestSuiteListItemNotYetRun
          refetchData={props.refetchData}
          id={props.id}
          status={props.status}
          lastRun={props.lastRun}
          name={props.name}
          url={props.url}
          testSuiteUpdatedAt={props.testSuiteUpdatedAt}
          testSuiteClonedAt={props.testSuiteClonedAt}
          testSuiteJiraIntegrationId={props.testSuiteJiraIntegrationId}
        />
      ) : (
        <TestSuiteListItemStarted
          refetchData={props.refetchData}
          id={props.id}
          status={props.status}
          lastRun={props.lastRun}
          name={props.name}
          url={props.url}
          testSuiteUpdatedAt={props.testSuiteUpdatedAt}
          testSuiteClonedAt={props.testSuiteClonedAt}
          buildId={props.buildId}
          totalTests={props.totalTests}
          failedTests={props.failedTests}
          warningTests={props.warningTests}
          passedTests={props.passedTests}
          testSuiteJiraIntegrationId={props.testSuiteJiraIntegrationId}
        />
      )}
    </div>
  );
}
