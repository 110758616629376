import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { doesUserMatchRole } from "../../utils/doesUserMatchRole/doesUserMatchRole";
import { RoleCode } from "../../../generated-graphql-interfaces";
import { PermissionsTooltip } from "../PermissionsTooltip/PermissionsTooltip";
import { Routes } from "../../routes/routes";
import { PrimaryActionButton } from "../PrimaryActionButton/PrimaryActionButton";
import AddIcon from "@material-ui/icons/Add";
import { PendoTagProps } from "../../../typings/pendo.prototype";

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none"
  },
  addNewButton: {
    padding: "9px 18px",
    borderRadius: "3px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    fontWeight: 600,
    minWidth: "auto",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  }
}));

interface AddTestSuiteButtonProps extends PendoTagProps {
  accountId: string;
  dataTestId?: string;
  dataCy?: string;
}

export function AddTestSuiteButton(props: AddTestSuiteButtonProps) {
  const { dataTestId, dataCy, accountId, ...rest } = props;
  const classes = useStyles();
  const isViewerOnly = doesUserMatchRole(RoleCode.Viewer);
  const defaultTestId = "add-test-suite-button";
  const label = "Add Test Suite";

  return isViewerOnly ? (
    <PermissionsTooltip>
      <PrimaryActionButton
        startIcon={<AddIcon />}
        disabled={isViewerOnly}
        dataTestId={dataTestId || defaultTestId}
        dataCy={dataCy || defaultTestId}
        label={label}
        {...rest}
      />
    </PermissionsTooltip>
  ) : (
    <Link
      data-testid={dataTestId || "add-test-suite-link-wrapper"}
      to={Routes.CreateTestSuite.getUrl({ accountId: accountId })}
      className={classes.link}
    >
      <PrimaryActionButton
        startIcon={<AddIcon />}
        dataTestId="add-test-suite-button"
        dataCy={dataCy || defaultTestId}
        label={label}
        {...rest}
      />
    </Link>
  );
}
