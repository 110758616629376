import React from "react";
import { makeStyles, Box, Theme, Chip } from "@material-ui/core";

export enum DCChipType {
  INFO = "info",
  DEFAULT = "default"
}

export interface DCChipProps {
  text: string;
  type: DCChipType;
}

function getBackgroundColor(theme: Theme, props: DCChipProps) {
  switch (props.type) {
    case DCChipType.INFO:
      return theme.palette.purple[50];
    default:
      return "#F4F4F4";
  }
}

function getTextColor(theme: Theme, props: DCChipProps) {
  switch (props.type) {
    case DCChipType.INFO:
      return theme.palette.purple[500];
    default:
      return theme.palette.navy[500];
  }
}

const useStyles = makeStyles(theme => ({
  box: (props: DCChipProps) => {
    const backgroundColor = getBackgroundColor(theme, props);
    const textColor = getTextColor(theme, props);
    return {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      padding: theme.spacing(0, 0.5),
      fontSize: "14px",
      fontWeight: 400,
      maxWidth: "250px",
      color: textColor,
      textTransform: "uppercase"
    };
  }
}));

export function DCChip(props: DCChipProps): JSX.Element {
  const classes = useStyles(props);

  return (
    <Chip
      label={props.text}
      size="small"
      className={classes.box}
      data-testid={`dc-chip-${props.text ? props.text.trim().toLocaleLowerCase().replace(/\s/g, "") : ""}`}
      data-cy="dc-chip"
    />
  );
}
