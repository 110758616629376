import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import clsx from "clsx";
import { isResolutionWithin, ResolutionStep } from "../../utils/window/window";
import { ResponsiveTabsMenu } from "./ResponsiveTabsMenu";
import { ResponsiveTabsContent } from "./ResponsiveTabsContent";
import { ResponsiveTabsBackBox } from "./ResponsiveTabsBackBox";

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(6, 3.5, 0, 3.5)
  },
  paddingRight12: {
    paddingRight: theme.spacing(3)
  }
}));

export interface ResponsiveTabsConfig {
  label: string;
  content: JSX.Element;
  icon?: JSX.Element;
  id: string;
}

interface ResponsiveTabsProps {
  config: ResponsiveTabsConfig[];
  label: string;
}

export function ResponsiveTabs(props: ResponsiveTabsProps) {
  const classes = useStyles();
  const swithLayoutStep = ResolutionStep.SM;
  const [isResponsiveUp, setIsResponsiveUp] = useState(isResolutionWithin(swithLayoutStep));
  const [selectedTabId, setSelectedTabId] = useState<string | null>(isResponsiveUp ? props.config[0].id : null);
  const tabSelected = selectedTabId !== null;
  const isResponsiveDown = !isResponsiveUp;
  const menuVisible = isResponsiveUp || (isResponsiveDown && !tabSelected);
  const backButtonVisible = isResponsiveDown && tabSelected;

  useEffect(() => {
    function handleResize() {
      const resolutionAboveResponsive = isResolutionWithin(swithLayoutStep);
      setIsResponsiveUp(resolutionAboveResponsive);
      if (resolutionAboveResponsive && !tabSelected) {
        setSelectedTabId(props.config[0].id);
      }
    }
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.config, selectedTabId, swithLayoutStep, tabSelected]);

  return (
    <Grid container className={clsx(isResponsiveUp && classes.padding)}>
      {menuVisible && (
        <Grid item xs={12} sm={3} className={clsx(isResponsiveUp && classes.paddingRight12)}>
          <ResponsiveTabsMenu config={props.config} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
        </Grid>
      )}
      {backButtonVisible && (
        <Grid item xs={12}>
          <ResponsiveTabsBackBox unselectTabId={() => setSelectedTabId(null)} label={props.label} />
        </Grid>
      )}
      <Grid item xs={12} sm={9}>
        <ResponsiveTabsContent
          config={props.config}
          selectedTabId={selectedTabId}
          isResponsiveLayout={isResponsiveDown}
        />
      </Grid>
    </Grid>
  );
}
