import {
  getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes,
  getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration
} from "../../../../../generated-graphql-interfaces";

export enum JiraIntegrationKey {
  NAME = "integration_name",
  JIRA_URL = "jira_url",
  JIRA_PROJECT_NAME = "jira_project_name",
  JIRA_ISSUE_TYPE_NAME = "jira_issue_type_name",
  JIRA_INTEGRATION_STATUS = "jira_integration_status"
}

export function decorateMetadata(
  integrationStatus: string,
  jiraIntegration?:
    | null
    | getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration
    | getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes
) {
  return jiraIntegration
    ? [
        { key: JiraIntegrationKey.NAME, value: jiraIntegration.name },
        { key: JiraIntegrationKey.JIRA_URL, value: jiraIntegration.jiraUrl },
        { key: JiraIntegrationKey.JIRA_PROJECT_NAME, value: jiraIntegration.jiraProjectName },
        { key: JiraIntegrationKey.JIRA_ISSUE_TYPE_NAME, value: jiraIntegration.jiraIssueTypeName },
        { key: JiraIntegrationKey.JIRA_INTEGRATION_STATUS, value: integrationStatus }
      ]
    : [];
}
