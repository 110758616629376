import gql from "graphql-tag";

export const userQuery = gql`
  query getUser {
    me {
      id
      firstName
      lastName
      username
      accounts(first: 100) {
        nodes {
          id
          name
        }
      }
      accountsUsers(first: 100) {
        nodes {
          roleCode
          account {
            id
            featureFlags {
              name
              enabled
            }
          }
        }
      }
    }
  }
`;
