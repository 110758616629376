import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes } from "../../../../generated-graphql-interfaces";
import { NoJiraIntegrationsView } from "./NoJiraIntegrationsView";
import { JiraIntegrationConnectedTestSuitesTable } from "./JiraIntegrationConnectedTestSuitesTable";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 5, 5, 5),
    display: "block"
  },
  header: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
    color: theme.palette.navy[500],
    paddingBottom: theme.spacing(1)
  },
  subHeading: {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.navy[200]
  }
}));

export interface JiraIntegrationDetailConnectedTestSuitesProps {
  integration: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes;
  isUnlinkingIntegrationLoading: boolean;
  unlinkFromTestSuite: (testSuiteId: string | undefined) => void;
}

export function JiraIntegrationDetailConnectedTestSuites(props: JiraIntegrationDetailConnectedTestSuitesProps) {
  const classes = useStyles();
  const testSuites = props.integration.testSuiteJiraIntegrations?.nodes?.map(({ testSuite }) => testSuite);
  const testSuitesNotPresent = !testSuites || testSuites.length === 0;

  return (
    <Grid
      container
      className={classes.root}
      data-testid="jira-integration-detail-connections"
      data-cy="jira-integration-detail-connections"
      id={`jira-integration-detail-connections-${props.integration.id}`}
    >
      <Grid item xs={12}>
        <Typography
          className={classes.header}
          data-testid="jira-integration-detail-connections-header"
          data-cy="jira-integration-detail-connections-header"
        >
          Test suites connected to this Jira integration
        </Typography>
      </Grid>

      {testSuitesNotPresent && (
        <NoJiraIntegrationsView
          title="Sorry, looks like you don’t have any test suites connected to this Jira integration yet."
          text="You can connect a test suite to a Jira integration in step 4 ‘Set alerts’ when configuring a test suite."
          includeButton={false}
        />
      )}

      {testSuites && testSuites.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.subHeading} data-testid="jira-integration-detail-connections-counter">
              {testSuites.length} connected test suites
            </Typography>
          </Grid>
          <JiraIntegrationConnectedTestSuitesTable
            testSuites={testSuites}
            isUnlinkingIntegrationLoading={props.isUnlinkingIntegrationLoading}
            unlinkIntegration={props.unlinkFromTestSuite}
          />
        </>
      )}
    </Grid>
  );
}
