import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Routes } from "../../routes/routes";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import { permissionsContext } from "../../contexts/Permissions/Permissions.context";
import { Skeleton } from "@material-ui/lab";

declare interface PermissionToElement {
  permission: ComponentPermissionsOption;
  elementFn: () => JSX.Element;
}

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(3.5)
  }
}));

export enum ComponentPermissionsOption {
  CONNECTED_APPS = "connected_apps",
  FRONTEND_SCHEDULER = "frontend_scheduler"
}

export function ComponentPermissionCheck(
  permissionToEvaluate: ComponentPermissionsOption,
  Component: React.ComponentType
): React.ComponentType {
  return function Wrapper() {
    const classes = useStyles();
    const accountId = useAccountRouteMatch();
    const { permissions, loaded: permisssionsLoaded } = useContext(permissionsContext);

    const redirectToLogin = <Redirect to={Routes.Login.ROUTE} />;
    const dashboardRedired = <Redirect to={Routes.Dashboard.getUrl({ accountId })} />;
    function evaluateConnectedApps() {
      return permissions.jiraIntegrationAccess ? <Component /> : dashboardRedired;
    }

    function evaluateFrontendScheduler() {
      return permissions.frontendSchedulerAccess ? <Component /> : dashboardRedired;
    }

    const permissionToElementMap: Array<PermissionToElement> = [
      { permission: ComponentPermissionsOption.CONNECTED_APPS, elementFn: evaluateConnectedApps },
      { permission: ComponentPermissionsOption.FRONTEND_SCHEDULER, elementFn: evaluateFrontendScheduler }
    ];

    function getCorrectRedirectionOrComponent() {
      const filteredFunctions = permissionToElementMap
        .filter(p => p.permission === permissionToEvaluate)
        .map(p => p.elementFn);
      return filteredFunctions.length ? filteredFunctions[0]() : redirectToLogin;
    }

    if (permisssionsLoaded) {
      return getCorrectRedirectionOrComponent();
    } else {
      return (
        <Skeleton data-testid="permissions-loader" className={classes.loader} variant="rect" height={56} width="100%" />
      );
    }
  };
}
