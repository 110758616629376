import { Theme } from "@material-ui/core";
import { StatusIconType } from "./StatusIcon";

export interface StatusIconColors {
  errorColor: string;
  warningColor: string;
  checkColor: string;
  naColor: string;
  alertColor: string;
  cancelColor: string;
  loadingColor: string;
}

export function getStatusTextColors(theme: Theme): StatusIconColors {
  const errorColor = theme.palette.error.main;
  const warningColor = theme.palette.warning.dark;
  const checkColor = theme.palette.deepcrawlGreen.dark;
  const alertColor = theme.palette.navy[300];
  const cancelColor = theme.palette.navy[300];
  const naColor = theme.palette.navy[300];
  const loadingColor = theme.palette.navy[300];
  return {
    errorColor,
    warningColor,
    checkColor,
    naColor,
    alertColor,
    cancelColor,
    loadingColor
  };
}

export function getBorderMatchingColors(theme: Theme): StatusIconColors {
  const errorColor = theme.palette.error.main;
  const warningColor = theme.palette.warning.main;
  const checkColor = theme.palette.deepcrawlGreen.main;
  const naColor = theme.palette.navy[200];
  const cancelColor = theme.palette.navy[200];
  const loadingColor = theme.palette.navy[200];
  const alertColor = theme.palette.navy[200];
  return {
    errorColor,
    warningColor,
    checkColor,
    naColor,
    alertColor,
    cancelColor,
    loadingColor
  };
}

export function getColorForType(
  colors: {
    errorColor: string;
    warningColor: string;
    checkColor: string;
    naColor: string;
    alertColor: string;
    cancelColor: string;
    loadingColor: string;
  },
  type: StatusIconType
): string {
  const map = [
    [StatusIconType.ALERT, colors.alertColor],
    [StatusIconType.ERROR, colors.errorColor],
    [StatusIconType.WARNING, colors.warningColor],
    [StatusIconType.CHECK, colors.checkColor],
    [StatusIconType.CANCELLED, colors.cancelColor],
    [StatusIconType.LOADING, colors.loadingColor],
    [StatusIconType.QUEUED, colors.cancelColor],
    [StatusIconType.ROUND_ERROR, colors.cancelColor],
    [StatusIconType.SCHEDULED, colors.cancelColor]
  ];
  const color = map.filter(item => item[0] === type).map(item => item[1]);
  return color.length ? color[0] : colors.naColor;
}
