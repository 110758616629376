import { deepCrawlTheme } from "../../../theme/deepCrawlTheme";

export enum ResolutionStep {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl"
}

const getValueOfStep = (step: ResolutionStep) => {
  return deepCrawlTheme.breakpoints.values[step];
};

const getWindowWidth = () => {
  return window.innerWidth;
};

const isResolutionAbove = (step: ResolutionStep) => {
  return getWindowWidth() >= getValueOfStep(step);
};

const isResolutionBelow = (step: ResolutionStep) => {
  return getWindowWidth() < getValueOfStep(step);
};

const isResolutionExactly = (step: ResolutionStep) => {
  return getWindowWidth() === getValueOfStep(step);
};

export const isResolutionWithin = (step1: ResolutionStep, step2?: ResolutionStep) => {
  if (step1 && step2) {
    return step1 !== step2 ? isResolutionAbove(step1) && isResolutionBelow(step2) : isResolutionExactly(step1);
  } else {
    return isResolutionAbove(step1);
  }
};
