import format from "date-fns/format";
import { BuildScheduleRepetitionRate } from "../../../../../generated-graphql-interfaces";
import { DoNotRepeatBuildScheduleFrequency } from "../scheduleSettingsValidationSchema";

export function generateSchedulerFrequencyOptions(date: Date | null | undefined) {
  return [
    { value: DoNotRepeatBuildScheduleFrequency.Never, label: "Does not repeat" },
    { value: BuildScheduleRepetitionRate.EveryFourHours, label: "Every 4 hours" },
    { value: BuildScheduleRepetitionRate.Daily, label: "Daily" },
    { value: BuildScheduleRepetitionRate.Weekly, label: `Weekly on ${date ? format(date, "EEEE") + "s" : "N/A"}` },
    { value: BuildScheduleRepetitionRate.Monthly, label: "Monthly" },
    { value: BuildScheduleRepetitionRate.EveryWeekday, label: "Every weekday (Mon to Fri)" }
  ];
}
