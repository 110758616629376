import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { TextField, Box } from "@material-ui/core";
import { Autocomplete, Alert, createFilterOptions, Skeleton } from "@material-ui/lab";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useGetNotLinkedTestSuitesQuery } from "../graphql/useGetNotLinkedTestSuitesQuery";
import { getNotLinkedTestSuites } from "../../../../../generated-graphql-interfaces";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { defaultPopOverId } from "../../../../../_common/constants/popover";

export interface TestSuiteOption {
  name: string;
  id: string;
  group: string;
}

export enum UseAsGlobalTemplateVariant {
  AddNewChild = "add-new-child",
  AddAnotherChild = "add-another-child"
}

function getOptions(data: getNotLinkedTestSuites | undefined, testSuiteId: string) {
  return (
    data?.getAccount?.notLinkedTestSuites?.edges
      .filter(testSuiteEdge => {
        return testSuiteEdge.node.id !== testSuiteId;
      })
      .map(testSuiteEdge => {
        return {
          name: testSuiteEdge.node.name,
          id: testSuiteEdge.node.id,
          group: "Available test suites"
        };
      }) || []
  );
}

interface UseAsGlobalTemplateProps {
  handleUseAsGlobalTemplate: (childId: string, childName: string) => Promise<void>;
  handleClose?: () => void;
  label: string;
  variant: UseAsGlobalTemplateVariant;
  testSuiteId: string;
  availableTestSuitesDetected?: (count: number) => void;
}
// eslint-disable-next-line max-statements, complexity, max-lines-per-function
export function UseAsGlobalTemplate(props: UseAsGlobalTemplateProps) {
  const autocompleteRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState<TestSuiteOption | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const accountId = useAccountRouteMatch();
  const { data, error, loading } = useGetNotLinkedTestSuitesQuery(accountId);
  const optionsList = getOptions(data, props.testSuiteId);

  useEffect(() => {
    if (props.availableTestSuitesDetected) {
      props.availableTestSuitesDetected(optionsList.length);
    }
  }, [props, optionsList]);

  const isFirstChild = props.variant === UseAsGlobalTemplateVariant.AddNewChild;

  const popoverTitle = isFirstChild
    ? "Are you sure you want to make this test suite a global template?"
    : "Are you sure you want to link this test suite to a global template?";
  const popoverText = isFirstChild
    ? "All your current configurations in steps 2 and 3 will overwrite steps 2 and 3 in your linked test suite."
    : "All your current configurations in steps 2 and 3 will be overwritten.";

  const confirmationButtonText = isFirstChild ? "Make global template" : "Link";

  const popoverButtons: PopoverDialogButton[] = [
    {
      label: "Cancel",
      identifier: PopoverDialogDefaultIdentifiers.CANCEL,
      color: "primary"
    },
    {
      label: confirmationButtonText,
      identifier: PopoverDialogDefaultIdentifiers.OK,
      color: "info"
    }
  ];

  const filterOptions = createFilterOptions<TestSuiteOption>({
    stringify: option => option.name
  });

  function handleLinkGlobalTemplate(id: PopoverDialogDefaultIdentifiers | string) {
    setAnchorEl(null);

    if (id === PopoverDialogDefaultIdentifiers.OK && selectedOption?.id) {
      if (props.handleClose) {
        props.handleClose();
      }
      props.handleUseAsGlobalTemplate(selectedOption.id, selectedOption.name);
    }
  }

  function handleAutocompleteChange(event: ChangeEvent<{}>, newOption: TestSuiteOption | null) {
    setSelectedOption(newOption);
    setAnchorEl(newOption ? autocompleteRef.current : null);
  }

  if (loading) {
    return <Skeleton variant="rect" height={59} data-testid="use-as-global-template-loading" />;
  }

  if (error) {
    return (
      <Box mb={2}>
        <Alert severity="error" data-testid="use-as-global-template-error">
          An error occurred. Please refresh the page and try again.
        </Alert>
      </Box>
    );
  }

  return (
    <Box data-testid="use-as-global-template">
      {optionsList.length ? (
        <Autocomplete
          openOnFocus
          popupIcon={<ExpandMoreRoundedIcon />}
          ref={autocompleteRef}
          value={selectedOption}
          onChange={handleAutocompleteChange}
          id="add-parent-autocomplete"
          data-testid="add-parent-autocomplete"
          data-pendo="auto-test-suite-edit-template-settings-link-use-as-global-template-select"
          options={optionsList}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={filterOptions}
          groupBy={option => option.group}
          renderInput={params => <TextField {...params} label={props.label} variant="outlined" />}
        />
      ) : (
        <Alert severity="info" data-testid="no-testsuites-available-info">
          All your test suites are currently linked to templates or no test suites are available.
        </Alert>
      )}
      <PopoverDialog
        anchorElement={anchorEl}
        handleAction={handleLinkGlobalTemplate}
        open={Boolean(anchorEl)}
        title={popoverTitle}
        text={popoverText}
        buttons={popoverButtons}
        id={defaultPopOverId}
      />
    </Box>
  );
}
