import { BuildTestStatus, ExtendedBuildStatus } from "../../interfaces/BuildTestStatus";
import { Theme } from "@material-ui/core";
import { StatusIconType } from "../StatusIcon/StatusIcon";
import { getBorderMatchingColors } from "../StatusIcon/StatusIconUtils";
import { BuildStatus } from "../../../generated-graphql-interfaces";

export function getStatusMessage(status: BuildTestStatus): string {
  return status === ExtendedBuildStatus.NotYetRun ? "NOT YET RUN" : status ? status.toUpperCase() : "";
}

export function getStatusIconType(status: BuildTestStatus) {
  const map: [BuildTestStatus | undefined, StatusIconType][] = [
    [BuildStatus.Queued, StatusIconType.QUEUED],
    [ExtendedBuildStatus.NotYetRun, StatusIconType.NOT_YET_RUN],
    [BuildStatus.Aborted, StatusIconType.ROUND_ERROR],
    [BuildStatus.Cancelled, StatusIconType.ROUND_ERROR],
    [ExtendedBuildStatus.Fail, StatusIconType.ERROR],
    [ExtendedBuildStatus.Pass, StatusIconType.CHECK],
    [BuildStatus.Running, StatusIconType.LOADING],
    [ExtendedBuildStatus.Warning, StatusIconType.WARNING],
    [ExtendedBuildStatus.Scheduled, StatusIconType.SCHEDULED]
  ];

  const statuses = map.filter(pair => pair[0] === status).map(pair => pair[1]);
  return statuses.length > 0 ? statuses[0] : StatusIconType.NA;
}

export function getMatchingBorderColorForStatus(status: BuildTestStatus, theme: Theme) {
  const {
    errorColor,
    warningColor,
    checkColor,
    naColor,
    alertColor,
    cancelColor,
    loadingColor
  } = getBorderMatchingColors(theme);
  const statusIconType = getStatusIconType(status);
  const map: [StatusIconType, string][] = [
    [StatusIconType.ERROR, errorColor],
    [StatusIconType.WARNING, warningColor],
    [StatusIconType.ALERT, alertColor],
    [StatusIconType.CANCELLED, cancelColor],
    [StatusIconType.CHECK, checkColor],
    [StatusIconType.LOADING, loadingColor],
    [StatusIconType.NOT_YET_RUN, cancelColor],
    [StatusIconType.SCHEDULED, cancelColor],
    [StatusIconType.ROUND_ERROR, cancelColor],
    [StatusIconType.QUEUED, cancelColor]
  ];
  const colors = map.filter(pair => pair[0] === statusIconType).map(pair => pair[1]);
  return colors.length ? colors[0] : naColor;
}
