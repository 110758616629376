import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import noIntegrationsImage from "../../../../images/no-integrations-empty-state.svg";
import { PrimaryActionButton } from "../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import AddIcon from "@material-ui/icons/Add";

export interface NoJiraIntegrationsViewProps {
  addIntegration?: () => void;
  title: string;
  text: string;
  includeButton: boolean;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    maxWidth: "800px",
    minWidth: "60%",
    color: theme.palette.text.primary,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  graphics: {
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: "1.125rem",
    fontWeight: 400,
    paddingBottom: theme.spacing(2),
    maxWidth: 500,
    margin: "0 auto"
  },
  text: {
    fontSize: "1rem",
    maxWidth: 400,
    margin: "0 auto"
  },
  addIntegationButton: {
    marginTop: theme.spacing(3)
  }
}));

export function NoJiraIntegrationsView(props: NoJiraIntegrationsViewProps) {
  const classes = useStyles();

  return (
    <Box
      mt={5}
      p={4}
      textAlign="center"
      className={classes.wrapper}
      data-testid="no-integrations-view"
      data-cy="no-integrations-view"
    >
      <img src={noIntegrationsImage} alt="No Jira integrations present" className={classes.graphics} />
      <Typography
        className={classes.title}
        color="textPrimary"
        data-cy="no-integrations-title"
        data-testid="no-integrations-title"
      >
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        color="textPrimary"
        className={classes.text}
        data-cy="no-integrations-text"
        data-testid="no-integrations-text"
      >
        {props.text}
      </Typography>
      {props.includeButton && (
        <Box className={classes.addIntegationButton}>
          <PrimaryActionButton
            data-pendo="no-jira-integrations"
            startIcon={<AddIcon />}
            label="Add Jira Integration"
            dataTestId="no-integrations-add-integration-button"
            dataCy="no-integrations-add-integration-button"
            onClick={() => props.addIntegration && props.addIntegration()}
            disableForViewerRole={true}
          />
        </Box>
      )}
    </Box>
  );
}
