import React from "react";

import { Field, FieldArrayRenderProps, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";

import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import { CustomDnsPair } from "./ConnectSiteForm";
import { DeleteIcon } from "../../../../_common/svg/DeleteIcon";

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    color: theme.palette.navy[500],
    marginTop: "7px",
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`
  }
}));

export function CustomDnsPairs(props: FieldArrayRenderProps): JSX.Element {
  const { values } = useFormikContext<{
    customDns: CustomDnsPair[];
  }>();
  const classes = useStyles();

  return values.customDns ? (
    <>
      {values.customDns.map((_dnsPair, index) => {
        return (
          <React.Fragment key={index}>
            <Grid container item xs={10} spacing={2}>
              <Grid item xs={6}>
                <Field
                  name={`customDns[${index}].hostname`}
                  id={`connect-site-form-custom-dns-hostname${index}`}
                  data-testid={`connect-site-form-custom-dns-hostname-${index}`}
                  data-pendo="auto-edit-add-test-suite-custom-dns-hostname"
                  type="text"
                  label="Hostname"
                  placeholder="e.g. testdomain.com"
                  component={TextField}
                  fullWidth={true}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`customDns[${index}].ipAddress`}
                  id={`connect-site-form-custom-dns-ip${index}`}
                  data-testid={`connect-site-form-custom-dns-ip-${index}`}
                  data-pendo="auto-edit-add-test-suite-custom-dns-ip"
                  label="IP Address"
                  component={TextField}
                  placeholder="e.g. 12.34.56.78"
                  fullWidth={true}
                  variant="filled"
                />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.deleteIcon}
                aria-label="delete"
                id={`btn-delete-connect-site-custom-dns-${index}`}
                data-testid={`btn-delete-connect-site-custom-dns-${index}`}
                data-pendo="auto-edit-add-test-suite-custom-dns-hostname-remove"
                onClick={() => props.remove(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <Box component="div">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            id="connect-site-form-add-custom-dns-pair"
            data-testid="connect-site-form-add-custom-dns-pair"
            data-pendo="auto-edit-add-test-suite-custom-dns-add"
            onClick={() => {
              // eslint-disable-next-line fp/no-mutating-methods
              props.push({
                hostname: "",
                ipAddress: ""
              });
            }}
          >
            Add Custom DNS Pair
          </Button>
        </Box>
      </Grid>
    </>
  ) : (
    <></>
  );
}
