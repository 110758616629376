import React, { useEffect, useState } from "react";
import { Box, Grid, Hidden, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../_common/routes/routes";
import { NoSchedulesView } from "./components/NoSchedulesView";
import { SchedulerDetailView } from "./components/SchedulerDetailView";
import { SchedulerManageList } from "./components/SchedulerManageList";
import { SchedulerPageHeader } from "./components/SchedulerPageHeader";
import { ScheduleSettings } from "./components/ScheduleSettings/ScheduleSettings";
import { useGetBuildSchedulesQuery } from "./components/graphql/useGetBuildSchedulesQuery";
import { Alert, Skeleton } from "@material-ui/lab";
import { useCreateBuildScheduleMutation } from "./graphql/useCreateBuildScheduleMutation";
import { BuildScheduleRepetitionRate } from "../../generated-graphql-interfaces";
import { useSnackbar } from "notistack";
import { useSetBuildScheduleTestSuitesMutation } from "./graphql/useSetBuildScheduleTestSuitesMutation";

const useStyles = makeStyles(theme => ({
  grid: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3)
    }
  },
  listGridItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 0
    }
  }
}));

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
export function FrontendScheduler() {
  const { schedulerId, accountId } = useParams<{ accountId: string; schedulerId?: string | undefined }>();
  const classes = useStyles();
  const history = useHistory();
  const [isScheduleSettingsOpen, setIsScheduleSettingsOpen] = useState(false);
  const { data, loading, error, refetch } = useGetBuildSchedulesQuery(accountId);
  const { enqueueSnackbar } = useSnackbar();
  const [setScheduleTestSuites] = useSetBuildScheduleTestSuitesMutation();

  useEffect(() => {
    // I know this looks weird, but this is the only way to keep the data fresh, but also stop the page reloading
    // every time the user clicks on a different scheduler list item.
    refetch().catch(() => {
      enqueueSnackbar("Something went wrong. Please refresh the page and try again.", { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulerId]);

  const schedules = data?.getAccount?.buildSchedules.nodes || [];
  const [createSchedule] = useCreateBuildScheduleMutation();

  const unselectScheduler = () => {
    history.push(Routes.FrontendScheduler.getUrl({ accountId, schedulerId: undefined }));
  };

  const selectScheduler = (id: string) => {
    history.push(Routes.FrontendScheduler.getUrl({ accountId, schedulerId: id }));
  };

  function openScheduleSettings() {
    setIsScheduleSettingsOpen(true);
  }

  function closeScheduleSettings() {
    setIsScheduleSettingsOpen(false);
  }

  async function handleSaveSchedule(
    input: {
      accountId?: string;
      name: string;
      repetitionRate?: BuildScheduleRepetitionRate | undefined | null;
      startAt: Date | undefined;
      buildScheduleId?: string;
    },
    testSuiteIds: string[]
  ) {
    try {
      const { data } = await createSchedule({
        variables: {
          input: {
            accountId: input.accountId,
            name: input.name,
            repetitionRate: input.repetitionRate,
            startAt: input.startAt
          }
        }
      });
      const schedulerId = data?.createBuildSchedule.buildSchedule.id;
      await setScheduleTestSuites({
        variables: {
          testSuiteIds,
          buildScheduleId: schedulerId
        }
      });
      enqueueSnackbar("Schedule successfully created", { variant: "success" });
      history.push(Routes.FrontendScheduler.getUrl({ accountId, schedulerId: schedulerId }));
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please refresh the page and try again.", {
        variant: "error",
        persist: true
      });
    }
  }

  async function onSchedulerDetailUpdateCompleted() {
    refetch();
  }

  const list = (
    <Grid item xs={12} lg={3} xl={3} className={classes.listGridItem}>
      <SchedulerManageList
        schedulers={schedules}
        accountId={accountId}
        selectedSchedulerId={schedulerId}
        onSelectItem={selectScheduler}
      />
    </Grid>
  );

  if (loading) {
    return (
      <>
        <Box
          data-testid="frontend-scheduler-loading"
          style={{ marginTop: 34, marginBottom: 58 }}
          display="flex"
          justifyContent="space-between"
        >
          <Skeleton variant="text" height={40} width={150} />
          <Skeleton variant="rect" height={40} width={150} />
        </Box>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} lg={3} xl={3} className={classes.listGridItem}>
            <Skeleton variant="rect" height={600} width="100%" />
          </Grid>
        </Grid>
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="frontend-scheduler-error">
        An error occurred trying to load your frontend schedules. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <Box data-testid="scheduler-header">
      <ScheduleSettings
        isOpen={isScheduleSettingsOpen}
        handleClose={closeScheduleSettings}
        handleSave={handleSaveSchedule}
      />
      <SchedulerPageHeader
        selectedSchedulerId={schedulerId}
        onGoBack={unselectScheduler}
        openScheduleSettings={openScheduleSettings}
      />
      {schedules.length ? (
        <Grid container className={classes.grid} spacing={2}>
          {schedulerId ? <Hidden mdDown>{list}</Hidden> : list}
          {schedulerId && (
            <Grid item xs={12} lg={9} xl={9}>
              <SchedulerDetailView schedulerId={schedulerId} onUpdateCompleted={onSchedulerDetailUpdateCompleted} />
            </Grid>
          )}
        </Grid>
      ) : (
        <NoSchedulesView openScheduleSettings={openScheduleSettings} />
      )}
    </Box>
  );
}
