import gql from "graphql-tag";

export const getBuildTestsQuery = gql`
  query getBuildTests($testSuiteId: ObjectID!) {
    node(id: $testSuiteId) {
      ... on TestSuite {
        id
        name
        tests(last: 100) {
          totalCount
        }
        builds(first: 20, orderBy: [{ field: id, direction: DESC }]) {
          nodes {
            id
            ciBuildId
            passedTestCount
            warnedTestCount
            failedTestCount
            createdAt
            finishedAt
            updatedAt
            status
            passed
            triggerType
          }
        }
      }
    }
  }
`;
