import gql from "graphql-tag";

export const cloneTestSuiteGql = gql`
  mutation cloneTestSuite($testSuiteId: ObjectID!) {
    cloneTestSuite(input: { testSuiteId: $testSuiteId }) {
      testSuite {
        id
        name
      }
    }
  }
`;
