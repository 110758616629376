import { makeStyles } from "@material-ui/core";
import { SchedulerTestSuiteListItemStartedProps } from "./SchedulerTestSuiteListItemStarted";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { BuildStatus } from "../../../generated-graphql-interfaces";

export const useStyles = makeStyles(theme => ({
  border: (props: SchedulerTestSuiteListItemStartedProps) => {
    return {
      width: "6px",
      backgroundColor: getMatchingBorderColorForStatus(props.status, theme),
      flexShrink: 0
    };
  },
  root: {
    padding: theme.spacing(2, 2, 2, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 1, 3, 0)
    },
    flexGrow: 1,
    backgroundColor: "#FFF",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: "5px"
  },
  wrapper: {
    display: "flex",
    borderRadius: "4px",
    overflow: "hidden",
    marginBottom: "10px",
    boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.04)",
    "&:hover": {
      boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 9px rgba(0, 0, 0, 0.2)"
    }
  },
  disabled: {
    "&:hover": {
      boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.04)"
    }
  },
  alignRight: {
    textAlign: "right"
  },
  showAsNotLink: (props: SchedulerTestSuiteListItemStartedProps) => {
    return {
      textDecoration: "none",
      "&:hover": {
        cursor: props.status === BuildStatus.Running ? "default" : "pointer"
      }
    };
  },
  lastRun: {
    fontWeight: 400,
    color: theme.palette.navy[300],
    fontSize: 14,
    ["@media (max-width: 1540px)"]: {
      textAlign: "right"
    }
  },
  iconWrapperSpecific: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      display: "block",
      justifyContent: "left"
    }
  },
  chipWarpperSpecific: {
    alignItems: "center"
  },
  dateTimeWrapperSpecific: {
    display: "flex",
    alignItems: "center",
    ["@media (max-width: 1540px)"]: {
      justifyContent: "flex-end"
    }
  }
}));
