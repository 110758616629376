import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const typographyOptions: TypographyOptions = {
  fontFamily: `"Manrope", sans-serif`,
  fontSize: 14,
  button: {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px"
  }
};
