import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDisplayingAccountId } from "../../utils/getDisplayingAccountId/getDisplayingAccountId";
import { initializePendo } from "../../utils/pendo/initializePendo";
import { setLastSelectedAccount } from "../../utils/LastSelectedAccount/setLastSelectedAccount";
import { setAccountIdInPendo } from "../../utils/pendo/setAccountIdInPendo";
import { RoleCode, getUser, getUser_me_accountsUsers_nodes } from "../../../generated-graphql-interfaces";
import { Select, makeStyles, InputLabel, FormControl, MenuItem, Box } from "@material-ui/core";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { getMenuProps } from "../../visualOverrides/selectVisualOverrides";
import { LocalStorageKeys } from "../../constants/localStorageKeys";
import { Routes } from "../../routes/routes";
import { Skeleton } from "@material-ui/lab";

export interface AccountSelectProps {
  userData: getUser | undefined;
  isUserDataLoading: boolean;
  closeSideNav: () => void;
}

const useStyles = makeStyles(theme => ({
  outline: {
    color: theme.palette.primary.light,
    paddingTop: 0,
    paddingBottom: 14,
    paddingLeft: 11,
    paddingRight: theme.spacing(2),
    lineHeight: 1
  },
  label: {
    color: theme.palette.navy[200],
    transform: "translate(12px, 20px) scale(0.75) !important"
  },
  formControl: {
    width: 192,
    "&:hover": {
      "& .MuiSelect-root": {
        color: "#09DCFF"
      }
    },
    "& fieldset": {
      border: "none",
      borderRadius: "4px"
    },
    "& svg": {
      color: theme.palette.primary.light
    },
    "& legend": {
      maxWidth: "0px"
    },
    "& label.Mui-focused": {
      color: theme.palette.navy[200]
    }
  },
  select: {
    fontSize: 15,
    fontWeight: 500,
    "&:hover": {
      "& fieldset": {
        border: "none"
      }
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.grey[300] + " !important",
      borderWidth: "1px !important"
    }
  },
  skeletonTitle: {
    backgroundColor: theme.palette.grey[400],
    marginBottom: 4
  },
  skeletonLabel: {
    backgroundColor: theme.palette.grey[400]
  }
}));

// eslint-disable-next-line max-lines-per-function
export function AccountSelect(props: AccountSelectProps) {
  const classes = useStyles();
  const history = useHistory();

  // evaluates if the url accountId is found
  const [initialAccountChangeEvaluated, setInitialAccountChangeEvaluated] = useState<null | boolean>(null);
  const accountId = useAccountRouteMatch();
  const displayingAccountId = getDisplayingAccountId(props.userData, accountId);
  const [accountSelectValue, setAccountSelectValue] = useState(displayingAccountId);

  if (!initialAccountChangeEvaluated && props.userData) {
    const dataForMatchedAccountId = props.userData?.me.accounts.nodes.filter(node => node.id === accountId);
    if (dataForMatchedAccountId?.length) {
      setInitialAccountChangeEvaluated(true);
      changeAccount(props.userData, accountId);
    }
  }

  function getCurrentAccountUser(userData: getUser, accountId: string): getUser_me_accountsUsers_nodes | null {
    const accountsUsers = userData.me?.accountsUsers?.nodes || [];

    const currentAccountUser = accountsUsers.find(accountUser => accountUser.account.id === accountId);
    return currentAccountUser || null;
  }

  function getUserRole(userData: getUser, accountId: string): RoleCode {
    const currentAccountUser = getCurrentAccountUser(userData, accountId);
    return currentAccountUser?.roleCode || RoleCode.Viewer;
  }

  function changeAccount(userData: getUser, id: string) {
    const userRole = getUserRole(userData, id);
    localStorage.setItem(LocalStorageKeys.USER_ROLE, userRole);
    setAccountIdInPendo(id);
    setLastSelectedAccount(id);
  }

  function redirectToSelectedAccount(accountToDisplay: string): void {
    if (accountToDisplay) {
      history.push(Routes.Dashboard.getUrl({ accountId: accountToDisplay }));
    }
  }

  function accountSelectOnChangeHandler(event: React.ChangeEvent<{ value: unknown }>) {
    if (props.userData) {
      const accountId = event.target.value;
      if (typeof accountId === "string") {
        setAccountSelectValue(accountId);
        redirectToSelectedAccount(accountId);
        changeAccount(props.userData, accountId);
        props.closeSideNav();
      } else {
        throw new Error("accountId should be a string");
      }
    }
  }

  useEffect(() => {
    setAccountSelectValue(displayingAccountId);
  }, [displayingAccountId]);

  useEffect(() => {
    if (!props.isUserDataLoading && props.userData) {
      initializePendo(props.userData.me.id, accountId);
    }
  }, [accountId, props.isUserDataLoading, props.userData]);

  useEffect(() => {
    if (props.userData) {
      changeAccount(props.userData, displayingAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayingAccountId, props.userData]);

  if (!props.userData || props.isUserDataLoading) {
    return (
      <Box paddingRight={8} role="progressbar">
        <Skeleton variant="text" height={17} width={100} classes={{ root: classes.skeletonTitle }} />
        <Skeleton variant="text" height={10} width={100} classes={{ root: classes.skeletonLabel }} />
      </Box>
    );
  }

  return (
    <FormControl variant="outlined" classes={{ root: classes.formControl }} data-testid="account-select-wrapper">
      <InputLabel id="account-select-label" className={classes.label}>
        Automation Hub
      </InputLabel>
      <Select
        IconComponent={ExpandMoreRoundedIcon}
        classes={{ outlined: classes.outline }}
        className={classes.select}
        labelId="account-select-label"
        id="account-select-dropdown"
        data-testid="account-select-dropdown"
        data-pendo="auto-global-account-select"
        value={accountSelectValue}
        onChange={accountSelectOnChangeHandler}
        label="Automation Hub"
        MenuProps={getMenuProps()}
      >
        {props.userData.me.accounts.nodes.map(accountNode => (
          <MenuItem key={accountNode.id} value={accountNode.id}>
            {accountNode.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
