import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3127 1.5H5.64014C5.36399 1.5 5.14014 1.72386 5.14014 2V22C5.14014 22.2761 5.36399 22.5 5.64014 22.5H19.3127C19.5889 22.5 19.8127 22.2761 19.8127 22V2C19.8127 1.72386 19.5889 1.5 19.3127 1.5ZM5.64014 0C4.53557 0 3.64014 0.89543 3.64014 2V22C3.64014 23.1046 4.53557 24 5.64014 24H19.3127C20.4173 24 21.3127 23.1046 21.3127 22V2C21.3127 0.895431 20.4173 0 19.3127 0H5.64014ZM8.04321 10.939V19.56H16.9098V10.939H8.04321ZM7.88615 9.43896C7.14446 9.43896 6.54321 9.87254 6.54321 10.4074V20.0916C6.54321 20.6264 7.14446 21.06 7.88615 21.06H17.0668C17.8085 21.06 18.4098 20.6264 18.4098 20.0916V10.4074C18.4098 9.87254 17.8085 9.43896 17.0668 9.43896H7.88615ZM7.39551 4.57998C6.89845 4.57998 6.49551 4.98292 6.49551 5.47998C6.49551 5.97704 6.89845 6.37998 7.39551 6.37998H14.3955C14.8926 6.37998 15.2955 5.97704 15.2955 5.47998C15.2955 4.98292 14.8926 4.57998 14.3955 4.57998H7.39551Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientDashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="19" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3127 1.54297H5.64014C5.36399 1.54297 5.14014 1.76683 5.14014 2.04297V22.043C5.14014 22.3191 5.36399 22.543 5.64014 22.543H19.3127C19.5889 22.543 19.8127 22.3191 19.8127 22.043V2.04297C19.8127 1.76683 19.5889 1.54297 19.3127 1.54297ZM5.64014 0.0429688C4.53557 0.0429688 3.64014 0.938399 3.64014 2.04297V22.043C3.64014 23.1475 4.53557 24.043 5.64014 24.043H19.3127C20.4173 24.043 21.3127 23.1475 21.3127 22.043V2.04297C21.3127 0.938399 20.4173 0.0429688 19.3127 0.0429688H5.64014ZM6.54321 10.4504C6.54321 9.91551 7.14446 9.48193 7.88615 9.48193H17.0668C17.8085 9.48193 18.4098 9.91551 18.4098 10.4504V20.1346C18.4098 20.6694 17.8085 21.103 17.0668 21.103H7.88615C7.14446 21.103 6.54321 20.6694 6.54321 20.1346V10.4504ZM7.39551 4.62295C6.89845 4.62295 6.49551 5.02589 6.49551 5.52295C6.49551 6.02001 6.89845 6.42295 7.39551 6.42295H14.3955C14.8926 6.42295 15.2955 6.02001 15.2955 5.52295C15.2955 5.02589 14.8926 4.62295 14.3955 4.62295H7.39551Z"
          fill="url(#paint0_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.976562" y="0.0429688" width="24" height="24" fill="url(#paint1_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.05479"
          y1="19.4402"
          x2="18.5937"
          y2="20.2712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="2.89771"
          y1="19.4402"
          x2="21.226"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
