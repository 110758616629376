import gql from "graphql-tag";

export const getAvailableParentTestSuitesGql = gql`
  query getAvailableParentTestSuites(
    $accountId: ObjectID!
    $cursorNotLinkedTestSuites: String
    $cursorParentTestSuites: String
  ) {
    getAccount(id: $accountId) {
      id
      notLinkedTestSuites(first: 100, after: $cursorNotLinkedTestSuites, orderBy: [{ field: name, direction: ASC }]) {
        edges {
          node {
            name
            id
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
        }
      }
      parentTestSuites(first: 100, after: $cursorParentTestSuites, orderBy: [{ field: name, direction: ASC }]) {
        edges {
          node {
            name
            id
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
        }
      }
    }
  }
`;
