import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getBuildSchedules, getBuildSchedulesVariables } from "../../../../generated-graphql-interfaces";

export const getBuildSchedulesGql = gql`
  query getBuildSchedules($accountId: ObjectID!) {
    getAccount(id: $accountId) {
      id
      buildSchedules(first: 100) {
        totalCount
        nodes {
          name
          id
          repetitionRate
          startAt
          buildScheduleTestSuites {
            totalCount
          }
        }
      }
    }
  }
`;

export function useGetBuildSchedulesQuery(accountId: string) {
  return useQuery<getBuildSchedules, getBuildSchedulesVariables>(getBuildSchedulesGql, {
    variables: { accountId },
    fetchPolicy: "cache-first"
  });
}
