import { BuildStatus } from "../../../generated-graphql-interfaces";
import { BuildTestStatus } from "../../interfaces/BuildTestStatus";
import { dateTimeToHumanReadableFormat } from "../date/date";

export function createLastRunText(status: BuildTestStatus, updatedAt: string, lastRun: string) {
  const isTestSuiteRunning = status === BuildStatus.Running;
  const finishedBuildText =
    status === BuildStatus.Cancelled || status === BuildStatus.Aborted
      ? `Cancelled on ${dateTimeToHumanReadableFormat(updatedAt, "N/A")}`
      : dateTimeToHumanReadableFormat(lastRun, "N/A");

  return isTestSuiteRunning ? "Running" : finishedBuildText;
}
