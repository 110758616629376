import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(theme => ({
  panel: {
    boxShadow: "none",
    "&:before": {
      height: "0px"
    },
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    "&:nth-child(1)": {
      borderTop: "none"
    }
  },
  icon: { color: theme.palette.secondary.main, marginRight: theme.spacing(1) },
  panelSummary: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  title: { lineHeight: 1 }
}));

interface FaqExpansionPanelProps {
  config: { title: string; content: JSX.Element; testId?: string };
  index: number;
  isDefaultExpanded: boolean;
}

export function FaqExpansionPanel(props: FaqExpansionPanelProps) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(props.isDefaultExpanded);

  function handleChange() {
    setIsExpanded(isExpanded => !isExpanded);
  }

  function getExpansionPanelIcon() {
    return isExpanded ? <RemoveIcon className={classes.icon} /> : <AddIcon className={classes.icon} />;
  }

  return (
    <Accordion defaultExpanded={props.isDefaultExpanded} onChange={handleChange} className={classes.panel}>
      <AccordionSummary
        aria-controls={`panel${props.index}a-content`}
        id={`panel${props.index}a-header`}
        className={classes.panelSummary}
        data-cy={`${props.config.testId}-panel`}
        data-testid={`${props.config.testId}-panel`}
      >
        <Box display="flex" alignItems="flex-start">
          {getExpansionPanelIcon()}
          <Typography variant="h5" className={classes.title}>
            {props.config.title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails data-cy={`${props.config.testId}-content`} data-testid={`${props.config.testId}-content`}>
        {props.config.content}
      </AccordionDetails>
    </Accordion>
  );
}
