import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function PlayFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0398 11.3148C20.1417 12.1674 20.1417 13.8309 19.0398 14.6835C15.7098 17.2602 11.9914 19.2912 8.02366 20.7002L7.29883 20.9577C5.91209 21.4502 4.44734 20.512 4.25961 19.0805C3.73508 15.081 3.73508 10.9173 4.25961 6.91779C4.44734 5.48632 5.91211 4.54808 7.29883 5.0406L8.02366 5.29804C11.9914 6.70721 15.7098 8.73813 19.0398 11.3148Z"
      />
    </SvgIcon>
  );
}
