import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

export interface CiCdInstructionsProps {
  maxInstructionsStepsShow?: number;
}

const useStyles = makeStyles(theme => ({
  instructions: {
    marginBottom: "21px",
    paddingLeft: "15px"
  },
  instructionItem: {
    marginBottom: theme.spacing(2),
    paddingLeft: "10px",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5
  }
}));

export function CiCdInstructions(props: CiCdInstructionsProps): JSX.Element {
  const TOTAL_STEPS_COUNT = 3;
  const limit =
    props.maxInstructionsStepsShow !== undefined && Number(props.maxInstructionsStepsShow) >= -1
      ? props.maxInstructionsStepsShow
      : TOTAL_STEPS_COUNT;
  const classes = useStyles();
  return (
    <>
      <ol className={classes.instructions} data-cy="ci-cd-instruction-points" data-testid="ci-cd-instruction-points">
        {limit >= 1 && (
          <li className={classes.instructionItem}>
            Click &apos;Copy to clipboard&apos; to copy all CI/CD integration details onto your clipboard.
          </li>
        )}
        {limit >= 2 && (
          <li className={classes.instructionItem}>
            Paste details into your messaging platform of choice (email/slack/jira etc.) and send to an admin who has
            access to your CI/CD pipeline. They will need to follow the instructions to complete the integration.
          </li>
        )}
        {limit >= 3 && <li className={classes.instructionItem}>Finish the test suite setup.</li>}
      </ol>
    </>
  );
}
