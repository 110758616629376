import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function PlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4192 11.3148C20.5211 12.1674 20.5211 13.8309 19.4192 14.6835C16.0892 17.2602 12.3707 19.2912 8.40306 20.7002L7.67822 20.9577C6.29149 21.4502 4.82674 20.512 4.639 19.0805C4.11447 15.081 4.11447 10.9173 4.639 6.91779C4.82674 5.48632 6.2915 4.54808 7.67822 5.0406L8.40306 5.29804C12.3707 6.70721 16.0892 8.73813 19.4192 11.3148ZM18.5438 13.3757C18.7891 13.1859 18.7891 12.8154 18.5438 12.6255C15.2786 10.0991 11.6325 8.10765 7.74194 6.72589L7.00009 6.46241C6.63063 6.33119 6.20603 6.58127 6.15134 6.9983C5.6338 10.9445 5.6338 15.0568 6.15134 19.003C6.20603 19.42 6.63063 19.6701 7.00008 19.5389L7.74194 19.2755C11.6325 17.8937 15.2786 15.9022 18.5438 13.3757Z"
      />
    </SvgIcon>
  );
}
