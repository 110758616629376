import React from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import notFoundGraphic from "../../images/404-page-not-found.svg";
import { Routes } from "../../_common/routes/routes";

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 880,
    margin: "0 auto",
    padding: theme.spacing(6)
  },
  img: {
    width: "100%"
  },
  imgWrapper: {
    maxWidth: 290
  },
  header: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  text: {
    maxWidth: 400,
    marginBottom: theme.spacing(5)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  function handleGoBack() {
    history.goBack();
  }

  function handleGoHome() {
    history.push(Routes.Root.ROUTE);
  }

  return (
    <Box className={classes.wrapper} data-testid="not-found">
      <Box className={classes.imgWrapper}>
        <img
          src={notFoundGraphic}
          alt="404 not found graphic"
          className={classes.img}
          data-testid="not-found-graphic"
        />
      </Box>
      <Typography variant="h1" className={classes.header} color="textPrimary" data-testid="not-found-title">
        Oh no! Page not found
      </Typography>
      <Typography color="textPrimary" className={classes.text} data-testid="not-found-text">
        The page you are looking for isn&apos;t available. If you typed the URL, please make sure the spelling is
        correct. If you clicked on a link to get here, the link could be outdated. Try again or use the Go Home button
        below.
      </Typography>
      <Box>
        <Button onClick={handleGoBack} className={classes.button} variant="outlined" data-testid="go-back-button">
          Go back
        </Button>
        <Button
          onClick={handleGoHome}
          variant="contained"
          className={classes.button}
          data-testid="go-home-button"
          color="secondary"
        >
          Go home
        </Button>
      </Box>
    </Box>
  );
}
