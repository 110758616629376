import React from "react";
import { Box, Grid, TextField, CircularProgress, makeStyles } from "@material-ui/core";
import { ActionIconButton } from "../ActionIconButton/ActionIconButton";
import { DeleteIcon } from "../../svg/DeleteIcon";

export type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridSettings {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

interface DeletableInputProps {
  value: string;
  handleDelete: () => {};
  loading: boolean;
  id: string;
  label: string;
  textFieldGrid: GridSettings;
  deleteButtonGrid: GridSettings;
}

export function DeletableInput(props: DeletableInputProps) {
  return (
    <Grid container alignItems="center">
      <Grid item {...props.textFieldGrid}>
        <TextField
          fullWidth
          label={props.label}
          variant="filled"
          value={props.value}
          data-testid={`${props.id}-field`}
          data-pendo={`auto-${props.id}-row-field`}
          data-cy={`${props.id}-field`}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item {...props.deleteButtonGrid}>
        <Box component="span" display="flex" justifyContent="center">
          {props.loading ? (
            <CircularProgress size="2rem" data-testid={`delete-${props.id}-loading`} />
          ) : (
            <ActionIconButton
              variant="error"
              icon={<DeleteIcon />}
              onClick={props.handleDelete}
              data-cy={`delete-${props.id}`}
              data-testid={`delete-${props.id}-button`}
              data-pendo={`auto-${props.id}-button-delete`}
              includeBorder={true}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
