import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      backgroundColor: "white",
      minHeight: "100%"
    },
    logo: { maxWidth: "250px", marginBottom: theme.spacing(1) },
    loginWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 9, 4, 9),
      textAlign: "center",
      marginTop: 280,
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0, 3, 4, 3)
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 74
      }
    },
    informationBar: {
      background:
        "linear-gradient( rgba(10,17,36, 1),75%, rgba(10,17,36, 0)),linear-gradient(to right, #6B00EF, #FF469C,#FFB747)",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        display: "block"
      }
    },
    errorsList: {
      listStyleType: "none"
    },
    errorsWrapper: {
      marginTop: theme.spacing(3)
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      marginBottom: theme.spacing(8)
    },
    dcLogoWrapper: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(10)
      }
    }
  })
);
