import React, { useContext } from "react";
import { RouteProps } from "react-router-dom";
import { AuthContext } from "../../contexts/Permissions/AuthContext";
import { AuthRouteAuthorised } from "./AuthRouteAuthorised";
import { AuthRouteNotAuthorised } from "./AuthRouteNotAuthorised";

export interface AuthRouteProps extends RouteProps {
  // Note: this should be set to false for pages that require breadcrumbs. This is because the breadcrumbs need to take
  // up the full width of the page. The <PageContainer /> component can then be used to wrap any content below the breadcrumbs.
  includePageContainer?: boolean;
}

export function AuthRoute(props: AuthRouteProps): JSX.Element {
  const { includePageContainer = true, ...otherProps } = props;
  const authContext = useContext(AuthContext);

  if (!authContext.isAuthorised) return <AuthRouteNotAuthorised {...otherProps} />;

  return <AuthRouteAuthorised {...otherProps} includePageContainer={includePageContainer} />;
}
