import {
  makeStyles,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import automatorLogo from "../../../images/automation-hub-logo.svg";

const useStyles = makeStyles(theme => ({
  dialog: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(4.5, 2, 2, 3.5),
    maxWidth: 450
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: 18
    }
  },
  dialogContent: {
    padding: 0
  },
  dialogContentText: {
    marginBottom: theme.spacing(6)
  },
  dialogActions: {
    padding: 0
  },
  icon: {
    marginRight: theme.spacing(3),
    color: theme.palette.error.main
  },
  logoWrapper: {
    position: "fixed",
    top: theme.spacing(3),
    left: theme.spacing(3),
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    fontSize: 16
  },
  logo: {
    width: 32,
    marginRight: theme.spacing(1)
  }
}));

interface Props {
  isOpen: boolean;
  handleConfirmation: () => void;
}

export function ExpiredTokenDialog(props: Props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.isOpen}
      aria-labelledby="expired-token-dialog-title"
      aria-describedby="expired-token-dialog-description"
      classes={{ paper: classes.dialog }}
      BackdropProps={{
        style: {
          background:
            "linear-gradient( rgba(10,17,36, 1),75%, rgba(10,17,36, 0)),linear-gradient(to right, #6B00EF, #FF469C,#FFB747)"
        }
      }}
      fullWidth
    >
      <div className={classes.logoWrapper}>
        <img src={automatorLogo} alt="Logo" data-testid="nav-logo" className={classes.logo} />
        <span>Automation Hub</span>
      </div>
      <InfoOutlinedIcon className={classes.icon} />
      <Box width="100%">
        <DialogTitle
          id="expired-token-dialog-title"
          className={classes.dialogTitle}
          color="textPrimary"
          data-cy="expired-token-dialog-title"
        >
          Whoops, your session has expired
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            className={classes.dialogContentText}
            id="expired-token-dialog-description"
            color="textPrimary"
          >
            You will be redirected to the Login page.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={props.handleConfirmation}
            color="secondary"
            variant="contained"
            data-cy="expired-token-dialog-button"
          >
            Ok
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
