import React from "react";
import { Box, Divider, Hidden, Paper, Typography } from "@material-ui/core";
import { formatSchedulingInterval, getTimeDescription } from "../utils/schedulerTime";
import { useGetBuildScheduleWithTestSuitesQuery } from "./graphql/useGetBuildScheduleWithTestSuitesQuery";
import { Alert } from "@material-ui/lab";
import noSchedulesGraphic from "../../../images/no-schedules-empty-state.svg";
import { useSnackbar } from "notistack";
import { useDeleteBuildScheduleMutation } from "../graphql/useDeleteBuildScheduleMutation";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import clsx from "clsx";
import { useUnlinkTestSuiteFromBuildSchedule } from "./ScheduleSettings/graphql/useUnlinkTestSuiteFromBuildSchedule";
import {
  BuildScheduleRepetitionRate,
  setBuildScheduleTestSuitesVariables,
  updateBuildScheduleVariables
} from "../../../generated-graphql-interfaces";
import { useSetBuildScheduleTestSuitesMutation } from "../graphql/useSetBuildScheduleTestSuitesMutation";
import { useUpdateBuildScheduleMutation } from "./graphql/useUpdateBuildSchedule";
import { sortedArrayAreSame } from "../../../_common/utils/array/comparison/arrayCompare";
import { useStyles } from "./SchedulerDetailViewStyles";
import { SchedulerTestSuiteListItem } from "./SchedulerTestSuiteListItem";
import { schedulerSuiteItemInformationFactory } from "../../../_common/utils/testSuiteItemInformationFactory/testSuiteItemInformationFactory";
import { SchedulerDetailViewHeader } from "./SchedulerDetailViewHeader";
import { LoadMoreItems } from "../../../_common/components/LoadMoreItems/LoadMoreItems";
import { SchedulerDetailLoadingView } from "./SchedulerDetailLoadingView";
import { SchedulerTestSuiteListTable } from "./SchedulerTestSuiteListTable/SchedulerTestSuiteListTable";

export interface SchedulerDetailViewProps {
  schedulerId: string;
  onUpdateCompleted: () => void;
}

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
export function SchedulerDetailView(props: SchedulerDetailViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const accountId = useAccountRouteMatch();

  const classes = useStyles();
  const { data, error, loading, refetch, loadMore, isFetchingMore } = useGetBuildScheduleWithTestSuitesQuery({
    buildScheduleId: props.schedulerId,
    itemsPerRequest: 20,
    cursor: ""
  });
  const [deleteBuildSchedule] = useDeleteBuildScheduleMutation();
  const [unlinkTestSuiteFromBuildSchedule] = useUnlinkTestSuiteFromBuildSchedule();

  const schedule = data?.node?.__typename === "BuildSchedule" ? data.node : null;

  const [updateBuildSchedule] = useUpdateBuildScheduleMutation();
  const [setScheduleTestSuites] = useSetBuildScheduleTestSuitesMutation();

  async function handleEditSchedule(
    input: {
      accountId?: string;
      name: string;
      repetitionRate?: BuildScheduleRepetitionRate | undefined | null;
      startAt: Date | undefined;
      buildScheduleId?: string;
    },
    testSuiteIds: string[]
  ) {
    try {
      const variables: updateBuildScheduleVariables = {
        input: {
          name: input.name,
          repetitionRate: input.repetitionRate,
          startAt: input.startAt,
          buildScheduleId: input.buildScheduleId
        }
      };
      await updateBuildSchedule({ variables });
      const previousTestSuitesId = schedule?.buildScheduleTestSuites.nodes.map(node => node.testSuite.id as string);
      if (!sortedArrayAreSame(previousTestSuitesId, testSuiteIds)) {
        const variables: setBuildScheduleTestSuitesVariables = {
          testSuiteIds,
          buildScheduleId: input.buildScheduleId
        };
        await setScheduleTestSuites({ variables });
      }
      props.onUpdateCompleted();
      enqueueSnackbar("Schedule edited successfully", { variant: "success" });
      refetch();
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please refresh the page and try again.", {
        variant: "error",
        persist: true
      });
    }
  }

  async function handleConfirmScheduleDeletion() {
    try {
      await deleteBuildSchedule({ variables: { buildScheduleId: props.schedulerId } });
      enqueueSnackbar("Schedule deleted successfully", { variant: "success" });
      history.push(Routes.FrontendScheduler.getUrl({ accountId, schedulerId: undefined }));
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please refresh the page and try again.", {
        variant: "error",
        persist: true
      });
    }
  }

  async function removeTestSuite(connectionId: string) {
    try {
      await unlinkTestSuiteFromBuildSchedule({ variables: { buildScheduleTestSuiteId: connectionId } });
      enqueueSnackbar(`Removed from ${schedule?.name}`, { variant: "success" });
      props.onUpdateCompleted();
      refetch();
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please refresh the page and try again.", {
        variant: "error",
        persist: true
      });
    }
  }

  if (loading) return <SchedulerDetailLoadingView />;

  if (error || !data) {
    return (
      <Paper className={classes.listPaper} data-testid="scheduler-detail-error">
        <Alert severity="error" className={classes.margin}>
          Something went wrong. Please refresh the page and try again.
        </Alert>
      </Paper>
    );
  }

  return (
    <Paper className={classes.listPaper} data-testid="scheduler-detail-view">
      {schedule && (
        <>
          <SchedulerDetailViewHeader
            scheduleId={schedule.id}
            scheduleName={schedule.name}
            handleConfirmScheduleDeletion={handleConfirmScheduleDeletion}
            handleEditSchedule={handleEditSchedule}
          />
          <Divider className={classes.divider} />

          <Box className={classes.timesBox}>
            <Box className={classes.timeBoxInfoBlock}>
              <Typography className={classes.text}>Starts:</Typography>
              <Typography className={clsx(classes.text, classes.darkerText)} data-testid="scheduler-detail-view-time">
                {getTimeDescription(schedule?.startAt)}
              </Typography>
            </Box>
            <Box className={classes.timeBoxInfoBlock}>
              <Typography className={classes.text}>Repeat:</Typography>
              <Typography
                className={clsx(classes.text, classes.darkerText)}
                data-testid="scheduler-detail-view-frequency"
              >
                {formatSchedulingInterval(new Date(schedule.startAt), schedule.repetitionRate)}
              </Typography>
            </Box>
          </Box>

          <Divider className={classes.divider} />
          <Box className={classes.tableHeadingsBox}>
            <Typography
              variant="body1"
              className={classes.tableHeadings}
              data-testid="scheduler-detail-view-test-suites-coutner"
            >
              {schedule.buildScheduleTestSuites.totalCount} scheduled test suite{" "}
              {schedule.buildScheduleTestSuites.totalCount === 1 ? "run" : "runs"}
            </Typography>
          </Box>

          {!schedule.buildScheduleTestSuites.nodes.length && (
            <Box data-testid="no-test-suites-message" className={classes.imgWrapper}>
              <img src={noSchedulesGraphic} className={classes.img} alt="No test suites" />
              <Typography className={classes.noTestSuitesText}>You don’t have any scheduled test suites yet</Typography>
            </Box>
          )}
          <Box className={classes.testSuitesBox}>
            {schedule.buildScheduleTestSuites.nodes.length ? (
              <Hidden mdDown>
                <Box className={classes.testSuiteBoxTableHeaderBox}>
                  <SchedulerTestSuiteListTable />
                </Box>
              </Hidden>
            ) : null}
            {schedule.buildScheduleTestSuites.nodes.map(node => {
              const testSuite = schedulerSuiteItemInformationFactory(node);
              return (
                <Box
                  data-testid="scheduler-detail-testsuite-item"
                  data-cy={`scheduler-detail-testsuite-item-${testSuite.id}`}
                  key={node.testSuite.id}
                >
                  <SchedulerTestSuiteListItem
                    {...testSuite}
                    editable={false}
                    onActionMenuEvent={() => {
                      removeTestSuite(node.id);
                      return new Promise(resolve => resolve(false));
                    }}
                    nextRunAt={schedule.nextRunAt}
                  />
                </Box>
              );
            })}
            {schedule.buildScheduleTestSuites.nodes.length ? (
              <Box my={3}>
                <LoadMoreItems
                  isLoading={isFetchingMore || loading}
                  onClick={loadMore}
                  dataId="scheduler-detail-view"
                  currentCount={schedule.buildScheduleTestSuites.nodes.length}
                  totalCount={schedule.buildScheduleTestSuites.totalCount}
                />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Paper>
  );
}
