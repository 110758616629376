import {
  getTestSuiteReport_node_Build,
  getTestSuiteReport_node_Build_testResults_nodes,
  Severity
} from "../../../generated-graphql-interfaces";

export enum TestsToDisplayOption {
  ALL = "all",
  WARNING = "warning",
  PASS = "pass",
  FAIL = "fail"
}

export function getTotalTestCount(suiteReport: getTestSuiteReport_node_Build) {
  return (suiteReport.failedTestCount || 0) + (suiteReport.warnedTestCount || 0) + (suiteReport.passedTestCount || 0);
}

function displayFail(testsToDisplay: TestsToDisplayOption) {
  return testsToDisplay === TestsToDisplayOption.FAIL;
}
function displayWarning(testsToDisplay: TestsToDisplayOption) {
  return testsToDisplay === TestsToDisplayOption.WARNING;
}
function displayPass(testsToDisplay: TestsToDisplayOption) {
  return testsToDisplay === TestsToDisplayOption.PASS;
}
function displayAll(testsToDisplay: TestsToDisplayOption) {
  return testsToDisplay === TestsToDisplayOption.ALL;
}

export function didTestPassed(testResult: getTestSuiteReport_node_Build_testResults_nodes, displayPass: boolean) {
  return displayPass && !!testResult.passed;
}

export function isTestResultWarninig(
  testResult: getTestSuiteReport_node_Build_testResults_nodes,
  displayWarning: boolean
) {
  return displayWarning && !testResult.passed && testResult.severity === "Warning";
}

export function isTestResultFail(testResult: getTestSuiteReport_node_Build_testResults_nodes, displayFail: boolean) {
  return displayFail && !testResult.passed && testResult.severity === "Fail";
}

export function isTestToBeDisplayed(
  testResult: getTestSuiteReport_node_Build_testResults_nodes,
  testsToDisplay: TestsToDisplayOption
) {
  const pass = didTestPassed(testResult, displayPass(testsToDisplay));
  const warning = isTestResultWarninig(testResult, displayWarning(testsToDisplay));
  const fail = isTestResultFail(testResult, displayFail(testsToDisplay));
  return pass || warning || fail || displayAll(testsToDisplay);
}

export function sortTestResults(testResults: getTestSuiteReport_node_Build_testResults_nodes[]) {
  // eslint-disable-next-line fp/no-mutating-methods
  const testResultsSortedByName = [...testResults].sort((a, b) =>
    a.reportTemplate.name.localeCompare(b.reportTemplate.name)
  );
  const failedTests = testResultsSortedByName.filter(test => test.severity === Severity.Fail && !test.passed);
  const warningTests = testResultsSortedByName.filter(test => test.severity === Severity.Warning && !test.passed);
  const passedTests = testResultsSortedByName.filter(test => test.passed);

  return [...failedTests, ...warningTests, ...passedTests];
}

export function isSelectedtTypeValid(selectedType: TestsToDisplayOption | string | undefined) {
  return (
    selectedType === TestsToDisplayOption.ALL ||
    selectedType === TestsToDisplayOption.FAIL ||
    selectedType === TestsToDisplayOption.PASS ||
    selectedType === TestsToDisplayOption.WARNING
  );
}
