import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CiCdForm } from "../../../../_common/components/CiCdForm/CiCdForm";
import { CiCdListInstructions } from "../../../../_common/components/CiCdForm/CiCdListInstructions";
import { CiCdToolsList } from "../../../../_common/components/CiCdForm/CiCdToolsList";
import { HidableBlock } from "../../../../_common/components/HideableBlock/HideableBlock";

const useStyles = makeStyles(theme => ({
  h3: {
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3)
    }
  },
  h4: {
    fontSize: 16,
    fontWeight: 600
  },
  smallParagraph: {
    fontSize: 14,
    marginBottom: theme.spacing(1)
  },
  ciCdFormWrapper: {
    maxWidth: "430px"
  },
  maxWidth600: {
    maxWidth: "600px"
  },
  instructionsHeader: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: theme.spacing(2)
  }
}));

interface ConnectViaCiCdProps {
  testSuiteId: string;
  testSuiteName: string;
  testSuiteSitePrimary: string;
}

export function ConnectViaCiCd(props: ConnectViaCiCdProps) {
  const classes = useStyles();

  return (
    <>
      <Box marginBottom={7}>
        <Typography className={classes.h3} color="textPrimary" variant="h3">
          Connect to CI/CD
        </Typography>
      </Box>
      <Box data-testid="connect-via-ci-cd" marginBottom={6}>
        <HidableBlock
          title="Connect CI/CD tool to your test suite"
          descriptor="instructions"
          dataIdDescriptor="ci-cd-block"
        >
          <Box className={classes.maxWidth600}>
            <Typography variant="body2" className={classes.smallParagraph}>
              Connect your test suite to your CI/CD pipeline with the tool of your choice.
            </Typography>
            <Typography variant="body2" className={classes.smallParagraph}>
              DeepCrawl Automator can connect with all major CI/CD tools:
            </Typography>
            <CiCdToolsList />
            <Typography variant="body2" className={classes.instructionsHeader}>
              Instructions
            </Typography>
            <CiCdListInstructions />
          </Box>
        </HidableBlock>
      </Box>
      <Box marginBottom={3}>
        <Box marginBottom={1}>
          <Typography variant="h3" color="textPrimary" className={classes.h4}>
            CI/CD integration details
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <Divider />
        </Box>

        <Box className={classes.ciCdFormWrapper}>
          <CiCdForm
            testSuiteId={props.testSuiteId}
            testSuiteName={props.testSuiteName}
            testSuiteSitePrimary={props.testSuiteSitePrimary}
          />
        </Box>
      </Box>
    </>
  );
}
