import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function TestSuitesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17.0916 13.2317C17.0916 12.7219 16.6783 12.3086 16.1685 12.3086H8.78392C8.27412 12.3086 7.86084 12.7219 7.86084 13.2317C7.86084 13.7415 8.27412 14.1547 8.78392 14.1547H16.1685C16.6783 14.1547 17.0916 13.7415 17.0916 13.2317Z" />
      <path d="M17.0916 18.1535C17.0916 17.6438 16.6783 17.2305 16.1685 17.2305H8.78392C8.27412 17.2305 7.86084 17.6438 7.86084 18.1535C7.86084 18.6633 8.27412 19.0766 8.78392 19.0766H16.1685C16.6783 19.0766 17.0916 18.6633 17.0916 18.1535Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3226 0C4.45334 0 2.93799 1.51535 2.93799 3.38462V20.6154C2.93799 22.4847 4.45334 24 6.3226 24H18.6303C20.4996 24 22.0149 22.4847 22.0149 20.6154V7.03712C22.0149 6.56848 21.8621 6.11263 21.5796 5.73871L17.89 0.855434C17.4829 0.316677 16.8468 0 16.1715 0H6.3226ZM4.78414 3.38462C4.78414 2.53494 5.47293 1.84615 6.3226 1.84615H15.2457V7.25791C15.2457 7.7677 15.659 8.18098 16.1688 8.18098H20.1688V20.6154C20.1688 21.4651 19.48 22.1538 18.6303 22.1538H6.3226C5.47293 22.1538 4.78414 21.4651 4.78414 20.6154V3.38462Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientTestSuitesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="0" width="21" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2457 0.350661C15.2457 0.180729 15.108 0.0429688 14.938 0.0429688H6.3226C4.45334 0.0429688 2.93799 1.55832 2.93799 3.42758V20.6584C2.93799 22.5276 4.45334 24.043 6.3226 24.043H18.6303C20.4996 24.043 22.0149 22.5276 22.0149 20.6584V8.53166C22.0149 8.36173 21.8772 8.22397 21.7072 8.22397H16.1688C15.659 8.22397 15.2457 7.81069 15.2457 7.30089V0.350661ZM16.1688 12.3507C16.6785 12.3507 17.0918 12.764 17.0918 13.2737C17.0918 13.7835 16.6785 14.1968 16.1688 14.1968H8.78414C8.27435 14.1968 7.86107 13.7835 7.86107 13.2737C7.86107 12.764 8.27435 12.3507 8.78414 12.3507H16.1688ZM16.1688 17.2737C16.6785 17.2737 17.0918 17.687 17.0918 18.1968C17.0918 18.7066 16.6785 19.1199 16.1688 19.1199H8.78414C8.27435 19.1199 7.86107 18.7066 7.86107 18.1968C7.86107 17.687 8.27435 17.2737 8.78414 17.2737H16.1688Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M17.0918 0.750304C17.0918 0.523227 17.3288 0.379055 17.5056 0.521688C17.6544 0.641824 17.7882 0.782574 17.9023 0.941578L21.6108 6.10822C21.6952 6.22585 21.6038 6.37851 21.4589 6.37851H17.3995C17.2295 6.37851 17.0918 6.24075 17.0918 6.07082V0.750304Z"
          fill="url(#paint1_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.476562" y="0.0429688" width="24" height="24" fill="url(#paint2_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="4.46505"
          y1="19.4402"
          x2="19.0708"
          y2="20.408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="17.4561"
          y1="5.24438"
          x2="20.9417"
          y2="5.468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="2.39771"
          y1="19.4402"
          x2="20.726"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
