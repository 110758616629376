import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTestSuiteQuery } from "./graphql/useTestSuiteQuery";
import { Box, Grid, Hidden, Typography, makeStyles } from "@material-ui/core";
import { TestSuiteItemLinks } from "../TestSuiteList/components/TestSuiteItemLinks";
import { getTestSuite_node_TestSuite, getTestSuiteChildren_node_TestSuite } from "../../generated-graphql-interfaces";
import { CustomBreadcrumbs } from "../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { permissionsContext } from "../../_common/contexts/Permissions/Permissions.context";
import { TemplateSettings } from "./components/TemplateSettings/TemplateSettings";
import { useTestSuiteChildrenQuery } from "./graphql/useTestSuiteChildrenQuery";
import { getBreadcrumbContent } from "./utils/UpdateTestSuiteUtils";
import { Alert } from "@material-ui/lab";
import { UpdateTestSuiteSteps } from "./UpdateTestSuiteSteps";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { RunTestSuiteNowButton } from "./components/ConnectCiCdTool/components/RunTestSuiteNowButton";
import { SaveChangesProvider } from "./SaveChangesProvider";
import { UpdateTestSuiteLoadingView } from "./UpdateTestSuiteLoadingView";
import { BuildStatusProvider } from "./contexts/BuildStatusProvider/BuildStatusProvider";
import { useReportTemplates } from "./components/ChooseTestsForm/graphql/useReportTemplates";

export interface UpdateTestSuiteProps {
  match: {
    params: {
      testSuiteId: string;
    };
  };
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "26px",
    fontWeight: 500,
    margin: theme.spacing(0)
  },
  header: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2.5, 3, 2.5)
    }
  }
}));

export type StepChangeHandler = (event?: React.ChangeEvent<{}>, isExpanded?: boolean) => void;

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export const UpdateTestSuite = withRouter((props: UpdateTestSuiteProps) => {
  const classes = useStyles();
  const { permissions } = useContext(permissionsContext);
  const [expandedStep, setExpandedStep] = useState("");
  const useReportTemplatesObject = useReportTemplates("");

  const {
    loading,
    data,
    error,
    refetch: refetchTestSuite
  } = useTestSuiteQuery({
    id: props.match.params.testSuiteId,
    hasJiraAccess: permissions.jiraIntegrationAccess,
    hasSmartThresholdAccess: permissions.automaticThresholdAccess
  });

  const {
    data: testSuiteChildrenData,
    loading: childrendLoading,
    error: childrenError,
    fetchMore: fetchMoreChildren,
    isFetchingMore: isFetchingMoreChildren
  } = useTestSuiteChildrenQuery({
    id: props.match.params.testSuiteId
  });

  const accountId = useAccountRouteMatch();
  const testSuite = data?.node as getTestSuite_node_TestSuite;

  const [smartThresholdSettings, setSmartThresholdSettings] = useState({
    isEnabled: testSuite?.automaticThreshold ?? false,
    enabledForAllTests: testSuite?.automaticThresholdEnabledForAll ?? true
  });

  useEffect(() => {
    if (
      testSuite &&
      testSuite.automaticThreshold !== undefined &&
      testSuite.automaticThresholdEnabledForAll !== undefined
    ) {
      setSmartThresholdSettings({
        isEnabled: testSuite.automaticThreshold,
        enabledForAllTests: testSuite.automaticThresholdEnabledForAll
      });
    }
  }, [testSuite]);

  if (error) {
    return (
      <Alert severity="error" data-testid="update-test-suite-error">
        {error.message}
      </Alert>
    );
  }
  if (loading) return <UpdateTestSuiteLoadingView />;

  if (!data || !testSuite || data?.node?.__typename !== "TestSuite") {
    return <Alert severity="info">No data.</Alert>;
  }

  const hasChildren = !!(testSuiteChildrenData?.node as getTestSuiteChildren_node_TestSuite)?.children?.edges.length;

  const templateSettings = (
    <TemplateSettings
      loadError={!!childrenError}
      loading={childrendLoading}
      parent={testSuite.parent}
      childrenTestSuites={(testSuiteChildrenData?.node as getTestSuiteChildren_node_TestSuite)?.children}
      testSuiteId={testSuite.id}
      testSuiteName={testSuite.name}
      isFetchingMoreChildren={isFetchingMoreChildren}
      loadMoreChildren={fetchMoreChildren}
      isAutomaticThresholdEnabled={smartThresholdSettings.isEnabled}
    />
  );

  function handleStepChange(step: string, shouldExpandNextStep?: boolean): StepChangeHandler {
    return (_?: React.ChangeEvent<{}>, isExpanded?: boolean) => {
      setExpandedStep(shouldExpandNextStep || isExpanded ? step : "");
    };
  }

  return (
    <SaveChangesProvider>
      <BuildStatusProvider testSuiteId={testSuite.id}>
        <CustomBreadcrumbs content={getBreadcrumbContent(accountId)} />
        <PageContainer>
          <Grid container item lg={9} md={12} alignItems="center" className={classes.header}>
            <Grid item xs={8}>
              <Typography variant="h1" className={classes.title} data-testid="update-test-suite-title">
                {testSuite.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <RunTestSuiteNowButton
                  testId="run-test-suite-now-top"
                  refetchTestSuite={refetchTestSuite}
                  testSuiteId={props.match.params.testSuiteId}
                  pendoId="auto-run-now-btn-update-testsuite"
                />
                <TestSuiteItemLinks
                  pendoIdSuffix="edit-test-suite"
                  editable={false}
                  clonable={true}
                  deletable={true}
                  id={props.match.params.testSuiteId}
                  name={testSuite.name}
                  isTemplate={
                    !!(testSuiteChildrenData?.node as getTestSuiteChildren_node_TestSuite)?.children?.totalCount
                  }
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Hidden lgUp>
              <Grid item xs={12}>
                {templateSettings}
              </Grid>
            </Hidden>
            <Grid item lg={9} md={12}>
              <UpdateTestSuiteSteps
                hasChildren={hasChildren}
                smartThresholdSettings={smartThresholdSettings}
                setSmartThresholdSettings={setSmartThresholdSettings}
                testSuite={testSuite}
                useReportTemplateObject={useReportTemplatesObject}
                handleStepChange={handleStepChange}
                expandedStep={expandedStep}
                refetchTestSuite={refetchTestSuite}
                testSuiteId={testSuite.id}
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={3}>
                {templateSettings}
              </Grid>
            </Hidden>
          </Grid>
        </PageContainer>
      </BuildStatusProvider>
    </SaveChangesProvider>
  );
});
