import {
  BuildStatus,
  BuildTriggerType,
  getBuildsStatuses_node_TestSuite_builds_nodes
} from "../../../generated-graphql-interfaces";

export function isPriorityBuildRunning(builds: getBuildsStatuses_node_TestSuite_builds_nodes[]) {
  const priorityBuilds =
    builds &&
    builds.filter(
      build =>
        (build.status === BuildStatus.Running || build.status === BuildStatus.Queued) &&
        build.triggerType !== BuildTriggerType.Schedule
    );
  return priorityBuilds && priorityBuilds.length > 0;
}
