import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core";
import { PendoTagProps } from "../../../typings/pendo.prototype";

const useStyles = makeStyles(theme => ({
  icon: { color: theme.palette.grey[800] },
  link: {
    display: "flex",
    alignItems: "center",
    color: "#999999",
    textDecoration: "none"
  }
}));

export interface StepIntoIconProps extends PendoTagProps {
  linkTo: string;
  children?: ReactNode;
  isLinkExternal: boolean;
}

export function StepIntoIcon(props: StepIntoIconProps) {
  const { linkTo, children, isLinkExternal, ...rest } = props;
  const classes = useStyles();
  return (
    <div data-testid="step-into-icon" data-cy="step-into-icon" {...rest}>
      {isLinkExternal ? (
        <a className={classes.link} target="_blank" rel="noopener noreferrer" href={linkTo}>
          <span>{children}</span>
          <ChevronRightIcon fontSize="large" className={classes.icon} />
        </a>
      ) : (
        <Link to={linkTo} className={classes.link}>
          <span>{children}</span>
          <ChevronRightIcon fontSize="large" className={classes.icon} />
        </Link>
      )}
    </div>
  );
}
