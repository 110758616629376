import {
  getTestSuite_node_TestSuite,
  TestSuiteLocationCode,
  getTestSuite_node_TestSuite_customDns
} from "../../../../generated-graphql-interfaces";
import { dummyAuthPassword } from "./ConnectSiteForm";
import { ConnectSiteFormValues } from "./ErrorEffect";

const DEFAULT_HEADER_USER_AGENT = "Googlebot/2.1 (+http://www.google.com/bot.html)";
const DEFAULT_HEADER_USER_AGENT_SHORT = "googlebot";

function getTestSiteTestUser(testSuiteToParse: getTestSuite_node_TestSuite) {
  return testSuiteToParse.siteTestUser || "";
}

function getTestSuiteLocation(testSuiteToParse: getTestSuite_node_TestSuite) {
  return testSuiteToParse.location?.code || TestSuiteLocationCode.Default;
}

function getCustomHeaderUserAgent(testSuiteToParse: getTestSuite_node_TestSuite) {
  return testSuiteToParse.customHeaderUserAgent || DEFAULT_HEADER_USER_AGENT;
}

function getCustomHeaderUserAgentShort(testSuiteToParse: getTestSuite_node_TestSuite) {
  return testSuiteToParse.customHeaderUserAgentShort || DEFAULT_HEADER_USER_AGENT_SHORT;
}

function getCustomDns(testSuiteToParse: getTestSuite_node_TestSuite) {
  return testSuiteToParse.customDns || [];
}

function getEmptyInitialValues(): ConnectSiteFormValues {
  return {
    name: "",
    sitePrimary: "",
    siteTestUser: "",
    siteTestPass: "",
    location: TestSuiteLocationCode.Default,
    customHeaderUserAgent: DEFAULT_HEADER_USER_AGENT,
    customHeaderUserAgentShort: DEFAULT_HEADER_USER_AGENT_SHORT,
    customDns: [] as getTestSuite_node_TestSuite_customDns[]
  };
}

function parseTestSuiteToInitialValues(
  testSuiteToParse: getTestSuite_node_TestSuite,
  isAuthPasswordSet: boolean
): ConnectSiteFormValues {
  return {
    name: testSuiteToParse.name,
    sitePrimary: testSuiteToParse.sitePrimary,
    siteTestUser: getTestSiteTestUser(testSuiteToParse),
    siteTestPass: isAuthPasswordSet ? dummyAuthPassword : "",
    location: getTestSuiteLocation(testSuiteToParse),
    customHeaderUserAgent: getCustomHeaderUserAgent(testSuiteToParse),
    customHeaderUserAgentShort: getCustomHeaderUserAgentShort(testSuiteToParse),
    customDns: getCustomDns(testSuiteToParse)
  };
}

export function getInitialValues(
  testSuiteToParse: getTestSuite_node_TestSuite | undefined,
  isAuthPasswordSet: boolean
): ConnectSiteFormValues {
  if (testSuiteToParse) {
    return parseTestSuiteToInitialValues(testSuiteToParse, isAuthPasswordSet);
  } else {
    return getEmptyInitialValues();
  }
}
