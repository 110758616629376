import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { AlertClassKey } from "@material-ui/lab";
import { PropsFunc } from "../overrides";
import { colorNavy, colorSecondary, colorError, colorWarning, colorDeepcrawlGreen, colorGrey } from "../palette";

export const muiAlert:
  | Partial<Record<AlertClassKey, CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>>
  | undefined = {
  root: {
    border: `1px solid ${colorGrey[400]}`,
    borderRadius: 4
  },
  standardInfo: {
    backgroundColor: "#FFFFFF",
    color: colorNavy[500],
    "& svg": {
      color: colorSecondary.main
    }
  },
  standardError: {
    backgroundColor: "#FFFFFF",
    color: colorNavy[500],
    "& svg": {
      color: colorError.main
    }
  },
  standardWarning: {
    backgroundColor: "#FFFFFF",
    color: colorNavy[500],
    "& svg": {
      color: colorWarning.main
    }
  },
  standardSuccess: {
    backgroundColor: "#FFFFFF",
    color: colorNavy[500],
    "& svg": {
      color: colorDeepcrawlGreen.main
    }
  }
};
