import React from "react";
import { TestSuiteListItem } from "./components/TestSuiteListItem";
import { HomeIntro } from "./components/HomeIntro/HomeIntro";
import { getNextTestSuites_getAccount_testSuites } from "../../generated-graphql-interfaces";
import { Box, Grid, Hidden, makeStyles, Paper } from "@material-ui/core";
import { TestSuiteCompactList } from "./components/TestSuiteCompactList";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { testSuiteItemInformationFactory } from "../../_common/utils/testSuiteItemInformationFactory/testSuiteItemInformationFactory";
import { TestSuiteListTable } from "../../_common/components/TestSuiteListTable/TestSuiteListTable";
import { Skeleton } from "@material-ui/lab";

export interface TestSuiteListProps {
  testSuites?: getNextTestSuites_getAccount_testSuites | null;
  totalTestSuiteCount: number | undefined;
  isLoading: boolean;
  refetchData: () => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%"
  },
  paper: {
    padding: theme.spacing(4, 0, 4, 0)
  },
  compactView: {
    marginBottom: theme.spacing(2)
  },
  tableBox: {
    margin: theme.spacing(4, 0, 4, 0)
  }
}));

// eslint-disable-next-line complexity
export function TestSuiteList(props: TestSuiteListProps): JSX.Element {
  const classes = useStyles();
  const testSuitesItems = props.testSuites?.edges.map(edge => edge.node).map(testSuiteItemInformationFactory) || [];
  const notYetRunTestSuites = testSuitesItems.filter(suite => suite.status === "NotYetRun");
  const alreadyRunTestSuites = testSuitesItems.filter(suite => suite.status !== "NotYetRun");

  return (
    <Grid container>
      <PageHeader mainText="Test Suites" includeAddTestSuite={true} pendoIdSuffix="test-suite-list" />

      {props.isLoading && <Skeleton variant="rect" height={600} width="100%" role="progressbar" />}

      {!props.isLoading && (
        <>
          {testSuitesItems.length === 0 && props.totalTestSuiteCount === 0 ? (
            <Grid className={classes.container}>
              <Paper className={classes.paper}>
                <HomeIntro />
              </Paper>
            </Grid>
          ) : (
            <>
              {notYetRunTestSuites.length > 0 && (
                <Grid xs={12} item className={classes.compactView}>
                  <TestSuiteCompactList testRuns={notYetRunTestSuites} refetchData={props.refetchData} />
                </Grid>
              )}

              {alreadyRunTestSuites.length > 0 && (
                <Grid item xs={12}>
                  <Hidden mdDown>
                    <Box className={classes.tableBox}>
                      <TestSuiteListTable />
                    </Box>
                  </Hidden>
                  {alreadyRunTestSuites.map(suite => (
                    <span key={suite.id}>
                      <TestSuiteListItem {...suite} refetchData={props.refetchData} />
                    </span>
                  ))}
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
}
