import React from "react";
import { useUpdateConnectSiteMutation } from "./graphql/useUpdateConnectSiteMutation";
import { ConnectSiteForm } from "./ConnectSiteForm";
import { TestSuiteVariables } from "./TestSuiteVariables";
import {
  getTestSuite,
  getTestSuiteVariables,
  getTestSuite_node_TestSuite
} from "../../../../generated-graphql-interfaces";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { ApolloQueryResult } from "apollo-client";

interface UpdateConnectSiteProps {
  testSuite: getTestSuite_node_TestSuite;
  onSubmit: StepChangeHandler;
  refetchTestSuite: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
}

export function UpdateConnectSite(props: UpdateConnectSiteProps) {
  const [mutateConnectSite] = useUpdateConnectSiteMutation();
  const testSuiteId = props.testSuite.id;

  async function handleSubmit(testSuiteVariables: TestSuiteVariables) {
    await mutateConnectSite({
      variables: {
        ...testSuiteVariables,
        testSuiteId: testSuiteId
      }
    });
  }

  return (
    <ConnectSiteForm
      testSuite={props.testSuite}
      onSuccess={props.onSubmit}
      onSubmit={handleSubmit}
      refetchTestSuite={props.refetchTestSuite}
    />
  );
}
