import React, { useState } from "react";
import { Divider, Paper, Tabs, Tab } from "@material-ui/core";
import { JiraIntegrationDetailConnectedTestSuites } from "./JiraIntegrationDetailConnectedTestSuites";
import { JiraIntegrationDetailIntegrationDetail } from "./JiraIntegrationDetailIntegrationDetail";
import { useDeleteJiraIntegrationMutation } from "../graphql/useDeleteJiraIntegrationMutation";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useUpdateJiraIntegrationMutation } from "../../../TestSuite/components/SetAlerts/graphql/useUpdateJiraIntegrationMutation";
import JiraIntegrationDialog, {
  JiraIntegrationDialogAction
} from "../../../TestSuite/components/SetAlerts/Jira/JiraIntegrationDialog";
import { useStyles } from "./JiraIntegrationDetailStyles";
import { JiraIntegrationDetailErrors } from "./JiraIntegrationDetailErrors";
import { EditOrDeleteJiraIntegration } from "./EditOrDeleteJiraIntegration";
import { Routes } from "../../../../_common/routes/routes";
import { useSnackbar } from "notistack";
import { JiraIntegrationDetailLoadingView } from "./JiraIntegrationDetailLoadingView";
import { useUnlinkJiraIntegrationFromTestSuiteMutation } from "../../../TestSuite/components/SetAlerts/graphql/useUnlinkJiraIntegrationFromTestSuite";
import { ApolloError } from "apollo-client";
import { JiraIntegrationWithTestSuites } from "../graphql/useGetJiraIntegrationWithTestSuitesQuery";

export enum JiraIntegrationDetailTab {
  DETAIL = 1,
  CONNECTIONS = 0
}

export interface JiraIntegrationDetailProps {
  jiraIntegration?: JiraIntegrationWithTestSuites;
  reloadIntegrations: () => void;
  loadingError?: ApolloError;
}

// eslint-disable-next-line max-lines-per-function, complexity, max-statements
export function JiraIntegrationDetail(props: JiraIntegrationDetailProps) {
  const classes = useStyles();
  const [tabToShow, setTabToShow] = useState<JiraIntegrationDetailTab>(JiraIntegrationDetailTab.CONNECTIONS);
  const history = useHistory();
  const accountId = useAccountRouteMatch();
  const [isUpdateDialogueOpen, setIsUpdateDialogueOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { jiraIntegration } = props;

  const [unlinkJiraIntegrationFromTestSuite, { loading: isUnlinkingIntegrationLoading, error: unlinkError }] =
    useUnlinkJiraIntegrationFromTestSuiteMutation();

  const [deleteJiraIntegration, { loading: isDeleteLoading, error: deleteError }] = useDeleteJiraIntegrationMutation();
  const [updateJiraIntegration, { loading: updateLoading, error: updateError }] = useUpdateJiraIntegrationMutation();

  function onTabChange(event: React.ChangeEvent<{}>, newValue: JiraIntegrationDetailTab) {
    setTabToShow(newValue);
  }

  function getTestSuiteJiraIntegrationId(testSuiteId?: string): string | undefined {
    const testSuiteJiraIntegration = jiraIntegration?.testSuiteJiraIntegrations.nodes.find(
      ({ testSuite }) => testSuite.id === testSuiteId
    );

    return testSuiteJiraIntegration?.id;
  }

  async function update(name: string) {
    try {
      if (!props.jiraIntegration?.id) return;

      await updateJiraIntegration({
        variables: {
          name,
          jiraIntegrationId: props.jiraIntegration.id
        }
      });
      props.reloadIntegrations();
      enqueueSnackbar("Jira integration updated successfully.", { variant: "success" });
    } catch (e) {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  async function unlinkJiraIntegration(testSuiteId?: string) {
    try {
      const testSuiteJiraIntegrationId = getTestSuiteJiraIntegrationId(testSuiteId);
      if (!testSuiteJiraIntegrationId) return;

      await unlinkJiraIntegrationFromTestSuite({ variables: { testSuiteJiraIntegrationId } });
      enqueueSnackbar("Jira integration disconnected successfully.", { variant: "success" });
      props.reloadIntegrations();
    } catch (e) {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  async function handleDeleteIntegration() {
    try {
      if (!props.jiraIntegration?.id) return;

      if (props.jiraIntegration.testSuiteJiraIntegrations.totalCount > 0) {
        props.jiraIntegration.testSuiteJiraIntegrations.nodes.forEach(testSuiteJiraIntegration => {
          unlinkJiraIntegrationFromTestSuite({
            variables: { testSuiteJiraIntegrationId: testSuiteJiraIntegration.id }
          });
        });
      }

      await deleteJiraIntegration({
        variables: {
          jiraIntegrationId: props.jiraIntegration.id
        }
      });

      enqueueSnackbar("Jira integration deleted successfully.", { variant: "success" });
      props.reloadIntegrations();
      history.push(Routes.ConnectedApps.getUrl({ accountId }));
    } catch (e) {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  const isLoading = updateLoading || isDeleteLoading;

  return (
    <Paper elevation={0} className={classes.paper}>
      <JiraIntegrationDetailErrors
        loadingError={props.loadingError}
        updateError={updateError}
        deleteError={deleteError}
      />

      {isLoading && <JiraIntegrationDetailLoadingView />}

      {!isLoading && jiraIntegration && !props.loadingError && (
        <>
          <EditOrDeleteJiraIntegration
            jiraIntegrationId={jiraIntegration.id}
            jiraIntegrationName={jiraIntegration.name}
            handleEdit={() => setIsUpdateDialogueOpen(true)}
            handleIntegrationDeletion={handleDeleteIntegration}
            isLoading={isLoading}
          />

          <Divider />

          <Tabs
            classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
            value={tabToShow}
            onChange={onTabChange}
            aria-label="disabled tabs example"
            data-testid="jira-integration-detail-tabs"
          >
            <Tab
              label="Connected test suites"
              value={JiraIntegrationDetailTab.CONNECTIONS}
              data-testid="jira-integration-detail-tabs-connections"
              data-pendo="auto-jira-integration-detail-tabs-connections"
              data-cy="jira-integration-detail-tabs-connections"
              classes={{
                root: classes.tabItem
              }}
            />

            <Tab
              label="Integration details"
              value={JiraIntegrationDetailTab.DETAIL}
              data-testid="jira-integration-detail-tabs-integration"
              data-cy="jira-integration-detail-tabs-integration"
              data-pendo="auto-jira-integration-detail-tabs-integration"
              classes={{
                root: classes.tabItem
              }}
            />
          </Tabs>
          <Divider />

          {tabToShow === JiraIntegrationDetailTab.CONNECTIONS && (
            <>
              {unlinkError && <Alert severity="error">Unable to disconnect integration.</Alert>}

              <JiraIntegrationDetailConnectedTestSuites
                integration={jiraIntegration}
                isUnlinkingIntegrationLoading={isUnlinkingIntegrationLoading}
                unlinkFromTestSuite={unlinkJiraIntegration}
              />
            </>
          )}

          {tabToShow === JiraIntegrationDetailTab.DETAIL && (
            <JiraIntegrationDetailIntegrationDetail jiraIntegration={jiraIntegration} />
          )}

          {jiraIntegration.name && (
            <JiraIntegrationDialog
              action={JiraIntegrationDialogAction.Edit}
              isOpen={isUpdateDialogueOpen}
              handleClose={() => setIsUpdateDialogueOpen(false)}
              accountId={accountId}
              submitHandler={update}
              loading={updateLoading}
              error={!!updateError}
              initialIntegrationName={jiraIntegration.name}
              jiraIntegration={jiraIntegration}
            />
          )}
        </>
      )}
    </Paper>
  );
}
