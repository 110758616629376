import React, { useState, useEffect, useContext } from "react";
import { AppBar, Grid, makeStyles, Hidden, IconButton } from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { UserMenu } from "../UserMenu/UserMenu";
import { SIDE_NAV_WIDTH, SideNav } from "../SideNav/SideNav";
import { useUserQuery } from "../graphql/userQuery/useUserQuery";
import { extractPermissionsFromGetUser } from "../../utils/permissions/permissions";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import { permissionsContext } from "../../contexts/Permissions/Permissions.context";

export interface NavBarProps {
  logoutCallback: () => void;
}

const useStyles = makeStyles(theme => ({
  appBar: {
    height: 60,
    width: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
    marginLeft: SIDE_NAV_WIDTH,
    backgroundColor: "#f7f8f8",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0"
    }
  },
  grid: {
    height: "100%"
  },
  gridItemLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0)
    }
  },
  gridItemRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary
  }
}));

export function NavBar(props: NavBarProps) {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const accountId = useAccountRouteMatch();
  const classes = useStyles();
  const { loading: isUserDataLoading, data: userData } = useUserQuery();
  const { setCurrentPermissions, loaded } = useContext(permissionsContext);
  const isLoading = isUserDataLoading || !loaded;

  function toggleSideNav(isOpen: boolean) {
    setIsSideNavOpen(isOpen);
  }

  useEffect(() => {
    if (userData) {
      const permissions = extractPermissionsFromGetUser(userData, accountId);
      setCurrentPermissions(permissions);
    }
  }, [accountId, setCurrentPermissions, userData]);

  return (
    <div data-testid="navbar">
      <AppBar className={classes.appBar}>
        <Grid container className={classes.grid}>
          <Grid item xs={6} className={classes.gridItemLeft}>
            <Hidden mdUp>
              <IconButton
                className={classes.iconButton}
                data-testid="mobile-menu-button"
                data-cy="mobile-menu-button"
                onClick={() => toggleSideNav(!isSideNavOpen)}
              >
                <MenuRoundedIcon fontSize="large" />
              </IconButton>
            </Hidden>
          </Grid>
          <Grid item xs={6} className={classes.gridItemRight}>
            <UserMenu logoutCallback={props.logoutCallback} userData={userData} isUserDataLoading={isLoading} />
          </Grid>
        </Grid>
      </AppBar>

      <SideNav isOpen={isSideNavOpen} toggleIsOpen={toggleSideNav} userData={userData} isUserDataLoading={isLoading} />
    </div>
  );
}
