import gql from "graphql-tag";

export const deleteTestMutationGql = gql`
  mutation deleteTest($testId: ObjectID!) {
    deleteTest(input: { testId: $testId }) {
      test {
        id
        relativeThreshold
        absoluteThreshold
        thresholdType
        reportTemplateCode
        severity
      }
    }
  }
`;
