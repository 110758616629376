import { getIndexesOfDuplicates } from "../../../../../_common/utils/getIndexesOfDuplicates/getIndexesOfDuplicates";
import { CustomDnsPair } from "../ConnectSiteForm";

function matchingFunction(dnsPairA: CustomDnsPair, dnsPairB: CustomDnsPair) {
  return dnsPairA.hostname === dnsPairB.hostname && dnsPairA.ipAddress === dnsPairB.ipAddress;
}

export function getDuplicateCustomDnsFields(customDnsPairs: CustomDnsPair[]) {
  const duplicateDnsPairsIndexes = getIndexesOfDuplicates<CustomDnsPair>(customDnsPairs, matchingFunction);
  return duplicateDnsPairsIndexes.flatMap(dnsIndex => [
    `customDns[${dnsIndex}].hostname`,
    `customDns[${dnsIndex}].ipAddress`
  ]);
}
