import { Threshold } from "../TestCollapse/TestCollapse";

export function getHelperTextForAbsoluteThreshold(threshold: Threshold) {
  if (isNaN(threshold.absolute) || threshold.absolute < 1) {
    return "Please enter at least 1 URL";
  } else if (threshold.absolute > 10000) {
    return "Please enter a number lower then 10,000";
  } else {
    return "";
  }
}

export function isAbsoluteThresholdInError(threshold: Threshold) {
  return isNaN(threshold.absolute) || threshold.absolute < 1 || threshold.absolute > 10000;
}
