import React from "react";
import { ActionMenuIconType, ActionMenuItem } from "../../../_common/components/ActionMenu/ActionsMenu";
import { BuildTestStatus, ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { SchedulerTestSuiteListItemNotYetRun } from "./SchedulerTestSuiteListItemNotYetRun";
import { SchedulerTestSuiteListItemStarted } from "./SchedulerTestSuiteListItemStarted";

export interface BasicSchedulerTestSuiteListItemProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  editable: boolean;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
}

export interface SchedulerTestSuiteListItemProps extends BasicSchedulerTestSuiteListItemProps {
  onActionMenuEvent: (event: string) => Promise<boolean>;
  nextRunAt: Date;
}

export function SchedulerTestSuiteListItem(props: SchedulerTestSuiteListItemProps): JSX.Element {
  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;
  const actionMenuItems: ActionMenuItem[] = [
    {
      label: "Remove test suite",
      id: "remove",
      iconType: ActionMenuIconType.DELETE,
      dataId: "remove-action",
      closeMenuOnClick: false
    }
  ];

  return (
    <div data-testid="test-suite-list-item">
      {isNotYetRun ? (
        <SchedulerTestSuiteListItemNotYetRun
          id={props.id}
          name={props.name}
          url={props.url}
          onActionMenuEvent={props.onActionMenuEvent}
          overrideActions={actionMenuItems}
          nextRunAt={props.nextRunAt}
        />
      ) : (
        <SchedulerTestSuiteListItemStarted
          id={props.id}
          status={props.status}
          lastRun={props.lastRun}
          name={props.name}
          url={props.url}
          onActionMenuEvent={props.onActionMenuEvent}
          overrideActions={actionMenuItems}
          buildId={props.buildId}
          totalTests={props.totalTests}
          failedTests={props.failedTests}
          warningTests={props.warningTests}
          passedTests={props.passedTests}
          updatedAt={props.testSuiteUpdatedAt}
        />
      )}
    </div>
  );
}
