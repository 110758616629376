import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  getJiraIntegrations,
  getJiraIntegrationsVariables,
  getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes
} from "../../../../generated-graphql-interfaces";

export const getJiraIntegrationsGql = gql`
  query getJiraIntegrations($accountId: ObjectID!) {
    getAccount(id: $accountId) {
      id
      jiraAuthentications {
        nodes {
          id
          name
          jiraIntegrations {
            totalCount
            nodes {
              id
              name
              jiraUrl
              jiraProjectId
              jiraIssueTypeId
              jiraProjectName
              jiraIssueTypeName
              testSuiteJiraIntegrations {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;

export type JiraIntegration = getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes & {
  jiraAuthenticationId: string;
  jiraAuthenticationName: string;
};

export function useGetJiraIntegrationsQuery(variables: getJiraIntegrationsVariables) {
  const { data, error, loading, refetch } = useQuery<getJiraIntegrations, getJiraIntegrationsVariables>(
    getJiraIntegrationsGql,
    {
      variables,
      fetchPolicy: "cache-first"
    }
  );

  const jiraIntegrations: JiraIntegration[] = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(jiraAuthentication =>
      jiraAuthentication.jiraIntegrations.nodes.map(jiraIntegration => ({
        jiraAuthenticationId: jiraAuthentication.id,
        jiraAuthenticationName: jiraAuthentication.name,
        ...jiraIntegration
      }))
    ) ?? [])
  ];

  const totalCount = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      jiraAuthenticationNode => jiraAuthenticationNode.jiraIntegrations.totalCount
    ) ?? [])
  ].reduce((acc, curr) => acc + curr, 0);

  return {
    data: { jiraIntegrations, totalCount },
    error,
    loading,
    refetch
  };
}
