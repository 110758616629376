import { getTestSuites } from "../../../generated-graphql-interfaces";
import lodash from "lodash";

export function getPreviousResultAsLast(data: getTestSuites) {
  if (data.getAccount?.__typename === "Account" && data.getAccount.testSuites) {
    const newData = lodash.cloneDeep(data);
    if (newData.getAccount !== null && newData.getAccount.testSuites !== null) {
      // eslint-disable-next-line fp/no-mutation, @typescript-eslint/no-non-null-assertion
      newData.getAccount!.testSuites!.pageInfo.hasNextPage = false;
    }
    return newData;
  }
  return data;
}
