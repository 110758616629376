import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Paper, Box, Grid, Typography, Divider, Button } from "@material-ui/core";
import clsx from "clsx";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Routes } from "../../../_common/routes/routes";

export interface NotYetRunTestsuiteInfo {
  createdAt: number | string;
  id: string;
  sitePrimary: string;
  name: string;
  status: string;
}

interface NotYetRunWidgetProps {
  testSuites: NotYetRunTestsuiteInfo[];
  accountId: string;
}

const useStyles = makeStyles(theme => ({
  listItem: {
    cursor: "pointer",
    borderLeft: `6px solid ${theme.palette.grey[800]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(3, 2, 3, 4),
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[50]
    }
  },
  topListItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  title: {
    marginLeft: theme.spacing(3)
  },
  listItemText: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  viewAllContainer: {
    padding: "28px 28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse"
  },
  icon: { color: theme.palette.grey[800] },
  link: { textDecoration: "none" },
  heading: { height: theme.spacing(8), padding: theme.spacing(0, 3.5, 0, 3.5), display: "flex", alignItems: "center" },
  h2: { fontSize: 16, fontWeight: 600, color: theme.palette.navy[500] },
  subheading: {
    color: theme.palette.navy[300],
    fontSize: 14
  },
  subheadingWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: 58,
    padding: theme.spacing(0, 3.5, 0, 3.5)
  },
  chevronWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  name: {
    fontSize: 15,
    fontWeight: 600
  },
  sitePrimary: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.navy[300]
  },
  testSuiteId: {
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.navy[200]
  }
}));

function sortByDate(testSuiteA: NotYetRunTestsuiteInfo, testSuiteB: NotYetRunTestsuiteInfo) {
  return new Date(testSuiteB.createdAt).getTime() - new Date(testSuiteA.createdAt).getTime();
}

// eslint-disable-next-line max-lines-per-function
export function NotYetRunWidget(props: NotYetRunWidgetProps) {
  const classes = useStyles();
  const remainingCount = props.testSuites.length - 3;

  if (!props.testSuites.length) {
    return null;
  }

  function getTestSuiteEditURL(testSuite: NotYetRunTestsuiteInfo) {
    return Routes.EditTestSuite.getUrl({ accountId: props.accountId, testSuiteId: testSuite.id });
  }

  return (
    <Paper
      data-testid="not-yet-run-widget-container"
      data-cy="not-yet-run-widget-container"
      data-pendo="auto-dashboard-widget-notyetrun"
    >
      <Box className={classes.heading}>
        <Typography className={classes.h2}>Not yet run test suites</Typography>
      </Box>
      <Divider />
      <Box className={classes.subheadingWrapper}>
        <Typography className={classes.subheading}>No runs/builds detected yet for the test suites below</Typography>
      </Box>
      <Box>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        {props.testSuites.sort(sortByDate).map((testSuite, index) => {
          if (index < 3) {
            return (
              <Link
                to={getTestSuiteEditURL(testSuite)}
                className={classes.link}
                data-testid="notyetrun-testsuite-link"
                key={testSuite.id}
              >
                <Box
                  className={clsx(classes.listItem, index == 0 && classes.topListItem)}
                  data-testid="notyetrun-testsuite-item"
                  data-cy="notyetrun-testsuite-item"
                  data-pendo="auto-dashboard-widget-notyetrun-testsuite-item"
                >
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={11}>
                      <Typography
                        variant="body1"
                        data-testid="notyetrun-testsuite-name"
                        data-cy="notyetrun-testsuite-name"
                        color="textPrimary"
                        className={clsx(classes.listItemText, classes.name)}
                      >
                        {testSuite.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        data-testid="notyetrun-testsuite-siteprimary"
                        data-cy="notyetrun-testsuite-siteprimary"
                        color="textPrimary"
                        className={clsx(classes.listItemText, classes.sitePrimary)}
                      >
                        {testSuite.sitePrimary}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        data-cy="notyetrun-testsuite-id-message"
                        className={clsx(classes.listItemText, classes.testSuiteId)}
                      >
                        Test suite ID:{" "}
                        <span data-cy="notyetrun-testsuite-id" data-testid="notyetrun-testsuite-id">
                          {testSuite.id}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.chevronWrapper}>
                      <ChevronRightIcon fontSize="large" className={classes.icon} />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            );
          }
          return null;
        })}
        <div className={classes.viewAllContainer}>
          <Link
            className={classes.link}
            to={Routes.TestSuites.getUrl({ accountId: props.accountId })}
            data-testid="view-all-link"
            data-cy="view-all-link"
          >
            <Button variant="outlined" data-pendo="auto-dashboard-widget-notyetrun-view-all">
              View all
            </Button>
          </Link>
          {props.testSuites.length > 3 && (
            <Typography
              data-testid="remaining-testsuite-count"
              data-cy="remaining-testsuite-count"
              className={classes.subheading}
            >
              + {remainingCount} more
            </Typography>
          )}
        </div>
      </Box>
    </Paper>
  );
}
