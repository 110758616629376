import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Box } from "@material-ui/core";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePrevious: () => void;
  handleNext: () => void;
  pendoIdSuffix?: string;
}

export function Pagination(props: PaginationProps): JSX.Element | null {
  const { currentPage, totalPages, handlePrevious, handleNext } = props;

  return totalPages > 1 ? (
    <Box display="flex" justifyContent="center" alignItems="center" data-testid="pagination-component">
      {currentPage !== 1 ? (
        <Box>
          <Link
            to="#"
            onClick={handlePrevious}
            data-testid="prev-page-link"
            data-pendo={`auto-${props.pendoIdSuffix}-pagination-prev`}
          >
            <Box display="flex" alignItems="center">
              <ArrowBackIosIcon />
              <Box component="span"> Previous</Box>
            </Box>
          </Link>
        </Box>
      ) : null}
      <Box mx={2}>{`${currentPage} of ${totalPages}`}</Box>
      {currentPage !== totalPages ? (
        <Box>
          <Link
            to="#"
            onClick={handleNext}
            data-testid="next-page-link"
            data-pendo={`auto-${props.pendoIdSuffix}-pagination-next`}
          >
            <Box display="flex" alignItems="center">
              <Box component="span">Next </Box>
              <ArrowForwardIosIcon />
            </Box>
          </Link>
        </Box>
      ) : null}
    </Box>
  ) : null;
}
