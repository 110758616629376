import gql from "graphql-tag";

export const createSlackWebhookGql = gql`
  mutation createSlackWebhook($testSuiteId: ObjectID!, $url: String!) {
    createAutomatorSlackWebhook(input: { testSuiteId: $testSuiteId, url: $url }) {
      slackWebhook {
        id
      }
    }
  }
`;
