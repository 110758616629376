import React from "react";
import { Severity, ThresholdPredicate, ThresholdType } from "../../../generated-graphql-interfaces";
import { Threshold } from "../TestCollapse/TestCollapse";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    "& svg": {
      color: theme.palette.error.main,
      width: "14px",
      height: "14px"
    }
  },
  text: {
    fontSize: 18
  },
  whiteText: {
    color: "#FFFFFF"
  }
}));

export interface TestCollapseDescriptionProps {
  severity: Severity;
  threshold: Threshold;
  isExpanded: boolean;
}

export function TestCollapseDescription(props: TestCollapseDescriptionProps): JSX.Element {
  const classes = useStyles();

  function getSeverityText(severity: Severity) {
    return severity === "Fail" ? "FAIL" : "WARN";
  }

  function getThresholdText(threshold: Threshold) {
    return threshold.type === ThresholdType.Relative
      ? `${threshold.relative || 0}%`
      : `${threshold.absolute || 0} URL(s)`;
  }

  if ((isNaN(props.threshold.absolute) || props.threshold.absolute < 1) && !props.isExpanded) {
    return (
      <span className={classes.error}>
        <ErrorOutlineIcon /> Please enter number of URL(s)
      </span>
    );
  } else if (props.threshold.absolute > 10000 && !props.isExpanded) {
    return (
      <span className={classes.error}>
        <ErrorOutlineIcon /> The number of URLs is too large
      </span>
    );
  } else {
    return (
      <span className={clsx(classes.text, props.isExpanded && classes.whiteText)}>{`${getSeverityText(
        props.severity
      )} if ${props.threshold.predicate === ThresholdPredicate.GreaterThanOrEqual ? "≥" : "<"} ${getThresholdText(
        props.threshold
      )}`}</span>
    );
  }
}
