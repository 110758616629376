import { ApolloLink } from "apollo-link";
import { errorsSubscription } from "../errors-subscription";

export const afterwareLink = new ApolloLink((operation, forward) => {
  if (forward) {
    return forward(operation).map(response => {
      if (!response.errors) {
        errorsSubscription.clearErrors();
      }

      return response;
    });
  }
  return null;
});
