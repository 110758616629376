import React, { Fragment, useState } from "react";
import { Box, Typography, Button, Divider, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { AddEmailInput } from "./AddEmailInput";
import { EmailRow } from "./EmailRow";
import { getTestSuite_node_TestSuite_emailAlerts_nodes } from "../../../../../generated-graphql-interfaces";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3)
    }
  },
  addNewButton: {
    minWidth: 120,
    height: 36
  },
  addEmailDivider: {
    margin: theme.spacing(6, 0, 3, 0),
    backgroundColor: theme.palette.grey[400]
  },
  emailRowDivider: { backgroundColor: theme.palette.grey[400], margin: theme.spacing(2, 0, 3, 0) },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  }
}));

interface EmailTabProps {
  alertEmails: getTestSuite_node_TestSuite_emailAlerts_nodes[];
  testSuiteId: string;
}

const MAX_NUMBER_OF_EMAILS = 100; // not enforced by API currently

export const EmailTab = (props: EmailTabProps) => {
  const classes = useStyles();
  const { alertEmails } = props;
  const [isAddEmailInputVisible, setIsAddEmailInputVisible] = useState(false);

  function showAddEmailInput() {
    setIsAddEmailInputVisible(true);
  }

  function hideAddEmailInput() {
    setIsAddEmailInputVisible(false);
  }

  function handleAddNewEmail() {
    setIsAddEmailInputVisible(true);
  }

  return (
    <Box component="div" data-cy="email-tab">
      <Box className={classes.header} marginBottom={6}>
        <Typography className={classes.title} color="textPrimary" data-testid="add-email-heading">
          Email notifications
        </Typography>
        <Button
          className={classes.addNewButton}
          variant="outlined"
          color="primary"
          type="button"
          id="set-alerts-add-email"
          data-testid="set-alerts-add-email"
          data-pendo="auto-add-new-email-btn"
          startIcon={<AddIcon />}
          onClick={handleAddNewEmail}
          disabled={isAddEmailInputVisible || alertEmails.length >= MAX_NUMBER_OF_EMAILS}
        >
          New email
        </Button>
      </Box>

      {isAddEmailInputVisible && (
        <>
          <AddEmailInput
            showConfirmationButton={showAddEmailInput}
            hideConfirmationButton={hideAddEmailInput}
            testSuiteId={props.testSuiteId}
          />
          <Divider className={classes.addEmailDivider} />
        </>
      )}

      {alertEmails.map(email => {
        return (
          <Fragment key={email.id}>
            <EmailRow alertEmail={email} />
            <Divider className={classes.emailRowDivider} />
          </Fragment>
        );
      })}
    </Box>
  );
};
