import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { updateSlackWebhook, updateSlackWebhookVariables } from "../../../../../generated-graphql-interfaces";

export const updateSlackWebhookGql = gql`
  mutation updateSlackWebhook($slackWebhookId: ObjectID!, $alertTypes: [SlackAlertType!]!) {
    updateAutomatorSlackWebhook(input: { slackWebhookId: $slackWebhookId, alertTypes: $alertTypes }) {
      slackWebhook {
        id
        url
        alertTypes
      }
    }
  }
`;

export function useUpdateSlackWebhookMutation() {
  return useMutation<updateSlackWebhook, updateSlackWebhookVariables>(updateSlackWebhookGql);
}
