import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function AboutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63425 8.83363C3.04572 3.84177 7.21719 0 12.2259 0H12.7276C17.7364 0 21.9078 3.84177 22.3193 8.83363C22.5404 11.5152 21.712 14.1779 20.0088 16.2608L14.4428 23.068C13.4267 24.3107 11.5269 24.3107 10.5107 23.068L4.94472 16.2608C3.24152 14.1779 2.41321 11.5152 2.63425 8.83363ZM12.226 1.74202C8.12384 1.74202 4.70737 4.88846 4.37037 8.97684C4.18641 11.2086 4.87579 13.4247 6.29331 15.1583L11.8593 21.9655C12.1786 22.3558 12.7752 22.3558 13.0944 21.9655L18.6604 15.1583C20.078 13.4247 20.7673 11.2086 20.5834 8.97684C20.2464 4.88846 16.8299 1.74202 12.7277 1.74202H12.226Z"
      />
      <path d="M13.638 17.1281C13.638 17.7694 13.1181 18.2893 12.4767 18.2893C11.8353 18.2893 11.3154 17.7694 11.3154 17.1281C11.3154 16.4867 11.8353 15.9668 12.4767 15.9668C13.1181 15.9668 13.638 16.4867 13.638 17.1281Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4768 7.54855C11.3774 7.54855 10.4445 8.47225 10.4445 9.66373C10.4445 10.1447 10.0546 10.5346 9.57359 10.5346C9.09257 10.5346 8.70264 10.1447 8.70264 9.66373C8.70264 7.55681 10.3694 5.80664 12.4768 5.80664C14.5841 5.80664 16.2509 7.55681 16.2509 9.66373C16.2509 11.3255 15.2195 12.7566 13.7519 13.2949C13.6074 13.3479 13.4898 13.429 13.4188 13.508C13.3524 13.5819 13.3477 13.6271 13.3477 13.6452C13.3477 14.1262 12.9578 14.5162 12.4768 14.5162C11.9958 14.5162 11.6058 14.1262 11.6058 13.6452C11.6058 12.5727 12.4692 11.9099 13.1522 11.6595C13.932 11.3736 14.509 10.5965 14.509 9.66373C14.509 8.47225 13.5762 7.54855 12.4768 7.54855Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientAboutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="0" width="21" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.68636 8.82255C3.09531 3.86124 7.24125 0.0429688 12.2195 0.0429688H12.7334C17.7116 0.0429688 21.8576 3.86124 22.2665 8.82255C22.4866 11.4938 21.6616 14.1462 19.9649 16.2211L14.2609 23.197C13.3386 24.325 11.6143 24.325 10.692 23.197L4.98792 16.2211C3.2913 14.1462 2.46617 11.4938 2.68636 8.82255ZM10.3938 9.6485C10.3938 8.42748 11.3497 7.48088 12.4764 7.48088C13.603 7.48088 14.559 8.42748 14.559 9.6485C14.559 10.6044 13.9677 11.4007 13.1685 11.6937C12.4686 11.9504 11.5838 12.6296 11.5838 13.7287C11.5838 14.2217 11.9835 14.6213 12.4764 14.6213C12.9693 14.6213 13.3689 14.2217 13.3689 13.7287C13.3689 13.7102 13.3737 13.6639 13.4418 13.5881C13.5145 13.5071 13.635 13.4241 13.7831 13.3697C15.2871 12.8181 16.3441 11.3515 16.3441 9.6485C16.3441 7.48934 14.636 5.69578 12.4764 5.69578C10.3168 5.69578 8.60867 7.48934 8.60867 9.6485C8.60867 10.1414 9.00828 10.541 9.50122 10.541C9.99416 10.541 10.3938 10.1414 10.3938 9.6485ZM12.4764 18.489C13.1337 18.489 13.6664 17.9562 13.6664 17.2989C13.6664 16.6417 13.1337 16.1089 12.4764 16.1089C11.8191 16.1089 11.2863 16.6417 11.2863 17.2989C11.2863 17.9562 11.8191 18.489 12.4764 18.489Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.476562" y="0.0429688" width="24" height="24" fill="url(#paint0_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2.39771"
          y1="19.4402"
          x2="20.726"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
