import React, { useEffect, useRef, useState } from "react";
import { FormGroup, FormControlLabel, Checkbox, makeStyles, Typography } from "@material-ui/core";

import useDebounce from "../../../../_common/hooks/useDebounce/UseDebounce";

const useStyles = makeStyles(theme => ({
  notifyMeText: {
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  checkbox: {
    padding: 6,
    marginRight: theme.spacing(1)
  },
  formLabelRoot: {
    marginLeft: "-8px"
  }
}));

interface NotificationOptionsProps {
  disabled: boolean;
  initialValues: {
    pass: boolean;
    warning: boolean;
    fail: boolean;
  };
  id: string;
  updateNotifications: (pass: boolean, warning: boolean, fail: boolean) => void;
}

export function NotificationOptions(props: NotificationOptionsProps) {
  const { updateNotifications } = props;
  const classes = useStyles();
  const [notifications, setNotifications] = useState(props.initialValues);
  const { pass, warning, fail } = useDebounce(notifications, 250);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    // We don't want to trigger the callback when the component is initially mounted.
    if (isMounted.current) {
      updateNotifications(pass, warning, fail);
    } else {
      // eslint-disable-next-line fp/no-mutation
      isMounted.current = true;
    }
  }, [pass, warning, fail, updateNotifications]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function componentWillUnmount() {
    // in case that the debounce did not trigger and the component has been removed from a page.
    if (notifications.fail !== fail || notifications.warning !== warning || notifications.pass !== pass) {
      updateNotifications(notifications.pass, notifications.warning, notifications.fail);
    }
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNotifications({ ...notifications, [event.target.name]: event.target.checked });
  }

  return (
    <div data-testid="notification-options">
      <Typography className={classes.notifyMeText} color="textPrimary">
        Notify me about...
      </Typography>
      <FormGroup>
        <FormControlLabel
          data-testid="notification-options-pass"
          data-pendo={`auto-${props.id}-notification-options-pass`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.pass}
              className={classes.checkbox}
              onChange={handleCheckboxChange}
              name="pass"
            />
          }
          label="Passes"
          disabled={props.disabled}
        />
        <FormControlLabel
          data-testid="notification-options-warn"
          data-pendo={`auto-${props.id}-notification-options-warn`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.warning}
              className={classes.checkbox}
              onChange={handleCheckboxChange}
              name="warning"
            />
          }
          label="Passes with warnings"
          disabled={props.disabled}
        />
        <FormControlLabel
          data-testid="notification-options-fail"
          data-pendo={`auto-${props.id}-notification-options-fail`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.fail}
              className={classes.checkbox}
              onChange={handleCheckboxChange}
              name="fail"
            />
          }
          label="Fails"
          disabled={props.disabled}
        />
      </FormGroup>
    </div>
  );
}
