import { useQuery } from "@apollo/react-hooks";
import { getAutomatorAccountStatisticsQuery } from "./getAutomatorAccountStatisticsQuery";
import {
  getAutomatorAccountStatisticsVariables,
  getAutomatorAccountStatistics
} from "./../../../generated-graphql-interfaces";

export function useGetAutomatorAccountStatisticsVariables(variables: getAutomatorAccountStatisticsVariables) {
  return useQuery<getAutomatorAccountStatistics, getAutomatorAccountStatisticsVariables>(
    getAutomatorAccountStatisticsQuery,
    { variables }
  );
}
