import { Box, Chip, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { BuildStatus } from "../../../generated-graphql-interfaces";
import { ActionButtonTooltip } from "../../../_common/components/ActionButtonTooltip/ActionButtonTooltip";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { Routes } from "../../../_common/routes/routes";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";

enum CustomChipType {
  Total = "Total tests",
  Fails = "Fails",
  Warnings = "Warnings",
  Passes = "Passes"
}

function getColor(theme: Theme, type?: CustomChipType, disabled?: boolean) {
  if (disabled) {
    return ["#F4F4F4", "#F4F4F4", theme.palette.navy[300]];
  }
  switch (type) {
    case CustomChipType.Fails:
      return [theme.palette.error.main, theme.palette.red[900], "#FFF"];
    case CustomChipType.Warnings:
      return [theme.palette.warning.main, theme.palette.warning.dark, "#FFF"];
    case CustomChipType.Passes:
      return [theme.palette.deepcrawlGreen.main, "#2A5304", "#FFF"];
    case CustomChipType.Total:
    default:
      return ["#FFF", theme.palette.navy[50], theme.palette.navy[500]];
  }
}

const useCustomChipStyles = makeStyles(theme => ({
  underLabel: {
    textAlign: "center",
    display: "block",
    marginTop: theme.spacing(1),
    fontSize: 13,
    color: theme.palette.navy[300]
  }
}));

const useChipStyles = makeStyles(theme => ({
  root: (props: CustomChipProps) => {
    const filter = props.renderAsDisabled ? "none" : "drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.10))";
    const cursor = props.renderAsDisabled ? "default !important" : "pointer";
    const [backgroundColor, hoverColor, textColor] = getColor(theme, props.type, props.renderAsDisabled);

    return {
      borderRadius: 4,
      width: props.renderAsText ? "inherit" : "100%",
      textTransform: props.renderAsText ? "uppercase" : "none",
      height: 28,
      filter: filter,
      "& .MuiChip-label": {
        color: textColor
      },
      backgroundColor,
      cursor: cursor,
      "&:hover": {
        backgroundColor: hoverColor
      },
      "&:focus": {
        cursor: cursor,
        backgroundColor: hoverColor
      }
    };
  },
  label: {
    fontSize: 16,
    fontWeight: 500
  },
  outlined: {
    borderColor: theme.palette.grey[300],
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: theme.palette.navy[50]
    },
    "& .MuiChip-label": {
      color: theme.palette.navy[500]
    }
  }
}));

const useStyles = makeStyles(theme => ({
  box: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%"
  },
  text: {
    fontSize: 14,
    color: theme.palette.navy[300],
    fontWeight: 400
  },
  chipWrapper: (props: TestCountChipsProps) => {
    return {
      margin: "0px 4px",
      width: `${props.skipTotalChips ? 33 : 25}%`,
      minWidth: "54px"
    };
  },
  oneChipBlock: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left"
    }
  }
}));

interface TestCountChipsProps {
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  buildId: string | null;
  testSuiteId: string;
  skipTotalChips?: boolean;
  status?: BuildTestStatus;
  pendoIdSuffix: string;
  showLabel: boolean;
}

interface CustomChipProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type: CustomChipType;
  label: number | string;
  renderAsDisabled: boolean;
  variant: "default" | "outlined" | undefined;
  renderAsText?: boolean;
  pendoIdSuffix: string;
  showLabel: boolean;
}

function CustomChip(props: CustomChipProps) {
  const classes = useChipStyles(props);
  const classesCustom = useCustomChipStyles();
  return (
    <>
      <Chip
        data-testid="test-count-chip"
        data-cy={`${props.type?.toLowerCase().replace(/\s/g, "")}-tests-count`}
        data-pendo={`auto-${props.pendoIdSuffix}-item-chip-${props.type?.toLowerCase().replace(/\s/g, "")}`}
        onClick={props.onClick}
        variant={props.variant}
        label={props.label}
        classes={classes}
      />
      <span className={classesCustom.underLabel}>{props.showLabel && props.type}</span>
    </>
  );
}

function getCorrectPathType(type: CustomChipType) {
  switch (type) {
    case CustomChipType.Passes:
      return TestsToDisplayOption.PASS;
    case CustomChipType.Fails:
      return TestsToDisplayOption.FAIL;
    case CustomChipType.Warnings:
      return TestsToDisplayOption.WARNING;
    case CustomChipType.Total:
    default:
      return TestsToDisplayOption.ALL;
  }
}

// eslint-disable-next-line max-lines-per-function
export function TestCountChips(props: TestCountChipsProps) {
  const { testSuiteId, buildId } = props;
  const classes = useStyles(props);
  const accountId = useAccountRouteMatch();
  const history = useHistory();

  const chipsConfig = [
    {
      title: CustomChipType.Total,
      label: props.totalTests,
      disabled: props.totalTests === 0
    },
    {
      title: CustomChipType.Fails,
      label: props.failedTests,
      disabled: props.failedTests === 0
    },
    {
      title: CustomChipType.Warnings,
      label: props.warningTests,
      disabled: props.warningTests === 0
    },
    {
      title: CustomChipType.Passes,
      label: props.passedTests,
      disabled: props.passedTests === 0
    }
  ];

  // For the case where the test count is 'Processing' or 'N/A'
  const areResultsStillProcessing = chipsConfig.some(chip => typeof chip.label === "string");

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: CustomChipType, disabled: boolean) {
    event.stopPropagation();
    const clickResultPath = buildId
      ? Routes.TestResults.getUrl({
          accountId,
          testSuiteId,
          buildTestId: buildId,
          selectedType: getCorrectPathType(type)
        })
      : Routes.Builds.getUrl({ accountId, testSuiteId });
    !disabled && history.push(clickResultPath);
  }

  if (props.status === BuildStatus.Cancelled || props.status === BuildStatus.Aborted) {
    return (
      <Box data-testid="cancelled-chip" className={classes.oneChipBlock}>
        <CustomChip
          renderAsDisabled={true}
          type={CustomChipType.Total}
          label="Cancelled"
          variant={undefined}
          renderAsText={true}
          pendoIdSuffix={props.pendoIdSuffix}
          showLabel={false}
        />
      </Box>
    );
  }

  if (areResultsStillProcessing) {
    return (
      <Box data-testid="crawlink-web-site" className={classes.oneChipBlock}>
        <CustomChip
          renderAsDisabled={true}
          type={CustomChipType.Total}
          label="Crawling the website"
          variant={undefined}
          renderAsText={true}
          pendoIdSuffix={props.pendoIdSuffix}
          showLabel={false}
        />
      </Box>
    );
  }

  return (
    <Box data-testid="test-count-chips-container" display="flex" className={classes.box}>
      {chipsConfig
        .filter(chip => {
          return props.skipTotalChips ? chip.title !== CustomChipType.Total : true;
        })
        .map(chip => (
          <Box key={chip.title} className={classes.chipWrapper}>
            <ActionButtonTooltip title={chip.title} margintop="8px">
              <Box>
                <CustomChip
                  onClick={event => handleClick(event, chip.title, chip.disabled)}
                  variant={chip.title === CustomChipType.Total ? "outlined" : undefined}
                  type={chip.title}
                  label={chip.label}
                  renderAsDisabled={chip.disabled}
                  pendoIdSuffix={props.pendoIdSuffix}
                  showLabel={props.showLabel}
                />
              </Box>
            </ActionButtonTooltip>
          </Box>
        ))}
    </Box>
  );
}
