import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import { TestSuiteListItemWarning } from "./TestSuiteListItemWarning";
import { BuildTestStatus, ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";

const useStyles = makeStyles(theme => ({
  container: {
    listStyleType: "none",
    margin: 0,
    paddingInlineStart: 0
  },
  testSuiteName: {
    color: theme.palette.navy[500],
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.2",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    fontSize: 15,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
      lineHeight: "1.2"
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "inherit",
      display: "block",
      whiteSpace: "inherit",
      textOverflow: "inherit",
      overflow: "inherit"
    }
  },
  infoText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineHeight: "1.5"
  },
  warningWrapper: {
    marginTop: theme.spacing(2)
  },
  url: {
    color: theme.palette.navy[300],
    fontWeight: 400,
    fontSize: 14
  }
}));

export interface TestSuiteItemInfoProps {
  id: string;
  url: string;
  name: string;
  status: BuildTestStatus;
  showCloneWarning?: boolean;
}

export function TestSuiteItemInfo(props: TestSuiteItemInfoProps): JSX.Element {
  const classes = useStyles();
  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;
  const nameTestId = isNotYetRun ? "test-suite-list-item-compact-name" : "test-suite-list-item-name";

  return (
    <Box className={classes.container}>
      <Typography className={classes.testSuiteName} data-cy="test-suite-list-item-name" data-testid={nameTestId}>
        {props.name}
      </Typography>

      <Box className={classes.infoText}>
        <Typography data-testid="test-suite-list-item-url" className={classes.url}>
          {props.url}
        </Typography>
      </Box>
      {props.showCloneWarning && (
        <Box className={classes.warningWrapper}>
          <TestSuiteListItemWarning />
        </Box>
      )}
    </Box>
  );
}
