import React, { useState } from "react";
import { Box, Button, CircularProgress, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { getTestSuite, getTestSuiteVariables } from "../../../../../generated-graphql-interfaces";
import { useSaveChangesContext } from "../../../SaveChangesProvider";
import { ApolloQueryResult } from "apollo-client";
import { useCreateAndRunManualBuild } from "../../../../../_common/graphql/useCreateAndRunManualBuildGql";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useBuildStatusContext } from "../../../hooks/useBuildStatusProvider/useBuildStatusProvider";
import { PlayIcon } from "../../../../../_common/svg/PlayIcon";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { isPriorityBuildRunning } from "../../../../../_common/utils/isPriorityBuildRunning/isPriorityBuildRunning";

const useStyles = makeStyles(theme => ({
  disabled: {
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: theme.palette.purple[200],
      "&:hover": { cursor: "not-allowed" }
    }
  },
  dialog: {
    padding: theme.spacing(4, 3, 1, 3)
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
    paddingTop: 0,
    lineHeight: 1.2,
    marginBottom: theme.spacing(2)
  },
  dialogContent: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.navy[500],
    marginBottom: theme.spacing(4.5)
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(3)
  },
  tooltipText: {
    fontWeight: 400,
    fontSize: 14
  },
  tooltipIcon: {
    color: "#2D7495",
    marginRight: theme.spacing(2)
  }
}));

export interface RunTestSuiteNowButtonProps {
  testId?: string;
  pendoId: string;
  refetchTestSuite: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
  testSuiteId: string;
}

const useTooltipStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "#FFF",
    margin: 0,
    padding: theme.spacing(2),
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.12), 0px 3px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
    width: 450
  }
}));

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
export function RunTestSuiteNowButton(props: RunTestSuiteNowButtonProps) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  const buildStatusContext = useBuildStatusContext();
  const saveChangesContext = useSaveChangesContext();

  const [createAndRunManualBuild] = useCreateAndRunManualBuild();
  const { enqueueSnackbar } = useSnackbar();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  async function tryToRunTestSuite() {
    buildStatusContext.setRunNowStatus("checking");
    try {
      await createAndRunManualBuild({
        variables: {
          testSuiteId: props.testSuiteId
        }
      });
      buildStatusContext.setRunNowStatus("running");
      enqueueSnackbar(`Test suite run started successfully`, { variant: "success" });
    } catch (e) {
      if (e.toString().includes("Conflict. Operation couldn't be performed.")) {
        enqueueSnackbar(`The test suite is already running`, { variant: "error", persist: true });
      } else {
        buildStatusContext.setRunNowStatus("idle");
        enqueueSnackbar(`Error during starting test suite`, { variant: "error", persist: true });
      }
    }
  }

  async function handleRunNow() {
    buildStatusContext.setRunNowStatus("checking");
    const isPriorityBuildRunning = await buildStatusContext?.isPriorityBuildRunning();

    if (isPriorityBuildRunning) {
      buildStatusContext.setRunNowStatus("idle");
      enqueueSnackbar(`The test suite is already running`, { variant: "error" });
      return;
    }

    try {
      await saveChangesContext.invokeCallbacks();
      props.refetchTestSuite();
    } catch (e) {
      buildStatusContext.setRunNowStatus("idle");
      setIsDialogOpen(true);
      return;
    }
    await tryToRunTestSuite();
  }

  if (buildStatusContext.error) {
    return null;
  }

  const isRunningBuild =
    isPriorityBuildRunning(buildStatusContext.builds.nodes) || buildStatusContext.runNowStatus === "running";
  const isLoading = (buildStatusContext.loading || buildStatusContext.runNowStatus === "checking") && !isRunningBuild;
  const isDisabled = isLoading || isRunningBuild;
  const isInInialization = isLoading && buildStatusContext.runNowStatus === "idle";

  const runningOrInformationText = isRunningBuild ? "Test suite is running" : "Run test suite now";
  const buttonLabel = isLoading ? "Loading..." : runningOrInformationText;

  const button = (
    <>
      {!isInInialization && (
        <Button
          classes={{ contained: classes.disabled }}
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          startIcon={isDisabled ? <CircularProgress style={{ height: 17, width: 17, color: "white" }} /> : <PlayIcon />}
          data-testid={props.testId || "run-test-suite-now"}
          onClick={handleRunNow}
          pendo-id={props.pendoId}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );

  return (
    <>
      {isRunningBuild ? (
        <Tooltip
          classes={tooltipClasses}
          title={
            <Box display="flex">
              <InfoRoundedIcon className={classes.tooltipIcon} />
              <Typography className={classes.tooltipText} color="textPrimary">
                This test suite is already running. Please try again later.
              </Typography>
            </Box>
          }
        >
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
        maxWidth="xs"
        data-testid="run-now-alert-dialog"
      >
        <Box display="flex">
          <InfoOutlinedIcon className={classes.icon} />
          <Box>
            <Typography variant="h2" id="alert-dialog-title" className={classes.dialogTitle}>
              Sorry, test suite run didn’t start
            </Typography>
            <Typography id="alert-dialog-description" className={classes.dialogContent}>
              We were unable to save the changes and therefore the build didn’t start. Please check your test suite
              settings for any validation errors and try again.
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
