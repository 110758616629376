import { getLocations_getTestSuiteLocations, LocationCode } from "../../../../../generated-graphql-interfaces";

export function sortLocationData(
  locations: getLocations_getTestSuiteLocations[]
): getLocations_getTestSuiteLocations[] {
  const defaultLocation = locations.find(location => location.code === LocationCode.Default);
  if (!defaultLocation) {
    return locations;
  }
  const filteredData = locations.filter(location => location.code !== LocationCode.Default);
  return [defaultLocation, ...filteredData];
}
