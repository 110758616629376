import React from "react";
import { makeStyles, Typography, Box, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: theme.spacing(10)
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "1rem",
    marginRight: theme.spacing(1)
  },
  buttonWrapper: (props: IntegrationNameSetup) => {
    return {
      marginTop: props.integrationName.length > 50 ? "30px" : theme.spacing(7)
    };
  },
  tooltipTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginTop: 0,
    lineHeight: "1.125rem"
  },
  tooltipText: {
    fontSize: "0.875rem",
    color: "#202020"
  },
  error: {
    color: theme.palette.error.main
  },
  alert: {
    marginTop: theme.spacing(2)
  }
}));

interface IntegrationNameSetup {
  integrationName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

export function IntegrationNameSetup(props: IntegrationNameSetup) {
  const isNameTooLong = props.integrationName.length > 50;
  const classes = useStyles(props);
  const { integrationName, onChange, onSubmit } = props;

  return (
    <div data-testid="integration-name-setup" data-cy="integration-name-setup">
      <Typography variant="h1" className={classes.title} data-testid="integration-name-title">
        Jira Integration
      </Typography>

      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h2" className={classes.subtitle} data-testid="integration-name-subtitle">
          Integration name
        </Typography>

        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-integration-name-setup"
          maxWidth={400}
          body={
            <Box>
              <Typography variant="h6" className={classes.tooltipTitle}>
                Your Jira integration name
              </Typography>
              <Typography variant="body1" className={classes.tooltipText}>
                You should name your integration with a unique identifier based on the configuration settings you make
                if you plan to have multiple Jira integrations for a single Automation Hub account.
              </Typography>
            </Box>
          }
        />
      </Box>

      <TextField
        label="Integration name"
        variant="filled"
        fullWidth
        value={integrationName}
        onChange={onChange}
        data-testid="integration-name-input"
        data-pendo="auto-add-jira-integration-name-input"
      />

      {isNameTooLong && (
        <Typography data-testid="validation-error" variant="caption" className={classes.error}>
          Integration name cannot be more than 50 characters.
        </Typography>
      )}

      <Alert classes={{ root: classes.alert }} severity="info" data-testid="integration-name-msg">
        Please use a unique integration name if you have multiple Jira integrations.
      </Alert>

      <Box display="flex" justifyContent="flex-end" className={classes.buttonWrapper}>
        <PrimaryActionButton
          data-pendo="auto-testsuite-integration-name-continue"
          dataTestId="integration-name-button"
          dataCy="integration-name-button"
          disabled={!integrationName || isNameTooLong}
          onClick={onSubmit}
          label="Continue"
        />
      </Box>
    </div>
  );
}
