import React from "react";
import { makeStyles } from "@material-ui/core";
import { InstructionsList } from "../InstructionsList/InstructionsList";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 800
  }
}));

export function CiCdListInstructions(): JSX.Element {
  const classes = useStyles();

  const instructions: JSX.Element[] = [
    <span>
      Copy all CI/CD integration details onto your clipboard by clicking{" "}
      <span className={classes.bold}>Copy instructions below.</span>
    </span>,
    <span>
      Paste details into your messaging platform of choice (email/slack/jira etc.) and{" "}
      <span className={classes.bold}>send</span> to an admin who has access to your CI/CD pipeline. They will need to
      follow the instructions to complete the integration.
    </span>,
    <span>Finish the test suite setup.</span>,
    <span>
      Alternatively, you can start running your test suite immediately by clicking on{" "}
      <span className={classes.bold}>Run now</span>.
    </span>
  ];

  return <InstructionsList instructions={instructions} dataId="ci-cd-steps" />;
}
