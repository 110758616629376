import { InputLabelClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";

export const muiInputLabel: Partial<
  Record<InputLabelClassKey, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
> = {
  filled: {
    zIndex: 1,
    color: "#667078",
    transform: "translate(16px, 21px) scale(1)",
    pointerEvents: "none",
    "&$shrink": {
      transform: "translate(16px, 10px) scale(0.75)"
    }
  }
};
