import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface WindowWithGaTag extends Window {
  gtag: (type: string, id: string | undefined, obj: object) => void;
}

export const GaTag = withRouter((props: RouteComponentProps<{}>) => {
  useEffect(() => {
    const unlisten = props.history.listen(location => {
      if (typeof (window as WindowWithGaTag & typeof globalThis).gtag === "function") {
        (window as WindowWithGaTag & typeof globalThis).gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID, {
          page_path: location.pathname
        });
      }
    });

    return () => {
      unlisten();
    };
  }, [props.history]);

  return <></>;
});
