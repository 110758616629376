import {
  getTestSuites_getAccount_testSuites_edges,
  getTestSuites_getAccount_testSuites_edges_node_builds_nodes
} from "../../../generated-graphql-interfaces";

export function getTestSuiteInfo(testSuiteEdge: getTestSuites_getAccount_testSuites_edges) {
  const mostRecentBuild = testSuiteEdge.node.builds.nodes[0]; // might be undefined, hence the typecasting below
  const buildStatus =
    (mostRecentBuild as getTestSuites_getAccount_testSuites_edges_node_builds_nodes | undefined)?.status || "NotYetRun";

  return {
    id: testSuiteEdge.node.id,
    name: testSuiteEdge.node.name,
    sitePrimary: testSuiteEdge.node.sitePrimary,
    status: buildStatus,
    createdAt: testSuiteEdge.node.createdAt
  };
}
