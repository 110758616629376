import {
  getTestSuites,
  getTestSuites_getAccount,
  BuildStatus,
  getNextTestSuites,
  getPreviousTestSuites
} from "../../../generated-graphql-interfaces";

const MINIMUM_REFETCH_THRESHOLD = 5000; // in milliseconds / 5 seconds
const DEFAULT_REFETCH_TIME = 60000; // in milliseconds / 60 seconds
const PENALISATION_FOR_RUNNING_BUILD = 15000; // in milliseconds / 15 seconds
const PENALISATION_FOR_QUEUING_BUILD = 5000; // in milliseconds / 5 seconds

// Returns a sum of PENALISATION_FOR_RUNNING_BUILD * running statuses + PENALISATION_FOR_QUEUING_BUILD * queued statuses
const reductionTimeForStatuses = (statuses: BuildStatus[]) => {
  const runningBuildsReduction =
    statuses.filter(status => status === BuildStatus.Running).length * PENALISATION_FOR_RUNNING_BUILD;
  const queuingBuildsReduction =
    statuses.filter(status => status === BuildStatus.Queued).length * PENALISATION_FOR_QUEUING_BUILD;
  return runningBuildsReduction + queuingBuildsReduction;
};

const computeReductionTime = (oldData: getTestSuites | getNextTestSuites | getPreviousTestSuites | undefined) => {
  if (oldData?.getAccount?.testSuites?.edges.length) {
    const account = oldData.getAccount as getTestSuites_getAccount;
    return (
      account.testSuites?.edges
        .map(edge => edge.node.builds.nodes.flatMap(build => build.status))
        .map(status => reductionTimeForStatuses(status))
        .reduce((before, after) => before + after) || 0
    );
  }
  return 0;
};

// Never returns value that is lower than MINIMUM_REFETCH_THRESHOLD
const computeRetriggerThreshold = (oldData: getTestSuites | getNextTestSuites | getPreviousTestSuites | undefined) => {
  return Math.max(DEFAULT_REFETCH_TIME - computeReductionTime(oldData), MINIMUM_REFETCH_THRESHOLD);
};

const getCurrentTimeStamp = () => {
  return new Date().getTime();
};

export const shouldRefetchData = (
  lastTimeStamp: number,
  oldData: getTestSuites | getNextTestSuites | getPreviousTestSuites | undefined
) => {
  return getCurrentTimeStamp() - lastTimeStamp > computeRetriggerThreshold(oldData);
};
