import { getBuildTestStatus } from "../../../_common/utils/getBuildTestStatus/getBuildTestStatus";
import { getBuildTestProcessedValue } from "../../../_common/utils/getBuildTestProcessedValue/getBuildTestProcessedValue";
import {
  getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite,
  getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild,
  getNextTestSuites_getAccount_testSuites_edges_node,
  getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes,
  getPreviousTestSuites_getAccount_testSuites_edges_node,
  getPreviousTestSuites_getAccount_testSuites_edges_node_builds_nodes,
  getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes
} from "../../../generated-graphql-interfaces";
import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { TestSuiteListItemProps } from "../../../pages/TestSuiteList/components/TestSuiteListItem";
import { BasicSchedulerTestSuiteListItemProps } from "../../../pages/FrontendScheduler/components/SchedulerTestSuiteListItem";

type TestSuite =
  | getNextTestSuites_getAccount_testSuites_edges_node
  | getPreviousTestSuites_getAccount_testSuites_edges_node;

export type BuildTest =
  | getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes
  | getPreviousTestSuites_getAccount_testSuites_edges_node_builds_nodes;

function getTestCount(testCount: number | null): number {
  return testCount || 0;
}

export function getTestSuiteInfoWithLatestBuildTest(suite: TestSuite, buildTest: BuildTest): TestSuiteListItemProps {
  const status = getBuildTestStatus(buildTest);
  const failedTests = getTestCount(buildTest.failedTestCount);
  const warningTests = getTestCount(buildTest.warnedTestCount);
  const passedTests = getTestCount(buildTest.passedTestCount);
  const totalTests = failedTests + passedTests + warningTests;
  return {
    id: suite.id,
    name: suite.name,
    url: suite.sitePrimary,
    status,
    editable: true,
    lastRun: getBuildTestProcessedValue(buildTest.finishedAt, status),
    totalTests: getBuildTestProcessedValue(totalTests, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: suite.builds?.nodes[0]?.id || buildTest.id,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null
  };
}

export function getSchedulerTestSuiteInfoWithLatestBuildTest(
  suite: TestSuite,
  buildTest: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild
): TestSuiteListItemProps {
  const status = getBuildTestStatus(buildTest);
  const failedTests = getTestCount(buildTest.failedTestCount);
  const warningTests = getTestCount(buildTest.warnedTestCount);
  const passedTests = getTestCount(buildTest.passedTestCount);
  const totalTests = failedTests + passedTests + warningTests;
  return {
    id: suite.id,
    name: suite.name,
    url: suite.sitePrimary,
    status,
    editable: true,
    lastRun: getBuildTestProcessedValue(buildTest.finishedAt, status),
    totalTests: getBuildTestProcessedValue(totalTests, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: buildTest.id,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null
  };
}

export function getTestSuiteInfoWithoutBuildTest(suite: TestSuite): TestSuiteListItemProps {
  return {
    id: suite.id,
    name: suite.name,
    url: suite.sitePrimary,
    lastRun: "Not yet run",
    totalTests: "Not yet run",
    failedTests: "Not yet run",
    warningTests: "Not yet run",
    passedTests: "Not yet run",
    status: ExtendedBuildStatus.NotYetRun,
    editable: true,
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: null,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null
  };
}

function getLatestTestBuild(suite: TestSuite) {
  const areBuildsPresent = Array.isArray(suite?.builds?.nodes);
  const sortedBuilds = suite.builds.nodes?.sort((item1: BuildTest, item2: BuildTest) =>
    item1.updatedAt >= item2.updatedAt ? -1 : 1
  );
  return areBuildsPresent && sortedBuilds[0];
}

export function testSuiteItemInformationFactory(suite: TestSuite) {
  const latestBuildTest = getLatestTestBuild(suite);
  if (latestBuildTest) {
    return getTestSuiteInfoWithLatestBuildTest(suite, latestBuildTest);
  }
  return getTestSuiteInfoWithoutBuildTest(suite);
}

export function schedulerSuiteItemInformationFactory(
  node: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes
): BasicSchedulerTestSuiteListItemProps {
  const latestBuildTest = node.lastRunBuild;
  if (latestBuildTest) {
    return getSchedulerTestSuiteInfoWithLatestBuildTest(node.testSuite as TestSuite, latestBuildTest);
  }
  return getTestSuiteInfoWithoutBuildTest(node.testSuite as TestSuite);
}
