import { Container, ContainerProps, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}));

export function PageContainer(props: ContainerProps) {
  const classes = useStyles();

  return <Container maxWidth="xl" className={classes.container} data-testid="page-container" {...props} />;
}
