function getNewColorComponent(component: number, alpha: number) {
  return (255 - component) * alpha + component;
}

function getNewColorWithoutAlpha(r: number, g: number, b: number, alpha: number) {
  const newR = Math.round(getNewColorComponent(r, alpha));
  const newG = Math.round(getNewColorComponent(g, alpha));
  const newB = Math.round(getNewColorComponent(b, alpha));
  return `rgb(${newR}, ${newG}, ${newB})`;
}

function parseColorComponentHexToNumber(componentHex: string, length: number) {
  return parseInt(componentHex + (length === 1 ? componentHex : ""), 16);
}

function parseHexToRGB(hex: string) {
  const multiplier = (hex.length - 1) / 3;
  const rString = hex.slice(1, 1 + multiplier);
  const gString = hex.slice(1 + multiplier, 1 + multiplier * 2);
  const bString = hex.slice(1 + multiplier * 2, 1 + multiplier * 3);
  const r = parseColorComponentHexToNumber(rString, multiplier);
  const g = parseColorComponentHexToNumber(gString, multiplier);
  const b = parseColorComponentHexToNumber(bString, multiplier);
  return { r, g, b };
}

export function hexToRgbA(hex: string, alpha?: number, alphaTransitionToWhite?: boolean) {
  if (alpha !== undefined && (alpha > 1 || alpha < 0)) {
    throw new Error(`Bad alpha for color provided, ${alpha} is not a valid number between 0 and 1.`);
  }
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    const { r, g, b } = parseHexToRGB(hex);
    if (alphaTransitionToWhite && alpha !== undefined && !Number.isNaN(alpha) && alpha >= 0 && alpha <= 1) {
      return getNewColorWithoutAlpha(r, g, b, alpha);
    } else {
      return `rgba(${r}, ${g}, ${b}, ${alpha !== undefined && !Number.isNaN(alpha) ? alpha : 1})`;
    }
  } else {
    throw new Error(`Bad hex color provided, ${hex} is not a valid HEX colour.`);
  }
}
