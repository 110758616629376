import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box, makeStyles, Typography, Card, Button, Collapse } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import LinkOffRoundedIcon from "@material-ui/icons/LinkOffRounded";
import { IntegrationDetails, IntegrationDetailConnectionType } from "./IntegrationDetails";
import { getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration } from "../../../../../generated-graphql-interfaces";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { defaultPopOverId } from "../../../../../_common/constants/popover";

const useStyles = makeStyles(theme => ({
  alertTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "bold",
    fontSize: "1.125rem"
  },
  alertText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1rem"
  },
  alertAction: {
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    "& svg": {
      fontSize: "1.5rem"
    }
  },
  successIcon: {
    color: theme.palette.deepcrawlGreen.light
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    boxShadow: "none"
  },
  cardTitle: {
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "1.125rem"
  },
  disconnectBtn: {
    boxShadow: "none",
    minWidth: "0px",
    lineHeight: 1,
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: "#F8EDED",
    color: theme.palette.error.main,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5d5d5",
      boxShadow: "none"
    }
  },
  cardHeader: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3)
    }
  },
  integrationWrapper: {
    padding: theme.spacing(8, 6.5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3)
    }
  }
}));

interface ConnectedIntegrationViewProps {
  jiraIntegration?: getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration;
  isLinkingAlertOpen: boolean;
  unlinkIntegration: () => Promise<void>;
  handleCloseLinkingAlert: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function ConnectedIntegrationView(props: ConnectedIntegrationViewProps) {
  const classes = useStyles();
  const [popoverAnchorElement, setPopoverAnchorElement] = React.useState<HTMLButtonElement | null>(null);

  async function handleRemoveIntegration(id: PopoverDialogDefaultIdentifiers | string) {
    setPopoverAnchorElement(null);

    if (id === PopoverDialogDefaultIdentifiers.OK) {
      await props.unlinkIntegration();
    }
  }

  const handleClickDisconnect = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElement(event.currentTarget);
  };

  const showDisconnectIntegration = Boolean(popoverAnchorElement);
  const popoverId = showDisconnectIntegration ? defaultPopOverId : undefined;
  const popoverButtons: PopoverDialogButton[] = [
    {
      label: "Cancel",
      identifier: PopoverDialogDefaultIdentifiers.CANCEL,
      color: "primary"
    },
    {
      label: "Disconnect",
      identifier: PopoverDialogDefaultIdentifiers.OK,
      color: "alert"
    }
  ];

  return (
    <div data-testid="connected-integration-wrapper">
      <Collapse in={props.isLinkingAlertOpen} data-testid="successful-integration-alert-collapse">
        <Box mb={4}>
          <Alert
            onClose={props.handleCloseLinkingAlert}
            data-testid="successful-integration-alert"
            classes={{
              action: classes.alertAction
            }}
            severity="success"
            iconMapping={{
              success: (
                <CheckCircleOutlineRoundedIcon
                  data-testid="close-alert"
                  fontSize="default"
                  className={classes.successIcon}
                />
              )
            }}
          >
            <AlertTitle className={classes.alertTitle}>Jira has been integrated successfully.</AlertTitle>
            <Typography>
              Now an issue will be created in your Jira backlog for each fail and warning generated by your tests.
            </Typography>
          </Alert>
        </Box>
      </Collapse>
      <Card className={classes.card}>
        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.cardHeader}>
          <Typography variant="h4" className={classes.cardTitle} data-testid="integration-title">
            {props.jiraIntegration?.name}
          </Typography>
          <Button
            className={classes.disconnectBtn}
            variant="contained"
            startIcon={<LinkOffRoundedIcon />}
            data-testid="disconnect-integration-btn"
            onClick={handleClickDisconnect}
          >
            Disconnect
          </Button>
          <PopoverDialog
            anchorElement={popoverAnchorElement}
            handleAction={handleRemoveIntegration}
            open={showDisconnectIntegration}
            text="If you disconnect this integration, you won’t receive any new tickets in your Jira for this Test Suite."
            title="Are you sure you want to disconnect this integration?"
            buttons={popoverButtons}
            id={popoverId}
          />
        </Box>
        <Box className={classes.integrationWrapper}>
          <IntegrationDetails
            jiraIntegration={props.jiraIntegration}
            status="Connected"
            statusType={IntegrationDetailConnectionType.OK}
          />
        </Box>
      </Card>
    </div>
  );
}
