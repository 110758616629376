import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: theme.spacing(2, 2, 0, 2)
  },
  instructions: {
    margin: theme.spacing(2.5, 0, 3, 0)
  },
  intergrationHeader: {
    fontWeight: 500,
    display: "block",
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.navy[300],
    marginTop: 0
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500
  },
  integrationDetailsWrapper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 3
  },
  notRunIconWrapper: {
    marginRight: theme.spacing(2)
  }
}));
