import { Box, Collapse, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { DeepCrawlSwitch } from "../../../../_common/components/DeepCrawlSwitch/DeepCrawlSwitch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(5, 0, 3, 0)
    }
  },
  title: {
    fontWeight: 400,
    fontSize: 22,
    marginRight: theme.spacing(2)
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(2)
  },
  subtitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2px 0px"
  },
  icon: {
    color: theme.palette.navy[200]
  },
  divider: {
    color: theme.palette.navy[50]
  },
  formControlLabel: {
    fontSize: 14,
    color: theme.palette.navy[500]
  },
  radioGroup: {
    margin: theme.spacing(2, 0)
  },
  smallIcon: {
    color: "#BDC2C5",
    fontSize: 18,
    marginLeft: 8,
    paddingTop: 2
  }
}));

export interface AutomaticThresholdSettingsProps {
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  handleSmartThresholdSettingsChange: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
}
// eslint-disable-next-line max-lines-per-function
export function AutomaticThresholdSettings({
  smartThresholdSettings,
  handleSmartThresholdSettingsChange
}: AutomaticThresholdSettingsProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container} data-testid="automatic-threshold-wrapper" data-cy="automatic-threshold">
      <Box display="flex" alignItems="center" mb={2}>
        <Typography color="textPrimary" data-testid="automatic-threshold-title" className={classes.title}>
          Test settings
        </Typography>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-automatic-threshold-settings"
          testId="test-settings-tooltip"
          size={20}
          maxWidth={450}
          iconComponent={<InfoOutlinedIcon fontSize="small" className={classes.icon} />}
          body="These settings will apply to all tests in this test suite."
        />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.subtitleWrapper}>
        <Box display="flex" alignItems="center">
          <Typography color="textPrimary" data-testid="automatic-threshold-subtitle" className={classes.subtitle}>
            Use smart thresholds
          </Typography>
          <DeepcrawlInfoTooltip
            data-pendo="auto-tooltip-automatic-threshold-info"
            title="Smart thresholds"
            testId="automatic-threshold-tooltip"
            size={20}
            maxWidth={450}
            iconComponent={<InfoOutlinedIcon fontSize="small" className={classes.icon} />}
            body={
              <>
                <p>
                  Learn from previous test results and update test thresholds automatically. The more tests you run and
                  the better your results, the more strict your thresholds will become and the better your SEO outcome
                  will be. The test settings will be set to an optimal value based on previous test suite runs.
                </p>
                <ul style={{ paddingInlineStart: 15 }}>
                  <li>
                    If a test passes with a value better than the test threshold, the threshold will automatically
                    update to the appropriate new value.
                  </li>
                  <li>
                    When enabled, the test thresholds will be optimized for all tests in this test suite. If you want
                    enable this for specific tests only, enable the &quot;Set on a per test basis&quot; toggle.
                  </li>
                </ul>
              </>
            }
          />
        </Box>
        <DeepCrawlSwitch
          checked={smartThresholdSettings.isEnabled}
          onChange={event => {
            handleSmartThresholdSettingsChange(currentSettings => ({
              ...currentSettings,
              isEnabled: event.target.checked
            }));
          }}
          data-testid="automatic-threshold-toggle"
          data-cy="automatic-threshold-toggle"
          data-pendo="auto-enable-smart-threshold-toggle"
          inputProps={{ "aria-label": "Enable smart threshold" }}
        />
      </Box>
      <Divider className={classes.divider} />

      <Collapse in={smartThresholdSettings.isEnabled}>
        <Box>
          <RadioGroup
            aria-label="smart-threshold-settings"
            name="smart-threshold-settings"
            value={smartThresholdSettings.enabledForAllTests ? "all" : "individual"}
            onChange={event => {
              handleSmartThresholdSettingsChange(currentSettings => ({
                ...currentSettings,
                enabledForAllTests: event.target.value === "all"
              }));
            }}
            classes={{ root: classes.radioGroup }}
          >
            <FormControlLabel
              data-cy="automatic-threshold-check-all"
              value="all"
              name="Apply to all tests"
              control={<Radio />}
              label="Apply to all tests"
              classes={{ label: classes.formControlLabel }}
              data-pendo="auto-smart-threshold-apply-to-all-tests"
            />
            <FormControlLabel
              data-cy="automatic-threshold-check-individual"
              value="individual"
              name="Apply to individual tests"
              control={<Radio />}
              classes={{ label: classes.formControlLabel }}
              label={
                <Box display="flex" alignItems="center">
                  <Typography className={classes.formControlLabel}>
                    Apply to individual tests (Enable below for each test)
                  </Typography>
                  <DeepcrawlInfoTooltip
                    data-pendo="auto-tooltip-optimize-threhold-individual-tests"
                    title="Automatically optimize threshold on a per test basis"
                    testId="automatic-threshold-tooltip-individual"
                    size={20}
                    maxWidth={450}
                    iconComponent={<InfoOutlinedIcon fontSize="small" className={classes.smallIcon} />}
                    body="Choose which tests to automatically set thresholds."
                  />
                </Box>
              }
              data-pendo="auto-smart-threshold-apply-to-individual-tests"
            />
          </RadioGroup>
          <Divider />
        </Box>
      </Collapse>
    </Box>
  );
}
