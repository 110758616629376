import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { AutocompleteClassKey } from "@material-ui/lab";
import { PropsFunc } from "../overrides";
import { colorBlue, colorGrey, colorNavy, colorSecondary } from "./../palette";

export const muiAutocomplete:
  | Partial<
      Record<AutocompleteClassKey, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
    >
  | undefined = {
  root: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: colorSecondary.main
      },
      "& input": {
        color: colorNavy[500]
      }
    },
    "& .MuiAutocomplete-inputFocused": {
      color: colorNavy[500]
    },
    "& fieldset": {
      borderRadius: 4
    },
    "& input": {
      lineHeight: 1,
      color: colorNavy[300]
    }
  },
  inputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colorGrey[400]
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colorSecondary.main
      }
    }
  },
  paper: {
    "& .MuiAutocomplete-listbox > li:nth-child(2) > div": {
      borderTop: `1px solid ${colorGrey[300]}`,
      paddingTop: "12px",
      marginTop: "10px"
    }
  },
  groupLabel: {
    fontSize: "16px",
    lineHeight: "30px",
    color: colorNavy[500],
    paddingTop: "10px"
  },
  option: {
    padding: "10px 16px",
    fontSize: "14px"
  },
  tag: {
    backgroundColor: colorBlue[50]
  },
  groupUl: {
    "& .MuiAutocomplete-option": {
      paddingLeft: "32px"
    }
  },
  listbox: {
    paddingTop: 0
  }
};
