import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ResponsiveTabsConfig } from "./ResponsiveTabs";

const useStyles = makeStyles(theme => ({
  paddingSmall: {
    padding: theme.spacing(3, 3, 0, 3)
  }
}));

interface ResponsiveTabsContentProps {
  config: ResponsiveTabsConfig[];
  isResponsiveLayout: boolean;
  selectedTabId: string | null;
}

export function ResponsiveTabsContent(props: ResponsiveTabsContentProps) {
  const classes = useStyles();

  return (
    <div data-testid="responsive-tabs-content">
      {props.config.map(
        tabItem =>
          tabItem.id === props.selectedTabId && (
            <Box key={tabItem.label} className={clsx(props.isResponsiveLayout && classes.paddingSmall)}>
              {tabItem.content}
            </Box>
          )
      )}
    </div>
  );
}
