import React from "react";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { TestSuiteItemInfo } from "./TestSuiteItemInfo";
import { Paper, Grid, Box, Typography } from "@material-ui/core";
import { generateEmailTextAndCopyToClipboard } from "../../../_common/components/CiCdForm/CiCdFormUtils";
import { useStylesItemGrid } from "./TestSuiteListItemStyles";
import { useStyles } from "./TestSuiteListItemNotYetRunStyles";
import { BuildStatus } from "../../../generated-graphql-interfaces";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { TestSuiteItemActionsMenu } from "./TestSuiteItemActionsMenu";
import clsx from "clsx";
import { shouldCloneWarningBeVisible } from "./TestSuiteListItemUtils";
import { GreyButton } from "../../../_common/components/GreyButton/GreyButton";

export interface TestSuiteListItemNotYetRunProps {
  id: string;
  status: BuildTestStatus;
  lastRun: string;
  name: string;
  url: string;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
  refetchData?: () => void;
  testSuiteJiraIntegrationId?: string | null;
}

export function TestSuiteListItemNotYetRun(props: TestSuiteListItemNotYetRunProps): JSX.Element {
  const classes = useStyles(props);
  const classesGrid = useStylesItemGrid();
  const showCloneWarning = shouldCloneWarningBeVisible(props.testSuiteUpdatedAt, props.testSuiteClonedAt);
  const isTestSuiteRunning = props.status === BuildStatus.Running;

  return (
    <div className={classes.wrapper}>
      <div className={classes.border}></div>
      <Paper
        className={clsx(classesGrid.root, classes.rootCompact)}
        data-cy="test-suite-list-item"
        data-testid="test-suite-list-item-compact"
      >
        <Box className={classesGrid.mainGrid}>
          <Box className={classesGrid.iconWrapper}>
            <BuildStatusIcon status={props.status} size={StatusIconSize.LG} tooltipVariant={true} />
          </Box>

          <Grid container className={classesGrid.textWrapper}>
            <Grid item xs={12} lg={5} className={classesGrid.itemInfoWrapper}>
              <TestSuiteItemInfo {...props} showCloneWarning={showCloneWarning} />
            </Grid>
            <Grid item xs={12} lg={3} className={classesGrid.chipsWrapper}>
              <GreyButton
                onClick={() => generateEmailTextAndCopyToClipboard(props.name, props.url, props.id)}
                data-testid="copy-to-clipboard-button"
                data-pendo="auto-testsuiteslist-item-copy-to-clipboard"
              >
                Copy to clipboard
              </GreyButton>
            </Grid>
            <Grid item xs={12} lg={4} className={classesGrid.dateTimeWrapper}>
              <Typography data-testid="test-suite-list-item-id" color="textPrimary" className={classes.testSuiteId}>
                {`Test suite ID: ${props.id}`}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classesGrid.actionsMenuWrapper}>
            <TestSuiteItemActionsMenu
              testSuiteId={props.id}
              isTestSuiteRunning={isTestSuiteRunning}
              refetchData={props.refetchData}
              testSuiteJiraIntegrationId={props.testSuiteJiraIntegrationId}
            />
          </Box>
        </Box>
      </Paper>
    </div>
  );
}
