import { useQuery } from "@apollo/react-hooks";
import { getBuildTestsVariables, getBuildTests } from "../../../generated-graphql-interfaces";
import { getBuildTestsQuery } from "./getBuildTestsQuery";

export function useBuildTestsQuery(variables: getBuildTestsVariables) {
  return useQuery<getBuildTests, getBuildTestsVariables>(getBuildTestsQuery, {
    variables,
    fetchPolicy: "cache-and-network"
  });
}
