import React from "react";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";

import { Paper, Grid, Box, Typography } from "@material-ui/core";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";

import { useStyles } from "./SchedulerTestSuiteListItemStartedStyles";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { BuildStatus, RoleCode } from "../../../generated-graphql-interfaces";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";

import clsx from "clsx";
import { TestCountChips } from "../../TestSuiteList/components/TestCountChips";
import { SchedulerTestSuiteItemInfo } from "./SchedulerTestSuiteItemInfo";
import { ActionMenuItem, ActionsMenu } from "../../../_common/components/ActionMenu/ActionsMenu";
import { doesUserMatchRole } from "../../../_common/utils/doesUserMatchRole/doesUserMatchRole";
import { createLastRunText } from "../../../_common/utils/createLastRunText/createLastRunText";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";
import { useStylesItemGrid } from "./SchedulerTestSuiteListItemStyles";

export interface SchedulerTestSuiteListItemStartedProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  updatedAt: string;
  onActionMenuEvent: (event: string) => Promise<boolean>;
  overrideActions: ActionMenuItem[];
}

export function SchedulerTestSuiteListItemStarted(props: SchedulerTestSuiteListItemStartedProps): JSX.Element {
  const classes = useStyles(props);
  const classesGrid = useStylesItemGrid();
  const accountId = useAccountRouteMatch();
  const history = useHistory();
  const testResultsPath = Routes.TestResults.getUrl({
    accountId,
    testSuiteId: props.id,
    buildTestId: props.buildId || "",
    selectedType: TestsToDisplayOption.ALL
  });
  const isTestSuiteRunning = props.status === BuildStatus.Running;
  const isViewOnly = doesUserMatchRole(RoleCode.Viewer);

  function handleLinkClick(event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (event.isDefaultPrevented() || isTestSuiteRunning) return;
    else {
      history.push(testResultsPath);
    }
  }

  return (
    <div
      onClick={handleLinkClick}
      className={classes.showAsNotLink}
      data-testid="scheduler-test-suite-list-item-started"
      data-pendo={`auto-scheduler-testsuites-list-item-${props.status.replace(/\s+/g, "-").toLowerCase()}`}
    >
      <div className={clsx(classes.wrapper, isTestSuiteRunning && classes.disabled)}>
        <div className={classes.border}></div>
        <Paper className={clsx(classes.root, classesGrid.root)} data-cy="scheduler-test-suite-list-item" elevation={0}>
          <Box className={classesGrid.mainGrid}>
            <Box className={clsx(classesGrid.iconWrapper, classes.iconWrapperSpecific)}>
              <BuildStatusIcon status={props.status} size={StatusIconSize.LG} tooltipVariant={true} />
            </Box>
            <Grid container className={classesGrid.textWrapper}>
              <Grid item xs={12} lg={5} className={classesGrid.itemInfoWrapper}>
                <SchedulerTestSuiteItemInfo name={props.name} url={props.url} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                lg={4}
                className={clsx(classesGrid.chipsWrapper, classes.chipWarpperSpecific)}
              >
                <TestCountChips
                  testSuiteId={props.id}
                  buildId={props.buildId}
                  totalTests={props.totalTests}
                  warningTests={props.warningTests}
                  failedTests={props.failedTests}
                  passedTests={props.passedTests}
                  skipTotalChips={true}
                  status={props.status}
                  pendoIdSuffix="scheduler"
                  showLabel={true}
                />
              </Grid>
              <Grid item xs={1} lg={1}></Grid>
              <Grid item xs={12} lg={2} className={clsx(classesGrid.dateTimeWrapper, classes.dateTimeWrapperSpecific)}>
                <Typography
                  className={classes.lastRun}
                  data-cy="scheduler-test-suite-list-item-last-run"
                  data-testid="scheduler-test-suite-list-item-last-run"
                >
                  {createLastRunText(props.status, props.updatedAt, props.lastRun)}
                </Typography>
              </Grid>
            </Grid>
            <Box className={classesGrid.actionsMenuWrapper}>
              {!isViewOnly && (
                <ActionsMenu
                  onActionMenuEvent={props.onActionMenuEvent}
                  customActions={props.overrideActions}
                  pendoId="auto-scheduler-detail-testsuites-item-menu"
                />
              )}
            </Box>
          </Box>
        </Paper>
      </div>
    </div>
  );
}
