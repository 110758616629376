import React, { useState, ChangeEvent, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardActions, Button, Collapse } from "@material-ui/core";
import jiraLogoBig from "../../../../../images/jira-logo-big.svg";
import { Alert } from "@material-ui/lab";
import JiraIntegrationDialog, { JiraIntegrationDialogAction } from "./JiraIntegrationDialog";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { IntegrationDetails, IntegrationDetailConnectionType } from "./IntegrationDetails";
import { useNoIntegrationsStyles } from "./NoIntegrationsViewStyles";
import { AddOrSelectJiraIntegration, ADD_NEW_INTEGRATION_VALUE } from "./AddOrSelectJiraIntegration";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { useGetJiraIntegrationsQuery } from "../../../../ConnectedApps/JiraIntegration/graphql/useGetJiraIntegrationsQuery";
import { useLinkJiraIntegrationToTestSuiteMutation } from "../graphql/useLinkJiraIntegrationToTestSuite";
import { getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration } from "../../../../../generated-graphql-interfaces";

interface NoIntegrationsViewProps {
  testSuiteId: string;
  onSuccessfulLinking: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function NoIntegrationsView(props: NoIntegrationsViewProps) {
  const classes = useNoIntegrationsStyles();
  const [isNewJiraIntegrationDialogOpen, setIsNewJiraIntegrationDialogOpen] = useState(false);
  const accountId = useAccountRouteMatch();

  const {
    data,
    loading: integrationsLoading,
    error: integrationsError
  } = useGetJiraIntegrationsQuery({
    accountId
  });

  const [linkJiraIntegration, { loading: linkIntegrationLoading, error: linkError }] =
    useLinkJiraIntegrationToTestSuiteMutation();

  const [selectedIntegrationName, setSelectedIntegrationName] = useState<string | null>(null);
  const [selectedJiraIntegration, setSelectedJiraIntegration] = useState<
    getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration | undefined
  >(undefined);

  useEffect(() => {
    const name = selectedIntegrationName?.split(" - ")[0];
    const jiraIntegration = data.jiraIntegrations.find(integration => integration.name === name);
    setSelectedJiraIntegration(jiraIntegration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegrationName]);

  function handleChange(event: ChangeEvent<{}>, newValue: string | null) {
    setSelectedIntegrationName(newValue);

    if (newValue === ADD_NEW_INTEGRATION_VALUE) {
      setIsNewJiraIntegrationDialogOpen(true);
    }
  }

  async function handleConnect(jiraIntegrationId: string) {
    try {
      await linkJiraIntegration({
        variables: {
          jiraIntegrationId,
          testSuiteId: props.testSuiteId
        }
      });
      props.onSuccessfulLinking();
    } catch (e) {
      // No need to do anything as Rollbar should catch this
    }
  }

  return (
    <div data-testid="no-integrations-view">
      <Box mb={5}>
        {integrationsError && (
          <Alert className={classes.error} severity="error" data-testid="get-integrations-error">
            There was an error loading your existing Jira integrations. Please refresh the page and try again.
          </Alert>
        )}
        {linkError && (
          <Alert className={classes.error} severity="error" data-testid="link-integration-error">
            Sorry, looks like something went wrong while trying to connect a Jira integration to the test suite. Please
            try again or contact support.
          </Alert>
        )}
      </Box>

      <Card className={classes.card}>
        <Box display="flex" justifyContent="center" py={2} className={classes.cardLogoWrapper}>
          <img src={jiraLogoBig} alt="Big Jira Logo" data-testid="big-jira-logo" />
        </Box>

        <CardContent className={classes.cardContent}>
          <Typography variant="h4" className={classes.cardTitle} data-testid="jira-card-title">
            Connect your Jira project to your test suite
          </Typography>

          <Typography variant="body1" className={classes.cardText} data-testid="jira-card-text">
            Automatically create issues in your Jira backlog for fails and warnings generated by your tests.
          </Typography>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <AddOrSelectJiraIntegration
            isLoading={!!integrationsLoading}
            selectedJiraIntegrationName={selectedIntegrationName}
            jiraIntegrations={data.jiraIntegrations}
            handleChange={handleChange}
            handleOpenDialog={() => setIsNewJiraIntegrationDialogOpen(true)}
          />
        </CardActions>

        {selectedJiraIntegration && (
          <Collapse in={!!selectedJiraIntegration} timeout={500}>
            <Box data-testid="integration-details-container">
              <CardContent className={classes.integrationDetailsWrapper}>
                <IntegrationDetails
                  jiraIntegration={selectedJiraIntegration}
                  status="Not connected"
                  description="Review integration details and click connect when you are ready. Please note, only new results will be added to Jira."
                  statusType={IntegrationDetailConnectionType.WARNING}
                />
              </CardContent>

              <CardActions className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  data-testid="cancel-connect-integration-btn"
                  onClick={() => {
                    setSelectedIntegrationName(null);
                  }}
                >
                  Cancel
                </Button>

                <PrimaryActionButton
                  data-pendo="auto-testsuite-integration-no-integration-connect"
                  dataTestId="connect-integration-btn"
                  dataCy="connect-integration-btn"
                  onClick={async () => {
                    await handleConnect(selectedJiraIntegration.id);
                    setSelectedIntegrationName(null);
                  }}
                  label="Connect"
                  disabled={linkIntegrationLoading}
                />
              </CardActions>
            </Box>
          </Collapse>
        )}
      </Card>

      <JiraIntegrationDialog
        action={JiraIntegrationDialogAction.Create}
        accountId={accountId}
        isOpen={isNewJiraIntegrationDialogOpen}
        handleClose={() => setIsNewJiraIntegrationDialogOpen(false)}
        jiraIntegrationLinker={handleConnect}
      />
    </div>
  );
}
