import React, { useState } from "react";
import { makeStyles, Divider, Box, Typography, FormControlLabel, Switch, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { ExtendedTest } from "../../../pages/TestSuite/components/ChooseTestsForm/CreateTests.interfaces";
import { DeepcrawlInfoTooltip } from "../DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { Severity } from "../../../generated-graphql-interfaces";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DeepCrawlSwitch } from "../DeepCrawlSwitch/DeepCrawlSwitch";

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(4, 0, 1, 0)
  },
  enableJiraTitle: {
    fontWeight: 500,
    fontSize: "1rem",
    marginRight: theme.spacing(1)
  },
  alert: {
    marginBottom: theme.spacing(4)
  },
  addCustomNote: {
    fontSize: "0.875rem",
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.text.primary
  },
  tooltipIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main
  },
  tooltipTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.125rem",
    marginBottom: theme.spacing(1)
  },
  tooltipText: {
    color: "#202020",
    fontSize: "0.875rem"
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  icon: {
    color: theme.palette.navy[200]
  }
}));

interface EnableJiraProps {
  handleEnableJiraChange: (input: {
    createJiraTicketOnFailure: boolean | null;
    jiraTicketCustomNote: string | null;
  }) => void;
  test: ExtendedTest;
  isJiraIntegrationConnected: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function EnableJira(props: EnableJiraProps) {
  const classes = useStyles();
  const [jiraSettings, setJiraSettings] = useState({
    jiraTicketCustomNote: props.test.data.jiraTicketCustomNote,
    createJiraTicketOnFailure: props.test.data.createJiraTicketOnFailure
  });
  const labelText = `Create Jira ticket if test ${props.test.data.severity === Severity.Fail ? "fails" : "warns"}`;

  function handleToggle() {
    const newSettings = {
      jiraTicketCustomNote: jiraSettings.jiraTicketCustomNote,
      createJiraTicketOnFailure: !jiraSettings.createJiraTicketOnFailure
    };
    setJiraSettings(newSettings);
    props.handleEnableJiraChange(newSettings);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSetting = {
      createJiraTicketOnFailure: jiraSettings.createJiraTicketOnFailure,
      jiraTicketCustomNote: event.currentTarget.value
    };
    setJiraSettings(newSetting);
    props.handleEnableJiraChange(newSetting);
  }

  return (
    <div data-testid="enable-jira">
      <Divider className={classes.divider} />
      <Box className={classes.headerWrapper} mb={2}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.enableJiraTitle}
            data-testid="enable-jira-title"
          >
            {labelText}
          </Typography>
          <DeepcrawlInfoTooltip
            data-pendo="auto-tooltip-enable-jira"
            testId="enable-jira-tooltip"
            title="Jira Integration"
            body="You can notify your development team about failed or received a warning test with an automatic Jira
        integration feature. You will be able to set it up in the next step."
            maxWidth={350}
            iconComponent={<InfoOutlinedIcon fontSize="small" className={classes.icon} />}
          />
        </Box>
        <DeepCrawlSwitch
          name="jira-ticket-toggle"
          data-testid="enable-jira-toggle"
          data-pendo={`auto-test-suite-edit-choose-tests-test-jira-ticket-create-toggle`}
          color="secondary"
          checked={jiraSettings.createJiraTicketOnFailure}
          onChange={handleToggle}
          inputProps={{ "aria-label": "Enable jira toggle" }}
        />
      </Box>

      {jiraSettings.createJiraTicketOnFailure && (
        <Box mb={2}>
          {!props.isJiraIntegrationConnected && (
            <Alert
              data-testid="enable-jira-alert"
              severity="warning"
              className={classes.alert}
              icon={<WarningRoundedIcon />}
            >
              To enable this feature please connect to Jira in the next step.
            </Alert>
          )}
          <Typography className={classes.addCustomNote} data-testid="custom-note-header">
            Add custom note to Jira ticket
          </Typography>
          <TextField
            data-testid="custom-note-input"
            data-pendo={`auto-test-suite-edit-choose-tests-test-jira-ticket-create-note`}
            fullWidth
            variant="filled"
            label="Write note here"
            id="custom-note-input"
            value={jiraSettings.jiraTicketCustomNote || ""}
            onChange={handleInputChange}
          />
        </Box>
      )}
    </div>
  );
}
