import { ApolloError, ApolloQueryResult } from "apollo-client";
import React, { useContext } from "react";
import { getTestSuiteVariables, getTestSuite, getReportTemplates } from "../../generated-graphql-interfaces";
import { permissionsContext } from "../../_common/contexts/Permissions/Permissions.context";
import ChooseTestsForm, { ChooseTestsFormProps } from "./components/ChooseTestsForm/ChooseTestsForm";
import { ConnectCiCdTool } from "./components/ConnectCiCdTool/ConnectCiCdTool";
import { UpdateConnectSite } from "./components/ConnectSiteForm/UpdateConnectSite";
import { CrawlSettings } from "./components/CrawlSettingsForm/CrawlSettings";
import { SetAlertsForm } from "./components/SetAlerts/SetAlertsForm";
import { CustomStep } from "./CustomStep";
import { useTestSuiteTestsAndExtractionsQuery } from "./graphql/useTestSuiteTestsAndCustomExtractionsQuery";
import { StepChangeHandler } from "./UpdateTestSuite";

interface UpdateTestSuiteStepsProps
  extends Omit<
    ChooseTestsFormProps,
    "onSuccess" | "useTestSuiteTestsAndExtractionsQueryObject" | "reportTemplateObject"
  > {
  refetchTestSuite: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
  testSuiteId: string;
  handleStepChange: (step: string, shouldExpandNextStep?: boolean | undefined) => StepChangeHandler;
  expandedStep: string;
  useReportTemplateObject: {
    loading: boolean;
    data: getReportTemplates | undefined;
    error: ApolloError | undefined;
  };
}

export function UpdateTestSuiteSteps(props: UpdateTestSuiteStepsProps) {
  const { testSuite, handleStepChange } = props;
  const doesParentExist = Boolean(testSuite?.parent);
  const { permissions } = useContext(permissionsContext);

  const useTestSuiteTestsAndExtractionsQueryObject = useTestSuiteTestsAndExtractionsQuery({
    id: props.testSuiteId,
    hasJiraAccess: permissions.jiraIntegrationAccess,
    hasSmartThresholdsAccess: permissions.automaticThresholdAccess
  });

  function refetchData() {
    useTestSuiteTestsAndExtractionsQueryObject.refetch();
    props.refetchTestSuite();
  }

  const stepsConfigs = [
    {
      name: "Connect site",
      number: 1,
      pendoId: "auto-edit-test-suite-step1",
      children: (
        <UpdateConnectSite
          refetchTestSuite={props.refetchTestSuite}
          testSuite={testSuite}
          onSubmit={handleStepChange("step2", !doesParentExist)}
        />
      )
    },
    {
      name: "Choose crawl settings",
      number: 2,
      pendoId: "auto-edit-test-suite-step2",
      children: (
        <CrawlSettings
          testSuite={testSuite}
          onSubmit={handleStepChange("step3", !doesParentExist)}
          refetchData={refetchData}
        />
      )
    },
    {
      name: "Choose tests",
      number: 3,
      pendoId: "auto-edit-test-suite-step3",
      children: (
        <ChooseTestsForm
          hasChildren={props.hasChildren}
          smartThresholdSettings={props.smartThresholdSettings}
          setSmartThresholdSettings={props.setSmartThresholdSettings}
          testSuite={testSuite}
          onSuccess={handleStepChange("step4", true)}
          useTestSuiteTestsAndExtractionsQueryObject={useTestSuiteTestsAndExtractionsQueryObject}
          reportTemplateObject={props.useReportTemplateObject}
        />
      )
    },
    {
      name: "Set notifications",
      number: 4,
      pendoId: "auto-edit-test-suite-step4",
      children: <SetAlertsForm testSuite={testSuite} onSubmit={handleStepChange("step5", true)} />
    },
    {
      name: "Run test suite",
      number: 5,
      pendoId: "auto-edit-test-suite-step5",
      children: <ConnectCiCdTool testSuite={testSuite} refetchTestSuite={props.refetchTestSuite} />
    }
  ];

  return (
    <>
      {stepsConfigs.map(step => {
        const isStep2or3 = step.number === 2 || step.number === 3;
        const isParentStep = isStep2or3 && props.hasChildren;
        const isDisabled = isStep2or3 && !!testSuite.parent;
        const isExpanded = isDisabled ? false : props.expandedStep === `step${step.number}`;

        return (
          <CustomStep
            disabled={isDisabled}
            key={step.number}
            isExpanded={isExpanded}
            handleChange={handleStepChange(`step${step.number}`)}
            stepHeader={step.name}
            stepNumber={step.number}
            id={`step${step.number}`}
            testId={`step${step.number}-collapse-container`}
            isParentStep={isParentStep}
            pendoId={step.pendoId}
          >
            {step.children}
          </CustomStep>
        );
      })}
    </>
  );
}
