import React, { useContext } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useUserQuery } from "../graphql/userQuery/useUserQuery";
import { getDisplayingAccountId } from "../../utils/getDisplayingAccountId/getDisplayingAccountId";
import { CircularProgress } from "@material-ui/core";
import { permissionsContext } from "../../contexts/Permissions/Permissions.context";
import { extractPermissionsFromGetUser } from "../../utils/permissions/permissions";
import { Routes } from "../../routes/routes";

export const PostLoginLayer = withRouter(function PostLoginLayer() {
  const { data: userData } = useUserQuery();
  const { setCurrentPermissions } = useContext(permissionsContext);

  if (userData) {
    const accountId = getDisplayingAccountId(userData, userData.me.accounts.nodes[0].id);
    const permissions = extractPermissionsFromGetUser(userData, accountId);
    setCurrentPermissions(permissions);
    return <Redirect to={Routes.Dashboard.getUrl({ accountId })} />;
  } else {
    return <CircularProgress data-testid="post-login-loader" color="secondary" />;
  }
});
