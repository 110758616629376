import { BuildStatus } from "../../../generated-graphql-interfaces";
import { BuildInfo } from "../Dashboard";

function sortByDescendingDate(dateStringA: string, dateStringB: string) {
  return new Date(dateStringB).getTime() - new Date(dateStringA).getTime();
}

/* eslint-disable fp/no-mutating-methods  */
export function sortBuildsByStatusAndDate(builds: BuildInfo[]) {
  const inProgressBuilds = builds
    .filter(build => build.status === BuildStatus.Queued || build.status === BuildStatus.Running)
    .sort((a, b) => sortByDescendingDate(a.createdAt, b.createdAt));
  const completedBuilds = builds
    .filter(build => build.status === BuildStatus.Finished)
    .sort((a, b) => sortByDescendingDate(a.finishedAt, b.finishedAt));

  return [...inProgressBuilds, ...completedBuilds];
}
/* eslint-enable fp/no-mutating-methods  */
