import flat from "array.prototype.flat";
import "normalize.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as dotenv from "dotenv";

dotenv.config();
flat.shim();

ReactDOM.render(<App />, document.getElementById("root"));
