import React from "react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Button, Box, makeStyles, createStyles, Theme } from "@material-ui/core";
import * as yup from "yup";
import { ValidationErrors } from "../../validation/ValidationErrors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ForgottenPassword } from "./components/ForgottenPassword";

export interface LoginFormProps {
  onSubmit: (username: string, password: string) => void;
  submitting: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "black",
      fontSize: "13px",
      lineHeight: "16px",
      textDecoration: "none"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    wrapper: {
      position: "relative"
    }
  })
);

const loginFormValidation = yup.object().shape({
  username: yup.string().required(ValidationErrors.UsernameAndPassword),
  password: yup.string().required(ValidationErrors.UsernameAndPassword)
});

export function LoginForm(props: LoginFormProps): JSX.Element {
  const classes = useStyles();
  return (
    <Box>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={loginFormValidation}
        onSubmit={(values, actions) => {
          props.onSubmit(values.username, values.password);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <Box component="div" mb={3}>
            <Field
              name="username"
              type="text"
              label="Username"
              id="username"
              autoComplete="off"
              component={TextField}
              fullWidth={true}
              variant="filled"
              data-cy="input-login-username"
            />
          </Box>
          <Box component="div" mb={5}>
            <Field
              name="password"
              type="password"
              id="password"
              label="Password"
              autoComplete="off"
              variant="filled"
              fullWidth={true}
              component={TextField}
              data-cy="input-login-password"
            />
          </Box>
          <Box component="div" mb={2} display="flex" justifyContent="center" className={classes.wrapper}>
            <Button
              id="login-submit"
              data-testid="login-submit"
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth={true}
              data-cy="login-submit"
              disabled={props.submitting}
            >
              Login
            </Button>
            {props.submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Box>
        </Form>
      </Formik>
      <Box component="div" display="flex" justifyContent="flex-end">
        <ForgottenPassword />
      </Box>
    </Box>
  );
}
