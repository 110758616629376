import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2, 2, 2, 6),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  closeButton: {
    color: theme.palette.navy[500],
    marginLeft: theme.spacing(1)
  },
  dialogContent: {
    padding: theme.spacing(6, 6, 10, 6)
  },
  paper: {
    maxWidth: 680
  }
}));

interface TemplateDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  title: string;
  message: string;
  component?: JSX.Element;
  skipMessage?: boolean;
}

export default function TemplateDialog(props: TemplateDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      fullWidth={false}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      data-testid="template-dialog"
      classes={{ paper: classes.paper }}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6" data-testid="template-dialog-title">
          {props.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
          data-testid="template-dialog-close"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        {props.component}
        {!props.skipMessage && (
          <Box mt={2}>
            <Alert severity="info" data-testid="template-dialog-message">
              {props.message}
            </Alert>
          </Box>
        )}
      </MuiDialogContent>
    </Dialog>
  );
}
