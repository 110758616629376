import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { updateCrawlSettings, updateCrawlSettingsVariables } from "../../../../../generated-graphql-interfaces";

export function useUpdateCrawlSettingsMutation() {
  return useMutation<updateCrawlSettings, updateCrawlSettingsVariables>(
    gql`
      mutation updateCrawlSettings(
        $testSuiteId: ObjectID!
        $crawlTypes: [TestSuiteCrawlType!]!
        $useRenderer: Boolean!
        $crawlRate: Float!
        $limitPagesMax: Int!
        $urlsIncluded: [String!]
        $urlsExcluded: [String!]
        $rendererBlockAds: Boolean!
        $rendererBlockAnalytics: Boolean!
        $rendererJsString: String!
        $rendererBlockCustom: [String!]
        $rendererJsUrls: [String!]
        $useRobotsOverwrite: Boolean!
        $robotsOverwrite: String!
        $emptyPageThreshold: Int
        $highLogSummaryRequests: Int!
        $lowLogSummaryRequests: Int!
        $maxContentSize: Int!
        $maxDescriptionLength: Int
        $maxExternalLinks: Int
        $maxHtmlSize: Int
        $maxLinks: Int
        $maxLoadTime: Float
        $maxRedirections: Int!
        $maxTitleWidth: Int!
        $maxUrlLength: Int
        $duplicatePrecision: Int!
        $minDescriptionLength: Int!
        $minTitleLength: Int!
        $thinPageThreshold: Int
        $customExtractions: [CustomExtractionSettingInput!]
        $startUrls: [String!]
      ) {
        updateTestSuite(
          input: {
            testSuiteId: $testSuiteId
            crawlTypes: $crawlTypes
            useRenderer: $useRenderer
            crawlRate: $crawlRate
            limitPagesMax: $limitPagesMax
            urlsIncluded: $urlsIncluded
            urlsExcluded: $urlsExcluded
            rendererBlockAds: $rendererBlockAds
            rendererBlockAnalytics: $rendererBlockAnalytics
            rendererJsString: $rendererJsString
            rendererBlockCustom: $rendererBlockCustom
            rendererJsUrls: $rendererJsUrls
            useRobotsOverwrite: $useRobotsOverwrite
            robotsOverwrite: $robotsOverwrite
            emptyPageThreshold: $emptyPageThreshold
            highLogSummaryRequests: $highLogSummaryRequests
            lowLogSummaryRequests: $lowLogSummaryRequests
            maxContentSize: $maxContentSize
            maxDescriptionLength: $maxDescriptionLength
            maxExternalLinks: $maxExternalLinks
            maxHtmlSize: $maxHtmlSize
            maxLinks: $maxLinks
            maxLoadTime: $maxLoadTime
            maxRedirections: $maxRedirections
            maxTitleWidth: $maxTitleWidth
            maxUrlLength: $maxUrlLength
            duplicatePrecision: $duplicatePrecision
            minDescriptionLength: $minDescriptionLength
            minTitleLength: $minTitleLength
            thinPageThreshold: $thinPageThreshold
            customExtractions: $customExtractions
            startUrls: $startUrls
          }
        ) {
          testSuite {
            id
          }
        }
      }
    `
  );
}
