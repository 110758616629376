import gql from "graphql-tag";

export const deleteTestsuiteMutation = gql`
  mutation deleteTestSuite($testSuiteId: ObjectID!) {
    deleteTestSuite(input: { testSuiteId: $testSuiteId }) {
      testSuite {
        id
      }
    }
  }
`;
