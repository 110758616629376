import React, { createContext } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { TestSuiteReport } from "../pages/TestSuiteReport/TestSuiteReport";
import { InitialiseTestSuite } from "../pages/TestSuite/InitialiseTestSuite";
import { BuildTestList } from "../pages/BuildTestList/BuildTestList";
import { PaginatedTestSuiteList } from "../pages/TestSuiteList/PaginatedTestSuiteList";
import { PostLoginLayer } from "../_common/components/PostLoginLayer/PostLoginLayer";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { WhyAutomator } from "../pages/WhyAutomator/WhyAutomator";
import { GettingStarted } from "../pages/GettingStarted/GettingStarted";
import { Faqs } from "../pages/Faqs/Faqs";
import { ConnectedApps } from "../pages/ConnectedApps/ConnectedApps";
import { NotFound } from "../pages/NotFound/NotFound";
import { Routes } from "../_common/routes/routes";
import { FrontendScheduler } from "../pages/FrontendScheduler/FrontendScheduler";
import {
  ComponentPermissionCheck,
  ComponentPermissionsOption
} from "../_common/components/ComponentPermissionCheck/ComponentPermissionCheck";
import { AuthRoute } from "../_common/components/AuthRoute/AuthRoute";

export interface AppRoutesProps {
  logoutHandler: () => void;
}

type AppRoutesComponentProps = RouteComponentProps<{}> & AppRoutesProps;

export interface LogoutContextType {
  logoutHandler: () => void;
}

export const LogoutContext = createContext<LogoutContextType>({
  logoutHandler: () => null
});

export const AppRoutesPrivate = withRouter((props: AppRoutesComponentProps) => {
  const contextValue = {
    logoutHandler: props.logoutHandler
  };

  return (
    <div data-testid="app-routes-private">
      <LogoutContext.Provider value={contextValue}>
        <Switch>
          <AuthRoute exact path={Routes.Root.ROUTE} render={() => <Redirect to={Routes.LoginCallback.ROUTE} />} />
          <AuthRoute exact path={Routes.LoginCallback.ROUTE} component={PostLoginLayer} />
          <AuthRoute exact path={Routes.WhyAutomator.ROUTE} component={WhyAutomator} />
          <AuthRoute exact path={Routes.GettingStarted.ROUTE} component={GettingStarted} />
          <AuthRoute exact path={Routes.Faqs.ROUTE} component={Faqs} />
          <AuthRoute exact path={Routes.Dashboard.ROUTE} component={Dashboard} />
          <AuthRoute exact path={Routes.TestSuites.ROUTE} component={PaginatedTestSuiteList} />

          <AuthRoute exact path={Routes.Builds.ROUTE} component={BuildTestList} includePageContainer={false} />
          <AuthRoute exact path={Routes.TestResults.ROUTE} component={TestSuiteReport} includePageContainer={false} />
          <AuthRoute
            exact
            path={Routes.CreateTestSuite.ROUTE}
            component={InitialiseTestSuite}
            includePageContainer={false}
          />
          <AuthRoute
            exact
            path={Routes.EditTestSuite.ROUTE}
            component={InitialiseTestSuite}
            includePageContainer={false}
          />

          <AuthRoute
            exact
            path={Routes.ConnectedApps.ROUTE}
            component={ComponentPermissionCheck(ComponentPermissionsOption.CONNECTED_APPS, ConnectedApps)}
          />
          <AuthRoute
            exact
            path={Routes.JiraIntegration.ROUTE}
            component={ComponentPermissionCheck(ComponentPermissionsOption.CONNECTED_APPS, ConnectedApps)}
          />
          <AuthRoute
            exact
            path={Routes.FrontendScheduler.ROUTE}
            component={ComponentPermissionCheck(ComponentPermissionsOption.FRONTEND_SCHEDULER, FrontendScheduler)}
          />
          <AuthRoute path={Routes.Any.ROUTE} component={NotFound} />
        </Switch>
      </LogoutContext.Provider>
    </div>
  );
});
