import gql from "graphql-tag";

export const getAutomatorAccountStatisticsQuery = gql`
  query getAutomatorAccountStatistics($accountId: ObjectID!) {
    getAutomatorAccountStatistics(accountId: $accountId) {
      failedTestPercentage
      passedTestPercentage
      warnedTestPercentage
      testSuitesCount
      buildsCount
      testsPerformedCount
      averageUrlsCrawledPerDay
    }
  }
`;
