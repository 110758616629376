import React, { useContext, useState } from "react";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { TemplateSettingsType } from "./NoTemplateSettingsView";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { defaultPopOverId } from "../../../../../_common/constants/popover";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { permissionsContext } from "../../../../../_common/contexts/Permissions/Permissions.context";
import { GreyButton } from "../../../../../_common/components/GreyButton/GreyButton";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.warning.main
  },
  button: {
    padding: "6px 15px"
  }
}));

interface TemplateSettingsMenuProps {
  openTemplateDialog: () => void;
  isAutomaticThresholdEnabled: boolean;
  updateTemplateSettingsType: (templateType: TemplateSettingsType) => void;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary"
  },
  {
    label: "Continue",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "info"
  }
];

//eslint-disable-next-line max-lines-per-function
export function TemplateSettingsMenu(props: TemplateSettingsMenuProps) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLLIElement | null>(null);
  const isPopoverOpen = Boolean(popoverAnchorElement);
  const { permissions } = useContext(permissionsContext);
  const popoverId = isPopoverOpen ? defaultPopOverId : undefined;

  const automaticThresholdWarningText = props.isAutomaticThresholdEnabled
    ? "If you apply or use as global template, smart threshold settings will be disabled for all your tests in this test suite."
    : "If you apply or use as global template, you won’t be able to use smart threshold settings for your tests in this test suite.";

  function openTemplateMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function closeTemplateMenu() {
    setMenuAnchorEl(null);
  }

  function handleAutomaticThresholdAction(id: string) {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      closeTemplateMenu();
      props.openTemplateDialog();
    }
  }

  function openAutomaticThresholdWarning(
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    templateType: TemplateSettingsType
  ) {
    setPopoverAnchorElement(event.currentTarget);
    props.updateTemplateSettingsType(templateType);
  }

  function menuItemClicked(event: React.MouseEvent<HTMLLIElement, MouseEvent>, templateType: TemplateSettingsType) {
    if (permissions.automaticThresholdAccess) {
      openAutomaticThresholdWarning(event, templateType);
    } else {
      props.updateTemplateSettingsType(templateType);
      props.openTemplateDialog();
    }
  }

  return (
    <>
      <GreyButton
        className={classes.button}
        aria-controls="template-settings-menu"
        aria-haspopup="true"
        onClick={openTemplateMenu}
        startIcon={<LinkRoundedIcon />}
        data-testid="link-test-suite-button"
        data-pendo="auto-test-suite-edit-template-settings-link"
      >
        Link test suite
      </GreyButton>
      <Menu
        id="template-settings-menu"
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeTemplateMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={event => menuItemClicked(event, TemplateSettingsType.AddChildren)}
          data-testid="use-as-global-template"
          data-pendo="auto-test-suite-edit-template-settings-link-as-global-template"
        >
          Use as global template
        </MenuItem>
        <MenuItem
          onClick={event => menuItemClicked(event, TemplateSettingsType.AddParent)}
          data-testid="apply-global-template"
          data-pendo="auto-test-suite-edit-template-settings-link-apply-global-template"
        >
          Apply global template
        </MenuItem>
      </Menu>
      <PopoverDialog
        anchorElement={popoverAnchorElement}
        handleAction={handleAutomaticThresholdAction}
        open={isPopoverOpen}
        title="Smart threshold settings will be disabled"
        text={automaticThresholdWarningText}
        buttons={popoverButtons}
        id={popoverId}
        icon={<WarningRoundedIcon className={classes.icon} />}
      />
    </>
  );
}
