import { useQuery } from "@apollo/react-hooks";
import {
  getTestSuiteOptionsForSchedule,
  getTestSuiteOptionsForScheduleVariables
} from "../../../../../generated-graphql-interfaces";
import { useGetTestSuitesQueryGql } from "./getTestSuitesQueryGql";

export function useGetTestSuitesQuery(accountId: string, searchText: string | null) {
  return useQuery<getTestSuiteOptionsForSchedule, getTestSuiteOptionsForScheduleVariables>(useGetTestSuitesQueryGql, {
    variables: { accountId, searchText }
  });
}
