import { Routes } from "../../../_common/routes/routes";
import { BreadcrumbPaths } from "../../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";

export function getBreadcrumbContent(accountId: string) {
  const testSuitesLink = {
    text: BreadcrumbPaths.MyTestSuites,
    link: Routes.TestSuites.getUrl({ accountId }),
    testDataId: "bcb-my-test-suites"
  };
  const editTestSuitesLink = { text: BreadcrumbPaths.EditTestSuite, testDataId: "bcb-edit-test-suite" };

  return [testSuitesLink, editTestSuitesLink];
}
