import { useQuery } from "@apollo/react-hooks";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { getBuildsStatuses, getBuildsStatusesVariables } from "../../../generated-graphql-interfaces";

export const getBuildsStatusesGql = gql`
  query getBuildsStatuses($testSuiteId: ObjectID!) {
    node(id: $testSuiteId) {
      ... on TestSuite {
        id
        builds(
          first: 100
          orderBy: [{ field: updatedAt, direction: DESC }]
          filter: { status: { in: ["Running", "Queued"] } }
        ) {
          nodes {
            id
            status
            triggerType
          }
        }
      }
    }
  }
`;

export function useGetBuildsStatusesQuery(testSuiteId: string) {
  const [internalData, setInternalData] = useState<getBuildsStatuses | undefined>();
  const [internalError, setInternalError] = useState<ApolloError | undefined>();

  const { data: buildsStatuses, refetch, error: errorGettingData, loading } = useQuery<
    getBuildsStatuses,
    getBuildsStatusesVariables
  >(getBuildsStatusesGql, {
    variables: { testSuiteId },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    setInternalData(buildsStatuses);
    setInternalError(undefined);
  }, [buildsStatuses]);

  useEffect(() => {
    setInternalError(errorGettingData);
    setInternalData(undefined);
  }, [errorGettingData]);

  const refetchData = (): Promise<ApolloQueryResult<getBuildsStatuses>> => {
    return refetch().then(
      data => {
        setInternalData(data.data);
        setInternalError(undefined);
        return data;
      },
      e => {
        setInternalData(undefined);
        setInternalError(e);
        return e;
      }
    );
  };

  return { data: internalData, refetch: refetchData, error: internalError, loading };
}
