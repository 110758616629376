import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function FaqIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0.476562 9.64929C0.476562 5.59589 4.36357 2.53516 8.85438 2.53516C12.4281 2.53516 15.5967 4.45938 16.7609 7.28438C16.8132 7.41108 16.7223 7.54852 16.5857 7.55982C16.2054 7.59128 15.8276 7.64529 15.4554 7.72085C15.3603 7.74014 15.2645 7.69169 15.222 7.60448C14.2376 5.5837 11.8197 4.06738 8.85438 4.06738C4.93754 4.06738 2.00879 6.69083 2.00879 9.64929C2.00879 11.2475 2.8402 12.7234 4.23818 13.7669C4.43192 13.9115 4.54603 14.1391 4.54603 14.3808V15.3418L6.12692 14.9023C6.2646 14.8641 6.41025 14.865 6.54739 14.9051C7.17122 15.0879 7.83808 15.1989 8.53355 15.2251C8.64391 15.2293 8.7328 15.318 8.73758 15.4284C8.75653 15.8655 8.81506 16.2877 8.90931 16.6937C8.91754 16.7291 8.89082 16.7632 8.85438 16.7633C7.9769 16.7633 7.12911 16.6489 6.33148 16.4357L3.98511 17.0881C3.75442 17.1522 3.50701 17.1048 3.31638 16.9599C3.12574 16.815 3.0138 16.5894 3.0138 16.3499V14.7531C1.4715 13.4802 0.476562 11.6791 0.476562 9.64929Z" />
      <path d="M7.22311 7.64989C7.22311 8.25043 6.73627 8.73727 6.13573 8.73727C5.53518 8.73727 5.04834 8.25043 5.04834 7.64989C5.04834 7.04934 5.53518 6.5625 6.13573 6.5625C6.73627 6.5625 7.22311 7.04934 7.22311 7.64989Z" />
      <path d="M11.5727 8.73727C12.1733 8.73727 12.6601 8.25043 12.6601 7.64989C12.6601 7.04934 12.1733 6.5625 11.5727 6.5625C10.9722 6.5625 10.4854 7.04934 10.4854 7.64989C10.4854 8.25043 10.9722 8.73727 11.5727 8.73727Z" />
      <path d="M15.0798 14.7193C15.6054 14.7193 16.0313 14.2934 16.0313 13.7679C16.0313 13.2424 15.6054 12.8164 15.0798 12.8164C14.5544 12.8164 14.1284 13.2424 14.1284 13.7679C14.1284 14.2934 14.5544 14.7193 15.0798 14.7193Z" />
      <path d="M20.6524 13.7679C20.6524 14.2934 20.2265 14.7193 19.701 14.7193C19.1755 14.7193 18.7495 14.2934 18.7495 13.7679C18.7495 13.2424 19.1755 12.8164 19.701 12.8164C20.2265 12.8164 20.6524 13.2424 20.6524 13.7679Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3676 9.28516C21.1577 9.28516 24.4767 11.8722 24.4767 15.3413C24.4767 17.0563 23.6507 18.3492 22.3672 19.4281V20.6989C22.3672 20.9383 22.2553 21.1641 22.0647 21.3089C21.874 21.4538 21.6266 21.5012 21.3959 21.437L19.474 20.9028C18.8084 21.0776 18.1023 21.1711 17.3724 21.1711C13.5822 21.1711 10.2632 18.5841 10.2632 15.115C10.2632 11.646 13.5774 9.28516 17.3676 9.28516ZM22.9494 15.1152C22.9494 12.741 20.5887 10.5913 17.3724 10.5913C14.1562 10.5913 11.7954 12.741 11.7954 15.1152C11.7954 17.4893 14.1562 19.639 17.3724 19.639C18.0366 19.639 18.6715 19.5447 19.259 19.3726C19.3962 19.3324 19.5419 19.3315 19.6796 19.3697L20.8351 19.6909V19.0582C20.8351 18.8164 20.9492 18.5888 21.143 18.4442C22.2814 17.5944 22.9494 16.4001 22.9494 15.1152Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientFaqIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="25" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.77389 2.63477C4.28411 2.63477 0.476562 5.68695 0.476562 9.6412C0.476562 11.6654 1.48876 13.4677 3.06843 14.7308V16.4861C3.06843 16.6492 3.14466 16.8028 3.27449 16.9015C3.40432 17.0001 3.5728 17.0325 3.72991 16.9887L6.19583 16.3033C7.00836 16.527 7.87508 16.6475 8.77389 16.6475C8.82946 16.6475 8.88491 16.6471 8.94022 16.6462C9.06965 16.6441 9.16275 16.5234 9.14029 16.3958C9.07377 16.0183 9.03898 15.6276 9.03898 15.2252C9.03898 11.1577 12.5919 8.30024 16.5495 7.91036C16.6828 7.89723 16.7733 7.76604 16.729 7.63961C15.6985 4.70013 12.4765 2.63477 8.77389 2.63477ZM7.10772 6.76646C7.10772 7.37993 6.6104 7.87725 5.99693 7.87725C5.38345 7.87725 4.88612 7.37993 4.88612 6.76646C4.88612 6.15298 5.38345 5.65566 5.99693 5.65566C6.6104 5.65566 7.10772 6.15298 7.10772 6.76646ZM11.5508 7.87725C12.1643 7.87725 12.6616 7.37993 12.6616 6.76645C12.6616 6.15298 12.1643 5.65566 11.5508 5.65566C10.9374 5.65566 10.44 6.15298 10.44 6.76645C10.44 7.37993 10.9374 7.87725 11.5508 7.87725Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4751 9.29883C21.2491 9.29883 24.4765 11.8672 24.4765 15.2244C24.4765 16.9279 23.6315 18.4424 22.3166 19.5074V20.9285C22.3166 21.0916 22.2403 21.2453 22.1105 21.3439C21.9807 21.4426 21.8122 21.4749 21.6551 21.4313L19.6233 20.8664C18.9453 21.0508 18.2232 21.15 17.4751 21.15C13.701 21.15 10.4736 18.5817 10.4736 15.2244C10.4736 11.8672 13.701 9.29883 17.4751 9.29883ZM15.1332 14.2635C15.67 14.2635 16.1052 13.8283 16.1052 13.2915C16.1052 12.7547 15.67 12.3196 15.1332 12.3196C14.5964 12.3196 14.1613 12.7547 14.1613 13.2915C14.1613 13.8283 14.5964 14.2635 15.1332 14.2635ZM20.826 13.2915C20.826 13.8283 20.3909 14.2635 19.8541 14.2635C19.3173 14.2635 18.8821 13.8283 18.8821 13.2915C18.8821 12.7547 19.3173 12.3196 19.8541 12.3196C20.3909 12.3196 20.826 12.7547 20.826 13.2915Z"
          fill="url(#paint1_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.476562" y="0.0429688" width="24" height="24" fill="url(#paint2_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1.77845"
          y1="14.2514"
          x2="14.1748"
          y2="15.4206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="11.5945"
          y1="19.1199"
          x2="22.264"
          y2="20.1447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="2.39771"
          y1="19.4402"
          x2="20.726"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
