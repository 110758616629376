import { ButtonClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";
import { colorGrey, colorNavy, colorPurple } from "../palette";

export const muiButton: Partial<
  Record<ButtonClassKey, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
> = {
  root: {
    fontSize: 14,
    borderRadius: 50,
    height: 40,
    lineHeight: 1,
    minWidth: "140px",
    fontWeight: 500,
    paddingLeft: 18,
    paddingRight: 18,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  },
  outlined: {
    color: colorNavy[500],
    borderColor: colorNavy[500],
    "&:hover": {
      backgroundColor: colorGrey[100]
    }
  },
  outlinedPrimary: {
    borderColor: colorPurple[500],
    "&:hover": {
      color: colorPurple[700],
      borderColor: colorPurple[500],
      backgroundColor: "transparent"
    }
  },
  contained: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  }
};
