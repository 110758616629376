import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Alert } from "@material-ui/lab";
import { getTimeDescription } from "../utils/schedulerTime";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: "2px",
    paddingTop: "2px",
    marginRight: theme.spacing(2)
  }
}));

interface SchedulerDetailItemScheduledInfoProps {
  testSuiteId: string;
  startTime: Date | null;
}

export function SchedulerDetailItemScheduledInfo(props: SchedulerDetailItemScheduledInfoProps) {
  const accountId = useAccountRouteMatch();
  const classes = useStyles();
  const editTestSuiteBuildURL = Routes.Builds.getUrl({
    accountId: accountId,
    testSuiteId: props.testSuiteId
  });

  return (
    <Box data-testid="scheduler-detail-item-scheduled-info">
      <Alert
        severity={props.startTime ? "info" : "warning"}
        icon={props.startTime ? undefined : <WarningRoundedIcon fontSize="inherit" />}
        className={classes.root}
      >
        {props.startTime ? (
          <>
            Test suite run has been scheduled to start on {getTimeDescription(props.startTime)}. For results please see{" "}
            <Link to={editTestSuiteBuildURL} data-testid="scheduler-detail-item-scheduled-info-link">
              test suite runs page
            </Link>
            .
          </>
        ) : (
          <span data-testid="scheduler-detail-item-not-scheduled-message">
            This test suite cannot run because it is set to run in the past with option ‘Does not repeat’. If you want
            to run this schedule, please edit the date and time.
          </span>
        )}
      </Alert>
    </Box>
  );
}
