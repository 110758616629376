import React from "react";
import { UrlListUploader } from "./UrlListUploader/UrlListUploader";
import { Field, FormikErrors, FormikValues, useFormikContext } from "formik";
import { FormControlLabel, InputLabel, Box, makeStyles, FormGroup, Typography } from "@material-ui/core";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { Checkbox } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import { generateTooltips } from "./UrlSourceUtils";

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.text.primary,
    paddingTop: "2px",
    marginRight: theme.spacing(1)
  },
  checkbox: {
    display: "inline-flex"
  },
  labelWithTooltip: {
    display: "inline-flex",
    "& svg": {
      marginLeft: theme.spacing(1)
    }
  },
  infoblock: {
    padding: theme.spacing(2, 0, 1, 0)
  }
}));

export interface UrlSourceProps {
  coreUIUrl: string;
  testSuiteId: string;
}

// eslint-disable-next-line max-lines-per-function, complexity
export function UrlSource(props: UrlSourceProps) {
  const { values, errors } = useFormikContext();
  const formikValues = values as FormikValues;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const formikErrors = errors as FormikErrors<{ crawlTypes: object }>;
  const classes = useStyles();

  const tooltips = generateTooltips(props.coreUIUrl);
  return (
    <>
      <Box component="div" display="flex">
        <InputLabel htmlFor="crawl-types" className={classes.label}>
          URL Source
        </InputLabel>
        <DeepcrawlInfoTooltip
          maxWidth={400}
          body={crawlSettingsTooltips.urlSource}
          data-pendo="auto-tooltip-url-source"
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          data-pendo="auto-test-suite-edit-choose-crawl-settings-url-source-web"
          label={
            <span className={classes.labelWithTooltip} data-testid="web-crawl-type-web-label">
              Web (Crawl website itself){" "}
              <DeepcrawlInfoTooltip
                body={tooltips.webTooltip}
                testId="web-crawl-type-web-tooltip"
                maxWidth={350}
                data-pendo="auto-tooltip-web-crawl-type"
              />
            </span>
          }
          className={classes.checkbox}
          control={
            <Field
              name="crawlTypes.web"
              id="crawl-types.web"
              variant="filled"
              data-cy="web-crawl-type-web"
              data-testid="web-crawl-type-web"
              type="checkbox"
              value={formikValues.crawlTypes.web}
              component={Checkbox}
            />
          }
        />
        <FormControlLabel
          data-pendo="auto-test-suite-edit-choose-crawl-settings-url-source-list"
          label={
            <span className={classes.labelWithTooltip}>
              List (Upload a list of URLs){" "}
              <DeepcrawlInfoTooltip
                body={tooltips.listTooltip}
                testId="web-crawl-type-list-tooltip"
                maxWidth={350}
                data-pendo="auto-tooltip-upload-url-list"
              />
            </span>
          }
          className={classes.checkbox}
          control={
            <Field
              name="crawlTypes.list"
              id="crawl-types.list"
              variant="filled"
              data-cy="web-crawl-type-list"
              data-testid="web-crawl-type-list"
              type="checkbox"
              value={formikValues.crawlTypes.list}
              component={Checkbox}
            />
          }
        />
        {formikValues.crawlTypes.list && (
          <Box my={4}>
            <UrlListUploader testSuiteId={props.testSuiteId} />
          </Box>
        )}

        {(formikValues.crawlTypes.sitemap ||
          formikValues.crawlTypes.google_analytics ||
          formikValues.crawlTypes.backlinks ||
          formikValues.crawlTypes.google_search_console ||
          formikValues.crawlTypes.log_summary) && (
          <Typography variant="body1" className={classes.infoblock}>
            Additional crawl types set in DeepCrawl project settings. You can update settings{" "}
            {
              <a
                href={props.coreUIUrl + "/crawls/0/edit?step=2"}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="tooltip-link"
              >
                here
              </a>
            }
            .
          </Typography>
        )}

        {formikValues.crawlTypes.sitemap && (
          <FormControlLabel
            data-pendo="auto-test-suite-edit-choose-crawl-settings-javascript-rendering-not-required"
            className={classes.checkbox}
            label={
              <span className={classes.labelWithTooltip}>
                Sitemaps{" "}
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-sitemaps"
                  body={tooltips.sitemapsTooltip}
                  testId="web-crawl-type-sitemap-tooltip"
                  maxWidth={350}
                />
              </span>
            }
            control={
              <Field
                disabled
                name="crawlTypes.sitemap"
                id="crawl-types.sitemap"
                variant="filled"
                data-cy="web-crawl-type-sitemap"
                data-testid="web-crawl-type-sitemap"
                type="checkbox"
                value={formikValues.crawlTypes.sitemap}
                component={Checkbox}
              />
            }
          />
        )}

        {formikValues.crawlTypes.google_analytics && (
          <FormControlLabel
            data-pendo="auto-test-suite-edit-choose-crawl-settings-javascript-rendering-enable"
            className={classes.checkbox}
            label={
              <span className={classes.labelWithTooltip}>
                Analytics{" "}
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-google-analytics"
                  body={tooltips.analyticsTooltip}
                  testId="web-crawl-type-google-analytics-tooltip"
                  maxWidth={350}
                />
              </span>
            }
            control={
              <Field
                disabled
                name="crawlTypes.google_analytics"
                id="crawl-types.google_analytics"
                variant="filled"
                data-cy="web-crawl-type-google-analytics"
                data-testid="web-crawl-type-google-analytics"
                type="checkbox"
                value={formikValues.crawlTypes.google_analytics}
                component={Checkbox}
              />
            }
          />
        )}

        {formikValues.crawlTypes.backlinks && (
          <FormControlLabel
            className={classes.checkbox}
            label={
              <span className={classes.labelWithTooltip}>
                Backlinks{" "}
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-backlinks"
                  body={tooltips.backlinksTooltip}
                  testId="web-crawl-type-backlinks-tooltip"
                  maxWidth={350}
                />
              </span>
            }
            control={
              <Field
                disabled
                name="crawlTypes.backlinks"
                id="crawl-types.backlinks"
                variant="filled"
                data-cy="web-crawl-type-backlinks"
                data-testid="web-crawl-type-backlinks"
                type="checkbox"
                value={formikValues.crawlTypes.backlinks}
                component={Checkbox}
              />
            }
          />
        )}

        {formikValues.crawlTypes.google_search_console && (
          <FormControlLabel
            className={classes.checkbox}
            label={
              <span className={classes.labelWithTooltip}>
                Google Search Console{" "}
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-google-search-console"
                  body={tooltips.gscTooltip}
                  testId="web-crawl-type-google-search-console-tooltip"
                  maxWidth={350}
                />
              </span>
            }
            control={
              <Field
                disabled
                name="crawlTypes.google_search_console"
                id="crawl-types.google_search_console"
                variant="filled"
                data-cy="web-crawl-type-google-search-console"
                data-testid="web-crawl-type-google-search-console"
                type="checkbox"
                value={formikValues.crawlTypes.google_search_console}
                component={Checkbox}
              />
            }
          />
        )}

        {formikValues.crawlTypes.log_summary && (
          <FormControlLabel
            className={classes.checkbox}
            label={
              <span className={classes.labelWithTooltip}>
                Log Summary{" "}
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-log-summary"
                  body={tooltips.logSummaryTooltip}
                  testId="web-crawl-type-log-summary-tooltip"
                  maxWidth={350}
                />
              </span>
            }
            control={
              <Field
                disabled
                name="crawlTypes.log_summary"
                id="crawl-types.log_summary"
                variant="filled"
                data-cy="web-crawl-type-log-summary"
                data-testid="web-crawl-type-log-summary"
                type="checkbox"
                value={formikValues.crawlTypes.log_summary}
                component={Checkbox}
              />
            }
          />
        )}
      </FormGroup>
      {formikErrors.crawlTypes && <Alert severity="error">{formikErrors.crawlTypes}</Alert>}
    </>
  );
}
