import {
  ColorPartial,
  PaletteOptions,
  SimplePaletteColorOptions,
  TypeText
} from "@material-ui/core/styles/createPalette";

export const colorPurple: ColorPartial = {
  50: "#EBE8FE",
  100: "#CDC5FD",
  200: "#8B79FB",
  300: "#593FF9",
  400: "#4831F7",
  500: "#2E1BF5",
  600: "#1208EE",
  700: "#0301D6",
  800: "#0101BD",
  900: "#000099"
};

export const colorNavy: ColorPartial = {
  50: "#E9EAEB",
  100: "#B6B8BD",
  200: "#858892",
  300: "#545866",
  400: "#2F3545",
  500: "#0A1124",
  600: "#090F20",
  700: "#070C1B",
  800: "#020409",
  900: "#010103"
};

export const colorBlue: ColorPartial = {
  50: "#E6EEF2",
  100: "#C0D5DF",
  200: "#96BACA",
  300: "#6C9EB5",
  400: "#4D89A5",
  500: "#2D7495",
  600: "#286C8D",
  700: "#226182",
  800: "#1C5778",
  900: "#114467"
};

export const colorRed: ColorPartial = {
  50: "#F9EEEE",
  100: "#F5E4E4",
  200: "#E9C4C4",
  300: "#DB9E9E",
  400: "#D48A8A",
  500: "#B63A3A",
  600: "#C76D6D",
  700: "#C06262",
  800: "#9E2525",
  900: "#8E1818"
};

export const colorGrey: ColorPartial = {
  50: "#F2F3F3",
  100: "#EFF0F1",
  200: "#E8E9EB",
  300: "#DEE0E2",
  400: "#C8CBCF",
  500: "#B3B8BC",
  600: "#ACB1B6",
  700: "#A3A8AD",
  800: "#9AA0A5",
  900: "#8B9197"
};

export const colorPrimary: SimplePaletteColorOptions = {
  main: "#0A1124",
  light: "#fff",
  dark: "#010103",
  contrastText: "#fff"
};

export const colorSecondary: SimplePaletteColorOptions = {
  main: "#2E1BF5",
  dark: "#0301D6",
  light: "#593FF9"
};

export const colorError: SimplePaletteColorOptions = {
  main: "#b63a3a",
  light: "#CB7E7C"
};

export const colorWarning: SimplePaletteColorOptions = {
  main: "#DDA200",
  light: "#FDF2D3",
  dark: "#C77500"
};

export const colorSuccess: SimplePaletteColorOptions = {
  main: "#55830E"
};

export const colorDeepcrawlGreen: SimplePaletteColorOptions = {
  main: "#55830E",
  dark: "#5B7722",
  light: "#85AD27"
};

export const colorDeepcrawlOrange: SimplePaletteColorOptions = {
  main: "#DDA200"
};

export const colorMidGrey: SimplePaletteColorOptions = { main: "#7F8995" };
export const colorDeepBlack: SimplePaletteColorOptions = { main: "#242A42" };

export const colorText: Partial<TypeText> = {
  primary: "#0A1124",
  secondary: "#525151"
};

export const paletteOptions: PaletteOptions = {
  navy: colorNavy,
  blue: colorBlue,
  red: colorRed,
  grey: colorGrey,
  primary: colorPrimary,
  secondary: colorSecondary,
  text: colorText,
  error: colorError,
  warning: colorWarning,
  midGrey: colorMidGrey,
  deepBlack: colorDeepBlack,
  deepcrawlGreen: colorDeepcrawlGreen, // main for buttons, dark for text
  deepcrawlOrange: colorDeepcrawlOrange,
  success: colorSuccess,
  purple: colorPurple
};
