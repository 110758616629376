import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { ActionIconButton } from "../../../../_common/components/ActionIconButton/ActionIconButton";
import { DeleteButtonWithConfirmation } from "../../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";
import { EditIcon } from "../../../../_common/svg/EditIcon";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2.5, 0, 3.5),
    minHeight: 68,
    ["@media (max-width: 500px)"]: {
      padding: theme.spacing(3),
      display: "block"
    }
  },
  headerText: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px"
  }
}));

interface EditOrDeleteJiraIntegrationProps {
  jiraIntegrationName: string;
  jiraIntegrationId: string;
  handleEdit: () => void;
  isLoading: boolean;
  handleIntegrationDeletion: () => Promise<void>;
}

export function EditOrDeleteJiraIntegration(props: EditOrDeleteJiraIntegrationProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.header}
      data-testid="jira-integration-detail"
      data-cy="jira-integration-detail"
      id={`jira-integration-detail-${props.jiraIntegrationId}`}
    >
      <Typography
        className={classes.headerText}
        noWrap={true}
        data-testid="jira-integration-detail-name"
        data-cy="jira-integration-detail-name"
      >
        {props.jiraIntegrationName}
      </Typography>

      <Box display="flex">
        <ActionIconButton
          disableForViewer
          tooltipTitle="Edit Jira integration"
          onClick={props.handleEdit}
          data-testid="jira-integration-detail-edit"
          data-pendo="auto-jira-integration-detail-edit"
          data-cy="action-icon-button"
          disabled={props.isLoading}
          icon={<EditIcon />}
          variant="info"
          includeBorder
        />

        <DeleteButtonWithConfirmation
          disableForViewer
          handleConfirmation={props.handleIntegrationDeletion}
          tooltipTitle="Delete Jira integration"
          confirmationTitle="Are you sure you want to delete this Jira integration?"
          confirmationText="All connected test suites will be immediately disconnected and Jira tickets for new fails and warnings generated by your tests will no longer be created."
          dataTestId="jira-integration-detail-remove"
          disabled={props.isLoading}
          data-pendo={`auto-jira-integration-detail-delete-button`}
        />
      </Box>
    </Box>
  );
}
