import React from "react";
import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { Paper, Grid, Box, Typography, Hidden } from "@material-ui/core";
import { useStyles } from "./SchedulerTestSuiteListItemNotYetRunStyles";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import clsx from "clsx";
import { SchedulerDetailItemScheduledInfo } from "./SchedulerDetailItemScheduledInfo";
import { SchedulerTestSuiteItemInfo } from "./SchedulerTestSuiteItemInfo";
import { ActionMenuItem, ActionsMenu } from "../../../_common/components/ActionMenu/ActionsMenu";
import { doesUserMatchRole } from "../../../_common/utils/doesUserMatchRole/doesUserMatchRole";
import { RoleCode } from "../../../generated-graphql-interfaces";
import { useStylesItemGrid } from "./SchedulerTestSuiteListItemStyles";

export interface SchedulerTestSuiteListItemNotYetRunProps {
  id: string;
  nextRunAt: Date | null;
  name: string;
  url: string;
  onActionMenuEvent: (event: string) => Promise<boolean>;
  overrideActions: ActionMenuItem[];
}

export function SchedulerTestSuiteListItemNotYetRun(props: SchedulerTestSuiteListItemNotYetRunProps): JSX.Element {
  const classes = useStyles();
  const classesGrid = useStylesItemGrid();
  const isViewOnly = doesUserMatchRole(RoleCode.Viewer);
  const buildStatus = props.nextRunAt ? ExtendedBuildStatus.Scheduled : ExtendedBuildStatus.NotYetRun;

  const scheduleInfoBlock = (
    <Box className={classes.childrenBox}>
      <SchedulerDetailItemScheduledInfo testSuiteId={props.id} startTime={props.nextRunAt} />
    </Box>
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.border}></div>
      <Paper
        className={clsx(classesGrid.root, classes.rootCompact)}
        data-cy="scheduler-test-suite-list-item"
        data-testid="scheduler-test-suite-list-item-compact"
      >
        <Box className={classesGrid.mainGrid}>
          <Box className={clsx(classesGrid.iconWrapper, classes.iconWrapperSpecific)}>
            <BuildStatusIcon status={buildStatus} size={StatusIconSize.LG} tooltipVariant={true} />
          </Box>

          <Grid container className={classesGrid.textWrapper}>
            <Grid item xs={12} lg={5} className={classesGrid.itemInfoWrapper}>
              <SchedulerTestSuiteItemInfo name={props.name} url={props.url} />
            </Grid>
            <Grid item xs={12} lg={4}></Grid>
            <Hidden lgUp>{scheduleInfoBlock}</Hidden>
            <Grid item xs={12} lg={3} className={classesGrid.dateTimeWrapper}>
              <Typography
                data-testid="scheduler-test-suite-list-item-last-run"
                data-cy="scheduler-test-suite-list-item-last-run"
                color="textPrimary"
                className={classes.testSuiteId}
              >
                {props.nextRunAt ? "Scheduled" : "Not yet run"}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classesGrid.actionsMenuWrapper}>
            {!isViewOnly && (
              <ActionsMenu
                onActionMenuEvent={props.onActionMenuEvent}
                customActions={props.overrideActions}
                pendoId="auto-scheduler-detail-testsuites-item-menu"
              />
            )}
          </Box>
        </Box>
        <Hidden mdDown>{scheduleInfoBlock}</Hidden>
      </Paper>
    </div>
  );
}
