import React from "react";
import { BuildTestStatus } from "../../interfaces/BuildTestStatus";
import { StatusIcon, StatusIconSize } from "../StatusIcon/StatusIcon";
import { getStatusMessage, getStatusIconType } from "./BuildStatusIconUtils";

export interface BuildTestStatusIconProps {
  status: BuildTestStatus;
  justIcon?: boolean;
  size?: StatusIconSize;
  tooltipVariant?: boolean;
}

export function BuildStatusIcon(props: BuildTestStatusIconProps): JSX.Element {
  function getIcon(status: BuildTestStatus): JSX.Element {
    const text = getStatusMessage(status);
    const statusIconType = getStatusIconType(status);
    return <StatusIcon type={statusIconType} size={props.size} text={text} tooltipVariant={props.tooltipVariant} />;
  }

  return getIcon(props.status);
}
