import React from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { Field } from "formik";
import { CustomCheckbox } from "./CustomCheckbox";
import { TextField } from "formik-material-ui";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

export function JsRenderingSettings() {
  const classes = useCrawlSettingsStyles();

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Box mt={7}>
          <Box mb={4} display="flex" alignItems="center">
            <b className={classes.tooltipTitle}>JavaScript Rendering Settings</b>
            <DeepcrawlInfoTooltip
              maxWidth={400}
              body={crawlSettingsTooltips.jsRendering}
              data-pendo="auto-tooltip-js-rendering-settings"
            />
          </Box>
          <Box data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-ad-scripts">
            <Field
              name="rendererBlockAds"
              id="renderer-block-ads"
              component={CustomCheckbox}
              data-cy="js-block-ad-checkbox"
            />
            <InputLabel htmlFor="renderer-block-ads" className={classes.label}>
              Block ad scripts
            </InputLabel>
          </Box>
          <Box
            mb={2}
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-analytics-scripts"
          >
            <Field
              name="rendererBlockAnalytics"
              id="renderer-block-analytics"
              component={CustomCheckbox}
              data-cy="js-block-analytics-checkbox"
            />
            <InputLabel htmlFor="renderer-block-analytics" className={classes.label}>
              Block analytics scripts
            </InputLabel>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            multiline={true}
            rows="4"
            name="rendererBlockCustom"
            label="Block Script URLs"
            component={TextField}
            variant="filled"
            type="text"
            data-cy="js-block-script-urls-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-script-url"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            name="rendererJsString"
            label="Custom JavaScript Snippet"
            variant="filled"
            type="text"
            multiline={true}
            rows="4"
            component={TextField}
            data-cy="custom-js-snippet-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-custom-js-snippets"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Field
            fullWidth
            variant="filled"
            type="text"
            multiline={true}
            rows="4"
            component={TextField}
            name="rendererJsUrls"
            label="Include External JavaScript Resources"
            placeholder="e.g. https://code.jquery.com/jquery-3.2.1.slim.min.js"
            data-cy="external-js-resources-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-include-external-js-resources"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
