import { useState, useContext, useEffect } from "react";
import { errorsSubscription, ServerError } from "../../../api/graphql/errors-subscription";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { AuthContext } from "../../contexts/Permissions/AuthContext";
import { PageContainer } from "../PageContainer/PageContainer";
import { ExpiredTokenDialog } from "./ExpiredTokenDialog";

const useStyles = makeStyles(theme => ({
  errorsList: {
    listStyleType: "none"
  },
  alert: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(3)
  }
}));

export interface ErrorsProps {
  logoutCallback: () => void;
  catchLoginErrors?: boolean;
}

export function Errors({ logoutCallback, catchLoginErrors }: ErrorsProps) {
  const [errors, setErrors] = useState<ServerError[]>([]);
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const filteredErrors = errors.filter(error => {
    const isAccessDeniedError = error.message === "Access denied! You don't have permission for this action!";
    const isLoginError = error.message === "incorrect username or password";

    if (catchLoginErrors) {
      return !isAccessDeniedError || authContext.isAuthorised || isLoginError;
    }

    return (!isAccessDeniedError || authContext.isAuthorised) && !isLoginError;
  });

  const includes401Error = errors.some(e => e.statusCode === 401);

  useEffect(() => {
    errorsSubscription.registerCallback(errors => {
      setErrors(errors);
      const unauthorizedError = "Access denied! You need to be authorized to perform this action!";

      if (errors.map(e => e.message).includes(unauthorizedError)) {
        logoutCallback();
      }

      if (errors.length) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });

    return () => {
      setErrors([]);
      errorsSubscription.clearCallbacks();
    };
  }, []);

  return (
    <>
      {filteredErrors.length ? (
        <PageContainer>
          <Alert severity="error" data-testid="errors-list" className={classes.alert}>
            <AlertTitle data-cy="error-alert">There was an error. Please check and try again.</AlertTitle>
            <ul className={classes.errorsList} data-cy="error-alert-list">
              {filteredErrors.map(({ message }) => {
                return <li key={message}>- {message}</li>;
              })}
            </ul>
          </Alert>
        </PageContainer>
      ) : null}

      <ExpiredTokenDialog isOpen={includes401Error && authContext.isAuthorised} handleConfirmation={logoutCallback} />
    </>
  );
}
