import {
  getAvailableParentTestSuites_getAccount_parentTestSuites,
  getAvailableParentTestSuites_getAccount_notLinkedTestSuites,
  getAvailableParentTestSuites
} from "../../../../../generated-graphql-interfaces";
import { TestSuiteOption } from "../components/ApplyGlobalTemplate";

export enum TestSuiteGroup {
  Parent = "Global templates",
  NotLinked = "Test suites to become global template"
}

function getOptionsFromTestSuites(
  testSuites:
    | getAvailableParentTestSuites_getAccount_parentTestSuites
    | getAvailableParentTestSuites_getAccount_notLinkedTestSuites
    | null,
  groupLabel: string
) {
  if (!testSuites) return [];

  return testSuites.edges.map(testSuite => {
    return {
      name: testSuite.node.name,
      id: testSuite.node.id,
      group: groupLabel
    };
  });
}

export function getParentTestsuiteOptionsFromData(
  data: getAvailableParentTestSuites | undefined,
  testSuiteId?: string
): TestSuiteOption[] | null {
  if (!data || data.getAccount?.__typename !== "Account") {
    return null;
  }

  const parentOptions = getOptionsFromTestSuites(data.getAccount.parentTestSuites, TestSuiteGroup.Parent);
  const notLinkedOptions = getOptionsFromTestSuites(data.getAccount.notLinkedTestSuites, TestSuiteGroup.NotLinked);
  return [...parentOptions, ...notLinkedOptions].filter(option => option.id !== testSuiteId);
}
