import React from "react";
import { Typography, Box, Grid, makeStyles } from "@material-ui/core";
import graphEmptyState from "../../../images/graph-empty-state.svg";

export interface NoDataChartSubstitudeProps {
  heading: string;
  text: string;
}

const useStyles = makeStyles(theme => ({
  noDataWrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2)
    }
  },
  noDataGraphic: {
    marginBottom: theme.spacing(2)
  },
  noDataTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.38)"
  },
  noDataText: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.38)",
    maxWidth: "300px",
    margin: "0 auto"
  }
}));

export function NoDataChartSubstitude(props: NoDataChartSubstitudeProps) {
  const classes = useStyles();

  return (
    <Grid container data-testid="no-data-chart-substitude">
      <Grid item md={6} sm={12} xs={12}>
        <Box
          mt={5}
          p={4}
          textAlign="center"
          data-testid="no-data-available"
          data-cy="no-data-available"
          className={classes.noDataWrapper}
        >
          <img src={graphEmptyState} alt="No data to display" className={classes.noDataGraphic} />
          <Typography
            className={classes.noDataTitle}
            color="textSecondary"
            data-testid="no-data-chart-substitude-heading"
          >
            {props.heading}
          </Typography>
          <Typography variant="body1" className={classes.noDataText} data-testid="no-data-chart-substitude-text">
            {props.text}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
