import React, { useRef } from "react";
import { TextField } from "formik-material-ui";
import { Grid, makeStyles } from "@material-ui/core";
import { Field, FormikProps, FormikValues } from "formik";
import { ConfirmOrDelete } from "../ConfirmOrDelete/ConfirmOrDelete";

const useStyles = makeStyles(theme => ({
  confirmButtonsWrapper: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  }
}));

interface AddInputProps {
  onFocus: () => void;
  onBlur: () => void;
  formik: FormikProps<FormikValues>;
  id: string;
  label: string;
  formikLabel: string;
}

export function AddInput(props: AddInputProps) {
  const classes = useStyles();
  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  function handleBlur({ relatedTarget }: FocusEvent) {
    if (relatedTarget && relatedTarget !== confirmBtnRef.current && relatedTarget !== cancelBtnRef.current) {
      props.onBlur();
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={8}>
        <Field
          component={TextField}
          id={`${props.id}-input`}
          type="text"
          label={props.label}
          name={props.formikLabel}
          variant="filled"
          fullWidth
          data-testid={`${props.id}-input`}
          onFocus={props.onFocus}
          InputProps={{ onBlur: handleBlur }}
          autoFocus
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} className={classes.confirmButtonsWrapper}>
        <ConfirmOrDelete
          confirmButtonRef={confirmBtnRef}
          cancelButtonRef={cancelBtnRef}
          dataTestId={props.id}
          handleCancel={() => {
            props.formik.setFieldValue(`${props.formikLabel}`, "");
            props.onBlur();
          }}
          handleConfirm={props.formik.submitForm}
        />
      </Grid>
    </Grid>
  );
}
