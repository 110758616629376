import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function SchedulerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78036 0.435547C6.33509 0.435547 6.78479 0.885254 6.78479 1.43999V3.67336C10.5718 3.3361 14.3814 3.3361 18.1684 3.67336V1.43999C18.1684 0.885254 18.6181 0.435547 19.1729 0.435547C19.7276 0.435547 20.1773 0.885254 20.1773 1.43999V3.87265C22.1768 4.15537 23.7566 5.73693 24.0239 7.75434L24.1399 8.6288C24.6274 12.3068 24.5853 16.0357 24.0148 19.7019C23.7322 21.5179 22.2569 22.9129 20.4277 23.0931L18.83 23.2508C14.6047 23.6674 10.3485 23.6674 6.12311 23.2508L4.52537 23.0931C2.6963 22.9129 1.2209 21.5179 0.938307 19.7019C0.36784 16.0357 0.325734 12.3068 0.813275 8.6288L0.929187 7.75434C1.19661 5.7369 2.77632 4.15533 4.77592 3.87264V1.43999C4.77592 0.885254 5.22562 0.435547 5.78036 0.435547ZM6.37643 5.72966C10.4333 5.3296 14.5197 5.3296 18.5765 5.72966L19.7891 5.84926C20.9499 5.96373 21.8791 6.86232 22.0323 8.01863L22.1483 8.8931C22.1888 9.19859 22.2254 9.50448 22.2583 9.81063H2.6947C2.7275 9.50448 2.76412 9.19861 2.80462 8.89311L2.92053 8.01863C3.07381 6.86232 4.00301 5.96373 5.16381 5.84926L6.37643 5.72966ZM2.53512 11.8192C2.40363 14.3478 2.53318 16.8858 2.92329 19.3929C3.06504 20.3039 3.80508 21.0035 4.72252 21.094L6.32026 21.2515C10.4146 21.6553 14.5387 21.6553 18.6329 21.2515L20.2306 21.094C21.148 21.0035 21.8881 20.3039 22.0299 19.3929C22.42 16.8858 22.5495 14.3478 22.418 11.8192H2.53512Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientSchedulerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <path
          d="M6.78467 1.48295C6.78467 0.928222 6.33496 0.478516 5.78023 0.478516C5.2255 0.478516 4.77579 0.928222 4.77579 1.48295V3.9156C2.7762 4.19829 1.19649 5.77986 0.929072 7.79729L0.81316 8.67175C0.7935 8.82001 0.77471 8.96836 0.756778 9.11677C0.709221 9.51047 1.01939 9.85325 1.41596 9.85325H23.5369C23.9335 9.85325 24.2436 9.51047 24.1961 9.11677C24.1781 8.96836 24.1594 8.82001 24.1397 8.67175L24.0237 7.79729C23.7564 5.77989 22.1766 4.19833 20.1771 3.91561V1.48295C20.1771 0.928222 19.7274 0.478516 19.1727 0.478516C18.618 0.478516 18.1683 0.928222 18.1683 1.48295V3.71632C14.3813 3.37906 10.5717 3.37906 6.78467 3.71632V1.48295Z"
          fill="black"
        />
        <path
          d="M24.4556 12.4983C24.4438 12.1414 24.1489 11.8613 23.792 11.8613H1.1611C0.804085 11.8613 0.509236 12.1414 0.497504 12.4983C0.417966 14.9191 0.565016 17.345 0.938304 19.744C1.2209 21.56 2.69629 22.955 4.52536 23.1353L6.1231 23.2929C10.3485 23.7095 14.6046 23.7095 18.83 23.2929L20.4277 23.1353C22.2568 22.955 23.7322 21.56 24.0147 19.744C24.3881 17.345 24.5352 14.9191 24.4556 12.4983Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.476562" y="0.0429688" width="24" height="24" fill="url(#paint0_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2.39771"
          y1="19.4402"
          x2="20.726"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
