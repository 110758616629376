import { TestsToDisplayOption } from "../../pages/TestSuiteReport/components/TestSuiteReportListUtils";
import { generatePathSafely } from "./generatePathSafely/generatePathSafely";

export const URL_PREFIX = "/account/:accountId";

export interface RequiredParams {
  accountId: string;
}

export const Routes = {
  Root: {
    ROUTE: "/",
    getUrl: () => {
      return Routes.Root.ROUTE;
    }
  },
  Any: {
    ROUTE: "/*",
    getUrl: () => {
      return Routes.Any.ROUTE;
    }
  },
  Login: {
    ROUTE: "/login",
    getUrl: () => {
      return Routes.Login.ROUTE;
    }
  },
  LoginCallback: {
    ROUTE: "/login-callback",
    getUrl: () => {
      return Routes.LoginCallback.ROUTE;
    }
  },
  Dashboard: {
    ROUTE: URL_PREFIX + "/dashboard",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.Dashboard.ROUTE, { accountId });
    }
  },
  GettingStarted: {
    ROUTE: URL_PREFIX + "/getting-started",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.GettingStarted.ROUTE, { accountId });
    }
  },
  WhyAutomator: {
    ROUTE: URL_PREFIX + "/why-automator",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.WhyAutomator.ROUTE, { accountId });
    }
  },
  Faqs: {
    ROUTE: URL_PREFIX + "/faqs",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.Faqs.ROUTE, { accountId });
    }
  },
  TestSuites: {
    ROUTE: URL_PREFIX + "/test-suites",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.TestSuites.ROUTE, { accountId });
    }
  },
  Builds: {
    ROUTE: URL_PREFIX + "/test-suites/:testSuiteId/build-tests",
    getUrl: ({ accountId, testSuiteId }: RequiredParams & { testSuiteId: string }) => {
      return generatePathSafely(Routes.Builds.ROUTE, { accountId, testSuiteId });
    }
  },
  TestResults: {
    ROUTE: URL_PREFIX + "/test-suites/:testSuiteId/build-tests/:buildTestId/:selectedType?",
    getUrl: ({
      accountId,
      testSuiteId,
      buildTestId,
      selectedType
    }: RequiredParams & {
      testSuiteId: string;
      buildTestId: string;
      selectedType: TestsToDisplayOption;
    }) => {
      return generatePathSafely(Routes.TestResults.ROUTE, {
        accountId,
        testSuiteId,
        buildTestId,
        selectedType
      });
    }
  },
  CreateTestSuite: {
    ROUTE: URL_PREFIX + "/setup/new",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.CreateTestSuite.ROUTE, { accountId });
    }
  },
  EditTestSuite: {
    ROUTE: URL_PREFIX + "/setup/:testSuiteId",
    getUrl: ({ accountId, testSuiteId }: RequiredParams & { testSuiteId: string }) => {
      return generatePathSafely(Routes.EditTestSuite.ROUTE, {
        accountId,
        testSuiteId
      });
    }
  },
  ConnectedApps: {
    ROUTE: URL_PREFIX + "/connected-apps",
    getUrl: ({ accountId }: RequiredParams) => {
      return generatePathSafely(Routes.ConnectedApps.ROUTE, {
        accountId
      });
    }
  },
  JiraIntegration: {
    ROUTE: URL_PREFIX + "/connected-apps/jira/:jiraIntegrationId",
    getUrl: ({ accountId, jiraIntegrationId }: RequiredParams & { jiraIntegrationId: string }) => {
      return generatePathSafely(Routes.JiraIntegration.ROUTE, { accountId, jiraIntegrationId });
    }
  },
  FrontendScheduler: {
    ROUTE: URL_PREFIX + "/scheduler/:schedulerId?",
    getUrl: ({ accountId, schedulerId }: RequiredParams & { schedulerId: string | undefined }) => {
      if (schedulerId) {
        return generatePathSafely(Routes.FrontendScheduler.ROUTE, { accountId, schedulerId });
      } else {
        return generatePathSafely(Routes.FrontendScheduler.ROUTE, { accountId });
      }
    }
  }
};
