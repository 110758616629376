import React from "react";
import { Grid, Box } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { Field } from "formik";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

export function IncludeExcludeUrls() {
  const classes = useCrawlSettingsStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Box mt={7} mb={4}>
          <Box mb={4} display="flex" alignItems="center">
            <b className={classes.tooltipTitle}>Include and Exclude URLs</b>
            <DeepcrawlInfoTooltip
              maxWidth={400}
              body={crawlSettingsTooltips.includeAndExcludeUrls}
              data-pendo="auto-tooltip-include-exclude-url"
            />
          </Box>
          <Field
            fullWidth
            component={TextField}
            multiline={true}
            rows="4"
            name="urlsIncluded"
            id="urls-included"
            data-testid="include-only-urls-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-include-urls"
            type="text"
            label="Include Only URLs"
            variant="filled"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Field
            fullWidth
            component={TextField}
            multiline={true}
            rows="4"
            name="urlsExcluded"
            data-testid="exclude-urls-input"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-exclude-urls"
            variant="filled"
            label="Exclude URLs"
            type="text"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
