import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../_common/routes/routes";
import { useDeleteTestSuiteMutation } from "../../TestSuite/graphql/useDeleteTestSuiteMutation";
import { useCloneTestSuiteMutation } from "./graphql/useCloneTestSuiteMutation";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../_common/components/PopoverDialog/PopoverDialog";
import { defaultPopOverId } from "../../../_common/constants/popover";
import { doesUserMatchRole } from "../../../_common/utils/doesUserMatchRole/doesUserMatchRole";
import { getBuildsStatuses_node_TestSuite, RoleCode } from "../../../generated-graphql-interfaces";
import { ActionMenuIconType, ActionMenuItem, ActionsMenu } from "../../../_common/components/ActionMenu/ActionsMenu";
import { useSnackbar } from "notistack";
import { useCreateAndRunManualBuild } from "../../../_common/graphql/useCreateAndRunManualBuildGql";
import { useGetBuildsStatusesQuery } from "../../TestSuite/graphql/useGetBuildsStatusesQuery";
import { isPriorityBuildRunning } from "../../../_common/utils/isPriorityBuildRunning/isPriorityBuildRunning";
import { useUnlinkJiraIntegrationFromTestSuiteMutation } from "../../TestSuite/components/SetAlerts/graphql/useUnlinkJiraIntegrationFromTestSuite";

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary"
  },
  {
    label: "Delete",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert"
  }
];

export interface ActionsMenuProps {
  testSuiteId: string;
  refetchData?: () => void;
  isTestSuiteRunning: boolean;
  testSuiteJiraIntegrationId?: string | null;
}

enum testSuiteActionId {
  CLONE = "clone",
  DELETE = "delete",
  EDIT = "edit",
  RUN_NOW = "run-now"
}

const actionMenuItemClone: ActionMenuItem = {
  dataId: "clone-test-suite",
  iconType: ActionMenuIconType.CLONE,
  id: testSuiteActionId.CLONE,
  label: "Duplicate",
  closeMenuOnClick: true
};

const actionMenuItemEdit: ActionMenuItem = {
  dataId: "edit-test-suite",
  iconType: ActionMenuIconType.EDIT,
  id: testSuiteActionId.EDIT,
  label: "Edit",
  closeMenuOnClick: true
};

const actionMenuItemDelete: ActionMenuItem = {
  dataId: "delete-test-suite",
  iconType: ActionMenuIconType.DELETE,
  id: testSuiteActionId.DELETE,
  label: "Delete",
  closeMenuOnClick: false
};

const actionMenuItemRunNow: ActionMenuItem = {
  dataId: "run-test-suite-now",
  iconType: ActionMenuIconType.RUN_NOW,
  id: testSuiteActionId.RUN_NOW,
  label: "Run now",
  closeMenuOnClick: false
};

// eslint-disable-next-line max-statements, max-lines-per-function
export function TestSuiteItemActionsMenu(props: ActionsMenuProps) {
  const [createAndRunManualBuild] = useCreateAndRunManualBuild();
  const accountId = useAccountRouteMatch();
  const history = useHistory();
  const [cloneTestSuiteMutation] = useCloneTestSuiteMutation();
  const [deleteTestSuiteMutation] = useDeleteTestSuiteMutation();
  const [unlinkJiraIntegration] = useUnlinkJiraIntegrationFromTestSuiteMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: buildsStatuses, refetch, error: errorGettingData } = useGetBuildsStatusesQuery(props.testSuiteId);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isTestSuiteRunning]);

  const isPriorityBuildOn = isPriorityBuildRunning(
    (buildsStatuses?.node as getBuildsStatuses_node_TestSuite)?.builds?.nodes
  );

  const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState<HTMLElement | null>(null);
  const isPopoverOpen = Boolean(deletePopoverAnchorEl);
  const isViewOnly = doesUserMatchRole(RoleCode.Viewer);

  async function handleDelete(event: React.MouseEvent<HTMLElement>): Promise<boolean> {
    event.stopPropagation();
    setDeletePopoverAnchorEl(event.currentTarget);
    return new Promise(resolve => resolve(false));
  }

  async function cloneTestSuite(): Promise<boolean> {
    try {
      await cloneTestSuiteMutation({
        variables: { testSuiteId: props.testSuiteId }
      });
      enqueueSnackbar("Test suite duplicated successfully", { variant: "success" });
      history.push(Routes.TestSuites.getUrl({ accountId }));
    } catch (e) {
      enqueueSnackbar("Test suite duplication failed. Please refresh the page and try again", {
        variant: "error",
        persist: true
      });
    }
    return new Promise(resolve => resolve(true));
  }

  function editTestSuite(): Promise<boolean> {
    history.push(Routes.EditTestSuite.getUrl({ accountId, testSuiteId: props.testSuiteId }));
    return new Promise(resolve => resolve(false));
  }

  async function handleDeleteConfirmation(id: string) {
    setDeletePopoverAnchorEl(null);

    if (id === PopoverDialogDefaultIdentifiers.OK) {
      try {
        // eslint-disable-next-line max-depth
        if (props.testSuiteJiraIntegrationId) {
          await unlinkJiraIntegration({
            variables: { testSuiteJiraIntegrationId: props.testSuiteJiraIntegrationId }
          });
        }

        await deleteTestSuiteMutation({
          variables: { testSuiteId: props.testSuiteId }
        });
        enqueueSnackbar("Test suite deleted successfully", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("Test suite deletion failed. Please refresh the page and try again", {
          variant: "error",
          persist: true
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleErrorFromRunNow(e: any) {
    if (e.toString().includes("Conflict. Operation couldn't be performed.")) {
      enqueueSnackbar(`The test suite is already running`, { variant: "error" });
    } else {
      enqueueSnackbar(`Error during starting test suite`, { variant: "error" });
    }
  }

  async function handleRunNow(): Promise<boolean> {
    try {
      const data = await refetch();
      const priorityBuildRunning = isPriorityBuildRunning(
        (data.data.node as getBuildsStatuses_node_TestSuite).builds.nodes
      );
      if (priorityBuildRunning) {
        enqueueSnackbar(`The test suite is already running`, { variant: "error" });
      } else {
        await createAndRunManualBuild({
          variables: {
            testSuiteId: props.testSuiteId
          }
        });
        enqueueSnackbar("Test suite run started successfully", { variant: "success" });
      }
    } catch (e) {
      handleErrorFromRunNow(e);
    }
    refetch();
    props.refetchData && props.refetchData();
    return new Promise(resolve => resolve(true));
  }

  function onActionsMenuClick(id: string, event: React.MouseEvent<HTMLElement>): Promise<boolean> {
    switch (id) {
      case testSuiteActionId.EDIT:
        return editTestSuite();
      case testSuiteActionId.CLONE:
        return cloneTestSuite();
      case testSuiteActionId.DELETE:
        return handleDelete(event);
      case testSuiteActionId.RUN_NOW:
        return handleRunNow();
      default:
        return new Promise(resolve => resolve(true));
    }
  }

  const actionMenuItems = [
    isPriorityBuildOn || errorGettingData ? undefined : actionMenuItemRunNow,
    actionMenuItemClone,
    props.isTestSuiteRunning ? undefined : actionMenuItemEdit,
    actionMenuItemDelete
  ].filter(item => item !== undefined);

  return !isViewOnly ? (
    <>
      <ActionsMenu
        onActionMenuEvent={onActionsMenuClick}
        customActions={actionMenuItems as ActionMenuItem[]}
        pendoId="auto-test-suites-list-item-actions"
      />
      <PopoverDialog
        anchorElement={deletePopoverAnchorEl}
        handleAction={handleDeleteConfirmation}
        open={isPopoverOpen}
        title="Are you sure you want to delete this test suite?"
        text="This cannot be undone."
        buttons={popoverButtons}
        id={isPopoverOpen ? defaultPopOverId : undefined}
      />
    </>
  ) : null;
}
