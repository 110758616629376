import { Container, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { AppRoutesPrivate } from "./AppRoutesPrivate";
import { Errors } from "../_common/components/Errors/Errors";
import { Footer } from "../_common/components/Footer/Footer";
import { NavBar } from "../_common/components/NavBar/NavBar";
import { SIDE_NAV_WIDTH } from "../_common/components/SideNav/SideNav";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 60,
    zIndex: 2,
    padding: 0,
    minHeight: `calc(100vh - 244px)`,
    [theme.breakpoints.down("xs")]: {
      minHeight: `calc(100vh - 314px)`
    }
  },
  nonSideNavContent: {
    width: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
    marginLeft: SIDE_NAV_WIDTH,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0"
    }
  },
  appGrid: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateColumns: "100%",
    minHeight: "100%"
  }
}));

interface LoggedInViewProps {
  logoutHandler: () => void;
}

export function LoggedInView(props: LoggedInViewProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.appGrid, classes.nonSideNavContent)} data-testid="logged-in-view">
      <NavBar logoutCallback={props.logoutHandler} />
      <Container maxWidth="xl" className={classes.container} data-testid="logged-in-container">
        <Errors catchLoginErrors={true} logoutCallback={props.logoutHandler} />
        <AppRoutesPrivate logoutHandler={props.logoutHandler} />
      </Container>
      <Footer />
    </div>
  );
}
