import React, { useState, useEffect } from "react";
import { useAuthTokenMutation } from "./graphql/useAuthTokenMutation";
import { LoginForm } from "./LoginForm";
import { Box, Grid, Typography } from "@material-ui/core";
import automationHubLogo from "../../../src/images/automation-hub-logo.svg";
import { LoginScreenInformation } from "./LoginScreenInformation";
import { errorsSubscription, ServerError } from "../../api/graphql/errors-subscription";
import { AlertTitle, Alert } from "@material-ui/lab";
import { useStyles } from "./LoginStyles";
import deepcrawlLogo from "../../images/new_deepcrawl_logo.svg";

interface LoginProps {
  authCallback: (input: { token?: string; errors?: string[] }) => void;
}

export function Login(props: LoginProps): JSX.Element {
  const [getAuthToken] = useAuthTokenMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<ServerError[]>([]);
  const classes = useStyles();
  const loginFailedMessage = "incorrect username or password";

  errorsSubscription.registerCallback(errors => {
    const filteredErrors = errors.filter(error => {
      return error.message === loginFailedMessage;
    });
    setErrors(filteredErrors);
  });

  useEffect(() => {
    return function cleanup() {
      errorsSubscription.clearCallbacks();
    };
  }, []);

  async function getAuthTokenAndUpdateView(username: string, password: string) {
    const data = await getAuthToken({
      variables: {
        username,
        password
      }
    });
    setIsSubmitting(false);
    if (data && data.data) {
      props.authCallback({
        token: data.data.createSessionUsingUsername.token
      });
    }
  }

  function handleAuthTokenError(error: Error) {
    setIsSubmitting(false);
    props.authCallback({
      errors: [error.message]
    });
  }

  async function handleSubmit(username: string, password: string): Promise<void> {
    setIsSubmitting(true);
    try {
      await getAuthTokenAndUpdateView(username, password);
    } catch (error) {
      handleAuthTokenError(error);
    }
  }

  return (
    <Grid container className={classes.page} data-testid="login-page-wrapper">
      <Grid item xl={4} lg={3} md={5} sm={12} xs={12} className={classes.loginWrapper}>
        <Box style={{ maxWidth: 362, width: "100%" }}>
          <img data-testid="logo" src={automationHubLogo} className={classes.logo} alt="Logo" />
          <Typography color="textPrimary" className={classes.title}>
            SEO Automation Hub
          </Typography>
          <LoginForm onSubmit={handleSubmit} submitting={isSubmitting} />
          {errors.length > 0 && (
            <Alert severity="error" className={classes.errorsWrapper}>
              <AlertTitle data-cy="error-alert">There was an error. Please check and try again.</AlertTitle>
              <ul data-cy="error-alert-list">
                {errors.map(error => {
                  return (
                    <li key={error.message} data-testid="error-alert-list">
                      - {error.message}
                    </li>
                  );
                })}
              </ul>
            </Alert>
          )}
        </Box>
        <Box className={classes.dcLogoWrapper}>
          <img src={deepcrawlLogo} alt="DeepCrawl Logo" />
        </Box>
      </Grid>

      <Grid item xl={8} lg={9} md={7} sm={12} xs={12} className={classes.informationBar}>
        <LoginScreenInformation />
      </Grid>
    </Grid>
  );
}
