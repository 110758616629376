import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import {
  unlinkTestSuiteFromBuildSchedule,
  unlinkTestSuiteFromBuildScheduleVariables
} from "../../../../../generated-graphql-interfaces";

export const unlinkTestSuiteFromBuildScheduleGql = gql`
  mutation unlinkTestSuiteFromBuildSchedule($buildScheduleTestSuiteId: ObjectID!) {
    unlinkTestSuiteFromBuildSchedule(input: { buildScheduleTestSuiteId: $buildScheduleTestSuiteId }) {
      buildScheduleTestSuite {
        id
      }
    }
  }
`;

export function useUnlinkTestSuiteFromBuildSchedule() {
  return useMutation<unlinkTestSuiteFromBuildSchedule, unlinkTestSuiteFromBuildScheduleVariables>(
    unlinkTestSuiteFromBuildScheduleGql,
    { refetchQueries: ["getScheduleForScheduleSettings"] }
  );
}
