/* eslint-disable max-lines */
import React from "react";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { Typography, Grid, Paper, makeStyles, List, ListItem, Box } from "@material-ui/core";
import AnchorScrollLink from "../../_common/components/AnchorScrollLink/AnchorScrollLink";
import { BackToTop } from "../../_common/components/BackToTop/BackToTop";
import connectSiteImg from "../../images/connect-site.png";
import addTestSuiteImg from "../../images/add-test-suite.png";
import crawlSettingsImg from "../../images/crawl-settings.png";
import chooseTestsImg from "../../images/choose-tests.png";
import setEmails from "../../images/set-alerts-email.png";
import setSlack from "../../images/set-alerts-slack.png";
import connectCiCdImg from "../../images/connect-ci-cd.png";
import testResultsImg from "../../images/test-results.png";
import buildResultsImg from "../../images/build-tests.png";

const useStyles = makeStyles(theme => ({
  overviewList: {
    paddingBottom: theme.spacing(4),
    listStyle: "inside"
  },
  overviewListItem: {
    display: "list-item"
  },
  img: { width: "100%", marginBottom: theme.spacing(2) },
  section: {
    marginBottom: theme.spacing(6)
  },
  text: {
    margin: theme.spacing(3.5)
  },
  headerBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3.5),
      marginRight: theme.spacing(3.5)
    }
  },
  textPageContainer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3.5)
    }
  }
}));

const linkConfig = [
  { name: "What is a Test Suite?", href: "what-is-a-test-suite" },
  {
    name: "Adding a Test Suite",
    href: "adding-a-testsuite"
  },
  { name: "Connecting your site", href: "connecting-your-site" },
  { name: "Choose crawl settings", href: "choose-crawl-settings" },
  { name: "Choose tests", href: "choose-tests" },
  { name: "Set Alerts", href: "set-alerts" },
  { name: "Connect to your CI/CD Tool", href: "connect-to-ci-cd" },
  { name: "Running the crawl", href: "running-the-crawl" },
  { name: "Viewing Crawl Results", href: "viewing-crawl-results" },
  { name: "Additional Tips", href: "additional-tips" }
];

// eslint-disable-next-line max-lines-per-function
export function GettingStarted() {
  const headerHeight = 100;
  const classes = useStyles();

  return (
    <>
      <Paper id="faq" data-testid="getting-started" className={classes.textPageContainer}>
        <BackToTop />
        <Grid container>
          <Grid item xs={12} className={classes.headerBox}>
            <PageHeader
              mainText="Getting Started with Automation Hub"
              includeAddTestSuite={false}
              includeGoTo={true}
              pendoIdSuffix="getting-started"
            />
          </Grid>
          <Grid item xs={12} className={classes.text}>
            <Typography variant="h4" gutterBottom>
              Overview
            </Typography>
            <List className={classes.overviewList}>
              {linkConfig.map(link => (
                <ListItem className={classes.overviewListItem} key={link.href}>
                  <AnchorScrollLink
                    href={`#${link.href}`}
                    offset={headerHeight}
                    data-cy={`link-${link.href}`}
                    data-pendo={`auto-getting-started-${link.href}`}
                  >
                    {link.name}
                  </AnchorScrollLink>
                </ListItem>
              ))}
            </List>
            <Grid item container xs={12} md={8} className={classes.section}>
              <Typography variant="h5" gutterBottom id="what-is-a-test-suite" data-cy="header-what-is-a-test-suite">
                What is a Test Suite?
              </Typography>
              <Typography variant="body1" gutterBottom paragraph>
                A Test Suite is a group of tests you wish to run across a specific domain. You can create multiple Test
                Suites for each domain should you wish to group them based on different outcomes for example site speed,
                stability, missing pages.
              </Typography>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="adding-a-testsuite" data-cy="header-adding-a-testsuite">
                  Adding a Test Suite
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  From the Home Page of Automation Hub click + Add Test Suite to begin the setup process.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={addTestSuiteImg} alt="Adding a test suite" className={classes.img} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="connecting-your-site" data-cy="header-connecting-your-site">
                  Connecting your site
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Define the general settings of your test by giving it a name and entering the domain. If your site is
                  behind a firewall, expand the Authentication section to enable configuration. We offer authentication
                  via Username and Password, Whitelisting IP or Custom DNS (or a mixture of all 3).
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={connectSiteImg} alt="Connect Site Form" className={classes.img} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Name</b> - the name of the test. This should be something easily recognizable.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Domain</b> - the domain that you want to crawl (your test, qa, staging or UAT environment). You
                  have to add the full domain name including protocol (http or https).
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Authentication</b> - You need to define your username and password and our crawler will send these
                  credentials to all pages on your website using basic authentication.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Whitelisting</b> - This section allows you to define the IP address we will use (which you have to
                  whitelist) and the crawler user agent.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Custom DNS</b> - Custom DNS entries can be configured if your website does not have public DNS
                  records which is common in staging environments. Enter DNS entries as you would in a host file.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="choose-crawl-settings" data-cy="header-choose-crawl-settings">
                  Choose crawl settings
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  This section enables users to define the crawl configuration like speed, limit and enable or disable
                  JavaScript.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={crawlSettingsImg} alt="Choose crawl settings" className={classes.img} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>URL source</b> - the user can choose from either crawling the website or crawling from a list of
                  crawls. If a user selects a list of crawls they can upload a file that contains the list.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>JS Rendering</b> - based on how the website is built the user has the ability to enable JavaScript
                  Rendering.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>Crawl speed</b> - Enables users to limit the maxim crawl speed to maintain the website’s
                  performance stable. The limit that the user sets it’s the maximum limit and the crawl speed will vary
                  up to the limit. We recommend 3 URL/s for development and staging sites.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  <b>URL limit</b> - The maximum limit of URLs that will be crawled.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Advanced settings allow you to include or exclude specific URLs, overwrite robots.txt file and define
                  specific javascript rendering settings.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Advanced Test Settings e.g. max page width, title length are set at DeepCrawl’s recommended levels but
                  can also be amended here.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="choose-tests" data-cy="header-choose-tests">
                  Choose tests
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Automation Hub can run over 160 different tests on your domain.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Each test can be set with a severity level of Fail or Warning. A Fail will stop your build and a
                  Warning will send a notification either via Email or Slack but allow the build to continue.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  You can apply a threshold against each test and can choose between a percentage or an absolute number.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={chooseTestsImg} alt="Choose tests" className={classes.img} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="set-alerts" data-cy="header-set-alerts">
                  Set Alerts
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  You can set alerts to be sent via email or integrate into your Slack environment. Instructions to
                  connect to Slack are included here.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}>
                  <img src={setEmails} alt="Set Alerts (Email)" className={classes.img} />
                </Box>
                <Box mb={2}>
                  <img src={setSlack} alt="Set Alerts (Slack)" className={classes.img} />
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="connect-to-ci-cd" data-cy="header-connect-to-ci-cd">
                  Connect to your CI/CD Tool
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  Automation Hub can connect to all major CI /CD tools via API and instructions are provided here.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={connectCiCdImg} alt="Connect CI/CD Tool" className={classes.img} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.section}>
              <Typography variant="h5" gutterBottom id="running-the-crawl" data-cy="header-running-the-crawl">
                Running the crawl
              </Typography>
              <Typography variant="body1" gutterBottom paragraph>
                Automation Hub is integrated with your CI/CD tool which means that it will automatically run as part of
                your automated tests whenever you release code. This means it is part of your process and you don’t need
                to set any reminders.
              </Typography>
            </Grid>
            <Grid item container xs={12} className={classes.section}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" gutterBottom id="viewing-crawl-results" data-cy="header-viewing-crawl-results">
                  Viewing Crawl Results
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  The results can be seen on your Automation Hub home page: The Builds list shows top level information
                  about each build (crawl) and the number of tests that passed or failed.
                </Typography>
                <Typography variant="body1" gutterBottom paragraph>
                  If all tests passed or warned, then the build will pass. If one test fails, then the build will fail
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={buildResultsImg} alt="Build Results" className={classes.img} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="body1" gutterBottom paragraph>
                  The Build detail page shows each test that passed, failed, or warned. Users can see more detail about
                  the URLs in each test by opening the DeepCrawl report.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={testResultsImg} alt="Test Results" className={classes.img} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.section}>
              <Typography variant="h5" gutterBottom id="additional-tips" data-cy="header-additional-tips">
                Additional Tips
              </Typography>
              <Typography variant="body1" gutterBottom paragraph>
                Duplicating a test suite: Automation Hub allows you to easily duplicate test suites to save you valuable
                time. This means you don’t have to re-enter configuration details for each staging environment. The
                duplicate will not copy across any URL lists and will automatically be set to a web crawl.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
