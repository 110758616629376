import React from "react";
import { SetupTestSuite } from "./SetupTestSuite";
import { UpdateTestSuite } from "./UpdateTestSuite";
import { RoleCode } from "../../generated-graphql-interfaces";
import { doesUserMatchRole } from "../../_common/utils/doesUserMatchRole/doesUserMatchRole";
import { NoPermissionMessage } from "./NoPermissionMessage";

export interface TestSuiteProps {
  location: {
    pathname: string;
  };
}

export function InitialiseTestSuite(props: TestSuiteProps) {
  const isCreatingNewTestSuite = props.location.pathname.includes("/setup/new");

  return doesUserMatchRole(RoleCode.Viewer) ? (
    <NoPermissionMessage />
  ) : (
    <>{isCreatingNewTestSuite ? <SetupTestSuite /> : <UpdateTestSuite />}</>
  );
}
