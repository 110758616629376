import gql from "graphql-tag";

export const getTestSuitesBuildQuery = gql`
  query getTestSuiteBuilds(
    $testSuiteId: ObjectID!
    $startDate: DateTime!
    $endDate: DateTime!
    $first: Int!
    $after: String!
    $order: OrderDirection!
  ) {
    node(id: $testSuiteId) {
      ... on TestSuite {
        id
        builds(
          first: $first
          after: $after
          filter: { finishedAt: { ge: $startDate, le: $endDate } }
          orderBy: [{ field: finishedAt, direction: $order }]
        ) {
          totalCount
          edges {
            node {
              passed
              passedTests: testResults(first: 5, filter: { passed: { eq: true } }) {
                totalCount
                edges {
                  node {
                    reportTemplate {
                      name
                    }
                  }
                }
              }
              warnedTests: testResults(first: 5, filter: { passed: { eq: false }, severity: { eq: "Warning" } }) {
                totalCount
                edges {
                  node {
                    reportTemplate {
                      name
                    }
                  }
                }
              }
              failedTests: testResults(first: 5, filter: { passed: { eq: false }, severity: { eq: "Fail" } }) {
                totalCount
                edges {
                  node {
                    reportTemplate {
                      name
                    }
                  }
                }
              }
              status
              id
              finishedAt
              failedTestCount
              passedTestCount
              warnedTestCount
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;
