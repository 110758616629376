import gql from "graphql-tag";

export const getTestSuiteFileUploadsGql = gql`
  query getTestSuiteFileUploads($id: ObjectID!) {
    node(id: $id) {
      ... on TestSuite {
        name
        id
        urlFileUploads(first: 100) {
          nodes {
            fileName
            id
            totalRows
            processingFeedback
          }
        }
      }
    }
  }
`;
