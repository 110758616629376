import React, { useContext } from "react";
import { Box, makeStyles, Divider, List, Fade } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { permissionsContext } from "../../contexts/Permissions/Permissions.context";
import { getLinksFromPermissions } from "./MainNavUtils";
import { NavButton } from "../NavButton/NavButton";
import { getLastSelectedAccount } from "../../utils/LastSelectedAccount/getLastSelectedAccount";
import { DashboardIcon, LinearGradientDashboardIcon } from "../../svg/DashboardIcon";
import { LinearGradientTestSuitesIcon, TestSuitesIcon } from "../../svg/TestSuitesIcon";
import { LinearGradientSchedulerIcon, SchedulerIcon } from "../../svg/SchedulerIcon";
import { ConnectedAppsIcon, LinearGradientConnectedAppsIcon } from "../../svg/ConnectedAppsIcon";
import { GettingStartedIcon, LinearGradientGettingStartedIcon } from "../../svg/GettingStartedIcon";
import { FaqIcon, LinearGradientFaqIcon } from "../../svg/FaqIcon";
import { AboutIcon, LinearGradientAboutIcon } from "../../svg/AboutIcon";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(2, 1, 1, 1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 1, 2)
    }
  },
  listTitle: {
    color: theme.palette.navy[200],
    textTransform: "uppercase",
    fontSize: "0.875rem",
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4)
    }
  },
  divider: {
    backgroundColor: theme.palette.navy[400],
    marginTop: "20px",
    marginBottom: theme.spacing(2)
  }
}));

const useSkeletonStyles = makeStyles(theme => ({
  skeletonLinkIcon: {
    backgroundColor: theme.palette.grey[400],
    marginRight: 16
  },
  skeletonLinkText: {
    backgroundColor: theme.palette.grey[400],
    width: 150
  }
}));

interface IconMap {
  [key: string]: JSX.Element;
}

const IconMap: IconMap = {
  "my-test-suites": <TestSuitesIcon />,
  "connected-apps": <ConnectedAppsIcon viewBox="0 0 25 24" />,
  dashboard: <DashboardIcon />,
  "getting-started": <GettingStartedIcon />,
  "why-automator": <AboutIcon />,
  faqs: <FaqIcon viewBox="0 0 25 24" />,
  "frontend-scheduler": <SchedulerIcon viewBox="0 0 25 24" />
};

const ActiveIconMap: IconMap = {
  "my-test-suites": <LinearGradientTestSuitesIcon />,
  "connected-apps": <LinearGradientConnectedAppsIcon viewBox="0 0 25 24" />,
  dashboard: <LinearGradientDashboardIcon />,
  "getting-started": <LinearGradientGettingStartedIcon />,
  "why-automator": <LinearGradientAboutIcon />,
  faqs: <LinearGradientFaqIcon viewBox="0 0 25 24" />,
  "frontend-scheduler": <LinearGradientSchedulerIcon viewBox="0 0 25 24" />
};

function SkeletonLink() {
  const classes = useSkeletonStyles();
  return (
    <Box marginBottom={1} paddingX={2} display="flex" alignItems="center" height={37} role="progressbar">
      <Skeleton variant="circle" height={24} width={24} classes={{ root: classes.skeletonLinkIcon }} />
      <Skeleton variant="text" height={21} classes={{ root: classes.skeletonLinkText }} />
    </Box>
  );
}

interface MainNavProps {
  closeSideNav: () => void;
  isLoading: boolean;
}

export function MainNav(props: MainNavProps) {
  const history = useHistory();
  const classes = useStyles();
  const { permissions } = useContext(permissionsContext);

  const linksConfig = getLinksFromPermissions(
    getLastSelectedAccount(),
    permissions.jiraIntegrationAccess,
    permissions.frontendSchedulerAccess
  );

  function handleNavButtonClick(href: string) {
    return function () {
      history.push(href);
      props.closeSideNav();
    };
  }

  return (
    <Box data-testid="main-nav-wrapper">
      <List component="nav" aria-label="workspace links" className={classes.list}>
        {props.isLoading ? (
          <>
            <SkeletonLink />
            <SkeletonLink />
            <SkeletonLink />
            <SkeletonLink />
          </>
        ) : (
          linksConfig.workspace.map(link => (
            <NavButton
              key={link.testId}
              onClick={handleNavButtonClick(link.href)}
              label={link.name}
              testId={link.testId}
              icon={IconMap[link.testId]}
              activeIcon={ActiveIconMap[link.testId]}
              href={link.href}
              activePaths={link.activePaths}
            />
          ))
        )}
      </List>
      <Divider className={classes.divider} />
      <span className={classes.listTitle} data-testid="get-started-title">
        Get Started
      </span>
      <List component="nav" aria-label="get started links" className={classes.list}>
        {linksConfig.getStarted.map(link =>
          props.isLoading ? (
            <SkeletonLink key={link.testId} />
          ) : (
            <NavButton
              key={link.testId}
              onClick={handleNavButtonClick(link.href)}
              label={link.name}
              testId={link.testId}
              icon={IconMap[link.testId]}
              activeIcon={ActiveIconMap[link.testId]}
              href={link.href}
              activePaths={link.activePaths}
            />
          )
        )}
      </List>
    </Box>
  );
}
