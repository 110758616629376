import gql from "graphql-tag";

export const updateTestMutationGql = gql`
  mutation updateTest(
    $hasJiraAccess: Boolean!
    $testId: ObjectID!
    $relativeThreshold: Int
    $absoluteThreshold: Int
    $thresholdType: ThresholdType
    $severity: Severity
    $thresholdPredicate: ThresholdPredicate
    $createJiraTicketOnFailure: Boolean
    $jiraTicketCustomNote: String
    $automaticThresholdEnabled: Boolean
  ) {
    updateTest(
      input: {
        testId: $testId
        relativeThreshold: $relativeThreshold
        absoluteThreshold: $absoluteThreshold
        thresholdType: $thresholdType
        severity: $severity
        thresholdPredicate: $thresholdPredicate
        createJiraTicketOnFailure: $createJiraTicketOnFailure
        jiraTicketCustomNote: $jiraTicketCustomNote
        automaticThresholdEnabled: $automaticThresholdEnabled
      }
    ) {
      test {
        id
        relativeThreshold
        absoluteThreshold
        thresholdType
        reportTemplateCode
        severity
        thresholdPredicate
        createJiraTicketOnFailure @include(if: $hasJiraAccess)
        jiraTicketCustomNote @include(if: $hasJiraAccess)
      }
    }
  }
`;
