import React from "react";
import { useBuildTestsQuery } from "./graphql/useBuildTestsQuery";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { buildTestItemInfoFactory } from "./factories/buildTestItemInfoFactory";
import { BuildTestListItem } from "./components/BuildTestListItem";
import { BuildStatus, getBuildTests_node_TestSuite } from "../../generated-graphql-interfaces";
import { CustomBreadcrumbs } from "../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { getBreadcrumbContent } from "./BuildTestListUtils";
import { Alert, Skeleton } from "@material-ui/lab";
import { TestSuiteItemLinks } from "../TestSuiteList/components/TestSuiteItemLinks";
import notFoundGraphic from "../../images/no-permissions-graphic.svg";
import { BuildListTableHeader } from "./BuildListTableHeader";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";

export type BuildTestListProps = RouteComponentProps<{ testSuiteId: string }>;

const useStyles = makeStyles(theme => ({
  editButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  title: {
    fontWeight: 500,
    fontSize: "26px"
  },
  imgWrapper: {
    maxWidth: 160
  },
  img: {
    width: "100%"
  },
  wrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 505,
    margin: "0 auto",
    padding: theme.spacing(8)
  },
  header: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.navy[300]
  },
  headerGrid: {
    marginBottom: 58,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5)
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3)
    }
  }
}));
// eslint-disable-next-line complexity, max-lines-per-function
const BuildTestListContent = withRouter(function BuildTestListContent(props: BuildTestListProps): JSX.Element {
  const testSuiteId = props.match.params.testSuiteId;
  const { loading, data, error } = useBuildTestsQuery({ testSuiteId });
  const classes = useStyles();

  if (loading) {
    return (
      <>
        <Grid
          container
          direction="row"
          data-testid="build-test-list-loader"
          justify="space-between"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item xs={10}>
            <Skeleton variant="text" height={40} width={200} />
          </Grid>

          <Grid item xs={2} className={classes.editButtonWrapper}>
            <Skeleton variant="circle" height={46} width={46} />
          </Grid>
        </Grid>
        <Skeleton variant="rect" height={600} width="100%" />
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="build-test-list-error">
        An error ocurred while loading test suites.
      </Alert>
    );
  }

  if (!data || !(data?.node as getBuildTests_node_TestSuite)?.builds) {
    return (
      <Alert severity="warning" data-testid="build-test-list-warning">
        No data.
      </Alert>
    );
  }

  const testSuite = data.node as getBuildTests_node_TestSuite;
  const buildTests = testSuite.builds.nodes.map(build => buildTestItemInfoFactory(build, testSuite.id));
  const isBuildCurrentlyRunning = !!buildTests.filter(build => build.status === BuildStatus.Running).length;

  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.headerGrid}>
        <Grid item xs={10}>
          <Typography className={classes.title} variant="h1">
            {testSuite.name}
          </Typography>
        </Grid>
        {!isBuildCurrentlyRunning && (
          <Grid item xs={2} className={classes.editButtonWrapper}>
            <Box>
              <TestSuiteItemLinks
                id={testSuiteId}
                name={testSuite.name}
                deletable={false}
                editable={true}
                clonable={false}
                pendoIdSuffix="build-list"
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {buildTests.length > 0 && <BuildListTableHeader />}
      <div>
        {buildTests.length === 0 && (
          <Box className={classes.wrapper} data-testid="build-test-list-info">
            <Box className={classes.imgWrapper}>
              <img
                src={notFoundGraphic}
                alt="404 not found graphic"
                className={classes.img}
                data-testid="not-found-graphic"
              />
            </Box>
            <Typography variant="h2" className={classes.header} color="textPrimary" data-testid="not-found-title">
              No test suite runs found
            </Typography>
          </Box>
        )}
        {buildTests.map(buildTest => {
          return (
            <span key={buildTest.id}>
              <BuildTestListItem {...buildTest} />
            </span>
          );
        })}
      </div>
    </>
  );
});

export function BuildTestList() {
  const accountId = useAccountRouteMatch();

  return (
    <div data-testid="build-test-list">
      <CustomBreadcrumbs content={getBreadcrumbContent(accountId)} />

      <PageContainer>
        <BuildTestListContent />
      </PageContainer>
    </div>
  );
}
