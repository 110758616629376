import React from "react";
import { TestReportList } from "./components/TestSuiteReportList";
import { useTestSuiteReportQuery } from "./queries/useTestSuiteReportQuery";
import { getTestSuiteReport_node_Build } from "../../generated-graphql-interfaces";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import { BreadcrumbPaths, CustomBreadcrumbs } from "../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../_common/routes/routes";

interface TestSuiteReportProps {
  match: {
    params: {
      buildTestId: string;
      testSuiteId: string;
    };
  };
}

const useStyles = makeStyles(theme => ({
  loader: {
    margin: theme.spacing(3.5)
  },
  error: {
    marginTop: theme.spacing(11)
  }
}));

interface TestSuiteReportContainerProps extends TestSuiteReportProps {
  children?: React.ReactNode;
}

const TestSuiteReportContainer = withRouter((props: TestSuiteReportContainerProps) => {
  const accountId = useAccountRouteMatch();

  const breadcrumbContent = [
    { text: BreadcrumbPaths.MyTestSuites, link: Routes.TestSuites.getUrl({ accountId }) },
    {
      text: BreadcrumbPaths.TestSuiteRuns,
      link: Routes.Builds.getUrl({ accountId, testSuiteId: props.match.params.testSuiteId })
    },
    {
      text: BreadcrumbPaths.Results
    }
  ];

  return (
    <>
      <CustomBreadcrumbs content={breadcrumbContent} />
      {props.children ? <PageContainer>{props.children}</PageContainer> : null}
    </>
  );
});

export const TestSuiteReport = withRouter((props: TestSuiteReportProps) => {
  const classes = useStyles();

  const { loading, data } = useTestSuiteReportQuery({
    id: props.match.params.buildTestId
  });

  return (
    <TestSuiteReportContainer>
      {loading && (
        <CircularProgress color="secondary" data-testid="test-suite-report-loader" className={classes.loader} />
      )}

      {!loading && !data?.node && (
        <Alert severity="error" data-testid="test-suite-report-error" className={classes.error}>
          No data found.
        </Alert>
      )}

      {!loading && data?.node && <TestReportList testSuiteReport={data.node as getTestSuiteReport_node_Build} />}
    </TestSuiteReportContainer>
  );
});
