import { useState } from "react";

export function useGradualNumber(
  timeStampOfStart: number,
  maxValue: number,
  addPerSecond: number,
  evaluateEveryMs: number
) {
  const [value, setValue] = useState<number>(0);
  const interval = setInterval(() => {
    evaluate();
  }, evaluateEveryMs);

  function evaluate() {
    const currentTime = new Date().getTime();
    const newValue = Math.floor(Math.min(((currentTime - timeStampOfStart) / 1000) * addPerSecond, maxValue));
    setValue(newValue);
    newValue === maxValue && clearInterval(interval);
  }

  const clear = () => {
    clearTimeout(interval);
  };

  return {
    value: value,
    clear: clear
  };
}
