import { Alert } from "@material-ui/lab";
import { ApolloError } from "apollo-client";
import React from "react";

interface JiraIntegrationDetailErrorsProps {
  loadingError: ApolloError | undefined;
  deleteError: ApolloError | undefined;
  updateError: ApolloError | undefined;
}

export function JiraIntegrationDetailErrors(props: JiraIntegrationDetailErrorsProps) {
  const pleaseRefreshText = "Please refresh the page and try again.";

  return (
    <>
      {props.loadingError && (
        <Alert
          severity="error"
          data-testid="data-loading-error"
        >{`There has been an error loading this integration. ${pleaseRefreshText}`}</Alert>
      )}
      {props.deleteError && (
        <Alert severity="error" data-testid="delete-integration-error">
          {`There was an problem trying to delete this integration. ${pleaseRefreshText}`}
        </Alert>
      )}
      {props.updateError && (
        <Alert severity="error" data-testid="update-integration-error">
          {`There was a problem trying to update this integration. ${pleaseRefreshText}`}
        </Alert>
      )}
    </>
  );
}
