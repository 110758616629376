import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarKey, useSnackbar } from "notistack";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

const useStyles = makeStyles(theme => ({
  snackbar: {
    zIndex: 2000
  },
  alert: {
    border: "1px solid #4C720F",
    borderRadius: 4,
    maxWidth: 600,
    backgroundColor: "white",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5)
  },
  alertTitle: {
    color: theme.palette.navy[500],
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom: theme.spacing(2)
  },
  message: {
    color: theme.palette.navy[400]
  },
  icon: {
    color: "#85AD27",
    fontSize: "28px"
  },
  alertAction: {
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    "& svg": {
      fontSize: "1.5rem",
      color: theme.palette.navy[300]
    }
  }
}));

interface CustomSnackbarProps {
  id: SnackbarKey;
  title: string;
  body: string | JSX.Element;
}

export const CustomSnackbar = React.forwardRef(function CustomSnackbar(
  props: CustomSnackbarProps,
  ref: React.Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  function handleDismiss() {
    closeSnackbar(props.id);
  }

  return (
    <div ref={ref}>
      <Alert
        onClose={handleDismiss}
        severity="success"
        data-testid="custom-snackbar"
        className={classes.alert}
        classes={{ message: classes.message, action: classes.alertAction }}
        iconMapping={{
          success: (
            <CheckCircleOutlineRoundedIcon data-testid="close-alert" fontSize="default" className={classes.icon} />
          )
        }}
      >
        <AlertTitle className={classes.alertTitle} data-testid="custom-snackbar-title">
          {props.title}
        </AlertTitle>
        <div data-testid="custom-snackbar-body">{props.body}</div>
      </Alert>
    </div>
  );
});
