import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const acceptTermsGql = gql`
  mutation acceptTermsAndConditions {
    acceptTermsAndConditions {
      termsAgreed
      id
    }
  }
`;

export function useAcceptTermsAndConditionsMutation() {
  return useMutation(acceptTermsGql, { refetchQueries: ["getUserWithTerms"] });
}
