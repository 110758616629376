import { makeStyles, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ActionIconButton } from "../../../_common/components/ActionIconButton/ActionIconButton";
import { DeleteButtonWithConfirmation } from "../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";
import { ScheduleSettings } from "./ScheduleSettings/ScheduleSettings";
import { BuildScheduleRepetitionRate } from "../../../generated-graphql-interfaces";
import { useGetScheduleForScheduleSettings } from "./graphql/useGetScheduleForScheduleSettings";
import { EditIcon } from "../../../_common/svg/EditIcon";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  headerGrid: {
    padding: theme.spacing(2, 3, 2, 3),
    paddingLeft: "28px",
    paddingRight: "28px",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 3, 1, 3)
    }
  },
  header: {
    paddingTop: theme.spacing(2),
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    display: "block",
    paddingBottom: 5
  },
  icons: {
    marginTop: theme.spacing(0.5)
  }
}));

interface SchedulerDetailViewHeaderProps {
  scheduleName: string;
  scheduleId: string;
  handleEditSchedule: (
    input: {
      accountId?: string | undefined;
      name: string;
      repetitionRate?: BuildScheduleRepetitionRate | undefined | null;
      startAt: Date | undefined;
      buildScheduleId?: string | undefined;
    },
    testSuiteIds: string[]
  ) => Promise<void>;
  handleConfirmScheduleDeletion: () => Promise<void>;
}

export function SchedulerDetailViewHeader(props: SchedulerDetailViewHeaderProps) {
  const classes = useStyles();
  const [isScheduleSettingsOpen, setIsScheduleSettingsOpen] = useState(false);
  const { data, error, loading } = useGetScheduleForScheduleSettings(props.scheduleId);

  const schedule = data?.node?.__typename === "BuildSchedule" ? data.node : null;
  const isEditDisabled = loading || Boolean(error) || !Boolean(schedule);

  return (
    <>
      <Box className={classes.headerGrid}>
        <Typography variant="h3" className={classes.header} data-testid="scheduler-detail-view-name" noWrap>
          {props.scheduleName}
        </Typography>
        <Box className={classes.icons}>
          <ActionIconButton
            disabled={isEditDisabled}
            disableForViewer
            tooltipTitle="Edit schedule"
            onClick={() => setIsScheduleSettingsOpen(true)}
            data-testid="edit-schedule"
            data-cy="action-icon-button"
            data-pendo="auto-scheduler-detail-edit"
            variant="info"
            icon={<EditIcon />}
            includeBorder
          />
          <DeleteButtonWithConfirmation
            disableForViewer
            tooltipTitle="Delete schedule"
            dataTestId="delete-schedule"
            data-pendo={`auto-scheduler-detail-delete-button`}
            confirmationTitle="Are you sure you want to delete this scheduled run permanently?"
            confirmationText="This cannot be undone. This will stop all test suites from running in this scheduled test suite run but they will still be running in the CI CD pipeline."
            handleConfirmation={props.handleConfirmScheduleDeletion}
          />
        </Box>
      </Box>
      {schedule && (
        <ScheduleSettings
          isOpen={isScheduleSettingsOpen}
          handleClose={() => setIsScheduleSettingsOpen(false)}
          handleSave={props.handleEditSchedule}
          schedule={schedule}
        />
      )}
    </>
  );
}
