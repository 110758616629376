import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  getJiraIntegrationsWithTestSuites,
  getJiraIntegrationsWithTestSuitesVariables,
  getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations
} from "../../../../generated-graphql-interfaces";
import { JiraIntegration } from "./useGetJiraIntegrationsQuery";

export const getJiraIntegrationsWithTestSuitesGql = gql`
  query getJiraIntegrationsWithTestSuites($accountId: ObjectID!) {
    getAccount(id: $accountId) {
      id
      jiraAuthentications {
        nodes {
          id
          name
          jiraIntegrations {
            totalCount
            nodes {
              id
              name
              jiraUrl
              jiraProjectId
              jiraIssueTypeId
              jiraProjectName
              jiraIssueTypeName
              testSuiteJiraIntegrations {
                totalCount
                nodes {
                  id
                  testSuite {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type JiraIntegrationWithTestSuites = JiraIntegration & {
  testSuiteJiraIntegrations: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations;
};

export function useGetJiraIntegrationsWithTestSuitesQuery(variables: getJiraIntegrationsWithTestSuitesVariables) {
  const { data, error, loading, refetch } = useQuery<
    getJiraIntegrationsWithTestSuites,
    getJiraIntegrationsWithTestSuitesVariables
  >(getJiraIntegrationsWithTestSuitesGql, {
    variables,
    fetchPolicy: "cache-first"
  });

  const jiraIntegrations = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(jiraAuthentication =>
      jiraAuthentication.jiraIntegrations.nodes.map(jiraIntegration => ({
        jiraAuthenticationId: jiraAuthentication.id,
        jiraAuthenticationName: jiraAuthentication.name,
        ...jiraIntegration
      }))
    ) ?? [])
  ];

  const totalCount = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      jiraAuthenticationNode => jiraAuthenticationNode.jiraIntegrations.totalCount
    ) ?? [])
  ].reduce((acc, curr) => acc + curr, 0);

  return {
    data: { jiraIntegrations, totalCount },
    error,
    loading,
    refetch
  };
}
