import { ResponsiveLine, SliceTooltipProps } from "@nivo/line";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Paper, makeStyles } from "@material-ui/core";
import {
  getProcessedChartData,
  getLabelFromId,
  getMinMax,
  getD3Ticks,
  getAxisXDateFormat,
  getD3TimeTicks
} from "../utils/LineChartUtils";

const useStyles = makeStyles(theme => ({
  chartContainer: {
    margin: "30px 0px 0px 0px",
    height: "400px",
    [theme.breakpoints.up("sm")]: {
      margin: "50px 0px 0px 0px",
      height: "400px"
    },
    [theme.breakpoints.up("md")]: {
      margin: "50px 0px 0px 0px"
    },
    [theme.breakpoints.up("lg")]: {
      margin: "50px 100px 0px 0px"
    }
  },
  tooltip: {
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    padding: theme.spacing(2)
  },
  tooltipColor: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  tooltipValue: { marginRight: theme.spacing(2) },
  tooltipTimestamp: { fontSize: "0.8rem", color: theme.palette.grey[800] },
  tooltipRow: {
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "left",
    flexDirection: "row"
  }
}));

export interface LineChartLineData {
  id: string;
  color: string;
  data: Array<{
    x: number | string | Date;
    y: number | null;
    details?: string[];
  }>;
}

export interface LineChartProps {
  data: LineChartLineData[];
}

export interface LegendVisibility {
  [key: string]: boolean;
}

// eslint-disable-next-line max-lines-per-function, max-statements
export function LineChart(props: LineChartProps) {
  const defaultTicksCount = 15;
  const tickSpaceInPixelsRequired = 70;
  function getTicksCount() {
    const chartWrapperBoundingBoxWidth = document.getElementById("chart-wrapper")?.clientWidth;
    return chartWrapperBoundingBoxWidth
      ? Math.round(chartWrapperBoundingBoxWidth / tickSpaceInPixelsRequired)
      : defaultTicksCount;
  }

  const classes = useStyles();
  const [timeTicksCount, setTimeTicksCount] = useState(getTicksCount());
  const processedData = getProcessedChartData(props.data);
  const dataToDisplay = processedData;
  const minMax = getMinMax(dataToDisplay);
  const ticks = getD3Ticks(minMax, 15);
  const timeTicks = getD3TimeTicks(processedData, getTicksCount() || timeTicksCount);

  useEffect(() => {
    function handleResize() {
      setTimeTicksCount(getTicksCount());
    }
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getSlicedTooltip(props: SliceTooltipProps): JSX.Element {
    const { slice } = props;
    const points = slice.points.map(point => {
      return (
        <div key={point.id + Math.random()} className={classes.tooltipRow}>
          <div
            style={{
              backgroundColor: point.color
            }}
            className={classes.tooltipColor}
          ></div>
          <b className={classes.tooltipValue}>{`${point.data.y} ${getLabelFromId(point.id)}`}</b>
        </div>
      );
    });
    return (
      <Paper className={classes.tooltip}>
        {points}
        <div className={classes.tooltipTimestamp}>
          {format(new Date(slice.points[0].data.xFormatted), "MMM dd, yyyy h:mmaaaaa'm'")}
        </div>
      </Paper>
    );
  }

  const axisLineFormat = getAxisXDateFormat(dataToDisplay);

  return (
    <div className={classes.chartContainer} id="chart-wrapper">
      <ResponsiveLine
        data={dataToDisplay}
        margin={{ top: 10, right: 20, bottom: 90, left: 20 }}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
          stacked: false,
          reverse: false
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d %H:%M",
          useUTC: false,
          precision: "minute"
        }}
        xFormat="time:%Y-%m-%d %H:%M"
        enableGridX={false}
        gridXValues={timeTicks.slice(1, timeTicks.length - 1)}
        enableGridY={true}
        gridYValues={ticks.slice(0, ticks.length)}
        axisBottom={{
          format: axisLineFormat,
          tickSize: 0,
          tickPadding: 10,
          tickValues: timeTicks
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickValues: ticks
        }}
        colors={{ datum: "color" }}
        curve="monotoneX"
        pointSize={15}
        pointColor={{ from: "color", modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={getSlicedTooltip}
      />
    </div>
  );
}
