import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Box, InputAdornment, IconButton, TextField } from "@material-ui/core";
import useDebounce from "../../hooks/useDebounce/UseDebounce";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ChooseTestsDispatch } from "../../contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";

export interface SearchFieldProps {
  debounce?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      width: "100%",
      padding: "5px 0",
      "&::before": {
        content: "",
        display: "none"
      },
      "& fieldset": {
        borderColor: theme.palette.navy[500]
      },
      "& div": {
        height: 48
      },
      "& input::placeholder": {
        color: theme.palette.navy[300],
        opacity: 1
      }
    },
    searchIcon: {
      paddingLeft: "4px",
      paddingRight: "4px",
      color: theme.palette.navy[500]
    },
    disabledSearchIcon: {
      color: `${theme.palette.navy[500]} !important`
    }
  })
);

function SearchField(props: SearchFieldProps) {
  const classes = useStyles();
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);

  const DEFAULT_SEARCH_DEBOUNCE = 250;
  const usedDebounce = props.debounce
    ? Math.max(props.debounce, 0) || DEFAULT_SEARCH_DEBOUNCE
    : DEFAULT_SEARCH_DEBOUNCE;
  const [searchText, setSearchText] = useState("");

  const debouncedSearchTerm = useDebounce(searchText, usedDebounce);

  useEffect(() => {
    chooseTestsFormDispatch({ type: "UPDATE_SEARCH_TEXT", payload: { searchText: debouncedSearchTerm } });
  }, [debouncedSearchTerm, props]);

  function updateSearch(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchText(event.target.value);
  }

  return (
    <Box>
      <TextField
        variant="outlined"
        className={classes.searchField}
        id="filled-search"
        data-testid="search-input"
        data-cy="find-test-input"
        data-pendo="auto-test-suite-edit-choose-tests-search-field"
        value={searchText}
        onChange={updateSearch}
        placeholder="Find a test"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                disabled
                aria-label="clear search"
                className={classes.searchIcon}
                classes={{ disabled: classes.disabledSearchIcon }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="search-input-clear"
                aria-label="clear search"
                onClick={() => {
                  setSearchText("");
                }}
              >
                {searchText.length ? <ClearIcon /> : <></>}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default SearchField;
