import { withStyles, Button } from "@material-ui/core";

export const GreyButton = withStyles(theme => ({
  root: {
    background: theme.palette.grey[100],
    fontSize: 14,
    fontWeight: 600,
    height: 36,
    "&:hover": {
      background: theme.palette.grey[200]
    }
  }
}))(Button);
