import React from "react";
import { BuildInfo } from "../Dashboard";
import { formatDistance } from "date-fns";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import { dateTimeToHumanReadableFormat } from "../../../_common/utils/date/date";
import { BuildStatus } from "../../../generated-graphql-interfaces";
import { Link } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { BuildListItemContent } from "./BuildListItemContent";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";
import clsx from "clsx";

interface BuildListItemProps {
  buildInfo: BuildInfo;
  accountId: string;
  isFirstItem: boolean;
}

const useStyles = makeStyles(theme => ({
  overflowText: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  bold: {
    fontWeight: "bold"
  },
  link: { textDecoration: "none" },
  elapsedMessage: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.navy[300]
  },
  timestamp: { fontWeight: 500 }
}));

export function BuildListItem(props: BuildListItemProps) {
  const { buildInfo } = props;
  const classes = useStyles(props);

  function getTimeElapsedMessage(): string {
    const message = `${formatDistance(new Date(buildInfo.finishedAt || buildInfo.createdAt), new Date())} ago`;
    return message[0].toUpperCase() + message.substring(1);
  }

  const isBuildFinished = buildInfo.status === BuildStatus.Finished;
  const editTestSuiteBuildURL = Routes.TestResults.getUrl({
    accountId: props.accountId,
    testSuiteId: buildInfo.testSuiteId,
    buildTestId: buildInfo.id,
    selectedType: TestsToDisplayOption.ALL
  });

  return (
    <Grid container key={buildInfo.id} data-testid="build-list-item" direction="row" alignItems="center">
      <Grid item xs={3}>
        <Box pr={2} className={classes.overflowText} textAlign="right">
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.elapsedMessage}
            data-testid="build-list-item-time-description"
          >
            {getTimeElapsedMessage()}
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            data-testid="build-list-item-timestamp"
            className={clsx(classes.elapsedMessage, classes.timestamp)}
          >
            {dateTimeToHumanReadableFormat(new Date(buildInfo.finishedAt || buildInfo.createdAt), "N/A")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={9}>
        {isBuildFinished ? (
          <Link to={editTestSuiteBuildURL} className={classes.link} data-testid="build-list-item-link">
            <BuildListItemContent buildInfo={buildInfo} isFirstItem={props.isFirstItem} />
          </Link>
        ) : (
          <BuildListItemContent buildInfo={buildInfo} isFirstItem={props.isFirstItem} />
        )}
      </Grid>
    </Grid>
  );
}
