import gql from "graphql-tag";

export const getReportsTemplatesQuery = gql`
  query getReportTemplates($after: String!) {
    getAutomatorReportTemplates(first: 100, after: $after, orderBy: [{ field: id, direction: ASC }]) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          categories
          name
          code
          automatorSummary
        }
      }
    }
  }
`;
