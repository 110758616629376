import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import automatorLogo from "../../../images/logo_automation_hub_grey.svg";
import deepCrawlLogo from "../../../images/logo_deepcrawl_grey.svg";

const useStyles = makeStyles(theme => ({
  footer: {
    height: "64px",
    backgroundColor: theme.palette.navy[50],
    marginTop: "50px",
    padding: theme.spacing(0, 3.5),
    [theme.breakpoints.down("xs")]: {
      maxHeight: "134px",
      padding: theme.spacing(5, 2)
    }
  },
  deepcrawlAtYear: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    fontSize: "0.75rem"
  },
  grid: { minHeight: "64px" },
  socialGridItem: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end"
    }
  },
  copyrightGridItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing(3)
    }
  },
  svg: {
    "&:hover path:nth-of-type(1)": {
      stroke: theme.palette.text.primary
    },
    "&:hover path:nth-of-type(2)": {
      fill: theme.palette.text.primary
    }
  },
  socialLink: {
    height: "29px",
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginLeft: theme.spacing(1)
    }
  },
  logo: {
    width: "30px",
    display: "block"
  },
  deepcrawlLogo: {
    width: "100px",
    marginLeft: theme.spacing(1)
  },
  textLink: {
    color: theme.palette.navy[300],
    fontSize: "0.75rem",
    textDecoration: "none",
    marginRight: theme.spacing(5),
    textAlign: "center",
    "&:hover": {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2)
    }
  }
}));

export function Footer(): JSX.Element {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container alignItems="center" className={classes.grid}>
        <Grid item lg={5} md={2} sm={2} xs={6}>
          <img src={automatorLogo} alt="DeepCrawl Automator" className={classes.logo} data-testid="footer-logo" />
        </Grid>
        <Grid item lg={2} md={5} sm={5} xs={6} className={classes.socialGridItem}>
          <a
            href="https://facebook.com/deepcrawlHD"
            target="_blank"
            data-testid="link-facebook"
            data-pendo="auto-footer-facebook-link"
            rel="noopener noreferrer"
            className={classes.socialLink}
          >
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.svg}
            >
              <path
                d="M27.3826 14.0581C27.3826 21.4203 21.4144 27.3885 14.0522 27.3885C6.69009 27.3885 0.721889 21.4203 0.721889 14.0581C0.721889 6.69598 6.69009 0.727779 14.0522 0.727779C21.4144 0.727779 27.3826 6.69598 27.3826 14.0581Z"
                stroke="#667078"
                strokeWidth="1.33929"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8892 22.3308H11.7853V15.0117H10.2344V12.4898H11.7853V10.9757C11.7853 8.91844 12.6606 7.69443 15.149 7.69443H17.2201V10.2173H15.9257C14.957 10.2173 14.893 10.5697 14.893 11.2274L14.8887 12.4898H17.2344L16.9598 15.0117H14.8887V22.3308H14.8892Z"
                fill="#667078"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/DeepCrawl"
            target="_blank"
            data-testid="link-twitter"
            data-pendo="auto-footer-twitter-link"
            rel="noopener noreferrer"
            className={classes.socialLink}
          >
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.svg}
            >
              <path
                d="M27.847 14.0581C27.847 21.4203 21.8788 27.3885 14.5166 27.3885C7.15445 27.3885 1.18624 21.4203 1.18624 14.0581C1.18624 6.69598 7.15445 0.727779 14.5166 0.727779C21.8788 0.727779 27.847 6.69598 27.847 14.0581Z"
                stroke="#667078"
                strokeWidth="1.33929"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9422 10.7781C20.5085 10.4208 20.9431 9.85407 21.147 9.17901C20.6168 9.51075 20.0308 9.75066 19.4057 9.88054C18.9068 9.31799 18.1938 8.96722 17.4047 8.96722C15.8908 8.96722 14.664 10.2619 14.664 11.8586C14.664 12.0852 14.6868 12.3061 14.7339 12.5171C12.456 12.3963 10.4362 11.2464 9.08312 9.49586C8.84702 9.92439 8.7121 10.4208 8.7121 10.9502C8.7121 11.9529 9.19607 12.8381 9.93183 13.3576C9.48237 13.3427 9.05959 13.2112 8.68935 12.9961V13.0317C8.68935 14.4331 9.63376 15.602 10.8896 15.8668C10.659 15.9346 10.4174 15.9685 10.1664 15.9685C9.98987 15.9685 9.81731 15.9511 9.65102 15.9172C9.99928 17.0655 11.0119 17.9027 12.212 17.925C11.2731 18.701 10.0911 19.1626 8.80701 19.1626C8.58581 19.1626 8.36697 19.1502 8.15283 19.1229C9.36628 19.9428 10.8072 20.4218 12.3556 20.4218C17.3992 20.4218 20.1556 16.0157 20.1556 12.1945C20.1556 12.0687 20.154 11.9438 20.1485 11.8205C20.6842 11.4127 21.1502 10.9031 21.5165 10.3231C21.0247 10.5531 20.496 10.7087 19.9422 10.7781Z"
                fill="#667078"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/deepcrawl"
            target="_blank"
            data-testid="link-linkedin"
            data-pendo="auto-footer-linkedin-link"
            rel="noopener noreferrer"
            className={classes.socialLink}
          >
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.svg}
            >
              <path
                d="M27.9182 14.0581C27.9182 21.4203 21.95 27.3885 14.5879 27.3885C7.22574 27.3885 1.25753 21.4203 1.25753 14.0581C1.25753 6.69598 7.22574 0.727779 14.5879 0.727779C21.95 0.727779 27.9182 6.69598 27.9182 14.0581Z"
                stroke="#667078"
                strokeWidth="1.33929"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.54528 11.776H11.0259V20.4214H8.54528V11.776ZM9.72224 10.6941H9.70428C8.80631 10.6941 8.22412 10.0332 8.22412 9.19631C8.22412 8.34228 8.82352 7.69443 9.73945 7.69443C10.6546 7.69443 11.2174 8.34065 11.2353 9.19386C11.2353 10.0308 10.6546 10.6941 9.72224 10.6941ZM20.9515 20.4218H18.1386V15.9471C18.1386 14.7761 17.6986 13.9775 16.731 13.9775C15.9909 13.9775 15.5793 14.5161 15.3878 15.0368C15.3159 15.2226 15.3272 15.4826 15.3272 15.7434V20.4218H12.5404C12.5404 20.4218 12.5764 12.4959 12.5404 11.7756H15.3272V13.1324C15.4918 12.5391 16.3823 11.6924 17.8033 11.6924C19.5663 11.6924 20.9515 12.9368 20.9515 15.6138V20.4218Z"
                fill="#667078"
              />
            </svg>
          </a>
          <a
            href="mailto:info@deepcrawl.com"
            target="_blank"
            data-testid="link-email"
            data-pendo="auto-footer-email-link"
            rel="noopener noreferrer"
            className={classes.socialLink}
          >
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.svg}
            >
              <path
                d="M28.3113 14.0581C28.3113 21.4203 22.3431 27.3885 14.981 27.3885C7.6188 27.3885 1.6506 21.4203 1.6506 14.0581C1.6506 6.69598 7.6188 0.727779 14.981 0.727779C22.3431 0.727779 28.3113 6.69598 28.3113 14.0581Z"
                stroke="#667078"
                strokeWidth="1.33929"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9267 13.1362C10.0361 12.565 9.50226 12.2214 9.32515 12.1057C9.00229 11.9033 8.69755 11.6248 8.41112 11.2705C8.1247 10.9162 7.98144 10.5871 7.98144 10.2833C7.98144 9.90735 8.08951 9.59395 8.30564 9.3432C8.52178 9.09255 8.83035 8.96722 9.23137 8.96722H20.7314C21.0698 8.96722 21.3628 9.08048 21.6102 9.30708C21.8576 9.53368 21.9814 9.80608 21.9814 10.1243C21.9814 10.5051 21.8539 10.8691 21.5985 11.2161C21.3433 11.5632 21.0257 11.8598 20.6455 12.1055C18.6872 13.3639 17.4684 14.1472 16.9893 14.4558C16.9372 14.4896 16.8265 14.563 16.6572 14.6763C16.488 14.7897 16.3474 14.8814 16.2354 14.9512C16.1234 15.0211 15.988 15.0994 15.8292 15.1862C15.6704 15.2728 15.5205 15.338 15.38 15.3813C15.2393 15.4247 15.1091 15.4463 14.9893 15.4463H14.9814H14.9736C14.8538 15.4463 14.7236 15.4247 14.5829 15.3813C14.4423 15.338 14.2926 15.2729 14.1337 15.1862C13.9748 15.0994 13.8394 15.0211 13.7274 14.9512C13.6155 14.8814 13.4748 14.7897 13.3056 14.6763C13.1363 14.5631 13.0257 14.4896 12.9736 14.4558C12.4996 14.1473 11.8173 13.7074 10.9267 13.1362ZM17.3092 15.3744C18.1944 14.7814 19.4914 13.9498 21.1996 12.8796C21.4966 12.6916 21.757 12.4818 21.981 12.2505V17.9921C21.981 18.3104 21.8587 18.5826 21.6138 18.8093C21.369 19.036 21.0747 19.1492 20.731 19.1492H9.23098C8.88722 19.1492 8.59293 19.036 8.34812 18.8093C8.10331 18.5827 7.98096 18.3104 7.98096 17.9921V12.2505C8.21014 12.4867 8.47316 12.6965 8.77004 12.8796C10.6554 14.0655 11.9497 14.8972 12.6528 15.3744C12.9497 15.5769 13.1906 15.7349 13.3755 15.848C13.5603 15.9614 13.8065 16.077 14.1137 16.1952C14.421 16.3133 14.7075 16.3723 14.9731 16.3723H14.981H14.9889C15.2545 16.3723 15.541 16.3133 15.8482 16.1952C16.1555 16.077 16.4016 15.9614 16.5864 15.848C16.7714 15.7349 17.0123 15.5769 17.3092 15.3744Z"
                fill="#667078"
              />
            </svg>
          </a>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12} className={classes.copyrightGridItem}>
          <a
            href="https://www.deepcrawl.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.textLink}
            data-testid="privacy-policy-link"
            data-pendo="auto-footer-privacy-policy-link"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.deepcrawl.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.textLink}
            data-testid="terms-of-service-link"
            data-pendo="auto-footer-terms-of-service-link"
          >
            Terms of Service
          </a>
          <span data-testid="copyright-year" className={classes.deepcrawlAtYear}>
            &#169;{`${new Date().getFullYear()}`}
            <img src={deepCrawlLogo} alt="DeepCrawl Automator" className={classes.deepcrawlLogo} />
          </span>
        </Grid>
      </Grid>
    </footer>
  );
}
