import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { JiraAuthenticationList } from "./JiraAuthenticationList";
import { CreateJiraAuthenticationForm, ICreateJiraAuthenticationPayload } from "./CreateJiraAuthenticationForm";
import { CreateJiraIntegrationForm } from "./CreateJiraIntegrationForm";
import { Alert } from "@material-ui/lab";

interface ConfigWizardProps {
  accountId: string;
  integrationName: string;
  onComplete: () => void;
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
    textAlign: "center"
  }
}));

interface IJiraAuthentication {
  id: string;
  jiraUrl: string;
  name: string;
}

// eslint-disable-next-line complexity
export function IntegrationSetupWizard(props: ConfigWizardProps) {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [jiraAuthentication, setJiraAuthentication] = useState<IJiraAuthentication | null>(null);
  const [jiraAuthenticationName, setJiraAuthenticationName] = useState("");
  const [creatingJiraAuthentication, setCreatingJiraAuthentication] = useState(false);

  function handleAuthenticationCreationSuccess(payload: ICreateJiraAuthenticationPayload): void {
    setJiraAuthentication({
      name: jiraAuthenticationName,
      id: payload.jiraAuthenticationId,
      jiraUrl: payload.jiraUrl
    });
  }

  function handleAuthenticationCreationError(payload: ICreateJiraAuthenticationPayload): void {
    setError(true);
    setJiraAuthentication(null);
  }

  function handleIntegrationCreationSuccess(): void {
    props.onComplete();
  }

  function handleIntegrationCreationError(error: Error): void {
    setError(true);
  }

  return (
    <div data-testid="jira-integration-setup-wizard">
      <Typography variant="h2" className={classes.title} data-testid="inegration-name">
        {props.integrationName}
      </Typography>

      {error && (
        <Alert severity="error" data-testid="jira-config-wizard-dialog-error">
          There was an error connecting. Please refresh the page and try again.
        </Alert>
      )}

      {!creatingJiraAuthentication && !jiraAuthentication && !error && (
        <JiraAuthenticationList
          accountId={props.accountId}
          onSelect={jiraAuthentication => setJiraAuthentication(jiraAuthentication)}
          createHandler={() => setCreatingJiraAuthentication(true)}
        />
      )}

      {creatingJiraAuthentication && !jiraAuthentication && !error && (
        <CreateJiraAuthenticationForm
          accountId={props.accountId}
          name={jiraAuthenticationName}
          onError={handleAuthenticationCreationError}
          onSuccess={handleAuthenticationCreationSuccess}
          onComplete={() => setCreatingJiraAuthentication(false)}
          onChange={e => setJiraAuthenticationName(e.currentTarget.value)}
        />
      )}

      {!creatingJiraAuthentication && !!jiraAuthentication && !error && (
        <CreateJiraIntegrationForm
          integrationName={props.integrationName}
          jiraAuthentication={jiraAuthentication}
          onError={handleIntegrationCreationError}
          onSuccess={handleIntegrationCreationSuccess}
        />
      )}
    </div>
  );
}
