import React from "react";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { StepIntoIcon } from "../../../_common/components/StepIntoIcon/StepIntoIcon";
import { Paper, Grid, makeStyles, Typography, Box } from "@material-ui/core";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { isReportReady } from "./BuildTestListItemUtils";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import { ActionIconButton } from "../../../_common/components/ActionIconButton/ActionIconButton";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";
import { SchedulerTextOptions } from "../factories/buildTestItemInfoFactory";
import { DCChip, DCChipType } from "../../../_common/components/Tag/DCChip";
import { TestCountChips } from "../../TestSuiteList/components/TestCountChips";
import { createLastRunText } from "../../../_common/utils/createLastRunText/createLastRunText";
import { BuildStatus } from "../../../generated-graphql-interfaces";

export interface BuildTestListItemProps {
  id: string;
  buildId: string;
  testSuiteId: string;
  status: BuildTestStatus;
  lastRun: string;
  duration: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
}

const useStyles = makeStyles(theme => ({
  root: (props: BuildTestListItemProps) => {
    return {
      background: isReportReady(props.status) ? "white" : "#F7F8F8",
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      padding: theme.spacing(3, 1, 3, 3),
      borderRadius: "2px",
      border: `1px solid ${theme.palette.navy[200]}`,
      borderLeft: `6px solid ${getMatchingBorderColorForStatus(props.status, theme)}`,
      "&:hover": isReportReady(props.status)
        ? {
            boxShadow: theme.shadows[2],
            cursor: "pointer"
          }
        : undefined,
      [theme.breakpoints.down("md")]: {
        alignItems: "start",
        padding: theme.spacing(2, 1, 2, 2)
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1)
      }
    };
  },
  chipsWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      marginTop: theme.spacing(2)
    }
  },
  durationText: {
    color: theme.palette.navy[300],
    fontWeight: 400,
    paddingRight: theme.spacing(1)
  },
  iconWrapper: {
    minWidth: 70,
    paddingRight: theme.spacing(2)
  },
  endIconsWrapper: {
    minWidth: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      minWidth: 100
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      flexDirection: "column"
    }
  },
  hidableButton: {
    alignItems: "center",
    "& button": {
      opacity: 0,
      transition: "opacity 0.1s ease-in-out"
    },
    "& p": {
      opacity: 1,
      transition: "opacity 0.1s ease-in-out"
    },
    "&:hover button": {
      opacity: 1
    },
    "&:hover p": {
      opacity: 0
    }
  },
  floatingBox: {
    position: "absolute"
  },
  buttonCopy: {
    height: "28px"
  }
}));

export interface BuildTestListItemProps {
  id: string;
  buildId: string;
  testSuiteId: string;
  status: BuildTestStatus;
  lastRun: string;
  duration: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  triggerType: SchedulerTextOptions;
  updatedAt: string;
}

// eslint-disable-next-line max-lines-per-function
export function BuildTestListItem(props: BuildTestListItemProps) {
  const classes = useStyles(props);
  const accountId = useAccountRouteMatch();
  const history = useHistory();
  const testResultsPath = Routes.TestResults.getUrl({
    accountId,
    testSuiteId: props.testSuiteId,
    buildTestId: props.id,
    selectedType: TestsToDisplayOption.ALL
  });
  const isCancelledOrAborted = props.status === BuildStatus.Aborted || props.status === BuildStatus.Cancelled;

  function handleLinkClick(event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (event.isDefaultPrevented() || !isReportReady(props.status)) return;
    history.push(testResultsPath);
  }

  function openInNewWindow() {
    window.open(testResultsPath);
  }

  return (
    <Paper
      className={classes.root}
      onClick={handleLinkClick}
      role="link"
      elevation={0}
      data-cyalso={`build-test-list-item-${props.status.toLocaleLowerCase()}`}
      data-cy="build-test-list-item"
      data-testid="build-test-list-item"
      data-pendo={`build-test-list-item-${props.status.toLocaleLowerCase()}`}
    >
      <Box className={classes.iconWrapper}>
        <BuildStatusIcon status={props.status} size={StatusIconSize.LG} />
      </Box>
      <Grid container alignItems="center">
        <Grid item lg={2} md={12} xs={12}>
          <Typography data-testid="last-run-timestamp" data-cy="last-run-timestamp" className={classes.durationText}>
            {createLastRunText(props.status, props.updatedAt, props.lastRun)}
          </Typography>
        </Grid>
        <Grid item lg={2} md={12} xs={12}>
          {!isCancelledOrAborted && (
            <Typography data-testid="last-run-duration" data-cy="last-run-duration" className={classes.durationText}>
              {props.duration}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={8} sm={10} xs={12} className={classes.chipsWrapper}>
          <TestCountChips
            buildId={props.id}
            testSuiteId={props.testSuiteId}
            passedTests={props.passedTests}
            warningTests={props.warningTests}
            failedTests={props.failedTests}
            totalTests={props.totalTests}
            status={props.status}
            pendoIdSuffix="build-list"
            showLabel={true}
          />
        </Grid>
        <Grid item lg={3} md={12} xs={12} className={classes.chipsWrapper}>
          <DCChip
            text={props.triggerType}
            type={props.triggerType === SchedulerTextOptions.SCHEDULED ? DCChipType.DEFAULT : DCChipType.INFO}
          />
        </Grid>
      </Grid>
      <Box className={classes.endIconsWrapper}>
        {isReportReady(props.status) && (
          <>
            <ActionIconButton
              tooltipTitle="Open test results in new tab"
              data-testid="open-test-results-new-tab"
              data-cy="open-test-results-new-tab"
              data-pendo="auto-testsuiteslist-item-result-new-tab"
              onClick={e => {
                e.stopPropagation();
                openInNewWindow();
              }}
              icon={<OpenInNewRoundedIcon />}
              variant="info"
            />
            <StepIntoIcon linkTo={testResultsPath} isLinkExternal={false} data-pendo="auto-buildlist-item-step-into" />
          </>
        )}
      </Box>
    </Paper>
  );
}
