import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { setBuildScheduleTestSuites, setBuildScheduleTestSuitesVariables } from "../../../generated-graphql-interfaces";

export const setBuildScheduleTestSuitesGql = gql`
  mutation setBuildScheduleTestSuites($buildScheduleId: ObjectID!, $testSuiteIds: [ObjectID!]!) {
    setBuildScheduleTestSuites(input: { buildScheduleId: $buildScheduleId, testSuiteIds: $testSuiteIds }) {
      buildScheduleTestSuites {
        id
      }
    }
  }
`;

export function useSetBuildScheduleTestSuitesMutation() {
  return useMutation<setBuildScheduleTestSuites, setBuildScheduleTestSuitesVariables>(setBuildScheduleTestSuitesGql, {
    refetchQueries: ["getScheduleForScheduleSettings"]
  });
}
