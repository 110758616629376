import React, { useRef } from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  iconButton: {
    borderRadius: 3,
    padding: 9,
    border: `1px solid ${theme.palette.grey[400]}`,
    "&:hover": {
      boxShadow: "0px 0.772933px 6.9564px rgba(0, 0, 0, 0.12), 0px 2.3188px 6.18347px rgba(0, 0, 0, 0.08)"
    }
  },
  confirmButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  checkIcon: {
    color: "#FFF"
  },
  cancelIcon: {
    color: theme.palette.navy[300]
  }
}));

interface ConfirmOrDeleteProps {
  handleConfirm: () => void;
  handleCancel: () => void;
  dataTestId: string;
  confirmButtonRef?: React.MutableRefObject<null>;
  cancelButtonRef?: React.MutableRefObject<null>;
}

export function ConfirmOrDelete(props: ConfirmOrDeleteProps) {
  const classes = useStyles();

  return (
    <>
      <IconButton
        ref={props.confirmButtonRef}
        className={clsx(classes.iconButton, classes.confirmButton)}
        aria-label="confirm"
        type="button"
        data-testid={`confirm-${props.dataTestId}`}
        data-pendo={`auto-confirm-${props.dataTestId}-btn`}
        id={`confirm-${props.dataTestId}`}
        onClick={props.handleConfirm}
      >
        <CheckRoundedIcon className={classes.checkIcon} />
      </IconButton>
      <IconButton
        ref={props.cancelButtonRef}
        className={classes.iconButton}
        aria-label="cancel"
        type="button"
        data-testid={`cancel-${props.dataTestId}`}
        id={`cancel-${props.dataTestId}`}
        onClick={props.handleCancel}
      >
        <ClearRoundedIcon className={classes.cancelIcon} />
      </IconButton>
    </>
  );
}
