import React, { MouseEvent } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Popover, Paper, Grid, Backdrop, Box } from "@material-ui/core";
import clsx from "clsx";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

export enum PopoverDialogDefaultIdentifiers {
  CANCEL = "cancel",
  OK = "ok"
}
export type PopoverDialogCloseIdentifier =
  | PopoverDialogDefaultIdentifiers.CANCEL
  | PopoverDialogDefaultIdentifiers.OK
  | string;

export interface PopoverDialogButton {
  label: string;
  identifier: PopoverDialogCloseIdentifier;
  color: "primary" | "secondary" | "alert" | "info";
}

export interface PopoverDialogProps {
  handleAction: (id: PopoverDialogCloseIdentifier) => void;
  open: boolean;
  text: string;
  additionalText?: string;
  title: string;
  buttons: PopoverDialogButton[];
  id: string | undefined;
  anchorElement: Element | null;
  centered?: boolean;
  icon?: JSX.Element;
}

const useStyles = makeStyles(theme => ({
  popover: {
    color: theme.palette.text.primary,
    borderRadius: "3px"
  },
  centered: {
    "& .MuiPopover-paper": {
      top: "30% !important"
    }
  },
  popoverPaper: {
    padding: theme.spacing(4, 3, 2.5, 3),
    maxWidth: "450px"
  },
  title: {
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "1.4",
    marginBottom: theme.spacing(2)
  },
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px"
  },
  button: {
    marginLeft: theme.spacing(2),
    lineHeight: 1,
    height: 36
  },
  alertButton: {
    backgroundColor: theme.palette.error.main,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#9F0000",
      color: "#FFFFFF"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.3)"
  },
  withInfoIconBox: {
    display: "flex"
  },
  infoIconBox: {
    marginRight: theme.spacing(3)
  },
  buttonWrapper: {
    marginTop: theme.spacing(5.5)
  }
}));

export default function PopoverDialog(props: PopoverDialogProps) {
  const classes = useStyles();

  function handleClick(event: MouseEvent<Element, MouseEvent>, action: PopoverDialogDefaultIdentifiers) {
    event.stopPropagation();
    props.handleAction(action);
  }

  function getButtonClasses(button: PopoverDialogButton) {
    return clsx(button.color === "alert" && classes.alertButton, classes.button);
  }

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorElement}
        className={clsx(classes.popover, props.centered && classes.centered)}
        onClose={event => handleClick(event as MouseEvent<Element, MouseEvent>, PopoverDialogDefaultIdentifiers.CANCEL)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Paper className={classes.popoverPaper} data-testid="popover-dialog">
          <Box className={classes.withInfoIconBox}>
            {props.icon && (
              <Box className={classes.infoIconBox} data-testid="popover-dialog-info-icon">
                {props.icon}
              </Box>
            )}
            <Box>
              <Typography variant="h5" className={classes.title} data-testid="popover-dialog-title">
                {props.title}
              </Typography>
              <Typography className={classes.text} data-testid="popover-dialog-text">
                {props.text}
              </Typography>
              {props.additionalText && <Typography className={classes.text}>{props.additionalText}</Typography>}
            </Box>
          </Box>

          <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.buttonWrapper}>
            {props.buttons.map(button => (
              <Button
                size="medium"
                variant={button.identifier === "cancel" ? "outlined" : "contained"}
                className={getButtonClasses(button)}
                color={button.identifier !== PopoverDialogDefaultIdentifiers.CANCEL ? "secondary" : undefined}
                data-testid={"popover-dialog-button-" + button.identifier}
                data-cy={"popover-dialog-button-" + button.identifier}
                key={button.identifier}
                onClick={(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
                  event.stopPropagation();
                  props.handleAction(button.identifier);
                }}
              >
                {button.label}
              </Button>
            ))}
          </Grid>
        </Paper>
      </Popover>
    </Backdrop>
  );
}
