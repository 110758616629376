import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";

const useStyles = makeStyles(theme => ({
  backBox: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    "& svg": {
      color: theme.palette.navy[200]
    },
    marginTop: theme.spacing(2)
  }
}));

interface ResponsiveTabsBackBoxProps {
  unselectTabId: () => void;
  label: string;
}

export function ResponsiveTabsBackBox(props: ResponsiveTabsBackBoxProps) {
  const classes = useStyles();

  return (
    <Box
      onClick={() => props.unselectTabId()}
      className={classes.backBox}
      data-testid="responsive-tabs-back-box"
      role="button"
    >
      <ChevronLeftRounded />
      {props.label}
    </Box>
  );
}
