import { ChooseTestsFilterOptions, TestCategory } from "../CreateTests.interfaces";
import { getRenderableCategories, getVisibleTests, getSelectedTests } from "../CreateTestsCommonUtils";

export function getAppliedFiltersCount(filter: ChooseTestsFilterOptions, categories: TestCategory[]) {
  return filter.showAllCategories ? 0 : categories.filter(cat => cat.selected).length;
}

export function getTotalShowingTestsCount(filter: ChooseTestsFilterOptions, categories: TestCategory[]) {
  const filteredTests = getRenderableCategories(filter, categories).flatMap(cat =>
    getVisibleTests(cat.tests, filter.textToSearchBy, false)
  );

  if (!filter.showSelected) {
    return filteredTests.length;
  } else {
    return getSelectedTests(categories).length + filteredTests.filter(test => !test.extended.selected).length;
  }
}
