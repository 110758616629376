import React, { useState } from "react";
import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { DurationSelection, getAvaliableTestSuites } from "./FailPassWarnChartUtils";
import { useStyles } from "./FailPassWarnChartStyles";
import { FailPassWarnChartFilterSettings, FailPassWarnChartFilter } from "./FailPassWarnChartFilter";
import { FailPassWarnChart, ChartDataLoadStatus } from "./FailPassWarnChart";
import { NoDataChartSubstitude } from "./NoDataChartSubstitude";
import { FailPassWarnChartFilterSwithches } from "./FailPassWarnChartFilterSwitches";
import { subDays } from "date-fns";

export interface FailPassWarnChartProps {
  testSuites:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  timeStampForDataReload?: number | undefined; // in milliseconds // denontates the timestamp for thhe oldest data. If data are older than this timestamp they need to be refetched.
}

export function FailPassWarnChartWrapper(props: FailPassWarnChartProps) {
  const classes = useStyles();

  const [chartDataLoadStatus, setChartDataLoadStatus] = useState<ChartDataLoadStatus>({
    error: false,
    loading: false,
    hasData: false
  });
  const [filterSettings, setFilterSettings] = useState<FailPassWarnChartFilterSettings>({
    customDuration: {
      start: subDays(new Date(), 7),
      end: new Date()
    },
    durationSelection: DurationSelection.LATEST_RUNS,
    testSuiteId: props?.testSuites?.length ? props.testSuites[0].id : "",
    show: {
      fails: true,
      passes: false,
      warnings: true
    }
  });

  function chartLoadedNoData() {
    return !!(
      props.testSuites &&
      props.testSuites.length > 0 &&
      !chartDataLoadStatus.hasData &&
      !chartDataLoadStatus.loading &&
      !chartDataLoadStatus.error
    );
  }

  return (
    <Paper
      className={classes.paper}
      data-cy="fail-pass-warn-chart"
      data-pendo="auto-dashboard-widget-fails-warninigs-over-time"
    >
      <Box className={classes.header}>
        <Typography variant="h2" className={classes.h2}>
          Fails and warnings over time
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.filterBox}>
        <FailPassWarnChartFilter
          availableTestSuites={getAvaliableTestSuites(props.testSuites || [])}
          settings={filterSettings}
          errorMessageForcedOnDatePickers={
            chartLoadedNoData() && "No builds for the selected date range. Pease select another one."
          }
          onSettingsChange={setFilterSettings}
        />
      </Box>

      <Box className={classes.chartBox}>
        {props.testSuites?.length ? (
          <>
            <FailPassWarnChart
              testSuites={props.testSuites}
              filterSettings={filterSettings}
              onDataLoadStatusChange={setChartDataLoadStatus}
              timeStampForReload={props.timeStampForDataReload}
            />
            <FailPassWarnChartFilterSwithches settings={filterSettings} onSettingsChange={setFilterSettings} />
          </>
        ) : (
          <NoDataChartSubstitude
            heading="No data available"
            text="Before we can show you the fails and warning graph, we’ll first need to get some data in here. Please run your first build."
          />
        )}
      </Box>
    </Paper>
  );
}
