import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    listStyleType: "none",
    margin: 0,
    paddingInlineStart: 0
  },
  testSuiteName: {
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.2",
    fontSize: 15,
    maxHeight: "20px",
    display: "block",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
      lineHeight: "1.2"
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "inherit",
      whiteSpace: "inherit",
      textOverflow: "inherit",
      overflow: "inherit"
    }
  },
  infoText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineHeight: "1.5"
  },
  warningWrapper: {
    marginBottom: theme.spacing(2)
  },
  url: {
    color: theme.palette.navy[300],
    fontWeight: 400,
    fontSize: 14
  }
}));

export interface SchedulerTestSuiteItemInfoProps {
  url: string;
  name: string;
}

export function SchedulerTestSuiteItemInfo(props: SchedulerTestSuiteItemInfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography
        className={classes.testSuiteName}
        data-cy="scheduler-detail-testsuite-name"
        data-testid="scheduler-test-suite-list-item-name"
        title={props.name}
      >
        {props.name}
      </Typography>
      <Box className={classes.infoText}>
        <Typography
          data-testid="scheduler-test-suite-list-item-url"
          data-cy="scheduler-detail-testsuite-url"
          className={classes.url}
        >
          {props.url}
        </Typography>
      </Box>
    </Box>
  );
}
