import React, { useState } from "react";
import { getTestSuiteChildren_node_TestSuite_children_edges } from "../../../../../generated-graphql-interfaces";
import { useUnlinkChildToParentMutation } from "../graphql/useUnlinkChildToParentMutation";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkOffRoundedIcon from "@material-ui/icons/LinkOffRounded";
import { ActionButtonTooltip } from "../../../../../_common/components/ActionButtonTooltip/ActionButtonTooltip";
import { Alert, Skeleton } from "@material-ui/lab";
import { UseAsGlobalTemplate } from "./UseAsGlobalTemplate";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { UseAsGlobalTemplateVariant } from "./UseAsGlobalTemplate";
import { Routes } from "../../../../../_common/routes/routes";
import { useSnackbar } from "notistack";
import { defaultPopOverId } from "../../../../../_common/constants/popover";
import { LoadMoreItems } from "../../../../../_common/components/LoadMoreItems/LoadMoreItems";

const useStyles = makeStyles(theme => ({
  expansionSummaryRoot: {
    padding: 0,
    "&.Mui-expanded": {
      minHeight: 0
    }
  },
  expansionSummaryContent: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0
    }
  },
  expansionDetails: {
    padding: theme.spacing(4, 0, 0, 0)
  },
  panel: {
    boxShadow: "none"
  },
  testSuiteItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    backgroundColor: "#EDEEEF",
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.purple[50],
      "& p": {
        color: theme.palette.secondary.main,
        textDecoration: "underline"
      }
    }
  },
  testSuiteWrapper: {
    width: "100%"
  },
  actionIcon: {
    color: theme.palette.navy[300],
    border: `1px solid ${theme.palette.navy[50]}`,
    borderRadius: "3px",
    padding: "3px",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.error.main
    }
  },
  header: {
    fontSize: "1.125rem",
    lineHeight: "1.2",
    paddingBottom: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.navy[200]
  },
  testSuiteName: {
    color: "#202020"
  },
  error: {
    marginBottom: theme.spacing(2)
  }
}));

interface ChildrenAddedSuccessfullyProps {
  childrenTestSuites: getTestSuiteChildren_node_TestSuite_children_edges[];
  totalAvailableChildren?: number;
  loadMoreChildren: () => void;
  isFetchingMoreChildren: boolean;
  useAsGlobalTemplate: (childId: string, childName: string) => Promise<void>;
  testSuiteId: string;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary"
  },
  {
    label: "Unlink",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert"
  }
];

//eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function ChildrenAddedSuccessfully(props: ChildrenAddedSuccessfullyProps) {
  const classes = useStyles();
  const [unlinkChildFromParent, { loading, error }] = useUnlinkChildToParentMutation();
  const [childTestSuite, setChildTestSuite] = useState({ name: "", id: "" });
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const history = useHistory();
  const accountId = useAccountRouteMatch();
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  async function handleUnlinkChildTestsuite(id: PopoverDialogDefaultIdentifiers | string) {
    setAnchorEl(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      try {
        const { data } = await unlinkChildFromParent({ variables: { childId: childTestSuite.id } });
        const snackbarMessage = `${data?.unlinkChildTestSuiteFromParentTestSuite.childTestSuite.name} unlinked successfully.`;
        enqueueSnackbar(snackbarMessage, {
          variant: "success"
        });
      } catch (e) {
        // should be handled by GraphQL
      }
    }
    setChildTestSuite({ name: "", id: "" });
  }

  function handleClick(childId: string, childName: string) {
    return function (event: React.MouseEvent<SVGSVGElement>) {
      if (!loading) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setChildTestSuite({ id: childId, name: childName });
      }
    };
  }

  function handleTestSuiteItemClick(testSuiteId: string) {
    return function (event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
      if (!event.isDefaultPrevented()) {
        history.push(Routes.EditTestSuite.getUrl({ accountId, testSuiteId }));
      }
    };
  }

  function handlePanelChange(event: React.ChangeEvent<{}>, isExpanded: boolean) {
    setIsPanelOpen(isExpanded);
  }

  if (loading) return <Skeleton variant="rect" height={200} data-testid="children-testsuites-loading" />;

  if (error) {
    return (
      <Alert className={classes.error} severity="error" data-testid="unlink-child-error">
        An error occurred while trying to unlink the test suite. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <div data-testid="children-applied-view">
      <Accordion className={classes.panel} expanded={isPanelOpen} onChange={handlePanelChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.expansionSummaryRoot, content: classes.expansionSummaryContent }}
          data-testid="children-test-suites-expansion-panel"
        >
          <Box>
            <Typography color="textPrimary" className={classes.header} data-testid="children-added-title">
              Test suites using this template
            </Typography>
            <Typography className={classes.subtitle} data-testid="children-added-testsuite-count">
              {props.totalAvailableChildren} test suites
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          <Box className={classes.testSuiteWrapper}>
            {props.childrenTestSuites.map(({ node: { id, name } }) => {
              return (
                <div key={id} className={classes.testSuiteItem} onClick={handleTestSuiteItemClick(id)}>
                  <Typography
                    noWrap
                    className={classes.testSuiteName}
                    data-testid="child-testsuite-name"
                    data-cy={`child-testsuite-name-${name}`}
                  >
                    {name}
                  </Typography>
                  <ActionButtonTooltip title="Unlink from global template">
                    <LinkOffRoundedIcon
                      data-testid="unlink-child-testsuite"
                      data-cy={`unlink-child-testsuite-${name}`}
                      data-pendo="auto-test-suite-edit-template-settings-global-template-unlink-child"
                      className={classes.actionIcon}
                      onClick={handleClick(id, name)}
                    />
                  </ActionButtonTooltip>
                </div>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box>
        {isPanelOpen && (
          <LoadMoreItems
            currentCount={props.childrenTestSuites.length}
            totalCount={props.totalAvailableChildren || 0}
            onClick={props.loadMoreChildren}
            isLoading={props.isFetchingMoreChildren}
            dataId="children-test-suites"
            variant="stacked"
          />
        )}
      </Box>

      <Box mt={5}>
        <UseAsGlobalTemplate
          handleUseAsGlobalTemplate={props.useAsGlobalTemplate}
          label="Link more test suites"
          variant={UseAsGlobalTemplateVariant.AddAnotherChild}
          testSuiteId={props.testSuiteId}
        />
      </Box>
      <PopoverDialog
        anchorElement={anchorEl}
        handleAction={handleUnlinkChildTestsuite}
        open={Boolean(anchorEl)}
        text="The test suite will be disconnected from the template. Any future changes made to the global template will not be applied."
        title="Are you sure you want to unlink this test suite?"
        buttons={popoverButtons}
        id={defaultPopOverId}
      />
    </div>
  );
}
