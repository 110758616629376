import { useQuery } from "@apollo/react-hooks";
import { getReportsTemplatesQuery } from "./getReportTemplatesQuery";
import { getReportTemplates, getReportTemplatesVariables } from "../../../../../generated-graphql-interfaces";
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";
import merge from "deepmerge";

export function useReportTemplates(
  after: string
): {
  loading: boolean;
  data: getReportTemplates | undefined;
  error: ApolloError | undefined;
} {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { data, loading, fetchMore, error } = useQuery<getReportTemplates, getReportTemplatesVariables>(
    getReportsTemplatesQuery,
    {
      variables: {
        after: after
      },
      fetchPolicy: "cache-and-network"
    }
  );

  function evaluateMoreFetch() {
    if (!loading && !isFetchingMore && data?.getAutomatorReportTemplates?.pageInfo.hasNextPage) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          after: data.getAutomatorReportTemplates.pageInfo.endCursor
        },
        updateQuery: (previousResult: getReportTemplates | undefined, { fetchMoreResult }) => {
          if (previousResult && fetchMoreResult) {
            return merge(previousResult, fetchMoreResult, defaultMergeOptions);
          }
          return previousResult;
        }
      })
        .catch(e => {
          // Note: we have to catch the error here as otherwise some e2e tests fail when running locally.
          // It relates to the fact that apollo client doesn't cancel fetchMore requests even when the component making
          // the request has been unmounted. More info: https://github.com/apollographql/apollo-client/issues/4114
          // eslint-disable-next-line no-console
          console.log(`Error caught in useReportTemplate`, e);
        })
        .finally(() => {
          setIsFetchingMore(false);
        });
    }
  }

  evaluateMoreFetch();

  return {
    loading: loading || !!data?.getAutomatorReportTemplates.pageInfo.hasNextPage,
    error,
    data
  };
}
