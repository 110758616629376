import React, { useContext, useEffect, useState } from "react";
import { ExtendedTest } from "../CreateTests.interfaces";
import { Grid, Box } from "@material-ui/core";
import useStyles from "./ChooseTestsSelectionListStyles";
import {
  getSelectedTests,
  nonMutationSortByTestName,
  getVisibleTests,
  getRenderableCategories,
  isMaxTestSelectedLimitReached
} from "../CreateTestsCommonUtils";
import ChooseTestsSelectionCheckBox from "./ChooseTestsSelectionCheckBox";
import { MAX_TESTS_SELECTION_LIMIT, RECOMMENDED_TESTS_CATEGORY_CODE } from "../CreateTestsCommonConst";
import { shouldRenderRecomendedTests } from "./ChooseTestsSelectionListUtils";
import { CategoryTestsList } from "./CategoryTestsList";
import { ChooseTestsState } from "../chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { useGradualNumber } from "../../../../../_common/hooks/useGradualNumber/useGradualNumber";
import BufferedLinearProgress from "../../../../../_common/components/BufferedLinearProgress/BufferedLinearProgress";

export interface ChooseTestsSelectionListProps {
  chooseTestsFormState: ChooseTestsState;
  clearSubmitError: () => void;
  openMaxTestSelectionPopup: () => void;
  maxHeight: number;
  renderingProgressed?: () => void;
}

// eslint-disable-next-line max-statements, max-lines-per-function
export function ChooseTestsSelectionList(props: ChooseTestsSelectionListProps) {
  const { categories, filter } = props.chooseTestsFormState;
  const classes = useStyles(props);
  const [renderingStarted] = useState(new Date().getTime());

  const { value: maxRenderedCategories, clear: clearTimeoutForCategoriesRender } = useGradualNumber(
    renderingStarted,
    categories.length,
    2,
    500
  );

  const { value: maxSelectedTestsToRender, clear: clearMainTimeout } = useGradualNumber(renderingStarted, 100, 20, 500);
  const selectedTestsSortedByName = nonMutationSortByTestName(getSelectedTests(categories)).slice(
    0,
    maxSelectedTestsToRender
  );

  useEffect(() => {
    props.renderingProgressed && props.renderingProgressed();
  }, [maxRenderedCategories, maxSelectedTestsToRender, props]);

  const recommendedTestsCategories = categories
    .filter(cat => cat.code === RECOMMENDED_TESTS_CATEGORY_CODE)
    .slice(0, maxRenderedCategories);
  const renderableCategories = getRenderableCategories(filter, categories).slice(0, maxRenderedCategories);
  const allCategoriesRendered = categories.length === maxRenderedCategories;

  useEffect(() => {
    return function cleanup() {
      clearMainTimeout();
      clearTimeoutForCategoriesRender();
    };
  }, [clearMainTimeout, clearTimeoutForCategoriesRender]);

  const maxLimitOfSelectedTestsReached = isMaxTestSelectedLimitReached(categories);
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const [alreadySelectedTests, setAlreadySelectedTests] = useState<number>(getSelectedTests(categories).length);

  useEffect(() => {
    const newAlreadySelectedTests = getSelectedTests(categories).length;
    if (
      newAlreadySelectedTests === MAX_TESTS_SELECTION_LIMIT &&
      alreadySelectedTests == MAX_TESTS_SELECTION_LIMIT - 1
    ) {
      props.openMaxTestSelectionPopup();
    }
    setAlreadySelectedTests(newAlreadySelectedTests);
  }, [categories, alreadySelectedTests, props]);

  const handleTestSelectionChange = (test: ExtendedTest, selected: boolean) => {
    props.clearSubmitError();
    chooseTestsFormDispatch({ type: "TEST_SELECTION_CHANGE", payload: { test, isTestSelected: selected } });
  };

  return (
    <>
      {!allCategoriesRendered && (
        <Box>
          <Box mb={1}>{`Loading categories: ${maxRenderedCategories} out of ${categories.length}.`}</Box>
          <Box mb={3}>
            <BufferedLinearProgress
              completed={maxRenderedCategories}
              totalCount={categories.length}
              buffered={categories.length - maxRenderedCategories}
            />
          </Box>
        </Box>
      )}
      <Box className={classes.scrollableList}>
        <Grid container data-testid="selected-tests-box">
          {filter.showSelected && (
            <Grid item className={classes.selectedTestsGrid}>
              {selectedTestsSortedByName.map(test => (
                <ChooseTestsSelectionCheckBox
                  key={test.data.reportTemplate.code + "-checkbox-in-selection" + test.data.reportTemplate.name}
                  test={test}
                  onChange={selected => handleTestSelectionChange(test, selected)}
                  data-testid={test.data.reportTemplate.code + "-checkbox-in-selection"}
                  disabled={maxLimitOfSelectedTestsReached}
                />
              ))}
            </Grid>
          )}
        </Grid>
        {shouldRenderRecomendedTests(filter, categories) &&
          recommendedTestsCategories.map(category => {
            const testsToRender = categories.flatMap(cat => cat.tests).filter(test => test.extended.recomended);
            const filteredTests = getVisibleTests(testsToRender, filter.textToSearchBy, filter.showSelected);

            return (
              <CategoryTestsList
                maxLimitOfSelectedTestsReached={maxLimitOfSelectedTestsReached}
                key={category.code}
                category={category}
                tests={filteredTests}
                onTestSelectionChange={handleTestSelectionChange}
              />
            );
          })}
        {renderableCategories.map(category => {
          const shouldRemoveRecomendedTest = shouldRenderRecomendedTests(filter, categories);
          const testsToRender = getVisibleTests(
            category.tests,
            filter.textToSearchBy,
            filter.showSelected
          ).filter(test => (shouldRemoveRecomendedTest ? !test.extended.recomended : true));

          return (
            <CategoryTestsList
              key={category.code}
              category={category}
              tests={testsToRender}
              onTestSelectionChange={handleTestSelectionChange}
              maxLimitOfSelectedTestsReached={maxLimitOfSelectedTestsReached}
            />
          );
        })}
      </Box>
    </>
  );
}
