import gql from "graphql-tag";

export const useTestSuiteChildrenGql = gql`
  query getTestSuiteChildren($id: ObjectID!, $cursor: String) {
    node(id: $id) {
      ... on TestSuite {
        id
        children(first: 20, after: $cursor, orderBy: [{ field: parentLinkedAt, direction: DESC }]) {
          edges {
            node {
              id
              name
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;
