import * as yup from "yup";
import { ValidationErrors } from "../../../validation/ValidationErrors";

export async function postForgetPasswordRequest(email: string) {
  const apiUrl = process.env.REACT_APP_RUBY_API;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  };
  return fetch(`${apiUrl}/auth/forgot_credentials`, requestOptions).then(response => {
    if (response.ok) {
      return response;
    } else {
      return Promise.reject("UnexpectedError");
    }
  });
}

export const emailValidationSchema = yup.object().shape({
  email: yup.string().required(ValidationErrors.EmailRequired).email(ValidationErrors.EmailValidity)
});
