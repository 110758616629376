import React from "react";
import { makeStyles } from "@material-ui/core";
import { PrimaryActionButton } from "../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { PendoTagProps } from "../../../../typings/pendo.prototype";

export const useStyles = makeStyles(theme => ({
  testMe: {
    justifyContent: "flex-end",
    display: "flex",
    position: "sticky",
    bottom: 0,
    backdropFilter: "blur(4px)",
    padding: theme.spacing(2, 2, 2, 2),
    background: "rgba(0,0,0,0.1)",
    fontSize: "20px"
  }
}));

export interface FloatingStepSaveProps extends PendoTagProps {
  disabled?: boolean;
  onClick?: () => void;
  label: string;
  dataTestId?: string;
  dataCy?: string;
}

export function FloatingStepSave(props: FloatingStepSaveProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.testMe}>
      <PrimaryActionButton type="submit" disableForViewerRole={true} {...props} />
    </div>
  );
}
