import React from "react";
import { CrawlSettingsForm } from "./CrawlSettingsForm";
import { useUpdateCrawlSettingsMutation } from "./graphql/useUpdateCrawlSettingsMutation";
import { getTestSuite_node_TestSuite } from "../../../../generated-graphql-interfaces";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { CircularProgress } from "@material-ui/core";

export interface CrawlSettingsProps {
  testSuite: getTestSuite_node_TestSuite;
  onSubmit: StepChangeHandler;
  refetchData: () => void;
}

export function CrawlSettings(props: CrawlSettingsProps): JSX.Element {
  const [updateCrawlSettings, { loading }] = useUpdateCrawlSettingsMutation();

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CrawlSettingsForm
      testSuite={props.testSuite}
      handleSave={async variables => {
        await updateCrawlSettings(variables);
      }}
      onSubmit={props.onSubmit}
      refetchData={props.refetchData}
    />
  );
}
