import { InputBaseClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";

export const muiInputBase: Partial<Record<
  InputBaseClassKey,
  CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>
>> = {
  input: {
    lineHeight: 1.5,
    height: "initial"
  }
};
