import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes } from "../../../../generated-graphql-interfaces";
import {
  IntegrationDetailConnectionType,
  IntegrationDetails
} from "../../../TestSuite/components/SetAlerts/Jira/IntegrationDetails";

export interface JiraIntegrationDetailIntegrationDetailProps {
  jiraIntegration: getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2, 2, 2)
    }
  }
}));

export function JiraIntegrationDetailIntegrationDetail(props: JiraIntegrationDetailIntegrationDetailProps) {
  const classes = useStyles();

  const connectionsCount = props.jiraIntegration.testSuiteJiraIntegrations.totalCount;
  const suiteText = connectionsCount > 1 ? "suites" : "suite";
  const statusString = connectionsCount
    ? `Connected to ${connectionsCount} test ${suiteText}`
    : "Not connected to any test suites";
  const statusType = connectionsCount ? IntegrationDetailConnectionType.OK : IntegrationDetailConnectionType.WARNING;

  return (
    <Box
      className={classes.wrapper}
      data-testid="jira-integration-detail-integration"
      data-cy="jira-integration-detail-integration"
      id={`jira-integration-detail-integration-${props.jiraIntegration.id}`}
    >
      <IntegrationDetails jiraIntegration={props.jiraIntegration} status={statusString} statusType={statusType} />
    </Box>
  );
}
