import { ApolloError } from "apollo-client";
import React, { useState } from "react";
import { getBuildsStatuses, getBuildsStatuses_node_TestSuite_builds } from "../../../../generated-graphql-interfaces";
import { isPriorityBuildRunning } from "../../../../_common/utils/isPriorityBuildRunning/isPriorityBuildRunning";
import { useGetBuildsStatusesQuery } from "../../graphql/useGetBuildsStatusesQuery";

export interface BuildStatusContext {
  data: getBuildsStatuses | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  isPriorityBuildRunning: () => Promise<boolean>;
  runNowStatus: RunNowStatus;
  setRunNowStatus: React.Dispatch<React.SetStateAction<RunNowStatus>>;
}

export const BuildStatusContext = React.createContext<BuildStatusContext | undefined>(undefined);

interface BuildStatusProviderProps {
  children: React.ReactNode;
  testSuiteId: string;
}

type RunNowStatus = "idle" | "checking" | "running";

export function BuildStatusProvider({ children, testSuiteId }: BuildStatusProviderProps) {
  const { data, error, loading, refetch } = useGetBuildsStatusesQuery(testSuiteId);
  const [runNowStatus, setRunNowStatus] = useState<RunNowStatus>("idle");

  async function refetchData(): Promise<boolean> {
    return refetch().then(data => {
      const testSuiteBuilds: getBuildsStatuses_node_TestSuite_builds =
        data.data?.node?.__typename === "TestSuite"
          ? data.data.node.builds
          : { __typename: "BuildConnection", nodes: [] };

      return isPriorityBuildRunning(testSuiteBuilds.nodes);
    });
  }

  return (
    <BuildStatusContext.Provider
      value={{
        data,
        error,
        loading,
        isPriorityBuildRunning: refetchData,
        runNowStatus,
        setRunNowStatus
      }}
    >
      {children}
    </BuildStatusContext.Provider>
  );
}
