import React from "react";
import { Grid, Box } from "@material-ui/core";
import { useStyles } from "./LoginScreenInformationStyles";
import { CheckedIconInformation } from "./CheckedIconInformation";

export function LoginScreenInformation() {
  const classes = useStyles();

  return (
    <Grid className={classes.block} data-testid="login-screen-information">
      <Box className={classes.heading} data-testid="main-heading">
        Automate your SEO testing
      </Box>
      <Box className={classes.description} data-testid="main-text">
        Integrate Automation Hub into your development pipeline and protect your site from SEO regressions.
      </Box>
      <Box>
        <a
          className="linear-gradient-border"
          href="https://www.deepcrawl.com/request-a-demo/"
          target="_blank"
          rel="noreferrer"
          data-testid="get-access-now-button"
        >
          Get started
        </a>
      </Box>

      <Box className={classes.crawlHeading} data-testid="main-subheading">
        Crawl before you run
      </Box>
      <Grid container direction="column" data-testid="check-points" className={classes.checkpointsGrid}>
        <Grid item container direction="row" alignItems="flex-start" className={classes.crawlRow}>
          <CheckedIconInformation description="Integrate with any CI/CD (continuous integration / continuous delivery) tool" />
          <CheckedIconInformation description="Automatically stop builds if they fail your SEO tests" />
        </Grid>
        <Grid item container direction="row" alignItems="flex-start" className={classes.crawlRow}>
          <CheckedIconInformation description="Choose from over 200 SEO tests" />
          <CheckedIconInformation description="Set warnings for important but non-critical SEO issues" />
        </Grid>
      </Grid>
    </Grid>
  );
}
