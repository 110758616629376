import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { ForgottenPasswordConfirmationDialog } from "./ForgottenPasswordConfirmationDialog";
import { ForgottenPasswordEmailDialog } from "./ForgottenPasswordEmailDialog";

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "black",
    fontSize: "13px",
    lineHeight: "16px",
    textDecoration: "none",
    cursor: "pointer"
  }
}));

export function ForgottenPassword() {
  const [openForgottenPasswordEmailDialog, setOpenForgottenPasswordEmailDialog] = useState(false);
  const [openForgottenPasswordEmailConfirmationDialog, setOpenForgottenPasswordEmailConfirmationDialog] = useState(
    false
  );
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpenForgottenPasswordEmailDialog(true);
  };

  const handleCloseForgottenPasswordEmailDialog = () => {
    setOpenForgottenPasswordEmailDialog(false);
  };

  const handleCloseForgottenPasswordEmailConfirmationDialog = () => {
    setOpenForgottenPasswordEmailConfirmationDialog(false);
  };

  const handleResetPasswordSuccedded = () => {
    setOpenForgottenPasswordEmailDialog(false);
    setOpenForgottenPasswordEmailConfirmationDialog(true);
  };

  return (
    <>
      <a
        onClick={handleClickOpen}
        className={classes.link}
        data-cy="forgot-password-link"
        data-testid="forgotten-password-link"
      >
        Forgot Password?
      </a>
      <ForgottenPasswordEmailDialog
        open={openForgottenPasswordEmailDialog}
        resetPasswordSucceded={handleResetPasswordSuccedded}
        onClose={handleCloseForgottenPasswordEmailDialog}
      />
      <ForgottenPasswordConfirmationDialog
        open={openForgottenPasswordEmailConfirmationDialog}
        onClose={handleCloseForgottenPasswordEmailConfirmationDialog}
      />
    </>
  );
}
