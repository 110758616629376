import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useScheduleSettingsStyles = makeStyles(theme => ({
  title: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left"
  },
  subtitle: {
    fontSize: "1.125rem",
    fontWeight: 500,
    color: theme.palette.navy[300],
    lineHeight: "24px",
    marginRight: theme.spacing(1)
  },
  root: {
    margin: 0,
    padding: theme.spacing(2, 3, 2, 5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dialogPaper: {
    maxWidth: 690
  },
  dialogContent: {
    padding: theme.spacing(5, 5, 3.5, 5),
    borderTop: "1px solid " + theme.palette.grey[400],
    borderBottom: "none"
  },
  divider: {
    margin: theme.spacing(5, 0, 3.5, 0),
    backgroundColor: theme.palette.grey[400]
  },
  closeButton: {
    color: theme.palette.navy[500]
  },
  actionButton: {
    minWidth: 115
  },
  saveButton: {
    marginLeft: theme.spacing(2)
  },
  alert: {
    display: "flex",
    alignItems: "center",
    border: "1px solid " + theme.palette.grey[400],
    borderRadius: "4px",
    minHeight: "64px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    color: theme.palette.navy[500],
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: theme.spacing(4.5),
    backgroundColor: "#FFF"
  },
  buttonsBox: {
    marginTop: theme.spacing(5)
  },
  mainInputBox: {
    marginBottom: theme.spacing(5)
  }
}));
