import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBox: {
      marginTop: "28px"
    },
    filterCategories: {
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "white",
      boxShadow: "none",
      "&::before": {
        content: "",
        display: "none"
      }
    },
    filterCategoriesHeader: {
      margin: "0",
      padding: "0 14px",
      "&.Mui-expanded": {
        minHeight: "unset",
        "& .MuiExpansionPanelSummary-content": {
          margin: "0"
        }
      }
    },
    filterCategoriesHeaderLeft: {
      maxWidth: "50%"
    },
    filterCategoryDetail: {
      padding: "0"
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    mainGrid: {
      width: "100%"
    },
    divider: {
      margin: `${theme.spacing(1)}px 10px`,
      borderTop: `1px solid ${theme.palette.grey[50]}`,
      borderBottom: "0",
      borderLeft: "0",
      borderRight: "0"
    },
    accordionContent: { margin: "10px 0px" }
  })
);

export default useStyles;
