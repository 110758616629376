import React from "react";
import { Severity } from "../../../generated-graphql-interfaces";
import { getTestStatus, getStatusIconType, getText } from "./TestResultIconUtils";
import { StatusIcon, StatusIconSize } from "../StatusIcon/StatusIcon";

export interface TestSuiteReportTestResultIconProps {
  severity: Severity;
  isPassed: boolean;
}

export function TestResultIcon(props: TestSuiteReportTestResultIconProps) {
  const testStatus = getTestStatus(props.isPassed, props.severity);
  const statusIconType = getStatusIconType(testStatus);
  const text = getText(testStatus);

  function getIcon() {
    return <StatusIcon type={statusIconType} text={text} size={StatusIconSize.MD} inline={true} />;
  }

  return getIcon();
}
