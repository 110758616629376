import React, { useState } from "react";
import { TestSuiteReportTestResult } from "./TestSuiteReportTestResult";
import { getTestSuiteReport_node_Build } from "../../../generated-graphql-interfaces";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { TestReportFilterButton } from "./TestReportFilterButton";
import { deepCrawlTheme } from "../../../theme/deepCrawlTheme";
import {
  getTotalTestCount,
  isSelectedtTypeValid,
  isTestToBeDisplayed,
  sortTestResults,
  TestsToDisplayOption
} from "./TestSuiteReportListUtils";
import { getCustomExtractionTestName } from "../../../_common/utils/getCustomExtractionTestName/getCustomExtractionTestName";
import { TestSuiteItemLinks } from "../../TestSuiteList/components/TestSuiteItemLinks";
import { useParams } from "react-router-dom";

export const useTestSuiteReportStyles = makeStyles(theme => ({
  allTestsButton: {
    fontSize: "2rem",
    marginRight: theme.spacing(4)
  },
  gridContainer: {
    marginBottom: theme.spacing(5),
    justifyContent: "flex-start"
  },
  wrapper: {
    margin: theme.spacing(0, 0, 4, 0)
  },
  editButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  title: {
    fontWeight: 500,
    fontSize: "26px",
    margin: theme.spacing(2, 0)
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 300
  }
}));

export interface TestReportListProps {
  testSuiteReport: getTestSuiteReport_node_Build;
}

export function TestReportList(props: TestReportListProps) {
  const { testSuiteReport } = props;
  const testsResults = testSuiteReport.testResults.nodes;
  const sortedTestsResults = sortTestResults(testsResults);
  const classes = useTestSuiteReportStyles();
  const { selectedType } = useParams<{ selectedType?: TestsToDisplayOption }>();

  // GraphQL query uses a filter so that it only returns builds that have a status of 'Running' or 'Queued'
  const isBuildRunning = Boolean(testSuiteReport.testSuite.builds.nodes.length);

  const validSelectedType = isSelectedtTypeValid(selectedType);

  const [testsToDisplay, setTestsToDisplay] = useState<TestsToDisplayOption>(
    validSelectedType ? (selectedType as TestsToDisplayOption) : TestsToDisplayOption.ALL
  );

  const totalTestCount = getTotalTestCount(testSuiteReport);

  return (
    <>
      <Grid container className={classes.wrapper} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={10}>
          <Typography className={classes.title} variant="h1">
            {testSuiteReport.testSuite.name}
          </Typography>
          <Typography className={classes.subtitle} variant="h2">{`Build ID: ${testSuiteReport.id}`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.editButtonWrapper}>
          <TestSuiteItemLinks
            pendoIdSuffix="testsuite-report"
            editable={!isBuildRunning}
            deletable={false}
            clonable={false}
            name={testSuiteReport.testSuite.name}
            id={testSuiteReport.testSuite.id}
            testSuiteJiraIntegrationId={testSuiteReport.testSuite.testSuiteJiraIntegration?.id}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="space-between" className={classes.gridContainer}>
        <Grid item>
          <TestReportFilterButton
            onClick={() => setTestsToDisplay(TestsToDisplayOption.ALL)}
            color={deepCrawlTheme.palette.grey[300]}
            isActive={testsToDisplay === TestsToDisplayOption.ALL}
            count={totalTestCount}
            label="Tests"
            dataTestId="all-reports-button"
          />
        </Grid>
        <Grid item>
          <TestReportFilterButton
            color={deepCrawlTheme.palette.error.light}
            isActive={testsToDisplay === TestsToDisplayOption.FAIL}
            onClick={() => setTestsToDisplay(TestsToDisplayOption.FAIL)}
            count={testSuiteReport.failedTestCount || 0}
            label="Fail"
            dataTestId="fail-reports-button"
          />
        </Grid>
        <Grid item>
          <TestReportFilterButton
            color={deepCrawlTheme.palette.warning.main}
            isActive={testsToDisplay === TestsToDisplayOption.WARNING}
            onClick={() => setTestsToDisplay(TestsToDisplayOption.WARNING)}
            count={testSuiteReport.warnedTestCount || 0}
            label="Warning"
            dataTestId="warning-reports-button"
          />
        </Grid>
        <Grid item>
          <TestReportFilterButton
            color={deepCrawlTheme.palette.deepcrawlGreen.main}
            isActive={testsToDisplay === TestsToDisplayOption.PASS}
            onClick={() => setTestsToDisplay(TestsToDisplayOption.PASS)}
            count={testSuiteReport.passedTestCount || 0}
            label="Pass"
            dataTestId="pass-reports-button"
          />
        </Grid>
      </Grid>
      {sortedTestsResults.map(testResult => {
        return isTestToBeDisplayed(testResult, testsToDisplay) ? (
          <TestSuiteReportTestResult
            testSummary={testResult.reportTemplate.automatorSummary}
            key={testResult.reportTemplate.code}
            isPassed={!!testResult.passed}
            severity={testResult.severity}
            testName={getCustomExtractionTestName(
              testResult.reportTemplate,
              testSuiteReport?.testSuite?.customExtractions
            )}
            crawlDcWebUrl={testSuiteReport.crawlDcWebUrl}
            reportCode={testResult.reportTemplate.code}
          />
        ) : null;
      })}
    </>
  );
}
