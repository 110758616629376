import React, { useCallback } from "react";
import { Box, Grid, Divider, makeStyles, Fade } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDeleteSlackWebhookMutation } from "../graphql/useDeleteSlackWebhookMutation";
import { getTestSuite_node_TestSuite_slackWebhooks_nodes } from "../../../../../generated-graphql-interfaces";
import { NotificationOptions } from "../NotificationOptions";
import { useUpdateSlackWebhookMutation } from "../graphql/useUpdateSlackWebhookMutation";
import { getInitialValuesFromAlerts } from "./utils/getInitialValuesFromAlerts";
import { getAlertTypesFromNotificationSettings } from "./utils/getAlertTypesFromNotificationSettings";
import { DeletableInput } from "../../../../../_common/components/DeletableInput/DeletableInput";

interface SlackWebhookRowProps {
  slackWebhook: getTestSuite_node_TestSuite_slackWebhooks_nodes;
}

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(2, 0, 3, 0)
  },
  grid: {
    marginBottom: theme.spacing(3)
  }
}));

export function SlackWebhookRow(props: SlackWebhookRowProps) {
  const classes = useStyles();
  const {
    slackWebhook: { id, url, alertTypes }
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [deleteSlackWebhook, { loading }] = useDeleteSlackWebhookMutation();
  const [updateSlackWebhook] = useUpdateSlackWebhookMutation();

  async function handleDelete() {
    try {
      await deleteSlackWebhook({
        variables: { slackWebhookId: id }
      });
      enqueueSnackbar("Slack webhook removed successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Something went wrong while trying to delete your Slack webhook", { variant: "error" });
    }
  }

  const updateAlertTypes = useCallback(
    async (pass: boolean, warning: boolean, fail: boolean) => {
      const chosenAlertTypes = getAlertTypesFromNotificationSettings(pass, warning, fail);

      try {
        await updateSlackWebhook({
          variables: {
            slackWebhookId: id,
            alertTypes: chosenAlertTypes
          }
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong while trying to update your notification settings", { variant: "error" });
      }
    },
    [id, updateSlackWebhook, enqueueSnackbar]
  );

  return (
    <Fade in timeout={500}>
      <div data-cy="slack-webhook">
        <Box component="div" marginBottom={1}>
          <Grid container alignItems="center" className={classes.grid}>
            <DeletableInput
              value={url}
              id="slack-webhook"
              handleDelete={handleDelete}
              loading={loading}
              label="Slack webhook"
              textFieldGrid={{ xs: 10, sm: 11, md: 11, lg: 11, xl: 7 }}
              deleteButtonGrid={{ xs: 2, sm: 1, xl: 1 }}
            />
          </Grid>

          <NotificationOptions
            id="slack"
            disabled={loading}
            initialValues={getInitialValuesFromAlerts(alertTypes)}
            updateNotifications={updateAlertTypes}
          />
        </Box>
        <Divider className={classes.divider} />
      </div>
    </Fade>
  );
}
