import { Switch, withStyles } from "@material-ui/core";

export const DeepCrawlSwitch = withStyles(theme => ({
  root: {
    height: 42,
    width: 65,
    paddingTop: 13,
    paddingLeft: 14,
    paddingRight: 15
  },
  thumb: {
    backgroundColor: "#FFF",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    height: 24,
    width: 24
  },
  track: {
    opacity: 1,
    backgroundColor: theme.palette.navy[200],
    height: 16,
    borderRadius: 20
  },
  switchBase: {
    "&$checked": {
      transform: "translateX(23px)"
    }
  },
  checked: {
    "& + .MuiSwitch-track": {
      opacity: "1 !important"
    }
  }
}))(Switch);
