import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getJiraSettings } from "../../../../../generated-graphql-interfaces";

export const getJiraSettingsGql = gql`
  query getJiraSettings {
    getJiraSettings {
      clientId
      authCallbackUrl
    }
  }
`;

export function useGetJiraSettingsQuery() {
  return useQuery<getJiraSettings>(getJiraSettingsGql, { fetchPolicy: "cache-and-network" });
}
