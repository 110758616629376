import { Routes } from "../../routes/routes";

export interface MainNavLink {
  name: string;
  href: string;
  testId: string;
  activePaths: string[];
  permissionGranted?: boolean;
}

export function getLinksFromPermissions(accountId: string, hasJiraAccess: boolean, hasSchedulerAccess: boolean) {
  const dashboardLink: MainNavLink = {
    name: "Dashboard",
    href: Routes.Dashboard.getUrl({ accountId }),
    testId: "dashboard",
    activePaths: [],
    permissionGranted: true
  };

  const testSuitesLink: MainNavLink = {
    name: "Test Suites",
    href: Routes.TestSuites.getUrl({ accountId }),
    testId: "my-test-suites",
    activePaths: [`/account/${accountId}/setup`, `/account/${accountId}/test-suites/`],
    permissionGranted: true
  };

  const connectedAppsLink: MainNavLink = {
    name: "Connected Apps",
    href: Routes.ConnectedApps.getUrl({ accountId }),
    testId: "connected-apps",
    activePaths: [`/account/${accountId}/connected-apps/jira`],
    permissionGranted: hasJiraAccess
  };

  const frontendSchedulerLink: MainNavLink = {
    name: "Scheduler",
    href: Routes.FrontendScheduler.getUrl({ accountId, schedulerId: undefined }),
    testId: "frontend-scheduler",
    activePaths: [`/account/${accountId}/scheduler`],
    permissionGranted: hasSchedulerAccess
  };

  const getStartedLinks: MainNavLink[] = [
    {
      name: "Getting Started",
      href: Routes.GettingStarted.getUrl({ accountId }),
      testId: "getting-started",
      activePaths: [],
      permissionGranted: true
    },
    {
      name: "About",
      href: Routes.WhyAutomator.getUrl({ accountId }),
      testId: "why-automator",
      activePaths: [],
      permissionGranted: true
    },
    { name: "FAQ", href: Routes.Faqs.getUrl({ accountId }), testId: "faqs", activePaths: [], permissionGranted: true }
  ];

  const workspaceLinks = [dashboardLink, testSuitesLink, frontendSchedulerLink, connectedAppsLink].filter(link => {
    return link.permissionGranted;
  });

  return {
    workspace: workspaceLinks,
    getStarted: getStartedLinks
  };
}
