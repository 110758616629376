import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68449 21.5113L21.2736 6.92115C22.2421 5.95265 22.2421 4.38484 21.2736 3.41631L19.3127 1.45524C18.3463 0.488871 16.7744 0.488885 15.8081 1.45524L1.21893 16.0455C1.10321 16.1612 1.023 16.3154 0.994018 16.4732L0.0136205 21.7678C-0.0351887 22.0315 0.0488858 22.3023 0.238482 22.4919C0.428171 22.6816 0.699009 22.7657 0.962573 22.7168L6.25696 21.7363C6.41651 21.7065 6.56938 21.6265 6.68449 21.5113ZM2.31297 18.293L4.43783 20.418L1.83005 20.901L2.31297 18.293ZM18.3301 7.56069L15.1676 4.39802L2.9458 16.6208L6.10824 19.7834L18.3301 7.56069ZM20.1222 4.56646C20.4544 4.89861 20.4544 5.4359 20.1222 5.76809L19.4823 6.40812L16.3198 3.24545L16.9598 2.6054C17.291 2.27422 17.8301 2.2742 18.1613 2.6054L20.1222 4.56646Z"
      />
    </SvgIcon>
  );
}
