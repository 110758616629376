import React from "react";
import { JiraIntegrations } from "./JiraIntegration/JiraIntegrations";

export function ConnectedApps(): JSX.Element {
  return (
    <>
      <JiraIntegrations />
    </>
  );
}
