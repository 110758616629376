import {
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate
} from "../../../../generated-graphql-interfaces";

export enum CategoriesCode {
  EXTRACTION = "extraction",
  CUSTOM_EXTRACTION = "custom extractions"
}

export interface SuiteTestNodesExtedned extends getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes {
  reportTemplate: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate & {
    id: string;
    categories: string[];
  };
}

export interface ExtendedTest {
  data: SuiteTestNodesExtedned;
  extended: {
    selected: boolean;
    category: string;
    expanded: boolean;
    recomended: boolean;
  };
}

export interface TestCategory {
  tests: ExtendedTest[];
  name: string;
  code: string | CategoriesCode;
  selected: boolean;
}

export interface ChooseTestsFilterOptions {
  showSelected: boolean;
  showAllCategories: boolean;
  textToSearchBy: string;
}
