import React from "react";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import { BackToTop } from "../../_common/components/BackToTop/BackToTop";
import { FaqExpansionPanel } from "./FaqExpansionPanel";

const useStyles = makeStyles(theme => ({
  title: {
    lineHeight: 1
  },
  text: {
    margin: theme.spacing(3.5)
  },
  headerBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(3.5)
    }
  },
  textPageContainer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3.5)
    }
  }
}));

const panelsConfig = [
  {
    title: "What is Automation Hub?",
    testId: "what-is-automator",
    pendoId: "what-is-automator",
    content: (
      <div data-cy="what-is-automator-text">
        <Typography variant="body1" gutterBottom paragraph>
          Engineering teams often release code without understanding the impact it can have on the overall SEO
          performance of the website.
        </Typography>
        <Typography variant="body1" gutterBottom paragraph>
          This creates risk for sudden losses of traffic, rankings and in turn overall revenue.
        </Typography>
        <Typography variant="body1" gutterBottom paragraph>
          DeepCrawl’s Automation Hub gives development teams the ability to test their code for SEO impact before
          pushing to production with a smart, automated and frictionless tool that allows for better collaboration
          between development &amp; SEO/Marketing teams.
        </Typography>
      </div>
    )
  },
  {
    title: "What CI/CD tools does Automation Hub integrate with?",
    testId: "what-ci-cd-tools",
    pendoId: "what-ci-cd-tools",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        Automation Hub can connect to all major CI /CD tools via API and instructions are provided within the setup
        process.
      </Typography>
    )
  },
  {
    title: "How do I integrate with my CI/CD tool?",
    testId: "how-do-i-integrate",
    pendoId: "how-do-i-integrate",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        Automation Hub can connect to all major CI /CD tools via API and instructions are provided within the setup
        process.
      </Typography>
    )
  },
  {
    title: "What are test suites?",
    testId: "what-are-test-suites",
    pendoId: "what-are-test-suites",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        A Test Suite is a group of tests you wish to run across a specific domain. You can create multiple Test Suites
        for each domain should you wish to group them based on different outcomes for example site speed, stability,
        missing pages.
      </Typography>
    )
  },
  {
    title: "Can you copy test suites?",
    testId: "can-you-copy-testsuites",
    pendoId: "can-you-copy-testsuites",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        Automation Hub allows you to easily duplicate test suites to save you valuable time. This means you don’t have
        to re-enter configuration details for each staging environment. The duplicate will not copy across any URL lists
        and will automatically be set to a web crawl.
      </Typography>
    )
  },
  {
    title: "How many test suites can I create?",
    testId: "how-many-test-suites",
    pendoId: "how-many-test-suites",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        There are no limits to the number of test suites you can create.
      </Typography>
    )
  },
  {
    title: "How many URLs does Automation Hub crawl?",
    testId: "how-many-urls",
    pendoId: "how-many-urls",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        You can choose how many URLs to crawl up to a maximum of 10,000.
      </Typography>
    )
  },
  {
    title: "Can Automation Hub crawl sites that are behind a firewall?",
    testId: "behind-a-firewall",
    pendoId: "behind-a-firewall",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        Yes, we provide a selection of options for users for authentication and whitelabeling like user/password
        authentication and custom DNS set up.
      </Typography>
    )
  },
  {
    title: "What authentication options are available?",
    testId: "whitelisting",
    pendoId: "whitelisting",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        As well as Password authentication you can use custom DNS and/or whitelisted IP.
      </Typography>
    )
  },
  {
    title: "How do notifications work?",
    testId: "notifications",
    pendoId: "notifications",
    content: (
      <Typography variant="body1" gutterBottom paragraph>
        Notifications can be sent at the end of each test via Email or Slack
      </Typography>
    )
  },
  {
    title: "What do you mean by Fail or Warning?",
    testId: "failure-or-warning",
    pendoId: "failure-or-warning",
    content: (
      <div>
        <Typography variant="body1" gutterBottom paragraph>
          Automation Hub can run over 160 different tests on your domain.
        </Typography>
        <Typography variant="body1" gutterBottom paragraph>
          Each test can be set with a severity level of Fail or Warning. A Fail will stop your build and a Warning will
          send a notification either via Email or Slack but allow the build to continue.
        </Typography>
        <Typography variant="body1" gutterBottom paragraph>
          You can apply a threshold against each test and can choose between a percentage or an absolute number.
        </Typography>
      </div>
    )
  }
];

export function Faqs() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.textPageContainer} id="faq" data-testid="faq">
        <BackToTop />
        <Grid container>
          <Grid item xs={12} className={classes.headerBox}>
            <PageHeader mainText="FAQs" includeAddTestSuite={false} includeGoTo={true} pendoIdSuffix="faq" />
          </Grid>
          <Grid item xs={12} md={8} className={classes.text}>
            {panelsConfig.map((config, index) => (
              <FaqExpansionPanel
                config={config}
                index={index}
                key={index}
                isDefaultExpanded={index === 0}
                data-pendo={`auto-faq-${config.pendoId}`}
              />
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
