import { withStyles, Button } from "@material-ui/core";

export const CancelButton = withStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    background: "#FFF",
    fontSize: 14,
    fontWeight: 600,
    height: 40,
    "&:hover": {
      border: `1px solid ${theme.palette.navy[500]}`,
      background: "#FFF"
    },
    "&:focused": {
      border: `1px solid ${theme.palette.navy[500]}`,
      background: "#FFF"
    }
  }
}))(Button);
