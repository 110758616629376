export const ValidationErrors = Object.freeze({
  // Login
  UsernameAndPassword: "Please fill in this field.",
  // Connect Site
  TestSuiteName: "Please provide a name for your test suite",
  TestSuiteNameMaxLength: "Test suite name is too long. The maximum number of characters is 255.",
  HttpProtocol: "HTTP or HTTPS protocol required",
  SitePrimary: "Please provide the domain/subdomain of the site you wish to test",
  SiteTestUser: "Please provide your username",
  Hostname: "Please provide a hostname",
  IpAddress: "Please provide an IP address",
  IpAddressValidity: "Please enter a valid IP address e.g. 12.34.56.78",
  CustomDnsDuplication: "No duplicate pairs please",
  // Crawl Settings
  RobotsOverwrite: "Robots overwrite must be non-empty when this option is enabled",
  EmptyPageThreshold: "Please provide a value for Empty page threshold e.g. 512",
  MaxContentSize: "Please provide a value for Max content size e.g. 51,200",
  MaxDescriptionLength: "Please provide a value for Max description length e.g. 230",
  MaxExternalLinks: "Please provide a value for Max external links e.g. 10",
  MaxHtmlSize: "Please provide a value for Max HTML size e.g. 204,800",
  MaxLinks: "Please provide a value for Max links e.g. 250",
  MaxLoadTime: "Please provide a value for Max fetch time e.g. 3",
  MaxRedirections: "Please provide a value for Max redirections e.g. 4",
  MaxTitleWidth: "Please provide a value for Max title width e.g. 600",
  MaxUrlLength: "Please provide a value for Max URL length e.g. 1,024",
  DuplicatePrecision: "Please provide a value (from 1 to 5) for Duplicate precision e.g. 3",
  MinDescriptionLength: "Please provide a value for Min description length e.g. 50",
  MinTitleLength: "Please provide a value for Min title length e.g. 10",
  ThinPageThreshold: "Please provide a value for Thin page threshold e.g. 3,072",
  RenderJsUrls: "External resources must be valid URLs with http(s) protocol",
  CustomExtractionLabel: "Please provide a name for this custom extraction",
  CustomExtractionRegex: "Please enter a Regex pattern",
  // Set Alerts
  EmailRequired: "Please fill in this field",
  EmailValidity: "Valid email address is required",
  SlackWebhookUnique: "Each Slack webhook must be unique",
  SlackWebhookUrl: "Slack webhook must be a valid URL",
  InvalidCustomExtractionRegex: "Please enter valid Regex. Check with Rubular"
});
