import React from "react";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { TestSuiteItemInfo } from "./TestSuiteItemInfo";
import { Paper, Grid, Box, Typography } from "@material-ui/core";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useStylesItemGrid } from "./TestSuiteListItemStyles";
import { useStyles } from "./TestSuiteListItemStartedStyles";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { BuildStatus } from "../../../generated-graphql-interfaces";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { TestSuiteItemActionsMenu } from "./TestSuiteItemActionsMenu";
import { TestCountChips } from "./TestCountChips";
import clsx from "clsx";
import { shouldCloneWarningBeVisible } from "./TestSuiteListItemUtils";
import { createLastRunText } from "../../../_common/utils/createLastRunText/createLastRunText";

export interface TestSuiteListItemStartedProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
  refetchData?: () => void;
  testSuiteJiraIntegrationId?: string | null;
}

export function TestSuiteListItemStarted(props: TestSuiteListItemStartedProps): JSX.Element {
  const classes = useStyles(props);
  const classesGrid = useStylesItemGrid();
  const accountId = useAccountRouteMatch();
  const showCloneWarning = shouldCloneWarningBeVisible(props.testSuiteUpdatedAt, props.testSuiteClonedAt);
  const history = useHistory();
  const buildTestsPath = Routes.Builds.getUrl({ accountId, testSuiteId: props.id });
  const isTestSuiteRunning = props.status === BuildStatus.Running;

  function handleLinkClick(event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (event.isDefaultPrevented() || isTestSuiteRunning) return;
    else {
      history.push(buildTestsPath);
    }
  }

  return (
    <div onClick={handleLinkClick} className={classes.showAsNotLink} data-testid="test-suite-list-item-finished">
      <div className={clsx(classes.wrapper, isTestSuiteRunning && classes.disabled)}>
        <div className={classes.border}></div>
        <Paper className={clsx(classes.root, classesGrid.root)} data-cy="test-suite-list-item" elevation={0}>
          <Box className={classesGrid.mainGrid}>
            <Box className={classesGrid.iconWrapper}>
              <BuildStatusIcon status={props.status} size={StatusIconSize.LG} tooltipVariant={true} />
            </Box>
            <Grid container className={classesGrid.textWrapper}>
              <Grid item xs={12} lg={5} className={classesGrid.itemInfoWrapper}>
                <TestSuiteItemInfo {...props} showCloneWarning={showCloneWarning} />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={4} className={classesGrid.chipsWrapper}>
                <TestCountChips
                  testSuiteId={props.id}
                  buildId={props.buildId}
                  totalTests={props.totalTests}
                  warningTests={props.warningTests}
                  failedTests={props.failedTests}
                  passedTests={props.passedTests}
                  status={props.status}
                  pendoIdSuffix="testsuitelist"
                  showLabel={true}
                />
              </Grid>
              <Grid item xs={1} lg={1}></Grid>
              <Grid item xs={12} lg={2} className={classesGrid.dateTimeWrapper}>
                <Typography
                  className={classes.lastRun}
                  data-cy="test-suite-list-item-last-run"
                  data-testid="test-suite-list-item-last-run"
                >
                  {createLastRunText(props.status, props.testSuiteUpdatedAt, props.lastRun)}
                </Typography>
              </Grid>
            </Grid>
            <Box className={classesGrid.actionsMenuWrapper}>
              <TestSuiteItemActionsMenu
                testSuiteId={props.id}
                isTestSuiteRunning={isTestSuiteRunning}
                refetchData={props.refetchData}
                testSuiteJiraIntegrationId={props.testSuiteJiraIntegrationId}
              />
            </Box>
          </Box>
        </Paper>
      </div>
    </div>
  );
}
