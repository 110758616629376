import React, { useState } from "react";
import { SlackInstructions } from "./SlackInstructions";
import { getTestSuite_node_TestSuite_slackWebhooks_nodes } from "../../../../../generated-graphql-interfaces";
import { Box, Divider, Typography, makeStyles, Button } from "@material-ui/core";
import { AddSlackWebhook } from "./AddSlackWebhook";
import { SlackWebhookRow } from "./SlackWebhookRow";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

export interface SlackTabProps {
  slackWebhooks: getTestSuite_node_TestSuite_slackWebhooks_nodes[];
  testSuiteId: string;
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3)
    }
  },
  addNewButton: {
    minWidth: 120,
    height: 36
  },
  topDivider: {
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(6, 0, 3, 0)
  },
  bottomDivider: {
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(6, 0, 3, 0)
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  }
}));

const MAX_NUMBER_OF_WEBHOOKS = 100; // this limit is currently not enforced by the API

export function SlackTab(props: SlackTabProps): JSX.Element {
  const { slackWebhooks } = props;
  const classes = useStyles();
  const [isConfirmationBtnVisible, setIsConfirmationBtnVisible] = useState(false);

  function showConfirmationButton() {
    setIsConfirmationBtnVisible(true);
  }

  function hideConfirmationButton() {
    setIsConfirmationBtnVisible(false);
  }

  function handleAddNewWebhook() {
    setIsConfirmationBtnVisible(true);
  }

  return (
    <Box component="div">
      <Box className={classes.header} marginBottom={6}>
        <Typography className={classes.title} color="textPrimary">
          Slack notifications
        </Typography>
        <Button
          startIcon={<AddRoundedIcon />}
          variant="outlined"
          color="primary"
          data-testid="add-new-slack-webhook"
          data-pendo="auto-add-new-slack-webhook-btn"
          onClick={handleAddNewWebhook}
          className={classes.addNewButton}
          disabled={isConfirmationBtnVisible || slackWebhooks.length >= MAX_NUMBER_OF_WEBHOOKS}
        >
          New webhook
        </Button>
      </Box>
      <SlackInstructions />

      {isConfirmationBtnVisible && (
        <>
          <Divider className={classes.topDivider} />
          <AddSlackWebhook
            slackWebhooks={slackWebhooks}
            testSuiteId={props.testSuiteId}
            showConfirmationButton={showConfirmationButton}
            hideConfirmationButton={hideConfirmationButton}
          />
        </>
      )}

      {slackWebhooks.length > 0 && <Divider className={classes.bottomDivider} />}
      {slackWebhooks.map(webhook => (
        <SlackWebhookRow key={webhook.id} slackWebhook={webhook} />
      ))}
    </Box>
  );
}
