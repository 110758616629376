import { setContext } from "apollo-link-context";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHeaders = (headers: any) => {
  return {
    ...headers,
    "X-Auth-Token": localStorage.getItem("token") || "",
    accept:
      "application/vnd.deepcrawl.tray-io-integration-preview, application/vnd.deepcrawl.automator-frontend-scheduler-preview"
  };
};

export const authLink = setContext((_, { headers }) => {
  return {
    headers: getHeaders(headers)
  };
});
