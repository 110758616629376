import React, { useContext } from "react";
import { EmailTab } from "./EmailTab/EmailTab";
import { SlackTab } from "./SlackTab/SlackTab";
import { getTestSuite_node_TestSuite } from "../../../../generated-graphql-interfaces";
import MailIcon from "@material-ui/icons/Mail";
import { Box, makeStyles } from "@material-ui/core";
import { JiraTab } from "./Jira/JiraTab";
import { permissionsContext } from "../../../../_common/contexts/Permissions/Permissions.context";
import { FloatingStepSave } from "../FloatingStepSave/FoatingStepSave";
import { ResponsiveTabs, ResponsiveTabsConfig } from "../../../../_common/components/ResposiveTabs/ResponsiveTabs";
import { SlackIcon } from "../../../../_common/svg/slackIcon";
import { JiraIcon } from "../../../../_common/svg/jiraIcon";
import { StepChangeHandler } from "../../UpdateTestSuite";

export interface SetAlertsFormProps {
  onSubmit: StepChangeHandler;
  testSuite: getTestSuite_node_TestSuite;
}

const useStyles = makeStyles({
  wrapper: {
    minHeight: 300
  }
});

export function SetAlertsForm(props: SetAlertsFormProps): JSX.Element {
  const { permissions } = useContext(permissionsContext);
  const { testSuite } = props;
  const classes = useStyles();

  const jiraTab: ResponsiveTabsConfig = {
    id: "jira",
    label: "Jira",
    icon: <JiraIcon />,
    content: (
      <JiraTab testSuite={testSuite} jiraIntegration={props.testSuite.testSuiteJiraIntegration?.jiraIntegration} />
    )
  };

  const tabsConfig: ResponsiveTabsConfig[] = [
    {
      id: "email",
      label: "Email",
      icon: <MailIcon />,
      content: <EmailTab alertEmails={testSuite.emailAlerts?.nodes || []} testSuiteId={testSuite.id} />
    },
    {
      id: "slack",
      label: "Slack",
      icon: <SlackIcon />,
      content: <SlackTab slackWebhooks={testSuite.slackWebhooks?.nodes || []} testSuiteId={testSuite.id} />
    }
  ];

  const filteredTabs = permissions.jiraIntegrationAccess ? [...tabsConfig, jiraTab] : tabsConfig;

  return (
    <Box data-cy="set-alerts-content">
      <Box component="div" mb={4} className={classes.wrapper}>
        <ResponsiveTabs config={filteredTabs} label="Set notifications" />
      </Box>
      <FloatingStepSave
        label="Next step"
        dataTestId="set-alerts-save"
        dataCy="set-alerts-save"
        data-pendo="auto-testsuite-edit-add-set-alerts-save-step"
        onClick={props.onSubmit}
      />
    </Box>
  );
}
