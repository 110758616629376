import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { deleteBuildSchedule, deleteBuildScheduleVariables } from "../../../generated-graphql-interfaces";

export const deleteScheduleGql = gql`
  mutation deleteBuildSchedule($buildScheduleId: ObjectID!) {
    deleteBuildSchedule(input: { buildScheduleId: $buildScheduleId }) {
      buildSchedule {
        id
      }
    }
  }
`;

export function useDeleteBuildScheduleMutation() {
  return useMutation<deleteBuildSchedule, deleteBuildScheduleVariables>(deleteScheduleGql, {
    refetchQueries: ["getBuildSchedules"]
  });
}
