import gql from "graphql-tag";

export const createTestMutationGql = gql`
  mutation createTest(
    $hasJiraAccess: Boolean!
    $testSuiteId: ObjectID!
    $relativeThreshold: Int!
    $absoluteThreshold: Int!
    $thresholdType: ThresholdType!
    $reportTemplateCode: String!
    $severity: Severity!
    $thresholdPredicate: ThresholdPredicate!
    $createJiraTicketOnFailure: Boolean
    $jiraTicketCustomNote: String
    $automaticThresholdEnabled: Boolean
  ) {
    createTest(
      input: {
        testSuiteId: $testSuiteId
        relativeThreshold: $relativeThreshold
        absoluteThreshold: $absoluteThreshold
        thresholdType: $thresholdType
        reportTemplateCode: $reportTemplateCode
        severity: $severity
        thresholdPredicate: $thresholdPredicate
        createJiraTicketOnFailure: $createJiraTicketOnFailure
        jiraTicketCustomNote: $jiraTicketCustomNote
        automaticThresholdEnabled: $automaticThresholdEnabled
      }
    ) {
      test {
        id
        relativeThreshold
        absoluteThreshold
        thresholdType
        reportTemplateCode
        severity
        thresholdPredicate
        createJiraTicketOnFailure @include(if: $hasJiraAccess)
        jiraTicketCustomNote @include(if: $hasJiraAccess)
      }
    }
  }
`;
