import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { makeStyles, TextField, Box } from "@material-ui/core";
import { Autocomplete, Alert, createFilterOptions, Skeleton } from "@material-ui/lab";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { useGetAvailableParentTestSuites } from "../graphql/useGetAvailableParentTestSuites";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { getParentTestsuiteOptionsFromData } from "../utils/getParentTestsuiteOptionsFromData";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { defaultPopOverId } from "../../../../../_common/constants/popover";

const useStyles = makeStyles(theme => ({
  error: {
    marginBottom: theme.spacing(2)
  }
}));

export interface TestSuiteOption {
  name: string;
  id: string;
  group: string;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary"
  },
  {
    label: "Link",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "info"
  }
];

interface ApplyGlobalTemplate {
  handleApplyGlobalTemplate: (parentId: string) => Promise<void>;
  handleClose: () => void;
  testSuiteId: string;
  availableTestSuitesDetected?: (count: number) => void;
}

// eslint-disable-next-line max-statements
export function ApplyGlobalTemplate(props: ApplyGlobalTemplate) {
  const autocompleteRef = useRef(null);
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState<TestSuiteOption | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const accountId = useAccountRouteMatch();
  const { data, error, loading } = useGetAvailableParentTestSuites({ accountId });

  const filterOptions = createFilterOptions<TestSuiteOption>({
    stringify: option => option.name
  });

  const parentOptions = getParentTestsuiteOptionsFromData(data, props.testSuiteId);

  useEffect(() => {
    if (parentOptions && props.availableTestSuitesDetected) {
      props.availableTestSuitesDetected(parentOptions.length);
    }
  }, [parentOptions, props]);

  function handleLinkGlobalTemplate(id: PopoverDialogDefaultIdentifiers | string) {
    setAnchorEl(null);

    if (id === PopoverDialogDefaultIdentifiers.OK && selectedOption?.id) {
      props.handleClose();
      props.handleApplyGlobalTemplate(selectedOption.id);
    }
  }

  function handleAutocompleteChange(_: ChangeEvent<{}>, newOption: TestSuiteOption | null) {
    setSelectedOption(newOption);
    setAnchorEl(newOption ? autocompleteRef.current : null);
  }

  if (loading) {
    return <Skeleton variant="rect" height={59} data-testid="apply-global-template-loading" />;
  }

  if (error) {
    return (
      <Alert severity="error" className={classes.error}>
        An error occurred. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <Box data-testid="apply-global-template">
      {parentOptions?.length ? (
        <Autocomplete
          openOnFocus
          popupIcon={<ExpandMoreRoundedIcon />}
          ref={autocompleteRef}
          value={selectedOption}
          onChange={handleAutocompleteChange}
          id="add-parent-autocomplete"
          data-testid="add-parent-autocomplete"
          data-pendo="auto-test-suite-edit-template-settings-link-apply-global-template-select"
          options={parentOptions}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={filterOptions}
          groupBy={option => option.group}
          renderInput={params => <TextField {...params} label="Choose test suite" variant="outlined" />}
        />
      ) : (
        <Alert severity="info" data-testid="no-testsuites-available-info">
          All your test suites are currently linked to templates or no test suites are available.
        </Alert>
      )}
      <PopoverDialog
        anchorElement={anchorEl}
        handleAction={handleLinkGlobalTemplate}
        open={Boolean(anchorEl)}
        text="All your current configurations in step 2 and 3 will be overwritten."
        title="Are you sure you want to link this test suite to a global template?"
        buttons={popoverButtons}
        id={defaultPopOverId}
      />
    </Box>
  );
}
