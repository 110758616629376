import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { updateConnectSite, updateConnectSiteVariables } from "../../../../../generated-graphql-interfaces";

export const updateConnectSiteGql = gql`
  mutation updateConnectSite(
    $testSuiteId: ObjectID!
    $name: String!
    $location: TestSuiteLocationCode!
    $sitePrimary: String!
    $siteTestUser: String!
    $customHeaderUserAgent: String!
    $customHeaderUserAgentShort: String!
    $customDns: [CustomDnsSettingInput!]
    $siteTestPass: String
  ) {
    updateTestSuite(
      input: {
        testSuiteId: $testSuiteId
        name: $name
        location: $location
        sitePrimary: $sitePrimary
        siteTestUser: $siteTestUser
        siteTestPass: $siteTestPass
        customHeaderUserAgent: $customHeaderUserAgent
        customHeaderUserAgentShort: $customHeaderUserAgentShort
        customDns: $customDns
      }
    ) {
      testSuite {
        id
        name
        location {
          code
          name
        }
        sitePrimary
        siteTestUser
        hasSiteTestPass
        customHeaderUserAgent
        customHeaderUserAgentShort
        customDns {
          hostname
          ipAddress
        }
      }
    }
  }
`;

export function useUpdateConnectSiteMutation() {
  return useMutation<updateConnectSite, updateConnectSiteVariables>(updateConnectSiteGql, {
    notifyOnNetworkStatusChange: true
  });
}
