import { makeStyles, IconButton, Box, ButtonProps, IconButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { RoleCode } from "../../../generated-graphql-interfaces";
import { doesUserMatchRole } from "../../utils/doesUserMatchRole/doesUserMatchRole";
import { ActionButtonTooltip } from "../ActionButtonTooltip/ActionButtonTooltip";
import { PermissionsTooltip } from "../PermissionsTooltip/PermissionsTooltip";

const useStyles = makeStyles(theme => ({
  icon: (props: ActionIconButtonProps) => {
    return {
      marginLeft: theme.spacing(1),
      color: theme.palette.navy[500],
      padding: "10px",
      border: props.includeBorder ? `1px solid ${theme.palette.grey[200]}` : "none",
      background: props.backgroundColor ? props.backgroundColor : "white"
    };
  },
  info: {
    "&:hover": {
      "& svg": {
        color: theme.palette.secondary.main
      }
    }
  },
  error: {
    "&:hover": {
      "& svg": {
        color: theme.palette.error.main
      }
    }
  },
  active: (props: ActionIconButtonProps) => {
    return {
      backgroundColor: props.variant === "error" ? theme.palette.error.main : theme.palette.secondary.main,
      "& svg": {
        color: "#FFF"
      }
    };
  }
}));

interface ActionIconButtonProps extends Omit<IconButtonProps, "variant"> {
  variant: "info" | "error";
  backgroundColor?: "white";
  icon: JSX.Element;
  tooltipTitle?: string;
  isActive?: boolean;
  disableForViewer?: boolean;
  includeBorder?: boolean;
}

export function ActionIconButton(props: ActionIconButtonProps) {
  const {
    variant,
    backgroundColor,
    icon,
    tooltipTitle,
    isActive,
    disableForViewer,
    disabled,
    includeBorder,
    ...buttonProps
  } = props;

  const classes = useStyles(props);
  const isInfoVariant = variant === "info";
  const isViewerRole = doesUserMatchRole(RoleCode.Viewer);
  const isDisabled = isViewerRole && disableForViewer ? true : disabled;

  const button = (
    <IconButton
      disabled={isDisabled}
      className={clsx(
        classes.icon,
        isInfoVariant && classes.info,
        !isInfoVariant && classes.error,
        isActive && classes.active,
        "actionIcon"
      )}
      {...buttonProps}
    >
      {icon}
    </IconButton>
  );

  if (isViewerRole && disableForViewer) {
    return <PermissionsTooltip>{button}</PermissionsTooltip>;
  }

  return tooltipTitle ? (
    <ActionButtonTooltip title={tooltipTitle} margintop="20px" data-testid="action-icon-button-tooltip">
      <span>{button}</span>
    </ActionButtonTooltip>
  ) : (
    button
  );
}
