/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUser
// ====================================================

export interface getUser_me_accounts_nodes {
  __typename: "Account";
  id: any;
  name: string | null;
}

export interface getUser_me_accounts {
  __typename: "AccountConnection";
  /**
   * A list of nodes.
   */
  nodes: getUser_me_accounts_nodes[];
}

export interface getUser_me_accountsUsers_nodes_account_featureFlags {
  __typename: "FeatureFlag";
  name: string;
  enabled: boolean;
}

export interface getUser_me_accountsUsers_nodes_account {
  __typename: "Account";
  id: any;
  featureFlags: getUser_me_accountsUsers_nodes_account_featureFlags[];
}

export interface getUser_me_accountsUsers_nodes {
  __typename: "AccountUser";
  roleCode: RoleCode;
  account: getUser_me_accountsUsers_nodes_account;
}

export interface getUser_me_accountsUsers {
  __typename: "AccountUserConnection";
  /**
   * A list of nodes.
   */
  nodes: getUser_me_accountsUsers_nodes[];
}

export interface getUser_me {
  __typename: "User";
  id: any;
  firstName: string | null;
  lastName: string | null;
  username: string;
  accounts: getUser_me_accounts;
  accountsUsers: getUser_me_accountsUsers;
}

export interface getUser {
  /**
   * The currently authenticated user.
   */
  me: getUser_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAndRunManualBuild
// ====================================================

export interface createAndRunManualBuild_createAndRunManualBuild_build {
  __typename: "Build";
  id: any;
}

export interface createAndRunManualBuild_createAndRunManualBuild {
  __typename: "CreateAndRunBuildPayload";
  build: createAndRunManualBuild_createAndRunManualBuild_build;
}

export interface createAndRunManualBuild {
  /**
   * Creates a build of trigger type Manual and immediately runs a crawl for it.
   */
  createAndRunManualBuild: createAndRunManualBuild_createAndRunManualBuild;
}

export interface createAndRunManualBuildVariables {
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptTermsAndConditions
// ====================================================

export interface acceptTermsAndConditions_acceptTermsAndConditions {
  __typename: "User";
  termsAgreed: boolean;
  id: any;
}

export interface acceptTermsAndConditions {
  acceptTermsAndConditions: acceptTermsAndConditions_acceptTermsAndConditions;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserWithTerms
// ====================================================

export interface getUserWithTerms_me {
  __typename: "User";
  id: any;
  termsAgreed: boolean;
}

export interface getUserWithTerms {
  /**
   * The currently authenticated user.
   */
  me: getUserWithTerms_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBuildTests
// ====================================================

export interface getBuildTests_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getBuildTests_node_TestSuite_tests {
  __typename: "TestConnection";
  /**
   * Identifies the total count of Tests in the connection.
   */
  totalCount: number;
}

export interface getBuildTests_node_TestSuite_builds_nodes {
  __typename: "Build";
  id: any;
  ciBuildId: string | null;
  passedTestCount: number | null;
  warnedTestCount: number | null;
  failedTestCount: number | null;
  createdAt: any;
  finishedAt: any | null;
  updatedAt: any;
  status: BuildStatus;
  passed: boolean;
  triggerType: BuildTriggerType;
}

export interface getBuildTests_node_TestSuite_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getBuildTests_node_TestSuite_builds_nodes[];
}

export interface getBuildTests_node_TestSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
  tests: getBuildTests_node_TestSuite_tests;
  builds: getBuildTests_node_TestSuite_builds;
}

export type getBuildTests_node = getBuildTests_node_Account | getBuildTests_node_TestSuite;

export interface getBuildTests {
  /**
   * Fetches an object given its ID.
   */
  node: getBuildTests_node | null;
}

export interface getBuildTestsVariables {
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteJiraIntegration
// ====================================================

export interface deleteJiraIntegration_deleteJiraIntegration_jiraIntegration {
  __typename: "JiraIntegration";
  name: string;
  id: any;
}

export interface deleteJiraIntegration_deleteJiraIntegration {
  __typename: "DeleteJiraIntegrationPayload";
  jiraIntegration: deleteJiraIntegration_deleteJiraIntegration_jiraIntegration;
}

export interface deleteJiraIntegration {
  deleteJiraIntegration: deleteJiraIntegration_deleteJiraIntegration;
}

export interface deleteJiraIntegrationVariables {
  jiraIntegrationId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraIntegrations
// ====================================================

export interface getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations {
  __typename: "TestSuiteJiraIntegrationConnection";
  /**
   * Identifies the total count of TestSuiteJiraIntegrations in the connection.
   */
  totalCount: number;
}

export interface getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes {
  __typename: "JiraIntegration";
  id: any;
  name: string;
  jiraUrl: string;
  jiraProjectId: string;
  jiraIssueTypeId: string;
  jiraProjectName: string;
  jiraIssueTypeName: string;
  testSuiteJiraIntegrations: getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations;
}

export interface getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations {
  __typename: "JiraIntegrationConnection";
  /**
   * Identifies the total count of JiraIntegrations in the connection.
   */
  totalCount: number;
  /**
   * A list of nodes.
   */
  nodes: getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes[];
}

export interface getJiraIntegrations_getAccount_jiraAuthentications_nodes {
  __typename: "JiraAuthentication";
  id: any;
  name: string;
  jiraIntegrations: getJiraIntegrations_getAccount_jiraAuthentications_nodes_jiraIntegrations;
}

export interface getJiraIntegrations_getAccount_jiraAuthentications {
  __typename: "JiraAuthenticationConnection";
  /**
   * A list of nodes.
   */
  nodes: getJiraIntegrations_getAccount_jiraAuthentications_nodes[];
}

export interface getJiraIntegrations_getAccount {
  __typename: "Account";
  id: any;
  jiraAuthentications: getJiraIntegrations_getAccount_jiraAuthentications;
}

export interface getJiraIntegrations {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getJiraIntegrations_getAccount | null;
}

export interface getJiraIntegrationsVariables {
  accountId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraIntegrationsWithTestSuites
// ====================================================

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations_nodes_testSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations_nodes {
  __typename: "TestSuiteJiraIntegration";
  id: any;
  testSuite: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations_nodes_testSuite;
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations {
  __typename: "TestSuiteJiraIntegrationConnection";
  /**
   * Identifies the total count of TestSuiteJiraIntegrations in the connection.
   */
  totalCount: number;
  /**
   * A list of nodes.
   */
  nodes: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations_nodes[];
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes {
  __typename: "JiraIntegration";
  id: any;
  name: string;
  jiraUrl: string;
  jiraProjectId: string;
  jiraIssueTypeId: string;
  jiraProjectName: string;
  jiraIssueTypeName: string;
  testSuiteJiraIntegrations: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes_testSuiteJiraIntegrations;
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations {
  __typename: "JiraIntegrationConnection";
  /**
   * Identifies the total count of JiraIntegrations in the connection.
   */
  totalCount: number;
  /**
   * A list of nodes.
   */
  nodes: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations_nodes[];
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes {
  __typename: "JiraAuthentication";
  id: any;
  name: string;
  jiraIntegrations: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes_jiraIntegrations;
}

export interface getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications {
  __typename: "JiraAuthenticationConnection";
  /**
   * A list of nodes.
   */
  nodes: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications_nodes[];
}

export interface getJiraIntegrationsWithTestSuites_getAccount {
  __typename: "Account";
  id: any;
  jiraAuthentications: getJiraIntegrationsWithTestSuites_getAccount_jiraAuthentications;
}

export interface getJiraIntegrationsWithTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getJiraIntegrationsWithTestSuites_getAccount | null;
}

export interface getJiraIntegrationsWithTestSuitesVariables {
  accountId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAutomatorAccountStatistics
// ====================================================

export interface getAutomatorAccountStatistics_getAutomatorAccountStatistics {
  __typename: "AutomatorAccountStatistics";
  failedTestPercentage: number;
  passedTestPercentage: number;
  warnedTestPercentage: number;
  testSuitesCount: number;
  buildsCount: number;
  testsPerformedCount: number;
  averageUrlsCrawledPerDay: number;
}

export interface getAutomatorAccountStatistics {
  /**
   * Look up automator account statistics by account ObjectID.
   */
  getAutomatorAccountStatistics: getAutomatorAccountStatistics_getAutomatorAccountStatistics;
}

export interface getAutomatorAccountStatisticsVariables {
  accountId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteBuilds
// ====================================================

export interface getTestSuiteBuilds_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges_node_reportTemplate {
  __typename: "ReportTemplate";
  name: string;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges_node {
  __typename: "TestResult";
  reportTemplate: getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges_node_reportTemplate;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges {
  __typename: "TestResultEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges_node;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests {
  __typename: "TestResultConnection";
  /**
   * Identifies the total count of TestResults in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests_edges[];
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges_node_reportTemplate {
  __typename: "ReportTemplate";
  name: string;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges_node {
  __typename: "TestResult";
  reportTemplate: getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges_node_reportTemplate;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges {
  __typename: "TestResultEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges_node;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests {
  __typename: "TestResultConnection";
  /**
   * Identifies the total count of TestResults in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests_edges[];
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges_node_reportTemplate {
  __typename: "ReportTemplate";
  name: string;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges_node {
  __typename: "TestResult";
  reportTemplate: getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges_node_reportTemplate;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges {
  __typename: "TestResultEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges_node;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests {
  __typename: "TestResultConnection";
  /**
   * Identifies the total count of TestResults in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests_edges[];
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges_node {
  __typename: "Build";
  passed: boolean;
  passedTests: getTestSuiteBuilds_node_TestSuite_builds_edges_node_passedTests;
  warnedTests: getTestSuiteBuilds_node_TestSuite_builds_edges_node_warnedTests;
  failedTests: getTestSuiteBuilds_node_TestSuite_builds_edges_node_failedTests;
  status: BuildStatus;
  id: any;
  finishedAt: any | null;
  failedTestCount: number | null;
  passedTestCount: number | null;
  warnedTestCount: number | null;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_edges {
  __typename: "BuildEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteBuilds_node_TestSuite_builds_edges_node;
}

export interface getTestSuiteBuilds_node_TestSuite_builds_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface getTestSuiteBuilds_node_TestSuite_builds {
  __typename: "BuildConnection";
  /**
   * Identifies the total count of Builds in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuiteBuilds_node_TestSuite_builds_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getTestSuiteBuilds_node_TestSuite_builds_pageInfo;
}

export interface getTestSuiteBuilds_node_TestSuite {
  __typename: "TestSuite";
  id: any;
  builds: getTestSuiteBuilds_node_TestSuite_builds;
}

export type getTestSuiteBuilds_node = getTestSuiteBuilds_node_Account | getTestSuiteBuilds_node_TestSuite;

export interface getTestSuiteBuilds {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuiteBuilds_node | null;
}

export interface getTestSuiteBuildsVariables {
  testSuiteId: any;
  startDate: any;
  endDate: any;
  first: number;
  after: string;
  order: OrderDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuites
// ====================================================

export interface getTestSuites_getAccount_testSuites_edges_node_tests {
  __typename: "TestConnection";
  /**
   * Identifies the total count of Tests in the connection.
   */
  totalCount: number;
}

export interface getTestSuites_getAccount_testSuites_edges_node_builds_nodes {
  __typename: "Build";
  id: any;
  createdAt: any;
  updatedAt: any;
  finishedAt: any | null;
  failedTestCount: number | null;
  warnedTestCount: number | null;
  passedTestCount: number | null;
  passed: boolean;
  status: BuildStatus;
}

export interface getTestSuites_getAccount_testSuites_edges_node_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuites_getAccount_testSuites_edges_node_builds_nodes[];
}

export interface getTestSuites_getAccount_testSuites_edges_node {
  __typename: "TestSuite";
  id: any;
  name: string;
  sitePrimary: string;
  createdAt: any;
  tests: getTestSuites_getAccount_testSuites_edges_node_tests;
  builds: getTestSuites_getAccount_testSuites_edges_node_builds;
}

export interface getTestSuites_getAccount_testSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * A cursor to use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: getTestSuites_getAccount_testSuites_edges_node;
}

export interface getTestSuites_getAccount_testSuites_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface getTestSuites_getAccount_testSuites {
  __typename: "TestSuiteConnection";
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuites_getAccount_testSuites_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getTestSuites_getAccount_testSuites_pageInfo;
}

export interface getTestSuites_getAccount {
  __typename: "Account";
  id: any;
  testSuites: getTestSuites_getAccount_testSuites | null;
}

export interface getTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getTestSuites_getAccount | null;
}

export interface getTestSuitesVariables {
  accountId: any;
  cursor?: string | null;
  perRequest: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBuildScheduleWithTestSuite
// ====================================================

export interface getBuildScheduleWithTestSuite_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuite" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild {
  __typename: "Build";
  id: any;
  status: BuildStatus;
  finishedAt: any | null;
  ciBuildId: string | null;
  updatedAt: any;
  passed: boolean;
  failedTestCount: number | null;
  passedTestCount: number | null;
  warnedTestCount: number | null;
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite_tests {
  __typename: "TestConnection";
  /**
   * Identifies the total count of Tests in the connection.
   */
  totalCount: number;
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
  sitePrimary: string;
  updatedAt: any;
  clonedAt: any | null;
  tests: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite_tests;
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes {
  __typename: "BuildScheduleTestSuite";
  lastRunBuild: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild | null;
  id: any;
  testSuite: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite;
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites {
  __typename: "BuildScheduleTestSuiteConnection";
  /**
   * Identifies the total count of BuildScheduleTestSuites in the connection.
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_pageInfo;
  /**
   * A list of nodes.
   */
  nodes: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes[];
}

export interface getBuildScheduleWithTestSuite_node_BuildSchedule {
  __typename: "BuildSchedule";
  id: any;
  name: string;
  repetitionRate: BuildScheduleRepetitionRate | null;
  startAt: any;
  nextRunAt: any | null;
  buildScheduleTestSuites: getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites;
}

export type getBuildScheduleWithTestSuite_node = getBuildScheduleWithTestSuite_node_Account | getBuildScheduleWithTestSuite_node_BuildSchedule;

export interface getBuildScheduleWithTestSuite {
  /**
   * Fetches an object given its ID.
   */
  node: getBuildScheduleWithTestSuite_node | null;
}

export interface getBuildScheduleWithTestSuiteVariables {
  buildScheduleId: any;
  itemsPerRequest: number;
  cursor: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBuildSchedules
// ====================================================

export interface getBuildSchedules_getAccount_buildSchedules_nodes_buildScheduleTestSuites {
  __typename: "BuildScheduleTestSuiteConnection";
  /**
   * Identifies the total count of BuildScheduleTestSuites in the connection.
   */
  totalCount: number;
}

export interface getBuildSchedules_getAccount_buildSchedules_nodes {
  __typename: "BuildSchedule";
  name: string;
  id: any;
  repetitionRate: BuildScheduleRepetitionRate | null;
  startAt: any;
  buildScheduleTestSuites: getBuildSchedules_getAccount_buildSchedules_nodes_buildScheduleTestSuites;
}

export interface getBuildSchedules_getAccount_buildSchedules {
  __typename: "BuildScheduleConnection";
  /**
   * Identifies the total count of BuildSchedules in the connection.
   */
  totalCount: number;
  /**
   * A list of nodes.
   */
  nodes: getBuildSchedules_getAccount_buildSchedules_nodes[];
}

export interface getBuildSchedules_getAccount {
  __typename: "Account";
  id: any;
  buildSchedules: getBuildSchedules_getAccount_buildSchedules;
}

export interface getBuildSchedules {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getBuildSchedules_getAccount | null;
}

export interface getBuildSchedulesVariables {
  accountId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getScheduleForScheduleSettings
// ====================================================

export interface getScheduleForScheduleSettings_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuite" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites_nodes {
  __typename: "BuildScheduleTestSuite";
  testSuite: getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites_nodes_testSuite;
}

export interface getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites {
  __typename: "BuildScheduleTestSuiteConnection";
  /**
   * A list of nodes.
   */
  nodes: getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites_nodes[];
}

export interface getScheduleForScheduleSettings_node_BuildSchedule {
  __typename: "BuildSchedule";
  id: any;
  name: string;
  repetitionRate: BuildScheduleRepetitionRate | null;
  startAt: any;
  buildScheduleTestSuites: getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites;
}

export type getScheduleForScheduleSettings_node = getScheduleForScheduleSettings_node_Account | getScheduleForScheduleSettings_node_BuildSchedule;

export interface getScheduleForScheduleSettings {
  /**
   * Fetches an object given its ID.
   */
  node: getScheduleForScheduleSettings_node | null;
}

export interface getScheduleForScheduleSettingsVariables {
  buildScheduleId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBuildSchedule
// ====================================================

export interface updateBuildSchedule_updateBuildSchedule_buildSchedule {
  __typename: "BuildSchedule";
  id: any;
}

export interface updateBuildSchedule_updateBuildSchedule {
  __typename: "UpdateBuildSchedulePayload";
  buildSchedule: updateBuildSchedule_updateBuildSchedule_buildSchedule;
}

export interface updateBuildSchedule {
  updateBuildSchedule: updateBuildSchedule_updateBuildSchedule;
}

export interface updateBuildScheduleVariables {
  input: UpdateBuildScheduleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteOptionsForSchedule
// ====================================================

export interface getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites_edges_node {
  __typename: "TestSuite";
  id: any;
  name: string;
}

export interface getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites_edges_node;
}

export interface getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites {
  __typename: "TestSuiteConnection";
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites_edges[];
}

export interface getTestSuiteOptionsForSchedule_getAccount {
  __typename: "Account";
  id: any;
  unscheduledTestSuites: getTestSuiteOptionsForSchedule_getAccount_unscheduledTestSuites | null;
}

export interface getTestSuiteOptionsForSchedule {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getTestSuiteOptionsForSchedule_getAccount | null;
}

export interface getTestSuiteOptionsForScheduleVariables {
  accountId: any;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unlinkTestSuiteFromBuildSchedule
// ====================================================

export interface unlinkTestSuiteFromBuildSchedule_unlinkTestSuiteFromBuildSchedule_buildScheduleTestSuite {
  __typename: "BuildScheduleTestSuite";
  id: any;
}

export interface unlinkTestSuiteFromBuildSchedule_unlinkTestSuiteFromBuildSchedule {
  __typename: "UnlinkTestSuiteFromBuildSchedulePayload";
  buildScheduleTestSuite: unlinkTestSuiteFromBuildSchedule_unlinkTestSuiteFromBuildSchedule_buildScheduleTestSuite;
}

export interface unlinkTestSuiteFromBuildSchedule {
  unlinkTestSuiteFromBuildSchedule: unlinkTestSuiteFromBuildSchedule_unlinkTestSuiteFromBuildSchedule;
}

export interface unlinkTestSuiteFromBuildScheduleVariables {
  buildScheduleTestSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBuildSchedule
// ====================================================

export interface createBuildSchedule_createBuildSchedule_buildSchedule {
  __typename: "BuildSchedule";
  id: any;
}

export interface createBuildSchedule_createBuildSchedule {
  __typename: "CreateBuildSchedulePayload";
  buildSchedule: createBuildSchedule_createBuildSchedule_buildSchedule;
}

export interface createBuildSchedule {
  createBuildSchedule: createBuildSchedule_createBuildSchedule;
}

export interface createBuildScheduleVariables {
  input: CreateBuildScheduleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBuildSchedule
// ====================================================

export interface deleteBuildSchedule_deleteBuildSchedule_buildSchedule {
  __typename: "BuildSchedule";
  id: any;
}

export interface deleteBuildSchedule_deleteBuildSchedule {
  __typename: "DeleteBuildSchedulePayload";
  buildSchedule: deleteBuildSchedule_deleteBuildSchedule_buildSchedule;
}

export interface deleteBuildSchedule {
  deleteBuildSchedule: deleteBuildSchedule_deleteBuildSchedule;
}

export interface deleteBuildScheduleVariables {
  buildScheduleId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setBuildScheduleTestSuites
// ====================================================

export interface setBuildScheduleTestSuites_setBuildScheduleTestSuites_buildScheduleTestSuites {
  __typename: "BuildScheduleTestSuite";
  id: any;
}

export interface setBuildScheduleTestSuites_setBuildScheduleTestSuites {
  __typename: "SetBuildScheduleTestSuitesPayload";
  buildScheduleTestSuites: setBuildScheduleTestSuites_setBuildScheduleTestSuites_buildScheduleTestSuites[];
}

export interface setBuildScheduleTestSuites {
  setBuildScheduleTestSuites: setBuildScheduleTestSuites_setBuildScheduleTestSuites;
}

export interface setBuildScheduleTestSuitesVariables {
  buildScheduleId: any;
  testSuiteIds: any[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getAuthToken
// ====================================================

export interface getAuthToken_createSessionUsingUsername {
  __typename: "Session";
  token: string;
}

export interface getAuthToken {
  /**
   * Creates new session using username and password.
   */
  createSessionUsingUsername: getAuthToken_createSessionUsingUsername;
}

export interface getAuthTokenVariables {
  password: string;
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTest
// ====================================================

export interface createTest_createTest_test {
  __typename: "Test";
  id: any;
  /**
   * Percentage of pages in the report (relative to all crawled pages) that will fail the test.
   */
  relativeThreshold: number;
  /**
   * The absolute number of pages in the report that will fail the test.
   */
  absoluteThreshold: number;
  /**
   * Type of threshold used to calculate test results.
   */
  thresholdType: ThresholdType;
  reportTemplateCode: string;
  severity: Severity;
  /**
   * Relational operator for threshold used to calculate test results.
   */
  thresholdPredicate: ThresholdPredicate;
  createJiraTicketOnFailure: boolean;
  jiraTicketCustomNote: string | null;
}

export interface createTest_createTest {
  __typename: "CreateTestPayload";
  test: createTest_createTest_test;
}

export interface createTest {
  createTest: createTest_createTest;
}

export interface createTestVariables {
  hasJiraAccess: boolean;
  testSuiteId: any;
  relativeThreshold: number;
  absoluteThreshold: number;
  thresholdType: ThresholdType;
  reportTemplateCode: string;
  severity: Severity;
  thresholdPredicate: ThresholdPredicate;
  createJiraTicketOnFailure?: boolean | null;
  jiraTicketCustomNote?: string | null;
  automaticThresholdEnabled?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTest
// ====================================================

export interface deleteTest_deleteTest_test {
  __typename: "Test";
  id: any;
  /**
   * Percentage of pages in the report (relative to all crawled pages) that will fail the test.
   */
  relativeThreshold: number;
  /**
   * The absolute number of pages in the report that will fail the test.
   */
  absoluteThreshold: number;
  /**
   * Type of threshold used to calculate test results.
   */
  thresholdType: ThresholdType;
  reportTemplateCode: string;
  severity: Severity;
}

export interface deleteTest_deleteTest {
  __typename: "DeleteTestPayload";
  test: deleteTest_deleteTest_test;
}

export interface deleteTest {
  deleteTest: deleteTest_deleteTest;
}

export interface deleteTestVariables {
  testId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getReportTemplates
// ====================================================

export interface getReportTemplates_getAutomatorReportTemplates_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface getReportTemplates_getAutomatorReportTemplates_edges_node {
  __typename: "ReportTemplate";
  id: any;
  categories: string[];
  name: string;
  code: string;
  automatorSummary: string | null;
}

export interface getReportTemplates_getAutomatorReportTemplates_edges {
  __typename: "ReportTemplateEdge";
  /**
   * A cursor to use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: getReportTemplates_getAutomatorReportTemplates_edges_node;
}

export interface getReportTemplates_getAutomatorReportTemplates {
  __typename: "ReportTemplateConnection";
  /**
   * Identifies the total count of ReportTemplates in the connection.
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: getReportTemplates_getAutomatorReportTemplates_pageInfo;
  /**
   * A list of edges.
   */
  edges: getReportTemplates_getAutomatorReportTemplates_edges[];
}

export interface getReportTemplates {
  /**
   * Find Automator Report Templates by connection arguments.
   */
  getAutomatorReportTemplates: getReportTemplates_getAutomatorReportTemplates;
}

export interface getReportTemplatesVariables {
  after: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTest
// ====================================================

export interface updateTest_updateTest_test {
  __typename: "Test";
  id: any;
  /**
   * Percentage of pages in the report (relative to all crawled pages) that will fail the test.
   */
  relativeThreshold: number;
  /**
   * The absolute number of pages in the report that will fail the test.
   */
  absoluteThreshold: number;
  /**
   * Type of threshold used to calculate test results.
   */
  thresholdType: ThresholdType;
  reportTemplateCode: string;
  severity: Severity;
  /**
   * Relational operator for threshold used to calculate test results.
   */
  thresholdPredicate: ThresholdPredicate;
  createJiraTicketOnFailure: boolean;
  jiraTicketCustomNote: string | null;
}

export interface updateTest_updateTest {
  __typename: "UpdateTestPayload";
  test: updateTest_updateTest_test;
}

export interface updateTest {
  updateTest: updateTest_updateTest;
}

export interface updateTestVariables {
  hasJiraAccess: boolean;
  testId: any;
  relativeThreshold?: number | null;
  absoluteThreshold?: number | null;
  thresholdType?: ThresholdType | null;
  severity?: Severity | null;
  thresholdPredicate?: ThresholdPredicate | null;
  createJiraTicketOnFailure?: boolean | null;
  jiraTicketCustomNote?: string | null;
  automaticThresholdEnabled?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAutomaticThreshold
// ====================================================

export interface updateAutomaticThreshold_updateTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
}

export interface updateAutomaticThreshold_updateTestSuite {
  __typename: "UpdateTestSuitePayload";
  testSuite: updateAutomaticThreshold_updateTestSuite_testSuite;
}

export interface updateAutomaticThreshold {
  updateTestSuite: updateAutomaticThreshold_updateTestSuite;
}

export interface updateAutomaticThresholdVariables {
  testSuiteId: any;
  automaticThreshold: boolean;
  automaticThresholdEnabledForAll: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLocations
// ====================================================

export interface getLocations_getTestSuiteLocations {
  __typename: "Location";
  code: LocationCode;
  name: string;
}

export interface getLocations {
  /**
   * List of locations available for test suite.
   */
  getTestSuiteLocations: getLocations_getTestSuiteLocations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTestSuite
// ====================================================

export interface createTestSuite_createTestSuite_testSuite_location {
  __typename: "Location";
  code: LocationCode;
  name: string;
}

export interface createTestSuite_createTestSuite_testSuite_customDns {
  __typename: "CustomDnsSetting";
  hostname: string;
  ipAddress: string;
}

export interface createTestSuite_createTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
  location: createTestSuite_createTestSuite_testSuite_location;
  sitePrimary: string;
  siteTestUser: string | null;
  hasSiteTestPass: boolean;
  customHeaderUserAgent: string | null;
  customHeaderUserAgentShort: string | null;
  customDns: createTestSuite_createTestSuite_testSuite_customDns[] | null;
}

export interface createTestSuite_createTestSuite {
  __typename: "CreateTestSuitePayload";
  testSuite: createTestSuite_createTestSuite_testSuite;
}

export interface createTestSuite {
  createTestSuite: createTestSuite_createTestSuite;
}

export interface createTestSuiteVariables {
  accountId: any;
  name: string;
  location: TestSuiteLocationCode;
  sitePrimary: string;
  siteTestUser: string;
  customHeaderUserAgent: string;
  customHeaderUserAgentShort: string;
  customDns?: CustomDnsSettingInput[] | null;
  siteTestPass?: string | null;
  automaticThreshold?: boolean | null;
  automaticThresholdEnabledForAll?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateConnectSite
// ====================================================

export interface updateConnectSite_updateTestSuite_testSuite_location {
  __typename: "Location";
  code: LocationCode;
  name: string;
}

export interface updateConnectSite_updateTestSuite_testSuite_customDns {
  __typename: "CustomDnsSetting";
  hostname: string;
  ipAddress: string;
}

export interface updateConnectSite_updateTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
  location: updateConnectSite_updateTestSuite_testSuite_location;
  sitePrimary: string;
  siteTestUser: string | null;
  hasSiteTestPass: boolean;
  customHeaderUserAgent: string | null;
  customHeaderUserAgentShort: string | null;
  customDns: updateConnectSite_updateTestSuite_testSuite_customDns[] | null;
}

export interface updateConnectSite_updateTestSuite {
  __typename: "UpdateTestSuitePayload";
  testSuite: updateConnectSite_updateTestSuite_testSuite;
}

export interface updateConnectSite {
  updateTestSuite: updateConnectSite_updateTestSuite;
}

export interface updateConnectSiteVariables {
  testSuiteId: any;
  name: string;
  location: TestSuiteLocationCode;
  sitePrimary: string;
  siteTestUser: string;
  customHeaderUserAgent: string;
  customHeaderUserAgentShort: string;
  customDns?: CustomDnsSettingInput[] | null;
  siteTestPass?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCrawlSettings
// ====================================================

export interface updateCrawlSettings_updateTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
}

export interface updateCrawlSettings_updateTestSuite {
  __typename: "UpdateTestSuitePayload";
  testSuite: updateCrawlSettings_updateTestSuite_testSuite;
}

export interface updateCrawlSettings {
  updateTestSuite: updateCrawlSettings_updateTestSuite;
}

export interface updateCrawlSettingsVariables {
  testSuiteId: any;
  crawlTypes: TestSuiteCrawlType[];
  useRenderer: boolean;
  crawlRate: number;
  limitPagesMax: number;
  urlsIncluded?: string[] | null;
  urlsExcluded?: string[] | null;
  rendererBlockAds: boolean;
  rendererBlockAnalytics: boolean;
  rendererJsString: string;
  rendererBlockCustom?: string[] | null;
  rendererJsUrls?: string[] | null;
  useRobotsOverwrite: boolean;
  robotsOverwrite: string;
  emptyPageThreshold?: number | null;
  highLogSummaryRequests: number;
  lowLogSummaryRequests: number;
  maxContentSize: number;
  maxDescriptionLength?: number | null;
  maxExternalLinks?: number | null;
  maxHtmlSize?: number | null;
  maxLinks?: number | null;
  maxLoadTime?: number | null;
  maxRedirections: number;
  maxTitleWidth: number;
  maxUrlLength?: number | null;
  duplicatePrecision: number;
  minDescriptionLength: number;
  minTitleLength: number;
  thinPageThreshold?: number | null;
  customExtractions?: CustomExtractionSettingInput[] | null;
  startUrls?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFileUpload
// ====================================================

export interface createFileUpload_createUrlFileUploadForTestSuite_urlFileUpload {
  __typename: "UrlFileUpload";
  fileName: string;
}

export interface createFileUpload_createUrlFileUploadForTestSuite {
  __typename: "CreateUrlFileUploadPayload";
  urlFileUpload: createFileUpload_createUrlFileUploadForTestSuite_urlFileUpload;
}

export interface createFileUpload {
  createUrlFileUploadForTestSuite: createFileUpload_createUrlFileUploadForTestSuite;
}

export interface createFileUploadVariables {
  testSuiteId: any;
  file?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFileUpload
// ====================================================

export interface deleteFileUpload_deleteUrlFileUpload_urlFileUpload {
  __typename: "UrlFileUpload";
  fileName: string;
}

export interface deleteFileUpload_deleteUrlFileUpload {
  __typename: "DeleteUrlFileUploadPayload";
  urlFileUpload: deleteFileUpload_deleteUrlFileUpload_urlFileUpload;
}

export interface deleteFileUpload {
  deleteUrlFileUpload: deleteFileUpload_deleteUrlFileUpload;
}

export interface deleteFileUploadVariables {
  fileUploadId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEmailAlert
// ====================================================

export interface createEmailAlert_createTestSuiteEmailAlert_testSuiteEmailAlert {
  __typename: "TestSuiteEmailAlert";
  id: any;
  email: string;
  alertTypes: EmailAlertType[];
}

export interface createEmailAlert_createTestSuiteEmailAlert {
  __typename: "CreateTestSuiteEmailAlertPayload";
  testSuiteEmailAlert: createEmailAlert_createTestSuiteEmailAlert_testSuiteEmailAlert;
}

export interface createEmailAlert {
  createTestSuiteEmailAlert: createEmailAlert_createTestSuiteEmailAlert;
}

export interface createEmailAlertVariables {
  testSuiteId: any;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAlertEmail
// ====================================================

export interface deleteAlertEmail_deleteTestSuiteEmailAlert_testSuiteEmailAlert {
  __typename: "TestSuiteEmailAlert";
  email: string;
  id: any;
  alertTypes: EmailAlertType[];
}

export interface deleteAlertEmail_deleteTestSuiteEmailAlert {
  __typename: "DeleteTestSuiteEmailAlertPayload";
  testSuiteEmailAlert: deleteAlertEmail_deleteTestSuiteEmailAlert_testSuiteEmailAlert;
}

export interface deleteAlertEmail {
  deleteTestSuiteEmailAlert: deleteAlertEmail_deleteTestSuiteEmailAlert;
}

export interface deleteAlertEmailVariables {
  testSuiteEmailAlertId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEmailAlert
// ====================================================

export interface updateEmailAlert_updateTestSuiteEmailAlert_testSuiteEmailAlert {
  __typename: "TestSuiteEmailAlert";
  id: any;
  email: string;
  alertTypes: EmailAlertType[];
}

export interface updateEmailAlert_updateTestSuiteEmailAlert {
  __typename: "CreateTestSuiteEmailAlertPayload";
  testSuiteEmailAlert: updateEmailAlert_updateTestSuiteEmailAlert_testSuiteEmailAlert;
}

export interface updateEmailAlert {
  updateTestSuiteEmailAlert: updateEmailAlert_updateTestSuiteEmailAlert;
}

export interface updateEmailAlertVariables {
  id: any;
  alertTypes?: EmailAlertType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createJiraIntegration
// ====================================================

export interface createJiraIntegration_createJiraIntegration_jiraIntegration {
  __typename: "JiraIntegration";
  id: any;
}

export interface createJiraIntegration_createJiraIntegration {
  __typename: "CreateJiraIntegrationPayload";
  jiraIntegration: createJiraIntegration_createJiraIntegration_jiraIntegration;
}

export interface createJiraIntegration {
  createJiraIntegration: createJiraIntegration_createJiraIntegration;
}

export interface createJiraIntegrationVariables {
  name: string;
  jiraAuthenticationId: any;
  jiraProjectId: string;
  jiraIssueTypeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSlackWebhook
// ====================================================

export interface createSlackWebhook_createAutomatorSlackWebhook_slackWebhook {
  __typename: "SlackWebhook";
  id: any;
}

export interface createSlackWebhook_createAutomatorSlackWebhook {
  __typename: "CreateSlackWebhookPayload";
  slackWebhook: createSlackWebhook_createAutomatorSlackWebhook_slackWebhook;
}

export interface createSlackWebhook {
  createAutomatorSlackWebhook: createSlackWebhook_createAutomatorSlackWebhook;
}

export interface createSlackWebhookVariables {
  testSuiteId: any;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSlackWebhook
// ====================================================

export interface deleteSlackWebhook_deleteAutomatorSlackWebhook_slackWebhook {
  __typename: "SlackWebhook";
  id: any;
}

export interface deleteSlackWebhook_deleteAutomatorSlackWebhook {
  __typename: "DeleteSlackWebhookPayload";
  slackWebhook: deleteSlackWebhook_deleteAutomatorSlackWebhook_slackWebhook;
}

export interface deleteSlackWebhook {
  deleteAutomatorSlackWebhook: deleteSlackWebhook_deleteAutomatorSlackWebhook;
}

export interface deleteSlackWebhookVariables {
  slackWebhookId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setAlertEmails
// ====================================================

export interface setAlertEmails_updateTestSuite_testSuite {
  __typename: "TestSuite";
  alertEmails: string[] | null;
}

export interface setAlertEmails_updateTestSuite {
  __typename: "UpdateTestSuitePayload";
  testSuite: setAlertEmails_updateTestSuite_testSuite;
}

export interface setAlertEmails {
  updateTestSuite: setAlertEmails_updateTestSuite;
}

export interface setAlertEmailsVariables {
  testSuiteId: any;
  alertEmails?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraAuthentications
// ====================================================

export interface getJiraAuthentications_getAccount_jiraAuthentications_nodes {
  __typename: "JiraAuthentication";
  id: any;
  name: string;
  jiraUrl: string;
}

export interface getJiraAuthentications_getAccount_jiraAuthentications {
  __typename: "JiraAuthenticationConnection";
  /**
   * A list of nodes.
   */
  nodes: getJiraAuthentications_getAccount_jiraAuthentications_nodes[];
}

export interface getJiraAuthentications_getAccount {
  __typename: "Account";
  id: any;
  jiraAuthentications: getJiraAuthentications_getAccount_jiraAuthentications;
}

export interface getJiraAuthentications {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getJiraAuthentications_getAccount | null;
}

export interface getJiraAuthenticationsVariables {
  accountId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraIssueTypes
// ====================================================

export interface getJiraIssueTypes_getJiraIssueTypes {
  __typename: "JiraIssueType";
  id: string;
  name: string;
}

export interface getJiraIssueTypes {
  getJiraIssueTypes: getJiraIssueTypes_getJiraIssueTypes[];
}

export interface getJiraIssueTypesVariables {
  jiraAuthenticationId: any;
  jiraProjectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraProjects
// ====================================================

export interface getJiraProjects_getJiraProjects {
  __typename: "JiraProject";
  id: string;
  key: string;
  name: string;
}

export interface getJiraProjects {
  getJiraProjects: getJiraProjects_getJiraProjects[];
}

export interface getJiraProjectsVariables {
  jiraAuthenticationId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getJiraSettings
// ====================================================

export interface getJiraSettings_getJiraSettings {
  __typename: "JiraSettings";
  /**
   * Client ID set up on Atlassian developer console for the current environment.
   */
  clientId: string;
  /**
   * Authentication callback URL set up on Atlassian developer console for the current environment.
   */
  authCallbackUrl: string;
}

export interface getJiraSettings {
  getJiraSettings: getJiraSettings_getJiraSettings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: linkJiraIntegrationToTestSuite
// ====================================================

export interface linkJiraIntegrationToTestSuite_linkJiraIntegrationToTestSuite_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
}

export interface linkJiraIntegrationToTestSuite_linkJiraIntegrationToTestSuite {
  __typename: "LinkJiraIntegrationToTestSuitePayload";
  testSuiteJiraIntegration: linkJiraIntegrationToTestSuite_linkJiraIntegrationToTestSuite_testSuiteJiraIntegration;
}

export interface linkJiraIntegrationToTestSuite {
  linkJiraIntegrationToTestSuite: linkJiraIntegrationToTestSuite_linkJiraIntegrationToTestSuite;
}

export interface linkJiraIntegrationToTestSuiteVariables {
  jiraIntegrationId: any;
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unlinkJiraIntegrationFromTestSuite
// ====================================================

export interface unlinkJiraIntegrationFromTestSuite_unlinkJiraIntegrationFromTestSuite_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
}

export interface unlinkJiraIntegrationFromTestSuite_unlinkJiraIntegrationFromTestSuite {
  __typename: "UnlinkJiraIntegrationFromTestSuitePayload";
  testSuiteJiraIntegration: unlinkJiraIntegrationFromTestSuite_unlinkJiraIntegrationFromTestSuite_testSuiteJiraIntegration;
}

export interface unlinkJiraIntegrationFromTestSuite {
  unlinkJiraIntegrationFromTestSuite: unlinkJiraIntegrationFromTestSuite_unlinkJiraIntegrationFromTestSuite;
}

export interface unlinkJiraIntegrationFromTestSuiteVariables {
  testSuiteJiraIntegrationId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateJiraIntegration
// ====================================================

export interface updateJiraIntegration_updateJiraIntegration_jiraIntegration {
  __typename: "JiraIntegration";
  id: any;
  name: string;
}

export interface updateJiraIntegration_updateJiraIntegration {
  __typename: "UpdateJiraIntegrationPayload";
  jiraIntegration: updateJiraIntegration_updateJiraIntegration_jiraIntegration;
}

export interface updateJiraIntegration {
  updateJiraIntegration: updateJiraIntegration_updateJiraIntegration;
}

export interface updateJiraIntegrationVariables {
  jiraIntegrationId: any;
  name?: string | null;
  jiraProjectId?: string | null;
  jiraIssueTypeId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSlackWebhook
// ====================================================

export interface updateSlackWebhook_updateAutomatorSlackWebhook_slackWebhook {
  __typename: "SlackWebhook";
  id: any;
  url: string;
  alertTypes: SlackAlertType[];
}

export interface updateSlackWebhook_updateAutomatorSlackWebhook {
  __typename: "CreateSlackWebhookPayload";
  slackWebhook: updateSlackWebhook_updateAutomatorSlackWebhook_slackWebhook;
}

export interface updateSlackWebhook {
  updateAutomatorSlackWebhook: updateSlackWebhook_updateAutomatorSlackWebhook;
}

export interface updateSlackWebhookVariables {
  slackWebhookId: any;
  alertTypes: SlackAlertType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAvailableParentTestSuites
// ====================================================

export interface getAvailableParentTestSuites_getAccount_notLinkedTestSuites_edges_node {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface getAvailableParentTestSuites_getAccount_notLinkedTestSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * The item at the end of the edge.
   */
  node: getAvailableParentTestSuites_getAccount_notLinkedTestSuites_edges_node;
}

export interface getAvailableParentTestSuites_getAccount_notLinkedTestSuites_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  startCursor: string | null;
}

export interface getAvailableParentTestSuites_getAccount_notLinkedTestSuites {
  __typename: "TestSuiteConnection";
  /**
   * A list of edges.
   */
  edges: getAvailableParentTestSuites_getAccount_notLinkedTestSuites_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getAvailableParentTestSuites_getAccount_notLinkedTestSuites_pageInfo;
}

export interface getAvailableParentTestSuites_getAccount_parentTestSuites_edges_node {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface getAvailableParentTestSuites_getAccount_parentTestSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * The item at the end of the edge.
   */
  node: getAvailableParentTestSuites_getAccount_parentTestSuites_edges_node;
}

export interface getAvailableParentTestSuites_getAccount_parentTestSuites_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  startCursor: string | null;
}

export interface getAvailableParentTestSuites_getAccount_parentTestSuites {
  __typename: "TestSuiteConnection";
  /**
   * A list of edges.
   */
  edges: getAvailableParentTestSuites_getAccount_parentTestSuites_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getAvailableParentTestSuites_getAccount_parentTestSuites_pageInfo;
}

export interface getAvailableParentTestSuites_getAccount {
  __typename: "Account";
  id: any;
  notLinkedTestSuites: getAvailableParentTestSuites_getAccount_notLinkedTestSuites | null;
  parentTestSuites: getAvailableParentTestSuites_getAccount_parentTestSuites | null;
}

export interface getAvailableParentTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getAvailableParentTestSuites_getAccount | null;
}

export interface getAvailableParentTestSuitesVariables {
  accountId: any;
  cursorNotLinkedTestSuites?: string | null;
  cursorParentTestSuites?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNotLinkedTestSuites
// ====================================================

export interface getNotLinkedTestSuites_getAccount_notLinkedTestSuites_edges_node {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface getNotLinkedTestSuites_getAccount_notLinkedTestSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * The item at the end of the edge.
   */
  node: getNotLinkedTestSuites_getAccount_notLinkedTestSuites_edges_node;
}

export interface getNotLinkedTestSuites_getAccount_notLinkedTestSuites_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  startCursor: string | null;
}

export interface getNotLinkedTestSuites_getAccount_notLinkedTestSuites {
  __typename: "TestSuiteConnection";
  /**
   * A list of edges.
   */
  edges: getNotLinkedTestSuites_getAccount_notLinkedTestSuites_edges[];
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: getNotLinkedTestSuites_getAccount_notLinkedTestSuites_pageInfo;
}

export interface getNotLinkedTestSuites_getAccount {
  __typename: "Account";
  id: any;
  notLinkedTestSuites: getNotLinkedTestSuites_getAccount_notLinkedTestSuites | null;
}

export interface getNotLinkedTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getNotLinkedTestSuites_getAccount | null;
}

export interface getNotLinkedTestSuitesVariables {
  accountId: any;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: linkChildTestSuiteToParentTestSuite
// ====================================================

export interface linkChildTestSuiteToParentTestSuite_linkChildTestSuiteToParentTestSuite_parentTestSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface linkChildTestSuiteToParentTestSuite_linkChildTestSuiteToParentTestSuite {
  __typename: "LinkChildTestSuiteToParentTestSuitePayload";
  parentTestSuite: linkChildTestSuiteToParentTestSuite_linkChildTestSuiteToParentTestSuite_parentTestSuite;
}

export interface linkChildTestSuiteToParentTestSuite {
  linkChildTestSuiteToParentTestSuite: linkChildTestSuiteToParentTestSuite_linkChildTestSuiteToParentTestSuite;
}

export interface linkChildTestSuiteToParentTestSuiteVariables {
  parentId: any;
  childId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unlinkChildTestSuiteFromParentTestSuite
// ====================================================

export interface unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite_parentTestSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite_childTestSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite {
  __typename: "UnlinkChildTestSuiteFromParentTestSuitePayload";
  parentTestSuite: unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite_parentTestSuite;
  childTestSuite: unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite_childTestSuite;
}

export interface unlinkChildTestSuiteFromParentTestSuite {
  unlinkChildTestSuiteFromParentTestSuite: unlinkChildTestSuiteFromParentTestSuite_unlinkChildTestSuiteFromParentTestSuite;
}

export interface unlinkChildTestSuiteFromParentTestSuiteVariables {
  childId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTestSuite
// ====================================================

export interface deleteTestSuite_deleteTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
}

export interface deleteTestSuite_deleteTestSuite {
  __typename: "DeleteTestSuitePayload";
  testSuite: deleteTestSuite_deleteTestSuite_testSuite;
}

export interface deleteTestSuite {
  deleteTestSuite: deleteTestSuite_deleteTestSuite;
}

export interface deleteTestSuiteVariables {
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteChildren
// ====================================================

export interface getTestSuiteChildren_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuiteChildren_node_TestSuite_children_edges_node {
  __typename: "TestSuite";
  id: any;
  name: string;
}

export interface getTestSuiteChildren_node_TestSuite_children_edges {
  __typename: "TestSuiteEdge";
  /**
   * The item at the end of the edge.
   */
  node: getTestSuiteChildren_node_TestSuite_children_edges_node;
}

export interface getTestSuiteChildren_node_TestSuite_children_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface getTestSuiteChildren_node_TestSuite_children {
  __typename: "TestSuiteConnection";
  /**
   * A list of edges.
   */
  edges: getTestSuiteChildren_node_TestSuite_children_edges[];
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: getTestSuiteChildren_node_TestSuite_children_pageInfo;
}

export interface getTestSuiteChildren_node_TestSuite {
  __typename: "TestSuite";
  id: any;
  children: getTestSuiteChildren_node_TestSuite_children | null;
}

export type getTestSuiteChildren_node = getTestSuiteChildren_node_Account | getTestSuiteChildren_node_TestSuite;

export interface getTestSuiteChildren {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuiteChildren_node | null;
}

export interface getTestSuiteChildrenVariables {
  id: any;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteFileUploads
// ====================================================

export interface getTestSuiteFileUploads_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuiteFileUploads_node_TestSuite_urlFileUploads_nodes {
  __typename: "UrlFileUpload";
  fileName: string;
  id: any;
  totalRows: number | null;
  processingFeedback: string | null;
}

export interface getTestSuiteFileUploads_node_TestSuite_urlFileUploads {
  __typename: "UrlFileUploadConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuiteFileUploads_node_TestSuite_urlFileUploads_nodes[];
}

export interface getTestSuiteFileUploads_node_TestSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
  urlFileUploads: getTestSuiteFileUploads_node_TestSuite_urlFileUploads;
}

export type getTestSuiteFileUploads_node = getTestSuiteFileUploads_node_Account | getTestSuiteFileUploads_node_TestSuite;

export interface getTestSuiteFileUploads {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuiteFileUploads_node | null;
}

export interface getTestSuiteFileUploadsVariables {
  id: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuite
// ====================================================

export interface getTestSuite_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuite_node_TestSuite_customExtractions {
  __typename: "CustomExtractionSetting";
  regex: string;
  label: string;
}

export interface getTestSuite_node_TestSuite_customDns {
  __typename: "CustomDnsSetting";
  hostname: string;
  ipAddress: string;
}

export interface getTestSuite_node_TestSuite_location {
  __typename: "Location";
  code: LocationCode;
  name: string;
}

export interface getTestSuite_node_TestSuite_account {
  __typename: "Account";
  id: any;
  /**
   * Maximum crawl rate that can be set for a Project or Test Suite.
   */
  maxCrawlRate: number;
}

export interface getTestSuite_node_TestSuite_slackWebhooks_nodes {
  __typename: "SlackWebhook";
  id: any;
  url: string;
  alertTypes: SlackAlertType[];
}

export interface getTestSuite_node_TestSuite_slackWebhooks {
  __typename: "SlackWebhookConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuite_node_TestSuite_slackWebhooks_nodes[];
}

export interface getTestSuite_node_TestSuite_emailAlerts_nodes {
  __typename: "TestSuiteEmailAlert";
  id: any;
  email: string;
  alertTypes: EmailAlertType[];
}

export interface getTestSuite_node_TestSuite_emailAlerts {
  __typename: "TestSuiteEmailAlertConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuite_node_TestSuite_emailAlerts_nodes[];
}

export interface getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration {
  __typename: "JiraIntegration";
  id: any;
  name: string;
  jiraUrl: string;
  jiraProjectName: string;
  jiraIssueTypeName: string;
}

export interface getTestSuite_node_TestSuite_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
  jiraIntegration: getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration;
}

export interface getTestSuite_node_TestSuite_parent {
  __typename: "TestSuite";
  name: string;
  id: any;
}

export interface getTestSuite_node_TestSuite {
  __typename: "TestSuite";
  siteTestUser: string | null;
  hasSiteTestPass: boolean;
  coreUIUrl: string;
  crawlRate: number;
  crawlTypes: TestSuiteCrawlType[];
  /**
   * Use regular expressions to extract custom information from pages when they are crawled.
   */
  customExtractions: getTestSuite_node_TestSuite_customExtractions[] | null;
  customHeaderUserAgent: string | null;
  customHeaderUserAgentShort: string | null;
  customDns: getTestSuite_node_TestSuite_customDns[] | null;
  duplicatePrecision: number;
  emptyPageThreshold: number | null;
  highLogSummaryRequests: number;
  limitPagesMax: number;
  lowLogSummaryRequests: number;
  location: getTestSuite_node_TestSuite_location;
  maxContentSize: number;
  maxDescriptionLength: number | null;
  maxExternalLinks: number | null;
  maxHtmlSize: number | null;
  maxLinks: number | null;
  maxLoadTime: number | null;
  maxRedirections: number;
  maxTitleWidth: number;
  maxUrlLength: number | null;
  minDescriptionLength: number;
  minTitleLength: number;
  name: string;
  rendererBlockAds: boolean;
  rendererBlockAnalytics: boolean;
  rendererBlockCustom: string[] | null;
  rendererJsString: string | null;
  rendererJsUrls: string[] | null;
  useRobotsOverwrite: boolean;
  robotsOverwrite: string | null;
  sitePrimary: string;
  siteTest: string | null;
  thinPageThreshold: number | null;
  urlsExcluded: string[] | null;
  urlsIncluded: string[] | null;
  startUrls: string[] | null;
  useRenderer: boolean;
  createdAt: any;
  updatedAt: any;
  id: any;
  account: getTestSuite_node_TestSuite_account;
  slackWebhooks: getTestSuite_node_TestSuite_slackWebhooks | null;
  emailAlerts: getTestSuite_node_TestSuite_emailAlerts | null;
  testSuiteJiraIntegration: getTestSuite_node_TestSuite_testSuiteJiraIntegration | null;
  parent: getTestSuite_node_TestSuite_parent | null;
  /**
   * Flags if threshold on all tests is calculated automatically, based on previous test results.
   */
  automaticThreshold: boolean;
  /**
   * Flags if automatic thresholds are calculated for all (true) or some (false) tests.
   */
  automaticThresholdEnabledForAll: boolean;
}

export type getTestSuite_node = getTestSuite_node_Account | getTestSuite_node_TestSuite;

export interface getTestSuite {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuite_node | null;
}

export interface getTestSuiteVariables {
  id: any;
  hasJiraAccess: boolean;
  hasSmartThresholdAccess: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteTestsAndCustomExtractions
// ====================================================

export interface getTestSuiteTestsAndCustomExtractions_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions {
  __typename: "CustomExtractionSetting";
  cleanHtmlTags: boolean;
  regex: string;
  label: string;
  matchNumberFrom: number;
  matchNumberTo: number;
  reportTemplateCode: string;
}

export interface getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate {
  __typename: "ReportTemplate";
  name: string;
  code: string;
  automatorSummary: string | null;
}

export interface getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes {
  __typename: "Test";
  createJiraTicketOnFailure: boolean;
  jiraTicketCustomNote: string | null;
  /**
   * Whether the test's threshold should be adjusted by the automatic threshold algorithm or not.
   */
  automaticThresholdEnabled: boolean;
  createdAt: any;
  updatedAt: any;
  /**
   * Percentage of pages in the report (relative to all crawled pages) that will fail the test.
   */
  relativeThreshold: number;
  /**
   * The absolute number of pages in the report that will fail the test.
   */
  absoluteThreshold: number;
  /**
   * Type of threshold used to calculate test results.
   */
  thresholdType: ThresholdType;
  /**
   * Relational operator for threshold used to calculate test results.
   */
  thresholdPredicate: ThresholdPredicate;
  reportTemplateCode: string;
  severity: Severity;
  reportTemplate: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate;
  id: any;
}

export interface getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests {
  __typename: "TestConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes[];
}

export interface getTestSuiteTestsAndCustomExtractions_node_TestSuite {
  __typename: "TestSuite";
  name: string;
  id: any;
  /**
   * Use regular expressions to extract custom information from pages when they are crawled.
   */
  customExtractions: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | null;
  tests: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests;
}

export type getTestSuiteTestsAndCustomExtractions_node = getTestSuiteTestsAndCustomExtractions_node_Account | getTestSuiteTestsAndCustomExtractions_node_TestSuite;

export interface getTestSuiteTestsAndCustomExtractions {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuiteTestsAndCustomExtractions_node | null;
}

export interface getTestSuiteTestsAndCustomExtractionsVariables {
  id: any;
  hasJiraAccess: boolean;
  hasSmartThresholdsAccess: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBuildsStatuses
// ====================================================

export interface getBuildsStatuses_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "Build" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getBuildsStatuses_node_TestSuite_builds_nodes {
  __typename: "Build";
  id: any;
  status: BuildStatus;
  triggerType: BuildTriggerType;
}

export interface getBuildsStatuses_node_TestSuite_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getBuildsStatuses_node_TestSuite_builds_nodes[];
}

export interface getBuildsStatuses_node_TestSuite {
  __typename: "TestSuite";
  id: any;
  builds: getBuildsStatuses_node_TestSuite_builds;
}

export type getBuildsStatuses_node = getBuildsStatuses_node_Account | getBuildsStatuses_node_TestSuite;

export interface getBuildsStatuses {
  /**
   * Fetches an object given its ID.
   */
  node: getBuildsStatuses_node | null;
}

export interface getBuildsStatusesVariables {
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cloneTestSuite
// ====================================================

export interface cloneTestSuite_cloneTestSuite_testSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
}

export interface cloneTestSuite_cloneTestSuite {
  __typename: "CloneTestSuitePayload";
  testSuite: cloneTestSuite_cloneTestSuite_testSuite;
}

export interface cloneTestSuite {
  cloneTestSuite: cloneTestSuite_cloneTestSuite;
}

export interface cloneTestSuiteVariables {
  testSuiteId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNextTestSuites
// ====================================================

export interface getNextTestSuites_getAccount_testSuites_edges_node_tests {
  __typename: "TestConnection";
  /**
   * Identifies the total count of Tests in the connection.
   */
  totalCount: number;
}

export interface getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes {
  __typename: "Build";
  id: any;
  createdAt: any;
  updatedAt: any;
  finishedAt: any | null;
  failedTestCount: number | null;
  warnedTestCount: number | null;
  passedTestCount: number | null;
  passed: boolean;
  status: BuildStatus;
}

export interface getNextTestSuites_getAccount_testSuites_edges_node_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes[];
}

export interface getNextTestSuites_getAccount_testSuites_edges_node_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
}

export interface getNextTestSuites_getAccount_testSuites_edges_node {
  __typename: "TestSuite";
  updatedAt: any;
  clonedAt: any | null;
  id: any;
  name: string;
  sitePrimary: string;
  createdAt: any;
  tests: getNextTestSuites_getAccount_testSuites_edges_node_tests;
  builds: getNextTestSuites_getAccount_testSuites_edges_node_builds;
  testSuiteJiraIntegration: getNextTestSuites_getAccount_testSuites_edges_node_testSuiteJiraIntegration | null;
}

export interface getNextTestSuites_getAccount_testSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * A cursor to use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: getNextTestSuites_getAccount_testSuites_edges_node;
}

export interface getNextTestSuites_getAccount_testSuites_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
}

export interface getNextTestSuites_getAccount_testSuites {
  __typename: "TestSuiteConnection";
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getNextTestSuites_getAccount_testSuites_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getNextTestSuites_getAccount_testSuites_pageInfo;
}

export interface getNextTestSuites_getAccount {
  __typename: "Account";
  id: any;
  testSuites: getNextTestSuites_getAccount_testSuites | null;
}

export interface getNextTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getNextTestSuites_getAccount | null;
}

export interface getNextTestSuitesVariables {
  accountId: any;
  itemsPerPage: number;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPreviousTestSuites
// ====================================================

export interface getPreviousTestSuites_getAccount_testSuites_edges_node_tests {
  __typename: "TestConnection";
  /**
   * Identifies the total count of Tests in the connection.
   */
  totalCount: number;
}

export interface getPreviousTestSuites_getAccount_testSuites_edges_node_builds_nodes {
  __typename: "Build";
  id: any;
  createdAt: any;
  updatedAt: any;
  finishedAt: any | null;
  failedTestCount: number | null;
  warnedTestCount: number | null;
  passedTestCount: number | null;
  passed: boolean;
  status: BuildStatus;
}

export interface getPreviousTestSuites_getAccount_testSuites_edges_node_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getPreviousTestSuites_getAccount_testSuites_edges_node_builds_nodes[];
}

export interface getPreviousTestSuites_getAccount_testSuites_edges_node_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
}

export interface getPreviousTestSuites_getAccount_testSuites_edges_node {
  __typename: "TestSuite";
  updatedAt: any;
  clonedAt: any | null;
  id: any;
  name: string;
  sitePrimary: string;
  createdAt: any;
  tests: getPreviousTestSuites_getAccount_testSuites_edges_node_tests;
  builds: getPreviousTestSuites_getAccount_testSuites_edges_node_builds;
  testSuiteJiraIntegration: getPreviousTestSuites_getAccount_testSuites_edges_node_testSuiteJiraIntegration | null;
}

export interface getPreviousTestSuites_getAccount_testSuites_edges {
  __typename: "TestSuiteEdge";
  /**
   * A cursor to use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: getPreviousTestSuites_getAccount_testSuites_edges_node;
}

export interface getPreviousTestSuites_getAccount_testSuites_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
}

export interface getPreviousTestSuites_getAccount_testSuites {
  __typename: "TestSuiteConnection";
  /**
   * Identifies the total count of TestSuites in the connection.
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: getPreviousTestSuites_getAccount_testSuites_edges[];
  /**
   * Information to aid in pagination.
   */
  pageInfo: getPreviousTestSuites_getAccount_testSuites_pageInfo;
}

export interface getPreviousTestSuites_getAccount {
  __typename: "Account";
  id: any;
  testSuites: getPreviousTestSuites_getAccount_testSuites | null;
}

export interface getPreviousTestSuites {
  /**
   * Look up account by ObjectID.
   */
  getAccount: getPreviousTestSuites_getAccount | null;
}

export interface getPreviousTestSuitesVariables {
  accountId: any;
  itemsPerPage: number;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTestSuiteReport
// ====================================================

export interface getTestSuiteReport_node_Account {
  __typename: "Account" | "AdobeAnalyticsProjectReportSuite" | "AdobeConnection" | "BuildSchedule" | "BuildScheduleRun" | "BuildScheduleTestSuite" | "Crawl" | "CrawlSetting" | "CrawlTypeMetadata" | "CustomMetricContainer" | "CustomMetricContainerVersion" | "GoogleAnalyticsProjectView" | "GoogleConnection" | "GoogleSearchConsoleProperty" | "JiraAuthentication" | "JiraIntegration" | "LegacyTask" | "Location" | "LogzioConnection" | "LogzioProjectQuery" | "Project" | "Report" | "ReportDownload" | "ReportTemplate" | "Schedule" | "Segment" | "SlackWebhook" | "SplunkConnection" | "SplunkProjectQuery" | "Test" | "TestResult" | "TestSuite" | "TestSuiteEmailAlert" | "TestSuiteJiraIntegration" | "UrlFileUpload" | "UrlFileUploadType" | "User";
}

export interface getTestSuiteReport_node_Build_crawl {
  __typename: "Crawl";
  id: any;
}

export interface getTestSuiteReport_node_Build_testSuite_account {
  __typename: "Account";
  id: any;
}

export interface getTestSuiteReport_node_Build_testSuite_customExtractions {
  __typename: "CustomExtractionSetting";
  regex: string;
  label: string;
  reportTemplateCode: string;
}

export interface getTestSuiteReport_node_Build_testSuite_builds_nodes {
  __typename: "Build";
  id: any;
}

export interface getTestSuiteReport_node_Build_testSuite_builds {
  __typename: "BuildConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuiteReport_node_Build_testSuite_builds_nodes[];
}

export interface getTestSuiteReport_node_Build_testSuite_testSuiteJiraIntegration {
  __typename: "TestSuiteJiraIntegration";
  id: any;
}

export interface getTestSuiteReport_node_Build_testSuite {
  __typename: "TestSuite";
  id: any;
  name: string;
  sitePrimary: string;
  account: getTestSuiteReport_node_Build_testSuite_account;
  /**
   * Use regular expressions to extract custom information from pages when they are crawled.
   */
  customExtractions: getTestSuiteReport_node_Build_testSuite_customExtractions[] | null;
  builds: getTestSuiteReport_node_Build_testSuite_builds;
  testSuiteJiraIntegration: getTestSuiteReport_node_Build_testSuite_testSuiteJiraIntegration | null;
}

export interface getTestSuiteReport_node_Build_testResults_nodes_reportTemplate {
  __typename: "ReportTemplate";
  name: string;
  code: string;
  automatorSummary: string | null;
}

export interface getTestSuiteReport_node_Build_testResults_nodes {
  __typename: "TestResult";
  passed: boolean | null;
  severity: Severity;
  id: any;
  reportTemplate: getTestSuiteReport_node_Build_testResults_nodes_reportTemplate;
}

export interface getTestSuiteReport_node_Build_testResults {
  __typename: "TestResultConnection";
  /**
   * A list of nodes.
   */
  nodes: getTestSuiteReport_node_Build_testResults_nodes[];
}

export interface getTestSuiteReport_node_Build {
  __typename: "Build";
  id: any;
  passed: boolean;
  failedTestCount: number | null;
  passedTestCount: number | null;
  warnedTestCount: number | null;
  crawlDcWebUrl: string | null;
  status: BuildStatus;
  crawl: getTestSuiteReport_node_Build_crawl | null;
  testSuite: getTestSuiteReport_node_Build_testSuite;
  testResults: getTestSuiteReport_node_Build_testResults;
}

export type getTestSuiteReport_node = getTestSuiteReport_node_Account | getTestSuiteReport_node_Build;

export interface getTestSuiteReport {
  /**
   * Fetches an object given its ID.
   */
  node: getTestSuiteReport_node | null;
}

export interface getTestSuiteReportVariables {
  id: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BuildScheduleRepetitionRate {
  Daily = "Daily",
  EveryFourHours = "EveryFourHours",
  EveryTwelveHours = "EveryTwelveHours",
  EveryWeekday = "EveryWeekday",
  Hourly = "Hourly",
  Monthly = "Monthly",
  Weekly = "Weekly",
}

export enum BuildStatus {
  Aborted = "Aborted",
  Cancelled = "Cancelled",
  Finished = "Finished",
  Queued = "Queued",
  Running = "Running",
}

export enum BuildTriggerType {
  CI = "CI",
  Manual = "Manual",
  Schedule = "Schedule",
}

export enum EmailAlertType {
  Fail = "Fail",
  Pass = "Pass",
  Warning = "Warning",
}

export enum LocationCode {
  Australia = "Australia",
  Austria = "Austria",
  Belgium = "Belgium",
  Canada = "Canada",
  China = "China",
  Custom = "Custom",
  Default = "Default",
  Egypt = "Egypt",
  France = "France",
  Germany = "Germany",
  Global = "Global",
  GreatBritain = "GreatBritain",
  India = "India",
  Italy = "Italy",
  Japan = "Japan",
  Netherlands = "Netherlands",
  Singapore = "Singapore",
  Spain = "Spain",
  Switzerland = "Switzerland",
  Turkey = "Turkey",
  UnitedStates = "UnitedStates",
  Uzbekistan = "Uzbekistan",
}

/**
 * Possible directions in which to order a list of items when provided an `orderBy` argument.
 */
export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum RoleCode {
  Admin = "Admin",
  Editor = "Editor",
  Viewer = "Viewer",
}

export enum Severity {
  Fail = "Fail",
  Warning = "Warning",
}

export enum SlackAlertType {
  Fail = "Fail",
  Pass = "Pass",
  Warning = "Warning",
}

export enum TestSuiteCrawlType {
  Backlinks = "Backlinks",
  GoogleAnalytics = "GoogleAnalytics",
  GoogleSearchConsole = "GoogleSearchConsole",
  List = "List",
  LogSummary = "LogSummary",
  Sitemap = "Sitemap",
  Web = "Web",
}

export enum TestSuiteLocationCode {
  Custom = "Custom",
  Default = "Default",
}

export enum ThresholdPredicate {
  GreaterThanOrEqual = "GreaterThanOrEqual",
  LessThan = "LessThan",
}

export enum ThresholdType {
  Absolute = "Absolute",
  Relative = "Relative",
}

export interface CreateBuildScheduleInput {
  accountId: any;
  name: string;
  repetitionRate?: BuildScheduleRepetitionRate | null;
  startAt: any;
}

export interface CustomDnsSettingInput {
  hostname: string;
  ipAddress: string;
}

export interface CustomExtractionSettingInput {
  cleanHtmlTags: boolean;
  label: string;
  matchNumberFrom: number;
  matchNumberTo: number;
  regex: string;
  regexFilter?: string | null;
}

export interface UpdateBuildScheduleInput {
  buildScheduleId: any;
  name?: string | null;
  repetitionRate?: BuildScheduleRepetitionRate | null;
  startAt?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
