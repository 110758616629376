import { useQuery } from "@apollo/react-hooks";
import { getTestSuiteReportVariables, getTestSuiteReport } from "../../../generated-graphql-interfaces";
import { getTestSuiteReportGql } from "./getTestSuiteReportGql";

export function useTestSuiteReportQuery(variables: getTestSuiteReportVariables) {
  return useQuery<getTestSuiteReport, getTestSuiteReportVariables>(getTestSuiteReportGql, {
    variables,
    fetchPolicy: "cache-first"
  });
}
