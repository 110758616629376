import { BuildTestStatus } from "../../interfaces/BuildTestStatus";
import { isBuildTestProcessing } from "../isBuildTestProcessing/isBuildTestProcessing";

export function getBuildTestProcessedValue<T>(value: T, status: BuildTestStatus): T | "Processing" | "N/A" {
  if (isBuildTestProcessing(status)) {
    return "Processing";
  } else if (typeof value !== "number" && !value) {
    return "N/A";
  }
  return value;
}
