import { Box, Fade, Grid, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { NotificationOptions } from "../NotificationOptions";
import { DeletableInput } from "../../../../../_common/components/DeletableInput/DeletableInput";
import { getTestSuite_node_TestSuite_emailAlerts_nodes } from "../../../../../generated-graphql-interfaces";
import { useDeleteEmailAlertMutation } from "./graphql/useDeleteEmailAlertMutation";
import { useSnackbar } from "notistack";
import { useUpdateEmailAlertMutation } from "./graphql/useUpdateEmailAlertMutation";
import { getAlertTypesFromNotificationSettings } from "./utils/getAlertTypesFromNotificationSettings";
import { getInitialValuesFromAlerts } from "./utils/getInitialValuesFromAlerts";

const useStyles = makeStyles(theme => ({
  grid: {
    marginBottom: theme.spacing(3)
  }
}));

interface EmailRowProps {
  alertEmail: getTestSuite_node_TestSuite_emailAlerts_nodes;
}
export function EmailRow(props: EmailRowProps) {
  const { alertEmail } = props;
  const classes = useStyles();
  const [deleteAlertEmail, { loading }] = useDeleteEmailAlertMutation();
  const [updateAlertEmail] = useUpdateEmailAlertMutation();
  const { enqueueSnackbar } = useSnackbar();

  const updateEmailAlerts = useCallback(
    async (pass: boolean, warning: boolean, fail: boolean) => {
      const alertTypes = getAlertTypesFromNotificationSettings(pass, warning, fail);

      try {
        await updateAlertEmail({
          variables: {
            id: alertEmail.id,
            alertTypes
          }
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong while trying to update your notification settings", { variant: "error" });
      }
    },
    [alertEmail.id, updateAlertEmail, enqueueSnackbar]
  );

  async function handleDelete() {
    try {
      await deleteAlertEmail({
        variables: {
          testSuiteEmailAlertId: alertEmail.id
        }
      });
      enqueueSnackbar("Email removed successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Something went wrong while trying to delete your email", { variant: "error" });
    }
  }

  return (
    <Fade in timeout={500}>
      <Box component="div" mb={2} data-testid="alert-email-row">
        <Grid container alignItems="center" className={classes.grid}>
          <DeletableInput
            value={alertEmail.email}
            id="alert-email"
            handleDelete={handleDelete}
            loading={loading}
            label="Email address"
            textFieldGrid={{ xs: 11, xl: 7 }}
            deleteButtonGrid={{ xs: 1, xl: 1 }}
          />
        </Grid>
        <NotificationOptions
          id="email"
          updateNotifications={updateEmailAlerts}
          initialValues={getInitialValuesFromAlerts(alertEmail.alertTypes)}
          disabled={loading}
        />
      </Box>
    </Fade>
  );
}
