import React, { useState } from "react";
import { Box, Typography, makeStyles, CircularProgress, Fade } from "@material-ui/core";
import { NoIntegrationsView } from "./NoIntegrationsView";
import { ConnectedIntegrationView } from "./ConnectedIntegrationView";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  getTestSuite_node_TestSuite,
  getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration
} from "../../../../../generated-graphql-interfaces";
import { useUnlinkJiraIntegrationFromTestSuiteMutation } from "../graphql/useUnlinkJiraIntegrationFromTestSuite";

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2)
    }
  },
  title: {
    fontSize: 22,
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400
  },
  infoIcon: {
    color: theme.palette.navy[300],
    padding: 10,
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

interface JiraTabProps {
  testSuite: getTestSuite_node_TestSuite;
  jiraIntegration?: getTestSuite_node_TestSuite_testSuiteJiraIntegration_jiraIntegration;
}

// eslint-disable-next-line complexity, max-lines-per-function
export function JiraTab(props: JiraTabProps) {
  const classes = useStyles();
  const [isLinkingAlertOpen, setIsLinkingAlertOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [unlinkJiraIntegrationFromTestSuite, { loading: isUnlinkIntegrationLoading, error: unlinkError }] =
    useUnlinkJiraIntegrationFromTestSuiteMutation();

  const isLoading = isUnlinkIntegrationLoading;
  const createAndLinkError = !!unlinkError;

  async function unlinkIntegration() {
    const testSuiteJiraIntegrationId = props.testSuite?.testSuiteJiraIntegration?.id;
    if (!testSuiteJiraIntegrationId) return;

    try {
      await unlinkJiraIntegrationFromTestSuite({
        variables: {
          testSuiteJiraIntegrationId
        }
      });
      enqueueSnackbar("Jira integration disconnected successfully.", { variant: "success" });
    } catch (e) {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Box display="flex" alignItems="center" mb={4}>
        <Typography variant="h3" className={classes.title} data-testid="jira-tab-title">
          Jira Integration
        </Typography>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-jira-integration-info"
          title="Connect your Jira project to your test suite"
          size={20}
          maxWidth={450}
          iconComponent={<InfoOutlinedIcon className={classes.infoIcon} />}
          body={
            <>
              <Typography>
                Create issues in your Jira backlog for fails and warnings generated by your tests.
              </Typography>
              <ul style={{ paddingInlineStart: "15px" }}>
                <li>
                  If you plan to have multiple Jira integrations for a single Automation Hub account, you should name
                  your integration with a unique name.
                </li>
                <li>If you have already configured an integration you can select it in the dropdown below.</li>
              </ul>
            </>
          }
        />
      </Box>

      {unlinkError && (
        <Alert severity="error" data-testid="error-alert">
          Sorry, looks like something went wrong while trying to disconnect a Jira integration from the test suite.
          Please try again or contact support.
        </Alert>
      )}

      {isLoading && <CircularProgress color="secondary" />}

      <Fade in={!props.jiraIntegration && !isLoading}>
        <div style={{ display: !props.jiraIntegration && !isLoading ? "block" : "none" }}>
          <NoIntegrationsView
            testSuiteId={props.testSuite.id}
            onSuccessfulLinking={() => {
              setIsLinkingAlertOpen(true);
            }}
          />
        </div>
      </Fade>

      <Fade in={!!props.jiraIntegration && !isLoading && !createAndLinkError}>
        <div style={{ display: !!props.jiraIntegration && !isLoading && !createAndLinkError ? "block" : "none" }}>
          <ConnectedIntegrationView
            jiraIntegration={props.jiraIntegration}
            isLinkingAlertOpen={isLinkingAlertOpen}
            unlinkIntegration={unlinkIntegration}
            handleCloseLinkingAlert={() => {
              setIsLinkingAlertOpen(false);
            }}
          />
        </div>
      </Fade>
    </Box>
  );
}
