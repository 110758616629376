import { useQuery } from "@apollo/react-hooks";
import {
  getTestSuiteChildrenVariables,
  getTestSuiteChildren,
  getTestSuiteChildren_node_TestSuite
} from "../../../generated-graphql-interfaces";
import { useTestSuiteChildrenGql } from "./getTestSuiteChildrenGql";
import { useState } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../_common/utils/mergePageInfo/mergePageInfo";

function getPreviousResultAsLast(data: getTestSuiteChildren) {
  if (data.node?.__typename === "TestSuite" && data.node.children) {
    // eslint-disable-next-line fp/no-mutation
    data.node.children.pageInfo.hasNextPage = false;
    return data;
  }
  return data;
}

export function useTestSuiteChildrenQuery(variables: getTestSuiteChildrenVariables) {
  const { loading, data, error, fetchMore } = useQuery<getTestSuiteChildren, getTestSuiteChildrenVariables>(
    useTestSuiteChildrenGql,
    {
      variables,
      fetchPolicy: "cache-and-network"
    }
  );
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  function fetchMoreChildren() {
    if (
      !isFetchingMore &&
      !loading &&
      data &&
      (data?.node as getTestSuiteChildren_node_TestSuite)?.children?.pageInfo.hasNextPage
    ) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          cursor: (data?.node as getTestSuiteChildren_node_TestSuite)?.children?.pageInfo.endCursor
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.node?.__typename === "TestSuite" && fetchMoreResult.node.children) {
            return merge(previousResult, fetchMoreResult, defaultMergeOptions) as getTestSuiteChildren;
          }
          return getPreviousResultAsLast(previousResult);
        }
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }

  return {
    loading: loading || isFetchingMore,
    data,
    error,
    fetchMore: fetchMoreChildren,
    isFetchingMore
  };
}
