import { TestCategory } from "./CreateTests.interfaces";

export const MAX_TESTS_SELECTION_LIMIT = 100;

export const RECOMMENDED_TESTS_CATEGORY_CODE = "recommended-tests";

export const recommendedTestsCategory: TestCategory = {
  selected: false,
  name: "Recommended Tests",
  code: RECOMMENDED_TESTS_CATEGORY_CODE,
  tests: []
};

export const recommendedTestCodesList = [
  "4xx_errors",
  "5xx_errors",
  "max_load_time",
  "missing_titles",
  "empty_pages",
  "pages_without_canonical_tag"
];
