import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { deleteJiraIntegration, deleteJiraIntegrationVariables } from "../../../../generated-graphql-interfaces";

export const deleteJiraIntegrationGql = gql`
  mutation deleteJiraIntegration($jiraIntegrationId: ObjectID!) {
    deleteJiraIntegration(input: { jiraIntegrationId: $jiraIntegrationId }) {
      jiraIntegration {
        name
        id
      }
    }
  }
`;

export function useDeleteJiraIntegrationMutation() {
  return useMutation<deleteJiraIntegration, deleteJiraIntegrationVariables>(deleteJiraIntegrationGql);
}
