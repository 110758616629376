import gql from "graphql-tag";

export const linkChildTestSuiteToParentTestSuiteGql = gql`
  mutation linkChildTestSuiteToParentTestSuite($parentId: ObjectID!, $childId: ObjectID!) {
    linkChildTestSuiteToParentTestSuite(input: { parentTestSuiteId: $parentId, childTestSuiteId: $childId }) {
      parentTestSuite {
        name
        id
      }
    }
  }
`;
