import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { RunTestSuiteNowButton } from "./RunTestSuiteNowButton";
import { Alert } from "@material-ui/lab";
import { ApolloQueryResult } from "apollo-client";
import { getTestSuiteVariables, getTestSuite } from "../../../../../generated-graphql-interfaces";
import { useBuildStatusContext } from "../../../hooks/useBuildStatusProvider/useBuildStatusProvider";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 22,
    fontWeight: 500
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: 20,
    color: "#BDC2C5"
  }
}));

interface RunTestSuiteNowProps {
  refetchTestSuite: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
  testSuiteId: string;
}

export function RunTestSuiteNow(props: RunTestSuiteNowProps) {
  const classes = useStyles();
  const buildStatusContext = useBuildStatusContext();

  return (
    <Box data-testid="run-now-tab-content">
      <Box display="flex" alignItems="center" marginBottom={6}>
        <Typography color="textPrimary" className={classes.title} data-testid="run-now-title">
          Run test suite now
        </Typography>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-run-test-suite-now"
          maxWidth={450}
          iconComponent={<InfoOutlinedIcon className={classes.icon} />}
          title="Run test suite immediately"
          body="Run test suite immediately after you have made changes to the test suite."
          testId="template-tooltip"
          cyId="template-tooltip"
          size={20}
        />
      </Box>
      {buildStatusContext?.error ? (
        <Alert severity="error" data-testid="run-test-suite-now-error">
          Something went wrong. Please refresh the page and try again.
        </Alert>
      ) : null}
      <RunTestSuiteNowButton
        testId="run-test-suite-now-step-5"
        refetchTestSuite={props.refetchTestSuite}
        testSuiteId={props.testSuiteId}
        pendoId="auto-run-now-btn-step-5"
      />
    </Box>
  );
}
