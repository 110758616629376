import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getJiraProjects, getJiraProjectsVariables } from "../../../../../generated-graphql-interfaces";

export const getJiraProjectsGql = gql`
  query getJiraProjects($jiraAuthenticationId: ObjectID!) {
    getJiraProjects(input: { jiraAuthenticationId: $jiraAuthenticationId }) {
      id
      key
      name
    }
  }
`;

export function useGetJiraProjectsQuery(variables: getJiraProjectsVariables) {
  return useQuery<getJiraProjects>(getJiraProjectsGql, { variables, fetchPolicy: "cache-and-network" });
}
