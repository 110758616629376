import React, { MouseEvent, PropsWithChildren } from "react";
import { PendoTagProps } from "../../../typings/pendo.prototype";

export interface AnchorScrollLinkProps extends PendoTagProps {
  onClick?: (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  offset?: number;
  resetToTop?: boolean;
  href?: string;
}

export function AnchorScrollLink<P>(props: PropsWithChildren<P & AnchorScrollLinkProps>) {
  const { offset, resetToTop, onClick, ...rest } = props;

  function smoothScroll(e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) {
    if (e) {
      e.preventDefault();
      if (resetToTop) {
        window.scroll({
          top: Math.max(0 - (offset || 0), 0),
          behavior: "smooth"
        });
      } else {
        const anchorElementId =
          e.target !== null ? (e.target as HTMLAnchorElement).getAttribute("href")?.slice(1) : null;
        if (anchorElementId) {
          const anchorElement = document.getElementById(anchorElementId);
          if (anchorElement) {
            const offsetTop = (anchorElement as HTMLElement).getBoundingClientRect().top + window.pageYOffset;
            window.scroll({
              top: Math.max(offsetTop - (offset || 0), 0),
              behavior: "smooth"
            });
          }
        }
      }

      if (onClick) {
        onClick(e as any);
      }
    }
  }

  return <a data-testid="anchor-scroll-link" onClick={event => smoothScroll(event)} {...rest} />;
}

export default AnchorScrollLink;
