import { useQuery } from "@apollo/react-hooks";
import {
  getTestSuiteTestsAndCustomExtractions,
  getTestSuiteTestsAndCustomExtractionsVariables
} from "../../../generated-graphql-interfaces";
import { getTestSuiteTestsAndCustomExtractionsGql } from "./getTestSuiteTestsAndCustomExtractionsGql";

export function useTestSuiteTestsAndExtractionsQuery(variables: getTestSuiteTestsAndCustomExtractionsVariables) {
  const { loading, data, error, refetch } = useQuery<
    getTestSuiteTestsAndCustomExtractions,
    getTestSuiteTestsAndCustomExtractionsVariables
  >(getTestSuiteTestsAndCustomExtractionsGql, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  return { loading, data, error, refetch };
}
