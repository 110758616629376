import React, { ReactNode } from "react";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StepChangeHandler } from "./UpdateTestSuite";
import { ActionButtonTooltip } from "../../_common/components/ActionButtonTooltip/ActionButtonTooltip";
import clsx from "clsx";

interface CustomStepProps {
  stepHeader: string;
  stepNumber: number;
  id: string;
  children?: ReactNode;
  handleChange?: StepChangeHandler;
  isExpanded: boolean;
  testId?: string;
  disabled?: boolean;
  isParentStep?: boolean;
  pendoId: string;
}

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    }
  },
  stepNumber: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  colouredStepNumber: {
    color: theme.palette.purple[300]
  },
  step: (props: CustomStepProps) => ({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    border: props.isExpanded ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.primary.light}`,
    borderRadius: "4px"
  }),
  stepContent: {
    padding: 0
  },
  summaryHeader: {
    padding: theme.spacing(0, 3.5, 0, 1.5)
  },
  panel: {
    borderRadius: "4px",
    "&.MuiExpansionPanel-root:before": {
      backgroundColor: theme.palette.grey[50]
    },
    "&.Mui-disabled": {
      backgroundColor: "#F4F4F4"
    }
  },
  noPadding: {
    paddingLeft: "0px",
    paddingRight: "0px"
  }
}));

export function CustomStep(props: CustomStepProps) {
  const classes = useStyles(props);
  const { isExpanded, handleChange, id, stepHeader, stepNumber, children, testId, disabled } = props;

  const stepContent = (
    <Accordion expanded={isExpanded} onChange={handleChange} className={classes.panel} disabled={disabled}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
        className={classes.step}
        data-cy={testId}
        data-testid={testId}
        data-pendo={props.pendoId}
      >
        <Box display="flex" alignItems="center" className={classes.summaryHeader}>
          <div>
            <Typography
              className={clsx(classes.stepNumber, props.isParentStep && classes.colouredStepNumber)}
              data-testid="custom-step-number"
            >
              Step {stepNumber}
            </Typography>
            <Typography className={classes.header} data-testid="custom-step-name">
              {stepHeader}
            </Typography>
          </div>
        </Box>
      </AccordionSummary>
      {children && (
        <AccordionDetails className={classes.stepContent}>
          <Container maxWidth="xl" className={classes.noPadding}>
            {children}
          </Container>
        </AccordionDetails>
      )}
    </Accordion>
  );

  return disabled ? (
    <ActionButtonTooltip title="You can only edit steps 2 and 3 in the global template.">
      {stepContent}
    </ActionButtonTooltip>
  ) : (
    stepContent
  );
}
