import { BuildStatus } from "../../generated-graphql-interfaces";

export enum ExtendedBuildStatus {
  Pass = "Pass",
  Fail = "Fail",
  NotYetRun = "NotYetRun",
  Warning = "Warning",
  Scheduled = "Scheduled"
}

export type BuildTestStatus = ExtendedBuildStatus | BuildStatus;
