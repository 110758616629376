import { makeStyles } from "@material-ui/core";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";

export const useStyles = makeStyles(theme => ({
  border: {
    width: "6px",
    backgroundColor: getMatchingBorderColorForStatus(ExtendedBuildStatus.Scheduled, theme),
    flexShrink: 0
  },
  rootCompact: {
    padding: theme.spacing(2, 2, 2, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 2, 3, 0)
    },
    maxWidth: `calc(100% - ${6 + 1 + theme.spacing(1)}px)`, // 6px for the left border, 1px for the right border, spacing 3 for the padding right
    flexGrow: 1,
    boxShadow: "none",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: "5px"
  },
  wrapper: {
    display: "flex",
    borderRadius: "4px",
    overflow: "hidden",
    marginBottom: "10px",
    boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.04)"
  },
  testSuiteId: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.navy[300]
  },
  childrenBox: {
    marginTop: theme.spacing(1),
    marginLeft: "76px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      marginBottom: theme.spacing(2)
    }
  },
  iconWrapperSpecific: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      display: "block",
      justifyContent: "left"
    }
  }
}));
