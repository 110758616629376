import React from "react";
import { Threshold } from "../TestCollapse/TestCollapse";
import { ThresholdType } from "../../../generated-graphql-interfaces";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "16px",
    height: 64,
    borderBottom: `1px solid ${theme.palette.navy[50]}`
  }
}));

export interface TestThresholdToggleProps {
  threshold: Threshold;
  handleThresholdTypeChange: (thresholdType: ThresholdType) => void;
}

export function TestThresholdToggle(props: TestThresholdToggleProps): JSX.Element {
  const { handleThresholdTypeChange, threshold } = props;
  const [tabIndex, setTabIndex] = React.useState(threshold.type === ThresholdType.Relative ? 0 : 1);
  const classes = useStyles();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number): void {
    const thresholds = [ThresholdType.Relative, ThresholdType.Absolute];
    setTabIndex(newValue);
    handleThresholdTypeChange(thresholds[newValue]);
  }

  return (
    <>
      <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" data-testid="test-threshold-toggle">
        <Tab
          className={classes.root}
          data-cy="severity-threshold-unit-percentage"
          data-testid="severity-threshold-unit-percentage"
          data-pendo="auto-test-suite-edit-choose-tests-test-threhsold-percentage"
          label="Percentage %"
        />
        <Tab
          className={classes.root}
          data-cy="severity-threshold-unit-abs-number"
          data-testid="severity-threshold-unit-abs-number"
          data-pendo="auto-test-suite-edit-choose-tests-test-threhsold-absolute"
          label="Absolute number"
        />
      </Tabs>
    </>
  );
}
