import React from "react";
import { getBuildsStatuses_node_TestSuite_builds } from "../../../../generated-graphql-interfaces";
import { BuildStatusContext } from "../../contexts/BuildStatusProvider/BuildStatusProvider";

export function useBuildStatusContext() {
  const context = React.useContext(BuildStatusContext);

  if (!context) {
    throw new Error("useBuildStatusContext must be used within a BuildStatusProvider");
  }

  const testSuiteBuilds: getBuildsStatuses_node_TestSuite_builds =
    context.data?.node?.__typename === "TestSuite"
      ? context.data.node.builds
      : { __typename: "BuildConnection", nodes: [] };

  return { ...context, builds: testSuiteBuilds };
}
