import { useState, useCallback } from "react";
import { AutomatorPermissions } from "../../contexts/Permissions/Permissions.context";

export function usePermissions() {
  const [permissions, setPermissions] = useState<AutomatorPermissions>({
    jiraIntegrationAccess: false,
    globalTemplatesAccess: false,
    frontendSchedulerAccess: false,
    automaticThresholdAccess: false
  });

  const [loaded, setLoaded] = useState<boolean>(false);

  const setCurrentPermissions = useCallback((context: AutomatorPermissions) => {
    setLoaded(true);
    setPermissions(context);
  }, []);

  return {
    permissions,
    loaded,
    setCurrentPermissions
  };
}
