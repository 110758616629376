import { SlackAlertType } from "../../../../../../generated-graphql-interfaces";

export function getInitialValuesFromAlerts(alertTypes: SlackAlertType[]) {
  const [pass, warning, fail] = [SlackAlertType.Pass, SlackAlertType.Warning, SlackAlertType.Fail].map(type =>
    alertTypes.includes(type)
  );

  return {
    pass,
    warning,
    fail
  };
}
