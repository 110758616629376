import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { createBuildSchedule, createBuildScheduleVariables } from "../../../generated-graphql-interfaces";

export const createBuildScheduleGql = gql`
  mutation createBuildSchedule($input: CreateBuildScheduleInput!) {
    createBuildSchedule(input: $input) {
      buildSchedule {
        id
      }
    }
  }
`;

export function useCreateBuildScheduleMutation() {
  return useMutation<createBuildSchedule, createBuildScheduleVariables>(createBuildScheduleGql);
}
