import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { createTestSuite, createTestSuiteVariables } from "../../../../../generated-graphql-interfaces";

export function useCreateTestSuiteMutation() {
  return useMutation<createTestSuite, createTestSuiteVariables>(
    gql`
      mutation createTestSuite(
        $accountId: ObjectID!
        $name: String!
        $location: TestSuiteLocationCode!
        $sitePrimary: String!
        $siteTestUser: String!
        $customHeaderUserAgent: String!
        $customHeaderUserAgentShort: String!
        $customDns: [CustomDnsSettingInput!]
        $siteTestPass: String
        $automaticThreshold: Boolean
        $automaticThresholdEnabledForAll: Boolean
      ) {
        createTestSuite(
          input: {
            accountId: $accountId
            name: $name
            location: $location
            sitePrimary: $sitePrimary
            siteTestUser: $siteTestUser
            siteTestPass: $siteTestPass
            customHeaderUserAgent: $customHeaderUserAgent
            customHeaderUserAgentShort: $customHeaderUserAgentShort
            customDns: $customDns
            automaticThreshold: $automaticThreshold
            automaticThresholdEnabledForAll: $automaticThresholdEnabledForAll
          }
        ) {
          testSuite {
            id
            name
            location {
              code
              name
            }
            sitePrimary
            siteTestUser
            hasSiteTestPass
            customHeaderUserAgent
            customHeaderUserAgentShort
            customDns {
              hostname
              ipAddress
            }
          }
        }
      }
    `
  );
}
