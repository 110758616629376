/* eslint-disable max-lines */
import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import { Alert } from "@material-ui/lab";
import {
  getTestSuite_node_TestSuite,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions,
  getTestSuiteTestsAndCustomExtractions,
  getTestSuiteTestsAndCustomExtractionsVariables,
  getReportTemplates
} from "../../../../generated-graphql-interfaces";
import { Grid, Box, Typography, LinearProgress, CircularProgress, Backdrop } from "@material-ui/core";
import { areAnyTestsSelected } from "./CreateTestsCommonUtils";
import { ExtendedTest, CategoriesCode } from "./CreateTests.interfaces";
import useStyles from "./ChooseTestFormStyles";
import { CollapsableTestsList } from "./components/CollapsableTestsList";
import { ChooseTestsSelectionList } from "./components/ChooseTestsSelectionList";
import {
  getCategories,
  areCustomExtractionArraysDifferent,
  isAnySelectedTestInvalid,
  resolveTestsChunks,
  getCreateTestMutationVariables,
  getUpdateTestMutationVariables
} from "./ChooseTestsFormUtils";
import SearchField from "../../../../_common/components/SearchField/SearchField";
import { ChooseTestsFilter } from "./components/ChooseTestFilter";
import { ChooseTestsFormCounters } from "./components/ChooseTestsFormCounters";
import { chunk } from "lodash";
import { permissionsContext } from "../../../../_common/contexts/Permissions/Permissions.context";
import { useCreateTestMutation } from "./graphql/useCreateTestMutation";
import { useUpdateTestMutation } from "./graphql/useUpdateTestMutation";
import { useDeleteTestMutation } from "./graphql/useDeleteTestMutation";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../_common/components/PopoverDialog/PopoverDialog";
import { MAX_TESTS_SELECTION_LIMIT } from "./CreateTestsCommonConst";
import { chooseTestsReducer, initialChooseTestsFormValue } from "./chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { AutomaticThresholdSettings } from "./AutomaticThresholdSettings";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { useUpdateAutomaticThresholdMutation } from "./graphql/useUpdateAutomaticThresholdMutation";
import { isResolutionWithin, ResolutionStep } from "../../../../_common/utils/window/window";
import { FloatingStepSave } from "../FloatingStepSave/FoatingStepSave";
import { useSaveChangesContext } from "../../SaveChangesProvider";

export interface ChooseTestsFormProps {
  testSuite: getTestSuite_node_TestSuite;
  onSuccess: () => void;
  useTestSuiteTestsAndExtractionsQueryObject: {
    loading: boolean;
    data: getTestSuiteTestsAndCustomExtractions | undefined;
    error: ApolloError | undefined;
    refetch: (
      variables?: getTestSuiteTestsAndCustomExtractionsVariables | undefined
    ) => Promise<ApolloQueryResult<getTestSuiteTestsAndCustomExtractions>>;
  };
  hasChildren?: boolean;
  reportTemplateObject: {
    loading: boolean;
    data: getReportTemplates | undefined;
    error: ApolloError | undefined;
  };
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  setSmartThresholdSettings: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Ok",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "secondary"
  }
];

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
function ChooseTestsForm(props: ChooseTestsFormProps) {
  const [chooseTestsFormState, chooseTestsFormDispatch] = useReducer(chooseTestsReducer, initialChooseTestsFormValue);

  const popupReference = useRef(null);
  const classes = useStyles();

  const {
    refetch: refetchTestsAndCustomExtractions,
    data: testsAndExtractions,
    error: testsAndExtractionsError,
    loading: testsAndExtractionsLoading
  } = props.useTestSuiteTestsAndExtractionsQueryObject;

  const [customExtractions, setCustomExtractions] =
    useState<getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[]>();
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [shouldRefreshCategories, setShouldRefreshCategories] = useState<boolean>(true);
  const MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE = 99;
  const { permissions } = useContext(permissionsContext);
  const anyTestsSelected = areAnyTestsSelected(chooseTestsFormState.categories);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [createTestCall] = useCreateTestMutation();
  const [updateTestCall] = useUpdateTestMutation();
  const [deleteTestCall] = useDeleteTestMutation();
  const [updateAutomaticThreshold] = useUpdateAutomaticThresholdMutation();

  const saveChangesContext = useSaveChangesContext();

  const { loading: areReportTemplatesLoading, data: reportTemplates, error: loadingError } = props.reportTemplateObject;

  const testsAndExtractionsNode = testsAndExtractions?.node as getTestSuiteTestsAndCustomExtractions_node_TestSuite;

  // eslint-disable-next-line complexity
  useEffect(() => {
    function categoriesLoadedOrTestSuiteChangedOrSetToReload() {
      return (
        chooseTestsFormState.categories.length === 0 ||
        areCustomExtractionArraysDifferent(customExtractions, testsAndExtractionsNode.customExtractions ?? undefined) ||
        shouldRefreshCategories
      );
    }

    if (
      !areReportTemplatesLoading &&
      !testsAndExtractionsLoading &&
      categoriesLoadedOrTestSuiteChangedOrSetToReload() &&
      testsAndExtractions &&
      reportTemplates?.getAutomatorReportTemplates?.edges
    ) {
      setShouldRefreshCategories(false);
      setCustomExtractions(testsAndExtractionsNode.customExtractions ?? undefined);
      const newCategories = getCategories(reportTemplates?.getAutomatorReportTemplates.edges, testsAndExtractionsNode);
      chooseTestsFormDispatch({ type: "SET_CATEGORIES", payload: { categories: newCategories } });
    }
  }, [
    areReportTemplatesLoading,
    chooseTestsFormState.categories.length,
    customExtractions,
    reportTemplates?.getAutomatorReportTemplates.edges,
    shouldRefreshCategories,
    testsAndExtractions,
    testsAndExtractionsLoading,
    testsAndExtractionsNode
  ]);

  useEffect(() => {
    setShouldRefreshCategories(true);
  }, [testsAndExtractions]);

  function createTestFn(test: ExtendedTest) {
    if (!test.data.id && test.extended.selected) {
      const variables = getCreateTestMutationVariables(
        test,
        permissions.jiraIntegrationAccess,
        props.testSuite.id,
        permissions.automaticThresholdAccess
      );
      return createTestCall({
        variables
      });
    }
  }

  function updateTestFn(test: ExtendedTest) {
    if (test.data.id && test.extended.selected) {
      const variables = getUpdateTestMutationVariables(
        test,
        permissions.jiraIntegrationAccess,
        permissions.automaticThresholdAccess
      );
      return updateTestCall({
        variables
      });
    }
  }

  function deleteTestFn(test: ExtendedTest) {
    if (test.data.id && !test.extended.selected) {
      const testId = test.data.id;
      // eslint-disable-next-line fp/no-mutation
      test.data.id = "";
      return deleteTestCall({
        variables: {
          testId: testId
        }
      });
    }
  }

  async function updateAutomaticThresholdOnTestSuite() {
    try {
      setSubmitting(true);
      await updateAutomaticThreshold({
        variables: {
          testSuiteId: props.testSuite.id,
          automaticThreshold: props.smartThresholdSettings.isEnabled,
          automaticThresholdEnabledForAll: props.smartThresholdSettings.enabledForAllTests
        }
      });
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setSubmitError("There has been an error while saving data. Please refresh the page and try again.");
    }
  }

  async function handleSave() {
    const testChunks = chunk(
      chooseTestsFormState.categories.flatMap(category => category.tests),
      MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE
    );

    if (testChunks.length) {
      setSubmitting(true);
      return resolveTestsChunks(testChunks, 0, createTestFn)
        .then(() => {
          return resolveTestsChunks(testChunks, 0, updateTestFn);
        })
        .then(() => {
          return resolveTestsChunks(testChunks, 0, deleteTestFn);
        })
        .then(async () => {
          await updateAutomaticThresholdOnTestSuite();
          setSubmitError(undefined);
        })
        .finally(() => {
          refetchTestsAndCustomExtractions();
          setSubmitting(false);
        });
    }
  }

  async function handleSubmit() {
    if (!areAnyTestsSelected(chooseTestsFormState.categories)) {
      return;
    }

    if (isAnySelectedTestInvalid(chooseTestsFormState.categories)) {
      setSubmitError("Please fill in all required fields in order to continue.");
      return;
    }

    try {
      await handleSave();
      props.onSuccess();
    } catch (e) {
      setSubmitError("There has been an error while saving data. Please refresh the page and try again.");
    }
  }

  useEffect(() => {
    if (!props.testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseTestsFormState]);

  function clearSubmitError() {
    setSubmitError(undefined);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function openMaxTestSelectionPopup() {
    setAnchorEl(popupReference.current);
  }

  const customExtractionSelectedTests = chooseTestsFormState.categories.filter(
    cat => cat.code === CategoriesCode.CUSTOM_EXTRACTION
  );
  const nonCustomExtractionSelectedTests = chooseTestsFormState.categories.filter(
    cat => cat.code !== CategoriesCode.CUSTOM_EXTRACTION
  );

  const filtersContainer = useRef<HTMLDivElement>(null);
  const testsContainer = useRef<HTMLDivElement>(null);
  const [computedMaxHeight, setComputedMaxHeight] = useState<number>(640);

  function calcHeight() {
    const areLocatedNextToEachOther = isResolutionWithin(ResolutionStep.SM);
    if (areLocatedNextToEachOther) {
      const filtersContainerBoundingBox = filtersContainer.current?.getBoundingClientRect();
      const testsConteinerBoundingBox = testsContainer.current?.getBoundingClientRect();
      if (testsConteinerBoundingBox && filtersContainerBoundingBox) {
        const max = Math.max(testsConteinerBoundingBox?.height - filtersContainerBoundingBox?.height, 640);
        setComputedMaxHeight(max);
      }
    } else {
      setComputedMaxHeight(640);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", calcHeight);
    setTimeout(() => {
      calcHeight();
    }, 1000);

    return () => window.removeEventListener("resize", calcHeight);
  }, [chooseTestsFormState.categories]);

  if (areReportTemplatesLoading || testsAndExtractionsLoading) {
    return <CircularProgress data-testid="choose-tests-form-loading" />;
  }

  /* eslint-disable no-nested-ternary */
  return (
    <ChooseTestsDispatch.Provider value={chooseTestsFormDispatch}>
      <Grid container ref={popupReference}>
        <PopoverDialog
          anchorElement={anchorEl}
          handleAction={handleClose}
          open={Boolean(anchorEl)}
          text={`The maximum number of tests that you can select is ${MAX_TESTS_SELECTION_LIMIT}. Please deselect existing tests before choosing more.`}
          title="Can’t select more tests"
          buttons={popoverButtons}
          id={`{simple-popover-warning-${MAX_TESTS_SELECTION_LIMIT}}`}
          centered={true}
          icon={<InfoRoundedIcon className={classes.icon} />}
        />
        {loadingError || testsAndExtractionsError ? (
          <Alert severity="warning">There has been an error while loading data for this view.</Alert>
        ) : !areReportTemplatesLoading && chooseTestsFormState.categories.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Box className={classes.padding}>
                <Box mb={4}>
                  <Typography color="textPrimary">
                    While Automation Hub crawls it will run the below tests. These will dictate the overall success of
                    the build. The maximum number of tests that can be selected for each test suite is 100.
                  </Typography>
                </Box>
                <Grid container className={classes.containerStart}>
                  <Grid item sm={4} className={classes.grid}>
                    <div ref={filtersContainer} id="layoutAffector">
                      <SearchField debounce={300} />
                      <ChooseTestsFilter
                        chooseTestsFormState={chooseTestsFormState}
                        filterToggle={() => {
                          setTimeout(() => {
                            calcHeight();
                          }, 1000);
                        }}
                      />
                      <ChooseTestsFormCounters chooseTestsFormState={chooseTestsFormState} />
                    </div>

                    <ChooseTestsSelectionList
                      maxHeight={computedMaxHeight}
                      chooseTestsFormState={chooseTestsFormState}
                      clearSubmitError={clearSubmitError}
                      openMaxTestSelectionPopup={openMaxTestSelectionPopup}
                      renderingProgressed={calcHeight}
                    />
                  </Grid>
                  <Grid item sm={8} ref={testsContainer}>
                    {!props.hasChildren && !props.testSuite.parent && permissions.automaticThresholdAccess && (
                      <AutomaticThresholdSettings
                        smartThresholdSettings={props.smartThresholdSettings}
                        handleSmartThresholdSettingsChange={props.setSmartThresholdSettings}
                      />
                    )}
                    {props.testSuite.customExtractions && props.testSuite.customExtractions.length > 0 && (
                      <>
                        <Typography
                          data-cy="custom-extractions-test-list-header"
                          variant="h5"
                          className={classes.collapsableListHeadingCustomExtraction}
                        >
                          Custom Extractions
                        </Typography>
                        <CollapsableTestsList
                          isJiraIntegrationConnected={Boolean(props.testSuite.testSuiteJiraIntegration)}
                          noItemsMessage="If you’ve added a custom extraction in step 2, please also select it from the list of tests on the left. You can search or filter for 'custom extractions'."
                          noItemsMessageId="no-custom-extractions-message"
                          categories={customExtractionSelectedTests}
                          clearSubmitError={clearSubmitError}
                          renderingProgressed={() => calcHeight()}
                          smartThresholdSettings={props.smartThresholdSettings}
                        />
                      </>
                    )}
                    <Typography variant="h5" className={classes.collapsableListHeadingOtherTests}>
                      Selected tests
                    </Typography>
                    <CollapsableTestsList
                      isJiraIntegrationConnected={Boolean(props.testSuite.testSuiteJiraIntegration)}
                      categories={nonCustomExtractionSelectedTests}
                      clearSubmitError={clearSubmitError}
                      renderingProgressed={() => calcHeight()}
                      smartThresholdSettings={props.smartThresholdSettings}
                    />

                    {submitError && (
                      <Alert data-cy="submit-error" className={classes.submitError} severity="error">
                        {submitError}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Backdrop open={!!submitting} className={classes.backdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <FloatingStepSave
                disabled={!anyTestsSelected || submitting}
                onClick={handleSubmit}
                label="Save"
                dataTestId="choose-tests-form-save"
                dataCy="choose-tests-form-save"
                data-pendo="auto-testsuite-edit-add-choose-tests-save-step"
              />
            </Grid>
          </>
        ) : (
          <div className={classes.progresssBar}>
            <LinearProgress key="linear-progress" />
          </div>
        )}
      </Grid>
    </ChooseTestsDispatch.Provider>
  );
  /* eslint-enable no-nested-ternary */
}

export default ChooseTestsForm;
