import gql from "graphql-tag";

export const getNotLinkedTestSuitesGql = gql`
  query getNotLinkedTestSuites($accountId: ObjectID!, $cursor: String) {
    getAccount(id: $accountId) {
      id
      notLinkedTestSuites(first: 100, after: $cursor, orderBy: [{ field: name, direction: ASC }]) {
        edges {
          node {
            name
            id
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
          startCursor
        }
      }
    }
  }
`;
