import { StatusIconProps, StatusIconSize } from "./StatusIcon";
import { makeStyles } from "@material-ui/core";
import { getColorForType, getStatusTextColors, getBorderMatchingColors } from "./StatusIconUtils";

function getFontSizeForIcon(size: StatusIconSize | undefined) {
  if (size === StatusIconSize.SM) {
    return "1.5rem";
  } else if (size === StatusIconSize.MD) {
    return "30px";
  } else if (size === StatusIconSize.LG) {
    return "32px";
  } else {
    return "46px";
  }
}

export const useStyles = makeStyles(theme => ({
  status: (props: StatusIconProps) => {
    const colors = getBorderMatchingColors(theme);
    return {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexDirection: props.inline ? "row" : "column",
      marginBottom: "0px",
      color: getColorForType(colors, props.type)
    };
  },
  icon: (props: StatusIconProps) => {
    return {
      opacity: 0.6,
      marginBottom: props.size === StatusIconSize.SM ? theme.spacing(1) : "0",
      fontSize: getFontSizeForIcon(props.size),
      marginRight: props.inline ? "10px" : "0"
    };
  },
  statusText: (props: StatusIconProps) => {
    const colors = getStatusTextColors(theme);
    return {
      textTransform: "uppercase",
      fontWeight: 500,
      color: getColorForType(colors, props.type),
      fontSize: "12px"
    };
  }
}));
