import { generatePath } from "react-router-dom";
import { RequiredParams, Routes } from "../routes";

interface AdditionalParams extends RequiredParams {
  [paramName: string]: string;
}

export function generatePathSafely(pattern: string, params: AdditionalParams) {
  const nullParamValues = Object.values(params).filter(p => !p);

  if (nullParamValues.length) {
    return Routes.LoginCallback.ROUTE;
  }
  return generatePath(pattern, params);
}
