import React, { useContext, useState } from "react";
import { CategoriesCode } from "../CreateTests.interfaces";
import { Grid, Box, Checkbox, InputLabel, Typography } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./ChooseTestFilterStyles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { ChooseTestsState } from "../chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";

export interface ChooseTestsFilterProps {
  chooseTestsFormState: ChooseTestsState;
  filterToggle: (expanded: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
export function ChooseTestsFilter(props: ChooseTestsFilterProps) {
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const [isFilterOpen, setFilterIsOpen] = useState(false);
  const classes = useStyles();

  function selectAllTest(selected: boolean) {
    if (selected) {
      chooseTestsFormDispatch({ type: "SHOW_ALL_CATEGORIES", payload: {} });
    }
  }

  return (
    <Box className={classes.filterBox}>
      <Accordion
        className={classes.filterCategories}
        onChange={(event: React.ChangeEvent<{}>, expanded: boolean) => {
          setFilterIsOpen(expanded);
          props.filterToggle(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.filterCategoriesHeader}
          classes={{ content: classes.accordionContent }}
          data-cy="tests-filter-expension-panel"
          data-testid="tests-filter-expension-panel"
          data-pendo="auto-test-suite-edit-choose-tests-filter"
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid
              container
              item
              direction="row"
              data-testid="tests-filter-label"
              alignItems="center"
              justify="flex-start"
              className={classes.filterCategoriesHeaderLeft}
            >
              <Grid item className={classes.icon}>
                <FilterListIcon />
              </Grid>
              <Grid item>
                <Typography color="textPrimary">Filters</Typography>
              </Grid>
            </Grid>
            <Grid item data-testid="tests-filter-trigger">
              {isFilterOpen ? "Hide" : "Show"}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.filterCategoryDetail}>
          <Grid className={classes.mainGrid}>
            {props.chooseTestsFormState.categories.map(category => {
              const categoryCode =
                category.code === CategoriesCode.CUSTOM_EXTRACTION ? "custom-extractions" : category.code;

              return (
                <Box
                  key={`${category.code}-category-box`}
                  display="flex"
                  alignItems="center"
                  data-pendo={`auto-test-suite-edit-choose-tests-filter-${categoryCode}`}
                >
                  <Checkbox
                    id={`${category.code}-category-checkbox`}
                    data-testid={`${categoryCode}-checkbox`}
                    data-cy={`${categoryCode}-checkbox`}
                    checked={category.selected}
                    onChange={(_, selected) =>
                      chooseTestsFormDispatch({
                        type: "TOGGLE_CATEGORY",
                        payload: { categoryId: category.code, isCategorySelected: selected }
                      })
                    }
                  />
                  <InputLabel htmlFor={`${category.code}-category-checkbox`}>{category.name}</InputLabel>
                </Box>
              );
            })}
            <hr className={classes.divider} />
            <Box
              key={`show-selected-box`}
              display="flex"
              alignItems="center"
              data-pendo="auto-test-suite-edit-choose-tests-filter-selected"
            >
              <Checkbox
                id={`show-selected-checkbox`}
                data-testid={`show-selected-checkbox`}
                checked={props.chooseTestsFormState.filter.showSelected}
                onChange={(_, selected) =>
                  chooseTestsFormDispatch({ type: "TOGGLE_SELECTED_TESTS", payload: { showSelected: selected } })
                }
              />
              <InputLabel htmlFor={`show-selected-checkbox`}>Show selected tests</InputLabel>
            </Box>
            <Box
              key={`show-all-box`}
              display="flex"
              alignItems="center"
              data-pendo="auto-test-suite-edit-choose-tests-filter-all"
            >
              <Checkbox
                id={`show-all-checkbox`}
                data-testid={`show-all-checkbox`}
                checked={props.chooseTestsFormState.filter.showAllCategories}
                onChange={(event, selected) => selectAllTest(selected)}
              />
              <InputLabel htmlFor={`show-all-checkbox`}>Show all tests</InputLabel>
            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
