import { TestSuiteVariables } from "./TestSuiteVariables";
import {
  LocationCode,
  TestSuiteLocationCode,
  getTestSuite_node_TestSuite_customDns
} from "../../../../generated-graphql-interfaces";
import { FormikProps } from "formik";
import { useEffect } from "react";
import { CustomDnsPair } from "./ConnectSiteForm";

export interface ConnectSiteFormValues extends Omit<TestSuiteVariables, "location" | "customDns"> {
  location: LocationCode | TestSuiteLocationCode;
  customDns: getTestSuite_node_TestSuite_customDns[];
}

interface ErrorEffectProps {
  formik: FormikProps<ConnectSiteFormValues>;
  onSubmissionError: () => void;
}

function doesCustomDnsContainEmptyObject(customDnsPairs: CustomDnsPair[]): boolean {
  return !!customDnsPairs.find(pair => !pair.hostname && !pair.ipAddress);
}

export function ErrorEffect(props: ErrorEffectProps) {
  const { submitCount, isValid, errors, values } = props.formik;
  const isAdvancedSettingsError = !isValid && !errors.name && !errors.sitePrimary;

  useEffect(() => {
    if (submitCount > 0 && (isAdvancedSettingsError || doesCustomDnsContainEmptyObject(values.customDns))) {
      props.onSubmissionError();
    }
  }, [isAdvancedSettingsError, props, submitCount, values.customDns]);
  return null;
}
