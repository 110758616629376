import React from "react";
import { Box, Checkbox, InputLabel, makeStyles } from "@material-ui/core";
import { ExtendedTest } from "../CreateTests.interfaces";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(1, 0, 1, 0),
    fontSize: 15,
    flexGrow: 1,
    cursor: "pointer"
  },
  checkBox: {
    lineHeight: theme.spacing(0),
    "&.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto"
    }
  },
  disabled: {
    cursor: "not-allowed",
    color: theme.palette.grey[400]
  },
  tooltip: {
    margin: theme.spacing(0, 1, 0, 1)
  }
}));

const useIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.navy[200],
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.navy[300]
    }
  }
}));

const useIconDisabledStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[300],
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.navy[300]
    }
  }
}));

export interface ChooseTestsSelectionCheckBoxProps {
  test: ExtendedTest;
  onChange: (selected: boolean) => void;
  disabled: boolean;
}

function compareProps(prevProp: ChooseTestsSelectionCheckBoxProps, newProp: ChooseTestsSelectionCheckBoxProps) {
  return (
    prevProp.test.extended.selected === newProp.test.extended.selected &&
    prevProp.test.data.id === newProp.test.data.id &&
    prevProp.disabled === newProp.disabled
  );
}

function ChooseTestsSelectionCheckBox(props: ChooseTestsSelectionCheckBoxProps) {
  const classes = useStyles();
  const tooltipIconClasses = useIconStyles();
  const tooltipIconClassesDiasbled = useIconDisabledStyles();
  const isDisabled = props.disabled && !props.test.extended.selected;

  return (
    <Box
      key={`${props.test.data.reportTemplate.code}-checkbox`}
      display="flex"
      alignItems="center"
      data-pendo={`auto-test-suite-edit-choose-tests-selection-${props.test.data.reportTemplate.code}`}
    >
      <Checkbox
        className={clsx(classes.checkBox, isDisabled && classes.disabled)}
        id={`${props.test.data.reportTemplate.code}-checkbox`}
        data-testid={`${props.test.data.reportTemplate.code}-checkbox`}
        checked={props.test.extended.selected}
        onChange={() => !isDisabled && props.onChange(!props.test.extended.selected)}
        disabled={isDisabled}
      />
      <InputLabel
        htmlFor={`${props.test.data.reportTemplate.code}-checkbox`}
        className={clsx(classes.label, isDisabled && classes.disabled)}
      >
        {props.test.data.reportTemplate.name}
      </InputLabel>
      <Box className={classes.tooltip}>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-choose-test-selection"
          body={props.test.data.reportTemplate.automatorSummary || "N/A"}
          maxWidth={350}
          testId={`${props.test.data.reportTemplate.code}-tooltip`}
          iconComponent={
            isDisabled ? (
              <InfoIcon classes={tooltipIconClassesDiasbled} fontSize="small" />
            ) : (
              <InfoIcon classes={tooltipIconClasses} fontSize="small" />
            )
          }
        />
      </Box>
    </Box>
  );
}

export default React.memo(ChooseTestsSelectionCheckBox, compareProps);
