import { TypographyClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";

export const muiTypography: Partial<Record<
  TypographyClassKey,
  CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>
>> = {
  body1: {
    color: "#525151"
  }
};
