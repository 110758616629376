import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { BuildInfo } from "../Dashboard";
import { getFinishedStatusFromBuild } from "../utils/getFinishedStatusFromBuild";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { BuildStatus } from "../../../generated-graphql-interfaces";

interface BuildListItemContentProps {
  buildInfo: BuildInfo;
  isFirstItem: boolean;
}

const useStyles = makeStyles(theme => ({
  buildListItem: (props: BuildListItemContentProps) => {
    const status = getFinishedStatusFromBuild(props.buildInfo);
    const borderColor = getMatchingBorderColorForStatus(status, theme);
    const isBuildFinished = props.buildInfo.status === BuildStatus.Finished;

    return {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderLeft: `6px solid ${borderColor}`,
      padding: theme.spacing(2),
      height: "100%",
      cursor: isBuildFinished ? "pointer" : "default",
      transition: isBuildFinished ? "all 0.2s ease-in-out" : "none",
      "&:hover": {
        backgroundColor: isBuildFinished ? theme.palette.grey[100] : "none"
      }
    };
  },
  firstBuildListItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  overflowText: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  name: {
    fontSize: 15,
    fontWeight: 600
  },
  sitePrimary: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.navy[300]
  },
  gridContainer: { minHeight: "72px" },
  icon: { color: theme.palette.grey[500] },
  chevonWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  iconWrapper: {
    minWidth: 53
  }
}));

export function BuildListItemContent(props: BuildListItemContentProps) {
  const classes = useStyles(props);
  const { buildInfo } = props;
  const testStatus = getFinishedStatusFromBuild(buildInfo);
  const isBuildFinished = buildInfo.status === BuildStatus.Finished;

  return (
    <Box
      className={clsx(classes.buildListItem, props.isFirstItem && classes.firstBuildListItem)}
      data-testid="build-list-item-box"
      data-pendo="auto-latest-run-testsuite-item"
    >
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.gridContainer}>
        <Grid item xs={11}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box mr={3} ml={1} data-testid="build-list-item-status" className={classes.iconWrapper}>
              <BuildStatusIcon status={testStatus} size={StatusIconSize.SM} />
            </Box>
            <Box>
              <Typography
                variant="body1"
                color="textPrimary"
                className={clsx(classes.overflowText, classes.name)}
                data-testid="build-list-item-name"
              >
                {buildInfo.name}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                data-testid="build-list-item-site-primary"
                className={clsx(classes.overflowText, classes.sitePrimary)}
              >
                {buildInfo.sitePrimary}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {isBuildFinished && (
          <Grid item xs={1} className={classes.chevonWrapper}>
            <ChevronRightIcon fontSize="large" className={classes.icon} data-testid="step-into-icon" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
