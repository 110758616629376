import { GraphQLError } from "graphql";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getContstrainAsString(err: any) {
  if (err.constraints) {
    return Object.keys(err.constraints).map(key => {
      return err.constraints[key];
    });
  } else if (Array.isArray(err.children)) {
    return err.children.flatMap(getContstrainAsString);
  } else {
    return "";
  }
}

export function getGraphQlValidationMessages(errors: readonly GraphQLError[]) {
  return errors.flatMap(error => {
    if (error?.extensions?.exception?.validationErrors) {
      return error.extensions.exception.validationErrors.flatMap(getContstrainAsString);
    } else {
      return [];
    }
  });
}

export function getFullListOfMessagesFromError(errors: readonly GraphQLError[]) {
  return errors.map(({ message }) => message).concat(getGraphQlValidationMessages(errors));
}
