import React from "react";
import { makeStyles, FormHelperText } from "@material-ui/core";
import { deepCrawlTheme } from "../../../theme/deepCrawlTheme";
import { FormikErrors } from "formik";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

function getBaseColour(colorToGet: FormHelperTextStyledType | undefined) {
  const colourMatch: { color: FormHelperTextStyledType; returnColour: string }[] = [
    { color: FormHelperTextStyledType.ERROR, returnColour: deepCrawlTheme.palette.error.main },
    { color: FormHelperTextStyledType.WARNING, returnColour: deepCrawlTheme.palette.deepcrawlOrange.main },
    { color: FormHelperTextStyledType.INFO, returnColour: deepCrawlTheme.palette.grey[300] },
    { color: FormHelperTextStyledType.SUCCESS, returnColour: deepCrawlTheme.palette.deepcrawlGreen.main }
  ];
  return colourMatch.filter(match => match.color === colorToGet)[0].returnColour;
}

const useStyles = makeStyles(theme => ({
  main: (props: FormHelperTextStyledProps) => {
    return {
      marginTop: theme.spacing(1),
      color: getBaseColour(props.type),
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0px",
      textAlign: "left"
    };
  },
  icon: (props: FormHelperTextStyledProps) => {
    return {
      color: getBaseColour(props.type),
      fontSize: "18px",
      marginBottom: "-4px",
      marginRight: theme.spacing(1)
    };
  }
}));

export enum FormHelperTextStyledType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success"
}

export interface FormHelperTextStyledProps {
  text: FormikErrors<Date | string | number> | undefined;
  id: string;
  show?: boolean;
  type?: FormHelperTextStyledType;
  testId?: string;
}

export function FormHelperTextStyled(props: FormHelperTextStyledProps) {
  const { text, id, type = FormHelperTextStyledType.INFO, show = true, testId } = props;
  const classes = useStyles(props);

  return show ? (
    <FormHelperText id={id} className={classes.main} data-testid={testId} data-cy={testId}>
      {type === FormHelperTextStyledType.ERROR && <ErrorOutlineRoundedIcon className={classes.icon} />}
      {type === FormHelperTextStyledType.WARNING && <WarningRoundedIcon className={classes.icon} />}
      {type === FormHelperTextStyledType.SUCCESS && <CheckCircleRoundedIcon className={classes.icon} />}
      {text}
    </FormHelperText>
  ) : (
    <></>
  );
}
