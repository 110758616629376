import gql from "graphql-tag";

export const getTestSuiteGql = gql`
  query getTestSuite($id: ObjectID!, $hasJiraAccess: Boolean!, $hasSmartThresholdAccess: Boolean!) {
    node(id: $id) {
      ... on TestSuite {
        siteTestUser
        hasSiteTestPass
        coreUIUrl
        crawlRate
        crawlTypes
        customExtractions {
          regex
          label
        }
        customHeaderUserAgent
        customHeaderUserAgentShort
        customDns {
          hostname
          ipAddress
        }
        duplicatePrecision
        emptyPageThreshold
        highLogSummaryRequests
        limitPagesMax
        lowLogSummaryRequests
        location {
          code
          name
        }
        maxContentSize
        maxDescriptionLength
        maxExternalLinks
        maxHtmlSize
        maxLinks
        maxLoadTime
        maxRedirections
        maxTitleWidth
        maxUrlLength
        minDescriptionLength
        minTitleLength
        name
        rendererBlockAds
        rendererBlockAnalytics
        rendererBlockCustom
        rendererJsString
        rendererJsUrls
        useRobotsOverwrite
        robotsOverwrite
        sitePrimary
        siteTest
        thinPageThreshold
        urlsExcluded
        urlsIncluded
        startUrls
        useRenderer
        createdAt
        updatedAt
        id
        account {
          id
          maxCrawlRate
        }
        slackWebhooks(first: 100, orderBy: [{ field: createdAt, direction: DESC }]) {
          nodes {
            id
            url
            alertTypes
          }
        }
        emailAlerts(first: 100, orderBy: [{ field: createdAt, direction: DESC }]) {
          nodes {
            id
            email
            alertTypes
          }
        }
        testSuiteJiraIntegration @include(if: $hasJiraAccess) {
          id
          jiraIntegration {
            id
            name
            jiraUrl
            jiraProjectName
            jiraIssueTypeName
          }
        }
        parent {
          name
          id
        }
        automaticThreshold @include(if: $hasSmartThresholdAccess)
        automaticThresholdEnabledForAll @include(if: $hasSmartThresholdAccess)
      }
    }
  }
`;
