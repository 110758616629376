import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, Divider, Hidden, Box } from "@material-ui/core";
import { DeepcrawlInfoTooltip } from "../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import jiraLogo from "../../../images/jira-logo.svg";
import { JiraIntegrationListItem } from "./components/JiraIntegrationListItem";
import { useHistory, useParams } from "react-router-dom";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import { useStyles } from "./JiraIntegrationsStyles";
import JiraIntegrationDialog, {
  JiraIntegrationDialogAction
} from "../../TestSuite/components/SetAlerts/Jira/JiraIntegrationDialog";
import { Skeleton } from "@material-ui/lab";
import { NoJiraIntegrationsView } from "./components/NoJiraIntegrationsView";
import { JiraIntegrationDetail } from "./components/JiraIntegrationDetail";
import { Routes } from "../../../_common/routes/routes";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { PrimaryActionButton } from "../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import AddIcon from "@material-ui/icons/Add";
import { useGetJiraIntegrationsWithTestSuitesQuery } from "./graphql/useGetJiraIntegrationWithTestSuitesQuery";

// eslint-disable-next-line max-lines-per-function, complexity, max-statements
export function JiraIntegrations(): JSX.Element {
  const { jiraIntegrationId } = useParams<{ jiraIntegrationId?: string; accountId: string }>();
  const classes = useStyles();
  const accountId = useAccountRouteMatch();
  const [isNewJiraIntegrationDialogOpen, setNewJiraIntegrationDialogOpen] = useState(false);

  const {
    data,
    loading: loadingJiraIntegrations,
    error: loadingJiraIntegrationsError,
    refetch
  } = useGetJiraIntegrationsWithTestSuitesQuery({ accountId });

  useEffect(() => {
    if (!jiraIntegrationId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jiraIntegrationId]);

  const jiraIntegrations = data?.jiraIntegrations ?? [];
  const totalCount = data?.totalCount || 0;
  const history = useHistory();

  function selectIntegration(id: string) {
    history.push(Routes.JiraIntegration.getUrl({ accountId, jiraIntegrationId: id }));
  }

  function unselectIntegration() {
    history.push(Routes.ConnectedApps.getUrl({ accountId }));
  }

  return (
    <Grid container alignItems="center" data-testid="jira-integrations">
      <Grid container className={classes.headerContainer}>
        <Grid container item xs={12} md={6} className={classes.headerName}>
          {jiraIntegrationId && (
            <Hidden lgUp>
              <ArrowBackIcon
                className={classes.headerBackIcon}
                onClick={() => unselectIntegration()}
                data-testid="jira-integrations-back"
              />
            </Hidden>
          )}
          <Typography variant="h1" data-cy="jira-integrations-heading" className={classes.title}>
            Jira integrations
          </Typography>
          <DeepcrawlInfoTooltip
            data-pendo="auto-tooltip-jira-integration"
            testId="add-jira-integration-tooltip"
            cyId="add-jira-integration-tooltip"
            title="Manage Jira integrations"
            size={20}
            maxWidth={450}
            iconComponent={<InfoOutlinedIcon className={classes.infoIcon} />}
            body={
              <>
                <Typography variant="body1" component="span">
                  Here you can manage your Jira Integrations:
                </Typography>
                <span className="ul">
                  <span className="li">- See all the Jira Integrations in your account</span>
                  <span className="li">- Edit Jira Integrations</span>
                  <span className="li">- Disconnect a test suite from Jira Integration</span>
                  <span className="li">- Edit Test Suite</span>
                </span>
              </>
            }
          />
        </Grid>
        <Grid container item xs={12} md={6} className={classes.headerButtonAndInfo}>
          <>
            <Grid item>
              <PrimaryActionButton
                data-pendo="jira-integrations-add"
                startIcon={<AddIcon />}
                label="Add Jira Integration"
                dataTestId="add-integration-button"
                dataCy="add-integration-button"
                onClick={() => setNewJiraIntegrationDialogOpen(true)}
                disableForViewerRole={true}
              />
            </Grid>

            <JiraIntegrationDialog
              action={JiraIntegrationDialogAction.Create}
              accountId={accountId}
              isOpen={isNewJiraIntegrationDialogOpen}
              handleClose={() => setNewJiraIntegrationDialogOpen(false)}
            />
          </>
        </Grid>
      </Grid>

      {loadingJiraIntegrations && (
        <Grid container spacing={2} alignItems="flex-start">
          <Grid container item xs={12} lg={5}>
            <Skeleton variant="rect" height={700} width="100%" />
          </Grid>
        </Grid>
      )}

      {!loadingJiraIntegrations && (!jiraIntegrations || jiraIntegrations.length === 0) && (
        <NoJiraIntegrationsView
          addIntegration={() => setNewJiraIntegrationDialogOpen(true)}
          title="Sorry, looks like you don’t have any integrations yet."
          text="You can add an integration to a specific test suite when you are setting up a test suite in step 5 ‘set alerts’ or add a new Jira integration here."
          includeButton={true}
        />
      )}

      {!loadingJiraIntegrations && jiraIntegrations && jiraIntegrations.length > 0 && (
        <Grid container spacing={2} alignItems="flex-start">
          <Grid
            container
            item
            xs={12}
            lg={5}
            className={clsx(jiraIntegrationId && classes.hidableContainer)}
            data-testid="jira-integrations-list"
            data-pendo="auto-jira-integrations-list"
          >
            <Paper elevation={0} className={classes.paper}>
              <Grid container className={classes.listHeader}>
                <img height={40} width={40} src={jiraLogo} alt="Jira Logo" data-testid="jira-tab-logo" />
                <Typography variant="h2" className={classes.jiraListHeader}>
                  Jira integrations in your account
                </Typography>
              </Grid>
              <Divider />
              <Box className={classes.tableHeadings}>
                <Typography data-cy="jira-integrations-count" className={classes.integrationsCount}>
                  {totalCount}
                  {totalCount === 1 ? " integration" : " integrations"}
                </Typography>
              </Box>

              {jiraIntegrations.map(jiraIntegration => (
                <JiraIntegrationListItem
                  isSelected={jiraIntegrationId === jiraIntegration.id}
                  key={jiraIntegration.id}
                  jiraIntegration={jiraIntegration}
                  onClick={selectIntegration}
                />
              ))}
            </Paper>
          </Grid>
          <Grid item container xs={12} lg={7}>
            {jiraIntegrationId && (
              <JiraIntegrationDetail
                jiraIntegration={data.jiraIntegrations?.find(({ id }) => id === jiraIntegrationId)}
                reloadIntegrations={refetch}
                loadingError={loadingJiraIntegrationsError}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
