import React, { useContext, useEffect, useState } from "react";
import { TestCategory } from "./../CreateTests.interfaces";
import { Box } from "@material-ui/core";
import { areAnyTestsSelected, getTestsByTemplateId, nonMutationSortByTestName } from "../CreateTestsCommonUtils";
import { TestCollapse, Threshold } from "../../../../../_common/components/TestCollapse/TestCollapse";
import { ExtendedTest } from "../CreateTests.interfaces";
import { Severity } from "../../../../../generated-graphql-interfaces";
import { getNewTestFromInput, isThresholdAndSeverityDifferent } from "./CollapsableTestsListUtil";
import Alert from "@material-ui/lab/Alert";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { useGradualNumber } from "../../../../../_common/hooks/useGradualNumber/useGradualNumber";
import BufferedLinearProgress from "../../../../../_common/components/BufferedLinearProgress/BufferedLinearProgress";
import { permissionsContext } from "../../../../../_common/contexts/Permissions/Permissions.context";

export interface CollapsableTestsListProps {
  noItemsMessage?: string;
  noItemsMessageId?: string;
  categories: TestCategory[];
  isJiraIntegrationConnected: boolean;
  clearSubmitError: () => void;
  renderingProgressed?: () => void;
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
}

//eslint-disable-next-line max-lines-per-function
export function CollapsableTestsList(props: CollapsableTestsListProps) {
  const [renderingStarted] = useState(new Date().getTime());
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const { permissions } = useContext(permissionsContext);

  const totalTestsToShow = nonMutationSortByTestName(props.categories.flatMap(category => category.tests)).filter(
    test => test.extended.selected
  );

  const { value: maxTestsShown, clear: clearTimeoutForCategoriesRender } = useGradualNumber(
    renderingStarted,
    totalTestsToShow.length,
    20,
    250
  );

  useEffect(() => {
    props.renderingProgressed && props.renderingProgressed();
  }, [props, maxTestsShown]);

  const testsToShow = totalTestsToShow.slice(0, maxTestsShown);

  useEffect(() => {
    return function cleanup() {
      clearTimeoutForCategoriesRender();
    };
  }, [clearTimeoutForCategoriesRender]);

  function handleTestUpdate(test: ExtendedTest) {
    props.clearSubmitError();
    chooseTestsFormDispatch({ type: "TEST_UPDATE", payload: { test } });
  }

  function handleThresholdChange(
    test: ExtendedTest,
    input: {
      threshold: Threshold;
      severity: Severity;
      expanded: boolean;
    }
  ) {
    const testToUpdate = getNewTestFromInput(test, input);
    const testToCompare = getTestsByTemplateId(props.categories, test.data.reportTemplate.code);

    if (isThresholdAndSeverityDifferent(testToCompare, testToUpdate)) {
      handleTestUpdate(testToUpdate);
    }
  }

  function handleEnableJiraChange(
    test: ExtendedTest,
    input: {
      createJiraTicketOnFailure: boolean | null;
      jiraTicketCustomNote: string | null;
    }
  ) {
    const testToUpdate: ExtendedTest = {
      ...test,
      data: {
        ...test.data,
        createJiraTicketOnFailure: Boolean(input.createJiraTicketOnFailure),
        jiraTicketCustomNote: input.jiraTicketCustomNote
      }
    };

    handleTestUpdate(testToUpdate);
  }

  function handleSmartThresholdChange(test: ExtendedTest, isSmartThresholdEnabled: boolean) {
    const updatedTest: ExtendedTest = {
      ...test,
      data: {
        ...test.data,
        automaticThresholdEnabled: isSmartThresholdEnabled
      }
    };
    handleTestUpdate(updatedTest);
  }

  const showLoader = totalTestsToShow.length !== 0 && maxTestsShown < totalTestsToShow.length;

  return (
    <Box mb={5}>
      {showLoader && (
        <Box>
          <Box mb={1}>{`Loading tests: ${maxTestsShown} out of ${totalTestsToShow.length}.`}</Box>
          <Box mb={1}>
            <BufferedLinearProgress
              completed={maxTestsShown}
              totalCount={totalTestsToShow.length}
              buffered={totalTestsToShow.length - maxTestsShown}
            />
          </Box>
        </Box>
      )}

      {areAnyTestsSelected(props.categories) ? (
        testsToShow.map(test => (
          <Box
            key={test.data.reportTemplate.code + "-collapsable"}
            mb={2}
            data-testid={test.data.reportTemplate.code + "-collapsable"}
          >
            <TestCollapse
              isJiraIntegrationConnected={props.isJiraIntegrationConnected}
              test={test}
              onChange={input => {
                handleThresholdChange(test, input);
              }}
              handleEnableJiraChange={input => {
                handleEnableJiraChange(test, input);
              }}
              showSmartThresholdSettings={
                !props.smartThresholdSettings.enabledForAllTests &&
                props.smartThresholdSettings.isEnabled &&
                permissions.automaticThresholdAccess
              }
              handleSmartThresholdChange={handleSmartThresholdChange}
            />
          </Box>
        ))
      ) : (
        <Alert
          severity="info"
          data-cy={props.noItemsMessageId || "no-selected-tests-label"}
          data-testid="no-selected-tests-label"
        >
          {props.noItemsMessage ??
            "You have no other tests selected. If you want to add other tests, please select it from the list of tests on the left."}
        </Alert>
      )}
    </Box>
  );
}
