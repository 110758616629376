import { getIdFromEncodedString } from "../getIdFromEncodedString/getIdFromEncodedString";
import { WindowWithPendo } from "./pendo.types";

export function initializePendo(encodedUserId: string, encodedAccountId: string): void {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const userId = getIdFromEncodedString(encodedUserId, "User");
  const accountId = getIdFromEncodedString(encodedAccountId, "Account");

  (window as WindowWithPendo).pendo?.initialize({
    visitor: { id: userId },
    account: { id: accountId }
  });
}
