import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "../../../../../_common/components/FileUploader/FileUploader";
import { useDeleteFileUploadMutation } from "./graphql/useDeleteFileUploadMutation";
import { useCreateFileUploadMutation } from "./graphql/useCreateFileUploadMutation";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { getTestSuiteFileUploads_node_TestSuite } from "../../../../../generated-graphql-interfaces";
import { useSnackbar } from "notistack";
import { useTestSuiteFileUploadsQuery } from "../../../graphql/useTestSuiteQueryFileUploads";

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

export interface UrlListUploaderProps {
  testSuiteId: string;
}

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
export function UrlListUploader(props: UrlListUploaderProps) {
  const [deleteFile, { error: deleteError }] = useDeleteFileUploadMutation();
  const [createFile, { error: createError }] = useCreateFileUploadMutation();
  const { loading, error, data, refetch } = useTestSuiteFileUploadsQuery({ id: props.testSuiteId });
  const urlFileUploadNodes = (data?.node as getTestSuiteFileUploads_node_TestSuite)?.urlFileUploads.nodes || [];
  const refFiles = useRef((data?.node as getTestSuiteFileUploads_node_TestSuite)?.urlFileUploads.nodes || []);
  // eslint-disable-next-line
  refFiles.current = (data?.node as getTestSuiteFileUploads_node_TestSuite)?.urlFileUploads.nodes || [];
  const [processingFiles, setIsProcessingFiles] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<number | undefined>();
  const [totalFilesInProcess, setTotalFilesInProcess] = useState<number | undefined>();
  const [totalFilesToUpload, setTotalFilesToUpload] = useState<number | undefined>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function handleFileUpload(file: File) {
    return createFile({
      variables: {
        testSuiteId: props.testSuiteId,
        file: file
      }
    });
  }

  function processFilesX(addedFiles: File[], position: number, resolveSoFar: boolean): Promise<boolean> {
    if (addedFiles.length === position) {
      return new Promise((resolve, reject) => (resolveSoFar ? resolve(resolveSoFar) : reject(resolveSoFar)));
    }
    setTotalFilesInProcess(1);
    return handleFileUpload(addedFiles[position]).then(
      res => {
        setTotalFilesInProcess(0);
        const newPostion = position + 1;
        setUploadedFiles(position);
        return processFilesX(addedFiles, newPostion, true && resolveSoFar);
      },
      error => {
        setTotalFilesInProcess(0);
        const newPostion = position + 1;
        setUploadedFiles(position);
        return processFilesX(addedFiles, newPostion, false);
      }
    );
  }

  async function onFilesAdded(addedFiles: File[]) {
    setIsProcessingFiles(true);
    setTotalFilesToUpload(addedFiles.length);
    setTotalFilesInProcess(0);
    setUploadedFiles(0);
    const multipleFiles = addedFiles.length > 1;
    processFilesX(addedFiles, 0, true)
      .then(() => {
        enqueueSnackbar(`${multipleFiles ? "Files" : "File"} uploaded successfully`, { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar(`Error during uploading ${multipleFiles ? "files" : "file"}`, { variant: "error" });
      })
      .finally(() => {
        refetch();
        setIsProcessingFiles(false);
        setTotalFilesToUpload(undefined);
        setUploadedFiles(undefined);
      });
  }

  function onFileDeleted(fileId: string) {
    setIsProcessingFiles(true);
    deleteFile({
      variables: {
        fileUploadId: fileId
      }
    })
      .then(
        () => {
          enqueueSnackbar("File deleted successfully", { variant: "success" });
        },
        error => {
          enqueueSnackbar("Unable to delete file", { variant: "error" });
        }
      )
      .finally(() => {
        refetch();
        setIsProcessingFiles(false);
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (refFiles.current.length > 0) {
        const needsRefetch = refFiles.current.map(node => node.totalRows === null).reduce((last, now) => last || now);
        if (needsRefetch) {
          refetch();
        }
      }
    }, 3000);

    const clear = () => {
      interval && clearInterval(interval);
    };

    return clear;
  }, [data, refetch]);

  return (
    <div data-testid="url-list-uploader">
      {error && (
        <Alert className={classes.alert} severity="error">
          {error.message}
        </Alert>
      )}
      {deleteError && (
        <Alert className={classes.alert} severity="error">
          {deleteError.message}
        </Alert>
      )}
      {createError && (
        <Alert className={classes.alert} severity="error">
          {createError.message}
        </Alert>
      )}
      <FileUploader
        files={urlFileUploadNodes}
        onFilesAdded={onFilesAdded}
        onFileDeleted={onFileDeleted}
        processing={processingFiles || loading}
        filesBeeingUploaded={totalFilesInProcess}
        filesToUpload={totalFilesToUpload}
        filesUploaded={uploadedFiles}
      />
    </div>
  );
}
