import React, { useContext } from "react";
import { Grid, Box, Typography, Divider } from "@material-ui/core";
import { getAppliedFiltersCount, getTotalShowingTestsCount } from "./ChooseTestsFormCountersUtils";
import useStyles from "./ChooseTestsFormCountersStyles";
import { getSelectedTests } from "../CreateTestsCommonUtils";
import { ChooseTestsState } from "../chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";

export interface ChooseTestsFormCountersProps {
  chooseTestsFormState: ChooseTestsState;
}

export function ChooseTestsFormCounters(props: ChooseTestsFormCountersProps) {
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.filtersAppliedBox} mt={2}>
        <Grid container className={classes.filtersBox}>
          <Grid item data-testid={"filters-applied"}>
            <Typography color="textPrimary" className={classes.filtersCount}>
              {getAppliedFiltersCount(props.chooseTestsFormState.filter, props.chooseTestsFormState.categories)} filters
              applied
            </Typography>
          </Grid>
          <Grid item className={classes.resetFilter}>
            <a
              onClick={() => {
                chooseTestsFormDispatch({ type: "RESET", payload: {} });
              }}
              data-testid="reset-filter"
              data-pendo="auto-test-suite-edit-choose-tests-filter-reset"
            >
              Reset filters
            </a>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.availableTestsBox} data-testid={"tests-available"}>
        <Typography color="textPrimary" className={classes.availableTestsCount} data-cy="available-tests-count">
          {getTotalShowingTestsCount(props.chooseTestsFormState.filter, props.chooseTestsFormState.categories)} tests
          available
        </Typography>
      </Box>
      <Box className={classes.selectedTestBox} data-testid={"tests-selected"}>
        <Divider />
        <Typography color="textPrimary" className={classes.selectedCount} data-testid="tests-selected-count">
          {getSelectedTests(props.chooseTestsFormState.categories).length} tests selected
        </Typography>
        <Divider />
      </Box>
    </>
  );
}
