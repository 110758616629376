import { TestSuiteShowType } from "./FailPassWarnChartFilterSwitches";
import { FailPassWarnChartFilterSettings } from "./FailPassWarnChartFilter";
export function shouldUpdateVisibility(
  selectedId: TestSuiteShowType,
  checked: boolean,
  settings: FailPassWarnChartFilterSettings
) {
  const listOfId = [TestSuiteShowType.FAIL, TestSuiteShowType.PASS, TestSuiteShowType.WARN];
  const mapIdToShow = {
    [TestSuiteShowType.FAIL]: settings.show.fails,
    [TestSuiteShowType.PASS]: settings.show.passes,
    [TestSuiteShowType.WARN]: settings.show.warnings
  };
  const filtredlistHasEnabledItem = listOfId
    .filter(id => id !== selectedId)
    .map(id => mapIdToShow[id])
    .reduce((prev, now) => prev || now);

  return (!checked && filtredlistHasEnabledItem) || checked;
}
