import { PaperClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";
import { shadowOptions } from "../shadows";

export const muiPaper: Partial<
  Record<PaperClassKey, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
> = {
  elevation1: {
    boxShadow: shadowOptions[1]
  }
};
