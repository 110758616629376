import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    availableTestsBox: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    availableTestsCount: {
      fontSize: 22,
      fontWeight: 500
    },
    availableTestsText: {
      fontSize: "20px"
    },
    filtersAppliedBox: {
      marginBottom: theme.spacing(5)
    },
    filtersBox: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    resetFilter: {
      fontSize: 15,
      cursor: "pointer",
      textDecoration: "underline"
    },
    filtersCount: {
      fontSize: 15,
      color: theme.palette.navy[300]
    },
    selectedCount: {
      fontWeight: 500,
      fontSize: 16,
      margin: theme.spacing(1, 0)
    },
    selectedTestBox: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default useStyles;
