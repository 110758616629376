export interface ServerError {
  message: string;
  statusCode?: number;
}
type Errors = ServerError[];
type callback = (errors: Errors) => void;
// TODO: get rid of mutability
// eslint-disable-next-line fp/no-let
let callbacks: callback[] = [];

export const errorsSubscription = {
  sendErrors: (errors: Errors): void => {
    callbacks.forEach(callback => callback(errors));
  },
  clearErrors: (): void => {
    callbacks.forEach(callback => callback([]));
  },
  registerCallback(callback: callback): void {
    // eslint-disable-next-line fp/no-mutation
    callbacks = [...callbacks, callback];
  },
  clearCallbacks() {
    // eslint-disable-next-line fp/no-mutation
    callbacks = [];
  }
};
