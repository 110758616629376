import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useStyles = makeStyles(theme => ({
  listPaper: {
    minHeight: "688px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down("md")]: {
      minHeight: "0px",
      overflow: "hidden",
      paddingBottom: theme.spacing(2)
    }
  },
  text: {
    fontWeight: 300,
    color: theme.palette.navy[300],
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "3px",
    display: "inline"
  },
  darkerText: {
    color: theme.palette.navy[500],
    marginLeft: theme.spacing(2)
  },
  tableHeadings: {
    color: theme.palette.navy[300],
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px"
  },
  tableHeadingsBox: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 3.5, 0, 3.5),
    textAlign: "left",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 3, 0, 3)
    }
  },
  margin: {
    margin: theme.spacing(3)
  },
  imgWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    margin: theme.spacing(8, 3.5, 5, 3.5),
    padding: theme.spacing(4, 5, 5, 5),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3),
      padding: theme.spacing(3)
    }
  },
  img: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  noTestSuitesText: {
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.navy[300]
  },
  icon: {
    marginLeft: theme.spacing(1)
  },
  deleteButton: {
    "&:hover": {
      "& svg": {
        color: theme.palette.error.main
      }
    }
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  divider: {
    margin: theme.spacing(0, 3.5, 0, 3.5),
    background: theme.palette.grey[200]
  },
  testSuitesBox: {
    margin: theme.spacing(0, 3.5, 0, 3.5)
  },
  testSuiteBoxTableHeaderBox: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  timesBox: {
    margin: theme.spacing(1.5, 3.5)
  },
  timeBoxInfoBlock: {
    marginRight: theme.spacing(8),
    display: "inline-block"
  }
}));
