import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { updateEmailAlert, updateEmailAlertVariables } from "../../../../../../generated-graphql-interfaces";

export function useUpdateEmailAlertMutation() {
  return useMutation<updateEmailAlert, updateEmailAlertVariables>(gql`
    mutation updateEmailAlert($id: ObjectID!, $alertTypes: [EmailAlertType!]) {
      updateTestSuiteEmailAlert(input: { testSuiteEmailAlertId: $id, alertTypes: $alertTypes }) {
        testSuiteEmailAlert {
          id
          email
          alertTypes
        }
      }
    }
  `);
}
