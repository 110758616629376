import { ValidationErrors } from "../../../../validation/ValidationErrors";
import * as yup from "yup";

export const ipRegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const connectSiteFormValidation = yup.object().shape({
  name: yup.string().required(ValidationErrors.TestSuiteName).max(255, ValidationErrors.TestSuiteNameMaxLength),
  sitePrimary: yup
    .string()
    .url(ValidationErrors.HttpProtocol)
    .matches(/^http(s)?:\/\/.*/, ValidationErrors.HttpProtocol)
    .test("test-name", ValidationErrors.SitePrimary, value => {
      try {
        const { pathname } = new URL(value as string);
        return pathname.length <= 1;
      } catch (e) {
        return false;
      }
    })
    .required(ValidationErrors.SitePrimary),
  siteTestUser: yup.string().when("siteTestPass", {
    is: value => !!value,
    then: yup.string().required(ValidationErrors.SiteTestUser),
    otherwise: yup.string().notRequired()
  }),
  siteTestPass: yup.string().notRequired(),
  location: yup.string().required(),
  customHeaderUserAgent: yup.string().notRequired(),
  customHeaderUserAgentShort: yup.string().notRequired(),
  customDns: yup.array().of(
    yup.object().shape({
      hostname: yup.string().required(ValidationErrors.Hostname),
      ipAddress: yup.string().required(ValidationErrors.IpAddress).matches(ipRegEx, ValidationErrors.IpAddressValidity)
    })
  )
});
