import { useMutation } from "@apollo/react-hooks";
import {
  linkChildTestSuiteToParentTestSuite,
  linkChildTestSuiteToParentTestSuiteVariables
} from "../../../../../generated-graphql-interfaces";
import { linkChildTestSuiteToParentTestSuiteGql } from "./linkChildToParentMutationGql";

export function useLinkChildToParentMutation() {
  return useMutation<linkChildTestSuiteToParentTestSuite, linkChildTestSuiteToParentTestSuiteVariables>(
    linkChildTestSuiteToParentTestSuiteGql,
    { refetchQueries: ["getTestSuite", "getTestSuiteChildren"] }
  );
}
