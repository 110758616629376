import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getJiraIssueTypesVariables, getJiraIssueTypes } from "../../../../../generated-graphql-interfaces";

export const getJiraIssueTypesGql = gql`
  query getJiraIssueTypes($jiraAuthenticationId: ObjectID!, $jiraProjectId: String!) {
    getJiraIssueTypes(input: { jiraAuthenticationId: $jiraAuthenticationId, jiraProjectId: $jiraProjectId }) {
      id
      name
    }
  }
`;

export function useGetJiraIssueTypesQuery(variables: getJiraIssueTypesVariables) {
  return useQuery<getJiraIssueTypes>(getJiraIssueTypesGql, { variables, fetchPolicy: "cache-and-network" });
}
