import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { TestCategory, ExtendedTest, CategoriesCode } from "../CreateTests.interfaces";
import ChooseTestsSelectionCheckBox from "./ChooseTestsSelectionCheckBox";

interface CategoryTestsListProps {
  category: TestCategory;
  tests: ExtendedTest[];
  onTestSelectionChange: (test: ExtendedTest, selection: boolean) => void;
  maxLimitOfSelectedTestsReached: boolean;
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: 16,
    margin: theme.spacing(1, 0)
  }
}));

export function CategoryTestsList(props: CategoryTestsListProps) {
  const classes = useStyles();
  const { category, tests } = props;
  const categoryCode = category.code === CategoriesCode.CUSTOM_EXTRACTION ? "custom-extractions" : category.code;

  return tests.length > 0 ? (
    <Box
      key={category.code}
      mt={2}
      data-testid={`${categoryCode}-category-box`}
      data-cy={`${categoryCode}-category-box`}
    >
      <Divider />
      <Typography color="textPrimary" className={classes.title}>
        {category.name}
      </Typography>
      <Divider />
      <Box mt={2}>
        {tests.map(test => (
          <ChooseTestsSelectionCheckBox
            disabled={props.maxLimitOfSelectedTestsReached}
            key={test.data.reportTemplate.code + "-checkbox" + test.data.reportTemplate.name}
            test={test}
            onChange={selected => props.onTestSelectionChange(test, selected)}
          />
        ))}
      </Box>
    </Box>
  ) : null;
}
