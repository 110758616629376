import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  accordionTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  accordionRoot: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.08)",
    borderRadius: "6px !important",
    "&::before": {
      background: "none"
    }
  },
  accordionExpanded: {
    margin: "0px !important"
  },
  instructionsTitle: {
    fontWeight: 500
  },
  showInstructions: {
    fontWeight: 500,
    color: "#005AFF"
  },
  accordionSummaryRoot: {
    padding: theme.spacing(0, 3),
    minHeight: "0px !important"
  },
  accordionSummaryExpanded: {
    "& .MuiAccordionSummary-content": {
      margin: "12px 0px"
    }
  },
  accordionDetails: {
    padding: theme.spacing(2, 3, 3, 3)
  },
  bold: {
    fontWeight: 600
  }
}));

export interface HidableBlockProps {
  children: JSX.Element;
  title: string;
  descriptor: string;
  dataIdDescriptor: string;
}

export function HidableBlock(props: HidableBlockProps): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  function handleChange() {
    setIsExpanded(current => !current);
  }

  return (
    <>
      <Accordion
        classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded }}
        data-testid={`${props.dataIdDescriptor}-accordion`}
        data-pendo={`auto-${props.dataIdDescriptor}`}
        expanded={isExpanded}
        onChange={handleChange}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-testid={`${props.dataIdDescriptor}-accordion-summary`}
          data-pendo={`auto-${props.dataIdDescriptor}-accordion`}
          classes={{ root: classes.accordionSummaryRoot, expanded: classes.accordionSummaryExpanded }}
        >
          <Box className={classes.accordionTitleWrapper}>
            <Typography className={classes.instructionsTitle} color="textPrimary">
              {props.title}
            </Typography>
            <Typography className={classes.showInstructions}>
              {isExpanded ? `Hide ${props.descriptor}` : `Show ${props.descriptor}`}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>{props.children}</AccordionDetails>
      </Accordion>
    </>
  );
}
