import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, CircularProgress, Divider, makeStyles, Theme } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import {
  FormHelperTextStyled,
  FormHelperTextStyledType
} from "../../../_common/components/FormHelperTextStyled/FormHelperTextStyled";
import { emailValidationSchema, postForgetPasswordRequest } from "./ForgottenPassword.utils";
import { Alert } from "@material-ui/lab";
import { CancelButton } from "./../../../_common/components/CancelButton/CancelButton";

export const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2, 3),
    background: "rgba(0, 0, 0, 0.1)"
  }
}));

export interface ForgottenPasswordEmailDialogProps {
  open: boolean;
  resetPasswordSucceded: () => void;
  onClose: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function ForgottenPasswordEmailDialog(props: ForgottenPasswordEmailDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (props.open) {
      setError(false);
    }
  }, [props.open]);

  const handleSendEmail = async (values: { email: string }) => {
    setIsLoading(true);
    try {
      await postForgetPasswordRequest(values.email);
      setError(false);
      props.resetPasswordSucceded();
      setShouldValidateOnChange(false);
    } catch (e) {
      setError(true);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setShouldValidateOnChange(false);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={closeModal} data-testid="forgot-password-dialog">
      <DialogTitle data-testid="forgot-password-dialog-title">Forgot your password?</DialogTitle>
      <Divider />
      <Formik
        enableReinitialize={false}
        validateOnBlur={true}
        validateOnChange={shouldValidateOnChange}
        initialValues={{
          email: ""
        }}
        onSubmit={handleSendEmail}
        validationSchema={emailValidationSchema}
      >
        {({ errors, handleChange }) => (
          <Form>
            <DialogContent>
              <DialogContentText data-testid="forgot-password-dialog-content">
                Don&apos;t worry, it happens to the best of us. Enter your email below to get a password reset link.
              </DialogContentText>
              <Field
                data-testid="forgot-password-dialog-email-input"
                autoFocus
                name="email"
                placeholder="user@deepcrawl.com"
                label="Enter your email address"
                component={TextField}
                fullWidth={true}
                onChange={handleChange}
                variant="filled"
                id="email"
                type="text"
                error={!!errors.email}
                message={errors.email}
              />
              <FormHelperTextStyled
                testId="forgot-password-dialog-email-error"
                id="forgot-password-dialog-email-error"
                text={errors?.email}
                show={!!errors.email}
                type={FormHelperTextStyledType.ERROR}
              />
              {error && (
                <Box mt={3}>
                  <Alert severity="error" data-testid="forgot-password-dialog-email-error-response">
                    We are unable to send email due to an unknown error. Please try again. If the problem persists,{" "}
                    <a href="mailto:support@deepcrawl.com">contact our support team</a>.
                  </Alert>
                </Box>
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <CancelButton
                onClick={closeModal}
                type="button"
                disabled={isLoading}
                data-testid="forgot-password-dialog-cancel"
              >
                Cancel
              </CancelButton>
              <Button
                data-testid="forgot-password-dialog-send"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => {
                  setShouldValidateOnChange(true);
                }}
              >
                {isLoading ? <CircularProgress size={20} /> : "Send"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
