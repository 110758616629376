/* eslint-disable max-lines */
import { Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import React, { useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, Grid, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getTestSuite_node_TestSuite,
  TestSuiteLocationCode,
  LocationCode,
  getTestSuiteVariables,
  getTestSuite
} from "../../../../generated-graphql-interfaces";
import { useLocationsQuery } from "./graphql/useLocationsQuery";
import { TestSuiteVariables } from "./TestSuiteVariables";
import { CollapseHeader } from "../CollapseHeader";
import { ErrorEffect, ConnectSiteFormValues } from "./ErrorEffect";
import { CustomDnsPairs } from "./CustomDnsPairs";
import { ValidationErrors } from "../../../../validation/ValidationErrors";
import { getDuplicateCustomDnsFields } from "./utils/getDuplicateCustomDnsFields";
import { connectSiteTooltips } from "./connectSiteTooltips";
import { sortLocationData } from "./utils/sortLocationData";
import { removeTypenameFromDnsSettings } from "./utils/removeTypenameFromDnsSettings";
import { getInitialValues } from "./ConnectSiteFormUtils";
import { connectSiteFormValidation } from "./connectSiteFormValidation";
import { AuthenticationSettings } from "./components/AuthenticationSettings";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { useSnackbar } from "notistack";
import { FloatingStepSave } from "../FloatingStepSave/FoatingStepSave";
import { useSaveChangesContext } from "../../SaveChangesProvider";
import { ApolloQueryResult } from "apollo-client";

export interface ConnectSiteFormProps {
  onSubmit: (testSuiteVariables: TestSuiteVariables) => Promise<void>;
  onSuccess?: () => void;
  testSuite?: getTestSuite_node_TestSuite;
  refetchTestSuite?: (variables?: getTestSuiteVariables | undefined) => Promise<ApolloQueryResult<getTestSuite>>;
}

export interface CustomDnsPair {
  hostname: string;
  ipAddress: string;
  __typename?: string;
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginRight: theme.spacing(1)
  },
  collapseButton: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  mainSettingsContainer: {
    marginBottom: theme.spacing(7)
  },
  contactLink: {
    color: theme.palette.text.secondary
  },
  padding: {
    padding: theme.spacing(3, 3.5, 3, 3.5)
  }
}));

// This is required for populating the `siteTestPass` field when the password is set.
// We don't want to populate the field with the actual password.
export const dummyAuthPassword = "fakepassword";

// eslint-disable-next-line max-lines-per-function, max-statements
export function ConnectSiteForm(props: ConnectSiteFormProps): JSX.Element {
  const { testSuite, onSubmit } = props;
  const { loading: isLocationsLoading, data: locationData } = useLocationsQuery();
  const classes = useStyles();
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef<FormikProps<ConnectSiteFormValues>>(null);
  const saveChangesContext = useSaveChangesContext();

  const isAuthPasswordInitiallySet = testSuite ? testSuite.hasSiteTestPass : false;
  const [authPassword, setAuthPassword] = useState({
    hasBeenChanged: false,
    value: isAuthPasswordInitiallySet ? dummyAuthPassword : ""
  });
  const { hasBeenChanged: hasAuthPasswordBeenChanged, value: authPasswordValue } = authPassword;

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const initialValues = getInitialValues(testSuite, isAuthPasswordInitiallySet);

  function handleSaveAuthPassword(newPassword: string) {
    setAuthPassword(current => ({ ...current, value: newPassword, hasBeenChanged: true }));
  }

  function toggleAuthPasswordWarning(isVisible: boolean) {
    setIsWarningVisible(isVisible);
  }

  function resetAuthPasswordState() {
    toggleAuthPasswordWarning(false);
    setAuthPassword(s => ({ ...s, hasBeenChanged: false }));
  }

  function handleAuthPasswordSnackbar() {
    if (isAuthPasswordInitiallySet && authPassword.hasBeenChanged) {
      enqueueSnackbar("Password changed successfully", { variant: "success" });
    }
  }

  const saveChanges = async () => {
    if (formikRef.current) {
      const { values } = formikRef.current;
      const customDnsSettings = removeTypenameFromDnsSettings(values.customDns || []);

      await onSubmit({
        ...values,
        location: values.location as TestSuiteLocationCode,
        customDns: customDnsSettings,
        siteTestPass: hasAuthPasswordBeenChanged ? authPasswordValue : null
      });
    }
  };

  useEffect(() => {
    saveChangesContext.registerCallback(saveChanges, "step1");
    // Note: we only need to subscribe the function if the auth password state has been changed.
    // This is so that the function is always called with the most up to date auth password value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPasswordValue, hasAuthPasswordBeenChanged]);

  async function handleSubmit(values: ConnectSiteFormValues, actions: FormikHelpers<ConnectSiteFormValues>) {
    const customDnsSettings = removeTypenameFromDnsSettings(values.customDns || []);
    const fieldsToValidate = getDuplicateCustomDnsFields(customDnsSettings);

    if (fieldsToValidate.length > 0) {
      fieldsToValidate.forEach(field => {
        actions.setFieldError(field, ValidationErrors.CustomDnsDuplication);
      });
      actions.setSubmitting(false);
      return;
    }

    await props.onSubmit({
      ...values,
      location: values.location as TestSuiteLocationCode,
      customDns: customDnsSettings,
      siteTestPass: authPassword.hasBeenChanged ? authPassword.value : null
    });
    props.refetchTestSuite?.();
    actions.setSubmitting(false);
    resetAuthPasswordState();
    handleAuthPasswordSnackbar();

    if (props.onSuccess) {
      props.onSuccess();
    }
  }

  const sortedLoactionData = locationData ? sortLocationData(locationData.getTestSuiteLocations) : [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={connectSiteFormValidation}
      innerRef={formikRef}
    >
      {
        //eslint-disable-next-line max-lines-per-function
        formik => (
          <Form>
            <Box className={classes.padding}>
              <ErrorEffect
                formik={formik}
                onSubmissionError={() => {
                  setIsCollapseOpen(true);
                }}
              />
              <Grid container className={classes.mainSettingsContainer}>
                <Grid item xs={12}>
                  <Box component="div" mb={5}>
                    <Typography variant="body1">
                      Configure the website that will be crawled during the test, along with any necessary
                      authentication settings.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" display="flex" alignItems="center" mb={4}>
                    <b className={classes.sectionTitle}>Name &amp; Domain</b>
                    <DeepcrawlInfoTooltip
                      maxWidth={400}
                      body={connectSiteTooltips.nameAndDomain}
                      data-pendo="auto-tooltip-connect-site-form"
                    />
                  </Box>
                  <Box component="div" mb={5}>
                    <Field
                      data-testid="connect-site-form-name"
                      data-pendo="auto-edit-add-test-suite-name"
                      id="connect-site-form-name"
                      name="name"
                      type="text"
                      label="Test Suite Name"
                      component={TextField}
                      fullWidth={true}
                      variant="filled"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    data-testid="connect-site-form-domain"
                    data-pendo="auto-edit-add-test-suite-domain"
                    id="connect-site-form-domain"
                    name="sitePrimary"
                    type="text"
                    placeholder="https://example.com"
                    label="Domain/subdomain"
                    component={TextField}
                    fullWidth={true}
                    variant="filled"
                  />
                </Grid>
              </Grid>
              <CollapseHeader
                name="Authentication and Whitelisting"
                isOpen={isCollapseOpen}
                pendoId="auto-edit-add-test-suite-step1-authentication-whitelisting"
                testId="connect-site-form-auth-and-whitelist-container"
                onClick={() => {
                  setIsCollapseOpen(!isCollapseOpen);
                }}
              >
                <AuthenticationSettings
                  isAuthPasswordInitiallySet={isAuthPasswordInitiallySet}
                  handleSaveAuthPassword={handleSaveAuthPassword}
                  authPasswordValue={authPassword.value}
                  hasAuthPasswordChanged={authPassword.hasBeenChanged}
                  isWarningVisible={isWarningVisible}
                  toggleWarning={toggleAuthPasswordWarning}
                />
                <Box component="div" mb={6} data-cy="connect-site-form-whitelist-box">
                  <Box component="div" display="flex" alignItems="center" mb={4} mt={7}>
                    <b className={classes.sectionTitle}>Whitelisting (optional)</b>
                    <DeepcrawlInfoTooltip
                      maxWidth={400}
                      body={connectSiteTooltips.whitelisting}
                      data-pendo="auto-tooltip-whitelisting"
                    />
                  </Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box component="div" mb={7}>
                        {!isLocationsLoading && locationData ? (
                          <Field name="location" label="Whitelisting Settings" component={RadioGroup} variant="filled">
                            {sortedLoactionData.map(location => (
                              <Box key={location.code} display="flex" alignItems="center">
                                <FormControlLabel
                                  control={<Radio />}
                                  id={`location-radio-${location.code}`}
                                  data-testid={`location-radio-${location.code}`}
                                  data-pendo={`auto-edit-add-test-suite-whitelisting-${location.code}`}
                                  key={location.code}
                                  value={location.code}
                                  label={location.name}
                                />
                                {location.code === LocationCode.Custom ? (
                                  <a href="https://app.deepcrawl.com/account" target="_blank" rel="noopener noreferrer">
                                    View details
                                  </a>
                                ) : null}
                              </Box>
                            ))}
                          </Field>
                        ) : (
                          <span>Location data did not load</span>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="div" mb={5}>
                        <Field
                          name="customHeaderUserAgent"
                          id="connect-site-form-custom-user-agent-full"
                          data-testid="connect-site-form-custom-user-agent-full"
                          data-pendo="auto-edit-add-test-suite-whitelisting-crawler-user-agent"
                          label="Crawler User Agent"
                          component={TextField}
                          fullWidth={true}
                          variant="filled"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="customHeaderUserAgentShort"
                        id="connect-site-form-custom-user-agent-short"
                        data-testid="connect-site-form-custom-user-agent-short"
                        data-pendo="auto-edit-add-test-suite-whitelisting-user-agent-robots"
                        label="User Agent for Robots.txt Rule Matching"
                        component={TextField}
                        fullWidth={true}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                  <Box component="div" mb={6}>
                    <Box component="div" display="flex" alignItems="center" mb={4} mt={7}>
                      <b className={classes.sectionTitle}>Custom DNS (optional)</b>
                      <DeepcrawlInfoTooltip
                        maxWidth={400}
                        body={connectSiteTooltips.customDns}
                        data-pendo="auto-tooltip-custom-dns"
                      />
                    </Box>
                    <Grid container>
                      <Grid container item xs={10} spacing={2}>
                        <FieldArray
                          name="customDns"
                          // eslint-disable-next-line
                          component={CustomDnsPairs as any}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CollapseHeader>
            </Box>
            <FloatingStepSave
              label="Save"
              dataTestId="connect-site-form-save"
              dataCy="connect-site-form-save"
              data-pendo="auto-testsuite-edit-add-connect-site-save-step"
            />
          </Form>
        )
      }
    </Formik>
  );
}
