import { TemplateSettingsProps } from "./TemplateSettings";
import { makeStyles } from "@material-ui/core";

export const useTemplateSettingsStyles = makeStyles(theme => ({
  title: (props: TemplateSettingsProps) => {
    return {
      fontSize: 20,
      color:
        !!props.parent || !!props.childrenTestSuites?.edges.length
          ? theme.palette.primary.contrastText
          : theme.palette.navy[500],
      marginRight: theme.spacing(1),
      fontWeight: 400
    };
  },
  header: (props: TemplateSettingsProps) => {
    const noParentBackgroundColor = !!props.childrenTestSuites?.edges.length ? theme.palette.purple[300] : "white";
    return {
      backgroundColor: !!props.parent ? theme.palette.navy[400] : noParentBackgroundColor,
      "& svg": {
        color:
          !!props.parent || !!props.childrenTestSuites?.edges.length
            ? theme.palette.primary.contrastText
            : theme.palette.navy[500]
      },
      borderRadius: 3,
      padding: "2px 28px",
      minHeight: "0px !important"
    };
  },
  icon: (props: TemplateSettingsProps) => ({
    color: props.parent || Boolean(props.childrenTestSuites?.edges.length) ? "white" : "#DADADA",
    fontSize: "1.1rem",
    padding: 10,
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.navy[500]
    }
  }),
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 3
  },
  tooltipTitle: {
    color: "#202020",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  tooltipText: {
    color: "#202020",
    fontSize: "0.875rem"
  },
  tooltip: {
    backgroundColor: "white",
    border: `1px ${theme.palette.grey[50]}`,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2),
    maxWidth: 400
  },
  error: {
    marginBottom: theme.spacing(2)
  },
  condensedText: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 500
  },
  textBlock: {
    marginBottom: theme.spacing(2)
  },
  content: { padding: theme.spacing(3, 3.5) },
  contentWrapper: {
    width: "100%"
  },
  accordionSummaryExpanded: {
    "& .MuiAccordionSummary-content": {
      margin: "12px 0px"
    }
  }
}));
