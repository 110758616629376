import React from "react";
import { Box, InputLabel, FormControlLabel, Radio } from "@material-ui/core";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";

export function EnableJsRendering() {
  const classes = useCrawlSettingsStyles();

  return (
    <Box mb={7}>
      <InputLabel htmlFor="js-rendering" className={`${classes.inputLabel} ${classes.labelTitle}`}>
        JavaScript Rendering
      </InputLabel>
      <Field name="useRenderer" id="js-rendering" label="JS Rendering" component={RadioGroup}>
        <FormControlLabel
          control={<Radio />}
          value="false"
          label="Not required"
          key="not-required"
          data-cy="js-not-required-radio"
        />
        <FormControlLabel
          control={<Radio />}
          value="true"
          label="Enable JavaScript Rendering"
          key="enable-js-rendering"
          data-cy="enable-js-rendering-radio"
        />
      </Field>
    </Box>
  );
}
