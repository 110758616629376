import { makeStyles, Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import noSchedulesGraphic from "../../../images/no-schedules-empty-state.svg";
import { PrimaryActionButton } from "../../../_common/components/PrimaryActionButton/PrimaryActionButton";

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: `1px dashed ${theme.palette.grey[400]}`,
    maxWidth: 880,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 7, 7, 7),
    marginTop: theme.spacing(14),
    marginLeft: "auto",
    marginRight: "auto"
  },
  image: {
    maxWidth: 290,
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: "17px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    maxWidth: 500,
    lineHeight: "26px",
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  bodyText: {
    fontSize: "0.875rem",
    maxWidth: 500,
    textAlign: "center",
    marginBottom: theme.spacing(4),
    fontWeight: 300
  }
}));

interface NoSchedulesViewProps {
  openScheduleSettings: () => void;
}

export function NoSchedulesView(props: NoSchedulesViewProps) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper} data-testid="no-schedules-view">
      <img
        src={noSchedulesGraphic}
        className={classes.image}
        alt="No schedules available"
        data-testid="no-schedules-graphic"
      />
      <Typography variant="h2" className={classes.title} data-testid="no-schedules-title">
        Sorry, it looks like you don&apos;t have any scheduled test suites yet.
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-testid="no-schedules-body">
        Automatically run a test suite at a specific time, or on a recurring schedule without the need for a CI/CD
        pipeline deployment to trigger the tests.
      </Typography>
      <PrimaryActionButton
        data-pendo="scheduler-empty-add-new"
        label="Add Schedule"
        startIcon={<AddIcon />}
        dataTestId="no-schedules-add-new-schedule"
        dataCy="no-schedules-add-new-schedule"
        onClick={props.openScheduleSettings}
        disableForViewerRole={true}
      />
    </Box>
  );
}
