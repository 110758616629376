import { Routes } from "../../routes/routes";

export function isPageActive(href: string, activePaths: string[]) {
  if (href === Routes.LoginCallback.ROUTE) {
    return false;
  }

  const areAnyMatchingActivePaths =
    !!activePaths && activePaths.filter(path => location.pathname.includes(path)).length > 0;
  return location.pathname === href || areAnyMatchingActivePaths;
}
