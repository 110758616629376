import { useQuery } from "@apollo/react-hooks";
import { getNotLinkedTestSuites, getNotLinkedTestSuitesVariables } from "../../../../../generated-graphql-interfaces";
import { getNotLinkedTestSuitesGql } from "./getNotLinkedTestSuitesQuery";
import { useState } from "react";
import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";
import merge from "deepmerge";

function getPreviousResultAsLastOne(data: getNotLinkedTestSuites) {
  if (data.getAccount?.notLinkedTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.notLinkedTestSuites.pageInfo.hasNextPage = false;
  }
  return data;
}

export function useGetNotLinkedTestSuitesQuery(accountId: string) {
  const { data, loading, error, fetchMore } = useQuery<getNotLinkedTestSuites, getNotLinkedTestSuitesVariables>(
    getNotLinkedTestSuitesGql,
    {
      variables: { accountId, cursor: "" },
      fetchPolicy: "cache-and-network"
    }
  );
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);

  async function fetchMoreResults() {
    if (data && data?.getAccount?.notLinkedTestSuites?.pageInfo.hasNextPage && !loading && !fetchingMore) {
      setFetchingMore(true);
      fetchMore({
        variables: {
          cursor: data?.getAccount?.notLinkedTestSuites?.pageInfo.endCursor || ""
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getAccount?.notLinkedTestSuites?.edges) {
            return merge(previousResult, fetchMoreResult, defaultMergeOptions) as getNotLinkedTestSuites;
          }
          return getPreviousResultAsLastOne(previousResult);
        }
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  fetchMoreResults();

  return {
    data,
    loading: loading || fetchingMore,
    error
  };
}
