import React from "react";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { ValidationErrors } from "../../../../../validation/ValidationErrors";
import { getTestSuite_node_TestSuite_slackWebhooks_nodes } from "../../../../../generated-graphql-interfaces";
import { useSnackbar } from "notistack";
import { useCreateSlackWebhookMutation } from "../graphql/useCreateSlackWebhookMutation";
import { AddInput } from "../../../../../_common/components/AddInput/AddInput";
import { AddInputSkeleton } from "../../../../../_common/components/AddInput/AddInputSkeleton";

const SlackWebhookValidation = yup.object().shape({
  slackWebhook: yup.string().url(ValidationErrors.SlackWebhookUrl)
});

type WebhookValue = { slackWebhook: string };

interface AddSlackWebhookProps {
  slackWebhooks: getTestSuite_node_TestSuite_slackWebhooks_nodes[];
  testSuiteId: string;
  showConfirmationButton: () => void;
  hideConfirmationButton: () => void;
}

export function AddSlackWebhook(props: AddSlackWebhookProps) {
  const [createSlackWebhook, { loading }] = useCreateSlackWebhookMutation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleCreateWebhook(webhookUrl: string) {
    try {
      await createSlackWebhook({
        variables: {
          testSuiteId: props.testSuiteId,
          url: webhookUrl
        }
      });
      enqueueSnackbar("Slack webhook saved successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Something went wrong while trying to save your Slack webhook", { variant: "error" });
    }
  }

  async function handleSubmit(value: WebhookValue, actions: FormikHelpers<WebhookValue>) {
    if (!value.slackWebhook) {
      actions.setSubmitting(false);
      return;
    }
    if (props.slackWebhooks.find(webhook => webhook.url === value.slackWebhook)) {
      actions.setFieldError("slackWebhook", ValidationErrors.SlackWebhookUnique);
      actions.setSubmitting(false);
      return;
    }
    await handleCreateWebhook(value.slackWebhook);
    props.hideConfirmationButton();
  }

  if (loading) {
    return <AddInputSkeleton xs={10} md={8} testId="add-slack-webhook-loading" />;
  }

  return (
    <Formik initialValues={{ slackWebhook: "" }} onSubmit={handleSubmit} validationSchema={SlackWebhookValidation}>
      {subformik => (
        <AddInput
          onFocus={props.showConfirmationButton}
          onBlur={props.hideConfirmationButton}
          // eslint-disable-next-line
          formik={subformik as any}
          id="add-slack-webhook"
          formikLabel="slackWebhook"
          label="Add Webhook"
        />
      )}
    </Formik>
  );
}
