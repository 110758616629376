import React, { useState } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import LinkOffRoundedIcon from "@material-ui/icons/LinkOffRounded";
import { ActionButtonTooltip } from "../../../../../_common/components/ActionButtonTooltip/ActionButtonTooltip";
import clsx from "clsx";
import { getTestSuite_node_TestSuite_parent } from "../../../../../generated-graphql-interfaces";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import PopoverDialog, {
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { Routes } from "../../../../../_common/routes/routes";
import { defaultPopOverId } from "../../../../../_common/constants/popover";

const useStyles = makeStyles(theme => ({
  actionIcon: {
    color: theme.palette.navy[300],
    border: `1px solid ${theme.palette.navy[50]}`,
    borderRadius: "3px",
    padding: "3px",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText
  },
  editIcon: {
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  unlinkIcon: {
    "&:hover": {
      color: theme.palette.error.main
    }
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    backgroundColor: "#F2E0FA",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#DFB3F4",
      cursor: "pointer",
      "& p": {
        textDecoration: "underline"
      }
    }
  }
}));

interface GlobalTemplateAppliedProps {
  parent: getTestSuite_node_TestSuite_parent;
  unlinkGlobalTemplate: () => void;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary"
  },
  {
    label: "Unlink",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert"
  }
];

export function GlobalTemplateApplied(props: GlobalTemplateAppliedProps) {
  const classes = useStyles();
  const history = useHistory();
  const accountId = useAccountRouteMatch();
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<SVGElement | null>(null);
  const editTestSuitePath = Routes.EditTestSuite.getUrl({ accountId, testSuiteId: props.parent.id });

  function handleEditGlobalTemplate(event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>) {
    event.stopPropagation();
    history.push(editTestSuitePath);
  }

  function handleUnlinkGlobalTemplate(id: PopoverDialogDefaultIdentifiers | string) {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      props.unlinkGlobalTemplate();
    }
  }

  function handleClickUnlink(event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>) {
    event.stopPropagation();
    setPopoverAnchorElement(event.currentTarget);
  }

  function handleTestSuiteItemClick(event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (!event.isDefaultPrevented()) {
      history.push(editTestSuitePath);
    }
  }

  const showDisconnectIntegration = Boolean(popoverAnchorElement);
  const popoverId = showDisconnectIntegration ? defaultPopOverId : undefined;

  return (
    <div data-testid="global-template-applied">
      <div className={classes.wrapper} onClick={handleTestSuiteItemClick} data-testid="global-template-item">
        <Typography color="textPrimary" noWrap data-testid="global-template-testsuite-name">
          {props.parent.name}
        </Typography>
        <Box display="flex" alignItems="center">
          <ActionButtonTooltip title="Edit global template">
            <CreateRoundedIcon
              data-testid="edit-global-template"
              data-pendo="auto-test-suite-edit-template-settings-global-template-edit"
              className={clsx(classes.actionIcon, classes.editIcon)}
              onClick={handleEditGlobalTemplate}
            />
          </ActionButtonTooltip>
          <PopoverDialog
            anchorElement={popoverAnchorElement}
            handleAction={handleUnlinkGlobalTemplate}
            open={showDisconnectIntegration}
            text="Your test suite will be disconnected from the global template."
            title="Are you sure you want to unlink from global template?"
            buttons={popoverButtons}
            id={popoverId}
          />
          <ActionButtonTooltip title="Unlink from global template">
            <LinkOffRoundedIcon
              data-testid="unlink-global-template"
              data-pendo="auto-test-suite-edit-template-settings-global-template-unlink"
              className={clsx(classes.actionIcon, classes.unlinkIcon)}
              onClick={handleClickUnlink}
            />
          </ActionButtonTooltip>
        </Box>
      </div>
    </div>
  );
}
