import React, { useState } from "react";
import { getFullNameFromUser } from "../../utils/getFullNameFromUser/getFullNameFromUser";
import {
  Box,
  IconButton,
  makeStyles,
  Divider,
  ListItemText,
  ListItemIcon,
  Popover,
  List,
  ListItem
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import clsx from "clsx";
import { getUser } from "../../../generated-graphql-interfaces";

const useStyles = makeStyles(theme => ({
  menuDisplayName: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#616161",
    "&:hover": {
      cursor: "auto",
      backgroundColor: theme.palette.primary.light
    }
  },
  iconButton: {
    color: theme.palette.navy[400],
    borderRadius: 50,
    padding: 6,
    "&:hover": {
      color: theme.palette.navy[700],
      backgroundColor: theme.palette.grey[200]
    }
  },
  activeIconButton: {
    color: theme.palette.navy[900]
  },
  logoutItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fullName: {
    display: "block",
    color: "rgba(0, 0, 0, 0.8)",
    marginBottom: theme.spacing(1)
  },
  menuPaper: {
    width: "320px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.04), 0px 3px 3px rgba(0, 0, 0, 0.02)",
    borderRadius: "3px",
    paddingBottom: 0,
    "& ul": {
      padding: 0
    }
  },
  logoutText: {
    fontWeight: "bold"
  },
  pushRight: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0)
    }
  }
}));

const useListIconStyles = makeStyles({
  root: {
    minWidth: "30px"
  }
});

interface UserMenuProps {
  logoutCallback: () => void;
  userData: getUser | undefined;
  isUserDataLoading: boolean;
}
// eslint-disable-next-line complexity, max-lines-per-function
export function UserMenu(props: UserMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles();
  const iconClasses = useListIconStyles();

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  }

  function handleClose() {
    setAnchorEl(null);
    setIsMenuOpen(false);
  }

  function handleLogout() {
    handleClose();
    props.logoutCallback();
  }

  return (
    <Box display="flex" alignItems="center" data-testid="user-menu-wrapper">
      <div className={classes.pushRight}>
        <IconButton
          aria-controls="user-menu"
          aria-haspopup="true"
          data-testid="user-menu-icon-button"
          data-pendo="auto-global-user-menu"
          onClick={handleClick}
          className={clsx(classes.iconButton, isMenuOpen && classes.activeIconButton)}
        >
          <AccountCircleIcon fontSize="large" />
          {isMenuOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </IconButton>
        <Popover
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{ paper: classes.menuPaper }}
        >
          <List role="menu">
            {props.userData && (
              <ListItem className={classes.menuDisplayName} data-testid="display-name-within-dropdown">
                <div>
                  <b className={classes.fullName}>{getFullNameFromUser(props.userData.me)}</b>
                  <span>{props.userData.me.username}</span>
                </div>
              </ListItem>
            )}
            {props.userData && <Divider />}
            <ListItem
              button
              data-testid="account-select-logout"
              data-cy="logout-button"
              data-pendo="auto-global-user-menu-logout"
              onClick={handleLogout}
              className={classes.logoutItem}
            >
              <ListItemIcon classes={iconClasses}>
                <ExitToAppRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" classes={{ primary: classes.logoutText }} />
            </ListItem>
          </List>
        </Popover>
      </div>
    </Box>
  );
}
