import { makeStyles } from "@material-ui/core";
import { TestSuiteListItemStartedProps } from "./TestSuiteListItemStarted";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { BuildStatus } from "../../../generated-graphql-interfaces";

export const useStyles = makeStyles(theme => ({
  border: (props: TestSuiteListItemStartedProps) => {
    return {
      width: "6px",
      backgroundColor: getMatchingBorderColorForStatus(props.status, theme),
      flexShrink: 0
    };
  },
  root: {
    backgroundColor: "#FFF",
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.navy[200]}`,
    borderBottom: `1px solid ${theme.palette.navy[200]}`,
    borderRight: `1px solid ${theme.palette.navy[200]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: "5px"
  },
  wrapper: {
    display: "flex",
    borderRadius: "4px",
    overflow: "hidden",
    marginBottom: "10px",
    boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.04)",
    "&:hover": {
      boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 9px rgba(0, 0, 0, 0.2)"
    }
  },
  disabled: {
    "&:hover": {
      boxShadow: "none !important"
    }
  },
  alignRight: {
    textAlign: "right"
  },
  showAsNotLink: (props: TestSuiteListItemStartedProps) => {
    return {
      textDecoration: "none",
      "&:hover": {
        cursor: props.status === BuildStatus.Running ? "default" : "pointer"
      }
    };
  },
  lastRun: {
    fontWeight: 400,
    color: theme.palette.navy[300],
    fontSize: 14
  }
}));
