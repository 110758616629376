import React from "react";
import { Grid, Box, InputAdornment } from "@material-ui/core";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";

// eslint-disable-next-line max-lines-per-function
export function TestSettings() {
  const classes = useCrawlSettingsStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={7} mb={4}>
          <b className={classes.tooltipTitle}>Test Settings</b>
          <DeepcrawlInfoTooltip
            maxWidth={400}
            body={crawlSettingsTooltips.testSettings}
            data-pendo="auto-tooltip-test-settings"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxTitleWidth"
            id="max-title-width"
            data-testid="max-title-width"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-title-width"
            label="Max title width"
            InputProps={{
              endAdornment: <InputAdornment position="start">pixels</InputAdornment>
            }}
            variant="filled"
            component={TextField}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="minTitleLength"
            label="Min title length"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-min-title-length"
            suffix="characters"
            InputProps={{
              endAdornment: <InputAdornment position="start">characters</InputAdornment>
            }}
            variant="filled"
            component={TextField}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            variant="filled"
            name="minDescriptionLength"
            component={TextField}
            label="Min description length"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-min-description-length"
            suffix="characters"
            InputProps={{
              endAdornment: <InputAdornment position="start">characters</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxDescriptionLength"
            label="Max description length"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-description-length"
            suffix="characters"
            InputProps={{
              endAdornment: <InputAdornment position="start">characters</InputAdornment>
            }}
            variant="filled"
            component={TextField}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxHtmlSize"
            variant="filled"
            component={TextField}
            label="Max HTML size"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-html-size"
            suffix="bytes"
            InputProps={{
              endAdornment: <InputAdornment position="start">bytes</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxLinks"
            label="Max links"
            variant="filled"
            component={TextField}
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-links"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="thinPageThreshold"
            component={TextField}
            variant="filled"
            label="Thin page threshold"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-thin-page-threshold"
            InputProps={{
              endAdornment: <InputAdornment position="start">bytes</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            component={TextField}
            variant="filled"
            type="number"
            name="emptyPageThreshold"
            label="Empty page threshold"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-empty-page-threhsold"
            suffix="bytes"
            InputProps={{
              endAdornment: <InputAdornment position="start">bytes</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            component={TextField}
            variant="filled"
            type="number"
            name="maxExternalLinks"
            label="Max external links"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-external-links"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxContentSize"
            id="max-content-size"
            data-testid="max-content-size"
            component={TextField}
            variant="filled"
            label="Max content size"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-content-size"
            InputProps={{
              endAdornment: <InputAdornment position="start">bytes</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxUrlLength"
            component={TextField}
            variant="filled"
            label="Max URL length"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-url-length"
            InputProps={{
              endAdornment: <InputAdornment position="start">characters</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxLoadTime"
            component={TextField}
            variant="filled"
            label="Max fetch time"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-fetch-time"
            InputProps={{
              endAdornment: <InputAdornment position="start">seconds</InputAdornment>
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            min="1"
            max="5"
            name="duplicatePrecision"
            component={TextField}
            variant="filled"
            label="Duplicate precision"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-duplicate-precision"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Field
            fullWidth
            type="number"
            name="maxRedirections"
            component={TextField}
            variant="filled"
            label="Max redirections"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-redirections"
            InputProps={{
              endAdornment: <InputAdornment position="start">redirections</InputAdornment>
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
