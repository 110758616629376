import { createContext } from "react";

export interface AutomatorPermissions {
  jiraIntegrationAccess: boolean;
  globalTemplatesAccess: boolean;
  frontendSchedulerAccess: boolean;
  automaticThresholdAccess: boolean;
}

export interface PermissionsContext {
  permissions: AutomatorPermissions;
  loaded: boolean;
  setCurrentPermissions: (permissions: AutomatorPermissions) => void;
}

export const permissionsContext: React.Context<PermissionsContext> = createContext<PermissionsContext>({
  permissions: {
    jiraIntegrationAccess: false,
    globalTemplatesAccess: false,
    frontendSchedulerAccess: false,
    automaticThresholdAccess: false
  },
  loaded: false,
  setCurrentPermissions: (permissions: AutomatorPermissions) => {}
});
