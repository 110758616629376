import React, { useLayoutEffect } from "react";
import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-common";
import Rollbar from "rollbar";
import { graphQLClient } from "./api/graphql/graphql.client";
import { addHotjarScript } from "./_common/utils/addHotjarScript/addHotjarScript";
import { ThemeProvider } from "@material-ui/styles";
import { deepCrawlTheme } from "./theme/deepCrawlTheme";
import { IconButton, makeStyles } from "@material-ui/core";
import { GaTag } from "./_common/components/GaTag/GaTag";
import { usePermissions } from "./_common/hooks/usePermissions/usePermissions";
import { permissionsContext } from "./_common/contexts/Permissions/Permissions.context";
import { ScrollToTop } from "./_common/components/ScrollToTop/ScrollToTop";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarKey, SnackbarProvider } from "notistack";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { LocalStorageKeys } from "./_common/constants/localStorageKeys";
import { rollbarConfig } from "./_common/constants/rollbarConfig";
import { AuthContext } from "./_common/contexts/Permissions/AuthContext";
import { ReactAppEnvironment } from "./_common/constants/ReactAppEnvironment";
import { LoggedInView } from "./routes/LoggedInView";
import { LoggedOutView } from "./routes/LoggedOutView";

const useAppStyles = makeStyles({
  success: {
    // Note: we have to use !important because of this issue: https://github.com/iamhosseindhv/notistack/issues/305
    // Also, it does not work if we use 'theme.palette.deepcrawlGreen.main'.
    backgroundColor: "#55830E !important"
  },
  error: {
    backgroundColor: "#b63a3a !important"
  },
  closeIcon: {
    color: "white"
  }
});

export function App() {
  const classes = useAppStyles();
  const permissionsContextValue = usePermissions();
  const [authContext, setAuthContext] = useState({
    isAuthorised: !!localStorage.getItem(LocalStorageKeys.TOKEN)
  });
  const notistackRef = useRef<SnackbarProvider>(null);
  useState(new Rollbar(rollbarConfig));

  useLayoutEffect(() => {
    // Note: we want to immediately redirect users to the new Automate app domain
    const isLocalHost = window.location.href.includes("localhost");
    const newUrl = isLocalHost
      ? "https://automate.dev.deepcrawl.com/"
      : window.location.href.replace("automator", "automate");

    // eslint-disable-next-line fp/no-mutation
    window.location.href = newUrl;
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== ReactAppEnvironment.DEVELOPMENT) {
      addHotjarScript();
    }
  }, []);

  function closeSnackbar(key: SnackbarKey) {
    return () => {
      if (notistackRef.current) {
        notistackRef.current.closeSnackbar(key);
      }
    };
  }

  function loginHandler() {
    setAuthContext(currentAuthContext => {
      return { ...currentAuthContext, isAuthorised: true };
    });
  }

  function logoutHandler() {
    localStorage.removeItem(LocalStorageKeys.TOKEN);
    localStorage.removeItem(LocalStorageKeys.USER_ROLE);
    setAuthContext(currentAuthContext => {
      return { ...currentAuthContext, isAuthorised: false };
    });
  }

  return (
    <ApolloProvider client={graphQLClient}>
      <AuthContext.Provider value={authContext}>
        <permissionsContext.Provider value={permissionsContextValue}>
          <Router>
            <ScrollToTop />
            <GaTag />
            <ThemeProvider theme={deepCrawlTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  classes={{ variantSuccess: classes.success, variantError: classes.error }}
                  ref={notistackRef}
                  action={key => (
                    <IconButton
                      onClick={closeSnackbar(key)}
                      className={classes.closeIcon}
                      data-testid="close-snackbar-button"
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  )}
                >
                  {authContext.isAuthorised ? (
                    <LoggedInView logoutHandler={logoutHandler} />
                  ) : (
                    <LoggedOutView logoutHandler={logoutHandler} loginHandler={loginHandler} />
                  )}
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Router>
        </permissionsContext.Provider>
      </AuthContext.Provider>
    </ApolloProvider>
  );
}
