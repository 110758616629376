import { withStyles, Slider } from "@material-ui/core";

export const DeepCrawlSlider = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.light,
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    opacity: 1
  }
}))(Slider);
