import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { useGetJiraSettingsQuery } from "../graphql/useGetJiraSettings";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: theme.spacing(10)
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "1rem",
    marginRight: theme.spacing(1)
  },
  buttonWrapper: (props: ICreateJiraAuthenticationFormProps) => {
    return {
      marginTop: props.name.length > 50 ? "30px" : theme.spacing(7)
    };
  },
  tooltipTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginTop: 0,
    lineHeight: "1.125rem"
  },
  tooltipText: {
    fontSize: "0.875rem",
    color: "#202020"
  },
  error: {
    color: theme.palette.error.main
  },
  alert: {
    marginTop: theme.spacing(2)
  }
}));

export interface ICreateJiraAuthenticationPayload {
  jiraAuthenticationId: string;
  jiraUrl: string;
}

interface ICreateJiraAuthenticationFormProps {
  accountId: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onComplete: () => void;
  onError: (payload: ICreateJiraAuthenticationPayload) => void;
  onSuccess: (payload: ICreateJiraAuthenticationPayload) => void;
}

// eslint-disable-next-line max-lines-per-function
export function CreateJiraAuthenticationForm(props: ICreateJiraAuthenticationFormProps): JSX.Element {
  const classes = useStyles(props);
  const { accountId, name, onChange, onComplete, onError, onSuccess } = props;
  const isNameTooLong = name.length > 50;

  const { data: jiraSettingsData } = useGetJiraSettingsQuery();

  useEffect(() => {
    async function handleWindowMessage({ data: { message, payload } }: MessageEvent) {
      const ERROR = "jira-authentication-error";
      const SUCCESS = "jira-authentication-success";

      switch (message) {
        case ERROR:
          onError(payload);
          break;
        case SUCCESS:
          onSuccess(payload);
          break;
        default:
          return;
      }

      onComplete();
    }

    window.addEventListener("message", handleWindowMessage);

    return () => {
      window.removeEventListener("message", handleWindowMessage);
    };
  }, [onComplete, onError, onSuccess]);

  function openAuthWindow(): void {
    if (!jiraSettingsData) return;

    const { clientId, authCallbackUrl } = jiraSettingsData.getJiraSettings;

    const state = btoa(JSON.stringify({ accountId, name }));

    const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=offline_access%20read%3Ajira-work%20write%3Ajira-work&redirect_uri=${authCallbackUrl}&state=${state}&response_type=code&prompt=consent`;

    window.open(authUrl);
  }

  return (
    <div data-testid="create-jira-authentication-form">
      <Typography variant="h1" className={classes.title} data-testid="create-jira-authentication-title">
        Create Jira Authentication
      </Typography>

      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h1" className={classes.subtitle} data-testid="create-jira-authentication-subtitle">
          Name
        </Typography>
        <DeepcrawlInfoTooltip
          data-pendo="auto-tooltip-jira-authentication-name-setup"
          maxWidth={400}
          body={
            <Box>
              <Typography variant="h6" className={classes.tooltipTitle}>
                Jira authentication name
              </Typography>
              <Typography variant="body1" className={classes.tooltipText}>
                You should name your authentication with a unique identifier based on the configuration settings you
                make if you plan to have multiple Jira authentications for a single Automation Hub account.
              </Typography>
            </Box>
          }
        />
      </Box>

      <TextField
        label="Authentication name"
        variant="filled"
        fullWidth
        value={name}
        onChange={onChange}
        data-testid="create-jira-authentication-name-input"
        data-pendo="auto-add-jira-authentication-name-input"
      />

      {isNameTooLong && (
        <Typography data-testid="validation-error" variant="caption" className={classes.error}>
          Authentication name cannot be more than 50 characters.
        </Typography>
      )}

      <Alert classes={{ root: classes.alert }} severity="info" data-testid="jira-authentication-name-msg">
        Please use a unique name if you have multiple Jira authentications.
      </Alert>

      <Box display="flex" justifyContent="flex-end" className={classes.buttonWrapper}>
        <PrimaryActionButton
          data-pendo="auto-testsuite-authentication-name-continue"
          dataTestId="create-jira-authentication-submit-btn"
          disabled={!name || isNameTooLong}
          onClick={() => openAuthWindow()}
          label="Continue"
        />
      </Box>
    </div>
  );
}
