import React from "react";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { NotYetRunWidget } from "./components/NotYetRunWidget";
import { Box, Grid } from "@material-ui/core";
import { useTestSuiteQuery } from "./graphql/useTestSuiteQuery";
import {
  getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes,
  getTestSuites,
  getTestSuites_getAccount
} from "../../generated-graphql-interfaces";
import { Fade } from "@material-ui/core";
import { DashboardEmpty } from "./components/DashboardEmpty";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { Alert, Skeleton } from "@material-ui/lab";
import { getNotYetRunTestsuitesInfo } from "./utils/getNotYetRunTestsuitesInfo";
import { LatestTestRunsWidget } from "./components/LatestTestRunsWidget";
import { getAllBuildsFromAccount } from "./utils/getAllBuildsFromAccount";
import { FailPassWarnChartWrapper } from "./components/FailPassWarnChartWrapper";
import { getTestSuitesFromData } from "./utils/getTestSuitesFromData";
import { SeoHealthBar } from "./components/SeoHealthBar";

export interface BuildInfo extends getNextTestSuites_getAccount_testSuites_edges_node_builds_nodes {
  sitePrimary: string;
  name: string;
  testSuiteId: string;
}

const smallColumnWidth = 7;
const largeColumnWidth = 12;

function extractAccount(accountId: string, testsuiteData: getTestSuites | undefined): getTestSuites_getAccount {
  return testsuiteData?.getAccount || { __typename: "Account", id: accountId, testSuites: null };
}

const ITEMS_PER_REQUEST = 100;

// eslint-disable-next-line complexity
export function Dashboard(): JSX.Element {
  const accountId = useAccountRouteMatch();
  const { loading, error, data, lastDataRetirevalAttemptTimestamp } = useTestSuiteQuery(accountId, ITEMS_PER_REQUEST);
  const account = extractAccount(accountId, data);
  const notYetRunTestsuitesInfo = getNotYetRunTestsuitesInfo(account);
  const allBuilds = getAllBuildsFromAccount(account);

  const isDashboardEmpty = (account.testSuites?.totalCount || 0) < 1;
  const latestTestRunsGridSize = notYetRunTestsuitesInfo.length ? smallColumnWidth : largeColumnWidth;
  const showEmptyDashboard = !loading && !error && isDashboardEmpty;
  const showDashBoardData = !loading && !isDashboardEmpty;
  const fadeInStyles = { display: !loading ? "none" : "block" };

  return (
    <Box>
      <PageHeader mainText="Welcome to Automation Hub" includeAddTestSuite={true} pendoIdSuffix="dashboard" />

      <SeoHealthBar accountId={accountId} timeStampForDataReload={lastDataRetirevalAttemptTimestamp} />

      <Fade in={loading} style={fadeInStyles} data-testid="dashboard-loader">
        <Box>
          <Grid container spacing={2}>
            <Grid item md={7} sm={12} xs={12}>
              <Skeleton variant="rect" height={600} />
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <Skeleton variant="rect" height={600} />
            </Grid>
          </Grid>
        </Box>
      </Fade>

      <Fade in={!!error} data-testid="dashboard-error" style={fadeInStyles}>
        <Box>
          <Alert severity="error">
            An error occurred while trying to load your data. Please refresh the page and try again.
          </Alert>
        </Box>
      </Fade>

      {showEmptyDashboard && <DashboardEmpty accountId={accountId} />}
      {showDashBoardData && (
        <>
          <Grid container spacing={2}>
            <Grid item md={latestTestRunsGridSize} sm={12} xs={12}>
              <LatestTestRunsWidget buildsInfo={allBuilds} accountId={accountId} />
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <NotYetRunWidget testSuites={notYetRunTestsuitesInfo} accountId={accountId} />
            </Grid>
          </Grid>
          <FailPassWarnChartWrapper
            testSuites={getTestSuitesFromData(data)}
            timeStampForDataReload={lastDataRetirevalAttemptTimestamp}
          />
        </>
      )}
    </Box>
  );
}
