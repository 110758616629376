import React from "react";
import { FieldProps } from "formik";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";

export interface CheckboxProps
  extends FieldProps,
    Omit<MuiCheckboxProps, "form" | "checked" | "defaultChecked" | "name" | "onChange" | "value"> {}

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting },
  disabled,
  // eslint-disable-next-line
  ...props
}: CheckboxProps): MuiCheckboxProps => {
  return {
    disabled: disabled != undefined ? disabled : isSubmitting,
    ...props,
    ...field,
    checked: field.value,
    value: field.value
  };
};

export const CustomCheckbox: React.ComponentType<CheckboxProps> = (props: CheckboxProps) => (
  <MuiCheckbox {...fieldToCheckbox(props)} />
);
