import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const textColor = "white";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      marginTop: 280,
      [theme.breakpoints.down("md")]: {
        margin: "280px 40px 0px 40px"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "74px"
      },
      [theme.breakpoints.down("xs")]: {
        margin: "74px 24px 0px 24px"
      }
    },
    heading: {
      color: textColor,
      lineHeight: "36px",
      fontSize: "30px",
      fontWeight: "bold",
      marginBottom: theme.spacing(3)
    },
    description: {
      maxWidth: "70%",
      color: textColor,
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: theme.spacing(4)
    },
    crawlHeading: {
      fontSize: "22px",
      lineHeight: "33px",
      fontWeight: "bold",
      color: textColor,
      marginTop: 60,
      marginBottom: theme.spacing(3)
    },
    crawlRow: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        marginBottom: "0"
      }
    },
    checkpointsGrid: {
      maxWidth: "900px"
    }
  })
);
