import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import TemplateDialog from "./TemplateDialog";
import { ApplyGlobalTemplate } from "./ApplyGlobalTemplate";
import { UseAsGlobalTemplate, UseAsGlobalTemplateVariant } from "./UseAsGlobalTemplate";
import { TemplateSettingsMenu } from "./TemplateSettingsMenu";

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: 15,
    marginBottom: theme.spacing(2)
  },
  text: {
    color: theme.palette.navy[300],
    fontSize: 14,
    marginBottom: theme.spacing(4)
  }
}));

interface NoTemplateSettingsViewProps {
  applyGlobalTemplate: (parentId: string) => Promise<void>;
  testSuiteId: string;
  useAsGlobalTemplate: (childId: string, childName: string) => Promise<void>;
  isAutomaticThresholdEnabled: boolean;
}

export enum TemplateSettingsType {
  AddParent = "Add parent",
  AddChildren = "Add children"
}

export function NoTemplateSettingsView(props: NoTemplateSettingsViewProps) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [templateSettingsType, setTemplateSettingsType] = useState<string | null>(null);
  const [availableChildrenDetected, setAvailableChildrenDetected] = useState<number | null>(null);
  const [availableParentsDetected, setAvailableParentsDetected] = useState<number | null>(null);

  function updateTemplateSettingsType(templateType: TemplateSettingsType) {
    setTemplateSettingsType(templateType);
  }

  function closeTemplateDialog() {
    setIsDialogOpen(false);
    setTemplateSettingsType(null);
  }

  function openTemplateDialog() {
    setIsDialogOpen(true);
  }

  const addingChildren = templateSettingsType === TemplateSettingsType.AddChildren;

  return (
    <div data-testid="no-template-settings-view">
      <Typography color="textPrimary" className={classes.subtitle}>
        Apply or use as global template
      </Typography>
      <Typography className={classes.text}>
        Make changes in one global template and apply your changes across all template instances for steps 2 and 3 of
        your test suite.
      </Typography>
      <TemplateSettingsMenu
        isAutomaticThresholdEnabled={props.isAutomaticThresholdEnabled}
        openTemplateDialog={openTemplateDialog}
        updateTemplateSettingsType={updateTemplateSettingsType}
      />
      <TemplateDialog
        isOpen={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        title={
          addingChildren
            ? "Use as global template"
            : "Choose test suite to serve as global template for this test suite"
        }
        message={
          addingChildren
            ? "All your current configurations in steps 2 and 3 will overwrite steps 2 and 3 in your linked test suite."
            : "Configuration steps 2 and 3 in your test suite will be overwritten using steps 2 and 3 from the global template."
        }
        skipMessage={addingChildren ? availableChildrenDetected === 0 : availableParentsDetected === 0}
        component={
          addingChildren ? (
            <UseAsGlobalTemplate
              handleClose={closeTemplateDialog}
              handleUseAsGlobalTemplate={props.useAsGlobalTemplate}
              label="Select test suite"
              variant={UseAsGlobalTemplateVariant.AddNewChild}
              testSuiteId={props.testSuiteId}
              availableTestSuitesDetected={setAvailableChildrenDetected}
            />
          ) : (
            <ApplyGlobalTemplate
              handleApplyGlobalTemplate={props.applyGlobalTemplate}
              handleClose={closeTemplateDialog}
              testSuiteId={props.testSuiteId}
              availableTestSuitesDetected={setAvailableParentsDetected}
            />
          )
        }
      />
    </div>
  );
}
