import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createEmailAlert, createEmailAlertVariables } from "../../../../../../generated-graphql-interfaces";

export const createEmailAlertGql = gql`
  mutation createEmailAlert($testSuiteId: ObjectID!, $email: String!) {
    createTestSuiteEmailAlert(input: { testSuiteId: $testSuiteId, email: $email }) {
      testSuiteEmailAlert {
        id
        email
        alertTypes
      }
    }
  }
`;

export function useCreateEmailAlertMutation() {
  return useMutation<createEmailAlert, createEmailAlertVariables>(createEmailAlertGql, {
    refetchQueries: ["getTestSuite"]
  });
}
