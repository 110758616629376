import { hasLastSelectedAccount } from "../LastSelectedAccount/hasLastSelectedAccount";
import { getUser } from "../../../generated-graphql-interfaces";
import { getLastSelectedAccount } from "../LastSelectedAccount/getLastSelectedAccount";

function isLastSelectedAccountValid(data: getUser | undefined) {
  const lastSelectedAccountId = getLastSelectedAccount();
  const availableAccounts = data?.me.accounts.nodes || [];
  const isLastSelectedAccountAvailable = availableAccounts.find(account => account.id === lastSelectedAccountId);
  return !!isLastSelectedAccountAvailable;
}

export function getDisplayingAccountId(data: getUser | undefined, accountToDisplay: string): string {
  return hasLastSelectedAccount() && isLastSelectedAccountValid(data) ? getLastSelectedAccount() : accountToDisplay;
}
