import React from "react";
import { Tooltip, makeStyles, Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";

const useMessageStyles = makeStyles(theme => ({
  titleText: {
    fontSize: "14px",
    marginBottom: theme.spacing(1),
    display: "block"
  },
  bodyText: {
    fontSize: "11px",
    lineHeight: "15px",
    display: "inline-block"
  }
}));

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "#FFF",
    margin: 0,
    padding: 0
  }
}));

interface PermissionsTooltipProps {
  children: React.ReactElement;
}

export function PermissionsTooltip(props: PermissionsTooltipProps) {
  const messageClasses = useMessageStyles();
  const tooltipClases = useStyles(props);

  return (
    <Tooltip
      classes={tooltipClases}
      title={
        <Alert data-testid="no-permission-warning" severity="warning">
          <div>
            <span className={messageClasses.titleText}>
              Looks like you don't have permission to perform this action.
            </span>
            <span className={messageClasses.bodyText}>
              Please contact your account admin or contact support if you need help.
            </span>
          </div>
        </Alert>
      }
      aria-label="no permission tooltip"
      placement="top"
      data-testid="no-permissions-tooltip"
    >
      <span>{props.children}</span>
    </Tooltip>
  );
}
