import React from "react";
import { ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { isPageActive } from "./NavButtonUtils";

const useStyles = makeStyles(theme => ({
  listItemText: {
    color: "#E0E0E0",
    fontWeight: 600,
    fontSize: 14
  },
  icon: {
    color: "white",
    minWidth: "40px"
  },
  listItem: {
    borderRadius: 50,
    padding: theme.spacing(0.5, 2),
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.navy[400],
      "& div": {
        "& svg": {
          color: theme.palette.primary.light
        }
      },
      "& div:nth-of-type(2)": {
        "& span": {
          color: theme.palette.primary.light
        }
      }
    }
  },
  selectedListItem: {
    "&:hover": {
      backgroundColor: "#050A18"
    },
    backgroundColor: "#050A18",
    "& div": {
      "& svg": {
        color: theme.palette.primary.light
      }
    },
    "& div:nth-of-type(2)": {
      "& span": {
        color: theme.palette.primary.light
      }
    }
  }
}));

interface NavButtonProps {
  onClick: () => void;
  testId: string;
  icon: React.ReactElement;
  activeIcon: React.ReactElement;
  label: string;
  href: string;
  activePaths: string[];
}

// TODO: in the future it would be worth using component composition so that we can pass react-router-dom <Link />
// component to <ListItem />. See https://material-ui.com/guides/composition/#routing-libraries for more info
export function NavButton(props: NavButtonProps) {
  const classes = useStyles();
  const isActive = isPageActive(props.href, props.activePaths);

  return (
    <ListItem
      button
      data-testid="nav-link"
      data-pendo={`auto-global-nav-link-${props.label.replace(/\s+/g, "-").toLowerCase()}`}
      data-cy={`${props.testId}-nav-link`}
      onClick={props.onClick}
      className={isActive ? "linear-gradient-border" : undefined}
      classes={{
        button: clsx(classes.listItem, isActive && classes.selectedListItem)
      }}
    >
      <ListItemIcon data-testid="nav-icon" className={classes.icon}>
        {isActive ? props.activeIcon : props.icon}
      </ListItemIcon>
      <ListItemText data-testid="nav-link-text" primary={props.label} classes={{ primary: classes.listItemText }} />
    </ListItem>
  );
}
