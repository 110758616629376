import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  linkJiraIntegrationToTestSuite,
  linkJiraIntegrationToTestSuiteVariables
} from "../../../../../generated-graphql-interfaces";

export const linkJiraIntegrationToTestSuiteGql = gql`
  mutation linkJiraIntegrationToTestSuite($jiraIntegrationId: ObjectID!, $testSuiteId: ObjectID!) {
    linkJiraIntegrationToTestSuite(input: { jiraIntegrationId: $jiraIntegrationId, testSuiteId: $testSuiteId }) {
      testSuiteJiraIntegration {
        id
      }
    }
  }
`;

export function useLinkJiraIntegrationToTestSuiteMutation() {
  return useMutation<linkJiraIntegrationToTestSuite, linkJiraIntegrationToTestSuiteVariables>(
    linkJiraIntegrationToTestSuiteGql,
    {
      refetchQueries: ["getTestSuite"]
    }
  );
}
