import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function CopyIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6132 6.64665C15.6079 5.49829 14.6903 4.56641 13.5649 4.56641H2.6694C1.53903 4.56641 0.621094 5.49865 0.621094 6.64665V22.5232C0.621094 23.6712 1.53903 24.6035 2.6694 24.6035H13.5649C14.6953 24.6035 15.6132 23.6712 15.6132 22.5232V6.64665ZM13.9213 6.6466V22.5183C13.9213 22.7206 13.7593 22.8851 13.5601 22.8851H2.66464C2.46547 22.8851 2.30345 22.7206 2.30345 22.5183V6.6466C2.30345 6.44433 2.46547 6.27979 2.66464 6.27979H13.5601C13.7593 6.27979 13.9213 6.44433 13.9213 6.6466Z"
      />
      <path d="M17.7314 0.404297C18.8618 0.404297 19.7797 1.33654 19.7797 2.48454V18.3611C19.7797 19.5091 18.8618 20.4414 17.7314 20.4414C17.2634 20.4414 16.8878 20.06 16.8878 19.5847C16.8878 19.1094 17.2634 18.728 17.7314 18.728C17.9306 18.728 18.0926 18.5634 18.0926 18.3611V2.48454C18.0926 2.28227 17.9306 2.11772 17.7314 2.11772H6.8359C6.63674 2.11772 6.47472 2.28227 6.47472 2.48454C6.47472 2.95984 6.09917 3.34125 5.63116 3.34125C5.16315 3.34125 4.7876 2.95984 4.7876 2.48454C4.7876 1.33654 5.70553 0.404297 6.8359 0.404297H17.7314Z" />
    </SvgIcon>
  );
}
