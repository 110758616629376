import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { updateJiraIntegration, updateJiraIntegrationVariables } from "../../../../../generated-graphql-interfaces";

export const updateJiraIntegrationGql = gql`
  mutation updateJiraIntegration(
    $jiraIntegrationId: ObjectID!
    $name: String
    $jiraProjectId: String
    $jiraIssueTypeId: String
  ) {
    updateJiraIntegration(
      input: {
        jiraIntegrationId: $jiraIntegrationId
        name: $name
        jiraProjectId: $jiraProjectId
        jiraIssueTypeId: $jiraIssueTypeId
      }
    ) {
      jiraIntegration {
        id
        name
      }
    }
  }
`;

export function useUpdateJiraIntegrationMutation() {
  return useMutation<updateJiraIntegration, updateJiraIntegrationVariables>(updateJiraIntegrationGql, {
    refetchQueries: ["getJiraIntegrations", "getJiraIntegrationsWithTestSuites"]
  });
}
