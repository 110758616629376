import React from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { AuthRoute } from "../_common/components/AuthRoute/AuthRoute";
import { withRouter } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import { Routes } from "../_common/routes/routes";

export interface AppRoutesPublicProps {
  loginHandler: (input: { token?: string; errors?: string[] }) => void;
}

type AppRoutesPublicComponentProps = RouteComponentProps<{}> & AppRoutesPublicProps;

export const AppRoutesPublic = withRouter((props: AppRoutesPublicComponentProps) => {
  return (
    <Switch>
      <AuthRoute
        exact
        path={Routes.Login.ROUTE}
        render={() => {
          return <Login authCallback={props.loginHandler} />;
        }}
      />
      <AuthRoute path={Routes.Any.ROUTE} render={() => <Redirect to={Routes.Login.ROUTE} />} />
    </Switch>
  );
});
