import { Box, Checkbox, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { Field, FormikErrors } from "formik";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import React, { useEffect, useState } from "react";
import { FormHelperTextStyled, FormHelperTextStyledType } from "../FormHelperTextStyled/FormHelperTextStyled";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { TextField as MuiTextField } from "@material-ui/core";
import {
  ScheduleSettingsInitialValues,
  TestSuiteOption
} from "../../../pages/FrontendScheduler/components/ScheduleSettings/scheduleSettingsValidationSchema";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import useDebounce from "../../hooks/useDebounce/UseDebounce";
import { getTestSuiteOptionsFromData } from "../../../pages/FrontendScheduler/components/ScheduleSettings/utils/getTestSuiteOptionsFromData";
import { QueryResult } from "@apollo/react-common";
import {
  getTestSuiteOptionsForSchedule,
  getTestSuiteOptionsForScheduleVariables
} from "../../../generated-graphql-interfaces";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { removeDuplicatesFromOptions } from "../../utils/removeDuplicatesFromOptions/removeDuplicatesFromOptions";
import clsx from "clsx";

const useAutocompleteStyles = makeStyles(() => ({
  paper: {
    "& .MuiAutocomplete-listbox > li:nth-child(2) > div": {
      borderTop: `0px solid white`,
      paddingTop: "5px",
      marginTop: 0
    },
    "& .MuiAutocomplete-listbox": {
      maxHeight: "240px" // approximately 5 items 48px * 5 // user can scroll in
    }
  },
  option: {
    height: "48px",
    padding: "0px",
    "& p": {
      overflow: "hidden",
      width: "80%",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }
}));

const useStyles = makeStyles(theme => ({
  option: {
    padding: "5px 10px",
    width: "calc(100% - 20px)",
    display: "flex",
    alignItems: "center"
  },
  selectedOption: {
    backgroundColor: theme.palette.purple[50]
  },
  optionLabel: {
    color: theme.palette.navy[500],
    fontWeight: 500,
    fontSize: 14
  },
  checkbox: {
    marginRight: theme.spacing(1)
  }
}));

export interface ChooseTestSuiteProps {
  errors?: FormikErrors<ScheduleSettingsInitialValues>;
  handleChange: {
    (e: React.ChangeEvent): void;
    <T = string | React.ChangeEvent>(field: T): T extends React.ChangeEvent
      ? void
      : (e: string | React.ChangeEvent) => void;
  };
  mutation: (
    accountId: string,
    searchText: string | null
  ) => QueryResult<getTestSuiteOptionsForSchedule, getTestSuiteOptionsForScheduleVariables>;
  initialValues?: {
    id: any;
    name: string;
  }[];
}

// eslint-disable-next-line max-lines-per-function
export function ChooseTestSuite(props: ChooseTestSuiteProps) {
  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();
  const accountId = useAccountRouteMatch();

  const [searchText, setSearchText] = useState<string | null>("");
  const [testSuites, setTestSuites] = useState<TestSuiteOption[]>(props.initialValues || []);
  const [totalTestSuiteCount, setTotalTestSuiteCount] = useState<number | null>(null);

  const debouncedSearchText = useDebounce(searchText, 300);
  const { data, error, loading } = props.mutation(accountId, debouncedSearchText !== "" ? debouncedSearchText : null);

  const initialValues = props.initialValues || [];
  const options = [...getTestSuiteOptionsFromData(data), ...initialValues];

  useEffect(() => {
    const totalCount = data?.getAccount?.unscheduledTestSuites?.totalCount;
    if (totalTestSuiteCount === null && totalCount !== undefined && totalCount >= 0) {
      setTotalTestSuiteCount(totalCount);
    }
  }, [data, totalTestSuiteCount]);

  function handleChange(_: React.ChangeEvent<{}> | null, newOptions: TestSuiteOption[]) {
    const filteredOptions = removeDuplicatesFromOptions(newOptions);
    if (filteredOptions) {
      setTestSuites(filteredOptions);
      props.handleChange({ target: { value: filteredOptions, name: "testSuite" }, type: "array" });
    }
    if (!filteredOptions.length) {
      setSearchText("");
    }
  }

  function handleInputChange(event: { target: { value: string } }) {
    setSearchText(event?.target?.value || "");
  }

  function getOptionDisabled(option: TestSuiteOption) {
    if (testSuites.length < 50) return false;
    const isOptionSelected = testSuites.some(({ id }) => id === option.id);
    return !isOptionSelected;
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="choose-test-suite-error">
        An error occurred loading your test suites. Please refresh the page and try again.
      </Alert>
    );
  }

  if (!loading && totalTestSuiteCount === 0 && initialValues.length === 0) {
    return (
      <Alert severity="info" data-testid="choose-test-suite-no-suites">
        You cannot create a new schedule because you currently don&apos;t have any test suites to choose from.
      </Alert>
    );
  }

  return (
    <Field
      openOnFocus
      multiple
      disableCloseOnSelect
      name="testSuite"
      value={testSuites}
      classes={autocompleteClasses}
      inputValue={searchText}
      loading={loading}
      blurOnSelect={false}
      onBlur={() => setSearchText("")}
      component={Autocomplete}
      getOptionDisabled={getOptionDisabled}
      onChange={handleChange}
      onInputChange={handleInputChange}
      popupIcon={<ExpandMoreRoundedIcon />}
      data-testid="choose-test-suite-input"
      data-pendo="auto-scheduler-edit-test-suites"
      options={options}
      getOptionSelected={(testSuite: TestSuiteOption, value: TestSuiteOption) => {
        return testSuite.id === value.id && testSuites.map(testsuite => testsuite.id).includes(testSuite.id);
      }}
      noOptionsText="No Test Suites matches the search"
      getOptionLabel={(option: TestSuiteOption) => option.name}
      renderOption={(option: TestSuiteOption, { selected }: { selected: boolean }) => {
        return (
          <Box className={clsx(classes.option, selected && classes.selectedOption)}>
            <Checkbox
              data-testid={`schedule-test-suite-option-checkbox-${option.id}`}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              className={classes.checkbox}
              checked={selected}
            />
            <Typography className={classes.optionLabel}>{option.name}</Typography>
          </Box>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <>
            <MuiTextField
              {...params}
              error={!!props?.errors?.testSuite}
              label="Choose test suite"
              aria-describedby="testSuite-text-field"
              variant="outlined"
              placeholder="Test suites"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
            <FormHelperTextStyled
              id="testSuite-text-field"
              testId="testSuite-text-field-error"
              text={typeof props.errors?.testSuite === "string" ? props.errors?.testSuite : ""}
              show={!!props?.errors?.testSuite}
              type={FormHelperTextStyledType.ERROR}
            />
          </>
        );
      }}
    />
  );
}
