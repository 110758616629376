import {
  getTestSuiteReport_node_Build_testSuite_customExtractions,
  getTestSuiteReport_node_Build_testResults_nodes_reportTemplate
} from "../../../generated-graphql-interfaces";
import { getCustomExtractionIndexInReportTemplates } from "../../../pages/TestSuite/components/ChooseTestsForm/utils/customExtractionWithReportTemplate";

export function getCustomExtractionTestName(
  reportTemplate: getTestSuiteReport_node_Build_testResults_nodes_reportTemplate,
  customExtractions: getTestSuiteReport_node_Build_testSuite_customExtractions[] | undefined | null
) {
  const index = getCustomExtractionIndexInReportTemplates(reportTemplate, customExtractions);
  return reportTemplate.name + (index > -1 && customExtractions ? ` (${customExtractions[index].label})` : "");
}
