import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const deleteEmailGql = gql`
  mutation deleteAlertEmail($testSuiteEmailAlertId: ObjectID!) {
    deleteTestSuiteEmailAlert(input: { testSuiteEmailAlertId: $testSuiteEmailAlertId }) {
      testSuiteEmailAlert {
        email
        id
        alertTypes
      }
    }
  }
`;

export function useDeleteEmailAlertMutation() {
  return useMutation(deleteEmailGql, {
    refetchQueries: ["getTestSuite"]
  });
}
