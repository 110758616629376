import React, { useState, useEffect } from "react";
import { TestSuiteList } from "./TestSuiteList";
import { Pagination } from "./Pagination";
import { useTestSuitesQuery } from "./graphql/useTestSuitesQuery";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { getNextTestSuites, getPreviousTestSuites } from "../../generated-graphql-interfaces";
import { getTotalPagesCount } from "./utils/getTotalPagesCount";
import { getPageInfo } from "./utils/getPageInfo";
import { Alert } from "@material-ui/lab";

const itemsPerPage = 20;

interface PaginationInfo {
  currentPage: number;
  cursor: string | null;
  isNext: boolean;
}

const useStyles = makeStyles(theme => ({
  loader: {
    margin: theme.spacing(3.5)
  }
}));

export type TestsuiteData = getNextTestSuites | getPreviousTestSuites | undefined;

// eslint-disable-next-line max-statements, complexity
export function PaginatedTestSuiteList(): JSX.Element {
  const classes = useStyles();
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPage: 1,
    cursor: null,
    isNext: true
  });
  const accountId = useAccountRouteMatch();

  const { loading, error, data, refetch } = useTestSuitesQuery(
    accountId,
    itemsPerPage,
    paginationInfo.isNext,
    paginationInfo.cursor
  );

  useEffect(() => {
    const currentDataAreEmpty = data?.getAccount?.testSuites?.edges.length === 0;
    const isOnNonExistingPage = currentDataAreEmpty && paginationInfo.currentPage !== 1;

    if (isOnNonExistingPage) {
      setPaginationInfo(currentInfo => ({
        ...currentInfo,
        currentPage: 1,
        cursor: null
      }));
    }
  }, [data, paginationInfo.currentPage, paginationInfo.cursor]);

  const extractedTestSuites = data?.getAccount?.testSuites;

  const totalPages = getTotalPagesCount(data, itemsPerPage);
  const pageInfo = getPageInfo(data);
  const totalTestSuiteCount = data?.getAccount?.testSuites?.totalCount;

  function handlePageChange(cursor: string | null, isNext: boolean) {
    setPaginationInfo(currentInfo => ({
      isNext,
      currentPage: isNext ? currentInfo.currentPage + 1 : currentInfo.currentPage - 1,
      cursor
    }));
  }

  useEffect(() => {
    setPaginationInfo(currentInfo => ({
      ...currentInfo,
      cursor: null,
      currentPage: 1
    }));
  }, [accountId]);

  if (!accountId) {
    return <CircularProgress color="secondary" data-testid="testsuites-list-loader" className={classes.loader} />;
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="testsuites-list-error">
        Error {error.message}
      </Alert>
    );
  }

  return (
    <>
      <TestSuiteList
        testSuites={extractedTestSuites}
        isLoading={loading}
        totalTestSuiteCount={totalTestSuiteCount}
        refetchData={refetch}
      />
      {!loading && (
        <Grid container>
          <Grid item xs={12}>
            <Pagination
              pendoIdSuffix="test-suite-list"
              currentPage={paginationInfo.currentPage}
              totalPages={totalPages}
              handleNext={(): void => {
                handlePageChange(pageInfo.endCursor, true);
              }}
              handlePrevious={(): void => {
                handlePageChange(pageInfo.startCursor, false);
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
