import gql from "graphql-tag";

export const previousTestSuitesQuery = gql`
  query getPreviousTestSuites($accountId: ObjectID!, $itemsPerPage: Int!, $cursor: String) {
    getAccount(id: $accountId) {
      id
      testSuites(last: $itemsPerPage, before: $cursor, orderBy: [{ field: id, direction: DESC }]) {
        totalCount
        edges {
          cursor
          node {
            updatedAt
            clonedAt
            id
            name
            sitePrimary
            createdAt
            tests(last: 100) {
              totalCount
            }
            builds(first: 1, orderBy: [{ field: updatedAt, direction: DESC }]) {
              nodes {
                id
                createdAt
                updatedAt
                finishedAt
                failedTestCount
                warnedTestCount
                passedTestCount
                passed
                status
              }
            }
            testSuiteJiraIntegration {
              id
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
      }
    }
  }
`;
