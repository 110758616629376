import { useQuery } from "@apollo/react-hooks";
import { getTestSuitesBuildQuery } from "./getTestSuiteBuildQuery";
import {
  getTestSuiteBuilds,
  getTestSuiteBuildsVariables,
  getTestSuiteBuilds_node_TestSuite,
  OrderDirection
} from "./../../../generated-graphql-interfaces";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import { useEffect, useState } from "react";
import { defaultMergeOptions } from "../../../_common/utils/mergePageInfo/mergePageInfo";
import merge from "deepmerge";

export interface TestSuiteBuildsQueryParams {
  testSuiteId: string;
  startDate: string;
  endDate: string;
  first: number;
  after: string;
  order: OrderDirection;
}

function getPreviousResultsAsLastOne(data: getTestSuiteBuilds) {
  if (data.node?.__typename === "TestSuite") {
    // eslint-disable-next-line fp/no-mutation
    data.node.builds.pageInfo.hasNextPage = false;
  }
  return data;
}

// eslint-disable-next-line complexity
export function useTestSuiteBuildsQuery(
  params: TestSuiteBuildsQueryParams
): {
  loading: boolean;
  data: getTestSuiteBuilds | undefined;
  error: ApolloError | undefined;
  refetch: (variables?: getTestSuiteBuildsVariables | undefined) => Promise<ApolloQueryResult<getTestSuiteBuilds>>;
} {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [oldData, setOldData] = useState<getTestSuiteBuilds>();
  const [gatheredData, setGatheredData] = useState<getTestSuiteBuilds>();
  const { data, loading, fetchMore, error, refetch } = useQuery<getTestSuiteBuilds, getTestSuiteBuildsVariables>(
    getTestSuitesBuildQuery,
    {
      variables: { ...params },
      fetchPolicy: "cache-and-network"
    }
  );

  useEffect(() => {
    setOldData(gatheredData);
  }, [params, gatheredData]);

  useEffect(() => {
    setGatheredData(data);
  }, [data]);

  // eslint-disable-next-line max-lines-per-function, complexity
  async function evaluateMoreFetch() {
    if (
      params.first === 100 &&
      !loading &&
      !isFetchingMore &&
      data &&
      (data?.node as getTestSuiteBuilds_node_TestSuite).builds?.pageInfo.hasNextPage
    ) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          after: (data?.node as getTestSuiteBuilds_node_TestSuite)?.builds?.pageInfo.endCursor || ""
        },
        updateQuery: (previousResult: getTestSuiteBuilds, { fetchMoreResult }): getTestSuiteBuilds => {
          const newData =
            fetchMoreResult?.node?.__typename === "TestSuite"
              ? merge(previousResult, fetchMoreResult, defaultMergeOptions)
              : getPreviousResultsAsLastOne(previousResult);
          setGatheredData(newData);
          return newData;
        }
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }

  evaluateMoreFetch();

  const hasNextPage = !!(gatheredData?.node as getTestSuiteBuilds_node_TestSuite)?.builds?.pageInfo.hasNextPage;
  const gatheredDataLoading = loading || isFetchingMore || (gatheredData ? hasNextPage && params.first === 100 : true);
  const isLoading = oldData ? false : gatheredDataLoading;

  return {
    loading: isLoading,
    error,
    data: gatheredDataLoading ? oldData : gatheredData,
    refetch
  };
}
