import { FailPassWarnChartFilterSettings } from "./FailPassWarnChartFilter";
import { sub, format, add, isValid, isSameDay, startOfDay, endOfDay } from "date-fns";
import {
  getTestSuiteBuilds_node_TestSuite,
  getTestSuiteBuilds,
  OrderDirection
} from "../../../generated-graphql-interfaces";
import { TestSuiteBuildsQueryParams } from "../graphql/useTestSuiteBuildsQuery";
import { LineChartLineData } from "./LineChart";

export enum DurationSelection {
  LATEST_RUNS = "latest_runs",
  LAST_7_DAYS = "last_7_days",
  LAST_30_DAYS = "last_30_days",
  CUSTOM_SELECTION = "custom_selection"
}

export interface DataPoint {
  x: string | number | Date;
  y: number | null;
}

export const durationOptions = [
  {
    label: "Latest Runs",
    id: DurationSelection.LATEST_RUNS
  },
  {
    label: "Last 7 days",
    id: DurationSelection.LAST_7_DAYS
  },
  {
    label: "Last 30 days",
    id: DurationSelection.LAST_30_DAYS
  },
  {
    label: "Custom date range",
    id: DurationSelection.CUSTOM_SELECTION
  }
];

export const dateFormat = "yyyy-MM-dd HH:mm";

function getTestSuiteId(id: string | undefined) {
  return id || "";
}

export function getValidDateFromFilterSettings(date: Date | null, defaultDate: Date) {
  return date !== null && isValid(date) ? date : defaultDate;
}

export function getTimeAdjustedDates(startDate: Date, endDate: Date) {
  if (isSameDay(startDate, endDate)) {
    return {
      adjustedStartDate: startOfDay(startDate),
      adjustedEndDate: endOfDay(endDate)
    };
  }
  return { adjustedStartDate: startDate, adjustedEndDate: endDate };
}

// eslint-disable-next-line complexity
export function getParamsForQuery(filterSettings: FailPassWarnChartFilterSettings): TestSuiteBuildsQueryParams {
  const defautEnd = add(new Date(), { days: 1 });
  const defaultStart = new Date("1970-01-01");
  const defaultParams: TestSuiteBuildsQueryParams = {
    after: "",
    first: 100,
    endDate: format(defautEnd, dateFormat),
    startDate: format(defaultStart, dateFormat),
    testSuiteId: getTestSuiteId(filterSettings.testSuiteId),
    order: OrderDirection.ASC
  };
  switch (filterSettings.durationSelection) {
    case DurationSelection.LATEST_RUNS:
      return {
        ...defaultParams,
        first: 30,
        order: OrderDirection.DESC
      };
    case DurationSelection.LAST_7_DAYS:
      return {
        ...defaultParams,
        startDate: format(sub(new Date(), { days: 7 }), dateFormat)
      };
    case DurationSelection.LAST_30_DAYS:
      return {
        ...defaultParams,
        startDate: format(sub(new Date(), { days: 30 }), dateFormat)
      };
    case DurationSelection.CUSTOM_SELECTION:
      const startDate = getValidDateFromFilterSettings(filterSettings.customDuration.start, defaultStart);
      const endDate = getValidDateFromFilterSettings(filterSettings.customDuration.end, defautEnd);
      const { adjustedStartDate, adjustedEndDate } = getTimeAdjustedDates(startDate, endDate);

      return {
        ...defaultParams,
        startDate: format(adjustedStartDate, dateFormat),
        endDate: format(adjustedEndDate, dateFormat)
      };
    default:
      return defaultParams;
  }
}

function getStartingPoint(filterSettings: FailPassWarnChartFilterSettings): DataPoint | undefined {
  if (filterSettings.durationSelection === DurationSelection.LAST_7_DAYS) {
    return { x: sub(new Date(), { days: 7 }).toISOString(), y: null };
  } else if (filterSettings.durationSelection === DurationSelection.LAST_30_DAYS) {
    return { x: sub(new Date(), { days: 30 }).toISOString(), y: null };
  }

  return undefined;
}

function getEndingPoint(filterSettings: FailPassWarnChartFilterSettings): DataPoint | undefined {
  if (
    filterSettings.durationSelection === DurationSelection.LAST_7_DAYS ||
    filterSettings.durationSelection === DurationSelection.LAST_30_DAYS
  ) {
    return { x: new Date().toISOString(), y: null };
  }
  return undefined;
}

function getWarnedData(data: getTestSuiteBuilds | undefined): Array<DataPoint> {
  return (data?.node as getTestSuiteBuilds_node_TestSuite)?.builds.edges.map(build => {
    return {
      x: build.node.finishedAt as string,
      y: build.node.warnedTestCount
    };
  });
}

function getFailData(data: getTestSuiteBuilds | undefined): Array<DataPoint> {
  return (data?.node as getTestSuiteBuilds_node_TestSuite)?.builds.edges.map(build => {
    return {
      x: build.node.finishedAt,
      y: build.node.failedTestCount || 0
    };
  });
}

function getPassData(data: getTestSuiteBuilds | undefined): Array<DataPoint> {
  return (data?.node as getTestSuiteBuilds_node_TestSuite)?.builds.edges.map(build => {
    return {
      x: build.node.finishedAt,
      y: build.node.passedTestCount || 0
    };
  });
}

function processDataForUse(
  data: getTestSuiteBuilds | undefined,
  points: {
    startingPoint: DataPoint | undefined;
    endingPoint: DataPoint | undefined;
  },
  processingFunction: (data: getTestSuiteBuilds | undefined) => DataPoint[]
) {
  const dataToPass: Array<DataPoint> = points.startingPoint
    ? [points.startingPoint].concat(processingFunction(data))
    : processingFunction(data);
  return points.endingPoint ? dataToPass.concat([points.endingPoint]) : dataToPass;
}

function prepareDataForUse(data: getTestSuiteBuilds | undefined, filterSettings: FailPassWarnChartFilterSettings) {
  const startingPoint = getStartingPoint(filterSettings);
  const endingPoint = getEndingPoint(filterSettings);
  const warnDataToUse = processDataForUse(data, { startingPoint, endingPoint }, getWarnedData);
  const failDataToUse = processDataForUse(data, { startingPoint, endingPoint }, getFailData);
  const passDataToUse = processDataForUse(data, { startingPoint, endingPoint }, getPassData);
  return { passDataToUse, warnDataToUse, failDataToUse };
}

export function transformGetTestSuiteBuildResponsetoChartData(
  data: getTestSuiteBuilds | undefined,
  filterSettings: FailPassWarnChartFilterSettings
): LineChartLineData[] {
  const { passDataToUse, warnDataToUse, failDataToUse } = prepareDataForUse(data, filterSettings);
  return [
    {
      id: "Warnings",
      color: "#DDA200",
      data: filterSettings.show.warnings ? warnDataToUse : []
    },
    {
      id: "Fails",
      color: "#CB0000",
      data: filterSettings.show.fails ? failDataToUse : []
    },
    {
      id: "Passes",
      color: "#5F7E2B",
      data: filterSettings.show.passes ? passDataToUse : []
    }
  ];
}

export function getAvaliableTestSuites(
  testSuites?: {
    id: string;
    name: string;
  }[]
) {
  return testSuites ? testSuites : [];
}
