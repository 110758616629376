import React from "react";
import { ThresholdType, Severity, ThresholdPredicate } from "../../../generated-graphql-interfaces";
import { TestThresholdToggle } from "../TestThresholdToggle/TestThresholdToggle";
import { Grid, Box, TextField, Typography, Select, makeStyles, MenuItem } from "@material-ui/core";
import { isAbsoluteThresholdInError, getHelperTextForAbsoluteThreshold } from "./TestThresholdUtils";
import { getMenuProps } from "../../visualOverrides/selectVisualOverrides";
import { TestThresholdPredicate, Threshold } from "../TestCollapse/TestCollapse";
import { DeepCrawlSlider } from "../DeepCrawlSlider/DeepCrawlSlider";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(2)
  },
  descriptionStart: {
    marginTop: theme.spacing(2)
  },
  descriptionStartAbsolute: {
    marginTop: theme.spacing(1)
  },
  thresholdPredicate: {
    marginBottom: theme.spacing(2)
  },
  selectThresholdPredicate: {
    width: "100%",
    "& div": {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`
    }
  },
  selectThresholdPredicateAbsolute: {
    width: "100%"
  },
  testSeverityText: {
    fontWeight: 500,
    marginBottom: theme.spacing(3)
  }
}));

const avaliableThresholdPredicates: TestThresholdPredicate[] = [
  {
    label: "greater than or equal to",
    value: ThresholdPredicate.GreaterThanOrEqual,
    default: true
  },
  {
    label: "less than",
    value: ThresholdPredicate.LessThan,
    default: false
  }
];

interface TestThresholdProps {
  threshold: Threshold;
  severity: Severity;
  onChange: (threshold: Threshold) => void;
  testCode: string;
}
// eslint-disable-next-line max-lines-per-function
export function TestThreshold(props: TestThresholdProps): JSX.Element {
  const classes = useStyles();
  const { threshold, severity, onChange } = props;
  const severityText = severity === "Fail" ? "fail" : "warn";
  const isThresholdRelative = threshold.type === ThresholdType.Relative;
  const severityDescription = severity === "Fail" ? "Failure" : "Warning";
  const mainTextMessage = isThresholdRelative ? `${threshold.relative}%` : `${threshold.absolute} URL(s)`;
  const informationMessage = `The test will ${severityText} if the number of results is`;

  function handleThresholdTypeChange(thresholdType: ThresholdType): void {
    const newThresholdObj = { ...threshold, type: thresholdType };
    onChange(newThresholdObj);
  }

  function handleThresholdPredicateChange(thresholdPredicate: ThresholdPredicate | undefined | unknown) {
    if (
      thresholdPredicate === ThresholdPredicate.GreaterThanOrEqual ||
      thresholdPredicate === ThresholdPredicate.LessThan
    ) {
      const newThresholdObj = { ...threshold, predicate: thresholdPredicate };
      onChange(newThresholdObj);
    }
  }

  function handleThresholdValueChange(newValue: number | number[], thresholdType: ThresholdType): void {
    const isRelativeThreshold = thresholdType === ThresholdType.Relative;
    const inputValue = typeof newValue === "number" ? newValue : newValue[0];
    const newThresholdObj = isRelativeThreshold
      ? { ...threshold, relative: inputValue }
      : { ...threshold, absolute: inputValue };
    onChange(newThresholdObj);
  }

  function renderThresholdPredicament(classes: string) {
    return (
      <Select
        variant="filled"
        onChange={event => handleThresholdPredicateChange(event?.target?.value)}
        className={classes}
        defaultValue={avaliableThresholdPredicates.filter(predicate => predicate.default)[0]}
        value={props.threshold.predicate}
        data-testid="test-threshold-predicament-select"
        data-cy="test-threshold-predicament-select"
        data-pendo="auto-test-suite-edit-choose-tests-test-predicament-select"
        inputProps={{
          "data-testid": "test-threshold-predicament-select-input"
        }}
        MenuProps={getMenuProps()}
      >
        {avaliableThresholdPredicates.map(predicate => {
          return (
            <MenuItem key={predicate.label.toLowerCase()} value={predicate.value}>
              {predicate.label}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  return (
    <div data-testid="test-threshold" className={classes.wrapper}>
      <Box mb={4}>
        <TestThresholdToggle threshold={threshold} handleThresholdTypeChange={handleThresholdTypeChange} />
      </Box>
      <Typography color="textPrimary" className={classes.testSeverityText} data-testid="test-threshold-main-text">
        {severityDescription} threshold: <span>{mainTextMessage}</span>
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          {isThresholdRelative ? (
            <Box component="div" data-testid="test-threshold-body-relative">
              <DeepCrawlSlider
                id={`${props.testCode}-relative-threshold-slider`}
                valueLabelDisplay="auto"
                onChange={(event, newValue) => {
                  handleThresholdValueChange(newValue, ThresholdType.Relative);
                }}
                value={threshold.relative}
                step={1}
                min={1}
                max={100}
                color="secondary"
                data-cy="test-severity-threshold-slider"
                data-testid="test-severity-threshold-slider"
                data-pendo={`auto-test-suite-edit-choose-tests-test-slider`}
              />
              <Grid container>
                <Grid item xs={7}>
                  <Typography
                    className={classes.descriptionStart}
                    variant="body1"
                    data-testid="test-threshold-information"
                  >
                    {informationMessage}
                  </Typography>
                </Grid>
                <Grid item xs={5} container>
                  <Grid item xs={12} className={classes.thresholdPredicate}>
                    {renderThresholdPredicament(classes.selectThresholdPredicate)}
                  </Grid>
                  <Grid item xs={12} data-testid="test-threshold-summary-info">
                    {`${threshold.relative}% of crawled pages.`}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Grid
              container
              alignContent="center"
              justify="space-between"
              spacing={1}
              data-testid="test-threshold-body-absolute"
            >
              <Grid item xs={4}>
                <Typography
                  className={classes.descriptionStartAbsolute}
                  variant="body1"
                  data-testid="test-threshold-information"
                >
                  {informationMessage}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {renderThresholdPredicament(classes.selectThresholdPredicateAbsolute)}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.thresholdPredicate}
                  type="number"
                  variant="filled"
                  label="URL(s) *"
                  id={`${props.testCode}-absolute-threshold-input`}
                  data-pendo={`auto-test-suite-edit-choose-tests-test-threshold-value`}
                  data-testid={`${props.testCode}-absolute-threshold-input`}
                  data-cy="absolute-threshold-input"
                  value={threshold.absolute || ""}
                  error={isAbsoluteThresholdInError(threshold)}
                  helperText={getHelperTextForAbsoluteThreshold(threshold)}
                  onChange={e => {
                    handleThresholdValueChange(Math.max(parseInt(e.target.value), 1), ThresholdType.Absolute);
                  }}
                />
                <Grid item xs={12} data-testid="test-threshold-summary-info">
                  {`of crawled pages.`}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
