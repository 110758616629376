import { BuildTestListItemProps } from "../components/BuildTestListItem";
import { getBuildTestProcessedValue } from "../../../_common/utils/getBuildTestProcessedValue/getBuildTestProcessedValue";
import { getBuildTestStatus } from "../../../_common/utils/getBuildTestStatus/getBuildTestStatus";
import { BuildTriggerType, getBuildTests_node_TestSuite_builds_nodes } from "../../../generated-graphql-interfaces";
import { differenceToHumanReadableFormatFromStrings } from "../../../_common/utils/date/date";

type Build = getBuildTests_node_TestSuite_builds_nodes;

function getCiBuildId(ciBuildId: string | null): string {
  return ciBuildId || "Not provided";
}

function getTestCount(testCount: number | null): number {
  return testCount || 0;
}

export enum SchedulerTextOptions {
  SCHEDULED = "Scheduled",
  API = "API triggered",
  MANUAL = "Manually triggered"
}

function getBuildTriggerType(trigger: BuildTriggerType | null) {
  switch (trigger) {
    case BuildTriggerType.CI:
      return SchedulerTextOptions.API;
    case BuildTriggerType.Schedule:
      return SchedulerTextOptions.SCHEDULED;
    case BuildTriggerType.Manual:
      return SchedulerTextOptions.MANUAL;
    default:
      return SchedulerTextOptions.API;
  }
}

export function buildTestItemInfoFactory(build: Build, testSuiteId: string): BuildTestListItemProps {
  const status = getBuildTestStatus(build);
  const duration = differenceToHumanReadableFormatFromStrings(build.createdAt as string, build.finishedAt as string);
  const buildId = getCiBuildId(build.ciBuildId);
  const failedTests = getTestCount(build.failedTestCount);
  const warningTests = getTestCount(build.warnedTestCount);
  const passedTests = getTestCount(build.passedTestCount);
  const totalTests = failedTests + passedTests + warningTests;
  return {
    id: build.id,
    testSuiteId,
    buildId,
    status,
    duration: getBuildTestProcessedValue(duration, status),
    lastRun: getBuildTestProcessedValue(build.finishedAt, status),
    totalTests: getBuildTestProcessedValue(totalTests, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    triggerType: getBuildTriggerType(build.triggerType),
    updatedAt: build.updatedAt
  };
}
