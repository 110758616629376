import { getTestSuite_node_TestSuite, TestSuiteCrawlType } from "../../../../../generated-graphql-interfaces";
import { arrayToTextAreaValue } from "../../../../../_common/utils/array/coerce/arrayToTextAreaValue";
import { CustomExtraction } from "../CustomExtractions";

function getTestSuiteCrawlTypes(testSuite: getTestSuite_node_TestSuite) {
  const types = {
    web: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.Web) !== -1,
    list: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.List) !== -1,
    sitemap: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.Sitemap) !== -1,
    google_analytics: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.GoogleAnalytics) !== -1,
    backlinks: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.Backlinks) !== -1,
    google_search_console: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.GoogleSearchConsole) !== -1,
    log_summary: testSuite.crawlTypes.indexOf(TestSuiteCrawlType.LogSummary) !== -1
  };
  return types;
}

export function removeTypenameFromCustomExtractions(customExtractions: CustomExtraction[]) {
  return customExtractions.map(({ label, regex }) => {
    return { label, regex };
  });
}

export function getInitialValuesFromTestSuite(testSuite: getTestSuite_node_TestSuite) {
  return {
    crawlRate: Math.min(testSuite.crawlRate, testSuite.account.maxCrawlRate),
    limitPagesMax: testSuite.limitPagesMax,
    rendererBlockAds: testSuite.rendererBlockAds,
    rendererBlockAnalytics: testSuite.rendererBlockAnalytics,
    emptyPageThreshold: testSuite.emptyPageThreshold,
    highLogSummaryRequests: testSuite.highLogSummaryRequests,
    lowLogSummaryRequests: testSuite.lowLogSummaryRequests,
    maxContentSize: testSuite.maxContentSize,
    maxDescriptionLength: testSuite.maxDescriptionLength,
    maxExternalLinks: testSuite.maxExternalLinks,
    maxHtmlSize: testSuite.maxHtmlSize,
    maxLinks: testSuite.maxLinks,
    maxLoadTime: testSuite.maxLoadTime,
    maxRedirections: testSuite.maxRedirections,
    maxTitleWidth: testSuite.maxTitleWidth,
    maxUrlLength: testSuite.maxUrlLength,
    duplicatePrecision: testSuite.duplicatePrecision,
    minDescriptionLength: testSuite.minDescriptionLength,
    minTitleLength: testSuite.minTitleLength,
    thinPageThreshold: testSuite.thinPageThreshold,
    crawlTypes: getTestSuiteCrawlTypes(testSuite),
    rendererBlockCustom: arrayToTextAreaValue(testSuite.rendererBlockCustom),
    rendererJsString: testSuite.rendererJsString || "",
    rendererJsUrls: arrayToTextAreaValue(testSuite.rendererJsUrls),
    useRobotsOverwrite: !!testSuite.useRobotsOverwrite,
    robotsOverwrite: testSuite.robotsOverwrite || "",
    urlsExcluded: arrayToTextAreaValue(testSuite.urlsExcluded),
    urlsIncluded: arrayToTextAreaValue(testSuite.urlsIncluded),
    useRenderer: testSuite.useRenderer.toString(),
    customExtractions: testSuite.customExtractions || [],
    startUrls: testSuite.startUrls?.length ? testSuite.startUrls[0] : ""
  };
}

function getArrayForCrawlType(enabled: boolean, value: TestSuiteCrawlType) {
  return enabled ? [value] : [];
}

export function getArrayOfCrawlTypes(types: {
  web: boolean;
  list: boolean;
  sitemap: boolean;
  google_analytics: boolean;
  backlinks: boolean;
  google_search_console: boolean;
  log_summary: boolean;
}) {
  const arr: TestSuiteCrawlType[] = [];
  const arrayForWeb: TestSuiteCrawlType[] = getArrayForCrawlType(types.web, TestSuiteCrawlType.Web);
  const arrayForList: TestSuiteCrawlType[] = getArrayForCrawlType(types.list, TestSuiteCrawlType.List);
  const arrayForSitemap: TestSuiteCrawlType[] = getArrayForCrawlType(types.sitemap, TestSuiteCrawlType.Sitemap);
  const arrayForGA: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.google_analytics,
    TestSuiteCrawlType.GoogleAnalytics
  );
  const arrayForBacking: TestSuiteCrawlType[] = getArrayForCrawlType(types.backlinks, TestSuiteCrawlType.Backlinks);
  const arrayForGSC: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.google_search_console,
    TestSuiteCrawlType.GoogleSearchConsole
  );
  const arrayForBLogSummary: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.log_summary,
    TestSuiteCrawlType.LogSummary
  );
  return arr.concat(
    ...arrayForWeb,
    ...arrayForList,
    ...arrayForSitemap,
    ...arrayForGA,
    ...arrayForBacking,
    ...arrayForGSC,
    ...arrayForBLogSummary
  );
}
