import { Box, makeStyles, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles(theme => ({
  listPaper: {
    minHeight: "688px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down("md")]: {
      minHeight: "0px",
      overflow: "hidden",
      paddingBottom: theme.spacing(2)
    },
    padding: 20
  }
}));

export function SchedulerDetailLoadingView() {
  const classes = useStyles();

  return (
    <Paper className={classes.listPaper} data-testid="schedule-detail-loader">
      <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: 28 }}>
        <Skeleton variant="text" height={40} width={200} />
        <Box display="flex" alignItems="center">
          <Skeleton variant="circle" height={46} width={46} />
          <Skeleton variant="circle" height={46} width={46} style={{ marginLeft: 8 }} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" style={{ marginBottom: 44 }}>
        <Skeleton variant="text" height={20} width={200} style={{ marginRight: 64 }} />
        <Skeleton variant="text" height={20} width={200} />
      </Box>
      <Skeleton variant="text" height={30} width={220} style={{ marginBottom: 24 }} />
      <Skeleton variant="rect" height={300} width="100%" />
    </Paper>
  );
}
