import React from "react";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import { formatSchedulingInterval, getTimeDescription } from "./../utils/schedulerTime";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import clsx from "clsx";
import { getBuildSchedules_getAccount_buildSchedules_nodes } from "../../../generated-graphql-interfaces";

const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    alignItems: "center",
    minHeight: "107px",
    padding: theme.spacing(0, 3.5, 0, 3.5),
    paddingLeft: theme.spacing(3),
    cursor: "pointer",
    borderLeft: `4px solid ${theme.palette.navy[50]}`,
    transition: "all 0.2s ease-in-out",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    "&:hover": {
      backgroundColor: theme.palette.purple[50]
    }
  },
  selected: {
    transition: "all 0.2s ease-in-out",
    background: theme.palette.purple[50],
    borderLeft: "4px solid " + theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.purple[50],
      borderLeft: "4px solid " + theme.palette.secondary.main
    }
  },
  grid: {
    display: "flex",
    alignItems: "center"
  },
  name: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.navy[500],
    paddingBottom: "3px"
  },
  text: {
    fontWeight: 300,
    color: theme.palette.navy[300],
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "3px"
  },
  time: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    color: theme.palette.navy[300]
  },
  boxInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left"
  },
  testSuitesBox: {
    margin: "auto",
    textAlign: "center",
    minHeight: "30px",
    display: "flex",
    alignItems: "center"
  },
  testSuiteConnectedNumber: {
    width: "100%",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.navy[500]
  },
  icon: {
    color: theme.palette.navy[200],
    fontSize: "34px",
    textAlign: "right"
  },
  arrowGap: {
    flex: "1 0 40px",
    paddingRight: "0px",
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      marginRight: -theme.spacing(1.8) // this is specific cause of the icon.
    }
  }
}));

export interface SchedulerListItemProps {
  scheduler: getBuildSchedules_getAccount_buildSchedules_nodes;
  onSelect: (schedulerId: string) => void;
  selected: boolean;
}

export function SchedulerListItem(props: SchedulerListItemProps) {
  const classes = useStyles();
  const timeDescription = getTimeDescription(props.scheduler.startAt);
  const frequency = formatSchedulingInterval(new Date(props.scheduler.startAt), props.scheduler.repetitionRate);

  return (
    <Box
      className={clsx(classes.box, props.selected && classes.selected)}
      onClick={() => props.onSelect(props.scheduler.id)}
      data-testid="scheduler-list-item"
      data-pendo="auto-scheduler-list-item"
    >
      <Grid container className={classes.grid}>
        <Grid item container xs={10} className={classes.boxInfo}>
          <Typography variant="h3" className={classes.name} data-testid="scheduler-list-item-name">
            {props.scheduler.name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Typography className={classes.text}>Starts:</Typography>
              <Typography className={classes.text}>Repeat:</Typography>
            </Box>
            <Box>
              <Typography className={classes.text} data-testid="scheduler-list-item-time">
                {timeDescription}
              </Typography>
              <Typography className={classes.text} data-testid="scheduler-list-item-frequency">
                {frequency}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.testSuitesBox}>
            <Typography
              variant="body1"
              className={classes.testSuiteConnectedNumber}
              data-testid="scheduler-list-item-testsuites"
            >
              {props.scheduler.buildScheduleTestSuites.totalCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.arrowGap}>
          <ChevronRightRoundedIcon className={classes.icon} data-testid="scheduler-list-item-goin" />
        </Grid>
      </Grid>
    </Box>
  );
}
