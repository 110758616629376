import { Routes } from "../../_common/routes/routes";
import { BreadcrumbPaths } from "../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";

export function getBreadcrumbContent(accountId: string) {
  return [
    { text: BreadcrumbPaths.MyTestSuites, link: Routes.TestSuites.getUrl({ accountId }) },
    {
      text: BreadcrumbPaths.TestSuiteRuns
    }
  ];
}
