import React from "react";
import { makeStyles, Paper, Grid, Box, CircularProgress, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";

const useTooltipStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    border: `1px ${theme.palette.grey[50]}`,
    boxShadow: "1px 2px 7px 3px rgba(215,215,215,0.5)",
    padding: theme.spacing(2),
    width: "350px",
    maxWidth: "1000px"
  },
  arrow: {
    color: theme.palette.primary.light
  }
}));

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "130px",
    minHeight: "68px",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  informationWrapper: {
    padding: "8px 13px 8px 19px"
  },
  informationWrapperWithoutIcon: {
    padding: "8px 19px 8px 19px"
  },
  label: {
    fontSize: "12px",
    lineHeight: "14px",
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.navy[300],
    minHeight: "28px"
  },
  value: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: theme.palette.navy[300]
  },
  checkIcon: {
    color: theme.palette.deepcrawlGreen.main
  },
  warningIcon: {
    color: theme.palette.deepcrawlOrange.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  tooltip: {
    maxWidth: "150px"
  }
}));

export enum InformationBoxIcon {
  WARNING = "warning",
  ERROR = "error",
  CHECK = "check"
}

export interface InformationBoxProps {
  label: string;
  value: string | undefined;
  icon?: InformationBoxIcon;
  isLoadingValue?: boolean;
  testId?: string;
  tooltip: string;
  pendoId: string;
}

// eslint-disable-next-line max-lines-per-function
export function InformationBox(props: InformationBoxProps) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  function getIconStyles() {
    switch (props.icon) {
      case InformationBoxIcon.CHECK:
        return classes.checkIcon;
      case InformationBoxIcon.ERROR:
        return classes.errorIcon;
      case InformationBoxIcon.WARNING:
        return classes.warningIcon;
      default:
        return "";
    }
  }

  function getClassForInformationWrapper() {
    return props.icon ? classes.informationWrapper : classes.informationWrapperWithoutIcon;
  }

  function renderIcon() {
    const cssClass = getIconStyles();
    switch (props.icon) {
      case InformationBoxIcon.CHECK:
        return (
          <CheckCircleIcon
            data-testid={`information-box-icon-check`}
            data-cy={`information-box-icon-check`}
            className={cssClass}
          />
        );
      case InformationBoxIcon.ERROR:
        return (
          <CancelIcon
            className={cssClass}
            data-testid={`information-box-icon-error`}
            data-cy={`information-box-icon-error`}
          />
        );
      case InformationBoxIcon.WARNING:
        return (
          <WarningIcon
            className={cssClass}
            data-testid={`information-box-icon-warning`}
            data-cy={`information-box-icon-warning`}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <Tooltip arrow placement="top" title={props.tooltip} classes={tooltipClasses}>
      <Paper
        elevation={0}
        className={classes.paper}
        data-testid={props.testId ? props.testId : "information-box"}
        data-cy={props.testId ? props.testId : "information-box"}
        data-pendo={`auto-dashboard-seo-health-widget-${props.pendoId}`}
      >
        <Grid container className={getClassForInformationWrapper()}>
          <Grid item container direction="column" xs={props.icon ? 8 : 12}>
            <Grid item className={classes.label} data-testid="information-box-label" data-cy="information-box-label">
              {props.label}
            </Grid>
            <Grid item className={classes.value} data-testid="information-box-value" data-cy="information-box-value">
              {props.isLoadingValue ? <CircularProgress size={14} /> : props.value}
            </Grid>
          </Grid>
          {props.icon && (
            <Grid item xs={4} container justify="flex-end">
              <Box>{renderIcon()}</Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Tooltip>
  );
}
