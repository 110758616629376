import React from "react";
import { Typography, Button, makeStyles, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import noPermissionsGraphic from "../../images/no-permissions-graphic.svg";
import { Routes } from "../../_common/routes/routes";

const useStyles = makeStyles(theme => ({
  wrapper: { maxWidth: "500px", margin: "0 auto", textAlign: "center" },
  title: { color: "rgba(0, 0, 0, 0.6)" },
  text: {
    fontSize: "1.25rem",
    marginBottom: theme.spacing(3),
    color: "rgba(0, 0, 0, 0.6)"
  },
  link: { textDecoration: "none" },
  contactLink: { color: "rgba(0, 0, 0, 0.6)" }
}));

export function NoPermissionMessage() {
  const classes = useStyles();
  const accountId = useAccountRouteMatch();

  return (
    <div className={classes.wrapper} data-testid="no-permissions-wrapper">
      <img src={noPermissionsGraphic} alt="No Permissions Graphic" data-testid="no-permissions-graphic" />
      <h1 className={classes.title} data-testid="no-permissions-msg-title">
        Looks like you don&apos;t have permission to access this page
      </h1>
      <Typography className={classes.text} data-testid="no-permissions-msg-body">
        Please speak to your account admin or{" "}
        <a href="mailto:support@deepcrawl.com" className={classes.contactLink}>
          contact support
        </a>{" "}
        if you need help.
      </Typography>
      <Box>
        <Link
          to={Routes.Dashboard.getUrl({ accountId })}
          className={classes.link}
          data-testid="no-permissions-msg-link"
        >
          <Button variant="contained" color="secondary" data-testid="no-permissions-msg-btn">
            Go to dashboard
          </Button>
        </Link>
      </Box>
    </div>
  );
}
