import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20.7857 4.18164H2.4132C1.91777 4.18164 1.51611 4.57953 1.51611 5.07032C1.51611 5.56111 1.91777 5.959 2.4132 5.959H20.7857C21.2812 5.959 21.6828 5.56111 21.6828 5.07032C21.6828 4.57953 21.2812 4.18164 20.7857 4.18164Z" />
      <path d="M18.7444 4.18164C18.2489 4.18164 17.8473 4.57953 17.8473 5.07032V19.2262C17.8473 19.8512 17.334 20.3597 16.703 20.3597H6.49607C5.86509 20.3597 5.35179 19.8512 5.35179 19.2262V5.07032C5.35179 4.57953 4.95013 4.18164 4.4547 4.18164C3.95927 4.18164 3.55762 4.57953 3.55762 5.07032V19.2262C3.55762 20.8313 4.87579 22.1371 6.49611 22.1371H16.7031C18.3233 22.1371 19.6416 20.8313 19.6416 19.2262V5.07032C19.6415 4.57953 19.2399 4.18164 18.7444 4.18164Z" />
      <path d="M13.6409 0.136719H9.55812C7.93784 0.136719 6.61963 1.44254 6.61963 3.04768V5.06996C6.61963 5.56075 7.02128 5.95864 7.51671 5.95864C8.01214 5.95864 8.4138 5.56075 8.4138 5.06996V3.04768C8.4138 2.42261 8.92714 1.91412 9.55808 1.91412H13.6408C14.2718 1.91412 14.7851 2.42266 14.7851 3.04768V5.06996C14.7851 5.56075 15.1867 5.95864 15.6822 5.95864C16.1777 5.95864 16.5793 5.56075 16.5793 5.06996V3.04768C16.5793 1.44258 15.2612 0.136719 13.6409 0.136719Z" />
      <path d="M9.55822 9.23633C9.06279 9.23633 8.66113 9.63422 8.66113 10.125V16.1918C8.66113 16.6826 9.06279 17.0805 9.55822 17.0805C10.0536 17.0805 10.4553 16.6826 10.4553 16.1918V10.125C10.4553 9.63422 10.0537 9.23633 9.55822 9.23633Z" />
      <path d="M13.6412 9.23633C13.1458 9.23633 12.7441 9.63422 12.7441 10.125V16.1918C12.7441 16.6826 13.1458 17.0805 13.6412 17.0805C14.1367 17.0805 14.5383 16.6826 14.5383 16.1918V10.125C14.5383 9.63422 14.1367 9.23633 13.6412 9.23633Z" />
    </SvgIcon>
  );
}
