import { makeStyles } from "@material-ui/core";
import { TestSuiteListItemNotYetRunProps } from "./TestSuiteListItemNotYetRun";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";

export const useStyles = makeStyles(theme => ({
  border: (props: TestSuiteListItemNotYetRunProps) => {
    return {
      width: "6px",
      backgroundColor: getMatchingBorderColorForStatus(props.status, theme),
      flexShrink: 0
    };
  },
  rootCompact: {
    boxShadow: "none",
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.navy[200]}`,
    borderBottom: `1px solid ${theme.palette.navy[200]}`,
    borderRight: `1px solid ${theme.palette.navy[200]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: "5px"
  },
  wrapper: {
    display: "flex",
    borderRadius: "4px",
    overflow: "hidden",
    marginBottom: "10px",
    boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.04)"
  },
  testSuiteId: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.navy[300]
  }
}));
