import { BuildTestStatus } from "../../interfaces/BuildTestStatus";
import {
  BuildStatus,
  getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild
} from "../../../generated-graphql-interfaces";
import { getPassWarnOrFail } from "../../../pages/Dashboard/utils/getPassWarnOrFail";
import { BuildTest } from "../testSuiteItemInformationFactory/testSuiteItemInformationFactory";

export function getBuildTestStatus(
  build: BuildTest | getBuildScheduleWithTestSuite_node_BuildSchedule_buildScheduleTestSuites_nodes_lastRunBuild
): BuildTestStatus {
  if (build.status === BuildStatus.Finished) {
    return getPassWarnOrFail(build.passed, build.warnedTestCount);
  }
  return build.status;
}
