import { CircularProgress, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { getMenuProps } from "../../../../../_common/visualOverrides/selectVisualOverrides";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useGetJiraProjectsQuery } from "../graphql/useGetJiraProjectsQuery";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  outline: {
    color: theme.palette.primary.dark,
    paddingTop: 15,
    paddingBottom: 14,
    paddingLeft: 11,
    paddingRight: theme.spacing(2),
    lineHeight: 1
  },
  formControl: {
    "& fieldset": {
      border: "none",
      borderRadius: "4px"
    },
    "& svg": {
      color: theme.palette.primary.dark
    },
    "& legend": {
      maxWidth: "0px"
    },
    "& label.Mui-focused": {
      color: theme.palette.navy[200]
    }
  }
}));

interface IJiraProjectSelectProps {
  jiraAuthenticationId: string;
  jiraProjectId: string | null;
  onSelect: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

// eslint-disable-next-line complexity
export function JiraProjectSelect(props: IJiraProjectSelectProps): JSX.Element {
  const classes = useStyles(props);
  const { jiraAuthenticationId, jiraProjectId, onSelect } = props;
  const { data, loading, error } = useGetJiraProjectsQuery({ jiraAuthenticationId });

  return (
    <>
      {loading && <CircularProgress color="secondary" />}

      {error && (
        <Alert severity="error" data-testid="error-alert">
          There was an error connecting. Please refresh the page and try again.
        </Alert>
      )}

      {!loading && !error && (
        <FormControl
          variant="outlined"
          fullWidth
          classes={{ root: classes.formControl }}
          data-testid="project-select-wrapper"
        >
          <InputLabel id="project-select-label">Jira Project</InputLabel>

          <Select
            IconComponent={ExpandMoreRoundedIcon}
            classes={{ outlined: classes.outline }}
            labelId="project-select-label"
            id="project-select-dropdown"
            data-testid="project-select-dropdown"
            data-pendo="auto-global-jira-project-select"
            value={jiraProjectId}
            onChange={onSelect}
            label="Jira Project"
            MenuProps={getMenuProps()}
          >
            {data?.getJiraProjects.map(project => (
              <MenuItem key={project.id} value={project.id}>
                [{project.key}] - {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
