import React from "react";
import { TestSuiteListItemProps, TestSuiteListItem } from "./TestSuiteListItem";
import { Box, Paper, Grid, Typography } from "@material-ui/core";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { useStyles } from "./TestSuiteCompactListStyles";
import { automatorSdkUrl } from "../../../_common/components/CiCdForm/CiCdFormUtils";
import { CiCdInstructions } from "../../../_common/components/CiCdInstructions/CiCdInstructions";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";

export interface TestSuiteCompactListProps {
  testRuns: TestSuiteListItemProps[];
  refetchData: () => void;
}

export function TestSuiteCompactList(props: TestSuiteCompactListProps): JSX.Element {
  const classes = useStyles();

  function getTestSuiteCypressId(suite: TestSuiteListItemProps) {
    const splitterString = "Copy of ";
    const suiteNameWithoutFrontTimestamp = suite.name.split(splitterString);
    const usableName =
      suiteNameWithoutFrontTimestamp.length > 1 ? splitterString + suiteNameWithoutFrontTimestamp[1] : suite.name;
    return `suite-${usableName.trim().toLocaleLowerCase().split(" ").join("_")}`;
  }

  return (
    <Paper data-testid="test-suite-compact-list">
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <>
            {Array.isArray(props.testRuns) && props.testRuns.length > 0 && props.testRuns[0].status === "NotYetRun" && (
              <Grid data-testid="information-block" container className={classes.gridContainer} spacing={2}>
                <Grid item container xs={12} lg={7}>
                  <Grid item xs={12} lg={2} className={classes.notRunIconWrapper}>
                    <BuildStatusIcon status={props.testRuns[0].status} size={StatusIconSize.XL} />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Box my={2}>
                      <h2 className={classes.title} data-testid="information-header">
                        No runs/builds for your test suite detected yet
                      </h2>
                    </Box>
                    <Typography color="textPrimary" className={classes.subtitle} data-testid="information-reminder">
                      Instructions:
                    </Typography>
                    <div className={classes.instructions} data-testid="information-cicd">
                      <CiCdInstructions maxInstructionsStepsShow={2} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-start" item xs={12} lg={5}>
                  <Box className={classes.integrationDetailsWrapper} pt={3} pr={3} pb={3} pl={3} mb={4}>
                    <span className={classes.intergrationHeader} data-testid="integration-header">
                      CI/CD Integration details:
                    </span>
                    <a href={automatorSdkUrl} target="_blank" rel="noopener noreferrer" data-testid="integration-link">
                      {automatorSdkUrl}
                    </a>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        </Grid>
        <Grid item xs={12}>
          {props.testRuns.map(suite => (
            <div data-testid={`suite-${suite.id}`} data-cy={getTestSuiteCypressId(suite)} key={suite.id}>
              <TestSuiteListItem {...suite} refetchData={props.refetchData} />
            </div>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
