import { useQuery } from "@apollo/react-hooks";
import {
  getAvailableParentTestSuites,
  getAvailableParentTestSuitesVariables
} from "../../../../../generated-graphql-interfaces";
import { getAvailableParentTestSuitesGql } from "./getAvailableParentTestSuitesGql";
import { useState, useEffect } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";

function getPreviousResultAsLastOne(data: getAvailableParentTestSuites) {
  if (data.getAccount?.notLinkedTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.notLinkedTestSuites.pageInfo.hasNextPage = false;
  }
  if (data.getAccount?.parentTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.parentTestSuites.pageInfo.hasNextPage = false;
  }
  return data;
}

export function useGetAvailableParentTestSuites(variables: { accountId: string }) {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [accumulatedData, setAccumulatedData] = useState<getAvailableParentTestSuites>();
  const { data, loading, fetchMore, error } = useQuery<
    getAvailableParentTestSuites,
    getAvailableParentTestSuitesVariables
  >(getAvailableParentTestSuitesGql, { variables });

  useEffect(() => {
    setAccumulatedData(data);
  }, [data]);

  function fetchMoreData() {
    if (
      (accumulatedData?.getAccount?.notLinkedTestSuites?.pageInfo?.hasNextPage ||
        accumulatedData?.getAccount?.parentTestSuites?.pageInfo?.hasNextPage) &&
      !isFetchingMore &&
      !loading
    ) {
      setIsFetchingMore(true);

      const newVariables = {
        cursorNotLinkedTestSuites: accumulatedData?.getAccount.notLinkedTestSuites?.pageInfo?.endCursor,
        cursorParentTestSuites: accumulatedData?.getAccount.parentTestSuites?.pageInfo?.endCursor,
        accountId: variables.accountId
      };

      fetchMore({
        variables: newVariables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult?.getAccount?.__typename === "Account" &&
            fetchMoreResult.getAccount.notLinkedTestSuites?.edges &&
            fetchMoreResult.getAccount.parentTestSuites?.edges
          ) {
            const newData = merge(previousResult, fetchMoreResult, defaultMergeOptions) as getAvailableParentTestSuites;
            setAccumulatedData(newData);
            return newData;
          }
          const oldData = getPreviousResultAsLastOne(previousResult);
          setAccumulatedData(oldData);
          return oldData;
        }
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }

  fetchMoreData();

  return { data: accumulatedData, loading: loading || isFetchingMore, error };
}
