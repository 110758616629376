import React from "react";
import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

interface CollapseHeaderProps {
  name: string;
  children: React.ReactNode;
  isOpen: boolean;
  testId?: string;
  onClick: () => void;
  pendoId: string;
}

const useStyles = makeStyles(theme => ({
  root: { padding: "0px 15px", color: theme.palette.secondary.main, marginLeft: -15 }
}));

export function CollapseHeader(props: CollapseHeaderProps) {
  const { name, children, isOpen, testId, onClick } = props;
  const classes = useStyles();

  return (
    <>
      <Box component="div" data-pendo={props.pendoId}>
        <Button
          className={classes.root}
          color="secondary"
          onClick={onClick}
          endIcon={isOpen ? <RemoveCircleIcon /> : <AddCircleIcon />}
          data-cy={testId}
          data-testid={testId}
        >
          {name}
        </Button>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
}
