import { useQuery } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { getNextTestSuites, getPreviousTestSuites } from "../../../generated-graphql-interfaces";
import { shouldRefetchData } from "../../../_common/utils/testSuiteRefetchEvaluation/testSuiteRefetchEvaluation";
import { nextTestSuitesQuery } from "./nextTestSuitesQuery";
import { previousTestSuitesQuery } from "./previousTestsSuiteQuery";

// eslint-disable-next-line max-params
export function useTestSuitesQuery(accountId: string, itemsPerPage: number, isNext: boolean, cursor: string | null) {
  const [loadedData, setLoadedData] = useState<getNextTestSuites | getPreviousTestSuites>();
  const [oldData, setOldData] = useState<getNextTestSuites | getPreviousTestSuites>();
  const [msOfLastRefetch, setMsOfLastRefetch] = useState<number>(new Date().getTime());
  const [forceRefetch, setForceRefetch] = useState<boolean>(false);

  const query = isNext ? nextTestSuitesQuery : previousTestSuitesQuery;

  const { data, loading, error, refetch } = useQuery<getNextTestSuites | getPreviousTestSuites>(query, {
    variables: {
      accountId,
      itemsPerPage,
      cursor
    }
  });

  useEffect(() => {
    setLoadedData(data);
  }, [data]);

  useEffect(() => {
    const evaluateReload = () => {
      if (!loading && (shouldRefetchData(msOfLastRefetch, oldData || loadedData) || forceRefetch)) {
        setForceRefetch(false);
        const msOfDateNow = new Date().getTime();
        setMsOfLastRefetch(msOfDateNow);
        setOldData(loadedData);
        setLoadedData(undefined);
        refetch().then(data => {
          setLoadedData(data.data);
        });
      }
    };

    const interval = setInterval(() => {
      evaluateReload();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [refetch, loadedData, oldData, loading, msOfLastRefetch, forceRefetch]);

  function setRefetch() {
    setForceRefetch(true);
  }

  return { data: loadedData || oldData, loading, error: error, refetch: setRefetch };
}
