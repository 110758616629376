import { ReactAppEnvironment } from "../../constants/ReactAppEnvironment";

function getHotjarID(): number {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.PRODUCTION;
  return isProduction ? 1457129 : 1457164;
}

export function addHotjarScript(): void {
  const hotjarID = getHotjarID();
  const script = document.createElement("script");

  //eslint-disable-next-line
  script.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjarID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

  document.body.appendChild(script);
}
