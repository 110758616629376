import React from "react";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { JiraProjectSelect } from "./JiraProjectSelect";
import { JiraIssueTypeSelect } from "./JiraIssueTypeSelect";
import AuthIcon from "@material-ui/icons/AccountBoxRounded";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import { useUpdateJiraIntegrationMutation } from "../graphql/useUpdateJiraIntegrationMutation";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { JiraIntegration } from "../../../../ConnectedApps/JiraIntegration/graphql/useGetJiraIntegrationsQuery";

const useStyles = makeStyles(theme => ({
  buttonWrapper: (props: IEditJiraIntegrationFormProps) => {
    return {
      marginTop: props.jiraIntegration.name.length > 50 ? "30px" : theme.spacing(7)
    };
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "1rem",
    marginRight: theme.spacing(1)
  },
  tooltipTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginTop: 0,
    lineHeight: "1.125rem"
  },
  tooltipText: {
    fontSize: "0.875rem",
    color: "#202020"
  }
}));

export interface IEditJiraIntegrationFormProps {
  jiraIntegration: JiraIntegration;
  onSuccess: () => void;
  onError: (e: Error) => void;
}

// eslint-disable-next-line complexity, max-lines-per-function
export function EditJiraIntegrationForm(props: IEditJiraIntegrationFormProps): JSX.Element {
  const classes = useStyles(props);

  const {
    jiraIntegration: { jiraAuthenticationId, jiraAuthenticationName }
  } = props;

  const [name, setName] = useState(props.jiraIntegration.name);
  const [jiraProjectId, setJiraProjectId] = useState(props.jiraIntegration.jiraProjectId);
  const [jiraIssueTypeId, setJiraIssueTypeId] = useState(props.jiraIntegration.jiraIssueTypeId);

  const [updateJiraIntegration, { loading, error }] = useUpdateJiraIntegrationMutation();

  const { enqueueSnackbar } = useSnackbar();

  async function update(): Promise<void> {
    try {
      if (!name || !jiraProjectId || !jiraIssueTypeId) return;

      const {
        jiraIntegration: { id: jiraIntegrationId }
      } = props;

      await updateJiraIntegration({
        variables: {
          jiraIntegrationId,
          name,
          jiraProjectId,
          jiraIssueTypeId
        }
      });

      enqueueSnackbar("Jira integration updated successfully.", { variant: "success" });

      props.onSuccess();
    } catch (e) {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  return (
    <div>
      {loading && <CircularProgress color="secondary" />}

      {error && (
        <Alert severity="error" data-testid="error-alert">
          Sorry, looks like something went wrong when creating a new Jira integration. Please try again or contact
          support.
        </Alert>
      )}

      {!loading && !error && (
        <>
          <List data-testid="jira-integration-authentication-details">
            <ListItem>
              <ListItemIcon>
                <AuthIcon />
              </ListItemIcon>
              <ListItemText primary={jiraAuthenticationName} secondary={props.jiraIntegration.jiraUrl} />
            </ListItem>
            <Divider />
          </List>

          <Box display="flex" alignItems="center" mb={3}>
            <Typography variant="h2" className={classes.subtitle} data-testid="integration-name-subtitle">
              Integration name
            </Typography>

            <DeepcrawlInfoTooltip
              data-pendo="auto-tooltip-integration-name-edit"
              maxWidth={400}
              body={
                <Box>
                  <Typography variant="h6" className={classes.tooltipTitle}>
                    Your Jira integration name
                  </Typography>
                  <Typography variant="body1" className={classes.tooltipText}>
                    You should name your integration with a unique identifier based on the configuration settings you
                    make if you plan to have multiple Jira integrations for a single Automation Hub account.
                  </Typography>
                </Box>
              }
            />
          </Box>

          <TextField
            label="Integration name"
            variant="filled"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            data-testid="integration-name-input"
          />

          {name.length > 0 && (
            <Box mb={4} mt={4} data-testid="jira-project-select">
              <JiraProjectSelect
                jiraAuthenticationId={jiraAuthenticationId}
                jiraProjectId={jiraProjectId}
                onSelect={e => setJiraProjectId(e.target.value as string)}
              />
            </Box>
          )}

          {name.length > 0 && jiraProjectId.length > 0 && (
            <Box mb={4} mt={4} data-testid="jira-issue-type-select">
              <JiraIssueTypeSelect
                jiraAuthenticationId={jiraAuthenticationId}
                jiraProjectId={jiraProjectId}
                issueTypeId={jiraIssueTypeId}
                onSelect={e => setJiraIssueTypeId(e.target.value as string)}
              />
            </Box>
          )}

          {name.length > 0 && jiraProjectId.length > 0 && jiraIssueTypeId.length > 0 && (
            <Box display="flex" justifyContent="flex-end" className={classes.buttonWrapper}>
              <PrimaryActionButton
                dataTestId="edit-jira-integration-submit-btn"
                disabled={!jiraProjectId.length || !jiraIssueTypeId.length}
                onClick={async () => await update()}
                label="Save"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
}
