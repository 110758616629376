import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function ConnectedAppsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12515 1.93152C4.25451 1.83422 3.3525 1.83422 2.48186 1.93152C2.46569 1.93333 2.44968 1.94065 2.43654 1.95361C2.4237 1.96627 2.41868 1.97907 2.41742 1.98992C2.31352 2.87816 2.31352 3.77546 2.41742 4.66368C2.41868 4.67455 2.4237 4.68734 2.43654 4.7C2.44968 4.71296 2.46569 4.72028 2.48186 4.72209C3.3525 4.81939 4.25451 4.81939 5.12515 4.72209C5.14132 4.72028 5.15731 4.71296 5.17047 4.7C5.18331 4.68734 5.18833 4.67455 5.18959 4.66368C5.29348 3.77546 5.29348 2.87816 5.18959 1.98992C5.18833 1.97907 5.18331 1.96627 5.17047 1.95361C5.15731 1.94065 5.14132 1.93333 5.12515 1.93152ZM2.27542 0.08448C3.28326 -0.02816 4.32374 -0.02816 5.33158 0.08448C6.21418 0.183131 6.93069 0.877519 7.03555 1.77403C7.15621 2.80571 7.15621 3.84791 7.03555 4.87959C6.93069 5.7761 6.21418 6.47049 5.33158 6.56914C4.32374 6.68178 3.28326 6.68178 2.27542 6.56914C1.39283 6.47049 0.676308 5.7761 0.571449 4.87959C0.450793 3.84791 0.450793 2.80571 0.571449 1.77403C0.676308 0.877519 1.39283 0.183131 2.27542 0.08448Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7986 1.93152C12.9279 1.83422 12.0259 1.83422 11.1552 1.93152C11.1391 1.93333 11.1231 1.94065 11.1099 1.95361C11.0971 1.96627 11.092 1.97907 11.0908 1.98992C10.987 2.87816 10.987 3.77546 11.0908 4.66368C11.092 4.67455 11.0971 4.68734 11.1099 4.7C11.1231 4.71296 11.1391 4.72028 11.1552 4.72209C12.0259 4.81939 12.9279 4.81939 13.7986 4.72209C13.8147 4.72028 13.8307 4.71296 13.8439 4.7C13.8567 4.68734 13.8618 4.67455 13.863 4.66368C13.9668 3.77546 13.9668 2.87816 13.863 1.98992C13.8618 1.97907 13.8567 1.96627 13.8439 1.95361C13.8307 1.94065 13.8147 1.93333 13.7986 1.93152ZM10.9487 0.08448C11.9566 -0.02816 12.9971 -0.02816 14.0049 0.08448C14.8875 0.183131 15.604 0.877519 15.7088 1.77403C15.8295 2.80571 15.8295 3.84791 15.7088 4.87959C15.604 5.7761 14.8875 6.47049 14.0049 6.56914C12.9971 6.68178 11.9566 6.68178 10.9487 6.56914C10.0662 6.47049 9.34965 5.7761 9.24479 4.87959C9.12413 3.84791 9.12413 2.80571 9.24479 1.77403C9.34965 0.877519 10.0662 0.183131 10.9487 0.08448Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4713 1.93152C21.6006 1.83422 20.6986 1.83422 19.8279 1.93152C19.8118 1.93333 19.7958 1.94065 19.7826 1.95361C19.7698 1.96627 19.7647 1.97907 19.7635 1.98992C19.6597 2.87816 19.6597 3.77546 19.7635 4.66368C19.7647 4.67455 19.7698 4.68734 19.7826 4.7C19.7958 4.71296 19.8118 4.72028 19.8279 4.72209C20.6986 4.81939 21.6006 4.81939 22.4713 4.72209C22.4874 4.72028 22.5034 4.71296 22.5166 4.7C22.5294 4.68734 22.5345 4.67455 22.5357 4.66368C22.6395 3.77546 22.6395 2.87816 22.5357 1.98992C22.5345 1.97907 22.5294 1.96627 22.5166 1.95361C22.5034 1.94065 22.4874 1.93333 22.4713 1.93152ZM19.6216 0.08448C20.6294 -0.02816 21.6699 -0.02816 22.6778 0.08448C23.5603 0.183131 24.2768 0.877519 24.3817 1.77403C24.5023 2.80571 24.5023 3.84791 24.3817 4.87959C24.2768 5.7761 23.5603 6.47049 22.6778 6.56914C21.6699 6.68178 20.6294 6.68178 19.6216 6.56914C18.739 6.47049 18.0225 5.7761 17.9177 4.87959C17.797 3.84791 17.797 2.80571 17.9177 1.77403C18.0225 0.877519 18.739 0.183131 19.6216 0.08448Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12515 10.6052C4.25451 10.5079 3.3525 10.5079 2.48186 10.6052C2.46569 10.607 2.44968 10.6143 2.43654 10.6273C2.4237 10.64 2.41868 10.6527 2.41742 10.6636C2.31352 11.5519 2.31352 12.4492 2.41742 13.3373C2.41868 13.3482 2.4237 13.361 2.43654 13.3736C2.44968 13.3867 2.46569 13.394 2.48186 13.3958C3.3525 13.4931 4.25451 13.4931 5.12515 13.3958C5.14132 13.394 5.15731 13.3867 5.17047 13.3736C5.18331 13.361 5.18833 13.3482 5.18959 13.3373C5.29348 12.4492 5.29348 11.5519 5.18959 10.6636C5.18833 10.6527 5.18331 10.64 5.17047 10.6273C5.15731 10.6143 5.14132 10.607 5.12515 10.6052ZM2.27542 8.75831C3.28326 8.64567 4.32374 8.64567 5.33158 8.75831C6.21418 8.85696 6.93069 9.55138 7.03555 10.4478C7.15621 11.4796 7.15621 12.5217 7.03555 13.5534C6.93069 14.4499 6.21418 15.1444 5.33158 15.243C4.32374 15.3556 3.28326 15.3556 2.27542 15.243C1.39283 15.1444 0.676308 14.4499 0.571449 13.5534C0.450793 12.5217 0.450793 11.4796 0.571449 10.4478C0.676308 9.55138 1.39283 8.85696 2.27542 8.75831Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7986 10.6052C12.9279 10.5079 12.0259 10.5079 11.1552 10.6052C11.1391 10.607 11.1231 10.6143 11.1099 10.6273C11.0971 10.64 11.092 10.6527 11.0908 10.6636C10.987 11.5519 10.987 12.4492 11.0908 13.3373C11.092 13.3482 11.0971 13.361 11.1099 13.3736C11.1231 13.3867 11.1391 13.394 11.1552 13.3958C12.0259 13.4931 12.9279 13.4931 13.7986 13.3958C13.8147 13.394 13.8307 13.3867 13.8439 13.3736C13.8567 13.361 13.8618 13.3482 13.863 13.3373C13.9668 12.4492 13.9668 11.5519 13.863 10.6636C13.8618 10.6527 13.8567 10.64 13.8439 10.6273C13.8307 10.6143 13.8147 10.607 13.7986 10.6052ZM10.9487 8.75831C11.9566 8.64567 12.9971 8.64567 14.0049 8.75831C14.8875 8.85696 15.604 9.55138 15.7088 10.4478C15.8295 11.4796 15.8295 12.5217 15.7088 13.5534C15.604 14.4499 14.8875 15.1444 14.0049 15.243C12.9971 15.3556 11.9566 15.3556 10.9487 15.243C10.0662 15.1444 9.34965 14.4499 9.24479 13.5534C9.12413 12.5217 9.12413 11.4796 9.24479 10.4478C9.34965 9.55138 10.0662 8.85696 10.9487 8.75831Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4713 10.6052C21.6006 10.5079 20.6986 10.5079 19.8279 10.6052C19.8118 10.607 19.7958 10.6143 19.7826 10.6273C19.7698 10.64 19.7647 10.6527 19.7635 10.6636C19.6597 11.5519 19.6597 12.4492 19.7635 13.3373C19.7647 13.3482 19.7698 13.361 19.7826 13.3736C19.7958 13.3867 19.8118 13.394 19.8279 13.3958C20.6986 13.4931 21.6006 13.4931 22.4713 13.3958C22.4874 13.394 22.5034 13.3867 22.5166 13.3736C22.5294 13.361 22.5345 13.3482 22.5357 13.3373C22.6395 12.4492 22.6395 11.5519 22.5357 10.6636C22.5345 10.6527 22.5294 10.64 22.5166 10.6273C22.5034 10.6143 22.4874 10.607 22.4713 10.6052ZM19.6216 8.75831C20.6294 8.64567 21.6699 8.64567 22.6778 8.75831C23.5603 8.85696 24.2768 9.55138 24.3817 10.4478C24.5023 11.4796 24.5023 12.5217 24.3817 13.5534C24.2768 14.4499 23.5603 15.1444 22.6778 15.243C21.6699 15.3556 20.6294 15.3556 19.6216 15.243C18.739 15.1444 18.0225 14.4499 17.9177 13.5534C17.797 12.5217 17.797 11.4796 17.9177 10.4478C18.0225 9.55138 18.739 8.85696 19.6216 8.75831Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12515 19.2774C4.25451 19.1801 3.3525 19.1801 2.48186 19.2774C2.46569 19.2792 2.44968 19.2865 2.43654 19.2996C2.4237 19.3122 2.41868 19.325 2.41742 19.3359C2.31352 20.2241 2.31352 21.1214 2.41742 22.0096C2.41868 22.0205 2.4237 22.0332 2.43654 22.0459C2.44968 22.0589 2.46569 22.0662 2.48186 22.068C3.3525 22.1653 4.25451 22.1653 5.12515 22.068C5.14132 22.0662 5.15731 22.0589 5.17047 22.0459C5.18331 22.0332 5.18833 22.0205 5.18959 22.0096C5.29348 21.1214 5.29348 20.2241 5.18959 19.3359C5.18833 19.325 5.18331 19.3122 5.17047 19.2996C5.15731 19.2865 5.14132 19.2792 5.12515 19.2774ZM2.27542 17.4302C3.28326 17.3175 4.32374 17.3175 5.33158 17.4302C6.21418 17.5288 6.93069 18.2233 7.03555 19.1197C7.15621 20.1514 7.15621 21.1936 7.03555 22.2253C6.93069 23.1218 6.21418 23.8162 5.33158 23.9149C4.32374 24.0275 3.28326 24.0275 2.27542 23.9149C1.39283 23.8162 0.676308 23.1218 0.571449 22.2253C0.450793 21.1936 0.450793 20.1514 0.571449 19.1197C0.676308 18.2233 1.39283 17.5288 2.27542 17.4302Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7986 19.2774C12.9279 19.1801 12.0259 19.1801 11.1552 19.2774C11.1391 19.2792 11.1231 19.2865 11.1099 19.2996C11.0971 19.3122 11.092 19.325 11.0908 19.3359C10.987 20.2241 10.987 21.1214 11.0908 22.0096C11.092 22.0205 11.0971 22.0332 11.1099 22.0459C11.1231 22.0589 11.1391 22.0662 11.1552 22.068C12.0259 22.1653 12.9279 22.1653 13.7986 22.068C13.8147 22.0662 13.8307 22.0589 13.8439 22.0459C13.8567 22.0332 13.8618 22.0205 13.863 22.0096C13.9668 21.1214 13.9668 20.2241 13.863 19.3359C13.8618 19.325 13.8567 19.3122 13.8439 19.2996C13.8307 19.2865 13.8147 19.2792 13.7986 19.2774ZM10.9487 17.4302C11.9566 17.3175 12.9971 17.3175 14.0049 17.4302C14.8875 17.5288 15.604 18.2233 15.7088 19.1197C15.8295 20.1514 15.8295 21.1936 15.7088 22.2253C15.604 23.1218 14.8875 23.8162 14.0049 23.9149C12.9971 24.0275 11.9566 24.0275 10.9487 23.9149C10.0662 23.8162 9.34965 23.1218 9.24479 22.2253C9.12413 21.1936 9.12413 20.1514 9.24479 19.1197C9.34965 18.2233 10.0662 17.5288 10.9487 17.4302Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4713 19.2774C21.6006 19.1801 20.6986 19.1801 19.8279 19.2774C19.8118 19.2792 19.7958 19.2865 19.7826 19.2996C19.7698 19.3122 19.7647 19.325 19.7635 19.3359C19.6597 20.2241 19.6597 21.1214 19.7635 22.0096C19.7647 22.0205 19.7698 22.0332 19.7826 22.0459C19.7958 22.0589 19.8118 22.0662 19.8279 22.068C20.6986 22.1653 21.6006 22.1653 22.4713 22.068C22.4874 22.0662 22.5034 22.0589 22.5166 22.0459C22.5294 22.0332 22.5345 22.0205 22.5357 22.0096C22.6395 21.1214 22.6395 20.2241 22.5357 19.3359C22.5345 19.325 22.5294 19.3122 22.5166 19.2996C22.5034 19.2865 22.4874 19.2792 22.4713 19.2774ZM19.6216 17.4302C20.6294 17.3175 21.6699 17.3175 22.6778 17.4302C23.5603 17.5288 24.2768 18.2233 24.3817 19.1197C24.5023 20.1514 24.5023 21.1936 24.3817 22.2253C24.2768 23.1218 23.5603 23.8162 22.6778 23.9149C21.6699 24.0275 20.6294 24.0275 19.6216 23.9149C18.739 23.8162 18.0225 23.1218 17.9177 22.2253C17.797 21.1936 17.797 20.1514 17.9177 19.1197C18.0225 18.2233 18.739 17.5288 19.6216 17.4302Z"
      />
    </SvgIcon>
  );
}

export function LinearGradientConnectedAppsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <path
          d="M5.10413 0.123847C4.09269 0.010801 3.04794 0.010801 2.03649 0.123847C1.27479 0.208978 0.657522 0.808498 0.56734 1.57963C0.446303 2.61447 0.446303 3.65991 0.56734 4.69475C0.657522 5.46588 1.2748 6.0654 2.03649 6.15053C3.04794 6.26358 4.09269 6.26358 5.10413 6.15053C5.86584 6.0654 6.48311 5.46588 6.57329 4.69475C6.69432 3.65991 6.69432 2.61447 6.57329 1.57963C6.48311 0.808498 5.86584 0.208978 5.10413 0.123847Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M14.0104 0.123847C12.999 0.010801 11.9542 0.010801 10.9428 0.123847C10.1811 0.208978 9.56378 0.808498 9.47359 1.57963C9.35255 2.61447 9.35255 3.65991 9.47359 4.69475C9.56378 5.46588 10.1811 6.0654 10.9428 6.15053C11.9542 6.26358 12.999 6.26358 14.0104 6.15053C14.7721 6.0654 15.3893 5.46588 15.4795 4.69475C15.6005 3.65991 15.6005 2.61447 15.4795 1.57963C15.3893 0.808498 14.7721 0.208978 14.0104 0.123847Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M22.9166 0.123847C21.9053 0.010801 20.8604 0.010801 19.8491 0.123847C19.0873 0.208978 18.47 0.808498 18.3799 1.57963C18.2588 2.61447 18.2588 3.65991 18.3799 4.69475C18.47 5.46588 19.0873 6.0654 19.8491 6.15053C20.8604 6.26358 21.9053 6.26358 22.9166 6.15053C23.6784 6.0654 24.2956 5.46588 24.3858 4.69475C24.5068 3.65991 24.5068 2.61447 24.3858 1.57963C24.2956 0.808498 23.6784 0.208978 22.9166 0.123847Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M5.10413 9.0301C4.09269 8.91705 3.04794 8.91705 2.03649 9.0301C1.27479 9.11523 0.657522 9.71476 0.56734 10.4859C0.446303 11.5207 0.446303 12.5662 0.56734 13.601C0.657522 14.3721 1.2748 14.9716 2.03649 15.0567C3.04794 15.1699 4.09269 15.1699 5.10413 15.0567C5.86584 14.9716 6.48311 14.3721 6.57329 13.601C6.69432 12.5662 6.69432 11.5207 6.57329 10.4859C6.48311 9.71476 5.86584 9.11523 5.10413 9.0301Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M14.0104 9.0301C12.999 8.91705 11.9542 8.91705 10.9428 9.0301C10.1811 9.11523 9.56378 9.71476 9.47359 10.4859C9.35255 11.5207 9.35255 12.5662 9.47359 13.601C9.56378 14.3721 10.1811 14.9716 10.9428 15.0567C11.9542 15.1699 12.999 15.1699 14.0104 15.0567C14.7721 14.9716 15.3893 14.3721 15.4795 13.601C15.6005 12.5662 15.6005 11.5207 15.4795 10.4859C15.3893 9.71476 14.7721 9.11523 14.0104 9.0301Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M22.9166 9.0301C21.9053 8.91705 20.8604 8.91705 19.8491 9.0301C19.0873 9.11523 18.47 9.71476 18.3799 10.4859C18.2588 11.5207 18.2588 12.5662 18.3799 13.601C18.47 14.3721 19.0873 14.9716 19.8491 15.0567C20.8604 15.1699 21.9053 15.1699 22.9166 15.0567C23.6784 14.9716 24.2956 14.3721 24.3858 13.601C24.5068 12.5662 24.5068 11.5207 24.3858 10.4859C24.2956 9.71476 23.6784 9.11523 22.9166 9.0301Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M5.10413 17.9364C4.09269 17.8233 3.04794 17.8233 2.03649 17.9364C1.27479 18.0215 0.657522 18.621 0.56734 19.3922C0.446303 20.427 0.446303 21.4724 0.56734 22.5072C0.657522 23.2784 1.2748 23.8779 2.03649 23.963C3.04794 24.0761 4.09269 24.0761 5.10413 23.963C5.86584 23.8779 6.48311 23.2784 6.57329 22.5072C6.69432 21.4724 6.69432 20.427 6.57329 19.3922C6.48311 18.621 5.86584 18.0215 5.10413 17.9364Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M14.0104 17.9364C12.999 17.8233 11.9542 17.8233 10.9428 17.9364C10.1811 18.0215 9.56378 18.621 9.47359 19.3922C9.35255 20.427 9.35255 21.4724 9.47359 22.5072C9.56378 23.2784 10.1811 23.8779 10.9428 23.963C11.9542 24.0761 12.999 24.0761 14.0104 23.963C14.7721 23.8779 15.3893 23.2784 15.4795 22.5072C15.6005 21.4724 15.6005 20.427 15.4795 19.3922C15.3893 18.621 14.7721 18.0215 14.0104 17.9364Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M22.9166 17.9364C21.9053 17.8233 20.8604 17.8233 19.8491 17.9364C19.0873 18.0215 18.47 18.621 18.3799 19.3922C18.2588 20.427 18.2588 21.4724 18.3799 22.5072C18.47 23.2784 19.0873 23.8779 19.8491 23.963C20.8604 24.0761 21.9053 24.0761 22.9166 23.963C23.6784 23.8779 24.2956 23.2784 24.3858 22.5072C24.5068 21.4724 24.5068 20.427 24.3858 19.3922C24.2956 18.621 23.6784 18.0215 22.9166 17.9364Z"
          fill="url(#paint8_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="0.476562" y="0.0429688" width="24" height="24" fill="url(#paint9_linear)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.971858"
          y1="5.04699"
          x2="5.6972"
          y2="5.44032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="9.87811"
          y1="5.04699"
          x2="14.6034"
          y2="5.44032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="18.7844"
          y1="5.04699"
          x2="23.5097"
          y2="5.44032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="0.971858"
          y1="13.9533"
          x2="5.6972"
          y2="14.3466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="9.87811"
          y1="13.9533"
          x2="14.6034"
          y2="14.3466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="18.7844"
          y1="13.9533"
          x2="23.5097"
          y2="14.3466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="0.971858"
          y1="22.8595"
          x2="5.6972"
          y2="23.2528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="9.87811"
          y1="22.8595"
          x2="14.6034"
          y2="23.2528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="18.7844"
          y1="22.8595"
          x2="23.5097"
          y2="23.2528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="2.39771"
          y1="19.4402"
          x2="20.726"
          y2="20.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
