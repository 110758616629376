import { makeStyles } from "@material-ui/core";
import React from "react";

const listNumberWidth = "21px"; // This is used in multiple places to add custom styling to the list item numbers

const useStyles = makeStyles(theme => ({
  ol: {
    margin: 0,
    paddingInlineStart: "10px",
    listStyle: "none",
    counterReset: "item"
  },
  li: {
    lineHeight: listNumberWidth,
    counterIncrement: "item",
    marginBottom: "12px",
    fontSize: 14,
    paddingLeft: listNumberWidth,
    position: "relative",
    "&:before": {
      content: "counter(item)",
      background: "black",
      borderRadius: "50%",
      color: "white",
      marginLeft: `-${listNumberWidth}`,
      width: listNumberWidth,
      textAlign: "center",
      display: "inline-block",
      position: "absolute",
      left: "10px"
    }
  }
}));

export interface InstructionListProps {
  dataId: string;
  instructions: JSX.Element[];
}

export function InstructionsList(props: InstructionListProps): JSX.Element {
  const classes = useStyles();

  return (
    <ol className={classes.ol} data-cy={`${props.dataId}-instructions`} data-testid={`${props.dataId}-instructions`}>
      {props.instructions.map((instruction, index) => (
        <li className={classes.li} data-testid={`${props.dataId}-list-item`} key={`${props.dataId}-step-${index}`}>
          {instruction}
        </li>
      ))}
    </ol>
  );
}
