import React from "react";
import { Drawer, makeStyles, IconButton, Box, useMediaQuery, Theme } from "@material-ui/core";
import { AccountSelect } from "../AccountSelect/AccountSelect";
import { MainNav } from "../MainNav/MainNav";
import automatorLogo from "../../../images/automation-hub-logo.svg";
import { Link } from "react-router-dom";
import { getUser } from "../../../generated-graphql-interfaces";
import { Routes } from "../../routes/routes";
import { CloseIcon } from "../../svg/CloseIcon";

export const SIDE_NAV_WIDTH = 240;
const MOBILE_SIDE_NAV_WIDTH = 320;

export interface SideBarProps {
  isOpen: boolean;
  toggleIsOpen: (isOpen: boolean) => void;
  userData: getUser | undefined;
  isUserDataLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: SIDE_NAV_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: MOBILE_SIDE_NAV_WIDTH
    }
  },
  drawerPaper: {
    width: SIDE_NAV_WIDTH,
    backgroundColor: theme.palette.navy[500],
    border: "none",
    overflowY: "visible",
    [theme.breakpoints.down("sm")]: {
      width: MOBILE_SIDE_NAV_WIDTH
    }
  },
  logo: {
    width: 32
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "24px 12px 48px 11px",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3, 2, 6, 3)
    }
  },
  closeButton: {
    padding: 0,
    marginLeft: 24
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  logoLink: {
    height: 28,
    padding: "4px 5px"
  },
  closeIcon: {
    fontSize: "2rem"
  }
}));

export function SideNav(props: SideBarProps) {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(props);

  function closeSideNav() {
    if (isMobileView) {
      props.toggleIsOpen(false);
    }
  }

  return (
    <Drawer
      data-testid="side-nav"
      className={classes.drawer}
      variant={isMobileView ? "temporary" : "permanent"}
      open={isMobileView ? props.isOpen : undefined}
      onClose={closeSideNav}
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Box className={classes.logoWrapper}>
        <Link to={Routes.Root.ROUTE} data-testid="nav-logo-link" className={classes.logoLink}>
          <img src={automatorLogo} alt="Logo" data-testid="nav-logo" className={classes.logo} />
        </Link>
        <AccountSelect
          closeSideNav={closeSideNav}
          userData={props.userData}
          isUserDataLoading={props.isUserDataLoading}
        />
        {isMobileView && (
          <IconButton onClick={closeSideNav} className={classes.closeButton} data-testid="close-side-nav-btn">
            <CloseIcon viewBox="0 0 25 25" className={classes.closeIcon} />
          </IconButton>
        )}
      </Box>

      <MainNav closeSideNav={closeSideNav} isLoading={props.isUserDataLoading} />
    </Drawer>
  );
}
