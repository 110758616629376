import React, { useRef, ChangeEvent, ReactNode } from "react";
import { FileTable, FileTableProps } from "./components/FileTable";
import { Button, Typography, Box, makeStyles, CircularProgress } from "@material-ui/core";
import BufferedLinearProgress from "../BufferedLinearProgress/BufferedLinearProgress";

const useStyles = makeStyles(theme => ({
  input: {
    display: "none"
  },
  progressBar: {
    marginBottom: theme.spacing(2)
  }
}));

export interface FileUploaderProps extends FileTableProps {
  onFilesAdded: (files: File[]) => void;
  children?: ReactNode;
  processing?: boolean;
  filesToUpload?: number | undefined;
  filesUploaded?: number | undefined;
  filesBeeingUploaded?: number | undefined;
}

export function FileUploader(props: FileUploaderProps): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  function openFileUploadDialog(): void {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function onFilesSelected(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.validity && event.target.files) {
      props.onFilesAdded(Array.from(event.target.files));
      event.target.value = "";
    }
  }

  return (
    <>
      <Box mb={3}>
        <Typography variant="body1" data-testid="files-description">
          Upload a list of URLs in a text file with each URL on a new line, or CSV with the URL in the first column (up
          to 100MB).
        </Typography>
      </Box>
      <Box mb={2} data-testid="files-counter">
        <strong data-cy="url-file-upload-label">{props.files.length} File(s) uploaded</strong>
      </Box>
      <Box mb={2}>
        <FileTable files={props.files} onFileDeleted={props.onFileDeleted} processing={props.processing} />
      </Box>
      {props.filesToUpload && (
        <>
          <Box
            mb={1}
            data-testid="loading-files-progress-label"
          >{`Uploaded: ${props.filesUploaded} files out of ${props.filesToUpload}`}</Box>
          <Box className={classes.progressBar}>
            <BufferedLinearProgress
              testId="loading-files-progress"
              completed={props.filesUploaded}
              buffered={props.filesBeeingUploaded}
              totalCount={props.filesToUpload}
            />
          </Box>
        </>
      )}
      <Button
        type="button"
        onClick={openFileUploadDialog}
        variant="outlined"
        data-cy="btn-url-file-upload"
        data-testid="btn-url-file-upload"
        data-pendo="auto-test-suite-edit-upload-files"
        disabled={props.processing}
      >
        {props.processing ? (
          <CircularProgress size={20} />
        ) : props.files.length > 0 ? (
          "Upload more files"
        ) : (
          "Upload files"
        )}
      </Button>
      <input
        ref={fileInputRef}
        onChange={onFilesSelected}
        className={classes.input}
        type="file"
        multiple
        data-cy="input-file-upload"
        data-testid="input-file-upload"
        data-pendo="auto-test-suite-edit-upload-files-input"
        id="input-file-upload"
      />
    </>
  );
}
