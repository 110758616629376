import gql from "graphql-tag";

export const unlinkChildTestSuiteFromParentTestSuiteGql = gql`
  mutation unlinkChildTestSuiteFromParentTestSuite($childId: ObjectID!) {
    unlinkChildTestSuiteFromParentTestSuite(input: { childTestSuiteId: $childId }) {
      parentTestSuite {
        name
        id
      }
      childTestSuite {
        name
        id
      }
    }
  }
`;
