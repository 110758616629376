import gql from "graphql-tag";

export const deleteFileUploadMutationGql = gql`
  mutation deleteFileUpload($fileUploadId: ObjectID!) {
    deleteUrlFileUpload(input: { urlFileUploadId: $fileUploadId }) {
      urlFileUpload {
        fileName
      }
    }
  }
`;
