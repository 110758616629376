import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useStylesItemGrid } from "../../../../pages/TestSuiteList/components/TestSuiteListItemStyles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  table: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  },
  tableHeadings: {
    fontSize: "14px",
    fontWeight: 500
  },
  actions: {
    flexShrink: 0,
    width: 56 + theme.spacing(2) + 1
  },
  tableChipItem: {
    display: "inline-block",
    textAlign: "center",
    minWidth: "33%"
  }
}));

export function SchedulerTestSuiteListTable(): JSX.Element {
  const classes = useStyles();
  const classesGrid = useStylesItemGrid();

  return (
    <Box
      className={clsx(classesGrid.root, classesGrid.rootBorder, classes.table)}
      data-testid={"scheduler-test-suite-list-table"}
    >
      <Box className={classesGrid.mainGrid}>
        <Box className={clsx(classesGrid.iconWrapper, classes.tableHeadings)}>Status</Box>
        <Grid container className={clsx(classesGrid.textWrapper, classes.tableHeadings)}>
          <Grid item xs={12} lg={5} className={classesGrid.itemInfoWrapper}>
            <Box>Test suite</Box>
          </Grid>
          <Grid item xs={12} lg={4} className={classesGrid.chipsWrapper}>
            <Box className={classes.tableChipItem}>Fail</Box>
            <Box className={classes.tableChipItem}>Warning</Box>
            <Box className={classes.tableChipItem}>Pass</Box>
          </Grid>
          <Grid item xs={1} lg={1} className={classesGrid.chipsWrapper}></Grid>
          <Grid item xs={12} lg={2} className={classesGrid.dateTimeWrapper}>
            <Box>Last run</Box>
          </Grid>
        </Grid>
        <Box className={clsx(classes.actions, classes.tableHeadings)}></Box>
      </Box>
    </Box>
  );
}
