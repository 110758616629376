export interface PageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
}

export function mergePageInfo(oldPageInfo: PageInfo, newPageInfo: PageInfo): PageInfo {
  return {
    __typename: "PageInfo",
    startCursor: oldPageInfo.startCursor,
    endCursor: newPageInfo.endCursor,
    hasNextPage: newPageInfo.hasNextPage
  };
}

export const defaultMergeOptions = {
  customMerge: (key: string) => {
    if (key === "pageInfo") {
      return mergePageInfo;
    }
  }
};
