import { makeStyles, Accordion, AccordionSummary, Grid, Box, AccordionDetails } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { Severity } from "../../../generated-graphql-interfaces";
import { DeepcrawlInfoTooltip } from "../DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { TestCollapseDescription } from "../TestCollapseDescription/TestCollapseDescription";
import { Threshold, ReportTemplate } from "./TestCollapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[400]}`,
    boxShadow: "none"
  },
  expanded: {
    border: "none",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.08)",
    "& .MuiAccordionDetails-root": {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderTop: "none"
    }
  },
  testName: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    fontSize: 18
  },
  whiteText: {
    color: "#FFFFFF"
  },
  accordianSummaryRoot: {
    "& .Mui-expanded": {
      minHeight: 48
    }
  },
  accordianSummaryExpanded: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    minHeight: "0px !important",
    "& svg": {
      color: "#FFFFFF"
    },
    "& .MuiAccordionSummary-content": {
      margin: "18px 0px"
    }
  },
  content: {
    margin: "18px 0px"
  },
  accordianDetailsRoot: {
    paddingBottom: 0
  }
}));

interface TestExpansionPanelProps {
  threshold: Threshold;
  severity: Severity;
  reportTemplate: ReportTemplate;
  onChange: (input: { threshold: Threshold; severity: Severity; expanded: boolean }) => void;
  initialExpanded: boolean;
  children: React.ReactNode;
}

export function TestExpansionPanel(props: TestExpansionPanelProps): JSX.Element {
  const { reportTemplate, threshold, severity, onChange, initialExpanded } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);
  const classes = useStyles();

  return (
    <Accordion
      classes={{ root: classes.root, expanded: classes.expanded }}
      id={`${reportTemplate.code}-panel`}
      data-testid={`${reportTemplate.code}-panel`}
      data-pendo={`auto-test-suite-edit-choose-tests-test-${reportTemplate.code}`}
      expanded={isExpanded}
      onChange={(_, expanded) => {
        setIsExpanded(expanded);
        onChange({
          severity,
          threshold,
          expanded
        });
      }}
    >
      <AccordionSummary
        data-testid={`${reportTemplate.code}-summary`}
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: classes.accordianSummaryExpanded,
          content: classes.content,
          root: classes.accordianDetailsRoot
        }}
      >
        <Grid container alignItems="center" justify="space-between">
          <Box display="flex" alignItems="center">
            <Box className={clsx(classes.testName, isExpanded && classes.whiteText)} data-testid="test-collapse-name">
              {reportTemplate.name}
            </Box>
            {reportTemplate.automatorSummary && (
              <DeepcrawlInfoTooltip
                data-pendo="auto-tooltip-test-summary-info"
                body={reportTemplate.automatorSummary}
                testId="test-collapse-summary-tooltip"
                maxWidth={350}
                variant={isExpanded ? "white" : undefined}
              />
            )}
          </Box>
          <Box data-cy="test-severity-status-label" data-testid="test-severity-status-label">
            <TestCollapseDescription isExpanded={isExpanded} severity={severity} threshold={threshold} />
          </Box>
        </Grid>
      </AccordionSummary>
      <AccordionDetails data-cy={`${reportTemplate.code}-panel-details`} className={classes.accordianDetailsRoot}>
        {isExpanded && props.children}
      </AccordionDetails>
    </Accordion>
  );
}
