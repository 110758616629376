import React, { useState } from "react";
import { BuildInfo } from "../Dashboard";
import { Paper, makeStyles, Box, Divider, Typography, Button, Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BuildListItem } from "./BuildListItem";
import { sortBuildsByStatusAndDate } from "../utils/sortBuildsByStatusAndDate";
import { filterByBuildInfoStatus } from "../utils/filterByBuildInfoStatus";
import { Routes } from "../../../_common/routes/routes";

interface LatestTestRunsWidgetProps {
  buildsInfo: BuildInfo[];
  accountId: string;
}

const useStyles = makeStyles(theme => ({
  linkContainer: {
    padding: theme.spacing(3.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse"
  },
  listItemContainer: { minHeight: "510px", padding: theme.spacing(3.5, 3.5, 0, 3.5) },
  buildsMessageWrapper: { minHeight: "210px" },
  heading: { height: theme.spacing(8), padding: theme.spacing(0, 3.5, 0, 3.5), display: "flex", alignItems: "center" },
  h2: { fontSize: 16, fontWeight: 600, color: theme.palette.navy[500] },
  link: {
    textDecoration: "none"
  },
  subheading: {
    color: theme.palette.navy[300]
  },
  noRunsHeader: {
    fontWeight: 600
  },
  ul: {
    paddingInlineStart: "20px"
  },
  li: {
    lineHeight: 1.5
  },
  tab: {
    height: 58,
    fontSize: 14
  }
}));

export enum BuildStatusFilter {
  All = "All",
  Passed = "Pass",
  Failed = "Fail",
  Warning = "Warning"
}

const buildStatusArray = [
  BuildStatusFilter.All,
  BuildStatusFilter.Failed,
  BuildStatusFilter.Warning,
  BuildStatusFilter.Passed
];
// eslint-disable-next-line max-lines-per-function
export function LatestTestRunsWidget(props: LatestTestRunsWidgetProps) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const buildsToShow = buildStatusArray[tabIndex];
  const filteredBuilds = props.buildsInfo.filter(build => filterByBuildInfoStatus(build, buildsToShow));
  const sortedAndFilteredBuilds = sortBuildsByStatusAndDate(filteredBuilds);
  const showBuildCount = sortedAndFilteredBuilds.length > 5;

  function handleTabChange(_: React.ChangeEvent<{}>, newValue: number) {
    setTabIndex(newValue);
  }

  function getNoBuildsMessage() {
    const lowerCaseStatus = buildsToShow.toLowerCase();
    const statusMessage = buildsToShow !== BuildStatusFilter.Warning ? `${lowerCaseStatus}ed` : lowerCaseStatus;

    return `You have no ${statusMessage} builds.`;
  }

  return (
    <Paper data-testid="latest-test-run" data-cy="latest-test-run" data-pendo="auto-latest-test-suite-runs-widget">
      <Box className={classes.heading}>
        <Typography variant="h2" className={classes.h2}>
          Latest test suite runs
        </Typography>
      </Box>
      <Divider />
      <Box>
        <div className={classes.buildsMessageWrapper}>
          {!props.buildsInfo.length && (
            <Box p={5} data-testid="no-builds-message">
              <b className={classes.noRunsHeader}>No test runs yet</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Have you followed the steps to integrate Automation Hub into your CI/CD pipeline?
                </li>
                <li className={classes.li}>Have there been any deploys on your test suite&apos;s domain yet?</li>
              </ul>
            </Box>
          )}
          {props.buildsInfo.length > 0 && (
            <div>
              <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                <Tab
                  label="All"
                  data-testid="builds-filter-button-All"
                  data-pendo="auto-latest-testsuite-runs-all-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Fail"
                  data-testid="builds-filter-button-Fail"
                  data-pendo="auto-latest-testsuite-runs-fail-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Warning"
                  data-testid="builds-filter-button-Warning"
                  data-pendo="auto-latest-testsuite-runs-warning-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Pass"
                  data-testid="builds-filter-button-Pass"
                  data-pendo="auto-latest-testsuite-runs-pass-tab"
                  className={classes.tab}
                />
              </Tabs>
              <Divider />
              <Box className={classes.listItemContainer}>
                {!sortedAndFilteredBuilds.length && (
                  <Box display="flex" justifyContent="center">
                    <b>{getNoBuildsMessage()}</b>
                  </Box>
                )}
                {sortedAndFilteredBuilds.map((buildInfo, index) => {
                  return (
                    index < 5 && (
                      <BuildListItem
                        key={buildInfo.id}
                        buildInfo={buildInfo}
                        accountId={props.accountId}
                        isFirstItem={index === 0}
                      />
                    )
                  );
                })}
              </Box>
            </div>
          )}
        </div>
        <div className={classes.linkContainer}>
          <Link
            to={Routes.TestSuites.getUrl({ accountId: props.accountId })}
            data-testid="view-all-testsuites-link"
            data-pendo="auto-view-all-latest-testsuite-runs"
            className={classes.link}
          >
            <Button variant="outlined">View all</Button>
          </Link>
          {showBuildCount && (
            <Typography data-testid="test-suites-count" className={classes.subheading}>
              + {sortedAndFilteredBuilds.length - 5} more
            </Typography>
          )}
        </div>
      </Box>
    </Paper>
  );
}
