import { getTestSuites_getAccount_testSuites_edges } from "../../../generated-graphql-interfaces";
import { BuildInfo } from "../Dashboard";

export function buildsInfoFactory(testSuiteEdge: getTestSuites_getAccount_testSuites_edges): BuildInfo[] {
  const builds = testSuiteEdge.node.builds.nodes;
  return builds.map(build => {
    return {
      name: testSuiteEdge.node.name,
      sitePrimary: testSuiteEdge.node.sitePrimary,
      testSuiteId: testSuiteEdge.node.id,
      ...build
    };
  });
}
