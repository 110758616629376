import React, { useEffect, useState } from "react";
import { InformationBox, InformationBoxIcon } from "../../../_common/components/InformationBox/InformationBox";
import { useGetAutomatorAccountStatisticsVariables } from "../graphql/useGetAutomatorAccountStatisticsQuery";
import { Alert, Skeleton } from "@material-ui/lab";
import { Box, Fade, makeStyles } from "@material-ui/core";
import { getFormattedNumberString } from "../utils/getFormattedNumberString";
import { CSSProperties } from "@material-ui/styles";

export interface SeoHealthBarProps {
  accountId: string;
  timeStampForDataReload?: number | undefined; // in milliseconds // denontates the timestamp for thhe oldest data. If data are older than this timestamp they need to be refetched.
}

const useStyles = makeStyles(theme => ({
  errorWrapper: {
    marginBottom: theme.spacing(3)
  },
  infoBoxWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    ["@media (max-width: 1325px)"]: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      rowGap: "14px"
    },
    ["@media (max-width: 625px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(4, 1fr)"
    },
    ["@media (max-width: 320px)"]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "repeat(7, 1fr)"
    }
  }
}));

// eslint-disable-next-line max-lines-per-function
export function SeoHealthBar(props: SeoHealthBarProps) {
  const [lastTimeStampOfDataLoad, setLastTimeStampOfDataLoad] = useState<number | undefined>(new Date().getTime());
  const { loading, error, data, refetch } = useGetAutomatorAccountStatisticsVariables({
    accountId: props.accountId
  });

  useEffect(() => {
    if (
      lastTimeStampOfDataLoad &&
      props.timeStampForDataReload &&
      lastTimeStampOfDataLoad < props.timeStampForDataReload
    ) {
      setLastTimeStampOfDataLoad(new Date().getTime());
      refetch();
    }
  }, [props.timeStampForDataReload, lastTimeStampOfDataLoad, refetch]);

  const showData = !loading && !error;
  const hasTestSuites = (data?.getAutomatorAccountStatistics?.testSuitesCount || 0) > 0;

  const classes = useStyles();
  const fadeInStyles = (show: boolean): CSSProperties => ({ display: !show ? "none" : "block" });

  return hasTestSuites ? (
    <div data-testid="seo-health-bar" data-cy="seo-health-bar">
      <Fade in={!!error} style={fadeInStyles(!!error)} data-testid="seo-healthbar-error">
        <Alert severity="error" className={classes.errorWrapper}>
          Unable to load data for statistics
        </Alert>
      </Fade>

      <Fade in={loading} style={fadeInStyles(!!loading)} data-testid="seo-healthbar-skeleton-loader">
        <Box>
          <Skeleton variant="rect" height={76} style={{ marginBottom: 16 }} />
        </Box>
      </Fade>

      {showData && (
        <div className={classes.infoBoxWrapper} data-testid="seo-healthbar-boxes">
          <InformationBox
            pendoId="total-tests"
            testId={"seo-health-bar-total-test-suites"}
            isLoadingValue={loading}
            tooltip="The total number of all test suites in your account"
            label="Total number of test suites"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.testSuitesCount, false)}
          />
          <InformationBox
            pendoId="comaplete-builds"
            testId={"seo-health-bar-completed-builds"}
            isLoadingValue={loading}
            label="Total number of completed builds"
            tooltip="The total number of completed builds in your account."
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.buildsCount, false)}
          />
          <InformationBox
            pendoId="tests-performed"
            testId={"seo-health-bar-total-tests-performed"}
            isLoadingValue={loading}
            tooltip="The total number of tests performed, across all test suites in your account"
            label="Total number of tests performed"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.testsPerformedCount, false)}
          />
          <InformationBox
            pendoId="total-passes"
            testId={"seo-health-bar-total-passes"}
            isLoadingValue={loading}
            tooltip="The total percentage of passed tests across all builds in your account"
            label="Total passes"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.passedTestPercentage, true)}
            icon={InformationBoxIcon.CHECK}
          />
          <InformationBox
            pendoId="total-warnings"
            testId={"seo-health-bar-total-warnings"}
            isLoadingValue={loading}
            tooltip="The total percentage of warned tests across all builds in your account"
            label="Total warnings"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.warnedTestPercentage, true)}
            icon={InformationBoxIcon.WARNING}
          />
          <InformationBox
            pendoId="total-fails"
            testId={"seo-health-bar-total-fails"}
            isLoadingValue={loading}
            label="Total fails"
            tooltip="The total percentage of failed tests across all builds in your account"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics?.failedTestPercentage, true)}
            icon={InformationBoxIcon.ERROR}
          />
          <InformationBox
            pendoId="url-crawled"
            testId={"seo-health-bar-avg-urls-crawled"}
            isLoadingValue={loading}
            label="Avg URLs crawled p/day"
            tooltip="The average number of URLs crawled per day across all builds in your account"
            value={getFormattedNumberString(data?.getAutomatorAccountStatistics.averageUrlsCrawledPerDay, false)}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
