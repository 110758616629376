import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  headerButtonAndInfo: {
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      marginTop: theme.spacing(0)
    }
  },
  headerName: {
    alignItems: "center",
    minHeight: "40px"
  },
  headerBackIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
    fontSize: "30px",
    cursor: "pointer"
  },
  infoIcon: {
    color: theme.palette.navy[300],
    padding: 10,
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[300]
    }
  },
  paper: {
    border: `1px solid`,
    borderColor: theme.palette.grey[300],
    width: "100%",
    borderRadius: "3px"
  },
  listHeader: {
    padding: theme.spacing(2, 3.5, 2, 3.5),
    fontSize: "18px",
    lineHeight: "21px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  tableHeadings: {
    display: "flex",
    alignItems: "center",
    minHeight: "57px",
    padding: theme.spacing(0, 3.5, 0, 3.5),
    color: theme.palette.navy[300],
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  integrationsCount: {
    color: theme.palette.navy[300],
    fontSize: "16px",
    lineHeight: "18px"
  },
  loader: {
    marginTop: theme.spacing(3.5)
  },
  hidableContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  jiraListHeader: {
    marginLeft: theme.spacing(2),
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left"
  },
  title: {
    fontWeight: 500,
    fontSize: "26px",
    color: theme.palette.text.primary,
    marginRight: 12
  },
  headerContainer: {
    marginBottom: 58,
    marginTop: 34,
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5)
    }
  }
}));
