import React from "react";
import { Field, FieldArrayRenderProps, useFormikContext } from "formik";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useCrawlSettingsStyles } from "./CrawlSettingsForm";
import { TextField } from "formik-material-ui";
import AddIcon from "@material-ui/icons/Add";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { isRegexValid } from "../../../../_common/utils/regex/regex";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { DeleteButtonWithConfirmation } from "../../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";

export interface CustomExtraction {
  label: string;
  regex: string;
  __typename?: string;
}

// eslint-disable-next-line max-lines-per-function
export function CustomExtractions(props: FieldArrayRenderProps) {
  const { values } = useFormikContext<{
    customExtractions: CustomExtraction[];
  }>();
  const crawlSettingsStyles = useCrawlSettingsStyles();

  function validateRegex(value: string) {
    return isRegexValid(value) ? "" : "This regex is invalid";
  }

  return (
    <>
      <Box mb={7}>
        <Box display="flex" alignItems="center" mb={4}>
          <b className={crawlSettingsStyles.tooltipTitle} data-testid="custom-extraction-title">
            Custom extraction (optional)
          </b>
          <DeepcrawlInfoTooltip
            data-pendo="auto-tooltip-custom-extraction"
            maxWidth={400}
            body={crawlSettingsTooltips.customExtractions}
            testId="custom-extractions-tooltip"
            cyId="custom-extractions-tooltip"
          />
        </Box>
        <>
          {values.customExtractions.map((_, index) => {
            return (
              <Box key={index} mb={2}>
                <Grid container spacing={2}>
                  <Grid container item xs={11} md={10} spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name={`customExtractions[${index}].label`}
                        data-testid={`customExtractions[${index}].label`}
                        data-cy={`customExtractions-${index}-label`}
                        data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-name"
                        type="text"
                        label="Custom Extraction 1 - Name"
                        component={TextField}
                        fullWidth={true}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name={`customExtractions[${index}].regex`}
                        data-testid={`customExtractions[${index}].regex`}
                        data-cy={`customExtractions-${index}-regex`}
                        data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-regex"
                        type="text"
                        label="Regex pattern"
                        component={TextField}
                        validate={validateRegex}
                        fullWidth={true}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={1} md={2}>
                    <Box mt={1}>
                      <DeleteButtonWithConfirmation
                        data-pendo={`auto-custom-extractions-delete-button`}
                        dataTestId={`delete-custom-extraction-${index}`}
                        dataCy={`delete-custom-extraction-${index}`}
                        tooltipTitle="Remove custom extraction"
                        confirmationTitle="Are you sure you want to remove this custom extraction?"
                        confirmationText="Your custom extraction will be removed from your selected tests in step 3. Please remember to save your changes."
                        handleConfirmation={() => {
                          props.remove(index);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Grid item xs={12}>
            <Box component="div">
              {values.customExtractions.length < 1 ? (
                <Button
                  startIcon={<AddIcon />}
                  data-testid="add-custom-extraction-button"
                  data-cy="add-custom-extraction-button"
                  data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-add"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    // eslint-disable-next-line fp/no-mutating-methods
                    props.push({
                      label: "",
                      regex: ""
                    });
                  }}
                >
                  Add Custom Extraction Rule
                </Button>
              ) : (
                <Typography data-testid="add-custom-extractions-message" data-cy="add-custom-extractions-message">
                  Want to add more custom extractions?{" "}
                  <a
                    href="mailto:product@deepcrawl.com"
                    data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-contact-us"
                  >
                    Contact us.
                  </a>
                </Typography>
              )}
            </Box>
          </Grid>
        </>
      </Box>
    </>
  );
}
