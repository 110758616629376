import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import azureLogo from "../../../images/cicd-logos/azure-pipelines-logo.png";
import circleCiLogo from "../../../images/cicd-logos/circle-ci-logo.png";
import gitHubActionsLogo from "../../../images/cicd-logos/github-actions-logo.png";
import teamcityLogo from "../../../images/cicd-logos/teamcity-logo.png";
import travisLogo from "../../../images/cicd-logos/travis-logo.png";
import jenkinsLogo from "../../../images/cicd-logos/jenkins-logo.png";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  ciCdlogosWrapper: {
    margin: theme.spacing(3, 0),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  ciCdlogo: {
    padding: theme.spacing(1, 1.5)
  },
  andMore: {
    fontSize: 13,
    fontWeight: 800
  }
}));

const cicdTools: { name: string; src: string; width: number }[] = [
  { name: "Jenkins", src: jenkinsLogo, width: 70 },
  { name: "Travis CI", src: travisLogo, width: 70 },
  { name: "GitHub Actions", src: gitHubActionsLogo, width: 60 },
  { name: "TeamCity", src: teamcityLogo, width: 60 },
  { name: "Circle CI", src: circleCiLogo, width: 71 },
  { name: "Azure Pipelines", src: azureLogo, width: 83 }
];

export function CiCdToolsList() {
  const classes = useStyles();

  return (
    <Box className={classes.ciCdlogosWrapper} data-testid="ci-cd-tools">
      {cicdTools.map(cicdTool => {
        return (
          <Box key={cicdTool.name} className={classes.ciCdlogo} data-testid={`ci-cd-tools-item`}>
            {cicdTool.src && <img src={cicdTool.src} alt={`${cicdTool.name} Logo`} width={cicdTool.width * 1.3} />}
          </Box>
        );
      })}
      <Box className={clsx(classes.ciCdlogo, classes.andMore)}>+ More</Box>
    </Box>
  );
}
