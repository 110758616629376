import { AccordionClassKey } from "@material-ui/core";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";
import { PropsFunc } from "../overrides";

export const muiAccordion: Partial<Record<
  AccordionClassKey,
  CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>
>> = {
  root: {
    "&::before": {
      top: 0
    }
  }
};
