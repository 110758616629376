import { makeStyles, Popover } from "@material-ui/core";
import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ActionIconButton } from "../../../_common/components/ActionIconButton/ActionIconButton";
import { EditIcon } from "../../svg/EditIcon";
import { DeleteIcon } from "../../svg/DeleteIcon";
import { CopyIcon } from "../../svg/CopyIcon";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { PlayIcon } from "../../svg/PlayIcon";

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4)
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2)
  },
  listItemText: {
    color: theme.palette.navy[500]
  },
  icon: {
    color: theme.palette.navy[500]
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  runNowIcon: {
    color: theme.palette.secondary.main
  }
}));

export enum ActionMenuIconType {
  DELETE = "delete",
  EDIT = "edit",
  CLONE = "clone",
  RUN_NOW = "run_now"
}

export interface ActionMenuItem {
  label: string;
  id: string;
  iconType: ActionMenuIconType;
  dataId: string;
  closeMenuOnClick: boolean;
}

export interface ActionsMenuProps {
  onActionMenuEvent: (action: string, e: React.MouseEvent<HTMLElement>) => Promise<boolean>;
  customActions: ActionMenuItem[];
  pendoId: string;
}

export function ActionsMenu(props: ActionsMenuProps) {
  const classes = useStyles();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<null | HTMLElement>(null);

  function openActionsMenu(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setActionsMenuAnchorEl(event.currentTarget);
  }

  function closeActionsMenu() {
    setActionsMenuAnchorEl(null);
  }

  async function onActionSelection(e: React.MouseEvent<HTMLElement>, id: string) {
    const action = props.customActions.filter(item => item.id === id)[0];
    action.closeMenuOnClick && closeActionsMenu();
    e.stopPropagation();
    if (props.onActionMenuEvent) {
      const close = await props.onActionMenuEvent(id, e);
      close && closeActionsMenu();
    } else {
      closeActionsMenu();
    }
  }

  function getIcon(actionType: ActionMenuIconType) {
    switch (actionType) {
      case ActionMenuIconType.DELETE:
        return <DeleteIcon className={classes.deleteIcon} />;
      case ActionMenuIconType.EDIT:
        return <EditIcon className={classes.icon} />;
      case ActionMenuIconType.CLONE:
        return <CopyIcon className={classes.icon} />;
      case ActionMenuIconType.RUN_NOW:
        return <PlayIcon className={classes.runNowIcon} />;
      default:
        return <CopyIcon className={classes.icon} />;
    }
  }

  return (
    <>
      <ActionIconButton
        icon={<MoreVertIcon />}
        variant="info"
        onClick={openActionsMenu}
        isActive={Boolean(actionsMenuAnchorEl)}
        tooltipTitle="Actions"
        data-testid="actions-menu-button"
        includeBorder
        data-pendo={props.pendoId}
        data-cy="action-icon-button"
      />
      <Popover
        id="simple-menu"
        data-cy="simple-menu"
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={(e: React.MouseEvent<{}>) => {
          e.stopPropagation();
          closeActionsMenu();
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <List component="nav" data-testid="actions-menu-list">
          {props.customActions?.map(action => {
            return (
              <ListItem
                key={action.id}
                button
                data-testid={action.dataId}
                data-cy={action.dataId}
                data-pendo={`${props.pendoId}-${action.dataId}`}
                onClick={e => {
                  onActionSelection(e, action.id);
                }}
                classes={{ root: classes.listItemRoot }}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>{getIcon(action.iconType)}</ListItemIcon>
                <ListItemText primary={action.label} classes={{ primary: classes.listItemText }} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}
