import React, { useState } from "react";
import { StepChangeHandler } from "./UpdateTestSuite";
import { CustomStep } from "./CustomStep";
import { BreadcrumbPaths, CustomBreadcrumbs } from "../../_common/components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../_common/routes/routes";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { CreateTestSuite } from "./components/ConnectSiteForm/CreateTestSuite";
import { SaveChangesProvider } from "./SaveChangesProvider";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: "26px",
    margin: theme.spacing(0)
  },
  header: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2.5, 0, 2.5)
    }
  }
}));

export function SetupTestSuite(): JSX.Element {
  const [expanded, setExpanded] = useState("step1");
  const accountId = useAccountRouteMatch();
  const classes = useStyles();

  function handleChange(step: string, shouldExpandNextStep?: boolean): StepChangeHandler {
    return function (event?: React.ChangeEvent<{}>, isExpanded?: boolean): void {
      setExpanded(shouldExpandNextStep || isExpanded ? step : "");
    };
  }

  const breadcrumbContent = [
    {
      text: BreadcrumbPaths.MyTestSuites,
      link: Routes.TestSuites.getUrl({ accountId }),
      testDataId: "bcb-my-test-suites"
    },
    { text: BreadcrumbPaths.AddTestSuite, testDataId: "bcb-add-test-suite" }
  ];

  return (
    <SaveChangesProvider>
      <CustomBreadcrumbs content={breadcrumbContent} />
      <PageContainer>
        <Box className={classes.header}>
          <Typography variant="h1" className={classes.title} data-testid="setup-test-suite-title">
            Add Test Suite
          </Typography>
        </Box>

        <CustomStep
          isExpanded={expanded === "step1"}
          handleChange={handleChange("step1")}
          stepHeader="Connect site"
          stepNumber={1}
          id="step1"
          testId="step1-collapse-container"
          pendoId="auto-add-test-suite-step1"
        >
          <CreateTestSuite />
        </CustomStep>
        <CustomStep
          isExpanded={expanded === "step2"}
          stepHeader="Choose crawl settings"
          stepNumber={2}
          id="step2"
          testId="step2-collapse-container"
          pendoId="auto-add-test-suite-step2"
        />
        <CustomStep
          isExpanded={expanded === "step3"}
          stepHeader="Choose tests"
          stepNumber={3}
          id="step3"
          testId="step3-collapse-container"
          pendoId="auto-add-test-suite-step3"
        />
        <CustomStep
          isExpanded={expanded === "step4"}
          stepHeader="Set notifications"
          stepNumber={4}
          id="step4"
          testId="step4-collapse-container"
          pendoId="auto-add-test-suite-step4"
        />
        <CustomStep
          isExpanded={expanded === "step5"}
          stepHeader="Run test suite"
          stepNumber={5}
          id="step5"
          testId="step5-collapse-container"
          pendoId="auto-add-test-suite-step5"
        />
      </PageContainer>
    </SaveChangesProvider>
  );
}
