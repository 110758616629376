import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { AppRoutesPublic } from "./AppRoutesPublic";
import { Errors } from "../_common/components/Errors/Errors";
import { LocalStorageKeys } from "../_common/constants/localStorageKeys";

const useStyles = makeStyles({
  fullSizeContainer: {
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0"
  }
});

interface LoggedOutViewProps {
  logoutHandler: () => void;
  loginHandler: () => void;
}

export function LoggedOutView(props: LoggedOutViewProps) {
  const classes = useStyles();

  function handleLogin(input: { token?: string }) {
    if (input.token) {
      localStorage.setItem(LocalStorageKeys.TOKEN, input.token);
      props.loginHandler();
    }
  }

  return (
    <Container maxWidth={false} className={classes.fullSizeContainer} data-testid="logged-out-container">
      <Errors logoutCallback={props.logoutHandler} />
      <AppRoutesPublic loginHandler={handleLogin} />
    </Container>
  );
}
