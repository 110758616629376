import { ApolloClient, ApolloClientOptions } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { from } from "apollo-link";
import { version } from "../../../package.json";
import { authLink } from "./links/authLink";
import { errorLink } from "./links/errorLink";
import { afterwareLink } from "./links/afterwareLink";
import { uploadLink } from "./links/uploadLink";

const graphQLConfig: ApolloClientOptions<{}> = {
  name: "automator-web-client",
  version,
  cache: new InMemoryCache({
    resultCaching: false
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: from([authLink as any, errorLink, afterwareLink, uploadLink as any]) as any,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache"
    },
    mutate: {
      fetchPolicy: "no-cache"
    }
  }
};

export const graphQLClient = new ApolloClient(graphQLConfig);
