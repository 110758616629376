export function compareStrings(textA: string, textB: string) {
  if (textA === undefined && textB === undefined) {
    return 0;
  }
  if (textA === undefined) {
    return -1;
  }
  if (textB === undefined) {
    return 1;
  }

  return textA.localeCompare(textB, "en", { sensitivity: "base" });
}
