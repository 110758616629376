export function isRegexValid(regex: string) {
  const isCaseInsensitive = regex?.startsWith("(?i)");

  try {
    if (isCaseInsensitive) {
      new RegExp(regex.substring(4), "i");
    } else {
      new RegExp(regex);
    }
    return true;
  } catch (error) {
    return false;
  }
}
