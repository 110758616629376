import React, { useContext, useState } from "react";
import { Paper, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { NoTemplateSettingsView } from "./components/NoTemplateSettingsView";
import { GlobalTemplateApplied } from "./components/GlobalTemplateApplied";
import {
  getTestSuite_node_TestSuite_parent,
  linkChildTestSuiteToParentTestSuiteVariables,
  getTestSuiteChildren_node_TestSuite_children
} from "../../../../generated-graphql-interfaces";
import { Link } from "react-router-dom";
import { useLinkChildToParentMutation } from "./graphql/useLinkChildToParentMutation";
import { useUnlinkChildToParentMutation } from "./graphql/useUnlinkChildToParentMutation";
import { Alert, Skeleton } from "@material-ui/lab";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { ChildrenAddedSuccessfully } from "./components/ChildrenAddedSuccessfully";
import { permissionsContext } from "../../../../_common/contexts/Permissions/Permissions.context";
import clsx from "clsx";
import { Routes } from "../../../../_common/routes/routes";
import { useSnackbar } from "notistack";
import { CustomSnackbar } from "../../../../_common/components/CustomSnackbar/CustomSnackbar";
import { isResolutionWithin, ResolutionStep } from "../../../../_common/utils/window/window";
import { useTemplateSettingsStyles } from "./templateSettingsStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface TemplateSettingsProps {
  loading: boolean;
  loadError: boolean;
  parent: getTestSuite_node_TestSuite_parent | null;
  testSuiteId: string;
  childrenTestSuites: getTestSuiteChildren_node_TestSuite_children | null;
  testSuiteName: string;
  isFetchingMoreChildren: boolean;
  loadMoreChildren: () => void;
  isAutomaticThresholdEnabled: boolean;
}

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
export function TemplateSettings(props: TemplateSettingsProps) {
  const classes = useTemplateSettingsStyles(props);
  const [linkChildToParent, { error: linkError, loading: linkLoading }] = useLinkChildToParentMutation();
  const [unlinkChildToParent, { error: unlinkError, loading: unlinkLoading }] = useUnlinkChildToParentMutation();
  const [isExpanded, setIsExpanded] = useState<boolean>(isResolutionWithin(ResolutionStep.LG));
  const accountId = useAccountRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { permissions } = useContext(permissionsContext);

  if (!permissions.globalTemplatesAccess) {
    return <></>;
  }

  const children = props.childrenTestSuites?.edges;
  const childrenTotalCount = props.childrenTestSuites?.totalCount;
  const isLoading = linkLoading || unlinkLoading;

  async function handleUnlinkGlobalTemplate() {
    try {
      const { data } = await unlinkChildToParent({ variables: { childId: props.testSuiteId } });
      enqueueSnackbar(`${data?.unlinkChildTestSuiteFromParentTestSuite.parentTestSuite.name} unlinked successfully.`, {
        variant: "success"
      });
    } catch (e) {
      // no need to handle ... erros from Apollo are handling this
    }
  }

  async function handleApplyGlobalTemplate(parentTestSuiteId: string) {
    try {
      const variables: linkChildTestSuiteToParentTestSuiteVariables = {
        parentId: parentTestSuiteId,
        childId: props.testSuiteId
      };
      const { data } = await linkChildToParent({ variables });
      enqueueSnackbar("custom snackbar", {
        // eslint-disable-next-line react/display-name
        content: key => (
          <CustomSnackbar
            id={key}
            title={`${data?.linkChildTestSuiteToParentTestSuite.parentTestSuite.name} is now a global template for this test suite`}
            body={
              <>
                <Typography color="textPrimary" gutterBottom className={clsx(classes.condensedText, classes.textBlock)}>
                  Configuration for steps 2 and 3 of your global template is now applied to your test suite.{" "}
                  <Link to={Routes.EditTestSuite.getUrl({ accountId, testSuiteId: parentTestSuiteId })}>
                    Edit global template
                  </Link>
                </Typography>
                <Typography color="textPrimary" className={classes.condensedText}>
                  This test suite is set to web crawl
                </Typography>
                <Typography color="textPrimary" className={classes.condensedText}>
                  Start URLs and URL file lists are not copied
                </Typography>
              </>
            }
          />
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        autoHideDuration: 10000 // make sure unit test is covering this change.
      });
    } catch (e) {
      // do not need to catch this. Error is catched by Apollo error.
    }
  }

  async function handleUseAsGlobalTemplate(childTestSuiteId: string, childTestSuiteName: string) {
    try {
      const variables: linkChildTestSuiteToParentTestSuiteVariables = {
        parentId: props.testSuiteId,
        childId: childTestSuiteId
      };
      await linkChildToParent({ variables });
      const snackbarMessage = !children?.length
        ? `${props.testSuiteName} is a global template now.`
        : `${childTestSuiteName} added now.`;
      enqueueSnackbar(snackbarMessage, { variant: "success" });
    } catch (e) {
      // do not need to catch this. Error is catched by Apollo error.
    }
  }

  function getHeader() {
    if (props.parent) {
      return "Global template applied";
    }
    if (Boolean(children?.length)) {
      return "Global template";
    }
    return "Template settings";
  }

  const showNoTemplateInfo = !isLoading && !props.loading && !props.parent && !children?.length;
  const showGlobalTemplateApplied = !isLoading && !props.loading && !children?.length;
  const showChildrenView = !isLoading && !props.loading && !props.parent;

  return (
    <>
      <Paper className={classes.paper}>
        <Accordion
          expanded={isExpanded}
          onChange={(_, expanded) => {
            setIsExpanded(expanded);
          }}
        >
          <AccordionSummary
            classes={{
              root: classes.header,
              expanded: classes.accordionSummaryExpanded
            }}
            expandIcon={<ExpandMoreIcon />}
            data-pendo="auto-test-suite-edit-template-settings-block"
          >
            <Box display="flex" alignItems="center">
              <Typography className={classes.title} data-testid="template-settings-title">
                {getHeader()}
              </Typography>
              <DeepcrawlInfoTooltip
                data-pendo="auto-tooltip-template-setttings"
                maxWidth={450}
                toolTipClasses={{ tooltip: classes.tooltip }}
                iconComponent={<InfoOutlinedIcon className={classes.icon} />}
                title="Use global templates to link test suites together and replicate their configuration."
                body="Make changes in one global template and apply your changes across all template instances for step 2 and 3 of your test suite."
                testId="template-tooltip"
                cyId="template-tooltip"
                size={20}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.content
            }}
          >
            <Box className={classes.contentWrapper}>
              {(isLoading || props.loading) && <Skeleton variant="rect" height={200} />}
              {linkError && (
                <Alert severity="error" className={classes.error}>
                  An error occurred when trying to apply the global template. Please refresh the page and try again.
                </Alert>
              )}
              {unlinkError && (
                <Alert severity="error" className={classes.error}>
                  An error occurred when trying to unlink the global template. Please refresh the page and try again.
                </Alert>
              )}

              {props.loadError && (
                <Alert severity="error" className={classes.error}>
                  An error occurred when trying load Template information. Please refresh the page and try again.
                </Alert>
              )}
              {showGlobalTemplateApplied && props.parent && (
                <GlobalTemplateApplied parent={props.parent} unlinkGlobalTemplate={handleUnlinkGlobalTemplate} />
              )}
              {showNoTemplateInfo && (
                <NoTemplateSettingsView
                  isAutomaticThresholdEnabled={props.isAutomaticThresholdEnabled}
                  applyGlobalTemplate={handleApplyGlobalTemplate}
                  testSuiteId={props.testSuiteId}
                  useAsGlobalTemplate={handleUseAsGlobalTemplate}
                />
              )}
              {showChildrenView && !!children?.length && (
                <ChildrenAddedSuccessfully
                  childrenTestSuites={children}
                  totalAvailableChildren={childrenTotalCount}
                  loadMoreChildren={props.loadMoreChildren}
                  isFetchingMoreChildren={props.isFetchingMoreChildren}
                  useAsGlobalTemplate={handleUseAsGlobalTemplate}
                  testSuiteId={props.testSuiteId}
                />
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}
