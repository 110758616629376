import React from "react";

export const connectSiteTooltips = {
  nameAndDomain: (
    <>
      <b>Name</b>
      <p>Enter a descriptive name for the domain/subdomain to be tested. This will be used as the test suite name.</p>
      <p>
        <b>Domain/subdomain</b>
      </p>
      <p>We currently support pre-production (dev/staging environments) and production domains/subdomains only.</p>
      <p>
        If you would like to test locally on each Pull Request (PR) or in a different way, please{" "}
        <a href="mailto:product@deepcrawl.com">contact us</a> with more details.
      </p>
    </>
  ),
  authentication: "Our crawler will send these credentials to all pages on your website using basic authentication.",
  whitelisting: (
    <>
      <b>IP Settings</b>
      <p>
        DeepCrawl will crawl your website from the IP address 52.5.118.182 by default. If your crawl is blocked, or you
        need to crawl a website behind a firewall, such as a staging environment, give this IP address to your network
        administrators to be whitelisted.
      </p>
      <b>Crawler User Agent</b>
      <p>
        Enter details for the user agent you want the crawl to use when requesting pages. e.g. Googlebot/2.1
        (+http://www.google.com/bot.html)
      </p>
      <b>User Agent for Robots.txt Matching</b>
      <p>
        When checking whether a crawler is allowed to access a given URL, DeepCrawl will use the rules relevant to its
        user agent. Changing this field will change which rules the crawler uses.
      </p>
      <b>Custom DNS</b>
      <p>
        Custom DNS entries can be configured if your website does not have public DNS records, such as with a staging
        environment. Enter a DNS pair as you would in a host file e.g. testdomain.com 12.34.56.78
      </p>
    </>
  ),
  customDns: (
    <>
      <span>
        Custom DNS entries can be configured if your website does not have public DNS records, such as with a staging
        environment.
      </span>
      <p>
        Please note: Custom DNS does not work when rendering is on. For more information view{" "}
        <a href="https://www.deepcrawl.com/help/guides/javascript-rendering" target="_blank" rel="noopener noreferrer">
          rendering our JavaScript rendering guide
        </a>
        .
      </p>
    </>
  )
};
