import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useNoIntegrationsStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.primary.light,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px"
  },
  cardTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  cardText: {
    color: "#616161",
    fontSize: "0.875rem"
  },
  cardLogoWrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  cardContent: {
    maxWidth: 450,
    padding: theme.spacing(5, 6, 4, 6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 3, 3, 3)
    }
  },
  cardActions: {
    display: "flex",
    justifyContent: "left",
    padding: theme.spacing(0, 6, 6, 6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 3, 3, 3)
    }
  },
  icon: {
    color: theme.palette.deepcrawlOrange.main
  },
  integrationDetailsWrapper: {
    padding: theme.spacing(6),
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(6, 3)
    }
  },
  buttonsWrapper: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(4, 6),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 3)
    }
  },
  error: {
    marginBottom: theme.spacing(2)
  }
}));
