import React, { useState } from "react";
import { Box, Button, Grid, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { connectSiteTooltips } from "../connectSiteTooltips";
import { Field, useFormikContext } from "formik";
import { DeepcrawlInfoTooltip } from "../../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { dummyAuthPassword } from "../ConnectSiteForm";
import { ConfirmOrDelete } from "../../../../../_common/components/ConfirmOrDelete/ConfirmOrDelete";

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginRight: theme.spacing(1)
  },
  changePassword: {
    fontWeight: 500,
    paddingLeft: 0,
    paddingRight: 0
  },
  alert: {
    marginTop: 19
  },
  visibilityIcon: {
    color: "#45525B"
  },
  passwordInput: {
    "& .MuiInputBase-input.Mui-disabled": {
      "&:hover": {
        cursor: "not-allowed !important"
      }
    }
  }
}));

interface AuthenticationSettingsProps {
  isAuthPasswordInitiallySet: boolean;
  handleSaveAuthPassword: (newPassword: string) => void;
  authPasswordValue: string;
  hasAuthPasswordChanged: boolean;
  isWarningVisible: boolean;
  toggleWarning: (isVisible: boolean) => void;
}

interface AuthSettingsValues {
  siteTestUser: string;
  siteTestPass: string;
}

// eslint-disable-next-line max-lines-per-function, complexity
export function AuthenticationSettings(props: AuthenticationSettingsProps) {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext<AuthSettingsValues>();
  const [passwordSettings, setPasswordSettings] = useState({
    showPassword: false,
    showConfirmButton: false
  });
  const isPasswordSet = props.isAuthPasswordInitiallySet || props.hasAuthPasswordChanged;

  function handlePasswordInputFocus() {
    setPasswordSettings(s => ({ ...s, showConfirmButton: true }));
  }

  function handleClickShowPassword() {
    setPasswordSettings(s => ({ ...s, showPassword: !s.showPassword }));
  }

  function handleChangePassword() {
    setPasswordSettings(s => ({ ...s, showConfirmButton: true }));
    setFieldValue("siteTestPass", "");
  }

  function handleCancelPassword() {
    setPasswordSettings({ showConfirmButton: false, showPassword: false });
    const newValue = props.authPasswordValue ? dummyAuthPassword : "";
    setFieldValue("siteTestPass", newValue);
  }

  function handleConfirmPassword() {
    props.handleSaveAuthPassword(values.siteTestPass);
    setPasswordSettings({ showConfirmButton: false, showPassword: false });
    const newValue = values.siteTestPass ? dummyAuthPassword : "";
    setFieldValue("siteTestPass", newValue);
    props.toggleWarning(true);
  }

  return (
    <Box component="div" mt={7} data-cy="connect-site-form-auth-box">
      <Box component="div" display="flex" alignItems="center" mb={4}>
        <b className={classes.sectionTitle}>Authentication (optional)</b>
        <DeepcrawlInfoTooltip
          maxWidth={400}
          body={connectSiteTooltips.authentication}
          data-pendo="auto-tooltip-test-suite-authentication"
        />
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Field
            id="connect-site-form-auth-user"
            data-testid="connect-site-form-auth-user"
            data-pendo="auto-edit-add-test-suite-authentication-username"
            name="siteTestUser"
            type="text"
            label="Username"
            component={TextField}
            fullWidth={true}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            className={classes.passwordInput}
            id="connect-site-form-auth-pass"
            data-testid="connect-site-form-auth-pass"
            data-pendo="auto-edit-add-test-suite-authentication-password"
            name="siteTestPass"
            autoComplete="new-password"
            type={passwordSettings.showPassword ? "text" : "password"}
            label="Password"
            component={TextField}
            disabled={isPasswordSet && !passwordSettings.showConfirmButton}
            onFocus={handlePasswordInputFocus}
            fullWidth={true}
            variant="filled"
            InputProps={{
              endAdornment: passwordSettings.showConfirmButton ? (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.visibilityIcon}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    data-testid="toggle-password-visibility"
                    data-pendo="auto-edit-add-test-suite-authentication-password-view"
                  >
                    {passwordSettings.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }}
          />
          {passwordSettings.showConfirmButton && (
            <Box display="flex" justifyContent="flex-end" mt={2.5}>
              <ConfirmOrDelete
                dataTestId="auth-password"
                handleConfirm={handleConfirmPassword}
                handleCancel={handleCancelPassword}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {isPasswordSet && !passwordSettings.showConfirmButton && (
        <Button
          color="secondary"
          className={classes.changePassword}
          data-testid="change-password-button"
          data-pendo="auto-edit-add-test-suite-authentication-password-change"
          type="button"
          onClick={handleChangePassword}
        >
          Change password...
        </Button>
      )}
      {props.isWarningVisible && (
        <Alert className={classes.alert} severity="warning" data-testid="save-auth-password-alert">
          Please save your changes in ‘Step 1’ by clicking on the ‘Save’ button below.
        </Alert>
      )}
    </Box>
  );
}
