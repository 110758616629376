import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";

export function getPassWarnOrFail(isPassed: boolean, warnedTestCount: number | null) {
  if (!isPassed) {
    return ExtendedBuildStatus.Fail;
  }
  if (warnedTestCount) {
    return ExtendedBuildStatus.Warning;
  }
  return ExtendedBuildStatus.Pass;
}
