import React from "react";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { Typography, Grid, Paper, makeStyles, List, ListItem } from "@material-ui/core";
import AnchorScrollLink from "../../_common/components/AnchorScrollLink/AnchorScrollLink";
import { BackToTop } from "../../_common/components/BackToTop/BackToTop";

const useStyles = makeStyles(theme => ({
  overviewList: {
    paddingBottom: theme.spacing(4),
    listStyle: "inside"
  },
  overviewListItem: {
    display: "list-item"
  },
  text: {
    margin: theme.spacing(3.5)
  },
  headerBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3.5),
      marginRight: theme.spacing(3.5)
    }
  },
  textPageContainer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3.5)
    }
  }
}));

// eslint-disable-next-line max-lines-per-function
export function WhyAutomator() {
  const headerHeight = 100;
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.textPageContainer} id="faq" data-testid="why-automator">
        <BackToTop />
        <Grid container>
          <Grid item xs={12} className={classes.headerBox}>
            <PageHeader
              mainText="Why Automation Hub?"
              includeAddTestSuite={false}
              includeGoTo={true}
              pendoIdSuffix="why-automator"
            />
          </Grid>
          <Grid item xs={12} md={8} className={classes.text}>
            <Typography variant="h4" gutterBottom>
              Overview
            </Typography>
            <List className={classes.overviewList}>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#whatisautomator"
                  offset={headerHeight}
                  data-cy="link-whatisautomator"
                  data-pendo="auto-why-automator-what-is-automator"
                >
                  What is Automation Hub?
                </AnchorScrollLink>
              </ListItem>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#whoshoulduseautomator"
                  offset={headerHeight}
                  data-cy="link-whoshoulduseautomator"
                  data-pendo="auto-why-automator-why-automator"
                >
                  Who should use Automation Hub?
                </AnchorScrollLink>
              </ListItem>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#howautomatorhelps"
                  offset={headerHeight}
                  data-cy="link-howautomatorhelps"
                  data-pendo="auto-why-automator-how-automator-helps"
                >
                  How can Automation Hub help Developers?
                </AnchorScrollLink>
              </ListItem>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#helpQA"
                  offset={headerHeight}
                  data-cy="link-helpQA"
                  data-pendo="auto-why-automator-help-qa"
                >
                  How can Automation Hub help QAs?
                </AnchorScrollLink>
              </ListItem>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#helpRM"
                  offset={headerHeight}
                  data-cy="link-helpRM"
                  data-pendo="auto-why-automator-help-rm"
                >
                  How can Automation Hub help Release Managers?
                </AnchorScrollLink>
              </ListItem>
              <ListItem className={classes.overviewListItem}>
                <AnchorScrollLink
                  href="#helpSEO"
                  offset={headerHeight}
                  data-cy="link-helpSEO"
                  data-pendo="auto-why-automator-help-seo"
                >
                  How can Automation Hub help SEOs?
                </AnchorScrollLink>
              </ListItem>
            </List>
            <Typography variant="h5" gutterBottom id="whatisautomator" data-cy="heading-whatisautomator">
              What is Automation Hub?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              Engineering teams often release code without understanding the impact it can have on the overall SEO
              performance of the website.
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              This creates risk for sudden losses of traffic, rankings and in turn overall revenue.
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              DeepCrawl’s Automation Hub gives development teams the ability to test their code for SEO impact before
              pushing to production with a smart, automated and frictionless tool that allows for better collaboration
              between development & SEO/Marketing teams.
            </Typography>
            <Typography variant="h5" gutterBottom id="whoshoulduseautomator" data-cy="heading-whoshoulduseautomator">
              Who should use Automation Hub?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              Automation Hub has been designed to help solidify the communication between marketing and engineering
              teams and reduce the chance of human error.
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              In becoming part of the development process Automation Hub can be used by engineers, QAs, Release Managers
              and SEOs.
            </Typography>
            <Typography variant="h5" gutterBottom id="howautomatorhelps" data-cy="heading-howautomatorhelps">
              How can Automation Hub help Developers?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              Automation Hub works by integrating into a business’s CI / CD tools e.g Jenkins, Github Actions or
              TeamCity using a wide range of authentication options in order to cater to any need. Automation Hub
              enables SEO to become part of the core workflow which means teams can fix and identify problems
              proactively on an ongoing basis rather than having to go back after releases.
            </Typography>
            <Typography variant="h5" gutterBottom id="helpQA" data-cy="heading-helpQA">
              How can Automation Hub help QAs?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              Automation Hub can be set up to run alongside the normal QA process, reducing time needed to check for SEO
              issues. It can deploy across multiple pre-production test /QA environments and you can select from more
              than 160 tests and receive instant notifications of issues over email or slack.
            </Typography>
            <Typography variant="h5" gutterBottom id="helpRM" data-cy="heading-helpRM">
              How can Automation Hub help Release Managers?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              As Automation Hub is part of the development and QA process, releases can go ahead with greater confidence
              that errors will be significantly reduced. Automation Hub has an extensive and flexible set of
              configuration options that let you define the outcome of tests either by blocking a release or raising a
              warning. This saves time and money in correcting any problems.
            </Typography>
            <Typography variant="h5" id="helpSEO" gutterBottom data-cy="heading-helpSEO">
              How can Automation Hub help SEOs?
            </Typography>
            <Typography variant="body1" gutterBottom paragraph>
              Automation Hub helps SEO teams to maintain existing optimisation to prevent unnecessary traffic and
              revenue loss. Using Automation Hub as part of the QA process means many problems will be identified and
              fixed without the need for SEOs to be continuously involved with releases - saving you valuable time which
              can be spent on revenue generating opportunities.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
