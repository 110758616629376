import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AnchorScrollLink from "../AnchorScrollLink/AnchorScrollLink";
import { hexToRgbA } from "../../utils/colors/colors";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  floatingDiv: {
    zIndex: 1000,
    position: "fixed",
    bottom: "60px",
    right: "60px",
    width: "60px",
    height: "60px",
    background: hexToRgbA(theme.palette.secondary.main, 0.4, true),
    borderRadius: "32px",
    border: "2px solid white",
    transition: "0.15s all linear"
  },
  link: {
    cursor: "pointer",
    display: "block",
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "9px",
    color: "white",
    "&:hover": {
      color: "white"
    }
  },
  arrow: {
    fontSize: "40px"
  },
  notVisible: {
    visibility: "hidden",
    opacity: 0
  },
  visible: {
    opacity: 1,
    background: hexToRgbA(theme.palette.secondary.main, 0.4, true),
    "&:hover": {
      background: theme.palette.secondary.main
    }
  }
}));

export interface BackToTopProps {
  offset?: number;
}

export function BackToTop(props: BackToTopProps) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  window.onscroll = () => {
    setVisible(window.scrollY > 0);
  };

  return (
    <div
      data-testid="back-to-top"
      data-pendo="auto-scroll-to-top"
      className={clsx(classes.floatingDiv, visible && classes.visible, !visible && classes.notVisible)}
    >
      <AnchorScrollLink
        data-pendo="auto-back-to-top"
        resetToTop={true}
        className={classes.link}
        offset={props.offset}
        data-cy="back-to-top"
      >
        <ArrowUpwardIcon className={classes.arrow} />
      </AnchorScrollLink>
    </div>
  );
}
