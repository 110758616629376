import {
  getTestSuiteReport_node_Build_testResults_nodes_reportTemplate,
  getTestSuiteReport_node_Build_testSuite_customExtractions
} from "../../../../../generated-graphql-interfaces";

export function getCustomExtractionIndexInReportTemplates(
  reportTemplate: getTestSuiteReport_node_Build_testResults_nodes_reportTemplate,
  customExtractions: getTestSuiteReport_node_Build_testSuite_customExtractions[] | undefined | null
) {
  return Array.isArray(customExtractions)
    ? customExtractions.map(ce => ce.reportTemplateCode).indexOf(reportTemplate.code)
    : -1;
}

export function isCustomExtractionInReportTemplate(
  reportTemplate: getTestSuiteReport_node_Build_testResults_nodes_reportTemplate,
  customExtractions: getTestSuiteReport_node_Build_testSuite_customExtractions[] | undefined | null
) {
  return getCustomExtractionIndexInReportTemplates(reportTemplate, customExtractions) !== -1;
}
