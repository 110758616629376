import React, { useEffect, useRef, useState } from "react";
import { MutationFunctionOptions } from "@apollo/react-common";
import {
  updateCrawlSettingsVariables,
  getTestSuite_node_TestSuite,
  updateCrawlSettings_updateTestSuite,
  CustomExtractionSettingInput
} from "../../../../generated-graphql-interfaces";
import { Formik, Form, FieldArray, FormikHelpers, FormikProps } from "formik";
import { InputLabel, Grid, Box, makeStyles, Typography } from "@material-ui/core";
import { textAreaValueToArray } from "../../../../_common/utils/array/coerce/textAreaValueToArray";
import { UrlSource } from "./UrlSource";
import { CollapseHeader } from "../CollapseHeader";
import { crawlSettingsFormValidation } from "./crawlSettingsFormValidation";
import { DeepCrawlSlider } from "../../../../_common/components/DeepCrawlSlider/DeepCrawlSlider";
import { isValidUrlArray } from "./utils/isValidUrlArray";
import { IncludeExcludeUrls } from "./IncludeExcludeUrls";
import { RobotsOverwrite } from "./RobotsOverwrite";
import { JsRenderingSettings } from "./JsRenderingSettings";
import { UrlLimit } from "./UrlLimit";
import { TestSettings } from "./TestSettings";
import { EnableJsRendering } from "./EnableJsRendering";
import { ValidationErrors } from "../../../../validation/ValidationErrors";
import { CustomExtractions } from "./CustomExtractions";
import {
  getArrayOfCrawlTypes,
  getInitialValuesFromTestSuite,
  removeTypenameFromCustomExtractions
} from "./utils/crawlSettingsFormUtils";
import { StartUrls } from "./StartUrls";
import { FloatingStepSave } from "../FloatingStepSave/FoatingStepSave";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { useSaveChangesContext } from "../../SaveChangesProvider";

export interface CrawlSettingsFormProps {
  handleSave: (
    variables:
      | MutationFunctionOptions<
          {
            updateTestSuite: updateCrawlSettings_updateTestSuite;
          },
          updateCrawlSettingsVariables
        >
      | undefined
  ) => Promise<void>;
  testSuite: getTestSuite_node_TestSuite;
  onSubmit: StepChangeHandler;
  refetchData: () => void;
}

export const useCrawlSettingsStyles = makeStyles(theme => ({
  label: {
    display: "inline"
  },
  tooltipTitle: {
    marginRight: theme.spacing(1)
  },
  inputLabel: {
    marginBottom: theme.spacing(4)
  },
  labelTitle: {
    fontWeight: "bold",
    color: theme.palette.text.primary
  },
  padding: {
    padding: theme.spacing(3, 3.5, 3, 3.5)
  }
}));

// eslint-disable-next-line max-lines-per-function
export function CrawlSettingsForm(props: CrawlSettingsFormProps): JSX.Element {
  const { testSuite } = props;
  const classes = useCrawlSettingsStyles();
  const [crawlRate, setCrawlRate] = useState(Math.min(testSuite.crawlRate, testSuite.account.maxCrawlRate));
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(getInitialValuesFromTestSuite(props.testSuite));
  type InitialValues = typeof initialValues;
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const saveChangesContext = useSaveChangesContext();

  useEffect(() => {
    setInitialValues(getInitialValuesFromTestSuite(testSuite));
    setCrawlRate(Math.min(testSuite.crawlRate, testSuite.account.maxCrawlRate));
  }, [testSuite]);

  function handleSliderChange(_: React.ChangeEvent<{}>, newValue: number | number[]): void {
    const sliderValue = typeof newValue === "number" ? newValue : newValue[0];
    setCrawlRate(sliderValue);
  }

  async function handleSave() {
    if (formikRef.current) {
      const { values } = formikRef.current;

      await props.handleSave({
        variables: {
          ...values,
          customExtractions: removeTypenameFromCustomExtractions(
            values.customExtractions || []
          ) as CustomExtractionSettingInput[],
          testSuiteId: testSuite.id,
          crawlTypes: getArrayOfCrawlTypes(values.crawlTypes),
          urlsExcluded: textAreaValueToArray(values.urlsExcluded),
          urlsIncluded: textAreaValueToArray(values.urlsIncluded),
          rendererBlockCustom: textAreaValueToArray(values.rendererBlockCustom),
          rendererJsUrls: textAreaValueToArray(values.rendererJsUrls),
          crawlRate,
          useRenderer: values.useRenderer === "true",
          startUrls: values.startUrls.length ? [values.startUrls] : []
        }
      });
    }
  }

  useEffect(() => {
    if (!testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlRate]);

  async function handleSubmit(values: InitialValues, actions: FormikHelpers<InitialValues>): Promise<void> {
    const isValid = await isValidUrlArray(textAreaValueToArray(values.rendererJsUrls));

    if (!isValid) {
      actions.setFieldError("rendererJsUrls", ValidationErrors.RenderJsUrls);
      actions.setSubmitting(false);
      return;
    }
    await props.handleSave({
      variables: {
        ...values,
        customExtractions: removeTypenameFromCustomExtractions(
          values.customExtractions || []
        ) as CustomExtractionSettingInput[],
        testSuiteId: testSuite.id,
        crawlTypes: getArrayOfCrawlTypes(values.crawlTypes),
        urlsExcluded: textAreaValueToArray(values.urlsExcluded),
        urlsIncluded: textAreaValueToArray(values.urlsIncluded),
        rendererBlockCustom: textAreaValueToArray(values.rendererBlockCustom),
        rendererJsUrls: textAreaValueToArray(values.rendererJsUrls),
        crawlRate,
        useRenderer: values.useRenderer === "true",
        startUrls: values.startUrls.length ? [values.startUrls] : []
      }
    });
    props.refetchData();
    props.onSubmit();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={crawlSettingsFormValidation}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formikRef}
    >
      <Form>
        <Grid container className={classes.padding}>
          <Grid item xs={12}>
            <Box mb={7}>
              <Typography variant="body1">
                Configure how the crawler treats your website and the URLs that will be crawled.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={7}>
              <UrlSource testSuiteId={testSuite.id} coreUIUrl={testSuite.coreUIUrl} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <EnableJsRendering />
          </Grid>
          <Grid item xs={12}>
            <Box mb={7}>
              <InputLabel
                htmlFor="crawl-rate"
                className={`${classes.inputLabel} ${classes.labelTitle}`}
                data-cy="max-crawl-speed-label"
              >
                Maximum Crawl Speed: {crawlRate} URL/s
              </InputLabel>
              <DeepCrawlSlider
                id="crawl-rate"
                min={1}
                max={testSuite.account.maxCrawlRate}
                step={1}
                value={crawlRate}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                data-cy="crawl-speed-slider"
                data-testid="crawl-speed-slider"
                data-pendo="auto-test-suite-edit-choose-crawl-settings-max-crawl-speed"
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <UrlLimit />
          </Grid>

          <Grid item xs={12} md={10}>
            <FieldArray
              name="customExtractions"
              // eslint-disable-next-line
              component={CustomExtractions as any}
            />
          </Grid>

          <Grid item xs={12}>
            <CollapseHeader
              pendoId="auto-edit-add-test-suite-advanced-settings"
              isOpen={isCollapseOpen}
              onClick={() => {
                setIsCollapseOpen(!isCollapseOpen);
              }}
              name="Advanced Settings"
              testId="advanced-choose-crawl-settings-collapse-container"
            >
              <Grid container item xs={12}>
                <StartUrls />
                <IncludeExcludeUrls />
                <RobotsOverwrite />
                <JsRenderingSettings />
                <TestSettings />
              </Grid>
            </CollapseHeader>
          </Grid>
        </Grid>
        <FloatingStepSave
          label="Save"
          dataTestId="crawl-settings-form-save"
          dataCy="crawl-settings-form-save"
          data-pendo="auto-testsuite-edit-add-crawl-settings-save-step"
        />
      </Form>
    </Formik>
  );
}
