import React from "react";
import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    fontWeight: 600
  },
  chipTitleWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  statusWrapper: {
    minWidth: 70 + theme.spacing(2)
  },
  grid: {
    marginRight: 150
  },
  test: {
    textAlign: "center",
    width: "25%"
  },
  wrapper: {
    margin: theme.spacing(2, 0, 4, 0),
    marginRight: "9px",
    marginLeft: "30px"
  }
}));

export function BuildListTableHeader() {
  const classes = useStyles();

  return (
    <Hidden mdDown>
      <Box data-testid="build-list-table-header" display="flex" alignItems="center" className={classes.wrapper}>
        <Box className={classes.statusWrapper}>
          <Typography color="textPrimary" className={classes.text} data-testid="build-list-table-header-status">
            Status
          </Typography>
        </Box>
        <Grid container className={classes.grid}>
          <Grid item xs={2}>
            <Typography color="textPrimary" className={classes.text} data-testid="build-list-table-header-last-run">
              Last run
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              noWrap
              color="textPrimary"
              className={classes.text}
              data-testid="build-list-table-header-duration"
            >
              Duration
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.chipTitleWrapper}>
            <Typography
              color="textPrimary"
              className={clsx(classes.text, classes.test)}
              data-testid="build-list-table-header-tests"
            >
              Tests
            </Typography>
            <Typography
              color="textPrimary"
              className={clsx(classes.text, classes.test)}
              data-testid="build-list-table-header-fail"
            >
              Fail
            </Typography>
            <Typography
              color="textPrimary"
              className={clsx(classes.text, classes.test)}
              data-testid="build-list-table-header-warning"
            >
              Warning
            </Typography>
            <Typography
              color="textPrimary"
              className={clsx(classes.text, classes.test)}
              data-testid="build-list-table-header-pass"
            >
              Pass
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.chipTitleWrapper}>
            <Typography color="textPrimary" className={classes.text} data-testid="build-list-table-header-run">
              Run
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Hidden>
  );
}
