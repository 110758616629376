import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { CSSProperties } from "react";
import { PropsFunc } from "../overrides";
import { colorSecondary } from "../palette";

export const muiPickersDay:
  | Partial<
      Record<string | number | symbol, CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>>
    >
  | undefined = {
  daySelected: {
    backgroundColor: colorSecondary.main,
    "&:hover": {
      backgroundColor: colorSecondary.main
    }
  }
};
