import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { ActionIconButton } from "../ActionIconButton/ActionIconButton";
import { useSnackbar } from "notistack";
import { CopyIcon } from "../../svg/CopyIcon";

export interface CopyBlockProps {
  label: string;
  text: string;
  copyMessage: string;
}

const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 1, 1, 2),
    maxWidth: "450px"
  },
  copyLabel: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "18px"
  },
  copyText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px"
  }
}));

export function CopyBlock(props: CopyBlockProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function copyToClipboard() {
    navigator.clipboard.writeText(props.text);
    enqueueSnackbar(props.copyMessage, { variant: "success" });
  }

  return (
    <Box className={classes.box}>
      <Box data-cy="test-suite-id" data-testid="test-suite-id">
        <Box className={classes.copyLabel}>Test Suite ID</Box>
        <Box className={classes.copyText}>{props.text}</Box>
      </Box>
      <Box>
        <ActionIconButton
          onClick={copyToClipboard}
          variant="info"
          backgroundColor="white"
          icon={<CopyIcon />}
          tooltipTitle="Copy test suite ID"
          data-testid="copy-block-action"
          data-cy="copy-block-action"
          data-pendo="auto-step5-copy-testsuite-id"
          includeBorder={false}
        />
      </Box>
    </Box>
  );
}
