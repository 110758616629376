// The folowing eslint rule is here only cause the import:
// import "@material-ui/lab/themeAugmentation"; throws error :
// "Can't resolve '@material-ui/lab/themeAugmentation' in ... "
// it needs to be present so the types from the Material UI Labs are overwritable.
// See: https://material-ui.com/customization/components/#global-theme-override
//eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as thisDoesNowWorkIfThisIsNotHere from "@material-ui/lab/themeAugmentation";

import { Overrides } from "@material-ui/core/styles/overrides";
import { muiAccordion } from "./overrides/muiAccordion";
import { muiAutocomplete } from "./overrides/muiAutocomplete";
import { muiButton } from "./overrides/muiButton";
import { muiFilledInput } from "./overrides/muiFilledInput";
import { muiInputBase } from "./overrides/muiInputBase";
import { muiInputLabel } from "./overrides/muiInputLabel";
import { muiPaper } from "./overrides/muiPaper";
import { muiTypography } from "./overrides/muiTypography";
import { muiPickersDay } from "./overrides/muiPickersDay";
import { muiAlert } from "./overrides/muiAlert";

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsFunc<Props extends object, T> = (props: Props) => T;

export const overrides: Overrides = {
  MuiTypography: muiTypography,
  MuiInputBase: muiInputBase,
  MuiFilledInput: muiFilledInput,
  MuiInputLabel: muiInputLabel,
  MuiButton: muiButton,
  MuiPaper: muiPaper,
  MuiAccordion: muiAccordion,
  MuiAutocomplete: muiAutocomplete,
  MuiPickersDay: muiPickersDay,
  MuiAlert: muiAlert,
  MuiFormControlLabel: {
    label: {
      color: "#24333E"
    }
  }
};
