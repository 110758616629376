import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  getScheduleForScheduleSettingsVariables,
  getScheduleForScheduleSettings
} from "../../../../generated-graphql-interfaces";

export const getScheduleForScheduleSettingsGql = gql`
  query getScheduleForScheduleSettings($buildScheduleId: ObjectID!) {
    node(id: $buildScheduleId) {
      ... on BuildSchedule {
        id
        name
        repetitionRate
        startAt
        buildScheduleTestSuites(first: 100) {
          nodes {
            testSuite {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export function useGetScheduleForScheduleSettings(buildScheduleId: string) {
  return useQuery<getScheduleForScheduleSettings, getScheduleForScheduleSettingsVariables>(
    getScheduleForScheduleSettingsGql,
    { variables: { buildScheduleId }, fetchPolicy: "cache-and-network" }
  );
}
