import React, { useState, useContext } from "react";
import { TestThreshold } from "../TestThreshold/TestThreshold";
import { Severity, ThresholdType, ThresholdPredicate } from "../../../generated-graphql-interfaces";
import { Box, Collapse, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TestSeverityButton } from "../TestSeverityButton/TestSeverityButton";
import { EnableJira } from "../EnableJira/EnableJira";
import { permissionsContext } from "../../contexts/Permissions/Permissions.context";
import { ExtendedTest } from "../../../pages/TestSuite/components/ChooseTestsForm/CreateTests.interfaces";
import { TestExpansionPanel } from "./TestExpansionPanel";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DeepcrawlInfoTooltip } from "../DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import { DeepCrawlSwitch } from "../DeepCrawlSwitch/DeepCrawlSwitch";
import clsx from "clsx";
import { isEqual } from "lodash";

const useStyles = makeStyles(theme => ({
  testName: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    fontSize: 18
  },
  whiteText: {
    color: "#FFFFFF"
  },
  severityButton: {
    fontSize: "16px"
  },
  content: {
    margin: "18px 0px"
  },
  testSeverityText: {
    fontWeight: 500,
    marginBottom: "12px"
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(2)
  },
  subtitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2px 0px"
  },
  icon: {
    color: theme.palette.navy[200]
  },
  divider: {
    color: theme.palette.navy[50]
  },
  topDivider: {
    marginTop: theme.spacing(5)
  }
}));

export interface TestThresholdPredicate {
  label: string;
  value: ThresholdPredicate;
  default: boolean;
}

export interface Threshold {
  relative: number;
  absolute: number;
  type: ThresholdType;
  predicate: ThresholdPredicate;
}

export interface ReportTemplate {
  name: string;
  automatorSummary: string | null;
  code: string;
}

export interface TestCollapseProps {
  isJiraIntegrationConnected: boolean;
  test: ExtendedTest;
  onChange: (input: { threshold: Threshold; severity: Severity; expanded: boolean }) => void;
  handleEnableJiraChange: (input: {
    createJiraTicketOnFailure: boolean | null;
    jiraTicketCustomNote: string | null;
  }) => void;
  showSmartThresholdSettings: boolean;
  handleSmartThresholdChange: (test: ExtendedTest, isSmartThresholdEnabled: boolean) => void;
}

export function arePropsEqual(prevProps: TestCollapseProps, newProps: TestCollapseProps) {
  // We only want to return true if both objects are `equivalent`.
  // By default, React only does a shallow comparison of props, and since props.test is an object,
  // it will always return false. Hence, we are using a custom function.
  const isJiraIntegrationStatusTheSame = prevProps.isJiraIntegrationConnected === newProps.isJiraIntegrationConnected;
  const isSmartThresholdTheSame = prevProps.showSmartThresholdSettings === newProps.showSmartThresholdSettings;
  return isEqual(prevProps.test, newProps.test) && isSmartThresholdTheSame && isJiraIntegrationStatusTheSame;
}

function TestCollapsable(props: TestCollapseProps): JSX.Element {
  const { onChange, test } = props;
  const defaultThreshold = {
    relative: test.data.relativeThreshold,
    absolute: test.data.absoluteThreshold,
    type: test.data.thresholdType,
    predicate: test.data.thresholdPredicate
  };
  const [threshold, setThreshold] = useState<Threshold>(defaultThreshold);
  const [severity, setSeverity] = useState<Severity>(test.data.severity);
  const { permissions } = useContext(permissionsContext);
  const classes = useStyles();

  function handleSeverityChange(severity: Severity): void {
    setSeverity(severity);
    onChange({
      severity,
      threshold,
      expanded: test.extended.expanded
    });
  }

  return (
    <TestExpansionPanel
      threshold={threshold}
      severity={severity}
      reportTemplate={test.data.reportTemplate}
      initialExpanded={test.extended.expanded}
      onChange={onChange}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography color="textPrimary" className={classes.testSeverityText}>
              Choose test severity
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} container>
          <TestSeverityButton
            severityType={Severity.Fail}
            isActive={severity === Severity.Fail}
            handleClick={() => {
              handleSeverityChange(Severity.Fail);
            }}
          />
        </Grid>
        <Grid item xs={6} container>
          <TestSeverityButton
            severityType={Severity.Warning}
            isActive={severity === Severity.Warning}
            handleClick={() => {
              handleSeverityChange(Severity.Warning);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Collapse in={props.showSmartThresholdSettings}>
            <Box>
              <Divider className={clsx(classes.divider, classes.topDivider)} />
              <Box className={classes.subtitleWrapper}>
                <Box display="flex" alignItems="center">
                  <Typography
                    color="textPrimary"
                    data-testid="automatic-threshold-subtitle"
                    className={classes.subtitle}
                  >
                    Use smart thresholds
                  </Typography>
                  <DeepcrawlInfoTooltip
                    data-pendo="auto-tooltip-smart-threshold"
                    title="Smart thresholds"
                    testId="smart-threshold-tooltip"
                    size={20}
                    maxWidth={450}
                    iconComponent={<InfoOutlinedIcon fontSize="small" className={classes.icon} />}
                    body={
                      <>
                        <p>
                          Learn from previous test results and update test thresholds automatically. The more tests you
                          run and the better your results, the more strict your thresholds will become and the better
                          your SEO outcome will be. The test settings will be set to an optimal value based on previous
                          test suite runs.
                        </p>
                        <ul style={{ paddingInlineStart: 15 }}>
                          <li>
                            If a test passes with a value better than the test threshold, the threshold will
                            automatically update to the appropriate new value.
                          </li>
                          <li>
                            When enabled, the test thresholds will be optimized for all tests in this test suite. If you
                            want to enable this for specific tests only, enable the &quot;Set on a per test basis&quot;
                            toggle.
                          </li>
                        </ul>
                      </>
                    }
                  />
                </Box>
                <DeepCrawlSwitch
                  data-cy="automatic-threshold-toggle-individual-test"
                  inputProps={{ "aria-label": "Smart threshold toggle" }}
                  checked={props.test.data.automaticThresholdEnabled}
                  onChange={event => {
                    props.handleSmartThresholdChange(props.test, event.target.checked);
                  }}
                />
              </Box>
              <Divider className={classes.divider} />
            </Box>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <TestThreshold
            testCode={test.data.reportTemplate.code}
            threshold={threshold}
            severity={severity}
            onChange={(threshold): void => {
              setThreshold(threshold);
              onChange({
                severity,
                threshold,
                expanded: test.extended.expanded
              });
            }}
          />
        </Grid>
        {permissions.jiraIntegrationAccess ? (
          <Grid item xs={12}>
            <EnableJira
              test={props.test}
              handleEnableJiraChange={props.handleEnableJiraChange}
              isJiraIntegrationConnected={props.isJiraIntegrationConnected}
            />
          </Grid>
        ) : null}
      </Grid>
    </TestExpansionPanel>
  );
}

export const TestCollapse = React.memo(TestCollapsable, arePropsEqual);
