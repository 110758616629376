/* eslint-disable max-lines */
import {
  getReportTemplates_getAutomatorReportTemplates_edges,
  ThresholdType,
  Severity,
  getTestSuite_node_TestSuite_customExtractions,
  ThresholdPredicate,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate,
  getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions,
  createTest,
  deleteTest,
  updateTest,
  updateTestVariables,
  createTestVariables
} from "../../../../generated-graphql-interfaces";
import { TestCategory, ExtendedTest, CategoriesCode } from "./CreateTests.interfaces";
import {
  recommendedTestCodesList,
  recommendedTestsCategory,
  RECOMMENDED_TESTS_CATEGORY_CODE
} from "./CreateTestsCommonConst";
import { compareStrings } from "../../../../_common/utils/string/comparisons/comparisons";
import { compareTestByName, getSelectedTests } from "./CreateTestsCommonUtils";
import { getCustomExtractionTestName } from "../../../../_common/utils/getCustomExtractionTestName/getCustomExtractionTestName";
import { isCustomExtractionInReportTemplate } from "./utils/customExtractionWithReportTemplate";
import { getCustomExtractionTooltip } from "../../../../_common/utils/getCustomExtractionTooltip/getCustomExtractionTooltip";
import { ExecutionResult } from "graphql";

function getSelectedTestsFromData(
  tests: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests,
  reportTemplateCode: string
) {
  return tests.nodes?.find(test => test.reportTemplate.code === reportTemplateCode);
}

function getCategoryCode(reportTemplate: getReportTemplates_getAutomatorReportTemplates_edges) {
  if (Array.isArray(reportTemplate.node.categories) && reportTemplate.node.categories.length) {
    return reportTemplate.node.categories[0] === CategoriesCode.CUSTOM_EXTRACTION
      ? reportTemplate.node.categories[0]
      : reportTemplate.node.categories[1];
  } else {
    return "other";
  }
}

function isTestCodeInRecomendedList(code: string) {
  return recommendedTestCodesList.indexOf(code) !== -1;
}

function compareCategoryByName(catA: TestCategory, catB: TestCategory) {
  return compareStrings(catA.name, catB.name);
}

function getExtendTestFromTemplates(
  reportTemplate: getReportTemplates_getAutomatorReportTemplates_edges,
  template: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate
): ExtendedTest {
  return {
    data: {
      id: "",
      __typename: "Test",
      relativeThreshold: 10,
      createJiraTicketOnFailure: false,
      jiraTicketCustomNote: null,
      absoluteThreshold: 10,
      thresholdType: ThresholdType.Relative,
      severity: Severity.Fail,
      reportTemplate: {
        __typename: "ReportTemplate",
        categories: reportTemplate.node.categories,
        code: template.code,
        id: reportTemplate.node.id,
        name: template.name,
        automatorSummary: template.automatorSummary
      },
      createdAt: new Date(),
      updatedAt: new Date(),
      reportTemplateCode: reportTemplate.node.code,
      thresholdPredicate: ThresholdPredicate.GreaterThanOrEqual,
      automaticThresholdEnabled: false
    },
    extended: {
      selected: false,
      expanded: false,
      category: "",
      recomended: isTestCodeInRecomendedList(reportTemplate.node.code)
    }
  };
}

function getTestNameForCustomExtraction(
  testInitState: ExtendedTest,
  customExtractions: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | null | undefined
) {
  return getCustomExtractionTestName(testInitState.data.reportTemplate, customExtractions);
}

function createCompleteTestList(
  reportTemplate: getReportTemplates_getAutomatorReportTemplates_edges,
  testSuite: getTestSuiteTestsAndCustomExtractions_node_TestSuite
): ExtendedTest {
  const template: getTestSuiteTestsAndCustomExtractions_node_TestSuite_tests_nodes_reportTemplate = {
    ...reportTemplate.node,
    __typename: "ReportTemplate"
  };
  const testInitState: ExtendedTest = getExtendTestFromTemplates(reportTemplate, template);

  const selectedTest = getSelectedTestsFromData(testSuite.tests, reportTemplate.node.code);

  const categoryCode = getCategoryCode(reportTemplate);

  const testName =
    categoryCode === CategoriesCode.CUSTOM_EXTRACTION
      ? getTestNameForCustomExtraction(testInitState, testSuite.customExtractions)
      : testInitState.data.reportTemplate.name;

  const testSummary =
    categoryCode === CategoriesCode.CUSTOM_EXTRACTION
      ? getCustomExtractionTooltip(testName)
      : testInitState.data.reportTemplate.automatorSummary;

  if (selectedTest) {
    return {
      data: {
        ...testInitState.data,
        ...selectedTest,
        reportTemplate: {
          ...testInitState.data.reportTemplate,
          name: testName,
          automatorSummary: testSummary
        }
      },
      extended: {
        ...testInitState.extended,
        selected: true,
        category: categoryCode
      }
    };
  }

  return {
    data: {
      ...testInitState.data,
      __typename: "Test",
      reportTemplate: {
        ...reportTemplate.node,
        __typename: "ReportTemplate",
        name: testName,
        automatorSummary: testSummary
      }
    },
    extended: {
      ...testInitState.extended,
      category: categoryCode
    }
  };
}

function getCategoryIndex(categories: TestCategory[], test: ExtendedTest) {
  return categories.findIndex(category => category.code === test.extended.category);
}

function getNewCategory(categoryName: string, testList: ExtendedTest) {
  return {
    code: categoryName,
    name: "Tests for " + categoryName,
    tests: [testList],
    selected: false
  };
}

/* eslint-disable fp/no-mutating-methods, max-statements */
function addTestToCategory(categories: TestCategory[], indexOfCategory: number, test: ExtendedTest) {
  categories[indexOfCategory].tests.push(test);
}

function addToOrCreateNewCategoryWithTest(
  testsCategories: TestCategory[],
  reportTemplate: getReportTemplates_getAutomatorReportTemplates_edges,
  testSuite: getTestSuiteTestsAndCustomExtractions_node_TestSuite
) {
  const test = createCompleteTestList(reportTemplate, testSuite);
  const indexOfCategory = getCategoryIndex(testsCategories, test);
  if (indexOfCategory > -1) {
    addTestToCategory(testsCategories, indexOfCategory, test);
  } else {
    const categoryCode = getCategoryCode(reportTemplate);
    const newCategory: TestCategory = getNewCategory(categoryCode, test);
    testsCategories.push(newCategory);
  }
}

function addNewTestCategoryForCustomExtraction(
  reportTemplate: getReportTemplates_getAutomatorReportTemplates_edges,
  testSuite: getTestSuiteTestsAndCustomExtractions_node_TestSuite,
  testsCategories: TestCategory[]
) {
  const customExtractionInReportTemplate = isCustomExtractionInReportTemplate(
    reportTemplate.node,
    testSuite.customExtractions
  );
  if (customExtractionInReportTemplate) {
    addToOrCreateNewCategoryWithTest(testsCategories, reportTemplate, testSuite);
  }
}

function getNewTestCategories(
  reportTemplates: getReportTemplates_getAutomatorReportTemplates_edges[],
  testSuite: getTestSuiteTestsAndCustomExtractions_node_TestSuite
) {
  const testsCategories: TestCategory[] = [];

  reportTemplates.forEach(reportTemplate => {
    getCategoryCode(reportTemplate) === CategoriesCode.CUSTOM_EXTRACTION
      ? addNewTestCategoryForCustomExtraction(reportTemplate, testSuite, testsCategories)
      : addToOrCreateNewCategoryWithTest(testsCategories, reportTemplate, testSuite);
  });
  return testsCategories;
}
/* eslint-enable fp/no-mutating-methods */

/* eslint-disable fp/no-mutating-methods, fp/no-mutation */
function sortCategoriesAndTheirTestsByName(testsCategories: TestCategory[]) {
  return testsCategories.sort(compareCategoryByName).map(cat => {
    return {
      name: cat.name,
      code: cat.code,
      selected: cat.selected,
      tests: cat.tests.sort(compareTestByName)
    };
  });
}

/* eslint-enable fp/no-mutating-methods, fp/no-mutation */

export function getCategories(
  reportTemplates: getReportTemplates_getAutomatorReportTemplates_edges[],
  testSuite: getTestSuiteTestsAndCustomExtractions_node_TestSuite
) {
  const testsCategories: TestCategory[] = sortCategoriesAndTheirTestsByName(
    getNewTestCategories(reportTemplates, testSuite)
  );

  return [recommendedTestsCategory]
    .concat(testsCategories)
    .filter(
      category =>
        category.tests.length > 0 ||
        category.code === CategoriesCode.CUSTOM_EXTRACTION ||
        category.code === RECOMMENDED_TESTS_CATEGORY_CODE
    );
}

function compareCustomExtractions(
  extractionA: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions,
  extractionB: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions
) {
  return (
    extractionA.regex !== extractionB.regex ||
    extractionA.label !== extractionB.label ||
    extractionA.cleanHtmlTags !== extractionB.cleanHtmlTags ||
    extractionA.matchNumberFrom !== extractionB.matchNumberFrom ||
    extractionA.matchNumberTo !== extractionB.matchNumberTo
  );
}

function areCustomExtractionDifferentLength(
  extractionsA: getTestSuite_node_TestSuite_customExtractions[],
  extractionsB: getTestSuite_node_TestSuite_customExtractions[]
) {
  return (
    (extractionsB.length === 0 && extractionsA.length !== 0) || (extractionsA.length === 0 && extractionsB.length !== 0)
  );
}

function areCustomExtractionsSameLength(
  extractionsA: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[],
  extractionsB: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[]
) {
  return extractionsA.length === 0 && extractionsB.length === 0;
}

function mapCustomExtractionsToDifferenceResult(
  extractionsA: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[],
  extractionsB: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[]
) {
  return extractionsA
    .map((extraction, index) => {
      return compareCustomExtractions(extractionsB[index], extraction);
    })
    .reduce((prev, next) => {
      return prev || next;
    });
}

function areCustomExtractionsUndefinedsDifferent(
  extractionsA: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | undefined,
  extractionsB: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | undefined
) {
  return (!!extractionsA && !extractionsB) || (!extractionsA && !!extractionsB);
}

export function areCustomExtractionArraysDifferent(
  extractionsA: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | undefined,
  extractionsB: getTestSuiteTestsAndCustomExtractions_node_TestSuite_customExtractions[] | undefined
) {
  if (Array.isArray(extractionsB) && Array.isArray(extractionsA)) {
    if (areCustomExtractionsSameLength(extractionsA, extractionsB)) {
      return false;
    } else if (areCustomExtractionDifferentLength(extractionsA, extractionsB)) {
      return true;
    } else {
      return mapCustomExtractionsToDifferenceResult(extractionsA, extractionsB);
    }
  } else {
    return areCustomExtractionsUndefinedsDifferent(extractionsA, extractionsB);
  }
}

export function isTestInValid(test: ExtendedTest) {
  return isNaN(test.data.absoluteThreshold) || test.data.absoluteThreshold > 10000 || test.data.absoluteThreshold < 1;
}

export function isAnySelectedTestInvalid(categories: TestCategory[]) {
  return getSelectedTests(categories).filter(test => isTestInValid(test)).length > 0;
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function resolveTestsChunks(
  testsChunks: ExtendedTest[][],
  index = 0,
  fn: (test: ExtendedTest) => Promise<ExecutionResult<deleteTest | createTest | updateTest>> | undefined
): Promise<ExecutionResult<deleteTest | createTest | updateTest>[]> {
  if (testsChunks[index]) {
    const promises = testsChunks[index]
      .map(test => {
        return fn(test);
      })
      .filter(notUndefined);
    return Promise.all(promises as Promise<ExecutionResult<deleteTest | createTest | updateTest>[]>[]).then(
      () => {
        return resolveTestsChunks(testsChunks, index + 1, fn);
      },
      () => {
        return resolveTestsChunks(testsChunks, index + 1, fn);
      }
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise(resolve => resolve(null as any));
  }
}

// eslint-disable-next-line max-params
export function getCreateTestMutationVariables(
  test: ExtendedTest,
  hasJiraAccess: boolean,
  testSuiteId: string,
  hasSmartThresholdsAccess: boolean
): createTestVariables {
  const variables = {
    hasJiraAccess: hasJiraAccess,
    testSuiteId,
    relativeThreshold: test.data.relativeThreshold,
    absoluteThreshold: test.data.absoluteThreshold,
    thresholdType: test.data.thresholdType,
    reportTemplateCode: test.data.reportTemplate.code,
    severity: test.data.severity,
    thresholdPredicate: test.data.thresholdPredicate
  };

  const createVariables: createTestVariables = hasSmartThresholdsAccess
    ? { ...variables, automaticThresholdEnabled: test.data.automaticThresholdEnabled }
    : variables;

  return hasJiraAccess
    ? {
        ...createVariables,
        createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
        jiraTicketCustomNote: test.data.jiraTicketCustomNote
      }
    : createVariables;
}

export function getUpdateTestMutationVariables(
  test: ExtendedTest,
  hasJiraAccess: boolean,
  hasSmartThresholdsAccess: boolean
): updateTestVariables {
  const variables = {
    hasJiraAccess: hasJiraAccess,
    testId: test.data.id,
    relativeThreshold: test.data.relativeThreshold,
    absoluteThreshold: test.data.absoluteThreshold,
    thresholdType: test.data.thresholdType,
    severity: test.data.severity,
    thresholdPredicate: test.data.thresholdPredicate
  };

  const updateVariables = hasSmartThresholdsAccess
    ? { ...variables, automaticThresholdEnabled: test.data.automaticThresholdEnabled }
    : variables;

  return hasJiraAccess
    ? {
        ...updateVariables,
        createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
        jiraTicketCustomNote: test.data.jiraTicketCustomNote
      }
    : updateVariables;
}
