import React from "react";
import { Button, Box, makeStyles } from "@material-ui/core";

interface TestReportFilterButtonProps {
  color?: string;
  count: number;
  label: string;
  isActive: boolean;
  onClick: () => void;
  dataTestId: string;
}

const useStyles = makeStyles(theme => ({
  count: {
    fontSize: 26
  },
  button: (props: TestReportFilterButtonProps) => ({
    minWidth: "180px",
    height: "auto",
    padding: theme.spacing(2),
    background: props.isActive && props.label === "Tests" ? props.color : "white",
    outline: props.isActive && props.label !== "Tests" ? `4px solid ${props.color}` : "none",
    "z-index": props.isActive ? "10" : "1",
    borderRadius: 0
  })
}));

export function TestReportFilterButton(props: TestReportFilterButtonProps) {
  const classes = useStyles(props);

  return (
    <Button
      className={classes.button}
      onClick={props.onClick}
      data-testid={props.dataTestId}
      data-pendo={`auto-report-${props.dataTestId}`}
    >
      <Box>
        <Box display="block" className={classes.count}>
          {props.count}
        </Box>
        <Box>{props.label}</Box>
      </Box>
    </Button>
  );
}
