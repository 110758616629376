import gql from "graphql-tag";

export const getTestSuiteReportGql = gql`
  query getTestSuiteReport($id: ObjectID!) {
    node(id: $id) {
      ... on Build {
        id
        passed
        failedTestCount
        passedTestCount
        warnedTestCount
        crawlDcWebUrl
        status
        crawl {
          id
        }
        testSuite {
          id
          name
          sitePrimary
          account {
            id
          }
          customExtractions {
            regex
            label
            reportTemplateCode
          }
          builds(filter: { status: { in: ["Running", "Queued"] } }) {
            nodes {
              id
            }
          }
          testSuiteJiraIntegration {
            id
          }
        }
        testResults(first: 100) {
          nodes {
            passed
            severity
            id
            reportTemplate {
              name
              code
              automatorSummary
            }
          }
        }
      }
    }
  }
`;
