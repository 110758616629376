import React, { useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box, Grid, makeStyles, MenuItem, Select } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { generateTimeOptions } from "./utils/generateTimeOptions";
import { generateSchedulerFrequencyOptions } from "./utils/generateSchedulerFrequencyOptions";
import { Field, FormikErrors } from "formik";
import {
  FormHelperTextStyled,
  FormHelperTextStyledType
} from "../../../../_common/components/FormHelperTextStyled/FormHelperTextStyled";
import { getStartDateWithMinutes } from "./utils/getStartDateWithMinutes";
import clsx from "clsx";
import { getFirstAvailableTimeOption, getSelectedTimeOption } from "./utils/getAvailableTimeOption";
import { ScheduleFrequency } from "./scheduleSettingsValidationSchema";
import { getMenuProps } from "../../../../_common/visualOverrides/selectVisualOverrides";

const useStyles = makeStyles(theme => ({
  dateInput: {
    width: "100%",
    "& .MuiFilledInput-input": {
      padding: "12px 24px",
      maxWidth: "100px",
      textAlign: "center"
    },
    "& .MuiFilledInput-root": {
      borderRadius: 4
    },
    minWidth: 170,
    "& .MuiPickersDay-daySelected": {
      color: theme.palette.secondary.main
    }
  },
  selectMinutes: {
    width: "100%",
    borderRadius: 4,
    minWidth: 168
  },
  select: {
    padding: "12px 30px"
  },
  frequencySelect: {
    width: "100%",
    minWidth: 252,
    borderRadius: 4
  },
  message: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    fontWeight: 300
  },
  alertWrapper: {
    height: theme.spacing(4)
  },
  wrapper: {
    alignItems: "center",
    justifyContent: "space-between",
    ["@media (max-width: 750px)"]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  menuPaper: {
    maxHeight: 240
  },
  alertIcon: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    marginRight: theme.spacing(1)
  },
  fieldExtendable: {
    ["@media (max-width: 750px)"]: {
      marginBottom: theme.spacing(4)
    }
  },
  field: {
    ["@media (max-width: 750px)"]: {
      minWidth: "50%"
    }
  }
}));

interface ScheduleTimeProps {
  errors: FormikErrors<{
    date: Date;
    minutes: number;
    frequency: ScheduleFrequency;
  }>;
  handleChange: {
    (e: React.ChangeEvent): void;
    <T = string | React.ChangeEvent>(field: T): T extends React.ChangeEvent
      ? void
      : (e: string | React.ChangeEvent) => void;
  };
  initialDate: Date;
  initialFrequency: ScheduleFrequency;
  minutesIntervalSkip: number;
}

// eslint-disable-next-line max-lines-per-function
export function ScheduleTime(props: ScheduleTimeProps) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(props.initialDate);
  const timeOptions = generateTimeOptions(props.minutesIntervalSkip, selectedDate);
  const closestMinutesToStart = selectedDate
    ? getSelectedTimeOption(timeOptions, selectedDate)
    : getFirstAvailableTimeOption(timeOptions);
  const [selectedMinutes, setSelectedMinutes] = useState<number | undefined>(closestMinutesToStart?.minutes);
  const frequencyOptions = generateSchedulerFrequencyOptions(selectedDate);
  const [selectedFrequency, setSelectedFrequency] = useState<ScheduleFrequency | unknown>(props.initialFrequency);

  function handlePropDateChange(newDate: Date | null) {
    props.handleChange({ target: { value: newDate, name: "date" }, type: "date" });
  }

  function handleDateChange(date: MaterialUiPickersDate) {
    const newDate = date ? getStartDateWithMinutes(date, selectedMinutes) : null;
    setSelectedDate(newDate);
    handlePropDateChange(newDate);
  }

  function handleTimeChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    setSelectedMinutes(event.target.value as number);
    props.handleChange(event);
    const newDate = selectedDate ? getStartDateWithMinutes(selectedDate, event?.target?.value as number) : null;
    setSelectedDate(newDate);
    handlePropDateChange(newDate);
  }

  function handleFrequencyChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    setSelectedFrequency(event.target.value);
    props.handleChange({ target: { value: event.target.value, name: "frequency" }, type: "string" });
  }

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item className={clsx(classes.field, classes.fieldExtendable)}>
          <Field
            component={DatePicker}
            autoOk
            name="date"
            className={classes.dateInput}
            disableToolbar
            variant="inline"
            inputVariant="filled"
            disablePast
            format="MMM d, yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            data-testid="schedule-date-picker"
            data-pendo="auto-scheduler-edit-date"
            aria-describedby="select-schedule-date"
            error={!!props.errors.date}
            helperText={null}
          />
        </Grid>

        <Grid item className={clsx(classes.field, classes.fieldExtendable)}>
          <Select
            name="minutes"
            variant="filled"
            IconComponent={ExpandMoreRoundedIcon}
            labelId="select-schedule-time"
            id="select-schedule-time"
            data-testid="select-schedule-time"
            data-pendo="auto-scheduler-edit-time"
            aria-describedby="select-schedule-time"
            value={selectedMinutes}
            onChange={handleTimeChange}
            label="Time"
            className={classes.selectMinutes}
            classes={{ select: classes.select }}
            error={!!props.errors.minutes || !!props.errors.date}
            MenuProps={getMenuProps({ paper: classes.menuPaper })}
          >
            {timeOptions.map(time => {
              return (
                <MenuItem
                  value={time.minutes}
                  key={`time-${time.minutes}`}
                  disabled={time.isDisabled}
                  data-testid="select-schedule-time-option"
                >
                  {time.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item className={classes.field}>
          <Select
            name="frequency"
            variant="filled"
            IconComponent={ExpandMoreRoundedIcon}
            labelId="select-schedule-frequency"
            id="select-schedule-frequency"
            data-testid="select-schedule-frequency"
            data-pendo="auto-scheduler-edit-frequency"
            value={selectedFrequency}
            onChange={handleFrequencyChange}
            label="Frequency"
            className={classes.frequencySelect}
            classes={{ select: classes.select }}
            error={!!props.errors.frequency}
            aria-describedby="select-schedule-frequency"
            MenuProps={getMenuProps()}
          >
            {frequencyOptions.map(option => {
              return (
                <MenuItem value={option.value} key={option.value} data-testid="select-schedule-frequency-option">
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      <Box>
        <FormHelperTextStyled
          testId="select-schedule-date-error"
          id="select-schedule-date"
          text={props.errors?.date}
          show={!!props.errors.date}
          type={FormHelperTextStyledType.ERROR}
        />
        <FormHelperTextStyled
          id="select-schedule-time"
          testId="select-schedule-time-error"
          text={props.errors?.minutes}
          show={!!props.errors.minutes}
          type={FormHelperTextStyledType.ERROR}
        />
        <FormHelperTextStyled
          id="select-schedule-frequency"
          testId="select-schedule-frequency-error"
          text={props.errors?.frequency}
          show={!!props.errors.frequency}
          type={FormHelperTextStyledType.ERROR}
        />
      </Box>
    </>
  );
}
