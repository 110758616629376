import { getTestSuites, getTestSuites_getAccount_testSuites_edges } from "../../../generated-graphql-interfaces";

function haveAnyBuildsFinished(testSuiteEdge: getTestSuites_getAccount_testSuites_edges) {
  const finishedBuilds = testSuiteEdge.node.builds.nodes.filter(
    node => node.finishedAt !== null && node.finishedAt !== undefined
  );
  return finishedBuilds.length > 0;
}

export function getTestSuitesFromData(data: getTestSuites | undefined) {
  if (!data) return undefined;

  return data?.getAccount?.testSuites?.edges.filter(haveAnyBuildsFinished).map(edge => {
    return {
      id: edge.node.id,
      name: edge.node.name
    };
  });
}
