import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React from "react";

export function GettingStartedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0696 18.7951C12.2005 18.8737 12.3406 18.9085 12.4766 18.9068C12.6126 18.9085 12.7526 18.8737 12.8835 18.7951L14.0452 18.0982C16.3235 16.7312 19.1098 16.5079 21.5767 17.4947C22.4867 17.8586 23.4766 17.1885 23.4766 16.2084V4.27838C23.4766 3.68699 23.1165 3.15519 22.5674 2.93554C19.3901 1.66463 15.8013 1.95218 12.8669 3.71282L12.4766 3.94706L12.0862 3.71282C9.15177 1.95218 5.56301 1.66463 2.3857 2.93554C1.83662 3.15519 1.47656 3.68699 1.47656 4.27838V16.2084C1.47656 17.1885 2.46645 17.8586 3.37646 17.4947C5.84333 16.5079 8.62965 16.7312 10.9079 18.0982L12.0696 18.7951ZM3.01135 4.33848V15.9901C5.87216 14.9177 9.07086 15.206 11.6976 16.7819L11.709 16.7888V5.27646L11.2963 5.02887C8.79043 3.52531 5.72947 3.2712 3.01135 4.33848ZM13.2554 16.7819L13.244 16.7888V5.27646L13.6566 5.02887C16.1626 3.52531 19.2236 3.2712 21.9416 4.33848V15.9901C19.0808 14.9177 15.8821 15.206 13.2554 16.7819Z"
      />
      <path d="M9.68817 19.2107C7.61724 18.0027 5.05644 18.0027 2.98552 19.2107L2.88039 19.272C2.51428 19.4856 2.39062 19.9555 2.60418 20.3216C2.81774 20.6877 3.28765 20.8114 3.65376 20.5979L3.7589 20.5365C5.35192 19.6072 7.32177 19.6072 8.91478 20.5365L10.0273 21.1855C11.5407 22.0683 13.412 22.0683 14.9254 21.1855L16.0379 20.5365C17.631 19.6072 19.6008 19.6072 21.1939 20.5365L21.299 20.5979C21.6651 20.8114 22.135 20.6877 22.3485 20.3216C22.5621 19.9555 22.4385 19.4856 22.0724 19.272L21.9673 19.2107C19.8963 18.0027 17.3355 18.0027 15.2645 19.2107L14.1521 19.8597C13.1166 20.4637 11.8361 20.4637 10.8007 19.8597L9.68817 19.2107Z" />
    </SvgIcon>
  );
}

export function LinearGradientGettingStartedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9664 4.15344C9.1657 2.47302 5.74044 2.19856 2.70789 3.41158C2.26619 3.58826 1.97656 4.01606 1.97656 4.49178V16.1507C1.97656 16.9316 2.76532 17.4656 3.49043 17.1756C5.97377 16.1823 8.77871 16.407 11.0722 17.7831L12.2075 18.4643C12.2941 18.5162 12.3867 18.5389 12.4766 18.5373C12.5665 18.5389 12.6591 18.5162 12.7457 18.4643L13.881 17.7831C16.1745 16.407 18.9794 16.1823 21.4627 17.1756C22.1878 17.4656 22.9766 16.9316 22.9766 16.1507V4.49178C22.9766 4.01606 22.687 3.58826 22.2453 3.41158C19.2127 2.19856 15.7875 2.47302 12.9868 4.15344L12.4766 4.45954L11.9664 4.15344ZM13.2266 6.54263C13.2266 6.12842 12.8908 5.79263 12.4766 5.79263C12.0624 5.79263 11.7266 6.12842 11.7266 6.54263V16.0426C11.7266 16.4568 12.0624 16.7926 12.4766 16.7926C12.8908 16.7926 13.2266 16.4568 13.2266 16.0426V6.54263Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M3.20152 19.0847C5.22538 17.9041 7.72798 17.9041 9.75184 19.0847L10.8391 19.7189C11.851 20.3092 13.1024 20.3092 14.1143 19.7189L15.2015 19.0847C17.2254 17.9041 19.728 17.9041 21.7519 19.0847L21.8546 19.1446C22.2124 19.3533 22.3332 19.8125 22.1245 20.1703C21.9158 20.5281 21.4566 20.649 21.0988 20.4403L20.9961 20.3803C19.4392 19.4722 17.5142 19.4722 15.9573 20.3803L14.8701 21.0146C13.3911 21.8773 11.5623 21.8773 10.0833 21.0146L8.99603 20.3803C7.43922 19.4722 5.51414 19.4722 3.95733 20.3803L3.85458 20.4403C3.49679 20.649 3.03756 20.5281 2.82885 20.1703C2.62014 19.8125 2.74099 19.3533 3.09878 19.1446L3.20152 19.0847Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.65757"
          y1="15.4932"
          x2="19.6122"
          y2="17.2527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="4.28751"
          y1="20.9976"
          x2="16.5748"
          y2="26.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#593FF9" />
          <stop offset="0.59375" stopColor="#09DCFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
