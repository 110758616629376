import React from "react";
import { Tooltip, TooltipProps, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: (props: ActionButtonTooltipProps) => {
    return {
      backgroundColor: theme.palette.navy[400],
      fontSize: "0.875rem",
      margin: 0
    };
  },
  popper: (props: ActionButtonTooltipProps) => {
    return {
      marginTop: props.margintop ? props.margintop : 0
    };
  }
}));

export interface ActionButtonTooltipProps extends TooltipProps {
  margintop?: string;
}

export function ActionButtonTooltip(props: ActionButtonTooltipProps) {
  const classes = useStyles(props);
  return (
    <Tooltip {...props} classes={classes}>
      {props.children}
    </Tooltip>
  );
}
