import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Divider, Theme } from "@material-ui/core";
import clsx from "clsx";

export interface ForgottenPasswordConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
}

export const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2, 3),
    background: "rgba(0, 0, 0, 0.1)"
  },
  line: {
    color: theme.palette.navy[500],
    display: "block",
    marginBottom: theme.spacing(2)
  },
  smallerFont: {
    color: theme.palette.navy[300],
    fontSize: 14
  },
  content: {
    marginTop: theme.spacing(2)
  },
  minWidth: {
    minWidth: 500
  }
}));

export function ForgottenPasswordConfirmationDialog(props: ForgottenPasswordConfirmationDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={classes.minWidth}
      data-testid="forgotten-password-confirmation-dialog"
    >
      <DialogTitle data-testid="forgotten-password-confirmation-dialog-title">Password reset email sent</DialogTitle>
      <Divider />
      <DialogContent data-testid="forgotten-password-confirmation-dialog-content">
        <DialogContentText className={classes.content}>
          <span className={classes.line}>Thank you!</span>{" "}
          <span className={classes.line}>Please follow the instructions in the email to reset your password.</span>{" "}
          <span className={clsx(classes.line, classes.smallerFont)}>
            Not received the password reset email? <a href="mailto:support@deepcrawl.com">Contact our support team</a>{" "}
            for help.
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          data-testid="forgotten-password-confirmation-dialog-ok"
          type="button"
          variant="contained"
          color="secondary"
          onClick={props.onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
