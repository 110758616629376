import { getTestSuites_getAccount } from "../../../generated-graphql-interfaces";
import { BuildInfo } from "../Dashboard";
import { buildsInfoFactory } from "./buildsInfoFactory";

export function getAllBuildsFromAccount(account: getTestSuites_getAccount): BuildInfo[] {
  const testSuiteEdges = account.testSuites?.edges || [];
  return testSuiteEdges
    .map(testSuiteEdge => {
      return buildsInfoFactory(testSuiteEdge);
    })
    .flatMap(arr => arr);
}
