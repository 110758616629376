import gql from "graphql-tag";

export const useGetTestSuitesQueryGql = gql`
  query getTestSuiteOptionsForSchedule($accountId: ObjectID!, $searchText: String) {
    getAccount(id: $accountId) {
      id
      unscheduledTestSuites(first: 100, filter: { name: { contains: $searchText } }) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
