import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createAndRunManualBuild, createAndRunManualBuildVariables } from "../../generated-graphql-interfaces";

export const createAndRunManualBuildGql = gql`
  mutation createAndRunManualBuild($testSuiteId: ObjectID!) {
    createAndRunManualBuild(input: { testSuiteId: $testSuiteId }) {
      build {
        id
      }
    }
  }
`;

export function useCreateAndRunManualBuild() {
  return useMutation<createAndRunManualBuild, createAndRunManualBuildVariables>(createAndRunManualBuildGql);
}
