import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2, 0, 2, 0),
    minHeight: "726px"
  },
  h2: {
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.navy[500]
  },
  selectsGrid: {
    marginTop: theme.spacing(2)
  },
  select: {
    padding: "16px 15px 17px 15px"
  },
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
    width: 180,
    "& .MuiOutlinedInput-root": {
      borderRadius: 4
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 15px 17px 15px"
    },
    "& .Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main + " !important",
      borderWidth: "2px !important"
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.navy[300]
    },
    "& MuiFormLabel-root": {
      color: theme.palette.navy[300]
    }
  },
  durationHidden: {
    display: "none"
  },
  switch: {
    margin: theme.spacing(0, 6, 3, 0)
  },
  customRangeContainer: {
    marginLeft: "0"
  },
  menuPaper: {
    maxHeight: 240
  },
  selectMinutes: {
    borderRadius: 4,
    minWidth: 230,
    "& .MuiSelect-iconOutlined": {
      right: "10px"
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main + " !important",
      borderWidth: "2px !important"
    }
  },
  formControl: {
    height: "57px"
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(0, 3.5, 0, 3.5),
    display: "flex",
    alignItems: "center"
  },
  filterBox: {
    padding: theme.spacing(1, 3.5, 0, 3.5)
  },
  chartBox: {
    padding: theme.spacing(0, 3.5, 0, 3.5)
  }
}));
