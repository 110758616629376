import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  unlinkJiraIntegrationFromTestSuite,
  unlinkJiraIntegrationFromTestSuiteVariables
} from "../../../../../generated-graphql-interfaces";

export const unlinkJiraIntegrationFromTestSuiteGql = gql`
  mutation unlinkJiraIntegrationFromTestSuite($testSuiteJiraIntegrationId: ObjectID!) {
    unlinkJiraIntegrationFromTestSuite(input: { testSuiteJiraIntegrationId: $testSuiteJiraIntegrationId }) {
      testSuiteJiraIntegration {
        id
      }
    }
  }
`;

export function useUnlinkJiraIntegrationFromTestSuiteMutation() {
  return useMutation<unlinkJiraIntegrationFromTestSuite, unlinkJiraIntegrationFromTestSuiteVariables>(
    unlinkJiraIntegrationFromTestSuiteGql,
    {
      refetchQueries: ["getTestSuite"]
    }
  );
}
