import React from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";

export enum BreadcrumbPaths {
  MyTestSuites = "Test suites",
  TestSuiteRuns = "Test suite runs",
  AddTestSuite = "Add test suite",
  EditTestSuite = "Edit test suite",
  Results = "Results"
}

interface CustomBreadcrumbsProps {
  content: { text: BreadcrumbPaths; link?: string; testDataId?: string }[];
}

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.navy[500],
    fontWeight: 600,
    textDecoration: "none",
    padding: "7px 11px",
    display: "block",
    borderRadius: 6,
    background: theme.palette.grey[100],
    "&:hover": {
      color: theme.palette.navy[500],
      background: theme.palette.grey[200]
    }
  },
  separator: {
    color: theme.palette.navy[200],
    margin: theme.spacing(0, 2)
  },
  text: {
    fontWeight: 600
  },
  topDivider: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: 6
  },
  bottomDivider: {
    backgroundColor: theme.palette.grey[200],
    marginTop: 6
  },
  root: {
    marginLeft: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1)
    }
  }
}));

export function CustomBreadcrumbs(props: CustomBreadcrumbsProps) {
  const classes = useStyles(props);

  function limitStringCharacterLength(string: string) {
    if (string.length < 61) return string;

    return `${string.slice(0, 60)}...`;
  }

  return (
    <Box component="div" marginBottom={6}>
      <Divider className={classes.topDivider} />
      <Breadcrumbs
        separator={<KeyboardArrowLeftRoundedIcon />}
        classes={{ separator: classes.separator, root: classes.root }}
      >
        {props.content.map(breadcrumb => {
          const transformedString = limitStringCharacterLength(breadcrumb.text);

          return breadcrumb.link ? (
            <Link
              key={breadcrumb.link}
              to={breadcrumb.link}
              className={classes.link}
              data-cy={breadcrumb.testDataId}
              data-testid={breadcrumb.testDataId}
              data-pendo={`auto-global-breadcrumbs-${transformedString.replace(/\s+/g, "-").toLowerCase()}-active`}
            >
              {transformedString}
            </Link>
          ) : (
            <Typography
              color="textPrimary"
              className={classes.text}
              data-cy={breadcrumb.testDataId}
              data-testid={breadcrumb.testDataId}
              key={breadcrumb.text}
              data-pendo={`auto-global-breadcrumbs-${transformedString.replace(/\s+/g, "-").toLowerCase()}-inactive`}
            >
              {transformedString}
            </Typography>
          );
        })}
      </Breadcrumbs>
      <Divider className={classes.bottomDivider} />
    </Box>
  );
}
