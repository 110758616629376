import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { updateBuildSchedule, updateBuildScheduleVariables } from "../../../../generated-graphql-interfaces";

export const useUpdateBuildScheduleGql = gql`
  mutation updateBuildSchedule($input: UpdateBuildScheduleInput!) {
    updateBuildSchedule(input: $input) {
      buildSchedule {
        id
      }
    }
  }
`;

export function useUpdateBuildScheduleMutation() {
  return useMutation<updateBuildSchedule, updateBuildScheduleVariables>(useUpdateBuildScheduleGql);
}
