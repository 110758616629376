import { AutomatorPermissions } from "../../contexts/Permissions/Permissions.context";
import {
  getUser,
  getUser_me_accountsUsers_nodes,
  getUser_me_accountsUsers_nodes_account_featureFlags
} from "../../../generated-graphql-interfaces";

enum PERMISSIONS_IDENTIFIERS {
  TRAYIO_INTEGRATION = "tray-io-integration", // @info This should be removed once Jira integration is released to all users.
  GLOBAL_TEMPLATE = "global-templates", // @info This should be removed once Global Template system is available for all users.
  FRONTEND_SCHEDULER = "automator-frontend-scheduler", // @info: Should be removed once Scheduler is available to all users.
  AUTOMATIC_THRESHOLD = "automatic-threshold" // @info: Should be removed once Smart Threshold will be rolled for all users.
}

function getFlagStatusFromPermissions(
  permissionsList: getUser_me_accountsUsers_nodes_account_featureFlags[],
  featureFlag: string
): boolean {
  return (
    permissionsList.filter(permission => {
      return permission.name === featureFlag && permission.enabled;
    }).length > 0
  );
}

export function extractPermissionsFromGetUser(data: getUser, accountId: string): AutomatorPermissions {
  const permissionsList = (data.me.accountsUsers.nodes as getUser_me_accountsUsers_nodes[])
    .filter(user => {
      return user.account.id === accountId;
    })
    .flatMap(user => {
      return user.account.featureFlags;
    });

  const isJiraIntegrationEnabled = getFlagStatusFromPermissions(
    permissionsList,
    PERMISSIONS_IDENTIFIERS.TRAYIO_INTEGRATION
  );
  const isGlobalTemplatesEnabled = getFlagStatusFromPermissions(
    permissionsList,
    PERMISSIONS_IDENTIFIERS.GLOBAL_TEMPLATE
  );
  const isFrontendSchedulerEnabled = getFlagStatusFromPermissions(
    permissionsList,
    PERMISSIONS_IDENTIFIERS.FRONTEND_SCHEDULER
  );

  const isAutomaticThresholdEnabled = getFlagStatusFromPermissions(
    permissionsList,
    PERMISSIONS_IDENTIFIERS.AUTOMATIC_THRESHOLD
  );

  return {
    jiraIntegrationAccess: isJiraIntegrationEnabled,
    globalTemplatesAccess: isGlobalTemplatesEnabled,
    frontendSchedulerAccess: isFrontendSchedulerEnabled,
    automaticThresholdAccess: isAutomaticThresholdEnabled
  };
}
