import * as yup from "yup";
import { ValidationErrors } from "../../../../validation/ValidationErrors";

export const crawlSettingsFormValidation = yup.object().shape({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  crawlTypes: yup.object().test("crawl-type-requied", "At least one URL Source is required", (types: any) => {
    return [
      types?.list,
      types?.web,
      types?.sitemap,
      types?.google_analytics,
      types?.backlinks,
      types?.google_search_console,
      types?.log_summary
    ].reduce((accumulator: boolean, currentValue: boolean) => accumulator || currentValue);
  }),
  useRenderer: yup.string().required(),
  limitPagesMax: yup.string().required(),
  urlsIncluded: yup.string(),
  urlsExcluded: yup.string(),
  startUrls: yup
    .string()
    .url(ValidationErrors.HttpProtocol)
    .matches(/^http(s)?:\/\/.*/, ValidationErrors.HttpProtocol),
  rendererBlockAds: yup.boolean().required(),
  rendererBlockAnalytics: yup.boolean().required(),
  rendererJsString: yup.string(),
  rendererBlockCustom: yup.string(),
  rendererJsUrls: yup.string(),
  useRobotsOverwrite: yup.boolean().required(),
  robotsOverwrite: yup.string().when("useRobotsOverwrite", {
    is: value => value === true,
    then: yup.string().required(ValidationErrors.RobotsOverwrite),
    otherwise: yup.string().notRequired()
  }),
  emptyPageThreshold: yup.number().required(ValidationErrors.EmptyPageThreshold).positive().integer(),
  highLogSummaryRequests: yup.number().required().positive().integer(),
  lowLogSummaryRequests: yup.number().required().positive().integer(),
  maxContentSize: yup.number().required(ValidationErrors.MaxContentSize).positive().integer(),
  maxDescriptionLength: yup.number().required(ValidationErrors.MaxDescriptionLength).positive().integer(),
  maxExternalLinks: yup.number().required(ValidationErrors.MaxExternalLinks).positive().integer(),
  maxHtmlSize: yup.number().required(ValidationErrors.MaxHtmlSize).positive().integer(),
  maxLinks: yup.number().required(ValidationErrors.MaxLinks).positive().integer(),
  maxLoadTime: yup.number().required(ValidationErrors.MaxLoadTime).positive().integer(),
  maxRedirections: yup.number().required(ValidationErrors.MaxRedirections).positive().integer(),
  maxTitleWidth: yup.number().required(ValidationErrors.MaxTitleWidth).positive().integer(),
  maxUrlLength: yup.number().required(ValidationErrors.MaxUrlLength).positive().integer(),
  duplicatePrecision: yup.number().required(ValidationErrors.DuplicatePrecision).integer().min(1).max(5),
  minDescriptionLength: yup.number().required(ValidationErrors.MinDescriptionLength).positive().integer(),
  minTitleLength: yup.number().required(ValidationErrors.MinTitleLength).positive().integer(),
  thinPageThreshold: yup.number().required(ValidationErrors.ThinPageThreshold).positive().integer(),
  customExtractions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(ValidationErrors.CustomExtractionLabel),
      regex: yup.string().required(ValidationErrors.CustomExtractionRegex)
    })
  )
});
