import React from "react";
import { TestResultIcon } from "../../../_common/components/TestResultIcon/TestResultIcon";
import { Paper, Grid, makeStyles, Typography, Box } from "@material-ui/core";
import { StepIntoIcon } from "../../../_common/components/StepIntoIcon/StepIntoIcon";
import { Severity } from "../../../generated-graphql-interfaces";
import {
  getTestStatus,
  getMatchingBorderColorForStatus
} from "../../../_common/components/TestResultIcon/TestResultIconUtils";
import { DeepcrawlInfoTooltip } from "../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import InfoIcon from "@material-ui/icons/Info";
import { getCustomExtractionTooltip } from "../../../_common/utils/getCustomExtractionTooltip/getCustomExtractionTooltip";
import { isReportTemplateCodeCustomExtraction } from "../../../_common/utils/isReportTemplateCodeCustomExtraction/isReportTemplateCodeCustomExtraction";

export interface TestSuiteReportTestResultProps {
  isPassed: boolean;
  severity: Severity;
  testName: string;
  testSummary: string | null;
  crawlDcWebUrl: string | null;
  reportCode: string;
}

const useStyles = makeStyles(theme => ({
  root: (props: TestSuiteReportTestResultProps) => {
    return {
      padding: theme.spacing(3),
      marginBottom: 10,
      border: `1px solid ${theme.palette.navy[200]}`,
      borderLeft: `15px solid ${getMatchingBorderColorForStatus(getTestStatus(props.isPassed, props.severity), theme)}`
    };
  },
  testName: {
    fontWeight: 600,
    marginLeft: theme.spacing(3)
  },
  text: {
    marginRight: theme.spacing(1)
  },
  textWrapper: {
    display: "flex",
    alignItems: "center"
  }
}));

const useIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.navy[200],
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.navy[300]
    }
  }
}));

export function TestSuiteReportTestResult(props: TestSuiteReportTestResultProps) {
  const { crawlDcWebUrl, reportCode, isPassed, severity, testName } = props;
  const classes = useStyles(props);
  const tooltipIconClasses = useIconStyles();

  function getDcLink() {
    return `${crawlDcWebUrl}/reports/${reportCode}_basic`;
  }

  const customExtractionTooltip = getCustomExtractionTooltip(testName);
  const tooltipText = isReportTemplateCodeCustomExtraction(reportCode)
    ? customExtractionTooltip
    : props.testSummary || "N/A";
  return (
    <Paper className={classes.root} data-testid="test-suite-report-test-result">
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <TestResultIcon isPassed={isPassed} severity={severity} />
        </Grid>
        <Grid item sm={5}>
          <Box className={classes.textWrapper}>
            <Typography className={classes.text} variant="body1" data-testid="test-suite-report-test-name">
              Test name: <span className={classes.testName}>{testName}</span>
            </Typography>
            <DeepcrawlInfoTooltip
              data-pendo="auto-tooltip-testsuite-report-info"
              maxWidth={350}
              body={tooltipText}
              testId={"test-suite-report-test-name-tooltip"}
              iconComponent={<InfoIcon classes={tooltipIconClasses} fontSize="small" />}
            />
          </Box>
        </Grid>
        <Grid item sm={4} container justify="flex-end">
          <StepIntoIcon linkTo={getDcLink()} isLinkExternal={true} data-pendo="auto-testsuite-report-item-step-into">
            Open DeepCrawl report
          </StepIntoIcon>
        </Grid>
      </Grid>
    </Paper>
  );
}
