import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid`,
    borderColor: theme.palette.grey[300],
    width: "100%",
    borderRadius: "3px"
  },
  tabs: {
    padding: theme.spacing(0, 3.5, 0, 3.5),
    color: theme.palette.secondary.main
  },
  tabIndicator: {
    backgroundColor: theme.palette.secondary.main,
    height: "3px"
  },
  tabItem: {
    color: "#666666",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 500,
    padding: theme.spacing(3, 4, 3, 4),
    "&.Mui-selected": { color: theme.palette.secondary.main }
  },
  icons: {
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(5)
  }
}));
