import gql from "graphql-tag";

export const getTestSuitesQuery = gql`
  query getTestSuites($accountId: ObjectID!, $cursor: String, $perRequest: Int!) {
    getAccount(id: $accountId) {
      id
      testSuites(first: $perRequest, after: $cursor, orderBy: [{ field: createdAt, direction: DESC }]) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            sitePrimary
            createdAt
            tests(last: 100) {
              totalCount
            }
            builds(first: 100, orderBy: [{ field: updatedAt, direction: DESC }]) {
              nodes {
                id
                createdAt
                updatedAt
                finishedAt
                failedTestCount
                warnedTestCount
                passedTestCount
                passed
                status
              }
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
