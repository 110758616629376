import { useState } from "react";

export function useTimeout(delay: number) {
  const [trigger, setTrigger] = useState(false);

  const timeoutOneShowMain = setTimeout(() => {
    setTrigger(true);
    clear();
  }, delay);

  const clear = () => {
    clearTimeout(timeoutOneShowMain);
  };

  return {
    trigger: trigger,
    clear: clear
  };
}
