import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider, Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ScheduleTime } from "./ScheduleTime";
import { Formik, Form, FormikHelpers } from "formik";
import { useScheduleSettingsStyles } from "./ScheduleSettingsStyles";
import { Alert } from "@material-ui/lab";
import {
  FormHelperTextStyled,
  FormHelperTextStyledType
} from "../../../../_common/components/FormHelperTextStyled/FormHelperTextStyled";
import { generateTimeOptions } from "./utils/generateTimeOptions";
import { differenceInSeconds, startOfDay } from "date-fns";
import { getStartDateWithMinutes } from "./utils/getStartDateWithMinutes";

import { MainInputField } from "../../../../_common/components/MainInputField/MainInputField";
import {
  BuildScheduleRepetitionRate,
  getScheduleForScheduleSettings_node_BuildSchedule,
  getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites
} from "../../../../generated-graphql-interfaces";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";

import {
  DoNotRepeatBuildScheduleFrequency,
  getValidationSchema,
  ScheduleFrequency,
  ScheduleSettingsInitialValues
} from "./scheduleSettingsValidationSchema";
import { isBuildScheduleRepetitionRate } from "./utils/isBuildScheduleRepetitionRate";
import { ChooseTestSuite } from "../../../../_common/components/ChooseTestSuite/ChooseTestSuite";
import { useGetTestSuitesQuery } from "./graphql/useGetTestSuitesQuery";
import { getFirstAvailableTimeOption } from "./utils/getAvailableTimeOption";
import { DeepcrawlInfoTooltip } from "../../../../_common/components/DeepcrawlInfoTooltip/DeepcrawlInfoTooltip";
import InfoIcon from "@material-ui/icons/Info";

interface ScheduleSettingsProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (
    input: {
      accountId?: string;
      name: string;
      repetitionRate?: BuildScheduleRepetitionRate | null | undefined;
      startAt: Date | undefined;
      buildScheduleId?: string;
    },
    testSuiteId: string[]
  ) => Promise<void>;
  schedule?: getScheduleForScheduleSettings_node_BuildSchedule;
}

const useIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.navy[200],
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.navy[300]
    }
  }
}));

// eslint-disable-next-line max-lines-per-function
export function ScheduleSettings(props: ScheduleSettingsProps) {
  const iconClasses = useIconStyles();
  const classes = useScheduleSettingsStyles();
  const minutesIntervalSkip = 15;
  const initialMinutes = getFirstAvailableTimeOption(generateTimeOptions(minutesIntervalSkip, startOfDay(new Date())))
    ?.minutes;
  const initialDate = props.schedule?.startAt
    ? new Date(props.schedule.startAt)
    : getStartDateWithMinutes(startOfDay(new Date()), initialMinutes);
  const initialFrequency: ScheduleFrequency = props.schedule?.repetitionRate || DoNotRepeatBuildScheduleFrequency.Never;
  const accountId = useAccountRouteMatch();
  const mutationForTestSuiteSearch = useGetTestSuitesQuery;

  function getInitialySelectedTestSuites(
    testSuitesList: getScheduleForScheduleSettings_node_BuildSchedule_buildScheduleTestSuites | undefined
  ) {
    return testSuitesList
      ? testSuitesList.nodes.map(testsuite => {
          return {
            id: testsuite.testSuite.id,
            name: testsuite.testSuite.name
          };
        })
      : [];
  }

  const initialValues = {
    scheduleName: props.schedule?.name || "",
    testSuite: getInitialySelectedTestSuites(props.schedule?.buildScheduleTestSuites),
    date: initialDate,
    frequency: initialFrequency
  };

  // eslint-disable-next-line complexity
  function handleSubmit(values: ScheduleSettingsInitialValues, actions: FormikHelpers<ScheduleSettingsInitialValues>) {
    const repetitionRate = isBuildScheduleRepetitionRate(values.frequency) ? values.frequency : null;
    const testSuiteIds = values.testSuite.map(testSuite => testSuite.id);

    const updatingScheduler = !!props.schedule;
    const hasRepetitionChanged = updatingScheduler && props.schedule?.repetitionRate !== repetitionRate;
    const dateChanged = differenceInSeconds(new Date(props.schedule?.startAt), values.date) !== 0;
    const repetitionRateSend = hasRepetitionChanged || dateChanged || !updatingScheduler ? repetitionRate : undefined;
    const dateChangedSend = dateChanged || hasRepetitionChanged ? values.date : undefined;

    props.handleSave(
      {
        accountId,
        name: values.scheduleName,
        startAt: dateChangedSend,
        repetitionRate: repetitionRateSend,
        buildScheduleId: props.schedule?.id
      },
      testSuiteIds
    );
    actions.setSubmitting(false);
    props.handleClose();
  }

  return (
    <Dialog
      onClose={props.handleClose}
      data-testid="schedule-settings-dialog"
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography className={classes.title} variant="h6">
          New scheduled run
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
          data-testid="close-schedule-settings"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(props.schedule?.startAt, props.schedule?.repetitionRate)}
        >
          {({ errors, isValid, handleChange, handleBlur }) => (
            <Form>
              {!isValid && (
                <Alert severity="error" className={classes.alert}>
                  You need to complete all the fields marked below.
                </Alert>
              )}
              <Box className={classes.mainInputBox}>
                <MainInputField
                  autoFocus
                  fullWidth
                  label="Title"
                  id="scheduleName"
                  name="scheduleName"
                  placeholder="Add title e.g. marketing weekly run"
                  data-testid="schedule-name-input"
                  data-pendo="auto-scheduler-edit-name-input"
                  aria-describedby="schedule-name-text-field"
                  helperText={""}
                  defaultValue={initialValues.scheduleName}
                  error={!!errors.scheduleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormHelperTextStyled
                  id="schedule-name-text-field"
                  testId="schedule-name-text-error"
                  text={errors?.scheduleName}
                  show={!!errors.scheduleName}
                  type={FormHelperTextStyledType.ERROR}
                />
              </Box>

              <Box>
                <ScheduleTime
                  errors={errors}
                  handleChange={handleChange}
                  initialDate={initialDate}
                  initialFrequency={initialFrequency}
                  minutesIntervalSkip={minutesIntervalSkip}
                />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" alignItems="center" mb={3.5}>
                <Typography className={classes.subtitle}>Add test suites</Typography>
                <DeepcrawlInfoTooltip
                  data-pendo="auto-tooltip-scheduler-add-test-suites"
                  body="Please note you can only select a maximum of 50 test suites per scheduler."
                  maxWidth={350}
                  testId="add-test-suites-to-scheduler-tooltip"
                  iconComponent={<InfoIcon classes={iconClasses} fontSize="small" />}
                />
              </Box>
              <ChooseTestSuite
                errors={errors}
                handleChange={handleChange}
                mutation={mutationForTestSuiteSearch}
                initialValues={initialValues.testSuite}
              />

              <Box display="flex" justifyContent="flex-end" className={classes.buttonsBox}>
                <Button
                  data-testid="cancel-schedule-settings"
                  data-pendo="auto-scheduler-edit-cancel"
                  type="button"
                  onClick={props.handleClose}
                  variant="outlined"
                  className={classes.actionButton}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  data-testid="save-schedule-settings"
                  data-pendo="auto-scheduler-edit-save"
                  variant="contained"
                  className={clsx(classes.actionButton, classes.saveButton)}
                  type="submit"
                  id="schedule-settings-save"
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </MuiDialogContent>
    </Dialog>
  );
}
