import gql from "graphql-tag";

export const createFileUploadMutationGql = gql`
  mutation createFileUpload($testSuiteId: ObjectID!, $file: Upload) {
    createUrlFileUploadForTestSuite(input: { testSuiteId: $testSuiteId }, file: $file) {
      urlFileUpload {
        fileName
      }
    }
  }
`;
