import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progresssBar: {
      width: "100%"
    },
    grid: {
      padding: theme.spacing(0, 4, 0, 0),
      [theme.breakpoints.down("xs")]: {
        paddingRight: theme.spacing(0)
      }
    },
    selectedItemsBox: {
      marginTop: "28px"
    },
    selectedItemsWrapper: {
      border: `1px solid ${theme.palette.grey[50]}`,
      backgroundColor: "white",
      boxShadow: "none",
      "&::before": {
        content: "",
        display: "none"
      }
    },
    selectedItemsHeader: {
      margin: "0",
      padding: "0 14px",
      "&.Mui-expanded": {
        minHeight: "unset",
        "& .MuiExpansionPanelSummary-content": {
          margin: "0"
        }
      }
    },
    selectedItemsDetails: {
      padding: "0"
    },
    collapsableListHeadingCustomExtraction: {
      marginBottom: theme.spacing(2),
      fontSize: 22
    },
    collapsableListHeadingOtherTests: {
      marginBottom: theme.spacing(2),
      fontSize: 22
    },
    submitError: {
      marginTop: theme.spacing(3)
    },
    submittingProgress: {
      marginRight: "10px",
      color: "white"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white"
    },
    icon: {
      color: theme.palette.purple[500]
    },
    containerStart: {
      alignItems: "start"
    },
    padding: {
      padding: theme.spacing(3, 3.5, 3, 3.5)
    }
  })
);

export default useStyles;
